import { Box, BoxProps } from '@chakra-ui/react';
import { ColorId } from '../colors';
import { useTranslation } from 'react-i18next';
import { getFontFamilyByLanguage, isJaOrZh } from '@/utils/utils';

export const Text = ({
  children,
  colorId = 'White',
  ...props
}: { colorId?: ColorId } & BoxProps) => {
  const { i18n } = useTranslation();

  return (
    <Box
      color={`var(--${colorId})`}
      fontFamily={getFontFamilyByLanguage(i18n)}
      fontSize="14px"
      fontWeight={400}
      lineHeight="20px"
      textTransform={'uppercase'}
      {...props}
    >
      {children}
    </Box>
  );
};
