import { getFontFamilyByLanguage, isJaOrZh } from '@/utils/utils';
import { Box, BoxProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const TextWithShadow = ({
  children,
  color = 'white',
  shadowColor = 'black',
  shadowOffsetX = '-2',
  shadowOffsetY = '2',
  ...props
}: {
  shadowColor?: string;
  shadowOffsetX?: string;
  shadowOffsetY?: string;
} & BoxProps) => {
  const { i18n } = useTranslation();
  const textShadow = `${shadowOffsetX}px ${shadowOffsetY}px ${shadowColor}`;

  return (
    <Box
      color={color}
      textShadow={textShadow}
      fontFamily={getFontFamilyByLanguage(i18n)}
      fontSize="14px"
      fontWeight={400}
      lineHeight="20px"
      textTransform={'uppercase'}
      {...props}
    >
      {children}
    </Box>
  );
};
