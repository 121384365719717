import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { useAlert } from '@/hooks/useAlert';
import { sounds } from '@/lib/sounds';
import { getPetName } from '@/utils/utils';
import { Box, VStack, effect } from '@chakra-ui/react';
import {
  BattleState,
  PetStatisticsEnum,
  RootState,
  UiEventPlayMove,
  UiEventType,
} from 'genopets-utils';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../../ui/atoms/Text';
import { useUIBattleContext } from '../context/useBattleContext';
import { useTranslation } from 'react-i18next';
import { useHapticFeedback } from '@tma.js/sdk-react';
import { useEffectPlayer } from '@/context/EffectPlayerContext';

export const STATUS_TRANSLATION_PREFIX = 'specialStatEffect';

export const usePrintMove = () => {
  const { t: translateText } = useTranslation();
  const {
    user,
    uiEvent,
    setPrintMoveInfo,
    printMoveInfo,
    dispatchUiEventPlayed,
  } = useUIBattleContext();

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const [playMoveEvent, setPlayMoveEvent] =
    useState<UiEventPlayMove['payload']>();

  const effectPlayer = useEffectPlayer();

  const hapticFeedback = useHapticFeedback();

  const alert = useAlert();

  useEffect(() => {
    if (
      uiEvent &&
      (uiEvent.type === UiEventType.PLAY_MOVE ||
        uiEvent.type === UiEventType.PLAY_COUNTER_MOVE)
    ) {
      setPlayMoveEvent(uiEvent.payload);
    }
  }, [uiEvent]);

  useEffect(() => {
    if (playMoveEvent && printMoveInfo && uiEvent) {
      const move = playMoveEvent.move;

      const attackerPetName = getPetName(
        playMoveEvent.initiatorPlayerId === battleState.player1.id
          ? battleState.player1.petInfo
          : battleState.player2.petInfo,
        battleState.player1.id === user?.uid ? 'player 1' : 'player 2',
      ) as string;
      const defenderPetName = getPetName(
        playMoveEvent.initiatorPlayerId === battleState.player1.id
          ? battleState.player2.petInfo
          : battleState.player1.petInfo,
        battleState.player1.id === user?.uid ? 'player 1' : 'player 2',
      ) as string;

      const attackerPetFromState =
        playMoveEvent.initiatorPlayerId === battleState.player1.id
          ? battleState.player1.pet
          : battleState.player2.pet;
      const defenderPetFromState =
        playMoveEvent.initiatorPlayerId === battleState.player1.id
          ? battleState.player2.pet
          : battleState.player1.pet;

      const dodgeAction = battleState.actions[battleState.actions.length - 1];

      const dodged = dodgeAction.payload.rolls?.dodged === 1;

      const critical = playMoveEvent.critical;

      const moveNameTranslated =
        translateText(
          // Poke is hardcoded as a default move and isn't saved in firestore
          // manually check if it's Poke and assign the correct localise key
          (move.name === `Poke`
            ? `moveNamePoke`
            : move.lokaliseKeyName) as string,
          {
            defaultValue: move.name as string,
          },
        ) || move.name;

      const title = (
        <VStack alignItems={'flex-start'}>
          <Box>
            <Text colorId="Black">
              <TranslatedText
                translationKey={`petUsedMove`}
                defaultMessage={`${attackerPetName}
            used ${moveNameTranslated}`}
                props={{
                  pet: attackerPetName,
                  move: moveNameTranslated,
                }}
              />
              !
            </Text>
          </Box>
          {critical && !dodged && move.power > 0 && (
            <Text colorId="Yellow">
              <TranslatedText
                translationKey={`criticalHit`}
                defaultMessage={`Critical Hit`}
              />
              !
            </Text>
          )}
          {dodged && (
            <Text colorId="Black">
              <TranslatedText
                translationKey={`dodgedTheAttack`}
                defaultMessage={`${defenderPetName} dodged the attack!`}
                props={{
                  pet: defenderPetName,
                }}
              />
            </Text>
          )}
          {move.status?.length && (
            <>
              {move.status
                // if dodged only self status are shown
                .filter((status) => !dodged || status.target === 'self')
                .map((status) => {
                  const petForStatus =
                    status.target === 'self'
                      ? attackerPetFromState
                      : defenderPetFromState;
                  const statusModifier =
                    status.statAffected &&
                    petForStatus.statusModifier?.[status.statAffected];
                  const isMax = statusModifier === 4;
                  const isMin = statusModifier === -4;
                  return (
                    <Box key={status.value}>
                      {status.value &&
                      status.statAffected !== PetStatisticsEnum.HP ? (
                        <>
                          <Text as="span" colorId="Black">
                            {status.target === `self`
                              ? `${attackerPetName}`
                              : `${defenderPetName}`}
                          </Text>
                          <Text as="span" colorId="Black">
                            {` ${status.statAffected}`}
                          </Text>
                          <Text as="span" colorId="Black">
                            {status.value.startsWith(`-`)
                              ? `${isMin ? ' ' + translateText('wontGoAny', { defaultValue: "won't go any" }) : ' ' + translateText('went', { defaultValue: 'went' })}`
                              : `${isMax ? ' ' + translateText('wontGoAny', { defaultValue: "won't go any" }) : ' ' + translateText('went', { defaultValue: 'went' })}`}
                          </Text>
                          <Text
                            as="span"
                            colorId={
                              status.value.startsWith(`-`) ? 'Red' : 'Green'
                            }
                          >
                            {status.value.startsWith(`-`)
                              ? `${isMin ? ` ${translateText('lower', { defaultValue: 'lower' })}` : ' ' + translateText('down', { defaultValue: 'down' })}`
                              : `${isMax ? ` ${translateText('higher', { defaultValue: 'higher' })}` : ' ' + translateText('up', { defaultValue: 'up' })}`}
                          </Text>
                          <Text as="span" colorId="Black">
                            !
                          </Text>
                        </>
                      ) : (
                        <Text as="span" colorId="Black">
                          <TranslatedText
                            translationKey={
                              (
                                STATUS_TRANSLATION_PREFIX + status.effect
                              ).replace(' ', '') as any
                            }
                            defaultMessage={
                              status.name ?? status.effect ?? 'status name'
                            }
                            props={{
                              pet:
                                status.target === `self`
                                  ? `${attackerPetName}`
                                  : `${defenderPetName}`,
                            }}
                          />
                        </Text>
                      )}
                    </Box>
                  );
                })}
            </>
          )}
        </VStack>
      );

      alert({
        title,
        onCloseComplete: () => {
          setPrintMoveInfo(false);
          dispatchUiEventPlayed(uiEvent.id);
          setPlayMoveEvent(undefined);
        },
      });

      if (critical) {
        effectPlayer.playEffect(sounds.critical);
      }

      hapticFeedback.impactOccurred('heavy');

      if (move.status?.length) {
        const statsValue = move.status.find((s) => s.value)?.value ?? '0';
        if (parseInt(statsValue) > 0) {
          effectPlayer.playEffect(sounds.buff);
        } else if (parseInt(statsValue) < 0) {
          effectPlayer.playEffect(sounds.nerf);
        }
      }
    }
  }, [printMoveInfo]);
};
