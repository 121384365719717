import { isDev } from '@/config/constants';
import { useCallback, useEffect, useRef } from 'react';
// Adgrams types
// https://docs.adsgram.ai/publisher/typescript
export interface ShowPromiseResult {
  done: boolean;
  description: string;
  state: 'load' | 'render' | 'playing' | 'destroy';
  error: boolean;
}

type BannerType = 'RewardedVideo' | 'FullscreenMedia';

interface AdsgramInitParams {
  blockId: string;
  debug?: boolean;
  debugBannerType?: BannerType;
}

type EventType =
  | 'onReward'
  | 'onStart'
  | 'onSkip'
  | 'onBannerNotFound'
  | 'onError';
type HandlerType = () => void;

export interface AdController {
  show(): Promise<ShowPromiseResult>;
  addEventListener(event: EventType, handler: HandlerType): void;
  removeEventListener(event: EventType, handler: HandlerType): void;
  destroy(): void;
}

declare global {
  interface Window {
    Adsgram?: {
      init(params: AdsgramInitParams): AdController;
    };
  }
}

export interface useAdsgramParams {
  blockId: string;
  onReward: () => void;
  onError?: (result: ShowPromiseResult) => void;
}

export function useAdsgram({
  blockId,
  onReward,
  onError,
}: useAdsgramParams): () => Promise<void> {
  const AdControllerRef = useRef<AdController | undefined>(undefined);

  useEffect(() => {
    AdControllerRef.current = window.Adsgram?.init({
      blockId,
      debug: isDev,
      debugBannerType: 'FullscreenMedia',
    });
  }, [blockId]);

  return useCallback(async () => {
    if (AdControllerRef.current) {
      AdControllerRef.current
        .show()
        .then(() => {
          // user watch ad till the end
          onReward();
        })
        .catch((result: ShowPromiseResult) => {
          // user get error during playing ad or skip ad
          onError?.(result);
        });
    } else {
      onError?.({
        error: true,
        done: false,
        state: 'load',
        description: 'Adsgram script not loaded',
      });
    }
  }, [onError, onReward]);
}
