import { useMusic } from '@/components/Sounds/useMusic';
import { MainContainer } from '@/components/battle/MainContainer';
import { PetLevelUp } from '@/components/battle/PetLevelUp';
import { bgSounds } from '@/lib/sounds';

export const BattleLevelUpPage = () => {
  useMusic(bgSounds.summon);

  return (
    <MainContainer>
      <PetLevelUp />
    </MainContainer>
  );
};
