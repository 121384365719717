import * as Sentry from '@sentry/react';

const ignoreErrors = [
  `undefined is not an object (evaluating 'window.TelegramGameProxy.receiveEvent')`,
  // connecting wallet and user aborted
  `Operation aborted`,
  `Aborted after attempts`,
  // user's network issue
  `installations/app-offline`,
  `Load failed`,
  `Java bridge method invocation error`,
  `Java object is gone`,
  `Connection to Indexed Database server lost`,
  `Failed to start the audio device`,
  `Failed to fetch dynamically imported module`,
  `auth/network-request-failed`,
];

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  // https://vercel.com/docs/frameworks/vite
  environment: import.meta.env.VITE_VERCEL_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.0001,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/dev-api\.genopets\.me/,
    /^https:\/\/api\.genopets\.me/,
  ],
  replaysOnErrorSampleRate:
    import.meta.env.VITE_VERCEL_ENV === `production` ? 0.01 : 0,
  ignoreErrors,
  beforeSend(event, hint) {
    // last place to listen for events/errors before they are sent to sentry
    // another layer of validation to ensure we don't send errors that should be ignored in case it's missed by sentry's ignoreErrors logic
    const errorFromOriginalException =
      typeof hint?.originalException === `string`
        ? hint?.originalException
        : (hint?.originalException as any)?.message;

    const errorFromEventObject = event.exception?.values?.[0];
    const errorMessageFromEvent = errorFromEventObject?.value;

    // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-828834651
    // filter out UnhandledRejection errors that have no information
    if (
      errorFromEventObject?.type === `UnhandledRejection` &&
      errorMessageFromEvent?.includes(
        `Non-Error promise rejection captured with value`,
      )
    ) {
      return null;
    }

    const capturedErrorMessage = (
      errorFromOriginalException || errorMessageFromEvent
    )?.toLowerCase();

    // early exit if no error message
    if (!capturedErrorMessage) return event;

    // find the error in case-insensitive format
    const shouldIgnoreError = ignoreErrors.find((ignoreErrorMessage: string) =>
      ignoreErrorMessage.toLowerCase().includes(capturedErrorMessage),
    );

    if (shouldIgnoreError) {
      // returning null will ignore this event/error
      return null;
    }

    // returning the event will capture the error
    return event;
  },
});
