import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { OKX_MANTLE_LEADERBOARD_END_DATE } from 'genopets-utils';

// time left for weekly airdrop
// export const useAirdropTimeLeft = () => {
//   const { amount } = useAirdropData();
//   const [timeLeft, setTimeLeft] = useState('00:00:00');
//
//   useEffect(() => {
//     if (amount) {
//       const interval = setInterval(() => {
//         const airdropTimeOverride = new Date(VITE_AIRDROP_TIME_OVERRIDE);
//         const now = DateTime.utc();
//
//         // weekly airdrop starts at the upcoming Monday UTC
//         const nextMonday = getStartOfNextWeek();
//
//         // Convert to UTC
//         const nextMondayUTC =
//           airdropTimeOverride.getTime() > nextMonday.toMillis()
//             ? DateTime.fromJSDate(airdropTimeOverride)
//             : nextMonday.toUTC();
//
//         const duration = nextMondayUTC.diff(now, [
//           'hours',
//           'minutes',
//           'seconds',
//         ]);
//
//         setTimeLeft(duration.toFormat('hh:mm:ss'));
//       });
//
//       return () => clearInterval(interval);
//     }
//   }, [amount]);
//
//   return timeLeft;
// };

// time left for static end date airdrop
export const useAirdropTimeLeft = () => {
  const [timeLeft, setTimeLeft] = useState('00:00:00');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = DateTime.utc();
      const endDate = DateTime.fromISO(OKX_MANTLE_LEADERBOARD_END_DATE, {
        zone: 'utc',
      });
      const duration = endDate.diff(now, ['hours', 'minutes', 'seconds']);

      if (duration.toMillis() < 0) {
        setTimeLeft('00:00:00');
      } else {
        setTimeLeft(duration.toFormat('hh:mm:ss'));
      }
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, []);

  return timeLeft;
};
