export const Trash = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      fill="none"
      viewBox="0 0 21 24"
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M2.31 24h16.38V7.774H2.31zm12.328-12.128v7.18h-1.094v-7.18zm-3.6 0v7.18H9.944v-7.18zm-3.6 0v7.18H6.344v-7.18z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M21 3.39H0v3.32h21zM13.157 0H7.844v2.225h5.313z"
      ></path>
    </svg>
  );
};
