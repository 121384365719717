import { ButtonProps } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { ColorId } from '../colors';
import { Button } from './Button';

const FileUploadButton = ({
  onFileChange,
  accept,
  ...props
}: {
  colorId?: ColorId;
  variant?: 'primary' | 'secondary';
  onFileChange: (file: File) => void;
  accept?: string;
} & ButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log('Selected file:', file.name);
      // You can handle the file upload or further processing here
      onFileChange(file);
    }
  };

  return (
    <>
      <Button onClick={handleClick} {...props}>
        Upload File
      </Button>
      <input
        type="file"
        accept={accept || 'image/*'}
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </>
  );
};

export default FileUploadButton;
