import { useState } from 'react';

/**
 * Custom hook for interacting with local storage.
 * NOTE: This hook maintains separate states for each usage instance.
 * As a result, changes made to a specific key in one component will not
 * be automatically reflected in another component using the same key.
 * If the key's value is updated in one component, the other component
 * won't detect the change unless re-rendered.
 *
 * @param {string} key - The key used for local storage.
 * @param {any} initialValue - The initial value to store if the key does not already exist.
 * @returns {[storedValue, setValue]} - The current value from local storage and a setter function to update it.
 */

function useLocalStorage<T>(key: string, initialValue: T) {
  // Get from local storage and parse stored JSON
  // or return the initial value if nothing is stored
  const readValue = () => {
    if (typeof window === 'undefined') {
      return initialValue; // Avoid SSR issues
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  };

  // State to store our value
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that stores the value in localStorage
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have the same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  return [storedValue, setValue] as const;
}

export default useLocalStorage;
