/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { BattleSetBreakMove } from '../../models/battle-action.entity';
import { BattleNextAction, BattleState } from '../../models/battle-state';
import {
  BattleEntity,
  BattleStatus,
  BattleType,
} from '../../models/battle.entity';
import { getPlayerData } from '../../utils/battle-utils';
import { selectMovesReducer } from './selectMoves';
import { updateGameStateReducer } from './updateGameState';
import { updatePlayerGameStateReducer } from './updatePlayerGameState';
import { useDodgeReducer } from './useDodge';
import { useItemReducer } from './useItem';
import { useMoveReducer } from './useMove';

const initialState = {
  nextAction: BattleNextAction.INIT,
  actions: [],
  history: [],
} as Partial<BattleState>;

// One player turn is compose of one action (useMove/useItem) and one potential block
const battleSlice = createSlice({
  name: `battle`,
  initialState,
  reducers: {
    resetBattle: () => initialState,
    useMove: useMoveReducer,
    useDodge: useDodgeReducer,
    useItem: useItemReducer,
    selectMoves: selectMovesReducer,
    updateGameState: updateGameStateReducer,
    updatePlayerGameState: updatePlayerGameStateReducer,
    multiAction: (state, action: PayloadAction<any[]>) => {
      let newState = cloneDeep(state) as BattleState;
      action.payload.forEach((act) => {
        switch (act.type) {
          case useMove.type:
            newState = useMoveReducer(newState, act);
            break;
          case useItem.type:
            newState = useItemReducer(newState, act);
            break;
          case useDodge.type:
            newState = useDodgeReducer(newState, act);
            break;
          case updateGameState.type:
            newState = updateGameStateReducer(newState, act);
            break;
          case updatePlayerGameState.type:
            newState = updatePlayerGameStateReducer(newState, act);
            break;
          case selectMoves.type:
            newState = selectMovesReducer(newState, act);
            break;

          default:
            throw new Error(`Unknown action type ${act.type}`);
        }
      });

      return newState;
    },
    uiEventPlayed: (state, action: PayloadAction<{ id: string }>) => {
      const newState = cloneDeep(state) as BattleState;
      if (newState.uiEvents) {
        newState.uiEvents = newState.uiEvents.filter(
          (e) => e.id !== action.payload.id,
        );
      }
      return newState;
    },
    undo: (state) => {
      if (!state.history?.length) {
        return state;
      }
      const prevState = state.history[state.history.length - 1];
      return { ...prevState, history: [...state.history.slice(0, -1)] };
    },
    initBattle: (
      state,
      action: PayloadAction<
        BattleEntity & { withHistory?: boolean; withUiEvents?: boolean }
      >,
    ) => {
      const newState = cloneDeep(state) as BattleState;
      if (state.nextAction !== BattleNextAction.INIT) {
        newState.error = `The battle have already been initialized`;
        return newState;
      }

      newState.player1 = {
        ...newState.player1,
        ...action.payload.player1,
      };

      newState.player2 = {
        ...newState.player2,
        ...action.payload.player2,
      };

      newState.id = action.payload.id;

      newState.elementalMoves = action.payload.elementalMoves;

      newState.nextPlayer = action.payload.startPlayer;

      newState.status =
        action.payload.player1 && action.payload.player2
          ? BattleStatus.ONGOING
          : BattleStatus.WAITING;
      newState.nextAction =
        // eslint-disable-next-line no-nested-ternary
        action.payload.player1 && action.payload.player2
          ? action.payload.type === BattleType.TELEGRAM
            ? BattleNextAction.PLAYER_CHOOSE
            : BattleNextAction.MOVE_SELECTION
          : BattleNextAction.INIT;

      if (
        newState.nextAction === BattleNextAction.PLAYER_CHOOSE &&
        action.payload.type === BattleType.TELEGRAM
      ) {
        newState.player1.moves = newState.player1.petInfo.moves;
        newState.player2.moves = newState.player2.petInfo.moves;
      }

      newState.withHistory = !!action.payload.withHistory;

      newState.withUiEvents = !!action.payload.withUiEvents;

      if (newState.withUiEvents) {
        newState.uiEvents = [];
      }

      return newState;
    },
    // break move can change in the battle according object used or action that the player takes
    // in case that should come from outside of an action this reducer is added
    setBreakMove: (state, action: PayloadAction<BattleSetBreakMove>) => {
      const newState = cloneDeep(state) as BattleState;
      const player = getPlayerData(newState, action.payload.playerId);
      player.break.move = action.payload.move;
      return newState;
    },
  },
});

export const {
  initBattle,
  resetBattle,
  useMove,
  useItem,
  useDodge,
  uiEventPlayed,
  selectMoves,
  updateGameState,
  updatePlayerGameState,
  undo,
  multiAction,
} = battleSlice.actions;

export type BattleAction =
  | typeof resetBattle.type
  | typeof useMove.type
  | typeof useItem.type
  | typeof useDodge.type
  | typeof uiEventPlayed.type
  | typeof updateGameState.type
  | typeof updatePlayerGameState.type
  | typeof selectMoves.type
  | typeof multiAction.type
  | typeof undo.type;

export default battleSlice.reducer;
