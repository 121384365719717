import {
  calculatePetLevelAfterNewXp,
  Pet,
  TELEGRAM_XP_CURVE,
} from 'genopets-utils';
import { useEffect, useState } from 'react';
import { LevelBar } from '../ui/molecules/LevelBar';

export const BattleLevelBar = ({
  energyRewarded,
  pet,
}: {
  energyRewarded: number;
  pet?: Pet;
}) => {
  const [to, setTo] = useState<string>('');
  const [from, setFrom] = useState<string>('');
  const [level, setLevel] = useState(1);

  useEffect(() => {
    if (pet && !to) {
      const { percentXpForLevel, levelUp, previousPercentXpForLevel } =
        calculatePetLevelAfterNewXp(
          TELEGRAM_XP_CURVE,
          pet,
          energyRewarded,
          true,
        );

      // show 100% xp if pet leveled up
      setTo(`${levelUp ? 100 : Math.min(100, percentXpForLevel)}%`);

      setFrom(previousPercentXpForLevel + '%');

      //freeze pet level
      setLevel(pet.telegramLevel);
    }
  }, [pet]);

  return <LevelBar from={from} to={to} level={level} />;
};
