export const HomeWheel = ({ ...props }) => {
  return (
    <svg
      width="64"
      height="63"
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.875 0.300781H30.1417V2.03411H31.875V0.300781Z"
        fill="black"
      />
      <path d="M31.875 2.0332H30.1417V3.76654H31.875V2.0332Z" fill="#F0D594" />
      <path
        d="M31.875 3.76758H30.1417V5.50091H31.875V3.76758Z"
        fill="#F0D594"
      />
      <path d="M31.875 5.5H30.1417V7.23333H31.875V5.5Z" fill="#F0D594" />
      <path
        d="M31.875 7.23438H30.1417V8.96771H31.875V7.23438Z"
        fill="#F0D594"
      />
      <path d="M31.875 8.9668H30.1417V10.7001H31.875V8.9668Z" fill="#F0D594" />
      <path
        d="M31.875 10.7012H30.1417V12.4345H31.875V10.7012Z"
        fill="#F0D594"
      />
      <path
        d="M31.875 12.4336H30.1417V14.1669H31.875V12.4336Z"
        fill="#F0D594"
      />
      <path d="M31.875 14.168H30.1417V15.9013H31.875V14.168Z" fill="black" />
      <path
        d="M31.875 15.9004H30.1417V17.6337H31.875V15.9004Z"
        fill="#292E3D"
      />
      <path d="M31.875 17.6348H30.1417V19.3681H31.875V17.6348Z" fill="black" />
      <path d="M31.875 19.3672H30.1417V21.1005H31.875V19.3672Z" fill="black" />
      <path d="M31.875 21.1016H30.1417V22.8349H31.875V21.1016Z" fill="black" />
      <path d="M31.875 22.834H30.1417V24.5673H31.875V22.834Z" fill="black" />
      <path d="M31.875 24.5684H30.1417V26.3017H31.875V24.5684Z" fill="black" />
      <path d="M31.875 26.3008H30.1417V28.0341H31.875V26.3008Z" fill="black" />
      <path d="M31.875 28.0332H30.1417V29.7665H31.875V28.0332Z" fill="black" />
      <path d="M31.875 29.7676H30.1417V31.5009H31.875V29.7676Z" fill="black" />
      <path d="M31.875 60.9668H30.1417V62.7001H31.875V60.9668Z" fill="black" />
      <path
        d="M31.875 59.2344H30.1417V60.9677H31.875V59.2344Z"
        fill="#6BECD4"
      />
      <path d="M31.875 57.5H30.1417V59.2333H31.875V57.5Z" fill="#6BECD4" />
      <path
        d="M31.875 55.7676H30.1417V57.5009H31.875V55.7676Z"
        fill="#6BECD4"
      />
      <path
        d="M31.875 54.0332H30.1417V55.7665H31.875V54.0332Z"
        fill="#6BECD4"
      />
      <path
        d="M31.875 52.3008H30.1417V54.0341H31.875V52.3008Z"
        fill="#6BECD4"
      />
      <path
        d="M31.875 50.5684H30.1417V52.3017H31.875V50.5684Z"
        fill="#6BECD4"
      />
      <path d="M31.875 48.834H30.1417V50.5673H31.875V48.834Z" fill="#6BECD4" />
      <path
        d="M31.875 47.1016H30.1417V48.8349H31.875V47.1016Z"
        fill="#6BECD4"
      />
      <path d="M31.875 45.3672H30.1417V47.1005H31.875V45.3672Z" fill="black" />
      <path d="M31.875 43.6348H30.1417V45.3681H31.875V43.6348Z" fill="black" />
      <path d="M31.875 41.9004H30.1417V43.6337H31.875V41.9004Z" fill="black" />
      <path d="M31.875 40.168H30.1417V41.9013H31.875V40.168Z" fill="black" />
      <path d="M31.875 38.4336H30.1417V40.1669H31.875V38.4336Z" fill="black" />
      <path d="M31.875 36.7012H30.1417V38.4345H31.875V36.7012Z" fill="black" />
      <path d="M31.875 34.9668H30.1417V36.7001H31.875V34.9668Z" fill="black" />
      <path d="M31.875 33.2344H30.1417V34.9677H31.875V33.2344Z" fill="black" />
      <path d="M31.875 31.5H30.1417V33.2333H31.875V31.5Z" fill="black" />
      <path
        d="M33.6083 0.300781H31.875V2.03411H33.6083V0.300781Z"
        fill="black"
      />
      <path
        d="M35.3417 0.300781H33.6083V2.03411H35.3417V0.300781Z"
        fill="black"
      />
      <path
        d="M37.075 0.300781H35.3416V2.03411H37.075V0.300781Z"
        fill="black"
      />
      <path
        d="M38.8083 0.300781H37.075V2.03411H38.8083V0.300781Z"
        fill="black"
      />
      <path
        d="M40.5417 0.300781H38.8083V2.03411H40.5417V0.300781Z"
        fill="black"
      />
      <path d="M33.6083 2.0332H31.875V3.76654H33.6083V2.0332Z" fill="#FF9CEC" />
      <path
        d="M35.3417 2.0332H33.6083V3.76654H35.3417V2.0332Z"
        fill="#FF9CEC"
      />
      <path d="M37.075 2.0332H35.3416V3.76654H37.075V2.0332Z" fill="#FF9CEC" />
      <path d="M38.8083 2.0332H37.075V3.76654H38.8083V2.0332Z" fill="#FF9CEC" />
      <path
        d="M40.5417 2.0332H38.8083V3.76654H40.5417V2.0332Z"
        fill="#FF9CEC"
      />
      <path d="M42.275 2.0332H40.5417V3.76654H42.275V2.0332Z" fill="#FF9CEC" />
      <path
        d="M42.275 0.300781H40.5417V2.03411H42.275V0.300781Z"
        fill="black"
      />
      <path d="M44.0083 2.0332H42.275V3.76654H44.0083V2.0332Z" fill="black" />
      <path d="M45.7417 2.0332H44.0083V3.76654H45.7417V2.0332Z" fill="black" />
      <path
        d="M33.6083 3.76758H31.875V5.50091H33.6083V3.76758Z"
        fill="#FF9CEC"
      />
      <path
        d="M35.3417 3.76758H33.6083V5.50091H35.3417V3.76758Z"
        fill="#F8B6EA"
      />
      <path
        d="M37.075 3.76758H35.3416V5.50091H37.075V3.76758Z"
        fill="#F8B6EA"
      />
      <path
        d="M38.8083 3.76758H37.075V5.50091H38.8083V3.76758Z"
        fill="#F8B6EA"
      />
      <path
        d="M40.5417 3.76758H38.8083V5.50091H40.5417V3.76758Z"
        fill="#F8B6EA"
      />
      <path
        d="M42.275 3.76758H40.5417V5.50091H42.275V3.76758Z"
        fill="#F8B6EA"
      />
      <path
        d="M44.0083 3.76758H42.275V5.50091H44.0083V3.76758Z"
        fill="#FF9CEC"
      />
      <path
        d="M45.7417 3.76758H44.0083V5.50091H45.7417V3.76758Z"
        fill="#FF9CEC"
      />
      <path d="M47.475 3.76758H45.7416V5.50091H47.475V3.76758Z" fill="black" />
      <path d="M49.2083 3.76758H47.475V5.50091H49.2083V3.76758Z" fill="black" />
      <path d="M33.6083 5.5H31.875V7.23333H33.6083V5.5Z" fill="#FF9CEC" />
      <path d="M35.3417 5.5H33.6083V7.23333H35.3417V5.5Z" fill="#F8B6EA" />
      <path d="M37.075 5.5H35.3416V7.23333H37.075V5.5Z" fill="#F8B6EA" />
      <path d="M38.8083 5.5H37.075V7.23333H38.8083V5.5Z" fill="#F8B6EA" />
      <path d="M40.5417 5.5H38.8083V7.23333H40.5417V5.5Z" fill="#F8B6EA" />
      <path d="M42.275 5.5H40.5417V7.23333H42.275V5.5Z" fill="#F8B6EA" />
      <path d="M44.0083 5.5H42.275V7.23333H44.0083V5.5Z" fill="#F8B6EA" />
      <path d="M45.7417 5.5H44.0083V7.23333H45.7417V5.5Z" fill="#F8B6EA" />
      <path d="M47.475 5.5H45.7416V7.23333H47.475V5.5Z" fill="#FF9CEC" />
      <path d="M49.2083 5.5H47.475V7.23333H49.2083V5.5Z" fill="black" />
      <path d="M50.9416 5.5H49.2083V7.23333H50.9416V5.5Z" fill="black" />
      <path
        d="M33.6083 7.23438H31.875V8.96771H33.6083V7.23438Z"
        fill="#FF9CEC"
      />
      <path
        d="M35.3417 7.23438H33.6083V8.96771H35.3417V7.23438Z"
        fill="#F8B6EA"
      />
      <path
        d="M37.075 7.23438H35.3416V8.96771H37.075V7.23438Z"
        fill="#F8B6EA"
      />
      <path
        d="M38.8083 7.23438H37.075V8.96771H38.8083V7.23438Z"
        fill="#F8B6EA"
      />
      <path
        d="M40.5417 7.23438H38.8083V8.96771H40.5417V7.23438Z"
        fill="#F8B6EA"
      />
      <path
        d="M42.275 7.23438H40.5417V8.96771H42.275V7.23438Z"
        fill="#F8B6EA"
      />
      <path
        d="M44.0083 7.23438H42.275V8.96771H44.0083V7.23438Z"
        fill="#F8B6EA"
      />
      <path
        d="M45.7417 7.23438H44.0083V8.96771H45.7417V7.23438Z"
        fill="#F8B6EA"
      />
      <path
        d="M47.475 7.23438H45.7416V8.96771H47.475V7.23438Z"
        fill="#F8B6EA"
      />
      <path
        d="M49.2083 7.23438H47.475V8.96771H49.2083V7.23438Z"
        fill="#FF9CEC"
      />
      <path
        d="M50.9416 7.23438H49.2083V8.96771H50.9416V7.23438Z"
        fill="#FF9CEC"
      />
      <path d="M52.675 7.23438H50.9417V8.96771H52.675V7.23438Z" fill="black" />
      <path d="M54.4083 7.23438H52.675V8.96771H54.4083V7.23438Z" fill="black" />
      <path d="M33.6083 8.9668H31.875V10.7001H33.6083V8.9668Z" fill="#FF9CEC" />
      <path
        d="M35.3417 8.9668H33.6083V10.7001H35.3417V8.9668Z"
        fill="#F8B6EA"
      />
      <path d="M37.075 8.9668H35.3416V10.7001H37.075V8.9668Z" fill="#F8B6EA" />
      <path d="M38.8083 8.9668H37.075V10.7001H38.8083V8.9668Z" fill="#F8B6EA" />
      <path
        d="M40.5417 8.9668H38.8083V10.7001H40.5417V8.9668Z"
        fill="#F8B6EA"
      />
      <path d="M42.275 8.9668H40.5417V10.7001H42.275V8.9668Z" fill="#F8B6EA" />
      <path d="M44.0083 8.9668H42.275V10.7001H44.0083V8.9668Z" fill="#F8B6EA" />
      <path d="M49.2083 8.9668H47.475V10.7001H49.2083V8.9668Z" fill="#F8B6EA" />
      <path
        d="M50.9416 8.9668H49.2083V10.7001H50.9416V8.9668Z"
        fill="#F8B6EA"
      />
      <path d="M52.675 8.9668H50.9417V10.7001H52.675V8.9668Z" fill="#FF9CEC" />
      <path d="M54.4083 8.9668H52.675V10.7001H54.4083V8.9668Z" fill="#FF9CEC" />
      <path d="M56.1417 8.9668H54.4083V10.7001H56.1417V8.9668Z" fill="black" />
      <path
        d="M33.6083 10.7012H31.875V12.4345H33.6083V10.7012Z"
        fill="#FF9CEC"
      />
      <path
        d="M35.3417 10.7012H33.6083V12.4345H35.3417V10.7012Z"
        fill="#F8B6EA"
      />
      <path
        d="M37.075 10.7012H35.3416V12.4345H37.075V10.7012Z"
        fill="#F8B6EA"
      />
      <path
        d="M38.8083 10.7012H37.075V12.4345H38.8083V10.7012Z"
        fill="#F8B6EA"
      />
      <path
        d="M40.5417 10.7012H38.8083V12.4345H40.5417V10.7012Z"
        fill="#F8B6EA"
      />
      <path
        d="M42.275 10.7012H40.5417V12.4345H42.275V10.7012Z"
        fill="#F8B6EA"
      />
      <path
        d="M47.475 10.7012H45.7416V12.4345H47.475V10.7012Z"
        fill="#F8B6EA"
      />
      <path
        d="M49.2083 10.7012H47.475V12.4345H49.2083V10.7012Z"
        fill="#F8B6EA"
      />
      <path
        d="M50.9416 10.7012H49.2083V12.4345H50.9416V10.7012Z"
        fill="#F8B6EA"
      />
      <path
        d="M52.675 10.7012H50.9417V12.4345H52.675V10.7012Z"
        fill="#F8B6EA"
      />
      <path
        d="M54.4083 10.7012H52.675V12.4345H54.4083V10.7012Z"
        fill="#F8B6EA"
      />
      <path
        d="M56.1417 10.7012H54.4083V12.4345H56.1417V10.7012Z"
        fill="#FF9CEC"
      />
      <path d="M57.875 10.7012H56.1417V12.4345H57.875V10.7012Z" fill="black" />
      <path
        d="M33.6083 12.4336H31.875V14.1669H33.6083V12.4336Z"
        fill="#FF9CEC"
      />
      <path
        d="M35.3417 12.4336H33.6083V14.1669H35.3417V12.4336Z"
        fill="#F8B6EA"
      />
      <path
        d="M37.075 12.4336H35.3416V14.1669H37.075V12.4336Z"
        fill="#F8B6EA"
      />
      <path
        d="M38.8083 12.4336H37.075V14.1669H38.8083V12.4336Z"
        fill="#F8B6EA"
      />
      <path
        d="M40.5417 12.4336H38.8083V14.1669H40.5417V12.4336Z"
        fill="#F8B6EA"
      />
      <path
        d="M42.275 12.4336H40.5417V14.1669H42.275V12.4336Z"
        fill="#F8B6EA"
      />
      <path
        d="M44.0083 12.4336H42.275V14.1669H44.0083V12.4336Z"
        fill="#F8B6EA"
      />
      <path
        d="M49.2083 12.4336H47.475V14.1669H49.2083V12.4336Z"
        fill="#F8B6EA"
      />
      <path
        d="M50.9416 12.4336H49.2083V14.1669H50.9416V12.4336Z"
        fill="#F8B6EA"
      />
      <path
        d="M52.675 12.4336H50.9417V14.1669H52.675V12.4336Z"
        fill="#F8B6EA"
      />
      <path
        d="M54.4083 12.4336H52.675V14.1669H54.4083V12.4336Z"
        fill="#F8B6EA"
      />
      <path
        d="M56.1417 12.4336H54.4083V14.1669H56.1417V12.4336Z"
        fill="#FF9CEC"
      />
      <path d="M57.875 12.4336H56.1417V14.1669H57.875V12.4336Z" fill="black" />
      <path d="M33.6083 14.168H31.875V15.9013H33.6083V14.168Z" fill="black" />
      <path
        d="M35.3417 14.168H33.6083V15.9013H35.3417V14.168Z"
        fill="#F8B6EA"
      />
      <path d="M37.075 14.168H35.3416V15.9013H37.075V14.168Z" fill="#F8B6EA" />
      <path d="M38.8083 14.168H37.075V15.9013H38.8083V14.168Z" fill="#F8B6EA" />
      <path
        d="M40.5417 14.168H38.8083V15.9013H40.5417V14.168Z"
        fill="#F8B6EA"
      />
      <path d="M42.275 14.168H40.5417V15.9013H42.275V14.168Z" fill="#F8B6EA" />
      <path
        d="M45.7417 8.9668H44.0083V10.7001H45.7417V8.9668Z"
        fill="#F8B6EA"
      />
      <path d="M47.475 8.9668H45.7416V10.7001H47.475V8.9668Z" fill="#F8B6EA" />
      <path
        d="M44.0083 10.7012H42.275V12.4345H44.0083V10.7012Z"
        fill="#F8B6EA"
      />
      <path
        d="M45.7417 10.7012H44.0083V12.4345H45.7417V10.7012Z"
        fill="#F8B6EA"
      />
      <path
        d="M45.7417 12.4336H44.0083V14.1669H45.7417V12.4336Z"
        fill="#F8B6EA"
      />
      <path
        d="M47.475 12.4336H45.7416V14.1669H47.475V12.4336Z"
        fill="#F8B6EA"
      />
      <path d="M44.0083 14.168H42.275V15.9013H44.0083V14.168Z" fill="#F8B6EA" />
      <path
        d="M45.7417 14.168H44.0083V15.9013H45.7417V14.168Z"
        fill="#F8B6EA"
      />
      <path d="M47.475 14.168H45.7416V15.9013H47.475V14.168Z" fill="#F8B6EA" />
      <path d="M49.2083 14.168H47.475V15.9013H49.2083V14.168Z" fill="#F8B6EA" />
      <path
        d="M50.9416 14.168H49.2083V15.9013H50.9416V14.168Z"
        fill="#F8B6EA"
      />
      <path d="M52.675 14.168H50.9417V15.9013H52.675V14.168Z" fill="#F8B6EA" />
      <path d="M54.4083 14.168H52.675V15.9013H54.4083V14.168Z" fill="#F8B6EA" />
      <path
        d="M56.1417 14.168H54.4083V15.9013H56.1417V14.168Z"
        fill="#F8B6EA"
      />
      <path d="M57.875 14.168H56.1417V15.9013H57.875V14.168Z" fill="#FF9CEC" />
      <path d="M59.6083 14.168H57.875V15.9013H59.6083V14.168Z" fill="black" />
      <path d="M33.6083 15.9004H31.875V17.6337H33.6083V15.9004Z" fill="black" />
      <path
        d="M35.3417 15.9004H33.6083V17.6337H35.3417V15.9004Z"
        fill="black"
      />
      <path
        d="M37.075 15.9004H35.3416V17.6337H37.075V15.9004Z"
        fill="#FF9CEC"
      />
      <path
        d="M38.8083 15.9004H37.075V17.6337H38.8083V15.9004Z"
        fill="#FF9CEC"
      />
      <path
        d="M40.5417 15.9004H38.8083V17.6337H40.5417V15.9004Z"
        fill="#F8B6EA"
      />
      <path
        d="M42.275 15.9004H40.5417V17.6337H42.275V15.9004Z"
        fill="#F8B6EA"
      />
      <path
        d="M44.0083 15.9004H42.275V17.6337H44.0083V15.9004Z"
        fill="#F8B6EA"
      />
      <path
        d="M45.7417 15.9004H44.0083V17.6337H45.7417V15.9004Z"
        fill="#F8B6EA"
      />
      <path
        d="M47.475 15.9004H45.7416V17.6337H47.475V15.9004Z"
        fill="#F8B6EA"
      />
      <path
        d="M49.2083 15.9004H47.475V17.6337H49.2083V15.9004Z"
        fill="#F8B6EA"
      />
      <path
        d="M50.9416 15.9004H49.2083V17.6337H50.9416V15.9004Z"
        fill="#F8B6EA"
      />
      <path
        d="M52.675 15.9004H50.9417V17.6337H52.675V15.9004Z"
        fill="#F8B6EA"
      />
      <path
        d="M54.4083 15.9004H52.675V17.6337H54.4083V15.9004Z"
        fill="#F8B6EA"
      />
      <path
        d="M56.1417 15.9004H54.4083V17.6337H56.1417V15.9004Z"
        fill="#F8B6EA"
      />
      <path
        d="M57.875 15.9004H56.1417V17.6337H57.875V15.9004Z"
        fill="#FF9CEC"
      />
      <path d="M59.6083 15.9004H57.875V17.6337H59.6083V15.9004Z" fill="black" />
      <path
        d="M61.3417 15.9004H59.6083V17.6337H61.3417V15.9004Z"
        fill="black"
      />
      <path d="M33.6083 17.6348H31.875V19.3681H33.6083V17.6348Z" fill="black" />
      <path
        d="M35.3417 17.6348H33.6083V19.3681H35.3417V17.6348Z"
        fill="black"
      />
      <path d="M37.075 17.6348H35.3416V19.3681H37.075V17.6348Z" fill="black" />
      <path d="M38.8083 17.6348H37.075V19.3681H38.8083V17.6348Z" fill="black" />
      <path
        d="M40.5417 17.6348H38.8083V19.3681H40.5417V17.6348Z"
        fill="#FF9CEC"
      />
      <path
        d="M42.275 17.6348H40.5417V19.3681H42.275V17.6348Z"
        fill="#FF9CEC"
      />
      <path
        d="M44.0083 17.6348H42.275V19.3681H44.0083V17.6348Z"
        fill="#F8B6EA"
      />
      <path
        d="M45.7417 17.6348H44.0083V19.3681H45.7417V17.6348Z"
        fill="#F8B6EA"
      />
      <path
        d="M47.475 17.6348H45.7416V19.3681H47.475V17.6348Z"
        fill="#F8B6EA"
      />
      <path
        d="M49.2083 17.6348H47.475V19.3681H49.2083V17.6348Z"
        fill="#F8B6EA"
      />
      <path
        d="M50.9416 17.6348H49.2083V19.3681H50.9416V17.6348Z"
        fill="#F8B6EA"
      />
      <path
        d="M52.675 17.6348H50.9417V19.3681H52.675V17.6348Z"
        fill="#F8B6EA"
      />
      <path
        d="M54.4083 17.6348H52.675V19.3681H54.4083V17.6348Z"
        fill="#FF9CEC"
      />
      <path
        d="M56.1417 17.6348H54.4083V19.3681H56.1417V17.6348Z"
        fill="#FF9CEC"
      />
      <path
        d="M57.875 17.6348H56.1417V19.3681H57.875V17.6348Z"
        fill="#7487CB"
      />
      <path
        d="M59.6083 17.6348H57.875V19.3681H59.6083V17.6348Z"
        fill="#7487CB"
      />
      <path
        d="M61.3417 17.6348H59.6083V19.3681H61.3417V17.6348Z"
        fill="black"
      />
      <path d="M33.6083 19.3672H31.875V21.1005H33.6083V19.3672Z" fill="black" />
      <path
        d="M35.3417 19.3672H33.6083V21.1005H35.3417V19.3672Z"
        fill="black"
      />
      <path d="M37.075 19.3672H35.3416V21.1005H37.075V19.3672Z" fill="black" />
      <path d="M38.8083 19.3672H37.075V21.1005H38.8083V19.3672Z" fill="black" />
      <path
        d="M40.5417 19.3672H38.8083V21.1005H40.5417V19.3672Z"
        fill="black"
      />
      <path d="M42.275 19.3672H40.5417V21.1005H42.275V19.3672Z" fill="black" />
      <path
        d="M44.0083 19.3672H42.275V21.1005H44.0083V19.3672Z"
        fill="#FF9CEC"
      />
      <path
        d="M45.7417 19.3672H44.0083V21.1005H45.7417V19.3672Z"
        fill="#F8B6EA"
      />
      <path
        d="M47.475 19.3672H45.7416V21.1005H47.475V19.3672Z"
        fill="#F8B6EA"
      />
      <path
        d="M49.2083 19.3672H47.475V21.1005H49.2083V19.3672Z"
        fill="#F8B6EA"
      />
      <path
        d="M50.9416 19.3672H49.2083V21.1005H50.9416V19.3672Z"
        fill="#FF9CEC"
      />
      <path
        d="M52.675 19.3672H50.9417V21.1005H52.675V19.3672Z"
        fill="#FF9CEC"
      />
      <path
        d="M54.4083 19.3672H52.675V21.1005H54.4083V19.3672Z"
        fill="#7487CB"
      />
      <path
        d="M56.1417 19.3672H54.4083V21.1005H56.1417V19.3672Z"
        fill="#7487CB"
      />
      <path
        d="M57.875 19.3672H56.1417V21.1005H57.875V19.3672Z"
        fill="#91A8FF"
      />
      <path
        d="M59.6083 19.3672H57.875V21.1005H59.6083V19.3672Z"
        fill="#91A8FF"
      />
      <path
        d="M61.3417 19.3672H59.6083V21.1005H61.3417V19.3672Z"
        fill="#7487CB"
      />
      <path d="M63.075 19.3672H61.3416V21.1005H63.075V19.3672Z" fill="black" />
      <path d="M33.6083 21.1016H31.875V22.8349H33.6083V21.1016Z" fill="black" />
      <path
        d="M35.3417 21.1016H33.6083V22.8349H35.3417V21.1016Z"
        fill="black"
      />
      <path d="M37.075 21.1016H35.3416V22.8349H37.075V21.1016Z" fill="black" />
      <path d="M38.8083 21.1016H37.075V22.8349H38.8083V21.1016Z" fill="black" />
      <path
        d="M40.5417 21.1016H38.8083V22.8349H40.5417V21.1016Z"
        fill="black"
      />
      <path d="M42.275 21.1016H40.5417V22.8349H42.275V21.1016Z" fill="black" />
      <path d="M44.0083 21.1016H42.275V22.8349H44.0083V21.1016Z" fill="black" />
      <path
        d="M45.7417 21.1016H44.0083V22.8349H45.7417V21.1016Z"
        fill="#FF9CEC"
      />
      <path
        d="M47.475 21.1016H45.7416V22.8349H47.475V21.1016Z"
        fill="#FF9CEC"
      />
      <path
        d="M49.2083 21.1016H47.475V22.8349H49.2083V21.1016Z"
        fill="#FF9CEC"
      />
      <path
        d="M50.9416 21.1016H49.2083V22.8349H50.9416V21.1016Z"
        fill="#7487CB"
      />
      <path
        d="M52.675 21.1016H50.9417V22.8349H52.675V21.1016Z"
        fill="#7487CB"
      />
      <path
        d="M54.4083 21.1016H52.675V22.8349H54.4083V21.1016Z"
        fill="#91A8FF"
      />
      <path
        d="M56.1417 21.1016H54.4083V22.8349H56.1417V21.1016Z"
        fill="#91A8FF"
      />
      <path
        d="M57.875 21.1016H56.1417V22.8349H57.875V21.1016Z"
        fill="#91A8FF"
      />
      <path
        d="M59.6083 21.1016H57.875V22.8349H59.6083V21.1016Z"
        fill="#91A8FF"
      />
      <path
        d="M61.3417 21.1016H59.6083V22.8349H61.3417V21.1016Z"
        fill="#7487CB"
      />
      <path d="M63.075 21.1016H61.3416V22.8349H63.075V21.1016Z" fill="black" />
      <path d="M33.6083 22.834H31.875V24.5673H33.6083V22.834Z" fill="black" />
      <path d="M35.3417 22.834H33.6083V24.5673H35.3417V22.834Z" fill="black" />
      <path d="M37.075 22.834H35.3416V24.5673H37.075V22.834Z" fill="black" />
      <path d="M38.8083 22.834H37.075V24.5673H38.8083V22.834Z" fill="black" />
      <path d="M40.5417 22.834H38.8083V24.5673H40.5417V22.834Z" fill="black" />
      <path d="M42.275 22.834H40.5417V24.5673H42.275V22.834Z" fill="black" />
      <path d="M44.0083 22.834H42.275V24.5673H44.0083V22.834Z" fill="black" />
      <path d="M45.7417 22.834H44.0083V24.5673H45.7417V22.834Z" fill="black" />
      <path d="M47.475 22.834H45.7416V24.5673H47.475V22.834Z" fill="#7487CB" />
      <path d="M49.2083 22.834H47.475V24.5673H49.2083V22.834Z" fill="#7487CB" />
      <path
        d="M50.9416 22.834H49.2083V24.5673H50.9416V22.834Z"
        fill="#91A8FF"
      />
      <path d="M52.675 22.834H50.9417V24.5673H52.675V22.834Z" fill="#91A8FF" />
      <path d="M54.4083 22.834H52.675V24.5673H54.4083V22.834Z" fill="#91A8FF" />
      <path
        d="M56.1417 22.834H54.4083V24.5673H56.1417V22.834Z"
        fill="#91A8FF"
      />
      <path d="M57.875 22.834H56.1417V24.5673H57.875V22.834Z" fill="#91A8FF" />
      <path d="M59.6083 22.834H57.875V24.5673H59.6083V22.834Z" fill="#91A8FF" />
      <path
        d="M61.3417 22.834H59.6083V24.5673H61.3417V22.834Z"
        fill="#7487CB"
      />
      <path d="M63.075 22.834H61.3416V24.5673H63.075V22.834Z" fill="black" />
      <path d="M33.6083 24.5684H31.875V26.3017H33.6083V24.5684Z" fill="black" />
      <path
        d="M35.3417 24.5684H33.6083V26.3017H35.3417V24.5684Z"
        fill="black"
      />
      <path d="M37.075 24.5684H35.3416V26.3017H37.075V24.5684Z" fill="black" />
      <path d="M38.8083 24.5684H37.075V26.3017H38.8083V24.5684Z" fill="black" />
      <path
        d="M40.5417 24.5684H38.8083V26.3017H40.5417V24.5684Z"
        fill="black"
      />
      <path d="M42.275 24.5684H40.5417V26.3017H42.275V24.5684Z" fill="black" />
      <path d="M44.0083 24.5684H42.275V26.3017H44.0083V24.5684Z" fill="black" />
      <path
        d="M45.7417 24.5684H44.0083V26.3017H45.7417V24.5684Z"
        fill="black"
      />
      <path
        d="M47.475 24.5684H45.7416V26.3017H47.475V24.5684Z"
        fill="#7487CB"
      />
      <path
        d="M49.2083 24.5684H47.475V26.3017H49.2083V24.5684Z"
        fill="#91A8FF"
      />
      <path
        d="M50.9416 24.5684H49.2083V26.3017H50.9416V24.5684Z"
        fill="#91A8FF"
      />
      <path
        d="M52.675 24.5684H50.9417V26.3017H52.675V24.5684Z"
        fill="#91A8FF"
      />
      <path
        d="M54.4083 24.5684H52.675V26.3017H54.4083V24.5684Z"
        fill="#91A8FF"
      />
      <path
        d="M56.1417 24.5684H54.4083V26.3017H56.1417V24.5684Z"
        fill="#91A8FF"
      />
      <path
        d="M57.875 24.5684H56.1417V26.3017H57.875V24.5684Z"
        fill="#91A8FF"
      />
      <path
        d="M59.6083 24.5684H57.875V26.3017H59.6083V24.5684Z"
        fill="#91A8FF"
      />
      <path
        d="M61.3417 24.5684H59.6083V26.3017H61.3417V24.5684Z"
        fill="#7487CB"
      />
      <path d="M63.075 24.5684H61.3416V26.3017H63.075V24.5684Z" fill="black" />
      <path d="M33.6083 26.3008H31.875V28.0341H33.6083V26.3008Z" fill="black" />
      <path
        d="M35.3417 26.3008H33.6083V28.0341H35.3417V26.3008Z"
        fill="black"
      />
      <path d="M37.075 26.3008H35.3416V28.0341H37.075V26.3008Z" fill="black" />
      <path d="M38.8083 26.3008H37.075V28.0341H38.8083V26.3008Z" fill="black" />
      <path
        d="M40.5417 26.3008H38.8083V28.0341H40.5417V26.3008Z"
        fill="black"
      />
      <path d="M42.275 26.3008H40.5417V28.0341H42.275V26.3008Z" fill="black" />
      <path d="M44.0083 26.3008H42.275V28.0341H44.0083V26.3008Z" fill="black" />
      <path
        d="M45.7417 26.3008H44.0083V28.0341H45.7417V26.3008Z"
        fill="black"
      />
      <path d="M47.475 26.3008H45.7416V28.0341H47.475V26.3008Z" fill="black" />
      <path
        d="M49.2083 26.3008H47.475V28.0341H49.2083V26.3008Z"
        fill="#91A8FF"
      />
      <path
        d="M50.9416 26.3008H49.2083V28.0341H50.9416V26.3008Z"
        fill="#91A8FF"
      />
      <path
        d="M52.675 26.3008H50.9417V28.0341H52.675V26.3008Z"
        fill="#91A8FF"
      />
      <path
        d="M54.4083 26.3008H52.675V28.0341H54.4083V26.3008Z"
        fill="#91A8FF"
      />
      <path
        d="M56.1417 26.3008H54.4083V28.0341H56.1417V26.3008Z"
        fill="#91A8FF"
      />
      <path
        d="M57.875 26.3008H56.1417V28.0341H57.875V26.3008Z"
        fill="#91A8FF"
      />
      <path
        d="M59.6083 26.3008H57.875V28.0341H59.6083V26.3008Z"
        fill="#91A8FF"
      />
      <path
        d="M61.3417 26.3008H59.6083V28.0341H61.3417V26.3008Z"
        fill="#7487CB"
      />
      <path d="M63.075 26.3008H61.3416V28.0341H63.075V26.3008Z" fill="black" />
      <path d="M33.6083 28.0332H31.875V29.7665H33.6083V28.0332Z" fill="black" />
      <path
        d="M35.3417 28.0332H33.6083V29.7665H35.3417V28.0332Z"
        fill="black"
      />
      <path d="M37.075 28.0332H35.3416V29.7665H37.075V28.0332Z" fill="black" />
      <path d="M38.8083 28.0332H37.075V29.7665H38.8083V28.0332Z" fill="black" />
      <path
        d="M40.5417 28.0332H38.8083V29.7665H40.5417V28.0332Z"
        fill="black"
      />
      <path d="M42.275 28.0332H40.5417V29.7665H42.275V28.0332Z" fill="black" />
      <path d="M44.0083 28.0332H42.275V29.7665H44.0083V28.0332Z" fill="black" />
      <path
        d="M45.7417 28.0332H44.0083V29.7665H45.7417V28.0332Z"
        fill="black"
      />
      <path d="M47.475 28.0332H45.7416V29.7665H47.475V28.0332Z" fill="black" />
      <path
        d="M49.2083 28.0332H47.475V29.7665H49.2083V28.0332Z"
        fill="#91A8FF"
      />
      <path
        d="M50.9416 28.0332H49.2083V29.7665H50.9416V28.0332Z"
        fill="#91A8FF"
      />
      <path
        d="M52.675 28.0332H50.9417V29.7665H52.675V28.0332Z"
        fill="#91A8FF"
      />
      <path
        d="M54.4083 28.0332H52.675V29.7665H54.4083V28.0332Z"
        fill="#91A8FF"
      />
      <path
        d="M56.1417 28.0332H54.4083V29.7665H56.1417V28.0332Z"
        fill="#91A8FF"
      />
      <path
        d="M57.875 28.0332H56.1417V29.7665H57.875V28.0332Z"
        fill="#91A8FF"
      />
      <path
        d="M59.6083 28.0332H57.875V29.7665H59.6083V28.0332Z"
        fill="#91A8FF"
      />
      <path
        d="M61.3417 28.0332H59.6083V29.7665H61.3417V28.0332Z"
        fill="#7487CB"
      />
      <path d="M63.075 28.0332H61.3416V29.7665H63.075V28.0332Z" fill="black" />
      <path d="M33.6083 29.7676H31.875V31.5009H33.6083V29.7676Z" fill="black" />
      <path
        d="M35.3417 29.7676H33.6083V31.5009H35.3417V29.7676Z"
        fill="black"
      />
      <path d="M37.075 29.7676H35.3416V31.5009H37.075V29.7676Z" fill="black" />
      <path d="M38.8083 29.7676H37.075V31.5009H38.8083V29.7676Z" fill="black" />
      <path
        d="M40.5417 29.7676H38.8083V31.5009H40.5417V29.7676Z"
        fill="black"
      />
      <path d="M42.275 29.7676H40.5417V31.5009H42.275V29.7676Z" fill="black" />
      <path d="M44.0083 29.7676H42.275V31.5009H44.0083V29.7676Z" fill="black" />
      <path
        d="M45.7417 29.7676H44.0083V31.5009H45.7417V29.7676Z"
        fill="black"
      />
      <path d="M47.475 29.7676H45.7416V31.5009H47.475V29.7676Z" fill="black" />
      <path
        d="M49.2083 29.7676H47.475V31.5009H49.2083V29.7676Z"
        fill="#91A8FF"
      />
      <path
        d="M50.9416 29.7676H49.2083V31.5009H50.9416V29.7676Z"
        fill="#91A8FF"
      />
      <path
        d="M52.675 29.7676H50.9417V31.5009H52.675V29.7676Z"
        fill="#91A8FF"
      />
      <path
        d="M54.4083 29.7676H52.675V31.5009H54.4083V29.7676Z"
        fill="#91A8FF"
      />
      <path
        d="M56.1417 29.7676H54.4083V31.5009H56.1417V29.7676Z"
        fill="#91A8FF"
      />
      <path
        d="M57.875 29.7676H56.1417V31.5009H57.875V29.7676Z"
        fill="#91A8FF"
      />
      <path
        d="M59.6083 29.7676H57.875V31.5009H59.6083V29.7676Z"
        fill="#91A8FF"
      />
      <path
        d="M61.3417 29.7676H59.6083V31.5009H61.3417V29.7676Z"
        fill="#7487CB"
      />
      <path d="M63.075 29.7676H61.3416V31.5009H63.075V29.7676Z" fill="black" />
      <path d="M33.6083 60.9668H31.875V62.7001H33.6083V60.9668Z" fill="black" />
      <path
        d="M35.3417 60.9668H33.6083V62.7001H35.3417V60.9668Z"
        fill="black"
      />
      <path d="M37.075 60.9668H35.3416V62.7001H37.075V60.9668Z" fill="black" />
      <path d="M38.8083 60.9668H37.075V62.7001H38.8083V60.9668Z" fill="black" />
      <path
        d="M40.5417 60.9668H38.8083V62.7001H40.5417V60.9668Z"
        fill="black"
      />
      <path
        d="M33.6083 59.2344H31.875V60.9677H33.6083V59.2344Z"
        fill="#B8CC3F"
      />
      <path
        d="M35.3417 59.2344H33.6083V60.9677H35.3417V59.2344Z"
        fill="#B8CC3F"
      />
      <path
        d="M37.075 59.2344H35.3416V60.9677H37.075V59.2344Z"
        fill="#B8CC3F"
      />
      <path
        d="M38.8083 59.2344H37.075V60.9677H38.8083V59.2344Z"
        fill="#B8CC3F"
      />
      <path
        d="M40.5417 59.2344H38.8083V60.9677H40.5417V59.2344Z"
        fill="#B8CC3F"
      />
      <path
        d="M42.275 59.2344H40.5417V60.9677H42.275V59.2344Z"
        fill="#B8CC3F"
      />
      <path d="M42.275 60.9668H40.5417V62.7001H42.275V60.9668Z" fill="black" />
      <path d="M44.0083 59.2344H42.275V60.9677H44.0083V59.2344Z" fill="black" />
      <path
        d="M45.7417 59.2344H44.0083V60.9677H45.7417V59.2344Z"
        fill="black"
      />
      <path d="M33.6083 57.5H31.875V59.2333H33.6083V57.5Z" fill="#B8CC3F" />
      <path d="M35.3417 57.5H33.6083V59.2333H35.3417V57.5Z" fill="#E5FF4E" />
      <path d="M37.075 57.5H35.3416V59.2333H37.075V57.5Z" fill="#E5FF4E" />
      <path d="M38.8083 57.5H37.075V59.2333H38.8083V57.5Z" fill="#E5FF4E" />
      <path d="M40.5417 57.5H38.8083V59.2333H40.5417V57.5Z" fill="#E5FF4E" />
      <path d="M42.275 57.5H40.5417V59.2333H42.275V57.5Z" fill="#E5FF4E" />
      <path d="M44.0083 57.5H42.275V59.2333H44.0083V57.5Z" fill="#B8CC3F" />
      <path d="M45.7417 57.5H44.0083V59.2333H45.7417V57.5Z" fill="#B8CC3F" />
      <path d="M47.475 57.5H45.7416V59.2333H47.475V57.5Z" fill="black" />
      <path d="M49.2083 57.5H47.475V59.2333H49.2083V57.5Z" fill="black" />
      <path
        d="M33.6083 55.7676H31.875V57.5009H33.6083V55.7676Z"
        fill="#B8CC3F"
      />
      <path
        d="M35.3417 55.7676H33.6083V57.5009H35.3417V55.7676Z"
        fill="#E5FF4E"
      />
      <path
        d="M37.075 55.7676H35.3416V57.5009H37.075V55.7676Z"
        fill="#E5FF4E"
      />
      <path
        d="M38.8083 55.7676H37.075V57.5009H38.8083V55.7676Z"
        fill="#E5FF4E"
      />
      <path
        d="M40.5417 55.7676H38.8083V57.5009H40.5417V55.7676Z"
        fill="#E5FF4E"
      />
      <path
        d="M42.275 55.7676H40.5417V57.5009H42.275V55.7676Z"
        fill="#E5FF4E"
      />
      <path
        d="M44.0083 55.7676H42.275V57.5009H44.0083V55.7676Z"
        fill="#E5FF4E"
      />
      <path
        d="M45.7417 55.7676H44.0083V57.5009H45.7417V55.7676Z"
        fill="#E5FF4E"
      />
      <path
        d="M47.475 55.7676H45.7416V57.5009H47.475V55.7676Z"
        fill="#B8CC3F"
      />
      <path d="M49.2083 55.7676H47.475V57.5009H49.2083V55.7676Z" fill="black" />
      <path
        d="M50.9416 55.7676H49.2083V57.5009H50.9416V55.7676Z"
        fill="black"
      />
      <path
        d="M33.6083 54.0332H31.875V55.7665H33.6083V54.0332Z"
        fill="#B8CC3F"
      />
      <path
        d="M35.3417 54.0332H33.6083V55.7665H35.3417V54.0332Z"
        fill="#E5FF4E"
      />
      <path
        d="M37.075 54.0332H35.3416V55.7665H37.075V54.0332Z"
        fill="#E5FF4E"
      />
      <path
        d="M38.8083 54.0332H37.075V55.7665H38.8083V54.0332Z"
        fill="#E5FF4E"
      />
      <path
        d="M40.5417 54.0332H38.8083V55.7665H40.5417V54.0332Z"
        fill="#E5FF4E"
      />
      <path
        d="M42.275 54.0332H40.5417V55.7665H42.275V54.0332Z"
        fill="#E5FF4E"
      />
      <path
        d="M44.0083 54.0332H42.275V55.7665H44.0083V54.0332Z"
        fill="#E5FF4E"
      />
      <path
        d="M45.7417 54.0332H44.0083V55.7665H45.7417V54.0332Z"
        fill="#E5FF4E"
      />
      <path
        d="M47.475 54.0332H45.7416V55.7665H47.475V54.0332Z"
        fill="#E5FF4E"
      />
      <path
        d="M49.2083 54.0332H47.475V55.7665H49.2083V54.0332Z"
        fill="#B8CC3F"
      />
      <path
        d="M50.9416 54.0332H49.2083V55.7665H50.9416V54.0332Z"
        fill="#B8CC3F"
      />
      <path d="M52.675 54.0332H50.9417V55.7665H52.675V54.0332Z" fill="black" />
      <path d="M54.4083 54.0332H52.675V55.7665H54.4083V54.0332Z" fill="black" />
      <path
        d="M33.6083 52.3008H31.875V54.0341H33.6083V52.3008Z"
        fill="#B8CC3F"
      />
      <path
        d="M35.3417 52.3008H33.6083V54.0341H35.3417V52.3008Z"
        fill="#E5FF4E"
      />
      <path
        d="M37.075 52.3008H35.3416V54.0341H37.075V52.3008Z"
        fill="#E5FF4E"
      />
      <path
        d="M38.8083 52.3008H37.075V54.0341H38.8083V52.3008Z"
        fill="#E5FF4E"
      />
      <path
        d="M40.5417 52.3008H38.8083V54.0341H40.5417V52.3008Z"
        fill="#E5FF4E"
      />
      <path
        d="M42.275 52.3008H40.5417V54.0341H42.275V52.3008Z"
        fill="#E5FF4E"
      />
      <path
        d="M44.0083 52.3008H42.275V54.0341H44.0083V52.3008Z"
        fill="#E5FF4E"
      />
      <path
        d="M45.7417 52.3008H44.0083V54.0341H45.7417V52.3008Z"
        fill="#E5FF4E"
      />
      <path
        d="M47.475 52.3008H45.7416V54.0341H47.475V52.3008Z"
        fill="#E5FF4E"
      />
      <path
        d="M49.2083 52.3008H47.475V54.0341H49.2083V52.3008Z"
        fill="#E5FF4E"
      />
      <path
        d="M50.9416 52.3008H49.2083V54.0341H50.9416V52.3008Z"
        fill="#E5FF4E"
      />
      <path
        d="M52.675 52.3008H50.9417V54.0341H52.675V52.3008Z"
        fill="#B8CC3F"
      />
      <path
        d="M54.4083 52.3008H52.675V54.0341H54.4083V52.3008Z"
        fill="#B8CC3F"
      />
      <path
        d="M56.1417 52.3008H54.4083V54.0341H56.1417V52.3008Z"
        fill="black"
      />
      <path
        d="M33.6083 50.5684H31.875V52.3017H33.6083V50.5684Z"
        fill="#B8CC3F"
      />
      <path
        d="M35.3417 50.5684H33.6083V52.3017H35.3417V50.5684Z"
        fill="#E5FF4E"
      />
      <path
        d="M37.075 50.5684H35.3416V52.3017H37.075V50.5684Z"
        fill="#E5FF4E"
      />
      <path
        d="M38.8083 50.5684H37.075V52.3017H38.8083V50.5684Z"
        fill="#E5FF4E"
      />
      <path
        d="M40.5417 50.5684H38.8083V52.3017H40.5417V50.5684Z"
        fill="#E5FF4E"
      />
      <path
        d="M42.275 50.5684H40.5417V52.3017H42.275V50.5684Z"
        fill="#E5FF4E"
      />
      <path
        d="M44.0083 50.5684H42.275V52.3017H44.0083V50.5684Z"
        fill="#E5FF4E"
      />
      <path
        d="M45.7417 50.5684H44.0083V52.3017H45.7417V50.5684Z"
        fill="#E5FF4E"
      />
      <path
        d="M47.475 50.5684H45.7416V52.3017H47.475V50.5684Z"
        fill="#E5FF4E"
      />
      <path
        d="M49.2083 50.5684H47.475V52.3017H49.2083V50.5684Z"
        fill="#E5FF4E"
      />
      <path
        d="M50.9416 50.5684H49.2083V52.3017H50.9416V50.5684Z"
        fill="#E5FF4E"
      />
      <path
        d="M52.675 50.5684H50.9417V52.3017H52.675V50.5684Z"
        fill="#E5FF4E"
      />
      <path
        d="M54.4083 50.5684H52.675V52.3017H54.4083V50.5684Z"
        fill="#E5FF4E"
      />
      <path
        d="M56.1417 50.5684H54.4083V52.3017H56.1417V50.5684Z"
        fill="#B8CC3F"
      />
      <path d="M57.875 50.5684H56.1417V52.3017H57.875V50.5684Z" fill="black" />
      <path d="M33.6083 48.834H31.875V50.5673H33.6083V48.834Z" fill="#B8CC3F" />
      <path
        d="M35.3417 48.834H33.6083V50.5673H35.3417V48.834Z"
        fill="#E5FF4E"
      />
      <path d="M37.075 48.834H35.3416V50.5673H37.075V48.834Z" fill="#E5FF4E" />
      <path d="M38.8083 48.834H37.075V50.5673H38.8083V48.834Z" fill="#E5FF4E" />
      <path
        d="M40.5417 48.834H38.8083V50.5673H40.5417V48.834Z"
        fill="#E5FF4E"
      />
      <path d="M42.275 48.834H40.5417V50.5673H42.275V48.834Z" fill="#E5FF4E" />
      <path d="M44.0083 48.834H42.275V50.5673H44.0083V48.834Z" fill="#E5FF4E" />
      <path
        d="M45.7417 48.834H44.0083V50.5673H45.7417V48.834Z"
        fill="#E5FF4E"
      />
      <path d="M47.475 48.834H45.7416V50.5673H47.475V48.834Z" fill="#E5FF4E" />
      <path d="M49.2083 48.834H47.475V50.5673H49.2083V48.834Z" fill="#E5FF4E" />
      <path
        d="M50.9416 48.834H49.2083V50.5673H50.9416V48.834Z"
        fill="#E5FF4E"
      />
      <path d="M52.675 48.834H50.9417V50.5673H52.675V48.834Z" fill="#E5FF4E" />
      <path d="M54.4083 48.834H52.675V50.5673H54.4083V48.834Z" fill="#E5FF4E" />
      <path
        d="M56.1417 48.834H54.4083V50.5673H56.1417V48.834Z"
        fill="#B8CC3F"
      />
      <path d="M57.875 48.834H56.1417V50.5673H57.875V48.834Z" fill="black" />
      <path
        d="M33.6083 47.1016H31.875V48.8349H33.6083V47.1016Z"
        fill="#B8CC3F"
      />
      <path
        d="M35.3417 47.1016H33.6083V48.8349H35.3417V47.1016Z"
        fill="#B8CC3F"
      />
      <path
        d="M37.075 47.1016H35.3416V48.8349H37.075V47.1016Z"
        fill="#B8CC3F"
      />
      <path
        d="M38.8083 47.1016H37.075V48.8349H38.8083V47.1016Z"
        fill="#B8CC3F"
      />
      <path
        d="M40.5417 47.1016H38.8083V48.8349H40.5417V47.1016Z"
        fill="#B8CC40"
      />
      <path
        d="M42.275 47.1016H40.5417V48.8349H42.275V47.1016Z"
        fill="#E5FF4E"
      />
      <path
        d="M44.0083 47.1016H42.275V48.8349H44.0083V47.1016Z"
        fill="#E5FF4E"
      />
      <path
        d="M45.7417 47.1016H44.0083V48.8349H45.7417V47.1016Z"
        fill="#E5FF4E"
      />
      <path
        d="M47.475 47.1016H45.7416V48.8349H47.475V47.1016Z"
        fill="#E5FF4E"
      />
      <path
        d="M49.2083 47.1016H47.475V48.8349H49.2083V47.1016Z"
        fill="#E5FF4E"
      />
      <path
        d="M50.9416 47.1016H49.2083V48.8349H50.9416V47.1016Z"
        fill="#E5FF4E"
      />
      <path
        d="M52.675 47.1016H50.9417V48.8349H52.675V47.1016Z"
        fill="#E5FF4E"
      />
      <path
        d="M54.4083 47.1016H52.675V48.8349H54.4083V47.1016Z"
        fill="#E5FF4E"
      />
      <path
        d="M56.1417 47.1016H54.4083V48.8349H56.1417V47.1016Z"
        fill="#E5FF4E"
      />
      <path
        d="M57.875 47.1016H56.1417V48.8349H57.875V47.1016Z"
        fill="#B8CC3F"
      />
      <path d="M59.6083 47.1016H57.875V48.8349H59.6083V47.1016Z" fill="black" />
      <path d="M33.6083 45.3672H31.875V47.1005H33.6083V45.3672Z" fill="black" />
      <path
        d="M35.3417 45.3672H33.6083V47.1005H35.3417V45.3672Z"
        fill="black"
      />
      <path d="M37.075 45.3672H35.3416V47.1005H37.075V45.3672Z" fill="black" />
      <path d="M38.8083 45.3672H37.075V47.1005H38.8083V45.3672Z" fill="black" />
      <path
        d="M42.275 45.3672H40.5417V47.1005H42.275V45.3672Z"
        fill="#B8CC3F"
      />
      <path
        d="M40.5417 45.3672H38.8083V47.1005H40.5417V45.3672Z"
        fill="#B8CC3F"
      />
      <path
        d="M44.0083 45.3672H42.275V47.1005H44.0083V45.3672Z"
        fill="#E5FF4E"
      />
      <path
        d="M45.7417 45.3672H44.0083V47.1005H45.7417V45.3672Z"
        fill="#E5FF4E"
      />
      <path
        d="M47.475 45.3672H45.7416V47.1005H47.475V45.3672Z"
        fill="#E5FF4E"
      />
      <path
        d="M49.2083 45.3672H47.475V47.1005H49.2083V45.3672Z"
        fill="#E5FF4E"
      />
      <path
        d="M50.9416 45.3672H49.2083V47.1005H50.9416V45.3672Z"
        fill="#E5FF4E"
      />
      <path
        d="M52.675 45.3672H50.9417V47.1005H52.675V45.3672Z"
        fill="#E5FF4E"
      />
      <path
        d="M54.4083 45.3672H52.675V47.1005H54.4083V45.3672Z"
        fill="#E5FF4E"
      />
      <path
        d="M56.1417 45.3672H54.4083V47.1005H56.1417V45.3672Z"
        fill="#E5FF4E"
      />
      <path
        d="M57.875 45.3672H56.1417V47.1005H57.875V45.3672Z"
        fill="#B8CC3F"
      />
      <path d="M59.6083 45.3672H57.875V47.1005H59.6083V45.3672Z" fill="black" />
      <path
        d="M61.3417 45.3672H59.6083V47.1005H61.3417V45.3672Z"
        fill="black"
      />
      <path d="M33.6083 43.6348H31.875V45.3681H33.6083V43.6348Z" fill="black" />
      <path
        d="M35.3417 43.6348H33.6083V45.3681H35.3417V43.6348Z"
        fill="black"
      />
      <path d="M37.075 43.6348H35.3416V45.3681H37.075V43.6348Z" fill="black" />
      <path d="M38.8083 43.6348H37.075V45.3681H38.8083V43.6348Z" fill="black" />
      <path
        d="M40.5417 43.6348H38.8083V45.3681H40.5417V43.6348Z"
        fill="black"
      />
      <path d="M42.275 43.6348H40.5417V45.3681H42.275V43.6348Z" fill="black" />
      <path
        d="M44.0083 43.6348H42.275V45.3681H44.0083V43.6348Z"
        fill="#B8CC40"
      />
      <path
        d="M45.7417 43.6348H44.0083V45.3681H45.7417V43.6348Z"
        fill="#E5FF4E"
      />
      <path
        d="M47.475 43.6348H45.7416V45.3681H47.475V43.6348Z"
        fill="#E5FF4E"
      />
      <path
        d="M49.2083 43.6348H47.475V45.3681H49.2083V43.6348Z"
        fill="#E5FF4E"
      />
      <path
        d="M50.9416 43.6348H49.2083V45.3681H50.9416V43.6348Z"
        fill="#E5FF4E"
      />
      <path
        d="M52.675 43.6348H50.9417V45.3681H52.675V43.6348Z"
        fill="#E5FF4E"
      />
      <path
        d="M54.4083 43.6348H52.675V45.3681H54.4083V43.6348Z"
        fill="#B8CC3F"
      />
      <path
        d="M56.1417 43.6348H54.4083V45.3681H56.1417V43.6348Z"
        fill="#B8CC3F"
      />
      <path
        d="M57.875 43.6348H56.1417V45.3681H57.875V43.6348Z"
        fill="#7487CB"
      />
      <path
        d="M59.6083 43.6348H57.875V45.3681H59.6083V43.6348Z"
        fill="#7487CB"
      />
      <path
        d="M61.3417 43.6348H59.6083V45.3681H61.3417V43.6348Z"
        fill="black"
      />
      <path d="M33.6083 41.9004H31.875V43.6337H33.6083V41.9004Z" fill="black" />
      <path
        d="M35.3417 41.9004H33.6083V43.6337H35.3417V41.9004Z"
        fill="black"
      />
      <path d="M37.075 41.9004H35.3416V43.6337H37.075V41.9004Z" fill="black" />
      <path d="M38.8083 41.9004H37.075V43.6337H38.8083V41.9004Z" fill="black" />
      <path
        d="M40.5417 41.9004H38.8083V43.6337H40.5417V41.9004Z"
        fill="black"
      />
      <path d="M42.275 41.9004H40.5417V43.6337H42.275V41.9004Z" fill="black" />
      <path d="M44.0083 41.9004H42.275V43.6337H44.0083V41.9004Z" fill="black" />
      <path
        d="M45.7417 41.9004H44.0083V43.6337H45.7417V41.9004Z"
        fill="#B8CC3F"
      />
      <path
        d="M47.475 41.9004H45.7416V43.6337H47.475V41.9004Z"
        fill="#E5FF4E"
      />
      <path
        d="M49.2083 41.9004H47.475V43.6337H49.2083V41.9004Z"
        fill="#E5FF4E"
      />
      <path
        d="M50.9416 41.9004H49.2083V43.6337H50.9416V41.9004Z"
        fill="#B8CC3F"
      />
      <path
        d="M52.675 41.9004H50.9417V43.6337H52.675V41.9004Z"
        fill="#B8CC3F"
      />
      <path
        d="M54.4083 41.9004H52.675V43.6337H54.4083V41.9004Z"
        fill="#7487CB"
      />
      <path
        d="M56.1417 41.9004H54.4083V43.6337H56.1417V41.9004Z"
        fill="#7487CB"
      />
      <path
        d="M57.875 41.9004H56.1417V43.6337H57.875V41.9004Z"
        fill="#91A8FF"
      />
      <path
        d="M59.6083 41.9004H57.875V43.6337H59.6083V41.9004Z"
        fill="#91A8FF"
      />
      <path
        d="M61.3417 41.9004H59.6083V43.6337H61.3417V41.9004Z"
        fill="#7487CB"
      />
      <path d="M63.075 41.9004H61.3416V43.6337H63.075V41.9004Z" fill="black" />
      <path d="M33.6083 40.168H31.875V41.9013H33.6083V40.168Z" fill="black" />
      <path d="M35.3417 40.168H33.6083V41.9013H35.3417V40.168Z" fill="black" />
      <path d="M37.075 40.168H35.3416V41.9013H37.075V40.168Z" fill="black" />
      <path d="M38.8083 40.168H37.075V41.9013H38.8083V40.168Z" fill="black" />
      <path d="M40.5417 40.168H38.8083V41.9013H40.5417V40.168Z" fill="black" />
      <path d="M42.275 40.168H40.5417V41.9013H42.275V40.168Z" fill="black" />
      <path d="M44.0083 40.168H42.275V41.9013H44.0083V40.168Z" fill="black" />
      <path d="M45.7417 40.168H44.0083V41.9013H45.7417V40.168Z" fill="black" />
      <path d="M47.475 40.168H45.7416V41.9013H47.475V40.168Z" fill="#B8CC40" />
      <path d="M49.2083 40.168H47.475V41.9013H49.2083V40.168Z" fill="#B8CC3F" />
      <path
        d="M50.9416 40.168H49.2083V41.9013H50.9416V40.168Z"
        fill="#7487CB"
      />
      <path d="M52.675 40.168H50.9417V41.9013H52.675V40.168Z" fill="#7487CB" />
      <path d="M54.4083 40.168H52.675V41.9013H54.4083V40.168Z" fill="#91A8FF" />
      <path
        d="M56.1417 40.168H54.4083V41.9013H56.1417V40.168Z"
        fill="#91A8FF"
      />
      <path d="M57.875 40.168H56.1417V41.9013H57.875V40.168Z" fill="#91A8FF" />
      <path d="M59.6083 40.168H57.875V41.9013H59.6083V40.168Z" fill="#91A8FF" />
      <path
        d="M61.3417 40.168H59.6083V41.9013H61.3417V40.168Z"
        fill="#7487CB"
      />
      <path d="M63.075 40.168H61.3416V41.9013H63.075V40.168Z" fill="black" />
      <path d="M33.6083 38.4336H31.875V40.1669H33.6083V38.4336Z" fill="black" />
      <path
        d="M35.3417 38.4336H33.6083V40.1669H35.3417V38.4336Z"
        fill="black"
      />
      <path d="M37.075 38.4336H35.3416V40.1669H37.075V38.4336Z" fill="black" />
      <path d="M38.8083 38.4336H37.075V40.1669H38.8083V38.4336Z" fill="black" />
      <path
        d="M40.5417 38.4336H38.8083V40.1669H40.5417V38.4336Z"
        fill="black"
      />
      <path d="M42.275 38.4336H40.5417V40.1669H42.275V38.4336Z" fill="black" />
      <path d="M44.0083 38.4336H42.275V40.1669H44.0083V38.4336Z" fill="black" />
      <path
        d="M45.7417 38.4336H44.0083V40.1669H45.7417V38.4336Z"
        fill="black"
      />
      <path
        d="M47.475 38.4336H45.7416V40.1669H47.475V38.4336Z"
        fill="#B8CC40"
      />
      <path
        d="M49.2083 38.4336H47.475V40.1669H49.2083V38.4336Z"
        fill="#7487CB"
      />
      <path
        d="M50.9416 38.4336H49.2083V40.1669H50.9416V38.4336Z"
        fill="#91A8FF"
      />
      <path
        d="M52.675 38.4336H50.9417V40.1669H52.675V38.4336Z"
        fill="#91A8FF"
      />
      <path
        d="M54.4083 38.4336H52.675V40.1669H54.4083V38.4336Z"
        fill="#91A8FF"
      />
      <path
        d="M56.1417 38.4336H54.4083V40.1669H56.1417V38.4336Z"
        fill="#91A8FF"
      />
      <path
        d="M57.875 38.4336H56.1417V40.1669H57.875V38.4336Z"
        fill="#91A8FF"
      />
      <path
        d="M59.6083 38.4336H57.875V40.1669H59.6083V38.4336Z"
        fill="#91A8FF"
      />
      <path
        d="M61.3417 38.4336H59.6083V40.1669H61.3417V38.4336Z"
        fill="#7487CB"
      />
      <path d="M63.075 38.4336H61.3416V40.1669H63.075V38.4336Z" fill="black" />
      <path d="M33.6083 36.7012H31.875V38.4345H33.6083V36.7012Z" fill="black" />
      <path
        d="M35.3417 36.7012H33.6083V38.4345H35.3417V36.7012Z"
        fill="black"
      />
      <path d="M37.075 36.7012H35.3416V38.4345H37.075V36.7012Z" fill="black" />
      <path d="M38.8083 36.7012H37.075V38.4345H38.8083V36.7012Z" fill="black" />
      <path
        d="M40.5417 36.7012H38.8083V38.4345H40.5417V36.7012Z"
        fill="black"
      />
      <path d="M42.275 36.7012H40.5417V38.4345H42.275V36.7012Z" fill="black" />
      <path d="M44.0083 36.7012H42.275V38.4345H44.0083V36.7012Z" fill="black" />
      <path
        d="M45.7417 36.7012H44.0083V38.4345H45.7417V36.7012Z"
        fill="black"
      />
      <path d="M47.475 36.7012H45.7416V38.4345H47.475V36.7012Z" fill="black" />
      <path
        d="M49.2083 36.7012H47.475V38.4345H49.2083V36.7012Z"
        fill="#91A8FF"
      />
      <path
        d="M50.9416 36.7012H49.2083V38.4345H50.9416V36.7012Z"
        fill="#91A8FF"
      />
      <path
        d="M52.675 36.7012H50.9417V38.4345H52.675V36.7012Z"
        fill="#91A8FF"
      />
      <path
        d="M54.4083 36.7012H52.675V38.4345H54.4083V36.7012Z"
        fill="#91A8FF"
      />
      <path
        d="M56.1417 36.7012H54.4083V38.4345H56.1417V36.7012Z"
        fill="#91A8FF"
      />
      <path
        d="M57.875 36.7012H56.1417V38.4345H57.875V36.7012Z"
        fill="#91A8FF"
      />
      <path
        d="M59.6083 36.7012H57.875V38.4345H59.6083V36.7012Z"
        fill="#91A8FF"
      />
      <path
        d="M61.3417 36.7012H59.6083V38.4345H61.3417V36.7012Z"
        fill="#7487CB"
      />
      <path d="M63.075 36.7012H61.3416V38.4345H63.075V36.7012Z" fill="black" />
      <path d="M33.6083 34.9668H31.875V36.7001H33.6083V34.9668Z" fill="black" />
      <path
        d="M35.3417 34.9668H33.6083V36.7001H35.3417V34.9668Z"
        fill="black"
      />
      <path d="M37.075 34.9668H35.3416V36.7001H37.075V34.9668Z" fill="black" />
      <path d="M38.8083 34.9668H37.075V36.7001H38.8083V34.9668Z" fill="black" />
      <path
        d="M40.5417 34.9668H38.8083V36.7001H40.5417V34.9668Z"
        fill="black"
      />
      <path d="M42.275 34.9668H40.5417V36.7001H42.275V34.9668Z" fill="black" />
      <path d="M44.0083 34.9668H42.275V36.7001H44.0083V34.9668Z" fill="black" />
      <path
        d="M45.7417 34.9668H44.0083V36.7001H45.7417V34.9668Z"
        fill="black"
      />
      <path d="M47.475 34.9668H45.7416V36.7001H47.475V34.9668Z" fill="black" />
      <path
        d="M49.2083 34.9668H47.475V36.7001H49.2083V34.9668Z"
        fill="#91A8FF"
      />
      <path
        d="M50.9416 34.9668H49.2083V36.7001H50.9416V34.9668Z"
        fill="#91A8FF"
      />
      <path
        d="M52.675 34.9668H50.9417V36.7001H52.675V34.9668Z"
        fill="#91A8FF"
      />
      <path
        d="M54.4083 34.9668H52.675V36.7001H54.4083V34.9668Z"
        fill="#91A8FF"
      />
      <path
        d="M56.1417 34.9668H54.4083V36.7001H56.1417V34.9668Z"
        fill="#91A8FF"
      />
      <path
        d="M57.875 34.9668H56.1417V36.7001H57.875V34.9668Z"
        fill="#91A8FF"
      />
      <path
        d="M59.6083 34.9668H57.875V36.7001H59.6083V34.9668Z"
        fill="#91A8FF"
      />
      <path
        d="M61.3417 34.9668H59.6083V36.7001H61.3417V34.9668Z"
        fill="#7487CB"
      />
      <path d="M63.075 34.9668H61.3416V36.7001H63.075V34.9668Z" fill="black" />
      <path d="M33.6083 33.2344H31.875V34.9677H33.6083V33.2344Z" fill="black" />
      <path
        d="M35.3417 33.2344H33.6083V34.9677H35.3417V33.2344Z"
        fill="black"
      />
      <path d="M37.075 33.2344H35.3416V34.9677H37.075V33.2344Z" fill="black" />
      <path d="M38.8083 33.2344H37.075V34.9677H38.8083V33.2344Z" fill="black" />
      <path
        d="M40.5417 33.2344H38.8083V34.9677H40.5417V33.2344Z"
        fill="black"
      />
      <path d="M42.275 33.2344H40.5417V34.9677H42.275V33.2344Z" fill="black" />
      <path d="M44.0083 33.2344H42.275V34.9677H44.0083V33.2344Z" fill="black" />
      <path
        d="M45.7417 33.2344H44.0083V34.9677H45.7417V33.2344Z"
        fill="black"
      />
      <path d="M47.475 33.2344H45.7416V34.9677H47.475V33.2344Z" fill="black" />
      <path
        d="M49.2083 33.2344H47.475V34.9677H49.2083V33.2344Z"
        fill="#91A8FF"
      />
      <path
        d="M50.9416 33.2344H49.2083V34.9677H50.9416V33.2344Z"
        fill="#91A8FF"
      />
      <path
        d="M52.675 33.2344H50.9417V34.9677H52.675V33.2344Z"
        fill="#91A8FF"
      />
      <path
        d="M54.4083 33.2344H52.675V34.9677H54.4083V33.2344Z"
        fill="#91A8FF"
      />
      <path
        d="M56.1417 33.2344H54.4083V34.9677H56.1417V33.2344Z"
        fill="#91A8FF"
      />
      <path
        d="M57.875 33.2344H56.1417V34.9677H57.875V33.2344Z"
        fill="#91A8FF"
      />
      <path
        d="M59.6083 33.2344H57.875V34.9677H59.6083V33.2344Z"
        fill="#91A8FF"
      />
      <path
        d="M61.3417 33.2344H59.6083V34.9677H61.3417V33.2344Z"
        fill="#7487CB"
      />
      <path d="M63.075 33.2344H61.3416V34.9677H63.075V33.2344Z" fill="black" />
      <path d="M33.6083 31.5H31.875V33.2333H33.6083V31.5Z" fill="black" />
      <path d="M35.3417 31.5H33.6083V33.2333H35.3417V31.5Z" fill="black" />
      <path d="M37.075 31.5H35.3416V33.2333H37.075V31.5Z" fill="black" />
      <path d="M38.8083 31.5H37.075V33.2333H38.8083V31.5Z" fill="black" />
      <path d="M40.5417 31.5H38.8083V33.2333H40.5417V31.5Z" fill="black" />
      <path d="M42.275 31.5H40.5417V33.2333H42.275V31.5Z" fill="black" />
      <path d="M44.0083 31.5H42.275V33.2333H44.0083V31.5Z" fill="black" />
      <path d="M45.7417 31.5H44.0083V33.2333H45.7417V31.5Z" fill="black" />
      <path d="M47.475 31.5H45.7416V33.2333H47.475V31.5Z" fill="black" />
      <path d="M49.2083 31.5H47.475V33.2333H49.2083V31.5Z" fill="#91A8FF" />
      <path d="M50.9416 31.5H49.2083V33.2333H50.9416V31.5Z" fill="#91A8FF" />
      <path d="M52.675 31.5H50.9417V33.2333H52.675V31.5Z" fill="#91A8FF" />
      <path d="M54.4083 31.5H52.675V33.2333H54.4083V31.5Z" fill="#91A8FF" />
      <path d="M56.1417 31.5H54.4083V33.2333H56.1417V31.5Z" fill="#91A8FF" />
      <path d="M57.875 31.5H56.1417V33.2333H57.875V31.5Z" fill="#91A8FF" />
      <path d="M59.6083 31.5H57.875V33.2333H59.6083V31.5Z" fill="#91A8FF" />
      <path d="M61.3417 31.5H59.6083V33.2333H61.3417V31.5Z" fill="#7487CB" />
      <path d="M63.075 31.5H61.3416V33.2333H63.075V31.5Z" fill="black" />
      <path
        d="M28.4083 2.0332H30.1417V0.29987L28.4083 0.29987V2.0332Z"
        fill="black"
      />
      <path
        d="M26.675 2.0332H28.4083V0.29987L26.675 0.29987V2.0332Z"
        fill="black"
      />
      <path
        d="M24.9417 2.0332H26.675V0.29987L24.9417 0.29987V2.0332Z"
        fill="black"
      />
      <path
        d="M23.2083 2.0332H24.9417V0.29987L23.2083 0.29987V2.0332Z"
        fill="black"
      />
      <path
        d="M28.4083 3.76758H30.1417V2.03424H28.4083V3.76758Z"
        fill="#FFF6DE"
      />
      <path
        d="M26.675 3.76758H28.4083V2.03424H26.675V3.76758Z"
        fill="#FFF6DE"
      />
      <path
        d="M24.9417 3.76758H26.675V2.03424H24.9417V3.76758Z"
        fill="#FFF6DE"
      />
      <path
        d="M23.2083 3.76758H24.9417V2.03424H23.2083V3.76758Z"
        fill="#FFF6DE"
      />
      <path
        d="M21.475 3.76758H23.2083V2.03424H21.475V3.76758Z"
        fill="#FFF6DE"
      />
      <path
        d="M21.475 2.0332H23.2083V0.29987L21.475 0.29987V2.0332Z"
        fill="black"
      />
      <path d="M19.7416 3.76758H21.475V2.03424H19.7416V3.76758Z" fill="black" />
      <path
        d="M18.0083 3.76758H19.7417V2.03424H18.0083V3.76758Z"
        fill="black"
      />
      <path d="M28.4083 5.5H30.1417V3.76667H28.4083V5.5Z" fill="#F9E49E" />
      <path d="M26.675 5.5H28.4083V3.76667H26.675V5.5Z" fill="#F9E49E" />
      <path d="M24.9417 5.5H26.675V3.76667H24.9417V5.5Z" fill="#F9E49E" />
      <path d="M23.2083 5.5H24.9417V3.76667H23.2083V5.5Z" fill="#F9E49E" />
      <path d="M21.475 5.5H23.2083V3.76667H21.475V5.5Z" fill="#F9E49E" />
      <path d="M19.7416 5.5H21.475V3.76667H19.7416V5.5Z" fill="#FFF6DE" />
      <path d="M18.0083 5.5H19.7417V3.76667H18.0083V5.5Z" fill="#FFF6DE" />
      <path d="M16.275 5.5H18.0083V3.76667H16.275V5.5Z" fill="black" />
      <path d="M14.5417 5.5H16.275V3.76667H14.5417V5.5Z" fill="black" />
      <path
        d="M28.4083 7.23438H30.1417V5.50104H28.4083V7.23438Z"
        fill="#F9E49E"
      />
      <path
        d="M26.675 7.23438H28.4083V5.50104H26.675V7.23438Z"
        fill="#F9E49E"
      />
      <path
        d="M24.9417 7.23438H26.675V5.50104H24.9417V7.23438Z"
        fill="#F9E49E"
      />
      <path
        d="M23.2083 7.23438H24.9417V5.50104H23.2083V7.23438Z"
        fill="#F9E49E"
      />
      <path
        d="M21.475 7.23438H23.2083V5.50104H21.475V7.23438Z"
        fill="#F9E49E"
      />
      <path
        d="M19.7416 7.23438H21.475V5.50104H19.7416V7.23438Z"
        fill="#F9E49E"
      />
      <path
        d="M18.0083 7.23438H19.7417V5.50104H18.0083V7.23438Z"
        fill="#F9E49E"
      />
      <path
        d="M16.275 7.23438H18.0083V5.50104H16.275V7.23438Z"
        fill="#FFF6DE"
      />
      <path d="M14.5417 7.23438H16.275V5.50104H14.5417V7.23438Z" fill="black" />
      <path
        d="M12.8083 7.23438H14.5417V5.50104H12.8083V7.23438Z"
        fill="black"
      />
      <path
        d="M28.4083 8.9668H30.1417V7.23346H28.4083V8.9668Z"
        fill="#F9E49E"
      />
      <path d="M26.675 8.9668H28.4083V7.23346H26.675V8.9668Z" fill="#F9E49E" />
      <path d="M24.9417 8.9668H26.675V7.23346H24.9417V8.9668Z" fill="#F9E49E" />
      <path
        d="M23.2083 8.9668H24.9417V7.23346H23.2083V8.9668Z"
        fill="#F9E49E"
      />
      <path d="M21.475 8.9668H23.2083V7.23346H21.475V8.9668Z" fill="#F9E49E" />
      <path d="M19.7416 8.9668H21.475V7.23346H19.7416V8.9668Z" fill="#F9E49E" />
      <path
        d="M18.0083 8.9668H19.7417V7.23346H18.0083V8.9668Z"
        fill="#F9E49E"
      />
      <path d="M16.275 8.9668H18.0083V7.23346H16.275V8.9668Z" fill="#F9E49E" />
      <path d="M14.5417 8.9668H16.275V7.23346H14.5417V8.9668Z" fill="#FFF6DE" />
      <path
        d="M12.8083 8.9668H14.5417V7.23346H12.8083V8.9668Z"
        fill="#FFF6DE"
      />
      <path d="M11.075 8.9668H12.8083V7.23346H11.075V8.9668Z" fill="black" />
      <path d="M9.34165 8.9668H11.075V7.23346H9.34165V8.9668Z" fill="black" />
      <path
        d="M28.4083 10.7012H30.1417V8.96784H28.4083V10.7012Z"
        fill="#F9E49E"
      />
      <path
        d="M26.675 10.7012H28.4083V8.96784H26.675V10.7012Z"
        fill="#F9E49E"
      />
      <path
        d="M24.9417 10.7012H26.675V8.96784H24.9417V10.7012Z"
        fill="#F9E49E"
      />
      <path
        d="M23.2083 10.7012H24.9417V8.96784H23.2083V10.7012Z"
        fill="#F9E49E"
      />
      <path
        d="M21.475 10.7012H23.2083V8.96784H21.475V10.7012Z"
        fill="#F9E49E"
      />
      <path
        d="M19.7416 10.7012H21.475V8.96784H19.7416V10.7012Z"
        fill="#F9E49E"
      />
      <path
        d="M18.0083 10.7012H19.7417V8.96784H18.0083V10.7012Z"
        fill="#F9E49E"
      />
      <path
        d="M16.275 10.7012H18.0083V8.96784H16.275V10.7012Z"
        fill="#F9E49E"
      />
      <path
        d="M14.5417 10.7012H16.275V8.96784H14.5417V10.7012Z"
        fill="#F9E49E"
      />
      <path
        d="M12.8083 10.7012H14.5417V8.96784H12.8083V10.7012Z"
        fill="#F9E49E"
      />
      <path
        d="M11.075 10.7012H12.8083V8.96784H11.075V10.7012Z"
        fill="#FFF6DE"
      />
      <path
        d="M9.34165 10.7012H11.075V8.96784H9.34165V10.7012Z"
        fill="#FFF6DE"
      />
      <path
        d="M7.60831 10.7012H9.34164V8.96784H7.60831V10.7012Z"
        fill="black"
      />
      <path
        d="M28.4083 12.4336H30.1417V10.7003H28.4083V12.4336Z"
        fill="#F9E49E"
      />
      <path
        d="M26.675 12.4336H28.4083V10.7003H26.675V12.4336Z"
        fill="#F9E49E"
      />
      <path
        d="M24.9417 12.4336H26.675V10.7003H24.9417V12.4336Z"
        fill="#F9E49E"
      />
      <path
        d="M23.2083 12.4336H24.9417V10.7003H23.2083V12.4336Z"
        fill="#F9E49E"
      />
      <path
        d="M21.475 12.4336H23.2083V10.7003H21.475V12.4336Z"
        fill="#F9E49E"
      />
      <path
        d="M19.7416 12.4336H21.475V10.7003H19.7416V12.4336Z"
        fill="#F9E49E"
      />
      <path
        d="M18.0083 12.4336H19.7417V10.7003H18.0083V12.4336Z"
        fill="#F9E49E"
      />
      <path
        d="M16.275 12.4336H18.0083V10.7003H16.275V12.4336Z"
        fill="#F9E49E"
      />
      <path
        d="M14.5417 12.4336H16.275V10.7003H14.5417V12.4336Z"
        fill="#F9E49E"
      />
      <path
        d="M12.8083 12.4336H14.5417V10.7003H12.8083V12.4336Z"
        fill="#F9E49E"
      />
      <path
        d="M11.075 12.4336H12.8083V10.7003H11.075V12.4336Z"
        fill="#F9E49E"
      />
      <path
        d="M9.34165 12.4336H11.075V10.7003H9.34165V12.4336Z"
        fill="#F9E49E"
      />
      <path
        d="M7.60831 12.4336H9.34164V10.7003H7.60831V12.4336Z"
        fill="#FFF6DE"
      />
      <path
        d="M5.87497 12.4336H7.60831V10.7003H5.87497V12.4336Z"
        fill="black"
      />
      <path
        d="M28.4083 14.168H30.1417V12.4346H28.4083V14.168Z"
        fill="#F9E49E"
      />
      <path d="M26.675 14.168H28.4083V12.4346H26.675V14.168Z" fill="#F9E49E" />
      <path d="M24.9417 14.168H26.675V12.4346H24.9417V14.168Z" fill="#F9E49E" />
      <path
        d="M23.2083 14.168H24.9417V12.4346H23.2083V14.168Z"
        fill="#F9E49E"
      />
      <path d="M21.475 14.168H23.2083V12.4346H21.475V14.168Z" fill="#F9E49E" />
      <path d="M19.7416 14.168H21.475V12.4346H19.7416V14.168Z" fill="#F9E49E" />
      <path
        d="M18.0083 14.168H19.7417V12.4346H18.0083V14.168Z"
        fill="#F9E49E"
      />
      <path d="M16.275 14.168H18.0083V12.4346H16.275V14.168Z" fill="#F9E49E" />
      <path d="M14.5417 14.168H16.275V12.4346H14.5417V14.168Z" fill="#F9E49E" />
      <path
        d="M12.8083 14.168H14.5417V12.4346H12.8083V14.168Z"
        fill="#F9E49E"
      />
      <path d="M11.075 14.168H12.8083V12.4346H11.075V14.168Z" fill="#F9E49E" />
      <path d="M9.34165 14.168H11.075V12.4346H9.34165V14.168Z" fill="#F9E49E" />
      <path
        d="M7.60831 14.168H9.34164V12.4346H7.60831V14.168Z"
        fill="#FFF6DE"
      />
      <path d="M5.87497 14.168H7.60831V12.4346H5.87497V14.168Z" fill="black" />
      <path
        d="M28.4083 15.9004H30.1417V14.1671H28.4083V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M26.675 15.9004H28.4083V14.1671H26.675V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M24.9417 15.9004H26.675V14.1671H24.9417V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M23.2083 15.9004H24.9417V14.1671H23.2083V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M21.475 15.9004H23.2083V14.1671H21.475V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M19.7416 15.9004H21.475V14.1671H19.7416V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M18.0083 15.9004H19.7417V14.1671H18.0083V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M16.275 15.9004H18.0083V14.1671H16.275V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M14.5417 15.9004H16.275V14.1671H14.5417V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M12.8083 15.9004H14.5417V14.1671H12.8083V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M11.075 15.9004H12.8083V14.1671H11.075V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M9.34165 15.9004H11.075V14.1671H9.34165V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M7.60831 15.9004H9.34164V14.1671H7.60831V15.9004Z"
        fill="#F9E49E"
      />
      <path
        d="M5.87497 15.9004H7.60831V14.1671H5.87497V15.9004Z"
        fill="#FFF6DE"
      />
      <path d="M4.14167 15.9004H5.875V14.1671H4.14167V15.9004Z" fill="black" />
      <path
        d="M28.4083 17.6348H30.1417V15.9014H28.4083V17.6348Z"
        fill="black"
      />
      <path
        d="M26.675 17.6348H28.4083V15.9014H26.675V17.6348Z"
        fill="#F0D594"
      />
      <path
        d="M24.9417 17.6348H26.675V15.9014H24.9417V17.6348Z"
        fill="#F0D594"
      />
      <path
        d="M23.2083 17.6348H24.9417V15.9014H23.2083V17.6348Z"
        fill="#F9E49E"
      />
      <path
        d="M21.475 17.6348H23.2083V15.9014H21.475V17.6348Z"
        fill="#F9E49E"
      />
      <path
        d="M19.7416 17.6348H21.475V15.9014H19.7416V17.6348Z"
        fill="#F9E49E"
      />
      <path
        d="M18.0083 17.6348H19.7417V15.9014H18.0083V17.6348Z"
        fill="#F9E49E"
      />
      <path
        d="M16.275 17.6348H18.0083V15.9014H16.275V17.6348Z"
        fill="#F9E49E"
      />
      <path
        d="M14.5417 17.6348H16.275V15.9014H14.5417V17.6348Z"
        fill="#F9E49E"
      />
      <path
        d="M12.8083 17.6348H14.5417V15.9014H12.8083V17.6348Z"
        fill="#F9E49E"
      />
      <path
        d="M11.075 17.6348H12.8083V15.9014H11.075V17.6348Z"
        fill="#F9E49E"
      />
      <path
        d="M9.34165 17.6348H11.075V15.9014H9.34165V17.6348Z"
        fill="#F9E49E"
      />
      <path
        d="M7.60831 17.6348H9.34164V15.9014H7.60831V17.6348Z"
        fill="#F9E49E"
      />
      <path
        d="M5.87497 17.6348H7.60831V15.9014H5.87497V17.6348Z"
        fill="#FFF6DE"
      />
      <path d="M4.14167 17.6348H5.875V15.9014H4.14167V17.6348Z" fill="black" />
      <path
        d="M2.40833 17.6348H4.14166V15.9014H2.40833V17.6348Z"
        fill="black"
      />
      <path
        d="M28.4083 19.3672H30.1417V17.6339H28.4083V19.3672Z"
        fill="#292E3D"
      />
      <path d="M26.675 19.3672H28.4083V17.6339H26.675V19.3672Z" fill="black" />
      <path d="M24.9417 19.3672H26.675V17.6339H24.9417V19.3672Z" fill="black" />
      <path
        d="M23.2083 19.3672H24.9417V17.6339H23.2083V19.3672Z"
        fill="#F0D594"
      />
      <path
        d="M21.475 19.3672H23.2083V17.6339H21.475V19.3672Z"
        fill="#F9E49E"
      />
      <path
        d="M19.7416 19.3672H21.475V17.6339H19.7416V19.3672Z"
        fill="#F9E49E"
      />
      <path
        d="M18.0083 19.3672H19.7417V17.6339H18.0083V19.3672Z"
        fill="#F9E49E"
      />
      <path
        d="M16.275 19.3672H18.0083V17.6339H16.275V19.3672Z"
        fill="#F9E49E"
      />
      <path
        d="M14.5417 19.3672H16.275V17.6339H14.5417V19.3672Z"
        fill="#F9E49E"
      />
      <path
        d="M12.8083 19.3672H14.5417V17.6339H12.8083V19.3672Z"
        fill="#F9E49E"
      />
      <path
        d="M11.075 19.3672H12.8083V17.6339H11.075V19.3672Z"
        fill="#F9E49E"
      />
      <path
        d="M9.34165 19.3672H11.075V17.6339H9.34165V19.3672Z"
        fill="#F0D594"
      />
      <path
        d="M7.60831 19.3672H9.34164V17.6339H7.60831V19.3672Z"
        fill="#F0D594"
      />
      <path
        d="M5.87497 19.3672H7.60831V17.6339H5.87497V19.3672Z"
        fill="#38859F"
      />
      <path
        d="M4.14167 19.3672H5.875V17.6339H4.14167V19.3672Z"
        fill="#38859F"
      />
      <path
        d="M2.40833 19.3672H4.14166V17.6339H2.40833V19.3672Z"
        fill="black"
      />
      <path
        d="M28.4083 21.1016H30.1417V19.3682H28.4083V21.1016Z"
        fill="black"
      />
      <path
        d="M26.675 21.1016H28.4083V19.3682H26.675V21.1016Z"
        fill="#292E3D"
      />
      <path d="M24.9417 21.1016H26.675V19.3682H24.9417V21.1016Z" fill="black" />
      <path
        d="M23.2083 21.1016H24.9417V19.3682H23.2083V21.1016Z"
        fill="black"
      />
      <path d="M21.475 21.1016H23.2083V19.3682H21.475V21.1016Z" fill="black" />
      <path
        d="M19.7416 21.1016H21.475V19.3682H19.7416V21.1016Z"
        fill="#F9E49E"
      />
      <path
        d="M18.0083 21.1016H19.7417V19.3682H18.0083V21.1016Z"
        fill="#F9E49E"
      />
      <path
        d="M16.275 21.1016H18.0083V19.3682H16.275V21.1016Z"
        fill="#F9E49E"
      />
      <path
        d="M14.5417 21.1016H16.275V19.3682H14.5417V21.1016Z"
        fill="#F9E49E"
      />
      <path
        d="M12.8083 21.1016H14.5417V19.3682H12.8083V21.1016Z"
        fill="#F0D594"
      />
      <path
        d="M11.075 21.1016H12.8083V19.3682H11.075V21.1016Z"
        fill="#F0D594"
      />
      <path
        d="M9.34165 21.1016H11.075V19.3682H9.34165V21.1016Z"
        fill="#38859F"
      />
      <path
        d="M7.60831 21.1016H9.34164V19.3682H7.60831V21.1016Z"
        fill="#38859F"
      />
      <path
        d="M5.87497 21.1016H7.60831V19.3682H5.87497V21.1016Z"
        fill="#56C2E7"
      />
      <path
        d="M4.14167 21.1016H5.875V19.3682H4.14167V21.1016Z"
        fill="#56C2E7"
      />
      <path
        d="M2.40833 21.1016H4.14166V19.3682H2.40833V21.1016Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 21.1016H2.40833V19.3682H0.674992L0.674992 21.1016Z"
        fill="black"
      />
      <path d="M28.4083 22.834H30.1417V21.1007H28.4083V22.834Z" fill="black" />
      <path d="M26.675 22.834H28.4083V21.1007H26.675V22.834Z" fill="black" />
      <path d="M24.9417 22.834H26.675V21.1007H24.9417V22.834Z" fill="#292E3D" />
      <path
        d="M23.2083 22.834H24.9417V21.1007H23.2083V22.834Z"
        fill="#292E3D"
      />
      <path d="M21.475 22.834H23.2083V21.1007H21.475V22.834Z" fill="black" />
      <path d="M19.7416 22.834H21.475V21.1007H19.7416V22.834Z" fill="black" />
      <path
        d="M18.0083 22.834H19.7417V21.1007H18.0083V22.834Z"
        fill="#F9E49E"
      />
      <path d="M16.275 22.834H18.0083V21.1007H16.275V22.834Z" fill="#F0D594" />
      <path d="M14.5417 22.834H16.275V21.1007H14.5417V22.834Z" fill="#F0D594" />
      <path
        d="M12.8083 22.834H14.5417V21.1007H12.8083V22.834Z"
        fill="#38859F"
      />
      <path d="M11.075 22.834H12.8083V21.1007H11.075V22.834Z" fill="#38859F" />
      <path d="M9.34165 22.834H11.075V21.1007H9.34165V22.834Z" fill="#56C2E7" />
      <path
        d="M7.60831 22.834H9.34164V21.1007H7.60831V22.834Z"
        fill="#56C2E7"
      />
      <path
        d="M5.87497 22.834H7.60831V21.1007H5.87497V22.834Z"
        fill="#56C2E7"
      />
      <path d="M4.14167 22.834H5.875V21.1007H4.14167V22.834Z" fill="#56C2E7" />
      <path
        d="M2.40833 22.834H4.14166V21.1007H2.40833V22.834Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 22.834H2.40833V21.1007H0.674992L0.674992 22.834Z"
        fill="black"
      />
      <path d="M28.4083 24.5684H30.1417V22.835H28.4083V24.5684Z" fill="black" />
      <path d="M26.675 24.5684H28.4083V22.835H26.675V24.5684Z" fill="black" />
      <path d="M24.9417 24.5684H26.675V22.835H24.9417V24.5684Z" fill="black" />
      <path d="M23.2083 24.5684H24.9417V22.835H23.2083V24.5684Z" fill="black" />
      <path d="M21.475 24.5684H23.2083V22.835H21.475V24.5684Z" fill="#292E3D" />
      <path d="M19.7416 24.5684H21.475V22.835H19.7416V24.5684Z" fill="black" />
      <path d="M18.0083 24.5684H19.7417V22.835H18.0083V24.5684Z" fill="black" />
      <path d="M16.275 24.5684H18.0083V22.835H16.275V24.5684Z" fill="#38859F" />
      <path
        d="M14.5417 24.5684H16.275V22.835H14.5417V24.5684Z"
        fill="#38859F"
      />
      <path
        d="M12.8083 24.5684H14.5417V22.835H12.8083V24.5684Z"
        fill="#56C2E7"
      />
      <path d="M11.075 24.5684H12.8083V22.835H11.075V24.5684Z" fill="#56C2E7" />
      <path
        d="M9.34165 24.5684H11.075V22.835H9.34165V24.5684Z"
        fill="#56C2E7"
      />
      <path
        d="M7.60831 24.5684H9.34164V22.835H7.60831V24.5684Z"
        fill="#56C2E7"
      />
      <path
        d="M5.87497 24.5684H7.60831V22.835H5.87497V24.5684Z"
        fill="#56C2E7"
      />
      <path d="M4.14167 24.5684H5.875V22.835H4.14167V24.5684Z" fill="#56C2E7" />
      <path
        d="M2.40833 24.5684H4.14166V22.835H2.40833V24.5684Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 24.5684H2.40833V22.835H0.674992L0.674992 24.5684Z"
        fill="black"
      />
      <path
        d="M28.4083 26.3008H30.1417V24.5674H28.4083V26.3008Z"
        fill="black"
      />
      <path d="M26.675 26.3008H28.4083V24.5674H26.675V26.3008Z" fill="black" />
      <path d="M24.9417 26.3008H26.675V24.5674H24.9417V26.3008Z" fill="black" />
      <path
        d="M23.2083 26.3008H24.9417V24.5674H23.2083V26.3008Z"
        fill="black"
      />
      <path d="M21.475 26.3008H23.2083V24.5674H21.475V26.3008Z" fill="black" />
      <path
        d="M19.7416 26.3008H21.475V24.5674H19.7416V26.3008Z"
        fill="#292E3D"
      />
      <path
        d="M18.0083 26.3008H19.7417V24.5674H18.0083V26.3008Z"
        fill="black"
      />
      <path
        d="M16.275 26.3008H18.0083V24.5674H16.275V26.3008Z"
        fill="#38859F"
      />
      <path
        d="M14.5417 26.3008H16.275V24.5674H14.5417V26.3008Z"
        fill="#56C2E7"
      />
      <path
        d="M12.8083 26.3008H14.5417V24.5674H12.8083V26.3008Z"
        fill="#56C2E7"
      />
      <path
        d="M11.075 26.3008H12.8083V24.5674H11.075V26.3008Z"
        fill="#56C2E7"
      />
      <path
        d="M9.34165 26.3008H11.075V24.5674H9.34165V26.3008Z"
        fill="#56C2E7"
      />
      <path
        d="M7.60831 26.3008H9.34164V24.5674H7.60831V26.3008Z"
        fill="#56C2E7"
      />
      <path
        d="M5.87497 26.3008H7.60831V24.5674H5.87497V26.3008Z"
        fill="#56C2E7"
      />
      <path
        d="M4.14167 26.3008H5.875V24.5674H4.14167V26.3008Z"
        fill="#56C2E7"
      />
      <path
        d="M2.40833 26.3008H4.14166V24.5674H2.40833V26.3008Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 26.3008H2.40833V24.5674H0.674992L0.674992 26.3008Z"
        fill="black"
      />
      <path
        d="M28.4083 28.0332H30.1417V26.2999H28.4083V28.0332Z"
        fill="black"
      />
      <path d="M26.675 28.0332H28.4083V26.2999H26.675V28.0332Z" fill="black" />
      <path d="M24.9417 28.0332H26.675V26.2999H24.9417V28.0332Z" fill="black" />
      <path
        d="M23.2083 28.0332H24.9417V26.2999H23.2083V28.0332Z"
        fill="black"
      />
      <path d="M21.475 28.0332H23.2083V26.2999H21.475V28.0332Z" fill="black" />
      <path
        d="M19.7416 28.0332H21.475V26.2999H19.7416V28.0332Z"
        fill="#292E3D"
      />
      <path
        d="M18.0083 28.0332H19.7417V26.2999H18.0083V28.0332Z"
        fill="black"
      />
      <path d="M16.275 28.0332H18.0083V26.2999H16.275V28.0332Z" fill="black" />
      <path
        d="M14.5417 28.0332H16.275V26.2999H14.5417V28.0332Z"
        fill="#56C2E7"
      />
      <path
        d="M12.8083 28.0332H14.5417V26.2999H12.8083V28.0332Z"
        fill="#56C2E7"
      />
      <path
        d="M11.075 28.0332H12.8083V26.2999H11.075V28.0332Z"
        fill="#56C2E7"
      />
      <path
        d="M9.34165 28.0332H11.075V26.2999H9.34165V28.0332Z"
        fill="#56C2E7"
      />
      <path
        d="M7.60831 28.0332H9.34164V26.2999H7.60831V28.0332Z"
        fill="#56C2E7"
      />
      <path
        d="M5.87497 28.0332H7.60831V26.2999H5.87497V28.0332Z"
        fill="#56C2E7"
      />
      <path
        d="M4.14167 28.0332H5.875V26.2999H4.14167V28.0332Z"
        fill="#56C2E7"
      />
      <path
        d="M2.40833 28.0332H4.14166V26.2999H2.40833V28.0332Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 28.0332H2.40833V26.2999H0.674992L0.674992 28.0332Z"
        fill="black"
      />
      <path
        d="M28.4083 29.7676H30.1417V28.0342H28.4083V29.7676Z"
        fill="black"
      />
      <path d="M26.675 29.7676H28.4083V28.0342H26.675V29.7676Z" fill="black" />
      <path d="M24.9417 29.7676H26.675V28.0342H24.9417V29.7676Z" fill="black" />
      <path
        d="M23.2083 29.7676H24.9417V28.0342H23.2083V29.7676Z"
        fill="black"
      />
      <path d="M21.475 29.7676H23.2083V28.0342H21.475V29.7676Z" fill="black" />
      <path d="M19.7416 29.7676H21.475V28.0342H19.7416V29.7676Z" fill="black" />
      <path
        d="M18.0083 29.7676H19.7417V28.0342H18.0083V29.7676Z"
        fill="#292E3D"
      />
      <path d="M16.275 29.7676H18.0083V28.0342H16.275V29.7676Z" fill="black" />
      <path
        d="M14.5417 29.7676H16.275V28.0342H14.5417V29.7676Z"
        fill="#56C2E7"
      />
      <path
        d="M12.8083 29.7676H14.5417V28.0342H12.8083V29.7676Z"
        fill="#56C2E7"
      />
      <path
        d="M11.075 29.7676H12.8083V28.0342H11.075V29.7676Z"
        fill="#56C2E7"
      />
      <path
        d="M9.34165 29.7676H11.075V28.0342H9.34165V29.7676Z"
        fill="#EF3A78"
      />
      <path
        d="M7.60831 29.7676H9.34164V28.0342H7.60831V29.7676Z"
        fill="#EF3A78"
      />
      <path
        d="M5.87497 29.7676H7.60831V28.0342H5.87497V29.7676Z"
        fill="#56C2E7"
      />
      <path
        d="M4.14167 29.7676H5.875V28.0342H4.14167V29.7676Z"
        fill="#56C2E7"
      />
      <path
        d="M2.40833 29.7676H4.14166V28.0342H2.40833V29.7676Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 29.7676H2.40833V28.0342H0.674992L0.674992 29.7676Z"
        fill="black"
      />
      <path d="M28.4083 31.5H30.1417V29.7667H28.4083V31.5Z" fill="black" />
      <path d="M26.675 31.5H28.4083V29.7667H26.675V31.5Z" fill="black" />
      <path d="M24.9417 31.5H26.675V29.7667H24.9417V31.5Z" fill="black" />
      <path d="M23.2083 31.5H24.9417V29.7667H23.2083V31.5Z" fill="black" />
      <path d="M21.475 31.5H23.2083V29.7667H21.475V31.5Z" fill="black" />
      <path d="M19.7416 31.5H21.475V29.7667H19.7416V31.5Z" fill="black" />
      <path d="M18.0083 31.5H19.7417V29.7667H18.0083V31.5Z" fill="#292E3D" />
      <path d="M16.275 31.5H18.0083V29.7667H16.275V31.5Z" fill="black" />
      <path d="M14.5417 31.5H16.275V29.7667H14.5417V31.5Z" fill="#56C2E7" />
      <path d="M12.8083 31.5H14.5417V29.7667H12.8083V31.5Z" fill="#56C2E7" />
      <path d="M11.075 31.5H12.8083V29.7667H11.075V31.5Z" fill="#56C2E7" />
      <path d="M9.34165 31.5H11.075V29.7667H9.34165V31.5Z" fill="#FE62B0" />
      <path d="M7.60831 31.5H9.34164V29.7667H7.60831V31.5Z" fill="#EF3A78" />
      <path d="M5.87497 31.5H7.60831V29.7667H5.87497V31.5Z" fill="#56C2E7" />
      <path d="M4.14167 31.5H5.875V29.7667H4.14167V31.5Z" fill="#56C2E7" />
      <path d="M2.40833 31.5H4.14166V29.7667H2.40833V31.5Z" fill="#8FE7FF" />
      <path
        d="M0.674992 31.5H2.40833V29.7667H0.674992L0.674992 31.5Z"
        fill="black"
      />
      <path
        d="M28.4083 62.7012H30.1417V60.9678H28.4083V62.7012Z"
        fill="black"
      />
      <path d="M26.675 62.7012H28.4083V60.9678H26.675V62.7012Z" fill="black" />
      <path d="M24.9417 62.7012H26.675V60.9678H24.9417V62.7012Z" fill="black" />
      <path
        d="M23.2083 62.7012H24.9417V60.9678H23.2083V62.7012Z"
        fill="black"
      />
      <path
        d="M28.4083 60.9668H30.1417V59.2335H28.4083V60.9668Z"
        fill="#6BECD4"
      />
      <path
        d="M26.675 60.9668H28.4083V59.2335H26.675V60.9668Z"
        fill="#6BECD4"
      />
      <path
        d="M24.9417 60.9668H26.675V59.2335H24.9417V60.9668Z"
        fill="#6BECD4"
      />
      <path
        d="M23.2083 60.9668H24.9417V59.2335H23.2083V60.9668Z"
        fill="#6BECD4"
      />
      <path
        d="M21.475 60.9668H23.2083V59.2335H21.475V60.9668Z"
        fill="#6BECD4"
      />
      <path d="M21.475 62.7012H23.2083V60.9678H21.475V62.7012Z" fill="black" />
      <path d="M19.7416 60.9668H21.475V59.2335H19.7416V60.9668Z" fill="black" />
      <path
        d="M18.0083 60.9668H19.7417V59.2335H18.0083V60.9668Z"
        fill="black"
      />
      <path
        d="M28.4083 59.2344H30.1417V57.501H28.4083V59.2344Z"
        fill="#76FFCF"
      />
      <path d="M26.675 59.2344H28.4083V57.501H26.675V59.2344Z" fill="#76FFCF" />
      <path
        d="M24.9417 59.2344H26.675V57.501H24.9417V59.2344Z"
        fill="#76FFCF"
      />
      <path
        d="M23.2083 59.2344H24.9417V57.501H23.2083V59.2344Z"
        fill="#76FFCF"
      />
      <path d="M21.475 59.2344H23.2083V57.501H21.475V59.2344Z" fill="#76FFCF" />
      <path
        d="M19.7416 59.2344H21.475V57.501H19.7416V59.2344Z"
        fill="#6BECD4"
      />
      <path
        d="M18.0083 59.2344H19.7417V57.501H18.0083V59.2344Z"
        fill="#6BECD4"
      />
      <path d="M16.275 59.2344H18.0083V57.501H16.275V59.2344Z" fill="black" />
      <path d="M14.5417 59.2344H16.275V57.501H14.5417V59.2344Z" fill="black" />
      <path d="M28.4083 57.5H30.1417V55.7667H28.4083V57.5Z" fill="#76FFCF" />
      <path d="M26.675 57.5H28.4083V55.7667H26.675V57.5Z" fill="#76FFCF" />
      <path d="M24.9417 57.5H26.675V55.7667H24.9417V57.5Z" fill="#76FFCF" />
      <path d="M23.2083 57.5H24.9417V55.7667H23.2083V57.5Z" fill="#76FFCF" />
      <path d="M21.475 57.5H23.2083V55.7667H21.475V57.5Z" fill="#76FFCF" />
      <path d="M19.7416 57.5H21.475V55.7667H19.7416V57.5Z" fill="#76FFCF" />
      <path d="M18.0083 57.5H19.7417V55.7667H18.0083V57.5Z" fill="#76FFCF" />
      <path d="M16.275 57.5H18.0083V55.7667H16.275V57.5Z" fill="#6BECD4" />
      <path d="M14.5417 57.5H16.275V55.7667H14.5417V57.5Z" fill="black" />
      <path d="M12.8083 57.5H14.5417V55.7667H12.8083V57.5Z" fill="black" />
      <path
        d="M28.4083 55.7676H30.1417V54.0342H28.4083V55.7676Z"
        fill="#76FFCF"
      />
      <path
        d="M26.675 55.7676H28.4083V54.0342H26.675V55.7676Z"
        fill="#76FFCF"
      />
      <path
        d="M24.9417 55.7676H26.675V54.0342H24.9417V55.7676Z"
        fill="#76FFCF"
      />
      <path
        d="M23.2083 55.7676H24.9417V54.0342H23.2083V55.7676Z"
        fill="#76FFCF"
      />
      <path
        d="M21.475 55.7676H23.2083V54.0342H21.475V55.7676Z"
        fill="#76FFCF"
      />
      <path
        d="M19.7416 55.7676H21.475V54.0342H19.7416V55.7676Z"
        fill="#76FFCF"
      />
      <path
        d="M18.0083 55.7676H19.7417V54.0342H18.0083V55.7676Z"
        fill="#76FFCF"
      />
      <path
        d="M16.275 55.7676H18.0083V54.0342H16.275V55.7676Z"
        fill="#76FFCF"
      />
      <path
        d="M14.5417 55.7676H16.275V54.0342H14.5417V55.7676Z"
        fill="#6BECD4"
      />
      <path
        d="M12.8083 55.7676H14.5417V54.0342H12.8083V55.7676Z"
        fill="#6BECD4"
      />
      <path d="M11.075 55.7676H12.8083V54.0342H11.075V55.7676Z" fill="black" />
      <path d="M9.34165 55.7676H11.075V54.0342H9.34165V55.7676Z" fill="black" />
      <path
        d="M28.4083 54.0332H30.1417V52.2999H28.4083V54.0332Z"
        fill="#76FFCF"
      />
      <path
        d="M26.675 54.0332H28.4083V52.2999H26.675V54.0332Z"
        fill="#76FFCF"
      />
      <path
        d="M24.9417 54.0332H26.675V52.2999H24.9417V54.0332Z"
        fill="#76FFCF"
      />
      <path
        d="M23.2083 54.0332H24.9417V52.2999H23.2083V54.0332Z"
        fill="#76FFCF"
      />
      <path
        d="M21.475 54.0332H23.2083V52.2999H21.475V54.0332Z"
        fill="#76FFCF"
      />
      <path
        d="M19.7416 54.0332H21.475V52.2999H19.7416V54.0332Z"
        fill="#76FFCF"
      />
      <path
        d="M18.0083 54.0332H19.7417V52.2999H18.0083V54.0332Z"
        fill="#76FFCF"
      />
      <path
        d="M16.275 54.0332H18.0083V52.2999H16.275V54.0332Z"
        fill="#76FFCF"
      />
      <path
        d="M14.5417 54.0332H16.275V52.2999H14.5417V54.0332Z"
        fill="#76FFCF"
      />
      <path
        d="M12.8083 54.0332H14.5417V52.2999H12.8083V54.0332Z"
        fill="#76FFCF"
      />
      <path
        d="M11.075 54.0332H12.8083V52.2999H11.075V54.0332Z"
        fill="#6BECD4"
      />
      <path
        d="M9.34165 54.0332H11.075V52.2999H9.34165V54.0332Z"
        fill="#6BECD4"
      />
      <path
        d="M7.60831 54.0332H9.34164V52.2999H7.60831V54.0332Z"
        fill="black"
      />
      <path
        d="M28.4083 52.3008H30.1417V50.5674H28.4083V52.3008Z"
        fill="#76FFCF"
      />
      <path
        d="M26.675 52.3008H28.4083V50.5674H26.675V52.3008Z"
        fill="#76FFCF"
      />
      <path
        d="M24.9417 52.3008H26.675V50.5674H24.9417V52.3008Z"
        fill="#76FFCF"
      />
      <path
        d="M23.2083 52.3008H24.9417V50.5674H23.2083V52.3008Z"
        fill="#76FFCF"
      />
      <path
        d="M21.475 52.3008H23.2083V50.5674H21.475V52.3008Z"
        fill="#76FFCF"
      />
      <path
        d="M19.7416 52.3008H21.475V50.5674H19.7416V52.3008Z"
        fill="#76FFCF"
      />
      <path
        d="M18.0083 52.3008H19.7417V50.5674H18.0083V52.3008Z"
        fill="#76FFCF"
      />
      <path
        d="M16.275 52.3008H18.0083V50.5674H16.275V52.3008Z"
        fill="#76FFCF"
      />
      <path
        d="M14.5417 52.3008H16.275V50.5674H14.5417V52.3008Z"
        fill="#76FFCF"
      />
      <path
        d="M12.8083 52.3008H14.5417V50.5674H12.8083V52.3008Z"
        fill="#76FFCF"
      />
      <path
        d="M11.075 52.3008H12.8083V50.5674H11.075V52.3008Z"
        fill="#76FFCF"
      />
      <path
        d="M9.34165 52.3008H11.075V50.5674H9.34165V52.3008Z"
        fill="#76FFCF"
      />
      <path
        d="M7.60831 52.3008H9.34164V50.5674H7.60831V52.3008Z"
        fill="#6BECD4"
      />
      <path
        d="M5.87497 52.3008H7.60831V50.5674H5.87497V52.3008Z"
        fill="black"
      />
      <path
        d="M28.4083 50.5684H30.1417V48.835H28.4083V50.5684Z"
        fill="#76FFCF"
      />
      <path d="M26.675 50.5684H28.4083V48.835H26.675V50.5684Z" fill="#76FFCF" />
      <path
        d="M24.9417 50.5684H26.675V48.835H24.9417V50.5684Z"
        fill="#76FFCF"
      />
      <path
        d="M23.2083 50.5684H24.9417V48.835H23.2083V50.5684Z"
        fill="#76FFCF"
      />
      <path d="M21.475 50.5684H23.2083V48.835H21.475V50.5684Z" fill="#76FFCF" />
      <path
        d="M19.7416 50.5684H21.475V48.835H19.7416V50.5684Z"
        fill="#76FFCF"
      />
      <path
        d="M18.0083 50.5684H19.7417V48.835H18.0083V50.5684Z"
        fill="#76FFCF"
      />
      <path d="M16.275 50.5684H18.0083V48.835H16.275V50.5684Z" fill="#76FFCF" />
      <path
        d="M14.5417 50.5684H16.275V48.835H14.5417V50.5684Z"
        fill="#76FFCF"
      />
      <path
        d="M12.8083 50.5684H14.5417V48.835H12.8083V50.5684Z"
        fill="#76FFCF"
      />
      <path d="M11.075 50.5684H12.8083V48.835H11.075V50.5684Z" fill="#76FFCF" />
      <path
        d="M9.34165 50.5684H11.075V48.835H9.34165V50.5684Z"
        fill="#76FFCF"
      />
      <path
        d="M7.60831 50.5684H9.34164V48.835H7.60831V50.5684Z"
        fill="#6BECD4"
      />
      <path d="M5.87497 50.5684H7.60831V48.835H5.87497V50.5684Z" fill="black" />
      <path
        d="M28.4083 48.834H30.1417V47.1007H28.4083V48.834Z"
        fill="#6BECD4"
      />
      <path d="M26.675 48.834H28.4083V47.1007H26.675V48.834Z" fill="#6BECD4" />
      <path d="M24.9417 48.834H26.675V47.1007H24.9417V48.834Z" fill="#6BECD4" />
      <path
        d="M23.2083 48.834H24.9417V47.1007H23.2083V48.834Z"
        fill="#6BECD4"
      />
      <path d="M21.475 48.834H23.2083V47.1007H21.475V48.834Z" fill="#76FFCF" />
      <path d="M19.7416 48.834H21.475V47.1007H19.7416V48.834Z" fill="#76FFCF" />
      <path
        d="M18.0083 48.834H19.7417V47.1007H18.0083V48.834Z"
        fill="#76FFCF"
      />
      <path d="M16.275 48.834H18.0083V47.1007H16.275V48.834Z" fill="#76FFCF" />
      <path d="M14.5417 48.834H16.275V47.1007H14.5417V48.834Z" fill="#76FFCF" />
      <path
        d="M12.8083 48.834H14.5417V47.1007H12.8083V48.834Z"
        fill="#76FFCF"
      />
      <path d="M11.075 48.834H12.8083V47.1007H11.075V48.834Z" fill="#76FFCF" />
      <path d="M9.34165 48.834H11.075V47.1007H9.34165V48.834Z" fill="#76FFCF" />
      <path
        d="M7.60831 48.834H9.34164V47.1007H7.60831V48.834Z"
        fill="#76FFCF"
      />
      <path
        d="M5.87497 48.834H7.60831V47.1007H5.87497V48.834Z"
        fill="#6BECD4"
      />
      <path d="M4.14167 48.834H5.875V47.1007H4.14167V48.834Z" fill="black" />
      <path
        d="M28.4083 47.1016H30.1417V45.3682H28.4083V47.1016Z"
        fill="black"
      />
      <path d="M26.675 47.1016H28.4083V45.3682H26.675V47.1016Z" fill="black" />
      <path d="M24.9417 47.1016H26.675V45.3682H24.9417V47.1016Z" fill="black" />
      <path
        d="M21.475 47.1016H23.2083V45.3682H21.475V47.1016Z"
        fill="#6BECD4"
      />
      <path
        d="M23.2083 47.1016H24.9417V45.3682H23.2083V47.1016Z"
        fill="#6BECD4"
      />
      <path
        d="M19.7416 47.1016H21.475V45.3682H19.7416V47.1016Z"
        fill="#76FFCF"
      />
      <path
        d="M18.0083 47.1016H19.7417V45.3682H18.0083V47.1016Z"
        fill="#76FFCF"
      />
      <path
        d="M16.275 47.1016H18.0083V45.3682H16.275V47.1016Z"
        fill="#76FFCF"
      />
      <path
        d="M14.5417 47.1016H16.275V45.3682H14.5417V47.1016Z"
        fill="#76FFCF"
      />
      <path
        d="M12.8083 47.1016H14.5417V45.3682H12.8083V47.1016Z"
        fill="#76FFCF"
      />
      <path
        d="M11.075 47.1016H12.8083V45.3682H11.075V47.1016Z"
        fill="#76FFCF"
      />
      <path
        d="M9.34165 47.1016H11.075V45.3682H9.34165V47.1016Z"
        fill="#76FFCF"
      />
      <path
        d="M7.60831 47.1016H9.34164V45.3682H7.60831V47.1016Z"
        fill="#76FFCF"
      />
      <path
        d="M5.87497 47.1016H7.60831V45.3682H5.87497V47.1016Z"
        fill="#6BECD4"
      />
      <path d="M4.14167 47.1016H5.875V45.3682H4.14167V47.1016Z" fill="black" />
      <path
        d="M2.40833 47.1016H4.14166V45.3682H2.40833V47.1016Z"
        fill="black"
      />
      <path
        d="M28.4083 45.3672H30.1417V43.6339H28.4083V45.3672Z"
        fill="black"
      />
      <path d="M26.675 45.3672H28.4083V43.6339H26.675V45.3672Z" fill="black" />
      <path d="M24.9417 45.3672H26.675V43.6339H24.9417V45.3672Z" fill="black" />
      <path
        d="M23.2083 45.3672H24.9417V43.6339H23.2083V45.3672Z"
        fill="black"
      />
      <path d="M21.475 45.3672H23.2083V43.6339H21.475V45.3672Z" fill="black" />
      <path
        d="M19.7416 45.3672H21.475V43.6339H19.7416V45.3672Z"
        fill="#6BECD4"
      />
      <path
        d="M18.0083 45.3672H19.7417V43.6339H18.0083V45.3672Z"
        fill="#76FFCF"
      />
      <path
        d="M16.275 45.3672H18.0083V43.6339H16.275V45.3672Z"
        fill="#76FFCF"
      />
      <path
        d="M14.5417 45.3672H16.275V43.6339H14.5417V45.3672Z"
        fill="#76FFCF"
      />
      <path
        d="M12.8083 45.3672H14.5417V43.6339H12.8083V45.3672Z"
        fill="#76FFCF"
      />
      <path
        d="M11.075 45.3672H12.8083V43.6339H11.075V45.3672Z"
        fill="#76FFCF"
      />
      <path
        d="M9.34165 45.3672H11.075V43.6339H9.34165V45.3672Z"
        fill="#6BECD4"
      />
      <path
        d="M7.60831 45.3672H9.34164V43.6339H7.60831V45.3672Z"
        fill="#6BECD4"
      />
      <path
        d="M5.87497 45.3672H7.60831V43.6339H5.87497V45.3672Z"
        fill="#38859F"
      />
      <path
        d="M4.14167 45.3672H5.875V43.6339H4.14167V45.3672Z"
        fill="#38859F"
      />
      <path
        d="M2.40833 45.3672H4.14166V43.6339H2.40833V45.3672Z"
        fill="black"
      />
      <path
        d="M28.4083 43.6348H30.1417V41.9014H28.4083V43.6348Z"
        fill="black"
      />
      <path d="M26.675 43.6348H28.4083V41.9014H26.675V43.6348Z" fill="black" />
      <path d="M24.9417 43.6348H26.675V41.9014H24.9417V43.6348Z" fill="black" />
      <path
        d="M23.2083 43.6348H24.9417V41.9014H23.2083V43.6348Z"
        fill="#292E3D"
      />
      <path d="M21.475 43.6348H23.2083V41.9014H21.475V43.6348Z" fill="black" />
      <path d="M19.7416 43.6348H21.475V41.9014H19.7416V43.6348Z" fill="black" />
      <path
        d="M18.0083 43.6348H19.7417V41.9014H18.0083V43.6348Z"
        fill="#6BECD4"
      />
      <path
        d="M16.275 43.6348H18.0083V41.9014H16.275V43.6348Z"
        fill="#76FFCF"
      />
      <path
        d="M14.5417 43.6348H16.275V41.9014H14.5417V43.6348Z"
        fill="#76FFCF"
      />
      <path
        d="M12.8083 43.6348H14.5417V41.9014H12.8083V43.6348Z"
        fill="#6BECD4"
      />
      <path
        d="M11.075 43.6348H12.8083V41.9014H11.075V43.6348Z"
        fill="#6BECD4"
      />
      <path
        d="M9.34165 43.6348H11.075V41.9014H9.34165V43.6348Z"
        fill="#38859F"
      />
      <path
        d="M7.60831 43.6348H9.34164V41.9014H7.60831V43.6348Z"
        fill="#38859F"
      />
      <path
        d="M5.87497 43.6348H7.60831V41.9014H5.87497V43.6348Z"
        fill="#56C2E7"
      />
      <path
        d="M4.14167 43.6348H5.875V41.9014H4.14167V43.6348Z"
        fill="#56C2E7"
      />
      <path
        d="M2.40833 43.6348H4.14166V41.9014H2.40833V43.6348Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 43.6348H2.40833V41.9014H0.674992L0.674992 43.6348Z"
        fill="black"
      />
      <path
        d="M28.4083 41.9004H30.1417V40.1671H28.4083V41.9004Z"
        fill="black"
      />
      <path d="M26.675 41.9004H28.4083V40.1671H26.675V41.9004Z" fill="black" />
      <path d="M24.9417 41.9004H26.675V40.1671H24.9417V41.9004Z" fill="black" />
      <path
        d="M23.2083 41.9004H24.9417V40.1671H23.2083V41.9004Z"
        fill="black"
      />
      <path
        d="M21.475 41.9004H23.2083V40.1671H21.475V41.9004Z"
        fill="#292E3D"
      />
      <path d="M19.7416 41.9004H21.475V40.1671H19.7416V41.9004Z" fill="black" />
      <path
        d="M18.0083 41.9004H19.7417V40.1671H18.0083V41.9004Z"
        fill="black"
      />
      <path
        d="M16.275 41.9004H18.0083V40.1671H16.275V41.9004Z"
        fill="#6BECD4"
      />
      <path
        d="M14.5417 41.9004H16.275V40.1671H14.5417V41.9004Z"
        fill="#6BECD4"
      />
      <path
        d="M12.8083 41.9004H14.5417V40.1671H12.8083V41.9004Z"
        fill="#38859F"
      />
      <path
        d="M11.075 41.9004H12.8083V40.1671H11.075V41.9004Z"
        fill="#38859F"
      />
      <path
        d="M9.34165 41.9004H11.075V40.1671H9.34165V41.9004Z"
        fill="#56C2E7"
      />
      <path
        d="M7.60831 41.9004H9.34164V40.1671H7.60831V41.9004Z"
        fill="#56C2E7"
      />
      <path
        d="M5.87497 41.9004H7.60831V40.1671H5.87497V41.9004Z"
        fill="#56C2E7"
      />
      <path
        d="M4.14167 41.9004H5.875V40.1671H4.14167V41.9004Z"
        fill="#56C2E7"
      />
      <path
        d="M2.40833 41.9004H4.14166V40.1671H2.40833V41.9004Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 41.9004H2.40833V40.1671H0.674992L0.674992 41.9004Z"
        fill="black"
      />
      <path d="M28.4083 40.168H30.1417V38.4346H28.4083V40.168Z" fill="black" />
      <path d="M26.675 40.168H28.4083V38.4346H26.675V40.168Z" fill="black" />
      <path d="M24.9417 40.168H26.675V38.4346H24.9417V40.168Z" fill="black" />
      <path d="M23.2083 40.168H24.9417V38.4346H23.2083V40.168Z" fill="black" />
      <path d="M21.475 40.168H23.2083V38.4346H21.475V40.168Z" fill="black" />
      <path d="M19.7416 40.168H21.475V38.4346H19.7416V40.168Z" fill="#292E3D" />
      <path d="M18.0083 40.168H19.7417V38.4346H18.0083V40.168Z" fill="black" />
      <path d="M16.275 40.168H18.0083V38.4346H16.275V40.168Z" fill="#6BECD4" />
      <path d="M14.5417 40.168H16.275V38.4346H14.5417V40.168Z" fill="#38859F" />
      <path
        d="M12.8083 40.168H14.5417V38.4346H12.8083V40.168Z"
        fill="#56C2E7"
      />
      <path d="M11.075 40.168H12.8083V38.4346H11.075V40.168Z" fill="#56C2E7" />
      <path d="M9.34165 40.168H11.075V38.4346H9.34165V40.168Z" fill="#56C2E7" />
      <path
        d="M7.60831 40.168H9.34164V38.4346H7.60831V40.168Z"
        fill="#56C2E7"
      />
      <path
        d="M5.87497 40.168H7.60831V38.4346H5.87497V40.168Z"
        fill="#56C2E7"
      />
      <path d="M4.14167 40.168H5.875V38.4346H4.14167V40.168Z" fill="#56C2E7" />
      <path
        d="M2.40833 40.168H4.14166V38.4346H2.40833V40.168Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 40.168H2.40833V38.4346H0.674992L0.674992 40.168Z"
        fill="black"
      />
      <path
        d="M28.4083 38.4336H30.1417V36.7003H28.4083V38.4336Z"
        fill="black"
      />
      <path d="M26.675 38.4336H28.4083V36.7003H26.675V38.4336Z" fill="black" />
      <path d="M24.9417 38.4336H26.675V36.7003H24.9417V38.4336Z" fill="black" />
      <path
        d="M23.2083 38.4336H24.9417V36.7003H23.2083V38.4336Z"
        fill="black"
      />
      <path d="M21.475 38.4336H23.2083V36.7003H21.475V38.4336Z" fill="black" />
      <path
        d="M19.7416 38.4336H21.475V36.7003H19.7416V38.4336Z"
        fill="#292E3D"
      />
      <path
        d="M18.0083 38.4336H19.7417V36.7003H18.0083V38.4336Z"
        fill="black"
      />
      <path d="M16.275 38.4336H18.0083V36.7003H16.275V38.4336Z" fill="black" />
      <path
        d="M14.5417 38.4336H16.275V36.7003H14.5417V38.4336Z"
        fill="#56C2E7"
      />
      <path
        d="M12.8083 38.4336H14.5417V36.7003H12.8083V38.4336Z"
        fill="#56C2E7"
      />
      <path
        d="M11.075 38.4336H12.8083V36.7003H11.075V38.4336Z"
        fill="#56C2E7"
      />
      <path
        d="M9.34165 38.4336H11.075V36.7003H9.34165V38.4336Z"
        fill="#56C2E7"
      />
      <path
        d="M7.60831 38.4336H9.34164V36.7003H7.60831V38.4336Z"
        fill="#56C2E7"
      />
      <path
        d="M5.87497 38.4336H7.60831V36.7003H5.87497V38.4336Z"
        fill="#56C2E7"
      />
      <path
        d="M4.14167 38.4336H5.875V36.7003H4.14167V38.4336Z"
        fill="#56C2E7"
      />
      <path
        d="M2.40833 38.4336H4.14166V36.7003H2.40833V38.4336Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 38.4336H2.40833V36.7003H0.674992L0.674992 38.4336Z"
        fill="black"
      />
      <path
        d="M28.4083 36.7012H30.1417V34.9678H28.4083V36.7012Z"
        fill="black"
      />
      <path d="M26.675 36.7012H28.4083V34.9678H26.675V36.7012Z" fill="black" />
      <path d="M24.9417 36.7012H26.675V34.9678H24.9417V36.7012Z" fill="black" />
      <path
        d="M23.2083 36.7012H24.9417V34.9678H23.2083V36.7012Z"
        fill="black"
      />
      <path d="M21.475 36.7012H23.2083V34.9678H21.475V36.7012Z" fill="black" />
      <path d="M19.7416 36.7012H21.475V34.9678H19.7416V36.7012Z" fill="black" />
      <path
        d="M18.0083 36.7012H19.7417V34.9678H18.0083V36.7012Z"
        fill="#292E3D"
      />
      <path d="M16.275 36.7012H18.0083V34.9678H16.275V36.7012Z" fill="black" />
      <path
        d="M14.5417 36.7012H16.275V34.9678H14.5417V36.7012Z"
        fill="#56C2E7"
      />
      <path
        d="M12.8083 36.7012H14.5417V34.9678H12.8083V36.7012Z"
        fill="#56C2E7"
      />
      <path
        d="M11.075 36.7012H12.8083V34.9678H11.075V36.7012Z"
        fill="#56C2E7"
      />
      <path
        d="M9.34165 36.7012H11.075V34.9678H9.34165V36.7012Z"
        fill="#56C2E7"
      />
      <path
        d="M7.60831 36.7012H9.34164V34.9678H7.60831V36.7012Z"
        fill="#56C2E7"
      />
      <path
        d="M5.87497 36.7012H7.60831V34.9678H5.87497V36.7012Z"
        fill="#56C2E7"
      />
      <path
        d="M4.14167 36.7012H5.875V34.9678H4.14167V36.7012Z"
        fill="#56C2E7"
      />
      <path
        d="M2.40833 36.7012H4.14166V34.9678H2.40833V36.7012Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 36.7012H2.40833V34.9678H0.674992L0.674992 36.7012Z"
        fill="black"
      />
      <path
        d="M28.4083 34.9668H30.1417V33.2335H28.4083V34.9668Z"
        fill="black"
      />
      <path d="M26.675 34.9668H28.4083V33.2335H26.675V34.9668Z" fill="black" />
      <path d="M24.9417 34.9668H26.675V33.2335H24.9417V34.9668Z" fill="black" />
      <path
        d="M23.2083 34.9668H24.9417V33.2335H23.2083V34.9668Z"
        fill="black"
      />
      <path d="M21.475 34.9668H23.2083V33.2335H21.475V34.9668Z" fill="black" />
      <path d="M19.7416 34.9668H21.475V33.2335H19.7416V34.9668Z" fill="black" />
      <path
        d="M18.0083 34.9668H19.7417V33.2335H18.0083V34.9668Z"
        fill="#292E3D"
      />
      <path d="M16.275 34.9668H18.0083V33.2335H16.275V34.9668Z" fill="black" />
      <path
        d="M14.5417 34.9668H16.275V33.2335H14.5417V34.9668Z"
        fill="#56C2E7"
      />
      <path
        d="M12.8083 34.9668H14.5417V33.2335H12.8083V34.9668Z"
        fill="#56C2E7"
      />
      <path
        d="M11.075 34.9668H12.8083V33.2335H11.075V34.9668Z"
        fill="#56C2E7"
      />
      <path
        d="M9.34165 34.9668H11.075V33.2335H9.34165V34.9668Z"
        fill="#FE62B0"
      />
      <path
        d="M7.60831 34.9668H9.34164V33.2335H7.60831V34.9668Z"
        fill="#EF3A78"
      />
      <path
        d="M5.87497 34.9668H7.60831V33.2335H5.87497V34.9668Z"
        fill="#56C2E7"
      />
      <path
        d="M4.14167 34.9668H5.875V33.2335H4.14167V34.9668Z"
        fill="#56C2E7"
      />
      <path
        d="M2.40833 34.9668H4.14166V33.2335H2.40833V34.9668Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 34.9668H2.40833V33.2335H0.674992L0.674992 34.9668Z"
        fill="black"
      />
      <path d="M28.4083 33.2344H30.1417V31.501H28.4083V33.2344Z" fill="black" />
      <path d="M26.675 33.2344H28.4083V31.501H26.675V33.2344Z" fill="black" />
      <path d="M24.9417 33.2344H26.675V31.501H24.9417V33.2344Z" fill="black" />
      <path d="M23.2083 33.2344H24.9417V31.501H23.2083V33.2344Z" fill="black" />
      <path d="M21.475 33.2344H23.2083V31.501H21.475V33.2344Z" fill="black" />
      <path d="M19.7416 33.2344H21.475V31.501H19.7416V33.2344Z" fill="black" />
      <path
        d="M18.0083 33.2344H19.7417V31.501H18.0083V33.2344Z"
        fill="#292E3D"
      />
      <path d="M16.275 33.2344H18.0083V31.501H16.275V33.2344Z" fill="black" />
      <path
        d="M14.5417 33.2344H16.275V31.501H14.5417V33.2344Z"
        fill="#56C2E7"
      />
      <path
        d="M12.8083 33.2344H14.5417V31.501H12.8083V33.2344Z"
        fill="#56C2E7"
      />
      <path d="M11.075 33.2344H12.8083V31.501H11.075V33.2344Z" fill="#56C2E7" />
      <path
        d="M9.34165 33.2344H11.075V31.501H9.34165V33.2344Z"
        fill="#FE62B0"
      />
      <path
        d="M7.60831 33.2344H9.34164V31.501H7.60831V33.2344Z"
        fill="#EF3A78"
      />
      <path
        d="M5.87497 33.2344H7.60831V31.501H5.87497V33.2344Z"
        fill="#56C2E7"
      />
      <path d="M4.14167 33.2344H5.875V31.501H4.14167V33.2344Z" fill="#56C2E7" />
      <path
        d="M2.40833 33.2344H4.14166V31.501H2.40833V33.2344Z"
        fill="#8FE7FF"
      />
      <path
        d="M0.674992 33.2344H2.40833V31.501H0.674992L0.674992 33.2344Z"
        fill="black"
      />
      <path d="M44.0083 8.9668H42.275V10.7001H44.0083V8.9668Z" fill="black" />
      <path d="M45.7417 8.9668H44.0083V10.7001H45.7417V8.9668Z" fill="black" />
      <path d="M42.275 10.7012H40.5417V12.4345H42.275V10.7012Z" fill="black" />
      <path d="M44.0083 10.7012H42.275V12.4345H44.0083V10.7012Z" fill="black" />
      <path d="M44.0083 12.4336H42.275V14.1669H44.0083V12.4336Z" fill="black" />
      <path
        d="M45.7417 12.4336H44.0083V14.1669H45.7417V12.4336Z"
        fill="black"
      />
      <path d="M42.275 14.168H40.5417V15.9013H42.275V14.168Z" fill="black" />
      <path d="M45.7416 52.3086H47.475V50.5753H45.7416V52.3086Z" fill="black" />
      <path
        d="M44.0083 52.3086H45.7416V50.5753H44.0083V52.3086Z"
        fill="black"
      />
      <path d="M47.475 50.5762H49.2083V48.8428H47.475V50.5762Z" fill="black" />
      <path d="M45.7416 50.5762H47.475V48.8428H45.7416V50.5762Z" fill="black" />
      <path d="M45.7416 48.8438H47.475V47.1104H45.7416V48.8438Z" fill="black" />
      <path
        d="M44.0083 48.8438H45.7416V47.1104H44.0083V48.8438Z"
        fill="black"
      />
      <path d="M47.475 47.1094H49.2083V45.376H47.475V47.1094Z" fill="black" />
      <path d="M18.0083 50.5684H16.275V52.3017H18.0083V50.5684Z" fill="black" />
      <path
        d="M19.7417 50.5684H18.0083V52.3017H19.7417V50.5684Z"
        fill="black"
      />
      <path d="M16.275 48.834H14.5417V50.5673H16.275V48.834Z" fill="black" />
      <path d="M18.0083 48.834H16.275V50.5673H18.0083V48.834Z" fill="black" />
      <path d="M18.0083 47.1016H16.275V48.8349H18.0083V47.1016Z" fill="black" />
      <path
        d="M19.7417 47.1016H18.0083V48.8349H19.7417V47.1016Z"
        fill="black"
      />
      <path d="M16.275 45.3672H14.5417V47.1005H16.275V45.3672Z" fill="black" />
      <path d="M57.0083 33.2333V31.5H55.275V33.2333H57.0083Z" fill="black" />
      <path d="M57.0083 34.9677V33.2344H55.275V34.9677H57.0083Z" fill="black" />
      <path d="M55.275 31.5009V29.7676H53.5417V31.5009H55.275Z" fill="black" />
      <path d="M55.275 33.2333V31.5H53.5417V33.2333H55.275Z" fill="black" />
      <path d="M53.5417 33.2333V31.5H51.8083V33.2333H53.5417Z" fill="black" />
      <path
        d="M53.5417 34.9677V33.2344H51.8083V34.9677H53.5417Z"
        fill="black"
      />
      <path d="M51.8083 31.5009V29.7676H50.075V31.5009H51.8083Z" fill="black" />
      <path
        d="M16.2923 15.9004H18.0257V14.1671H16.2923V15.9004Z"
        fill="#FE62B0"
      />
      <path
        d="M19.759 12.4336H21.4923V10.7003H19.759V12.4336Z"
        fill="#EF3A78"
      />
      <path
        d="M18.0257 12.4336H19.759V10.7003H18.0257V12.4336Z"
        fill="#EF3A78"
      />
      <path d="M19.759 14.168H21.4923V12.4346H19.759V14.168Z" fill="#FE62B0" />
      <path d="M18.0257 14.168H19.759V12.4346H18.0257V14.168Z" fill="#FE62B0" />
      <path
        d="M16.2923 14.168H18.0257V12.4346H16.2923V14.168Z"
        fill="#EF3A78"
      />
      <path
        d="M18.0257 15.9004H19.759V14.1671H18.0257V15.9004Z"
        fill="#FE62B0"
      />
      <path d="M14.559 14.168H16.2923V12.4346H14.559V14.168Z" fill="#EF3A78" />
      <path
        d="M14.559 15.9004H16.2923V14.1671H14.559V15.9004Z"
        fill="#FE62B0"
      />
    </svg>
  );
};
