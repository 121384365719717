import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Card } from '@/components/ui/molecules/Card';
import { MenuItemPop } from '@/components/ui/molecules/MenuItemPop';
import {
  Center,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { MatchmakingButton } from '../battle/Matchmacking/MatchmakingButton';
import { Button } from '../ui/molecules/Button';
import { HomeBattle } from '../ui/icons/HomeBattle';

export const BattleButton = ({
  isLoading,
  createBattle,
  searchParams,
}: {
  isLoading: boolean;
  createBattle: (vsCPU: boolean) => void;
  searchParams: URLSearchParams;
}) => {
  const { onClose, isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Popover placement="bottom-end" onClose={onClose} isOpen={isOpen}>
        <PopoverTrigger>
          <Center w="104px" minW="104px" position="relative" h="full">
            <Card
              className="animate-color-cycle"
              height="96px"
              w="full"
              flexDirection="column"
              gap={0}
              padding={0}
              borderBottom={0}
              position="absolute"
              bottom={0}
              onClick={() => {
                onToggle();
              }}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <HomeBattle />
              <Text
                colorId="Black"
                fontSize={'10px'}
                lineHeight={'10px'}
                w="full"
                textAlign={'center'}
              >
                <TranslatedText
                  translationKey="battle"
                  defaultMessage="battle"
                />
                !
              </Text>
            </Card>
          </Center>
        </PopoverTrigger>
        <PopoverContent
          border={`none`}
          sx={{
            '&:focus, &:focus-visible': {
              outline: 'none !important',
              boxShadow: 'none !important',
            },
          }}
        >
          <Card>
            <MatchmakingButton auto={searchParams.get('auto') === 'true'} />
            <MenuItemPop
              width="full"
              color="black"
              onClick={() => createBattle(false)}
              disabled={isLoading}
            >
              <Text colorId={`Black`}>
                {isLoading ? (
                  <>
                    <TranslatedText
                      translationKey={`loading`}
                      defaultMessage={`Loading`}
                    />
                    ...
                  </>
                ) : (
                  <TranslatedText
                    translationKey={`vsFren`}
                    defaultMessage={`VS Fren`}
                  />
                )}
              </Text>
            </MenuItemPop>
            <Button colorId="Black" w="full" onClick={onClose}>
              <TranslatedText
                translationKey={`textBack`}
                defaultMessage={`Back`}
              />
            </Button>
          </Card>
        </PopoverContent>
      </Popover>
    </>
  );
};
