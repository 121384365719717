import { BattleState, RootState } from 'genopets-utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUIBattleContext } from '../context/useBattleContext';

export const useReadUiEvents = () => {
  const { uiEvent, setUiEvent } = useUIBattleContext();

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  useEffect(() => {
    if (!uiEvent) {
      const nextUiEvent = battleState.uiEvents?.[0];

      if (nextUiEvent) {
        setUiEvent(nextUiEvent);
      }
    }
  }, [battleState, uiEvent, setUiEvent]);
};
