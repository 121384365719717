import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { useAlert } from '@/hooks/useAlert';
import { getPetName } from '@/utils/utils';
import { Box, VStack } from '@chakra-ui/react';
import {
  BattleState,
  PetStatisticsEnum,
  RootState,
  StatusEffect,
  UiEventPlayTickStatus,
  UiEventType,
  getPlayerData,
} from 'genopets-utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text } from '../../ui/atoms/Text';
import { useUIBattleContext } from '../context/useBattleContext';

const getEffectApplied = (
  status: StatusEffect[],
  name: string,
  translateText: any,
) => {
  if (!status.length) {
    return null;
  }
  return status.map((status, i) => {
    return (
      <Box key={i}>
        {status.value ? (
          <>
            <Text as="span" colorId="Black">
              <TranslatedText
                translationKey="possessiveS"
                defaultMessage={`${name}'s ${status.statAffected}`}
                props={{ name: name, stats: status.statAffected }}
              />
            </Text>
            <Text as="span" colorId="Black">
              {` ${translateText('went', { defaultValue: 'went' })}`}
            </Text>
            <Text
              as="span"
              colorId={status.value.startsWith(`-`) ? 'Red' : 'Green'}
            >
              {status.value.startsWith(`-`)
                ? ` ${translateText('down', { defaultValue: 'down' })}`
                : ` ${translateText('up', { defaultValue: 'up' })}`}
            </Text>
            <Text as="span" colorId="Black">
              {`! ${translateText('dueTo', { defaultValue: 'due to' })} ${translateText(status.name, status.name)}`}
            </Text>
          </>
        ) : (
          <Text as="span" colorId="Black">
            {translateText(status.name, status.name)}
          </Text>
        )}
      </Box>
    );
  });
};

export const useEndTurn = () => {
  const { uiEvent, dispatchUiEventPlayed } = useUIBattleContext();

  const [playTickStatus, setPlayTickStatus] =
    useState<UiEventPlayTickStatus['payload']>();

  const { t: translateText } = useTranslation();

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const alert = useAlert();

  useEffect(() => {
    if (uiEvent?.type === UiEventType.PLAY_TICK_STATUS) {
      setPlayTickStatus(uiEvent.payload);
    }
  }, [uiEvent]);

  useEffect(() => {
    // wait for play move animation to be played ans toast to not be visible before end round
    if (playTickStatus && uiEvent) {
      const status = playTickStatus.status;

      // we display only the effect that impact the HP
      if (status.statAffected === PetStatisticsEnum.HP) {
        const petName = getPetName(
          getPlayerData(battleState, playTickStatus.targetPlayerId).petInfo,
          'player 1',
        ) as string;

        const title = (
          <VStack alignItems={'flex-start'}>
            {getEffectApplied([status], petName, translateText)}
          </VStack>
        );

        alert({
          title,
          onCloseComplete: () => {
            dispatchUiEventPlayed(uiEvent.id);
          },
        });
      } else {
        dispatchUiEventPlayed(uiEvent.id);
      }
    }
  }, [playTickStatus]);
};
