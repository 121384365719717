import ReactDOM from 'react-dom/client';

import { Root } from '@/components/Root';

// Uncomment this import in case, you would like to develop the application even outside
// the Telegram application, just in your browser.
import './mockEnv.ts';

import '@telegram-apps/telegram-ui/dist/styles.css';
import './index.css';
import './i18n.ts';
import './utils/instrument';

declare global {
  interface Window {
    telegramAnalytics?: {
      init: (options: { token: string; appName: string }) => void;
    };
  }
}

// Initialize Telegram Analytics SDK
window?.telegramAnalytics?.init({
  token: import.meta.env.VITE_TELEGRAM_ANALYTICS_TOKEN,
  appName: import.meta.env.VITE_TELEGRAM_ANALYTICS_APP_NAME,
});

ReactDOM.createRoot(document.getElementById('root')!).render(<Root />);
