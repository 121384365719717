import { cloneDeep, isArray } from 'lodash';
import { coerceArray } from '../../utils';
import {
  BattleActionEntity,
  BattleMoveEntity,
  MoveType,
  Rolls,
  RollType,
} from '../models/battle-action.entity';
import { BattlePet } from '../models/battle-pet';
import { BattleState, UiEventType } from '../models/battle-state';
import {
  CRITICAL_HIT_VALUE,
  getOpponentData,
  getPlayerData,
} from './battle-utils';
import {
  getElementalStatus,
  isElementStrongAgainst,
  isElementWeekAgainst,
} from './elemental';
import { getRandomInt } from './random';
import {
  applyStatus,
  checkIfCanCounterAttack,
  getCounterAttackStatus,
  getShieldedStatus,
  getStatusMultiplier,
  getStatusValue,
} from './status';

/**
 * The calculateDamage function calculates the damage done by an attack.
 * The function takes the attacking and defending pets, the move used, and any optional rolls that might affect the damage calculation.
 * The function considers elemental advantages/disadvantages and randomness to determine the final damage.
 *
 * Flow:
 * Calculates elemental advantages/disadvantages.
 * Generates random values for dodge, critical, and power (if not provided via rolls).
 * Computes attack and defense stats with possible elemental coefficients.
 *
 * @param attackingPet pet launching the attack
 * @param defendingPet pet receiving the attack
 * @param move attack used
 * @param rolls roll performed (used in case of replay)
 * @returns the final calculated damage and the rolls used in the calculation.
 */
export function calculateDamage(
  attackingPet: BattlePet,
  defendingPet: BattlePet,
  move: BattleMoveEntity,
  rolls?: Rolls,
): {
  damage: number[];
  totalDamage: number;
  rolls: Rolls;
} {
  // calculation rules https://docs.google.com/spreadsheets/d/178wFvjl_MZe6eGCsQgrB9epruYJQF8D2mXFh4tZb-x8/edit#gid=1309051270
  const result: { damage: number; rolls: Rolls }[] = [];
  const hit = move.hitNumber ?? 1;
  for (let i = 0; i < hit; i++) {
    const atkElement = getElementalStatus(attackingPet); // get elemental status
    const defElement = getElementalStatus(defendingPet); // get elemental status

    const haveElementAdvantage = isElementStrongAgainst(atkElement, defElement);
    const haveElementDisadvantage = isElementWeekAgainst(
      atkElement,
      defElement,
    );

    let atkElementalCoefficient = 1;
    if (haveElementAdvantage) {
      // ATK boosts 10% if the player goes into Battle against an Element that is Weak to their given Element.
      atkElementalCoefficient = 1.1;
    } else if (haveElementDisadvantage) {
      // Nothing but eliminate this case from the next one
      atkElementalCoefficient = 1;
    } else if (atkElement) {
      // ATK boosts 5% for any neutral type - which includes pets of no element, or pets with an element that is neither strong nor weak to the given crystal type.
      atkElementalCoefficient = 1.05;
    }

    let defElementalCoefficient = 1;
    if (haveElementAdvantage) {
      // DEF gets a debuff of 5% against a Strong element.
      defElementalCoefficient = 0.95;
    }

    const attackingPetAccuracy =
      attackingPet.base.ACC *
      getStatusMultiplier(attackingPet.statusModifier?.ACC);
    const defendingPetStability =
      defendingPet.base.STB *
      getStatusMultiplier(defendingPet.statusModifier?.STB);

    const critical =
      rolls?.critical !== undefined
        ? coerceArray(rolls.critical)[i]
        : getRandomInt(
            1,
            Math.max(100 + (defendingPetStability - attackingPetAccuracy), 1), // minimum 1 to avoid errors
          );

    const roll =
      rolls?.power !== undefined
        ? coerceArray(rolls.power)[i]
        : getRandomInt(0, 40); // roll between (0 and 40)

    // if critical double move power
    const power =
      (critical <= CRITICAL_HIT_VALUE ? move.power * 2 : move.power) *
      attackingPet.powerModifier;
    const computedPower = power + power * (roll / 100); // eq between 1 and 1.4

    // this broke the old system that was using ATK and DEF (not base.ATK and base.DEF)
    // are default stats are not used anymore to calculate damage
    const attackingPetATK =
      attackingPet.base.ATK *
      getStatusMultiplier(attackingPet.statusModifier?.ATK);
    const defendingPetDEF =
      defendingPet.base.DEF *
      getStatusMultiplier(defendingPet.statusModifier?.DEF);

    // rolls have the priority
    const computedATK =
      (rolls?.atkElementalCoefficient !== undefined
        ? coerceArray(rolls.atkElementalCoefficient)[i]
        : atkElementalCoefficient) * attackingPetATK;

    // rolls have the priority
    const computedDEF =
      (rolls?.defElementalCoefficient !== undefined
        ? coerceArray(rolls.defElementalCoefficient)[i]
        : defElementalCoefficient) * defendingPetDEF;

    const damage = Math.floor(computedPower * (computedATK / computedDEF)); // not using decimal

    result.push({
      damage,
      rolls: {
        power: roll,
        critical,
        atkElementalCoefficient,
        defElementalCoefficient,
      },
    });
  }

  // remove damage from dodged hit
  const totalDamage = result.reduce((prev, curr) => prev + curr.damage, 0);

  // we are not using an array for rolls as others rolls (counter,block...) need to be uniq
  // so we convert the array in an object
  // This new structure will have roll types as keys, each containing an array of corresponding roll results.
  // The "reduce" function is used to transform the "result" array into a new format.
  const rollsFlat = result.reduce((prev, curr) => {
    // Here we create a clone of the previous object to avoid mutating the original one.
    const agg = { ...prev };
    // We go through each roll type (key) in the current object's "rolls" property.
    (Object.keys(curr.rolls) as RollType[]).forEach((key) => {
      if (agg[key]) {
        // Check if the corresponding value is already an array. If so, simply push the new roll result into this array.
        if (isArray(agg[key])) {
          (agg[key] as number[]).push(curr.rolls[key] as number);
        } else {
          // If the corresponding value is not an array (meaning this is the second instance of this roll type),
          // we need to transform the value into an array containing both roll results.
          agg[key] = [agg[key] as number, curr.rolls[key] as number];
        }
      } else {
        // If the roll type doesn't exist in the accumulated object, simply add it.
        agg[key] = curr.rolls[key];
      }
    });
    return agg;
  }, {} as Rolls);

  return {
    damage: result.map((r) => r.damage),
    totalDamage,
    rolls: rollsFlat,
  };
}

/**
 * The calculateDamageWithBlock function calculates the damage after a defensive block action.
 * The function takes initial damage and reduction percentage obtained by the block and returns the new damage which could be reduced or even increased (if the block fails).
 *
 * Flow:
 * Calculates new damage based on the reduction percentage.
 *
 * @param damage initial damage done
 * @param reductionPercentage reduction obtain by the block (input front the front link to agility game)
 * @returns new damage reduced or augmented
 */
export function calculateDamageWithBlock(
  damage: number,
  reductionPercentage: number,
): { damage: number; reductionPercentage: number } {
  // reduction damage can be negative to raise damage
  // -40/100=-0.4
  // 1--0.4=1.4
  // 1.4 * x (raise damage)
  const multiplier = reductionPercentage / 100; // 40 => 0.4
  const damageAfterBlock = damage * (1 - multiplier); // 1-0.4 = 0.6 * x (reduction damage)

  return { damage: Math.floor(damageAfterBlock), reductionPercentage };
}

/**
 * Apply damage to the pet
 * @param pet the pet
 * @param damage the damages
 * @returns pet with updated stats
 */
export function applyDamage(pet: BattlePet, damage: number): BattlePet {
  const shield = getShieldedStatus(pet);

  let appliedDamage = damage;

  if (shield && shield.value) {
    // the shielded status have been registered as flat amount, no need ref
    const shieldValue = getStatusValue(shield);
    // do not apply negative damage
    appliedDamage = Math.max(0, damage - shieldValue);
    // update ref value of the status
    // cannot be negative
    shield.value = `${Math.max(0, shieldValue - damage)}`;
  }

  return {
    ...pet,
    HP: pet.HP - appliedDamage,
  };
}

/**
 * The processPetHit function applies the damage to the defender and checks for possible counter-attacks. It takes the current BattleState, damage, move, attacker's ID, and action as parameters.
 *
 * Flow:
 * Applies the damage to the defending pet.
 * Applies any status effects on the defender.
 * Checks if the defender can counter-attack and is not KO.
 * If a counter-attack is possible, calculates the counter damage and applies it to the attacker.
 *
 * @param {BattleState} state - The current state of the battle.
 * @param {number} damage - The amount of damage to be applied.
 * @param {BattleMoveEntity} move - The move used in the battle.
 * @param {string} attackerId - The ID of the attacking pet.
 * @param {BattleActionEntity} action - The action taken in the battle.
 * @return {{ state: BattleState; rolls?: Rolls }} the updated BattleState and any rolls performed during counter-attack calculation.
 */
export function processPetHit(
  state: BattleState,
  damage: number,
  move: BattleMoveEntity,
  attackerId: string,
  action: BattleActionEntity,
): { state: BattleState; rolls?: Rolls } {
  let newState = cloneDeep(state);

  let defender = getOpponentData(newState, attackerId);

  // apply damage
  defender.pet = applyDamage(defender.pet, damage);

  // Update reference
  defender = getOpponentData(newState, attackerId);

  const attacker = getPlayerData(newState, attackerId);

  let rolls: Rolls = {};
  // pet can counter and is not KO
  if (checkIfCanCounterAttack(defender.pet) && defender.pet.HP > 0) {
    const counter = getCounterAttackStatus(defender.pet);
    const counterMove = {
      power: parseInt(counter!.value!, 10),
      name: counter?.name || `counter`,
      cooldown: 0,
      types: [MoveType.ATK],
    };
    const { totalDamage, rolls: rollsDamage } = calculateDamage(
      defender.pet,
      attacker.pet,
      counterMove,
      {
        critical: 2, // no critical allowed
        power: action.rolls?.counter,
      },
    );

    rolls = { counter: rollsDamage.power };

    // apply counter damage
    attacker.pet = applyDamage(attacker.pet, totalDamage);

    if (state.withUiEvents && newState.uiEvents) {
      newState.uiEvents.push({
        type: UiEventType.PLAY_COUNTER_MOVE,
        id: newState.uiEvents.length.toString(),
        payload: {
          move: counterMove,
          dodged: false,
          damage: totalDamage,
          initiatorPlayerId: defender.id,
          targetPlayerId: attacker.id,
        },
      });
    }
  }

  // block or not, always apply status
  // this behavior could change in the future (or according the status effect)
  newState = applyStatus(move, `opponent`, defender, newState);

  return { state: newState, rolls };
}
