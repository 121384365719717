import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Ticket } from '@/components/ui/icons/Ticket';
import { Button } from '@/components/ui/molecules/Button';
import {
  CLAIM_DAILY_TICKETS_ENDPOINT,
  PURCHASE_DAILY_TICKETS_WITH_MULTIPLIER_ENDPOINT,
} from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import TelegramStar from '@/images/telegram-star.png';
import Whittakr from '@/images/whittakr.png';
import { Box, Flex } from '@chakra-ui/react';
import { initInvoice } from '@tma.js/sdk-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSpinWheelSettings } from './hooks/useSpinWheelSettings';
import { Modal } from '@/components/ui/molecules/Modal';
import { useUserData } from '@/context/FirestoreContext';

export const TICKET_NOT_PAID_PREFIX = 'ticketNotPaidPrefix-';

export const ClaimDailyTicketsModal = ({
  isOpen,
  onClose,
  ticketsToClaim = 0,
}: {
  ticketsToClaim: number;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { apiPost } = useAPI();
  const alert = useAlert();
  const { userData } = useUserData();
  const settings = useSpinWheelSettings();
  const ticketRequireToSpin = settings?.numOfTicketsRequired ?? 5;
  const navigate = useNavigate();

  const [isClaiming, setIsClaiming] = useState<
    'normal' | 'multiplier' | undefined
  >();

  useEffect(() => {
    if (isClaiming) {
      setTimeout(() => {
        // there's no event when telegram IAP modal is closed ie when user cancelled the IAP modal
        // so adding this to enable the buttons after few seconds
        setIsClaiming(undefined);
      }, 5000);
    }
  }, [isClaiming]);

  const claimFree = () => {
    setIsClaiming(`normal`);

    apiPost(CLAIM_DAILY_TICKETS_ENDPOINT, {})
      .then(({ claimed, tickets }) => {
        if (claimed) {
          alert({
            title: (
              <Flex alignItems={`center`} gap={`4px`}>
                <Ticket />
                <Text colorId={`Black`} mt={`1px`}>
                  <TranslatedText
                    translationKey={`ticketsClaimed`}
                    defaultMessage={`${tickets} claimed`}
                    props={{ amount: tickets }}
                  />
                  !
                </Text>
              </Flex>
            ),
          });
        }
        onClose();
      })
      .catch((error: any) => {
        alert({
          title: error.message || 'Error',
        });
      })
      .finally(() => {
        setIsClaiming(undefined);
      });
  };

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      showBackButton={false}
      contentProps={{ maxWidth: `298px`, padding: 0 }}
      cardProps={{ padding: 0, gap: 0 }}
    >
      <Box padding={`24px`} bgColor={`black`}>
        <Text colorId="White" textAlign={`center`}>
          <TranslatedText
            translationKey="offlineEarnings"
            defaultMessage="offline earnings"
          />
          !
        </Text>
      </Box>

      <Box padding={`16px`} minHeight={`200px`} position={`relative`}>
        <Box
          position="absolute"
          top="20px"
          left="30px"
          bgColor="black"
          maxW="128px"
          zIndex={10}
          padding={`4px 0 4px 8px`}
          _after={{
            content: `''`,
            position: 'absolute',
            bottom: '-10px',
            left: '80%',
            transform: 'translateX(-50%)',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: '10px solid black',
          }}
        >
          <Text colorId="White">
            <TranslatedText
              translationKey="welcomeBack"
              defaultMessage="WELCOME BACK"
            />
            !
          </Text>
        </Box>

        <img
          src={Whittakr}
          alt={'Whittakr'}
          style={{
            width: '205px',
            height: '220px',
            transform: 'rotate(-7.786deg)',
            position: 'absolute',
            top: 20,
            left: 35,
            zIndex: 1,
          }}
        />
      </Box>

      <Box textAlign={`center`}>
        <Text colorId="Black">
          <TranslatedText
            defaultMessage="You earned"
            translationKey="youEarned"
          />
          :
        </Text>
        <Flex alignItems={'center'} justifyContent={`center`}>
          <Ticket />
          <Text colorId={`Black`}>{ticketsToClaim}</Text>
        </Flex>
      </Box>

      <Box w={`full`} padding={`16px`}>
        <Button
          colorId={`Black`}
          w={`full`}
          padding={`12px 8px`}
          isLoading={isClaiming === 'normal'}
          isDisabled={isClaiming !== undefined}
          onClick={claimFree}
        >
          <Flex justifyContent={`space-between`} alignItems={`center`}>
            <Text colorId="White">
              <TranslatedText translationKey="claim" defaultMessage="CLAIM" />
            </Text>

            <Text colorId="White" opacity={0.8}>
              <TranslatedText translationKey="free" defaultMessage="free" />
            </Text>
          </Flex>
        </Button>

        <Button
          colorId={`Black`}
          w={`full`}
          mt={`8px`}
          bgColor={`#EBDC53`}
          border={`3px solid black`}
          padding={`12px 6px`}
          isLoading={isClaiming === 'multiplier'}
          isDisabled={isClaiming !== undefined}
          onClick={() => {
            if (isClaiming) return;

            setIsClaiming('multiplier');
            const invoice = initInvoice();
            apiPost(PURCHASE_DAILY_TICKETS_WITH_MULTIPLIER_ENDPOINT, {})
              .then((result: any) => invoice.open(result?.invoiceUrl, 'url'))
              .then((status: string) => {
                if (status !== 'paid') {
                  alert({
                    title: t(TICKET_NOT_PAID_PREFIX + status, 'cancelled'),
                  });
                } else {
                  onClose();
                  navigate('/spinner?tickets=' + 12);
                }
              });
          }}
        >
          <Flex justifyContent={`space-between`} alignItems={`center`}>
            <Flex gap={`12px`}>
              <Text colorId="Black">
                <TranslatedText translationKey="claim" defaultMessage="CLAIM" />
              </Text>
              <Text colorId="Black">
                <TranslatedText translationKey="4X" defaultMessage="4X" />
              </Text>
            </Flex>

            <Flex flexDirection={`column`}>
              <Flex
                alignItems={`center`}
                opacity={0.5}
                justifyContent={`flex-end`}
                position={`relative`}
              >
                <Box
                  border={`solid 0.75px black`}
                  position={`absolute`}
                  w={`full`}
                  maxWidth={`50px`}
                  transform={'rotate(-11deg)'}
                  bottom={2}
                  left={4}
                />
                <img
                  src={TelegramStar}
                  alt={'TelegramStar'}
                  style={{ width: 14.6, height: 14.6 }}
                />
                <Text colorId="Black" fontSize={`10px`}>
                  300
                </Text>
              </Flex>

              <Flex alignItems={`center`}>
                <img
                  src={TelegramStar}
                  alt={'TelegramStar'}
                  style={{ width: 22, height: 22 }}
                />
                <Text colorId="Black">249</Text>
              </Flex>
            </Flex>
          </Flex>
        </Button>
      </Box>
    </Modal>
  );
};
