/* eslint-disable no-unused-vars */
import { bgSounds, sounds, startBattle } from '@/lib/sounds';
import useSound from 'use-sound';

export const PreloadSounds = () => {
  // preload sounds
  const sound1 = useSound(sounds.newMove, { volume: 0 });
  const sound2 = useSound(sounds.petLevelUp, { volume: 0 });
  const sound3 = useSound(sounds.newMoveLearned, { volume: 0 });
  const sound4 = useSound(sounds.summoned, { volume: 0 });
  const sound5 = useSound(sounds.dodge, { volume: 0 });
  const sound6 = useSound(sounds.buff, { volume: 0 });
  const sound7 = useSound(sounds.critical, { volume: 0 });
  const sound8 = useSound(sounds.nerf, { volume: 0 });
  const sound9 = useSound(sounds.faint, { volume: 0 });
  const sound10 = useSound(startBattle, { volume: 0 });
  const sound11 = useSound(sounds.newMoveLearned, { volume: 0 });

  // preload bg music
  const bgMusic1 = useSound(bgSounds.battle, { volume: 0 });
  const bgMusic2 = useSound(bgSounds.leaderboard, { volume: 0 });
  const bgMusic3 = useSound(bgSounds.menu, { volume: 0 });
  const bgMusic4 = useSound(bgSounds.spinner, { volume: 0 });
  const bgMusic5 = useSound(bgSounds.stats, { volume: 0 });
  const bgMusic6 = useSound(bgSounds.summon, { volume: 0 });
  const bgMusic7 = useSound(bgSounds.viralLoopPage, { volume: 0 });

  return <></>;
};
