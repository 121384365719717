export const HomeFeedToEarn = ({ ...props }) => {
  return (
    <svg
      width="76"
      height="75"
      viewBox="0 0 76 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4351 28.0801H13.7151V28.8001H14.4351V28.0801Z"
        fill="#FDAE35"
      />
      <path
        d="M14.4351 28.8008H13.7151V29.5208H14.4351V28.8008Z"
        fill="#FDE962"
      />
      <path
        d="M13.7151 28.8008H12.9951V29.5208H13.7151V28.8008Z"
        fill="#FDAE35"
      />
      <path
        d="M14.4351 29.5195H13.7151V30.2395H14.4351V29.5195Z"
        fill="#FDE962"
      />
      <path
        d="M13.7151 29.5195H12.9951V30.2395H13.7151V29.5195Z"
        fill="#FDAE35"
      />
      <path d="M14.4351 30.25H13.7151V30.97H14.4351V30.25Z" fill="#FDE962" />
      <path d="M13.7151 30.25H12.9951V30.97H13.7151V30.25Z" fill="#FDE962" />
      <path
        d="M14.4351 30.9707H13.7151V31.6907H14.4351V30.9707Z"
        fill="#FDE962"
      />
      <path
        d="M13.7151 30.9707H12.9951V31.6907H13.7151V30.9707Z"
        fill="#FDE962"
      />
      <path d="M12.9951 30.25H12.2751V30.97H12.9951V30.25Z" fill="#FDAE35" />
      <path
        d="M12.9951 30.9707H12.2751V31.6907H12.9951V30.9707Z"
        fill="#FDAE35"
      />
      <path
        d="M14.4351 31.6895H13.7151V32.4095H14.4351V31.6895Z"
        fill="#EBEFF0"
      />
      <path
        d="M13.7151 31.6895H12.9951V32.4095H13.7151V31.6895Z"
        fill="#FDE962"
      />
      <path
        d="M12.9951 31.6895H12.2751V32.4095H12.9951V31.6895Z"
        fill="#FDE962"
      />
      <path
        d="M12.2751 31.6895H11.5551V32.4095H12.2751V31.6895Z"
        fill="#FDAE35"
      />
      <path
        d="M11.5451 31.6895H10.8251V32.4095H11.5451V31.6895Z"
        fill="#FDAE35"
      />
      <path
        d="M12.9951 32.4102H12.2751V33.1302H12.9951V32.4102Z"
        fill="#FDE962"
      />
      <path
        d="M12.2751 32.4102H11.5551V33.1302H12.2751V32.4102Z"
        fill="#FDE962"
      />
      <path
        d="M11.5451 32.4102H10.8251V33.1302H11.5451V32.4102Z"
        fill="#FDAE35"
      />
      <path
        d="M10.8251 32.4102H10.1051V33.1302H10.8251V32.4102Z"
        fill="#FDAE35"
      />
      <path
        d="M10.1051 32.4102H9.38507V33.1302H10.1051V32.4102Z"
        fill="#FDAE35"
      />
      <path
        d="M14.4351 32.4102H13.7151V33.1302H14.4351V32.4102Z"
        fill="#EBEFF0"
      />
      <path
        d="M13.7151 32.4102H12.9951V33.1302H13.7151V32.4102Z"
        fill="#FDE962"
      />
      <path
        d="M12.9951 33.1309H12.2751V33.8509H12.9951V33.1309Z"
        fill="#EBEFF0"
      />
      <path
        d="M12.2751 33.1309H11.5551V33.8509H12.2751V33.1309Z"
        fill="#FDE962"
      />
      <path
        d="M11.5451 33.1309H10.8251V33.8509H11.5451V33.1309Z"
        fill="#FDE962"
      />
      <path
        d="M10.8251 33.1309H10.1051V33.8509H10.8251V33.1309Z"
        fill="#FDE962"
      />
      <path
        d="M10.1051 33.1309H9.38507V33.8509H10.1051V33.1309Z"
        fill="#FDAE35"
      />
      <path
        d="M9.38507 33.1309H8.66507V33.8509H9.38507V33.1309Z"
        fill="#FDAE35"
      />
      <path
        d="M8.65506 33.1309H7.93506V33.8509H8.65506V33.1309Z"
        fill="#FDAE35"
      />
      <path
        d="M14.4351 33.1309H13.7151V33.8509H14.4351V33.1309Z"
        fill="#EBEFF0"
      />
      <path
        d="M13.7151 33.1309H12.9951V33.8509H13.7151V33.1309Z"
        fill="#EBEFF0"
      />
      <path
        d="M14.4351 27.3594H13.7151V28.0794H14.4351V27.3594Z"
        fill="#FDAE35"
      />
      <path
        d="M14.4351 26.6309H13.7151V27.3509H14.4351V26.6309Z"
        fill="#FDAE35"
      />
      <path
        d="M13.7251 28.8008H14.4451V28.0808H13.7251V28.8008Z"
        fill="#FDAE35"
      />
      <path
        d="M13.7251 29.5195H14.4451V28.7995H13.7251V29.5195Z"
        fill="#FDE962"
      />
      <path
        d="M14.4451 29.5195H15.1651V28.7995H14.4451V29.5195Z"
        fill="#FDAE35"
      />
      <path d="M13.7251 30.25H14.4451V29.53H13.7251V30.25Z" fill="#FDE962" />
      <path d="M14.4451 30.25H15.1651V29.53H14.4451V30.25Z" fill="#FDAE35" />
      <path
        d="M13.7251 30.9609H14.4451V30.2409H13.7251V30.9609Z"
        fill="#FDE962"
      />
      <path
        d="M14.4451 30.9609H15.1651V30.2409H14.4451V30.9609Z"
        fill="#FDE962"
      />
      <path
        d="M13.7251 31.6895H14.4451V30.9695H13.7251V31.6895Z"
        fill="#FDE962"
      />
      <path
        d="M14.4451 31.6895H15.1651V30.9695H14.4451V31.6895Z"
        fill="#FDE962"
      />
      <path
        d="M15.1651 30.9609H15.8851V30.2409H15.1651V30.9609Z"
        fill="#FDAE35"
      />
      <path
        d="M15.1651 31.6895H15.8851V30.9695H15.1651V31.6895Z"
        fill="#FDAE35"
      />
      <path
        d="M13.7251 32.4102H14.4451V31.6902H13.7251V32.4102Z"
        fill="#EBEFF0"
      />
      <path
        d="M14.4451 32.4102H15.1651V31.6902H14.4451V32.4102Z"
        fill="#FDE962"
      />
      <path
        d="M15.1651 32.4102H15.8851V31.6902H15.1651V32.4102Z"
        fill="#FDE962"
      />
      <path
        d="M15.8851 32.4102H16.6051V31.6902H15.8851V32.4102Z"
        fill="#FDAE35"
      />
      <path
        d="M16.6151 32.4102H17.3351V31.6902H16.6151V32.4102Z"
        fill="#FDAE35"
      />
      <path
        d="M15.1651 33.1406H15.8851V32.4206H15.1651V33.1406Z"
        fill="#FDE962"
      />
      <path
        d="M15.8851 33.1406H16.6051V32.4206H15.8851V33.1406Z"
        fill="#FDE962"
      />
      <path
        d="M16.6151 33.1406H17.3351V32.4206H16.6151V33.1406Z"
        fill="#FDAE35"
      />
      <path
        d="M17.3351 33.1406H18.0551V32.4206H17.3351V33.1406Z"
        fill="#FDAE35"
      />
      <path d="M18.045 33.1406H18.765V32.4206H18.045V33.1406Z" fill="#FDAE35" />
      <path
        d="M13.7251 33.1406H14.4451V32.4206H13.7251V33.1406Z"
        fill="#EBEFF0"
      />
      <path
        d="M14.4451 33.1406H15.1651V32.4206H14.4451V33.1406Z"
        fill="#FDE962"
      />
      <path
        d="M15.1651 33.8594H15.8851V33.1394H15.1651V33.8594Z"
        fill="#EBEFF0"
      />
      <path
        d="M15.8851 33.8594H16.6051V33.1394H15.8851V33.8594Z"
        fill="#FDE962"
      />
      <path
        d="M16.6151 33.8594H17.3351V33.1394H16.6151V33.8594Z"
        fill="#FDE962"
      />
      <path
        d="M17.3351 33.8594H18.0551V33.1394H17.3351V33.8594Z"
        fill="#FDE962"
      />
      <path d="M18.045 33.8594H18.765V33.1394H18.045V33.8594Z" fill="#FDAE35" />
      <path
        d="M18.7751 33.8594H19.4951V33.1394H18.7751V33.8594Z"
        fill="#FDAE35"
      />
      <path
        d="M19.4951 33.8594H20.2151V33.1394H19.4951V33.8594Z"
        fill="#FDAE35"
      />
      <path
        d="M13.7251 33.8594H14.4451V33.1394H13.7251V33.8594Z"
        fill="#EBEFF0"
      />
      <path
        d="M14.4451 33.8594H15.1651V33.1394H14.4451V33.8594Z"
        fill="#EBEFF0"
      />
      <path
        d="M13.7251 28.0801H14.4451V27.3601H13.7251V28.0801Z"
        fill="#FDAE35"
      />
      <path
        d="M13.7251 27.3594H14.4451V26.6394H13.7251V27.3594Z"
        fill="#FDAE35"
      />
      <path
        d="M13.7251 38.9199H14.4451V38.1999H13.7251V38.9199Z"
        fill="#FDAE35"
      />
      <path
        d="M13.7251 38.1992H14.4451V37.4792H13.7251V38.1992Z"
        fill="#FDE962"
      />
      <path
        d="M14.4451 38.1992H15.1651V37.4792H14.4451V38.1992Z"
        fill="#FDAE35"
      />
      <path
        d="M13.7251 37.4805H14.4451V36.7605H13.7251V37.4805Z"
        fill="#FDE962"
      />
      <path
        d="M14.4451 37.4805H15.1651V36.7605H14.4451V37.4805Z"
        fill="#FDAE35"
      />
      <path
        d="M13.7251 36.7598H14.4451V36.0398H13.7251V36.7598Z"
        fill="#FDE962"
      />
      <path
        d="M14.4451 36.7598H15.1651V36.0398H14.4451V36.7598Z"
        fill="#FDE962"
      />
      <path
        d="M13.7251 36.0293H14.4451V35.3093H13.7251V36.0293Z"
        fill="#FDE962"
      />
      <path
        d="M14.4451 36.0293H15.1651V35.3093H14.4451V36.0293Z"
        fill="#FDE962"
      />
      <path
        d="M15.1651 36.7598H15.8851V36.0398H15.1651V36.7598Z"
        fill="#FDAE35"
      />
      <path
        d="M15.1651 36.0293H15.8851V35.3093H15.1651V36.0293Z"
        fill="#FDAE35"
      />
      <path
        d="M13.7251 35.3105H14.4451V34.5905H13.7251V35.3105Z"
        fill="#EBEFF0"
      />
      <path
        d="M14.4451 35.3105H15.1651V34.5905H14.4451V35.3105Z"
        fill="#FDE962"
      />
      <path
        d="M15.1651 35.3105H15.8851V34.5905H15.1651V35.3105Z"
        fill="#FDE962"
      />
      <path
        d="M15.8851 35.3105H16.6051V34.5905H15.8851V35.3105Z"
        fill="#FDAE35"
      />
      <path
        d="M16.6151 35.3105H17.3351V34.5905H16.6151V35.3105Z"
        fill="#FDAE35"
      />
      <path
        d="M15.1651 34.5801H15.8851V33.8601H15.1651V34.5801Z"
        fill="#FDE962"
      />
      <path
        d="M15.8851 34.5801H16.6051V33.8601H15.8851V34.5801Z"
        fill="#FDE962"
      />
      <path
        d="M16.6151 34.5801H17.3351V33.8601H16.6151V34.5801Z"
        fill="#FDAE35"
      />
      <path
        d="M17.3351 34.5801H18.0551V33.8601H17.3351V34.5801Z"
        fill="#FDAE35"
      />
      <path d="M18.045 34.5801H18.765V33.8601H18.045V34.5801Z" fill="#FDAE35" />
      <path
        d="M13.7251 34.5801H14.4451V33.8601H13.7251V34.5801Z"
        fill="#EBEFF0"
      />
      <path
        d="M14.4451 34.5801H15.1651V33.8601H14.4451V34.5801Z"
        fill="#FDE962"
      />
      <path
        d="M15.1651 33.8691H15.8851V33.1491H15.1651V33.8691Z"
        fill="#EBEFF0"
      />
      <path
        d="M15.8851 33.8691H16.6051V33.1491H15.8851V33.8691Z"
        fill="#FDE962"
      />
      <path
        d="M16.6151 33.8691H17.3351V33.1491H16.6151V33.8691Z"
        fill="#FDE962"
      />
      <path
        d="M17.3351 33.8691H18.0551V33.1491H17.3351V33.8691Z"
        fill="#FDE962"
      />
      <path d="M18.045 33.8691H18.765V33.1491H18.045V33.8691Z" fill="#FDAE35" />
      <path
        d="M18.7751 33.8691H19.4951V33.1491H18.7751V33.8691Z"
        fill="#FDAE35"
      />
      <path
        d="M19.4951 33.8691H20.2151V33.1491H19.4951V33.8691Z"
        fill="#FDAE35"
      />
      <path
        d="M13.7251 33.8691H14.4451V33.1491H13.7251V33.8691Z"
        fill="#EBEFF0"
      />
      <path
        d="M14.4451 33.8691H15.1651V33.1491H14.4451V33.8691Z"
        fill="#EBEFF0"
      />
      <path
        d="M13.7251 39.6406H14.4451V38.9206H13.7251V39.6406Z"
        fill="#FDAE35"
      />
      <path
        d="M13.7251 40.3691H14.4451V39.6491H13.7251V40.3691Z"
        fill="#FDAE35"
      />
      <path
        d="M14.4351 38.1992H13.7151V38.9192H14.4351V38.1992Z"
        fill="#FDAE35"
      />
      <path
        d="M14.4351 37.4805H13.7151V38.2005H14.4351V37.4805Z"
        fill="#FDE962"
      />
      <path
        d="M13.7151 37.4805H12.9951V38.2005H13.7151V37.4805Z"
        fill="#FDAE35"
      />
      <path d="M14.4351 36.75H13.7151V37.47H14.4351V36.75Z" fill="#FDE962" />
      <path d="M13.7151 36.75H12.9951V37.47H13.7151V36.75Z" fill="#FDAE35" />
      <path
        d="M14.4351 36.0293H13.7151V36.7493H14.4351V36.0293Z"
        fill="#FDE962"
      />
      <path
        d="M13.7151 36.0293H12.9951V36.7493H13.7151V36.0293Z"
        fill="#FDE962"
      />
      <path
        d="M14.4351 35.3105H13.7151V36.0305H14.4351V35.3105Z"
        fill="#FDE962"
      />
      <path
        d="M13.7151 35.3105H12.9951V36.0305H13.7151V35.3105Z"
        fill="#FDE962"
      />
      <path
        d="M12.9951 36.0293H12.2751V36.7493H12.9951V36.0293Z"
        fill="#FDAE35"
      />
      <path
        d="M12.9951 35.3105H12.2751V36.0305H12.9951V35.3105Z"
        fill="#FDAE35"
      />
      <path
        d="M14.4351 34.5898H13.7151V35.3098H14.4351V34.5898Z"
        fill="#EBEFF0"
      />
      <path
        d="M13.7151 34.5898H12.9951V35.3098H13.7151V34.5898Z"
        fill="#FDE962"
      />
      <path
        d="M12.9951 34.5898H12.2751V35.3098H12.9951V34.5898Z"
        fill="#FDE962"
      />
      <path
        d="M12.2751 34.5898H11.5551V35.3098H12.2751V34.5898Z"
        fill="#FDAE35"
      />
      <path
        d="M11.5451 34.5898H10.8251V35.3098H11.5451V34.5898Z"
        fill="#FDAE35"
      />
      <path
        d="M12.9951 33.8691H12.2751V34.5891H12.9951V33.8691Z"
        fill="#FDE962"
      />
      <path
        d="M12.2751 33.8691H11.5551V34.5891H12.2751V33.8691Z"
        fill="#FDE962"
      />
      <path
        d="M11.5451 33.8691H10.8251V34.5891H11.5451V33.8691Z"
        fill="#FDAE35"
      />
      <path
        d="M10.8251 33.8691H10.1051V34.5891H10.8251V33.8691Z"
        fill="#FDAE35"
      />
      <path
        d="M10.1051 33.8691H9.38507V34.5891H10.1051V33.8691Z"
        fill="#FDAE35"
      />
      <path
        d="M14.4351 33.8691H13.7151V34.5891H14.4351V33.8691Z"
        fill="#EBEFF0"
      />
      <path
        d="M13.7151 33.8691H12.9951V34.5891H13.7151V33.8691Z"
        fill="#FDE962"
      />
      <path
        d="M12.9951 33.1406H12.2751V33.8606H12.9951V33.1406Z"
        fill="#EBEFF0"
      />
      <path
        d="M12.2751 33.1406H11.5551V33.8606H12.2751V33.1406Z"
        fill="#FDE962"
      />
      <path
        d="M11.5451 33.1406H10.8251V33.8606H11.5451V33.1406Z"
        fill="#FDE962"
      />
      <path
        d="M10.8251 33.1406H10.1051V33.8606H10.8251V33.1406Z"
        fill="#FDE962"
      />
      <path
        d="M10.1051 33.1406H9.38507V33.8606H10.1051V33.1406Z"
        fill="#FDAE35"
      />
      <path
        d="M9.38507 33.1406H8.66507V33.8606H9.38507V33.1406Z"
        fill="#FDAE35"
      />
      <path
        d="M8.65506 33.1406H7.93506V33.8606H8.65506V33.1406Z"
        fill="#FDAE35"
      />
      <path
        d="M14.4351 33.1406H13.7151V33.8606H14.4351V33.1406Z"
        fill="#EBEFF0"
      />
      <path
        d="M13.7151 33.1406H12.9951V33.8606H13.7151V33.1406Z"
        fill="#EBEFF0"
      />
      <path
        d="M14.4351 38.9199H13.7151V39.6399H14.4351V38.9199Z"
        fill="#FDAE35"
      />
      <path
        d="M14.4351 39.6406H13.7151V40.3606H14.4351V39.6406Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 6.11914H61.5551V7.13914H62.5751V6.11914Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 7.14062H61.5551V8.16062H62.5751V7.14062Z"
        fill="#FDE962"
      />
      <path
        d="M61.5551 7.14062H60.5351V8.16062H61.5551V7.14062Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 8.16016H61.5551V9.18016H62.5751V8.16016Z"
        fill="#FDE962"
      />
      <path
        d="M61.5551 8.16016H60.5351V9.18016H61.5551V8.16016Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 9.17969H61.5551V10.1997H62.5751V9.17969Z"
        fill="#FDE962"
      />
      <path
        d="M61.5551 9.17969H60.5351V10.1997H61.5551V9.17969Z"
        fill="#FDE962"
      />
      <path
        d="M62.5751 10.1992H61.5551V11.2192H62.5751V10.1992Z"
        fill="#FDE962"
      />
      <path
        d="M61.5551 10.1992H60.5351V11.2192H61.5551V10.1992Z"
        fill="#FDE962"
      />
      <path
        d="M60.5351 9.17969H59.5151V10.1997H60.5351V9.17969Z"
        fill="#FDAE35"
      />
      <path
        d="M60.5351 10.1992H59.5151V11.2192H60.5351V10.1992Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 11.2207H61.5551V12.2407H62.5751V11.2207Z"
        fill="#EBEFF0"
      />
      <path
        d="M61.5551 11.2207H60.5351V12.2407H61.5551V11.2207Z"
        fill="#FDE962"
      />
      <path
        d="M60.5351 11.2207H59.5151V12.2407H60.5351V11.2207Z"
        fill="#FDE962"
      />
      <path
        d="M59.5151 11.2207H58.4951V12.2407H59.5151V11.2207Z"
        fill="#FDAE35"
      />
      <path
        d="M58.4951 11.2207H57.4751V12.2407H58.4951V11.2207Z"
        fill="#FDAE35"
      />
      <path
        d="M60.5351 12.2402H59.5151V13.2602H60.5351V12.2402Z"
        fill="#FDE962"
      />
      <path
        d="M59.5151 12.2402H58.4951V13.2602H59.5151V12.2402Z"
        fill="#FDE962"
      />
      <path
        d="M58.4951 12.2402H57.4751V13.2602H58.4951V12.2402Z"
        fill="#FDAE35"
      />
      <path
        d="M57.4751 12.2402H56.4551V13.2602H57.4751V12.2402Z"
        fill="#FDAE35"
      />
      <path
        d="M56.4551 12.2402H55.4351V13.2602H56.4551V12.2402Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 12.2402H61.5551V13.2602H62.5751V12.2402Z"
        fill="#EBEFF0"
      />
      <path
        d="M61.5551 12.2402H60.5351V13.2602H61.5551V12.2402Z"
        fill="#FDE962"
      />
      <path
        d="M60.5351 13.2598H59.5151V14.2798H60.5351V13.2598Z"
        fill="#EBEFF0"
      />
      <path
        d="M59.5151 13.2598H58.4951V14.2798H59.5151V13.2598Z"
        fill="#FDE962"
      />
      <path
        d="M58.4951 13.2598H57.4751V14.2798H58.4951V13.2598Z"
        fill="#FDE962"
      />
      <path
        d="M57.4751 13.2598H56.4551V14.2798H57.4751V13.2598Z"
        fill="#FDE962"
      />
      <path
        d="M56.4551 13.2598H55.4351V14.2798H56.4551V13.2598Z"
        fill="#FDAE35"
      />
      <path
        d="M55.4351 13.2598H54.4151V14.2798H55.4351V13.2598Z"
        fill="#FDAE35"
      />
      <path
        d="M54.4151 13.2598H53.3951V14.2798H54.4151V13.2598Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 13.2598H61.5551V14.2798H62.5751V13.2598Z"
        fill="#EBEFF0"
      />
      <path
        d="M61.5551 13.2598H60.5351V14.2798H61.5551V13.2598Z"
        fill="#EBEFF0"
      />
      <path
        d="M62.5751 5.09961H61.5551V6.11961H62.5751V5.09961Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 4.08984H61.5551V5.10984H62.5751V4.08984Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 7.15039H62.5651V6.13039H61.5451V7.15039Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 8.16992H62.5651V7.14992H61.5451V8.16992Z"
        fill="#FDE962"
      />
      <path
        d="M62.5651 8.16992H63.5851V7.14992H62.5651V8.16992Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 9.18945H62.5651V8.16945H61.5451V9.18945Z"
        fill="#FDE962"
      />
      <path
        d="M62.5651 9.18945H63.5851V8.16945H62.5651V9.18945Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 10.2109H62.5651V9.19094H61.5451V10.2109Z"
        fill="#FDE962"
      />
      <path
        d="M62.5651 10.2109H63.5851V9.19094H62.5651V10.2109Z"
        fill="#FDE962"
      />
      <path
        d="M61.5451 11.2305H62.5651V10.2105H61.5451V11.2305Z"
        fill="#FDE962"
      />
      <path
        d="M62.5651 11.2305H63.5851V10.2105H62.5651V11.2305Z"
        fill="#FDE962"
      />
      <path
        d="M63.595 10.2109H64.6151V9.19094H63.595V10.2109Z"
        fill="#FDAE35"
      />
      <path
        d="M63.595 11.2305H64.6151V10.2105H63.595V11.2305Z"
        fill="#FDAE35"
      />
      <path d="M61.5451 12.25H62.5651V11.23H61.5451V12.25Z" fill="#EBEFF0" />
      <path d="M62.5651 12.25H63.5851V11.23H62.5651V12.25Z" fill="#FDE962" />
      <path d="M63.595 12.25H64.6151V11.23H63.595V12.25Z" fill="#FDE962" />
      <path d="M64.6151 12.25H65.6351V11.23H64.6151V12.25Z" fill="#FDAE35" />
      <path d="M65.6351 12.25H66.6551V11.23H65.6351V12.25Z" fill="#FDAE35" />
      <path
        d="M63.595 13.2695H64.6151V12.2495H63.595V13.2695Z"
        fill="#FDE962"
      />
      <path
        d="M64.6151 13.2695H65.6351V12.2495H64.6151V13.2695Z"
        fill="#FDE962"
      />
      <path
        d="M65.6351 13.2695H66.6551V12.2495H65.6351V13.2695Z"
        fill="#FDAE35"
      />
      <path
        d="M66.6551 13.2695H67.6751V12.2495H66.6551V13.2695Z"
        fill="#FDAE35"
      />
      <path
        d="M67.6751 13.2695H68.6951V12.2495H67.6751V13.2695Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 13.2695H62.5651V12.2495H61.5451V13.2695Z"
        fill="#EBEFF0"
      />
      <path
        d="M62.5651 13.2695H63.5851V12.2495H62.5651V13.2695Z"
        fill="#FDE962"
      />
      <path d="M63.595 14.291H64.6151V13.271H63.595V14.291Z" fill="#EBEFF0" />
      <path d="M64.6151 14.291H65.6351V13.271H64.6151V14.291Z" fill="#FDE962" />
      <path d="M65.6351 14.291H66.6551V13.271H65.6351V14.291Z" fill="#FDE962" />
      <path d="M66.6551 14.291H67.6751V13.271H66.6551V14.291Z" fill="#FDE962" />
      <path d="M67.6751 14.291H68.6951V13.271H67.6751V14.291Z" fill="#FDAE35" />
      <path d="M68.6951 14.291H69.7151V13.271H68.6951V14.291Z" fill="#FDAE35" />
      <path d="M69.7151 14.291H70.7351V13.271H69.7151V14.291Z" fill="#FDAE35" />
      <path d="M61.5451 14.291H62.5651V13.271H61.5451V14.291Z" fill="#EBEFF0" />
      <path d="M62.5651 14.291H63.5851V13.271H62.5651V14.291Z" fill="#EBEFF0" />
      <path
        d="M61.5451 6.13086H62.5651V5.11086H61.5451V6.13086Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 5.09961H62.5651V4.07961H61.5451V5.09961Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 21.4395H62.5651V20.4195H61.5451V21.4395Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 20.4199H62.5651V19.3999H61.5451V20.4199Z"
        fill="#FDE962"
      />
      <path
        d="M62.5651 20.4199H63.5851V19.3999H62.5651V20.4199Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 19.4004H62.5651V18.3804H61.5451V19.4004Z"
        fill="#FDE962"
      />
      <path
        d="M62.5651 19.4004H63.5851V18.3804H62.5651V19.4004Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 18.3809H62.5651V17.3609H61.5451V18.3809Z"
        fill="#FDE962"
      />
      <path
        d="M62.5651 18.3809H63.5851V17.3609H62.5651V18.3809Z"
        fill="#FDE962"
      />
      <path
        d="M61.5451 17.3594H62.5651V16.3394H61.5451V17.3594Z"
        fill="#FDE962"
      />
      <path
        d="M62.5651 17.3594H63.5851V16.3394H62.5651V17.3594Z"
        fill="#FDE962"
      />
      <path
        d="M63.595 18.3809H64.6151V17.3609H63.595V18.3809Z"
        fill="#FDAE35"
      />
      <path
        d="M63.595 17.3594H64.6151V16.3394H63.595V17.3594Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 16.3398H62.5651V15.3198H61.5451V16.3398Z"
        fill="#EBEFF0"
      />
      <path
        d="M62.5651 16.3398H63.5851V15.3198H62.5651V16.3398Z"
        fill="#FDE962"
      />
      <path
        d="M63.595 16.3398H64.6151V15.3198H63.595V16.3398Z"
        fill="#FDE962"
      />
      <path
        d="M64.6151 16.3398H65.6351V15.3198H64.6151V16.3398Z"
        fill="#FDAE35"
      />
      <path
        d="M65.6351 16.3398H66.6551V15.3198H65.6351V16.3398Z"
        fill="#FDAE35"
      />
      <path
        d="M63.595 15.3203H64.6151V14.3003H63.595V15.3203Z"
        fill="#FDE962"
      />
      <path
        d="M64.6151 15.3203H65.6351V14.3003H64.6151V15.3203Z"
        fill="#FDE962"
      />
      <path
        d="M65.6351 15.3203H66.6551V14.3003H65.6351V15.3203Z"
        fill="#FDAE35"
      />
      <path
        d="M66.6551 15.3203H67.6751V14.3003H66.6551V15.3203Z"
        fill="#FDAE35"
      />
      <path
        d="M67.6751 15.3203H68.6951V14.3003H67.6751V15.3203Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 15.3203H62.5651V14.3003H61.5451V15.3203Z"
        fill="#EBEFF0"
      />
      <path
        d="M62.5651 15.3203H63.5851V14.3003H62.5651V15.3203Z"
        fill="#FDE962"
      />
      <path
        d="M63.595 14.3008H64.6151V13.2808H63.595V14.3008Z"
        fill="#EBEFF0"
      />
      <path
        d="M64.6151 14.3008H65.6351V13.2808H64.6151V14.3008Z"
        fill="#FDE962"
      />
      <path
        d="M65.6351 14.3008H66.6551V13.2808H65.6351V14.3008Z"
        fill="#FDE962"
      />
      <path
        d="M66.6551 14.3008H67.6751V13.2808H66.6551V14.3008Z"
        fill="#FDE962"
      />
      <path
        d="M67.6751 14.3008H68.6951V13.2808H67.6751V14.3008Z"
        fill="#FDAE35"
      />
      <path
        d="M68.6951 14.3008H69.7151V13.2808H68.6951V14.3008Z"
        fill="#FDAE35"
      />
      <path
        d="M69.7151 14.3008H70.7351V13.2808H69.7151V14.3008Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 14.3008H62.5651V13.2808H61.5451V14.3008Z"
        fill="#EBEFF0"
      />
      <path
        d="M62.5651 14.3008H63.5851V13.2808H62.5651V14.3008Z"
        fill="#EBEFF0"
      />
      <path
        d="M61.5451 22.4609H62.5651V21.4409H61.5451V22.4609Z"
        fill="#FDAE35"
      />
      <path
        d="M61.5451 23.4805H62.5651V22.4605H61.5451V23.4805Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 20.4102H61.5551V21.4302H62.5751V20.4102Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 19.3906H61.5551V20.4106H62.5751V19.3906Z"
        fill="#FDE962"
      />
      <path
        d="M61.5551 19.3906H60.5351V20.4106H61.5551V19.3906Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 18.3691H61.5551V19.3891H62.5751V18.3691Z"
        fill="#FDE962"
      />
      <path
        d="M61.5551 18.3691H60.5351V19.3891H61.5551V18.3691Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 17.3496H61.5551V18.3696H62.5751V17.3496Z"
        fill="#FDE962"
      />
      <path
        d="M61.5551 17.3496H60.5351V18.3696H61.5551V17.3496Z"
        fill="#FDE962"
      />
      <path
        d="M62.5751 16.3301H61.5551V17.3501H62.5751V16.3301Z"
        fill="#FDE962"
      />
      <path
        d="M61.5551 16.3301H60.5351V17.3501H61.5551V16.3301Z"
        fill="#FDE962"
      />
      <path
        d="M60.5351 17.3496H59.5151V18.3696H60.5351V17.3496Z"
        fill="#FDAE35"
      />
      <path
        d="M60.5351 16.3301H59.5151V17.3501H60.5351V16.3301Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 15.3105H61.5551V16.3305H62.5751V15.3105Z"
        fill="#EBEFF0"
      />
      <path
        d="M61.5551 15.3105H60.5351V16.3305H61.5551V15.3105Z"
        fill="#FDE962"
      />
      <path
        d="M60.5351 15.3105H59.5151V16.3305H60.5351V15.3105Z"
        fill="#FDE962"
      />
      <path
        d="M59.5151 15.3105H58.4951V16.3305H59.5151V15.3105Z"
        fill="#FDAE35"
      />
      <path
        d="M58.4951 15.3105H57.4751V16.3305H58.4951V15.3105Z"
        fill="#FDAE35"
      />
      <path d="M60.5351 14.291H59.5151V15.311H60.5351V14.291Z" fill="#FDE962" />
      <path d="M59.5151 14.291H58.4951V15.311H59.5151V14.291Z" fill="#FDE962" />
      <path d="M58.4951 14.291H57.4751V15.311H58.4951V14.291Z" fill="#FDAE35" />
      <path d="M57.4751 14.291H56.4551V15.311H57.4751V14.291Z" fill="#FDAE35" />
      <path d="M56.4551 14.291H55.4351V15.311H56.4551V14.291Z" fill="#FDAE35" />
      <path d="M62.5751 14.291H61.5551V15.311H62.5751V14.291Z" fill="#EBEFF0" />
      <path d="M61.5551 14.291H60.5351V15.311H61.5551V14.291Z" fill="#FDE962" />
      <path
        d="M60.5351 13.2695H59.5151V14.2895H60.5351V13.2695Z"
        fill="#EBEFF0"
      />
      <path
        d="M59.5151 13.2695H58.4951V14.2895H59.5151V13.2695Z"
        fill="#FDE962"
      />
      <path
        d="M58.4951 13.2695H57.4751V14.2895H58.4951V13.2695Z"
        fill="#FDE962"
      />
      <path
        d="M57.4751 13.2695H56.4551V14.2895H57.4751V13.2695Z"
        fill="#FDE962"
      />
      <path
        d="M56.4551 13.2695H55.4351V14.2895H56.4551V13.2695Z"
        fill="#FDAE35"
      />
      <path
        d="M55.4351 13.2695H54.4151V14.2895H55.4351V13.2695Z"
        fill="#FDAE35"
      />
      <path
        d="M54.4151 13.2695H53.3951V14.2895H54.4151V13.2695Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 13.2695H61.5551V14.2895H62.5751V13.2695Z"
        fill="#EBEFF0"
      />
      <path
        d="M61.5551 13.2695H60.5351V14.2895H61.5551V13.2695Z"
        fill="#EBEFF0"
      />
      <path
        d="M62.5751 21.4297H61.5551V22.4497H62.5751V21.4297Z"
        fill="#FDAE35"
      />
      <path
        d="M62.5751 22.4492H61.5551V23.4692H62.5751V22.4492Z"
        fill="#FDAE35"
      />
      <path
        d="M30.5351 22.3105H28.5351V24.3105H30.5351V22.3105Z"
        fill="black"
      />
      <path
        d="M32.5351 22.3105H30.5351V24.3105H32.5351V22.3105Z"
        fill="black"
      />
      <path
        d="M34.5351 22.3105H32.5351V24.3105H34.5351V22.3105Z"
        fill="black"
      />
      <path
        d="M36.5351 22.3105H34.5351V24.3105H36.5351V22.3105Z"
        fill="black"
      />
      <path
        d="M38.5351 22.3105H36.5351V24.3105H38.5351V22.3105Z"
        fill="black"
      />
      <path
        d="M40.5351 22.3105H38.5351V24.3105H40.5351V22.3105Z"
        fill="#FF8E92"
      />
      <path
        d="M40.5351 20.3105H38.5351V22.3105H40.5351V20.3105Z"
        fill="black"
      />
      <path
        d="M42.5351 22.3105H40.5351V24.3105H42.5351V22.3105Z"
        fill="#FFBFBD"
      />
      <path
        d="M44.5351 22.3105H42.5351V24.3105H44.5351V22.3105Z"
        fill="#FF595A"
      />
      <path
        d="M46.5351 22.3105H44.5351V24.3105H46.5351V22.3105Z"
        fill="#FF595A"
      />
      <path
        d="M48.5351 22.3105H46.5351V24.3105H48.5351V22.3105Z"
        fill="#FF595A"
      />
      <path
        d="M42.5351 20.3105H40.5351V22.3105H42.5351V20.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M44.5351 20.3105H42.5351V22.3105H44.5351V20.3105Z"
        fill="#FF8E92"
      />
      <path
        d="M46.5351 20.3105H44.5351V22.3105H46.5351V20.3105Z"
        fill="#FF8E92"
      />
      <path
        d="M44.5351 18.3105H42.5351V20.3105H44.5351V18.3105Z"
        fill="#FFBFBD"
      />
      <path
        d="M46.5351 18.3105H44.5351V20.3105H46.5351V18.3105Z"
        fill="#FFBFBD"
      />
      <path
        d="M44.5551 16.3105H42.5551V18.3105H44.5551V16.3105Z"
        fill="black"
      />
      <path
        d="M46.5551 16.3105H44.5551V18.3105H46.5551V16.3105Z"
        fill="black"
      />
      <path
        d="M48.5351 20.3105H46.5351V22.3105H48.5351V20.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M42.5351 18.3105H40.5351V20.3105H42.5351V18.3105Z"
        fill="black"
      />
      <path
        d="M48.5351 18.3105H46.5351V20.3105H48.5351V18.3105Z"
        fill="black"
      />
      <path
        d="M50.5351 22.3105H48.5351V24.3105H50.5351V22.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M50.5351 20.3105H48.5351V22.3105H50.5351V20.3105Z"
        fill="black"
      />
      <path
        d="M30.5351 24.3105H28.5351V26.3105H30.5351V24.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M32.5351 24.3105H30.5351V26.3105H32.5351V24.3105Z"
        fill="#F6E6FF"
      />
      <path
        d="M34.5351 24.3105H32.5351V26.3105H34.5351V24.3105Z"
        fill="#F6E6FF"
      />
      <path
        d="M36.5351 24.3105H34.5351V26.3105H36.5351V24.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M38.5351 24.3105H36.5351V26.3105H38.5351V24.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M40.5351 24.3105H38.5351V26.3105H40.5351V24.3105Z"
        fill="#FF8E92"
      />
      <path
        d="M42.5351 24.3105H40.5351V26.3105H42.5351V24.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M44.5351 24.3105H42.5351V26.3105H44.5351V24.3105Z"
        fill="#FF595A"
      />
      <path
        d="M46.5351 24.3105H44.5351V26.3105H46.5351V24.3105Z"
        fill="#FF595A"
      />
      <path
        d="M48.5351 24.3105H46.5351V26.3105H48.5351V24.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M50.5351 24.3105H48.5351V26.3105H50.5351V24.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M30.5351 26.3105H28.5351V28.3105H30.5351V26.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M32.5351 26.3105H30.5351V28.3105H32.5351V26.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M34.5351 26.3105H32.5351V28.3105H34.5351V26.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M36.5351 26.3105H34.5351V28.3105H36.5351V26.3105Z"
        fill="#F6E6FF"
      />
      <path
        d="M38.5351 26.3105H36.5351V28.3105H38.5351V26.3105Z"
        fill="#F6E6FF"
      />
      <path
        d="M40.5351 26.3105H38.5351V28.3105H40.5351V26.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M42.5351 26.3105H40.5351V28.3105H42.5351V26.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M44.5351 26.3105H42.5351V28.3105H44.5351V26.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M46.5351 26.3105H44.5351V28.3105H46.5351V26.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M48.5351 26.3105H46.5351V28.3105H48.5351V26.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M50.5351 26.3105H48.5351V28.3105H50.5351V26.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M30.5351 28.3105H28.5351V30.3105H30.5351V28.3105Z"
        fill="#FFFDFA"
      />
      <path
        d="M32.5351 28.3105H30.5351V30.3105H32.5351V28.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M34.5351 28.3105H32.5351V30.3105H34.5351V28.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M36.5351 28.3105H34.5351V30.3105H36.5351V28.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M38.5351 28.3105H36.5351V30.3105H38.5351V28.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M40.5351 28.3105H38.5351V30.3105H40.5351V28.3105Z"
        fill="#F6E6FF"
      />
      <path
        d="M42.5351 28.3105H40.5351V30.3105H42.5351V28.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M44.5351 28.3105H42.5351V30.3105H44.5351V28.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M46.5351 28.3105H44.5351V30.3105H46.5351V28.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M48.5351 28.3105H46.5351V30.3105H48.5351V28.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M50.5351 28.3105H48.5351V30.3105H50.5351V28.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M30.5351 30.3105H28.5351V32.3105H30.5351V30.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M32.5351 30.3105H30.5351V32.3105H32.5351V30.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M34.5351 30.3105H32.5351V32.3105H34.5351V30.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M36.5351 30.3105H34.5351V32.3105H36.5351V30.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M38.5351 30.3105H36.5351V32.3105H38.5351V30.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M40.5351 30.3105H38.5351V32.3105H40.5351V30.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M42.5351 30.3105H40.5351V32.3105H42.5351V30.3105Z"
        fill="#F6E6FF"
      />
      <path
        d="M44.5351 30.3105H42.5351V32.3105H44.5351V30.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M46.5351 30.3105H44.5351V32.3105H46.5351V30.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M48.5351 30.3105H46.5351V32.3105H48.5351V30.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M50.5351 30.3105H48.5351V32.3105H50.5351V30.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M30.5351 32.3105H28.5351V34.3105H30.5351V32.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M32.5351 32.3105H30.5351V34.3105H32.5351V32.3105Z"
        fill="#FF595A"
      />
      <path
        d="M34.5351 32.3105H32.5351V34.3105H34.5351V32.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M36.5351 32.3105H34.5351V34.3105H36.5351V32.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M38.5351 32.3105H36.5351V34.3105H38.5351V32.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M40.5351 32.3105H38.5351V34.3105H40.5351V32.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M42.5351 32.3105H40.5351V34.3105H42.5351V32.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M44.5351 32.3105H42.5351V34.3105H44.5351V32.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M46.5351 32.3105H44.5351V34.3105H46.5351V32.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M48.5351 32.3105H46.5351V34.3105H48.5351V32.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M50.5351 32.3105H48.5351V34.3105H50.5351V32.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M30.5351 34.3105H28.5351V36.3105H30.5351V34.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M32.5351 34.3105H30.5351V36.3105H32.5351V34.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M34.5351 34.3105H32.5351V36.3105H34.5351V34.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M36.5351 34.3105H34.5351V36.3105H36.5351V34.3105Z"
        fill="#FF595A"
      />
      <path
        d="M38.5351 34.3105H36.5351V36.3105H38.5351V34.3105Z"
        fill="#FF595A"
      />
      <path
        d="M40.5351 34.3105H38.5351V36.3105H40.5351V34.3105Z"
        fill="#FF595A"
      />
      <path
        d="M42.5351 34.3105H40.5351V36.3105H42.5351V34.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M44.5351 34.3105H42.5351V36.3105H44.5351V34.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M46.5351 34.3105H44.5351V36.3105H46.5351V34.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M48.5351 34.3105H46.5351V36.3105H48.5351V34.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M50.5351 34.3105H48.5351V36.3105H50.5351V34.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M30.5351 36.3105H28.5351V38.3105H30.5351V36.3105Z"
        fill="#FFFDFA"
      />
      <path
        d="M32.5351 36.3105H30.5351V38.3105H32.5351V36.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M34.5351 36.3105H32.5351V38.3105H34.5351V36.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M36.5351 36.3105H34.5351V38.3105H36.5351V36.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M38.5351 36.3105H36.5351V38.3105H38.5351V36.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M40.5351 36.3105H38.5351V38.3105H40.5351V36.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M42.5351 36.3105H40.5351V38.3105H42.5351V36.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M44.5351 36.3105H42.5351V38.3105H44.5351V36.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M46.4951 36.3105H44.4951V38.3105H46.4951V36.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M48.5351 36.3105H46.5351V38.3105H48.5351V36.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M50.5351 36.3105H48.5351V38.3105H50.5351V36.3105Z"
        fill="#FFBFBD"
      />
      <path
        d="M30.5351 38.3105H28.5351V40.3105H30.5351V38.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M32.5351 38.3105H30.5351V40.3105H32.5351V38.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M34.5351 38.3105H32.5351V40.3105H34.5351V38.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M36.5351 38.3105H34.5351V40.3105H36.5351V38.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M38.5351 38.3105H36.5351V40.3105H38.5351V38.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M40.5351 38.3105H38.5351V40.3105H40.5351V38.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M42.5351 38.3105H40.5351V40.3105H42.5351V38.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M44.5351 38.3105H42.5351V40.3105H44.5351V38.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M46.4951 38.3105H44.4951V40.3105H46.4951V38.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M48.5351 38.3105H46.5351V40.3105H48.5351V38.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M50.5351 38.3105H48.5351V40.3105H50.5351V38.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M30.5351 40.3105H28.5351V42.3105H30.5351V40.3105Z"
        fill="black"
      />
      <path
        d="M32.5351 40.3105H30.5351V42.3105H32.5351V40.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M34.5351 40.3105H32.5351V42.3105H34.5351V40.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M36.5351 40.3105H34.5351V42.3105H36.5351V40.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M38.5351 40.3105H36.5351V42.3105H38.5351V40.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M40.5351 40.3105H38.5351V42.3105H40.5351V40.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M42.5351 40.3105H40.5351V42.3105H42.5351V40.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M44.5351 40.3105H42.5351V42.3105H44.5351V40.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M46.5351 40.3105H44.5351V42.3105H46.5351V40.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M48.4951 40.3105H46.4951V42.3105H48.4951V40.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M50.5351 40.3105H48.5351V42.3105H50.5351V40.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M30.5351 42.3105H28.5351V44.3105H30.5351V42.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M32.5351 42.3105H30.5351V44.3105H32.5351V42.3105Z"
        fill="black"
      />
      <path
        d="M34.5351 42.3105H32.5351V44.3105H34.5351V42.3105Z"
        fill="black"
      />
      <path
        d="M36.5351 42.3105H34.5351V44.3105H36.5351V42.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M38.5351 42.3105H36.5351V44.3105H38.5351V42.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M40.5351 42.3105H38.5351V44.3105H40.5351V42.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M42.5351 42.3105H40.5351V44.3105H42.5351V42.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M44.5351 42.3105H42.5351V44.3105H44.5351V42.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M46.5351 42.3105H44.5351V44.3105H46.5351V42.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M48.4951 42.3105H46.4951V44.3105H48.4951V42.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M50.5351 42.3105H48.5351V44.3105H50.5351V42.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M30.5351 44.3594H28.5351V46.3594H30.5351V44.3594Z"
        fill="#95A2D2"
      />
      <path
        d="M32.5351 44.3594H30.5351V46.3594H32.5351V44.3594Z"
        fill="#95A2D2"
      />
      <path
        d="M34.5351 44.3105H32.5351V46.3105H34.5351V44.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M36.5351 44.3105H34.5351V46.3105H36.5351V44.3105Z"
        fill="black"
      />
      <path
        d="M38.5351 44.3105H36.5351V46.3105H38.5351V44.3105Z"
        fill="black"
      />
      <path
        d="M40.5351 44.3105H38.5351V46.3105H40.5351V44.3105Z"
        fill="black"
      />
      <path
        d="M42.5351 44.3105H40.5351V46.3105H42.5351V44.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M44.5351 44.3105H42.5351V46.3105H44.5351V44.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M46.5351 44.3105H44.5351V46.3105H46.5351V44.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M48.5351 44.3105H46.5351V46.3105H48.5351V44.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M50.5351 44.3105H48.5351V46.3105H50.5351V44.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M30.5351 46.3105H28.5351V48.3105H30.5351V46.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M32.5351 46.3105H30.5351V48.3105H32.5351V46.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M34.5351 46.3105H32.5351V48.3105H34.5351V46.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M36.5351 46.3105H34.5351V48.3105H36.5351V46.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M38.5351 46.3105H36.5351V48.3105H38.5351V46.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M40.5351 46.3105H38.5351V48.3105H40.5351V46.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M42.5351 46.3105H40.5351V48.3105H42.5351V46.3105Z"
        fill="black"
      />
      <path
        d="M44.5351 46.3105H42.5351V48.3105H44.5351V46.3105Z"
        fill="black"
      />
      <path
        d="M46.5351 46.3105H44.5351V48.3105H46.5351V46.3105Z"
        fill="black"
      />
      <path
        d="M48.5351 46.3105H46.5351V48.3105H48.5351V46.3105Z"
        fill="black"
      />
      <path
        d="M50.5351 46.3105H48.5351V48.3105H50.5351V46.3105Z"
        fill="black"
      />
      <path
        d="M30.5351 48.3105H28.5351V50.3105H30.5351V48.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M28.5351 24.3105H26.5351V26.3105H28.5351V24.3105Z"
        fill="black"
      />
      <path
        d="M28.5351 26.3105H26.5351V28.3105H28.5351V26.3105Z"
        fill="black"
      />
      <path
        d="M28.5351 28.3105H26.5351V30.3105H28.5351V28.3105Z"
        fill="black"
      />
      <path
        d="M28.5351 30.3105H26.5351V32.3105H28.5351V30.3105Z"
        fill="black"
      />
      <path
        d="M28.5351 32.3105H26.5351V34.3105H28.5351V32.3105Z"
        fill="black"
      />
      <path
        d="M28.5351 34.3105H26.5351V36.3105H28.5351V34.3105Z"
        fill="black"
      />
      <path
        d="M28.5351 36.3105H26.5351V38.3105H28.5351V36.3105Z"
        fill="black"
      />
      <path
        d="M28.5351 38.3105H26.5351V40.3105H28.5351V38.3105Z"
        fill="black"
      />
      <path
        d="M28.5351 40.3594H26.5351V42.3594H28.5351V40.3594Z"
        fill="#95A2D2"
      />
      <path
        d="M28.5351 42.3594H26.5351V44.3594H28.5351V42.3594Z"
        fill="#95A2D2"
      />
      <path
        d="M28.5351 44.3105H26.5351V46.3105H28.5351V44.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M28.5351 46.3105H26.5351V48.3105H28.5351V46.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M28.5351 48.3105H26.5351V50.3105H28.5351V48.3105Z"
        fill="black"
      />
      <path
        d="M26.5351 34.3105H24.5351V36.3105H26.5351V34.3105Z"
        fill="black"
      />
      <path
        d="M26.5351 36.3594H24.5351V38.3594H26.5351V36.3594Z"
        fill="#D4DFFC"
      />
      <path
        d="M26.5351 38.3105H24.5351V40.3105H26.5351V38.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M26.5351 40.3105H24.5351V42.3105H26.5351V40.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M26.5351 42.3594H24.5351V44.3594H26.5351V42.3594Z"
        fill="#95A2D2"
      />
      <path
        d="M26.5351 44.3105H24.5351V46.3105H26.5351V44.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M26.5351 46.3105H24.5351V48.3105H26.5351V46.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M26.5351 48.3105H24.5351V50.3105H26.5351V48.3105Z"
        fill="black"
      />
      <path
        d="M24.5351 36.3105H22.5351V38.3105H24.5351V36.3105Z"
        fill="black"
      />
      <path
        d="M24.5351 38.3594H22.5351V40.3594H24.5351V38.3594Z"
        fill="#D4DFFC"
      />
      <path
        d="M24.5351 40.3105H22.5351V42.3105H24.5351V40.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M24.5351 42.3105H22.5351V44.3105H24.5351V42.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M24.5351 44.3105H22.5351V46.3105H24.5351V44.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M24.5351 46.3105H22.5351V48.3105H24.5351V46.3105Z"
        fill="black"
      />
      <path
        d="M22.5351 38.3105H20.5351V40.3105H22.5351V38.3105Z"
        fill="black"
      />
      <path
        d="M22.5351 40.3105H20.5351V42.3105H22.5351V40.3105Z"
        fill="#D4DFFC"
      />
      <path
        d="M22.5351 42.3105H20.5351V44.3105H22.5351V42.3105Z"
        fill="#D4DFFC"
      />
      <path
        d="M22.5351 44.3105H20.5351V46.3105H22.5351V44.3105Z"
        fill="black"
      />
      <path
        d="M20.5351 40.3105H18.5351V42.3105H20.5351V40.3105Z"
        fill="black"
      />
      <path
        d="M20.5351 42.3105H18.5351V44.3105H20.5351V42.3105Z"
        fill="black"
      />
      <path
        d="M32.5351 48.3105H30.5351V50.3105H32.5351V48.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M34.5351 48.3105H32.5351V50.3105H34.5351V48.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M36.5351 48.3105H34.5351V50.3105H36.5351V48.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M38.5351 48.3105H36.5351V50.3105H38.5351V48.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M40.5351 48.3105H38.5351V50.3105H40.5351V48.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M42.5351 48.3105H40.5351V50.3105H42.5351V48.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M44.5351 48.3105H42.5351V50.3105H44.5351V48.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M46.5351 48.3105H44.5351V50.3105H46.5351V48.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M48.5351 48.3105H46.5351V50.3105H48.5351V48.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M50.5351 48.3105H48.5351V50.3105H50.5351V48.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M30.5351 50.3105H28.5351V52.3105H30.5351V50.3105Z"
        fill="black"
      />
      <path
        d="M32.5351 50.3105H30.5351V52.3105H32.5351V50.3105Z"
        fill="black"
      />
      <path
        d="M34.5351 50.3105H32.5351V52.3105H34.5351V50.3105Z"
        fill="black"
      />
      <path
        d="M36.5351 50.3105H34.5351V52.3105H36.5351V50.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M38.5351 50.3105H36.5351V52.3105H38.5351V50.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M40.5351 50.3105H38.5351V52.3105H40.5351V50.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M42.5351 50.3105H40.5351V52.3105H42.5351V50.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M44.5351 50.3105H42.5351V52.3105H44.5351V50.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M46.5351 50.3105H44.5351V52.3105H46.5351V50.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M48.5351 50.3105H46.5351V52.3105H48.5351V50.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M50.5351 50.3105H48.5351V52.3105H50.5351V50.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M52.5351 24.3105H50.5351V26.3105H52.5351V24.3105Z"
        fill="black"
      />
      <path
        d="M52.5351 22.3105H50.5351V24.3105H52.5351V22.3105Z"
        fill="black"
      />
      <path
        d="M52.5351 26.3105H50.5351V28.3105H52.5351V26.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M52.5351 28.3105H50.5351V30.3105H52.5351V28.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M52.5351 30.3105H50.5351V32.3105H52.5351V30.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M52.5351 32.3105H50.5351V34.3105H52.5351V32.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M52.5351 34.3105H50.5351V36.3105H52.5351V34.3105Z"
        fill="#FFBFBD"
      />
      <path
        d="M52.5351 36.3105H50.5351V38.3105H52.5351V36.3105Z"
        fill="#FF595A"
      />
      <path
        d="M52.5351 38.3105H50.5351V40.3105H52.5351V38.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M52.5351 40.3105H50.5351V42.3105H52.5351V40.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M52.5351 42.3105H50.5351V44.3105H52.5351V42.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M52.5351 44.3105H50.5351V46.3105H52.5351V44.3105Z"
        fill="black"
      />
      <path
        d="M52.5351 46.3105H50.5351V48.3105H52.5351V46.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M52.5351 48.3105H50.5351V50.3105H52.5351V48.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M52.5351 50.3105H50.5351V52.3105H52.5351V50.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M54.5351 24.3105H52.5351V26.3105H54.5351V24.3105Z"
        fill="black"
      />
      <path
        d="M54.5351 26.3105H52.5351V28.3105H54.5351V26.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M54.5351 28.3105H52.5351V30.3105H54.5351V28.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M54.5351 30.3105H52.5351V32.3105H54.5351V30.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M54.5351 32.3105H52.5351V34.3105H54.5351V32.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M54.5351 34.3105H52.5351V36.3105H54.5351V34.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M54.5351 36.3105H52.5351V38.3105H54.5351V36.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M54.5351 38.3105H52.5351V40.3105H54.5351V38.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M54.5351 40.3105H52.5351V42.3105H54.5351V40.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M54.5351 42.3105H52.5351V44.3105H54.5351V42.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M54.5351 44.3105H52.5351V46.3105H54.5351V44.3105Z"
        fill="black"
      />
      <path
        d="M54.5351 46.3105H52.5351V48.3105H54.5351V46.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M54.5351 48.3105H52.5351V50.3105H54.5351V48.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M54.5351 50.3105H52.5351V52.3105H54.5351V50.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M56.5351 24.3105H54.5351V26.3105H56.5351V24.3105Z"
        fill="black"
      />
      <path
        d="M56.5351 26.3105H54.5351V28.3105H56.5351V26.3105Z"
        fill="#EBCAFA"
      />
      <path
        d="M56.5351 28.3105H54.5351V30.3105H56.5351V28.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M56.5351 30.3105H54.5351V32.3105H56.5351V30.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M56.5351 32.3105H54.5351V34.3105H56.5351V32.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M56.5351 34.3105H54.5351V36.3105H56.5351V34.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M56.5351 36.3105H54.5351V38.3105H56.5351V36.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M56.5351 38.3105H54.5351V40.3105H56.5351V38.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M56.5351 40.3105H54.5351V42.3105H56.5351V40.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M56.5351 42.3105H54.5351V44.3105H56.5351V42.3105Z"
        fill="#F8D18E"
      />
      <path
        d="M56.5351 44.3105H54.5351V46.3105H56.5351V44.3105Z"
        fill="black"
      />
      <path
        d="M56.5351 46.3105H54.5351V48.3105H56.5351V46.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M56.5351 48.3105H54.5351V50.3105H56.5351V48.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M56.5351 50.3105H54.5351V52.3105H56.5351V50.3105Z"
        fill="black"
      />
      <path
        d="M58.5351 26.3105H56.5351V28.3105H58.5351V26.3105Z"
        fill="black"
      />
      <path
        d="M58.5351 28.3105H56.5351V30.3105H58.5351V28.3105Z"
        fill="#E0AEF9"
      />
      <path
        d="M58.5351 30.3105H56.5351V32.3105H58.5351V30.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M58.5351 32.3105H56.5351V34.3105H58.5351V32.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M58.5351 34.3105H56.5351V36.3105H58.5351V34.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M58.5351 36.3105H56.5351V38.3105H58.5351V36.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M58.5351 38.3105H56.5351V40.3105H58.5351V38.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M58.5351 40.3105H56.5351V42.3105H58.5351V40.3105Z"
        fill="#FFE9B7"
      />
      <path
        d="M58.5351 42.3105H56.5351V44.3105H58.5351V42.3105Z"
        fill="black"
      />
      <path
        d="M58.5351 44.3105H56.5351V46.3105H58.5351V44.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M58.5351 46.3105H56.5351V48.3105H58.5351V46.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M58.5351 48.3105H56.5351V50.3105H58.5351V48.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M58.5351 50.3105H56.5351V52.3105H58.5351V50.3105Z"
        fill="black"
      />
      <path
        d="M60.5351 26.3105H58.5351V28.3105H60.5351V26.3105Z"
        fill="black"
      />
      <path
        d="M60.5351 28.3105H58.5351V30.3105H60.5351V28.3105Z"
        fill="black"
      />
      <path
        d="M60.5351 30.3105H58.5351V32.3105H60.5351V30.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M60.5351 32.3105H58.5351V34.3105H60.5351V32.3105Z"
        fill="#FDFFE9"
      />
      <path
        d="M60.5351 34.3105H58.5351V36.3105H60.5351V34.3105Z"
        fill="#FF595A"
      />
      <path
        d="M60.5351 36.3105H58.5351V38.3105H60.5351V36.3105Z"
        fill="#E83B3A"
      />
      <path
        d="M60.5351 38.3105H58.5351V40.3105H60.5351V38.3105Z"
        fill="black"
      />
      <path
        d="M60.5351 40.3105H58.5351V42.3105H60.5351V40.3105Z"
        fill="black"
      />
      <path
        d="M60.5351 42.3105H58.5351V44.3105H60.5351V42.3105Z"
        fill="black"
      />
      <path
        d="M60.5351 44.3105H58.5351V46.3105H60.5351V44.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M60.5351 46.3105H58.5351V48.3105H60.5351V46.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M60.5351 48.3105H58.5351V50.3105H60.5351V48.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M60.5351 50.3105H58.5351V52.3105H60.5351V50.3105Z"
        fill="black"
      />
      <path
        d="M62.5351 30.3105H60.5351V32.3105H62.5351V30.3105Z"
        fill="black"
      />
      <path
        d="M62.5351 32.3105H60.5351V34.3105H62.5351V32.3105Z"
        fill="black"
      />
      <path
        d="M62.5351 34.3105H60.5351V36.3105H62.5351V34.3105Z"
        fill="black"
      />
      <path
        d="M62.5351 36.3105H60.5351V38.3105H62.5351V36.3105Z"
        fill="black"
      />
      <path
        d="M62.5351 38.3105H60.5351V40.3105H62.5351V38.3105Z"
        fill="black"
      />
      <path
        d="M62.5351 40.3105H60.5351V42.3105H62.5351V40.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M62.5351 42.3105H60.5351V44.3105H62.5351V42.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M62.5351 44.3105H60.5351V46.3105H62.5351V44.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M62.5351 46.3105H60.5351V48.3105H62.5351V46.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M62.5351 48.3105H60.5351V50.3105H62.5351V48.3105Z"
        fill="black"
      />
      <path
        d="M64.5351 36.3105H62.5351V38.3105H64.5351V36.3105Z"
        fill="black"
      />
      <path
        d="M64.5351 38.3105H62.5351V40.3105H64.5351V38.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M64.5351 40.3105H62.5351V42.3105H64.5351V40.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M64.5351 42.3105H62.5351V44.3105H64.5351V42.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M64.5351 44.3105H62.5351V46.3105H64.5351V44.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M64.5351 46.3105H62.5351V48.3105H64.5351V46.3105Z"
        fill="black"
      />
      <path
        d="M66.5351 36.3105H64.5351V38.3105H66.5351V36.3105Z"
        fill="black"
      />
      <path
        d="M66.5351 38.3105H64.5351V40.3105H66.5351V38.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M66.5351 40.3105H64.5351V42.3105H66.5351V40.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M66.5351 42.3105H64.5351V44.3105H66.5351V42.3105Z"
        fill="#F1F3F5"
      />
      <path
        d="M66.5351 44.3105H64.5351V46.3105H66.5351V44.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M66.5351 46.3105H64.5351V48.3105H66.5351V46.3105Z"
        fill="black"
      />
      <path
        d="M68.5351 38.3105H66.5351V40.3105H68.5351V38.3105Z"
        fill="black"
      />
      <path
        d="M68.5351 40.3105H66.5351V42.3105H68.5351V40.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M68.5351 42.3105H66.5351V44.3105H68.5351V42.3105Z"
        fill="#95A2D2"
      />
      <path
        d="M70.5351 40.3105H68.5351V42.3105H70.5351V40.3105Z"
        fill="black"
      />
      <path
        d="M70.5351 42.3105H68.5351V44.3105H70.5351V42.3105Z"
        fill="black"
      />
      <path
        d="M68.5351 44.3105H66.5351V46.3105H68.5351V44.3105Z"
        fill="black"
      />
      <path
        d="M36.5351 52.3105H34.5351V54.3105H36.5351V52.3105Z"
        fill="black"
      />
      <path
        d="M38.5351 52.3105H36.5351V54.3105H38.5351V52.3105Z"
        fill="black"
      />
      <path
        d="M40.5351 52.3105H38.5351V54.3105H40.5351V52.3105Z"
        fill="black"
      />
      <path
        d="M42.5351 52.3105H40.5351V54.3105H42.5351V52.3105Z"
        fill="black"
      />
      <path
        d="M44.5351 52.3105H42.5351V54.3105H44.5351V52.3105Z"
        fill="black"
      />
      <path
        d="M46.5351 52.3105H44.5351V54.3105H46.5351V52.3105Z"
        fill="black"
      />
      <path
        d="M48.5351 52.3105H46.5351V54.3105H48.5351V52.3105Z"
        fill="black"
      />
      <path
        d="M50.5351 52.3105H48.5351V54.3105H50.5351V52.3105Z"
        fill="black"
      />
      <path
        d="M52.5351 52.3105H50.5351V54.3105H52.5351V52.3105Z"
        fill="black"
      />
      <path
        d="M54.5351 52.3105H52.5351V54.3105H54.5351V52.3105Z"
        fill="black"
      />
      <path d="M14.545 44.291H12.545V46.291H14.545V44.291Z" fill="black" />
      <path d="M16.545 44.291H14.545V46.291H16.545V44.291Z" fill="black" />
      <path d="M14.545 46.291H12.545V48.291H14.545V46.291Z" fill="#6E6E89" />
      <path d="M12.545 46.291H10.545V48.291H12.545V46.291Z" fill="black" />
      <path d="M12.545 44.291H10.545V46.291H12.545V44.291Z" fill="black" />
      <path d="M16.545 46.291H14.545V48.291H16.545V46.291Z" fill="#6E6E89" />
      <path d="M18.545 46.291H16.545V48.291H18.545V46.291Z" fill="black" />
      <path d="M18.545 44.291H16.545V46.291H18.545V44.291Z" fill="black" />
      <path d="M20.545 46.291H18.545V48.291H20.545V46.291Z" fill="black" />
      <path d="M22.545 46.291H20.545V48.291H22.545V46.291Z" fill="black" />
      <path d="M24.545 46.291H22.545V48.291H24.545V46.291Z" fill="black" />
      <path d="M26.545 46.291H24.545V48.291H26.545V46.291Z" fill="black" />
      <path d="M28.545 46.291H26.545V48.291H28.545V46.291Z" fill="black" />
      <path d="M30.545 46.291H28.545V48.291H30.545V46.291Z" fill="black" />
      <path d="M32.545 46.291H30.545V48.291H32.545V46.291Z" fill="#6E6E89" />
      <path d="M34.545 46.291H32.545V48.291H34.545V46.291Z" fill="black" />
      <path d="M32.545 44.291H30.545V46.291H32.545V44.291Z" fill="black" />
      <path d="M36.5451 46.291H34.5451V48.291H36.5451V46.291Z" fill="black" />
      <path d="M14.545 48.291H12.545V50.291H14.545V48.291Z" fill="#3F3F3F" />
      <path d="M12.545 48.291H10.545V50.291H12.545V48.291Z" fill="#3F3F3F" />
      <path d="M16.545 48.291H14.545V50.291H16.545V48.291Z" fill="#3F3F3F" />
      <path d="M18.545 48.291H16.545V50.291H18.545V48.291Z" fill="#3F3F3F" />
      <path d="M20.545 48.291H18.545V50.291H20.545V48.291Z" fill="#6E6E89" />
      <path d="M22.545 48.291H20.545V50.291H22.545V48.291Z" fill="#6E6E89" />
      <path d="M24.545 48.291H22.545V50.291H24.545V48.291Z" fill="#6E6E89" />
      <path d="M26.545 48.291H24.545V50.291H26.545V48.291Z" fill="#6E6E89" />
      <path d="M28.545 48.291H26.545V50.291H28.545V48.291Z" fill="#6E6E89" />
      <path d="M30.545 48.291H28.545V50.291H30.545V48.291Z" fill="#6E6E89" />
      <path d="M32.545 48.291H30.545V50.291H32.545V48.291Z" fill="#6E6E89" />
      <path d="M34.545 48.291H32.545V50.291H34.545V48.291Z" fill="#6E6E89" />
      <path d="M36.5451 48.291H34.5451V50.291H36.5451V48.291Z" fill="#6E6E89" />
      <path d="M14.545 50.291H12.545V52.291H14.545V50.291Z" fill="#F8DEA9" />
      <path d="M12.545 50.291H10.545V52.291H12.545V50.291Z" fill="#3F3F3F" />
      <path d="M16.545 50.291H14.545V52.291H16.545V50.291Z" fill="#F8DEA9" />
      <path d="M18.545 50.291H16.545V52.291H18.545V50.291Z" fill="#282725" />
      <path d="M20.545 50.291H18.545V52.291H20.545V50.291Z" fill="#181A1E" />
      <path d="M22.545 50.291H20.545V52.291H22.545V50.291Z" fill="black" />
      <path d="M24.545 50.291H22.545V52.291H24.545V50.291Z" fill="black" />
      <path d="M26.545 50.291H24.545V52.291H26.545V50.291Z" fill="black" />
      <path d="M28.545 50.291H26.545V52.291H28.545V50.291Z" fill="black" />
      <path d="M30.545 50.291H28.545V52.291H30.545V50.291Z" fill="#181A1E" />
      <path d="M32.545 50.291H30.545V52.291H32.545V50.291Z" fill="#181A1E" />
      <path d="M34.545 50.291H32.545V52.291H34.545V50.291Z" fill="#181A1E" />
      <path d="M36.5451 50.291H34.5451V52.291H36.5451V50.291Z" fill="#181A1E" />
      <path d="M14.545 52.291H12.545V54.291H14.545V52.291Z" fill="#282725" />
      <path d="M12.545 52.291H10.545V54.291H12.545V52.291Z" fill="#3F3F3F" />
      <path d="M16.545 52.291H14.545V54.291H16.545V52.291Z" fill="#282725" />
      <path d="M18.545 52.291H16.545V54.291H18.545V52.291Z" fill="#282725" />
      <path d="M20.545 52.291H18.545V54.291H20.545V52.291Z" fill="black" />
      <path d="M22.545 52.291H20.545V54.291H22.545V52.291Z" fill="#C5D6E0" />
      <path d="M24.545 52.291H22.545V54.291H24.545V52.291Z" fill="#C5D6E0" />
      <path d="M26.545 52.291H24.545V54.291H26.545V52.291Z" fill="#C5D6E0" />
      <path d="M28.545 52.291H26.545V54.291H28.545V52.291Z" fill="#C5D6E0" />
      <path d="M30.545 52.291H28.545V54.291H30.545V52.291Z" fill="black" />
      <path d="M32.545 52.291H30.545V54.291H32.545V52.291Z" fill="#38404C" />
      <path d="M14.545 54.291H12.545V56.291H14.545V54.291Z" fill="#38404C" />
      <path d="M34.545 52.291H32.545V54.291H34.545V52.291Z" fill="#38404C" />
      <path d="M16.545 54.291H14.545V56.291H16.545V54.291Z" fill="#38404C" />
      <path d="M36.5451 52.291H34.5451V54.291H36.5451V52.291Z" fill="#38404C" />
      <path d="M12.545 54.291H10.545V56.291H12.545V54.291Z" fill="#6E6E89" />
      <path d="M18.545 54.291H16.545V56.291H18.545V54.291Z" fill="black" />
      <path d="M20.545 54.291H18.545V56.291H20.545V54.291Z" fill="#C5D6E0" />
      <path d="M22.545 54.291H20.545V56.291H22.545V54.291Z" fill="#41477C" />
      <path d="M24.545 54.291H22.545V56.291H24.545V54.291Z" fill="black" />
      <path d="M26.545 54.291H24.545V56.291H26.545V54.291Z" fill="black" />
      <path d="M28.545 54.291H26.545V56.291H28.545V54.291Z" fill="#41477C" />
      <path d="M30.545 54.291H28.545V56.291H30.545V54.291Z" fill="#41477C" />
      <path d="M32.545 54.291H30.545V56.291H32.545V54.291Z" fill="black" />
      <path d="M14.545 56.291H12.545V58.291H14.545V56.291Z" fill="#38404C" />
      <path d="M34.545 54.291H32.545V56.291H34.545V54.291Z" fill="#38404C" />
      <path d="M16.545 56.291H14.545V58.291H16.545V56.291Z" fill="#38404C" />
      <path d="M36.5451 54.291H34.5451V56.291H36.5451V54.291Z" fill="#38404C" />
      <path d="M12.545 56.291H10.545V58.291H12.545V56.291Z" fill="#6E6E89" />
      <path d="M18.545 56.291H16.545V58.291H18.545V56.291Z" fill="black" />
      <path d="M20.545 56.291H18.545V58.291H20.545V56.291Z" fill="#C5D6E0" />
      <path d="M22.545 56.291H20.545V58.291H22.545V56.291Z" fill="black" />
      <path d="M24.545 56.291H22.545V58.291H24.545V56.291Z" fill="#5395A5" />
      <path d="M26.545 56.291H24.545V58.291H26.545V56.291Z" fill="#5395A5" />
      <path d="M28.545 56.291H26.545V58.291H28.545V56.291Z" fill="black" />
      <path d="M30.545 56.291H28.545V58.291H30.545V56.291Z" fill="#41477C" />
      <path d="M32.545 56.291H30.545V58.291H32.545V56.291Z" fill="black" />
      <path d="M14.545 58.291H12.545V60.291H14.545V58.291Z" fill="#38404C" />
      <path d="M34.545 56.291H32.545V58.291H34.545V56.291Z" fill="#38404C" />
      <path d="M16.545 58.291H14.545V60.291H16.545V58.291Z" fill="#38404C" />
      <path d="M36.5451 56.291H34.5451V58.291H36.5451V56.291Z" fill="#38404C" />
      <path d="M12.545 58.291H10.545V60.291H12.545V58.291Z" fill="#6E6E89" />
      <path d="M18.545 58.291H16.545V60.291H18.545V58.291Z" fill="black" />
      <path d="M20.545 58.291H18.545V60.291H20.545V58.291Z" fill="#C5D6E0" />
      <path d="M22.545 58.291H20.545V60.291H22.545V58.291Z" fill="black" />
      <path d="M24.545 58.291H22.545V60.291H24.545V58.291Z" fill="#5395A5" />
      <path d="M26.545 58.291H24.545V60.291H26.545V58.291Z" fill="#397C87" />
      <path d="M28.545 58.291H26.545V60.291H28.545V58.291Z" fill="black" />
      <path d="M30.545 58.291H28.545V60.291H30.545V58.291Z" fill="#41477C" />
      <path d="M32.545 58.291H30.545V60.291H32.545V58.291Z" fill="black" />
      <path d="M14.545 60.291H12.545V62.291H14.545V60.291Z" fill="#38404C" />
      <path d="M34.545 58.291H32.545V60.291H34.545V58.291Z" fill="#38404C" />
      <path d="M16.545 60.291H14.545V62.291H16.545V60.291Z" fill="#38404C" />
      <path d="M36.5451 58.291H34.5451V60.291H36.5451V58.291Z" fill="#38404C" />
      <path d="M12.545 60.291H10.545V62.291H12.545V60.291Z" fill="#6E6E89" />
      <path d="M18.545 60.291H16.545V62.291H18.545V60.291Z" fill="black" />
      <path d="M20.545 60.291H18.545V62.291H20.545V60.291Z" fill="#C5D6E0" />
      <path d="M22.545 60.291H20.545V62.291H22.545V60.291Z" fill="#41477C" />
      <path d="M24.545 60.291H22.545V62.291H24.545V60.291Z" fill="black" />
      <path d="M26.545 60.291H24.545V62.291H26.545V60.291Z" fill="black" />
      <path d="M28.545 60.291H26.545V62.291H28.545V60.291Z" fill="#41477C" />
      <path d="M30.545 60.291H28.545V62.291H30.545V60.291Z" fill="#41477C" />
      <path d="M32.545 60.291H30.545V62.291H32.545V60.291Z" fill="black" />
      <path d="M14.545 62.291H12.545V64.291H14.545V62.291Z" fill="#38404C" />
      <path d="M34.545 60.291H32.545V62.291H34.545V60.291Z" fill="#38404C" />
      <path d="M16.545 62.291H14.545V64.291H16.545V62.291Z" fill="#38404C" />
      <path d="M18.545 62.291H16.545V64.291H18.545V62.291Z" fill="#38404C" />
      <path d="M36.5451 60.291H34.5451V62.291H36.5451V60.291Z" fill="#38404C" />
      <path d="M12.545 62.291H10.545V64.291H12.545V62.291Z" fill="#6E6E89" />
      <path d="M20.545 62.291H18.545V64.291H20.545V62.291Z" fill="black" />
      <path d="M22.545 62.291H20.545V64.291H22.545V62.291Z" fill="#41477C" />
      <path d="M24.545 62.291H22.545V64.291H24.545V62.291Z" fill="#41477C" />
      <path d="M26.545 62.291H24.545V64.291H26.545V62.291Z" fill="#41477C" />
      <path d="M28.545 62.291H26.545V64.291H28.545V62.291Z" fill="#41477C" />
      <path d="M30.545 62.291H28.545V64.291H30.545V62.291Z" fill="black" />
      <path d="M32.545 62.291H30.545V64.291H32.545V62.291Z" fill="#1B1C1E" />
      <path d="M14.545 64.291H12.545V66.291H14.545V64.291Z" fill="#38404C" />
      <path d="M34.545 62.291H32.545V64.291H34.545V62.291Z" fill="#38404C" />
      <path d="M36.5451 62.291H34.5451V64.291H36.5451V62.291Z" fill="#38404C" />
      <path d="M12.545 64.291H10.545V66.291H12.545V64.291Z" fill="#6E6E89" />
      <path d="M10.545 48.291H8.54504V50.291H10.545V48.291Z" fill="black" />
      <path d="M10.545 50.291H8.54504V52.291H10.545V50.291Z" fill="black" />
      <path d="M10.545 52.291H8.54504V54.291H10.545V52.291Z" fill="black" />
      <path d="M10.545 54.291H8.54504V56.291H10.545V54.291Z" fill="black" />
      <path d="M10.545 56.291H8.54504V58.291H10.545V56.291Z" fill="black" />
      <path d="M10.545 58.291H8.54504V60.291H10.545V58.291Z" fill="black" />
      <path d="M10.545 60.291H8.54504V62.291H10.545V60.291Z" fill="black" />
      <path d="M16.545 64.291H14.545V66.291H16.545V64.291Z" fill="#38404C" />
      <path d="M18.545 64.291H16.545V66.291H18.545V64.291Z" fill="#38404C" />
      <path d="M10.545 62.291H8.54504V64.291H10.545V62.291Z" fill="black" />
      <path d="M10.545 64.291H8.54504V66.291H10.545V64.291Z" fill="black" />
      <path d="M20.545 64.291H18.545V66.291H20.545V64.291Z" fill="#38404C" />
      <path d="M22.545 64.291H20.545V66.291H22.545V64.291Z" fill="black" />
      <path d="M24.545 64.291H22.545V66.291H24.545V64.291Z" fill="black" />
      <path d="M26.545 64.291H24.545V66.291H26.545V64.291Z" fill="black" />
      <path d="M28.545 64.291H26.545V66.291H28.545V64.291Z" fill="black" />
      <path d="M30.545 64.291H28.545V66.291H30.545V64.291Z" fill="#1B1C1E" />
      <path d="M32.545 64.291H30.545V66.291H32.545V64.291Z" fill="#38404C" />
      <path d="M34.545 64.291H32.545V66.291H34.545V64.291Z" fill="#38404C" />
      <path d="M36.5451 64.291H34.5451V66.291H36.5451V64.291Z" fill="#38404C" />
      <path d="M38.5451 48.291H36.5451V50.291H38.5451V48.291Z" fill="black" />
      <path d="M38.5451 50.291H36.5451V52.291H38.5451V50.291Z" fill="black" />
      <path d="M38.5451 52.291H36.5451V54.291H38.5451V52.291Z" fill="black" />
      <path d="M38.5451 54.291H36.5451V56.291H38.5451V54.291Z" fill="black" />
      <path d="M38.5451 56.291H36.5451V58.291H38.5451V56.291Z" fill="black" />
      <path d="M38.5451 58.291H36.5451V60.291H38.5451V58.291Z" fill="black" />
      <path d="M38.5451 60.291H36.5451V62.291H38.5451V60.291Z" fill="black" />
      <path d="M38.5451 62.291H36.5451V64.291H38.5451V62.291Z" fill="black" />
      <path d="M38.5451 64.291H36.5451V66.291H38.5451V64.291Z" fill="black" />
      <path d="M14.545 66.291H12.545V68.291H14.545V66.291Z" fill="#1B1C1E" />
      <path d="M12.545 66.291H10.545V68.291H12.545V66.291Z" fill="#6E6E89" />
      <path d="M16.545 66.291H14.545V68.291H16.545V66.291Z" fill="#1B1C1E" />
      <path d="M18.545 66.291H16.545V68.291H18.545V66.291Z" fill="#1B1C1E" />
      <path d="M20.545 66.291H18.545V68.291H20.545V66.291Z" fill="#1B1C1E" />
      <path d="M22.545 66.291H20.545V68.291H22.545V66.291Z" fill="#1B1C1E" />
      <path d="M24.545 66.291H22.545V68.291H24.545V66.291Z" fill="#1B1C1E" />
      <path d="M26.545 66.291H24.545V68.291H26.545V66.291Z" fill="#1B1C1E" />
      <path d="M28.545 66.291H26.545V68.291H28.545V66.291Z" fill="#1B1C1E" />
      <path d="M30.545 66.291H28.545V68.291H30.545V66.291Z" fill="#1B1C1E" />
      <path d="M32.545 66.291H30.545V68.291H32.545V66.291Z" fill="#1B1C1E" />
      <path d="M34.545 66.291H32.545V68.291H34.545V66.291Z" fill="#1B1C1E" />
      <path d="M36.5451 66.291H34.5451V68.291H36.5451V66.291Z" fill="#1B1C1E" />
      <path d="M10.545 66.291H8.54504V68.291H10.545V66.291Z" fill="black" />
      <path d="M22.545 66.291H20.545V68.291H22.545V66.291Z" fill="#1B1C1E" />
      <path d="M24.545 66.291H22.545V68.291H24.545V66.291Z" fill="#1B1C1E" />
      <path d="M26.545 66.291H24.545V68.291H26.545V66.291Z" fill="#1B1C1E" />
      <path d="M28.545 66.291H26.545V68.291H28.545V66.291Z" fill="#1B1C1E" />
      <path d="M38.5451 66.291H36.5451V68.291H38.5451V66.291Z" fill="black" />
      <path d="M14.545 68.291H12.545V70.291H14.545V68.291Z" fill="black" />
      <path d="M12.545 68.291H10.545V70.291H12.545V68.291Z" fill="black" />
      <path d="M16.545 68.291H14.545V70.291H16.545V68.291Z" fill="black" />
      <path d="M18.545 68.291H16.545V70.291H18.545V68.291Z" fill="black" />
      <path d="M20.545 68.291H18.545V70.291H20.545V68.291Z" fill="black" />
      <path d="M22.545 68.291H20.545V70.291H22.545V68.291Z" fill="black" />
      <path d="M24.545 68.291H22.545V70.291H24.545V68.291Z" fill="black" />
      <path d="M26.545 68.291H24.545V70.291H26.545V68.291Z" fill="black" />
      <path d="M28.545 68.291H26.545V70.291H28.545V68.291Z" fill="black" />
      <path d="M30.545 68.291H28.545V70.291H30.545V68.291Z" fill="black" />
      <path d="M32.545 68.291H30.545V70.291H32.545V68.291Z" fill="black" />
      <path d="M34.545 68.291H32.545V70.291H34.545V68.291Z" fill="black" />
      <path d="M36.5451 68.291H34.5451V70.291H36.5451V68.291Z" fill="black" />
    </svg>
  );
};
