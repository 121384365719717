/* eslint-disable max-classes-per-file */
import { PetStatistics } from './statistics';

export enum SpecialStatusEffectEnum {
  Paralyzed = `Paralyzed`,
  Counter = `Counter`,
  'Increase Cooldown' = `Increase Cooldown`,
  'Increase Break' = `Increase Break`,
  'Reduce Status duration' = `Reduce Status duration`,
  'Full Cooldown' = `Full Cooldown`,
  Encrypt = `Encrypt`,
  'Swap Moves' = `Swap Moves`,
  'Shielded' = `Shielded`,
  'Cleanse' = `Cleanse`,
  'Bleeding' = `Bleeding`,
  'Burning' = `Burning`,
  'Slow' = `Slow`,
  'Heal' = `Heal`,
  'Regenerating' = `Regenerating`,
  'Damage (Raw)' = `Damage (Raw)`,
}

// SEE README-status for more information
export class StatusEffectEntity {
  duration?: number; // in turns

  name?: string;

  effect?: string;

  icon?: string;

  description?: string;

  statAffected?: PetStatistics;

  value?: string; // could be negative

  target: 'self' | 'opponent';
}

export class StatusEffect extends StatusEffectEntity {
  statModifier?: number;

  new?: boolean;
}
