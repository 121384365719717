import { Card } from '../ui/molecules/Card';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Text } from '../ui/atoms/Text';
import { useNavigate } from 'react-router-dom';
import { HomeFeedToEarn } from '../ui/icons/HomeFeedToEarn';
import { Center, Flex } from '@chakra-ui/react';
import { Energy } from '../ui/icons/Energy';

export const FeedToEarn = () => {
  const navigate = useNavigate();
  return (
    <Card
      h="auto"
      w="60px"
      padding="8px 4px"
      background="#FFD272"
      justifyContent={'center'}
      flexDirection={'column'}
      alignItems={'center'}
      borderTop="none"
      gap={0}
      cursor={'pointer'}
      onClick={() => {
        navigate('feed-to-earn');
      }}
    >
      <Flex alignItems={'center'}>
        <Energy
          color="var(--Black)"
          style={{ width: '16px', height: 'auto' }}
        />
        <Text colorId="Black" fontSize={'8px'}>
          500
        </Text>
      </Flex>
      <Center w="full">
        <HomeFeedToEarn style={{ width: '40px', height: '40px' }} />
      </Center>
      <Text
        colorId={'Black'}
        textAlign="center"
        w="full"
        fontSize={'6px'}
        lineHeight={'10px'}
      >
        <TranslatedText
          translationKey={`feedToEarn`}
          defaultMessage={`feed to earn`}
        />
        !
      </Text>
    </Card>
  );
};
