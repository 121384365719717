import { useMusicPlayer } from '@/context/MusicPlayerContext';
import { useEffect } from 'react';

export const useMusic = (src: string) => {
  const musicPlayer = useMusicPlayer();

  useEffect(() => {
    if (!musicPlayer.isPlaying) {
      musicPlayer.startPlaying(src);
    }
  }, [musicPlayer]);

  useEffect(() => {
    return () => musicPlayer.stopPlaying();
  }, []);
};
