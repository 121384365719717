import useLocalStorage from '@/hooks/useLocalStorage';
import { bgSounds } from '@/lib/sounds';
import React, { createContext, useContext, useState, useEffect } from 'react';
import useSound from 'use-sound';

type MusicPlayerContextType = {
  isPlaying: boolean;
  soundUrl: string | null;
  volume: number;
  muted: boolean;
  startPlaying: (url: string) => void;
  stopPlaying: () => void;
  setVolume: (volume: number) => void;
  toggleMute: () => void;
};

const MusicPlayerContext = createContext<MusicPlayerContextType | undefined>(
  undefined,
);

export const useMusicPlayer = (): MusicPlayerContextType => {
  const context = useContext(MusicPlayerContext);
  if (!context) {
    throw new Error('useMusicPlayer must be used within a MusicPlayerProvider');
  }
  return context;
};

export const MusicPlayerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [soundUrl, setSoundUrl] = useState<string>(bgSounds.menu);

  const [volume, setVolume] = useLocalStorage<number>('volume', 1);
  const [muted, setMuted] = useLocalStorage<boolean>('muted', false);

  const [play, { stop, pause, sound }] = useSound(soundUrl, {
    volume: muted ? 0 : +volume,
    interrupt: false,
    loop: true,
  });

  useEffect(() => {
    if (isPlaying && soundUrl && sound) {
      if (!muted) {
        sound.fade(0, volume, 1000);
      }
      play();
    } else {
      pause();
    }

    return () => {
      if (sound) {
        stop();
      }
    };
  }, [isPlaying, soundUrl, volume, muted, play, pause, sound]);

  const startPlaying = (url: string) => {
    setSoundUrl(url);
    setIsPlaying(true);
  };

  const stopPlaying = () => {
    setIsPlaying(false);
    stop();
  };

  const toggleMute = () => setMuted(!muted);

  return (
    <MusicPlayerContext.Provider
      value={{
        isPlaying,
        soundUrl,
        volume,
        muted,
        startPlaying,
        stopPlaying,
        setVolume,
        toggleMute,
      }}
    >
      {children}
    </MusicPlayerContext.Provider>
  );
};
