// eslint-disable-next-line import/no-extraneous-dependencies
import { FieldValue, Timestamp } from '@google-cloud/firestore';
import { PayloadAction } from '@reduxjs/toolkit';
import { BattleActionEntity, BattleMoveEntity } from './battle-action.entity';
import { BattlePlayerEntity } from './battle-player.entity';

export enum BattleStatus {
  OPEN = `OPEN`,
  WAITING = `WAITING`,
  ONGOING = `ONGOING`,
  ENDED = `ENDED`,
}

export enum BattleType {
  TELEGRAM = `TELEGRAM`,
}

export enum TelegramRolledMoveType {
  BREAK = `break`,
  REGULAR = `regular`,
}

export class BattleEntity {
  constructor(data: Partial<BattleEntity>) {
    Object.assign(this, data);
  }

  id: string;

  creator: string;

  player1: BattlePlayerEntity;

  player2: BattlePlayerEntity;

  createdAt: number;

  // we need to save the move to be able to replay the battle in the same condition
  elementalMoves: BattleMoveEntity[];

  status: BattleStatus;

  startPlayer?: string;

  winner?: string;

  type?: BattleType;

  // initial state should include move selected by each player
  // this can also be consider as an action and so include into actions fields (new action move-selection could be created)
  // consider the move selection as part of the battle could allow a pick and ban by player which could be fun

  actions: PayloadAction<BattleActionEntity>[];

  // Battle start time (when both user joined)
  startTime?: Timestamp | FieldValue;

  // battle end time (a winner has been declared)
  endTime?: Timestamp | FieldValue;

  // Timestamp that flag when the next player should have played the next action
  expiryTime?: number;

  // Timestamp that flag when the player should have both joined the battle
  battleExpiryTime?: number;

  // energy gained by the winner after telegram battle
  winnerTelegramEnergy?: number;

  // energy gained by the loser after telegram battle
  loserTelegramEnergy?: number;

  // name of the move the winner rolled after telegram battle
  winnerTelegramRolledMove?: string;

  // type of the move the winner rolled after telegram battle
  winnerTelegramRolledMoveType?: TelegramRolledMoveType;

  // if true, telegram battle rewards has been processed = energy and if new move is rolled
  telegramRewardsProcessed?: boolean;

  // if this battle was created from an in-line telegram message, record the id so we can update the state
  telegramMessageId?: string;

  // the link to join the battle
  joinLink?: string;

  // if battle was created during onboarding
  isOnboarding?: boolean;
}
