import { Energy } from '@/components/ui/icons/Energy';
import { Ticket, TicketSvg } from '@/components/ui/icons/Ticket';
import { PixeltonSpinnerPrize } from '../hooks/useSpinWheelSettings';
import { useTranslation } from 'react-i18next';
import { getFontFamilyByLanguage, isJaOrZh } from '@/utils/utils';

// show 1000 as 1K, 1000000 as 1000K, etc
const formatNumber = (num: number) => {
  if (num >= 1000) {
    const formattedNum = (num / 1000).toFixed(1);
    return formattedNum.endsWith('.0')
      ? `${parseInt(formattedNum)}K`
      : `${formattedNum}K`;
  }
  return num.toString();
};

const getColor = (index: number) => {
  switch (index) {
    case 1:
      return '#90A8FF';
    case 2:
      return '#E7FF50';
    case 3:
      return '#76FFCE';
    case 4:
      return '#48C1E8';
    case 5:
      return '#FFE49E';
    default:
      return '#FFB6EA';
  }
};

export const Wheel = ({
  selections,
  wheelRef,
}: {
  selections: PixeltonSpinnerPrize[];
  wheelRef: React.LegacyRef<SVGGElement> | undefined;
}) => {
  const { t: translateText, i18n } = useTranslation();
  function getAdditionShiftX(length: number) {
    if (length === 2) return 3;
    if (length === 3) return 2;
    return 0;
  }

  return (
    <svg viewBox="-50 -50 100 100">
      <g ref={wheelRef}>
        {selections.map((prize, index) => {
          const angle = (index * 360) / selections.length;
          const startAngle = (angle - 90) * (Math.PI / 180);
          const endAngle =
            (angle + 360 / selections.length - 90) * (Math.PI / 180);
          const x1 = Math.cos(startAngle) * 50;
          const y1 = Math.sin(startAngle) * 50;
          const x2 = Math.cos(endAngle) * 50;
          const y2 = Math.sin(endAngle) * 50;

          // Calculate the midpoint for text placement
          const midAngle = (startAngle + endAngle) / 2;
          const textRadius = 35;
          const textX = Math.cos(midAngle) * textRadius;
          const textY = Math.sin(midAngle) * textRadius;

          let iconX = textX;

          const formattedAmount = formatNumber(prize.amount);
          if (prize.type === 'tickets') {
            iconX = iconX - prize.amount.toString().length * 8;
          } else {
            iconX =
              iconX -
              formattedAmount.length * 4 -
              getAdditionShiftX(formattedAmount.length);
          }

          return (
            <g key={index}>
              <path
                d={`M 0 0 L ${x1} ${y1} A 50 50 0 0 1 ${x2} ${y2} Z`}
                fill={getColor(index)}
              />
              <g
                transform={`rotate(${angle + 360 / selections.length / 2}, ${textX}, ${textY})`}
              >
                {prize.type === 'energy' ? (
                  <Energy
                    color="var(--Black)"
                    width="8"
                    height="8"
                    x={iconX}
                    y={textY - 4.5}
                  />
                ) : (
                  <TicketSvg width="10" height="10" x={iconX} y={textY - 6} />
                )}
                <text
                  x={textX - (prize.type === 'tickets' ? -5 : -3)}
                  y={textY}
                  fontSize="6"
                  fontFamily={getFontFamilyByLanguage(i18n)}
                  textAnchor="middle"
                  dominantBaseline="middle"
                >
                  {formattedAmount}
                </text>
              </g>
            </g>
          );
        })}
      </g>
      <path d="M -5 -17 L 5 -17 L 0 -27 Z" fill="black" />
      <circle cx="0" cy="0" r="22" fill="black" />
      <text
        x="0"
        y="-9"
        fontSize="4"
        fontFamily={getFontFamilyByLanguage(i18n)}
        textAnchor="middle"
        fill="white"
        dominantBaseline="middle"
        pointerEvents="none"
      >
        {translateText('the', 'THE')}
      </text>
      <text
        x="0"
        y="-3"
        fontSize="6"
        fontFamily={getFontFamilyByLanguage(i18n)}
        textAnchor="middle"
        fill="white"
        dominantBaseline="middle"
        pointerEvents="none"
      >
        {translateText('wheel', 'WHEEL')}
      </text>
      <text
        x="0"
        y="3"
        fontSize="4"
        fontFamily={getFontFamilyByLanguage(i18n)}
        textAnchor="middle"
        fill="white"
        dominantBaseline="middle"
        pointerEvents="none"
      >
        {translateText('of', 'OF')}
      </text>
      <text
        x="0"
        y="9"
        fontSize="6"
        fontFamily={getFontFamilyByLanguage(i18n)}
        textAnchor="middle"
        fill="white"
        dominantBaseline="middle"
        pointerEvents="none"
      >
        {translateText('luck', 'LUCK')}
      </text>
    </svg>
  );
};
