export const DropdownIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fill="#000"
        d="M11 1V0H1v1zM12 1h-1v2h1zM11 3h-1v2h1zM10 5H9v2h1zM9 7H8v2h1zM8 9H7v2h1zM5 11v1h2v-1zM5 9H4v2h1zM4 7H3v2h1zM3 5H2v2h1zM2 3H1v2h1zM1 1H0v2h1z"
      ></path>
      <path fill="#000" d="M11 3V1H1v2h1v2h1v2h1v2h1v2h2V9h1V7h1V5h1V3z"></path>
    </svg>
  );
};
