import {
  BattleMode,
  useUIBattleContext,
} from '@/components/battle/context/useBattleContext';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Bar } from '@/components/ui/molecules/Bar';
import { useAuthState } from '@/context/UserContext';
import { sounds } from '@/lib/sounds';
import { getPetName } from '@/utils/utils';
import { Box, Flex, FlexProps, useDisclosure } from '@chakra-ui/react';
import { useHapticFeedback } from '@tma.js/sdk-react';
import { Variants, motion } from 'framer-motion';
import {
  AnimationType,
  BattleMoveEntity,
  BattleState,
  DEFAULT_TELEGRAM_PET,
  MoveType,
  RootState,
  StatusEffectEntity,
  UiEventPlayMove,
  UiEventType,
  getPlayerData,
  getTelegramPetImage,
} from 'genopets-utils';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CleanShot from '../../../images/clean-shot.png';
import { Text } from '../../ui/atoms/Text';
import { BreakIndicator } from '../../ui/molecules/BreakIndicator';
import { StatusIndicators } from './StatusIndicators';
import { StatusInformationModal } from './StatusInformationModal';
import * as Sentry from '@sentry/react';
import { useEffectPlayer } from '@/context/EffectPlayerContext';

const petSize = 300;

const opponentPetSize = 170;

const halfPetSize = petSize / 2;

const halfOpponentPetSize = opponentPetSize / 2;

export const PetInformation = ({
  playerId,
  player,
  level,
  breakLevel,
  status,
  maxHp,
  opponent = false,
  ...props
}: {
  playerId: string;
  player: string;
  level: number;
  breakLevel: number;
  maxHp: number;
  status?: StatusEffectEntity[];
  opponent?: boolean;
} & FlexProps) => {
  const {
    uiEvent,
    pet: petFromDb,
    mode,
    opponentPet: opponentPetFromDb,
    setPrintMoveInfo,
    dispatchUiEventPlayed,
  } = useUIBattleContext();
  useUIBattleContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [chargeVisible, setChargeVisible] = useState(false);
  const [impactVisible, setImpactVisible] = useState(false);
  const [projectileVisible, setProjectileVisible] = useState(false);
  const [animationToPlay, setAnimationToPlay] = useState<
    'hidden' | 'melee' | 'meleeOpponent' | 'shake'
  >('hidden');

  const [hp, setHp] = useState(maxHp);
  const [playMoveEvent, setPlayMoveEvent] =
    useState<UiEventPlayMove['payload']>();
  const [petKo, setPetKo] = useState(false);

  const effectPlayer = useEffectPlayer();

  const { state } = useAuthState();

  const user = state.currentUser;

  const hapticFeedback = useHapticFeedback();

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const petFromBattleState = useMemo(() => {
    return battleState?.player1?.id === user?.uid
      ? battleState?.player1?.petInfo
      : battleState?.player2?.petInfo;
  }, [battleState]);

  const opponentPetFromBattleState = useMemo(() => {
    return battleState?.player1?.id === user?.uid
      ? battleState?.player2?.petInfo
      : battleState?.player1?.petInfo;
  }, [battleState, user]);

  const pet = petFromDb ?? petFromBattleState;

  const opponentPet = opponentPetFromDb ?? opponentPetFromBattleState;

  useEffect(() => {
    if (battleState.uiEvents && battleState.uiEvents.length === 0) {
      const pet = getPlayerData(battleState, playerId).pet;
      if (pet.HP !== hp) {
        if (mode === BattleMode.REPLAY) {
          // if replay mode, update the hp to the current hp to handle click on previous
          setHp(pet.HP);
        } else {
          console.error(
            `HP is not equal to pet HP for player ${playerId}`,
            hp,
            pet.HP,
            pet,
          );
          // sentry urgent catch with battle id to investigate in the error
          Sentry.captureException(new Error(`HP is not equal to pet HP`), {
            data: { battleId: battleState.id, playerId },
          });
        }
      }
    }
  }, [battleState]);

  useEffect(() => {
    if (impactVisible && playMoveEvent) {
      if (playMoveEvent.damage > 20) {
        hapticFeedback.notificationOccurred('success');
      } else if (playMoveEvent.damage > 5) {
        hapticFeedback.notificationOccurred('error');
      } else if (playMoveEvent.damage > 0) {
        hapticFeedback.notificationOccurred('warning');
      }
      if (playMoveEvent.targetPlayerId === playerId) {
        setHp(hp - playMoveEvent.damage);
      }
    }
  }, [impactVisible]);

  useEffect(() => {
    if (uiEvent) {
      console.log(
        `pet information use effect ${uiEvent?.type}`,
        getPlayerData(battleState, playerId).pet.HP,
        hp,
        uiEvent.payload,
      );
      if (uiEvent.type === UiEventType.PLAY_MOVE) {
        setPlayMoveEvent(uiEvent.payload);
      } else if (
        uiEvent.type === UiEventType.PLAY_KO &&
        uiEvent.payload.petKoId === playerId
      ) {
        setPetKo(true);
        setTimeout(() => {
          dispatchUiEventPlayed(uiEvent.id);
        }, 2000);
      } else if (
        uiEvent.type === UiEventType.PLAY_STATUS &&
        uiEvent.payload.targetPlayerId === playerId
      ) {
        setHp(hp - uiEvent.payload.damage);
      } else if (
        uiEvent.type === UiEventType.PLAY_TICK_STATUS &&
        uiEvent.payload.targetPlayerId === playerId
      ) {
        setHp(hp - uiEvent.payload.damage);
      } else if (
        uiEvent.type === UiEventType.PLAY_COUNTER_MOVE &&
        uiEvent.payload.targetPlayerId === playerId
      ) {
        setHp(hp - uiEvent.payload.damage);
      }
    }
  }, [uiEvent]);

  useEffect(() => {
    let timeout: any;
    let timeout1: any;
    let timeout2: any;
    let timeout3: any;
    let timeout4: any;
    if (playMoveEvent) {
      const move = playMoveEvent.move as BattleMoveEntity;
      const isMeleeMove =
        move.animationType === AnimationType.Melee ||
        move.types.includes(MoveType.Minor); // Poke is consider as melee move
      const isRangedMove = move.animationType === AnimationType.Ranged;
      const isPowerMove = move.animationType === AnimationType.Power;
      const isHackMove = move.animationType === AnimationType.Hack;

      const isDodged = playMoveEvent.dodged;

      const isNotDodged = !isDodged;

      const initialWait = 200;

      // wait 500 ms shake effect
      const shakeTime = 500;

      const impactTime = 1200;

      const chargeTime = 1200;

      const sound = move.sound;

      if (isHackMove) {
        timeout = setTimeout(
          () => {
            if (sound) {
              const soundPath = sound;
              effectPlayer.playEffect(soundPath);
            }
            setAnimationToPlay(
              playMoveEvent.initiatorPlayerId === playerId ? 'shake' : 'hidden',
            );
          },
          initialWait, // wait 200ms before starting the animation
        );

        timeout1 = setTimeout(() => {
          setChargeVisible(true);
          setImpactVisible(isNotDodged);
          if (isDodged) {
            effectPlayer.playEffect(sounds.dodge);
          }
        }, initialWait + shakeTime);

        timeout2 = setTimeout(
          () => {
            setChargeVisible(false);
            setImpactVisible(false);
          },
          initialWait + shakeTime + impactTime,
        );

        timeout3 = setTimeout(
          () => {
            setPrintMoveInfo(true);
            setPlayMoveEvent(undefined);
            setAnimationToPlay('hidden');
          },
          initialWait + shakeTime + impactTime + 200,
        ); // wait 200ms after the animation  before allowing ui update
      } else if (isPowerMove) {
        timeout = setTimeout(
          () => {
            if (sound) {
              const soundPath = sound;
              effectPlayer.playEffect(soundPath);
            }
            setChargeVisible(true);
          },
          initialWait, // wait 200ms before starting the animation
        );

        timeout1 = setTimeout(() => {
          setChargeVisible(false);
          setAnimationToPlay(
            playMoveEvent.initiatorPlayerId === playerId ? 'shake' : 'hidden',
          );
        }, initialWait + chargeTime);

        timeout2 = setTimeout(
          () => {
            setImpactVisible(isNotDodged);
            if (isDodged) {
              effectPlayer.playEffect(sounds.dodge);
            }
          },
          initialWait + chargeTime + shakeTime,
        );

        timeout4 = setTimeout(
          () => {
            setImpactVisible(false);
          },
          initialWait + chargeTime + shakeTime + impactTime,
        );

        timeout3 = setTimeout(
          () => {
            setPrintMoveInfo(true);
            setPlayMoveEvent(undefined);
            setAnimationToPlay('hidden');
          },
          initialWait + chargeTime + shakeTime + impactTime + 200,
        ); // wait 200ms after the animation  before allowing ui update
      } else if (isMeleeMove) {
        timeout = setTimeout(
          () => {
            setAnimationToPlay(
              playMoveEvent.initiatorPlayerId === playerId
                ? opponent
                  ? 'meleeOpponent'
                  : 'melee'
                : 'hidden',
            );

            if (sound) {
              const soundPath = sound;
              effectPlayer.playEffect(soundPath);
            }
          },
          initialWait, // wait 200ms before starting the animation
        );

        timeout1 = setTimeout(() => {
          setImpactVisible(isNotDodged);
          if (isDodged) {
            effectPlayer.playEffect(sounds.dodge);
          }
        }, initialWait + 100);

        timeout2 = setTimeout(
          () => {
            setImpactVisible(false);
          },
          initialWait + impactTime + 100,
        );

        timeout3 = setTimeout(
          () => {
            setPrintMoveInfo(true);
            setPlayMoveEvent(undefined);
            setAnimationToPlay('hidden');
          },
          initialWait + impactTime + 300,
        ); // wait 200ms after the animation  before allowing ui update
      } else if (isRangedMove) {
        timeout = setTimeout(
          () => {
            setAnimationToPlay(
              playMoveEvent.initiatorPlayerId === playerId ? 'shake' : 'hidden',
            );

            if (sound) {
              const soundPath = sound;
              effectPlayer.playEffect(soundPath);
            }
          },
          initialWait, // wait 200ms before starting the animation
        );

        // animation projectile 600ms
        timeout4 = setTimeout(() => {
          setProjectileVisible(true);
        }, shakeTime + initialWait);

        timeout1 = setTimeout(
          () => {
            setImpactVisible(isNotDodged);
            if (isDodged) {
              effectPlayer.playEffect(sounds.dodge);
            }
          },
          shakeTime + initialWait + 100,
        );

        // scale = 1 at 300 ms

        timeout2 = setTimeout(
          () => {
            setImpactVisible(false);
          },
          shakeTime + initialWait + impactTime + 100,
        );

        timeout3 = setTimeout(
          () => {
            setPrintMoveInfo(true);
            setPlayMoveEvent(undefined);
            setAnimationToPlay('hidden');
            setProjectileVisible(false);
          },
          shakeTime + initialWait + impactTime + 300,
        ); // wait 200ms after the animation  before allowing ui update
      } else {
        // no animation to play trigger the alert and move to next player action
        setPrintMoveInfo(true);
        setPlayMoveEvent(undefined);
      }

      return () => {
        clearTimeout(timeout);
        clearTimeout(timeout1);
        clearTimeout(timeout2);
        clearTimeout(timeout3);
        clearTimeout(timeout4);
      };
    }
  }, [playMoveEvent]);

  const projectileVariants: Variants = {
    hidden: { scale: 0 },
    ranged: {
      scale: [0, 1, 0],
      transition: {
        transition: {
          duration: 0.6,
          ease: 'easeOut', // Bezier curve for the animation
          times: [0, 0.5, 1], // Keyframe times for back and forth
        },
      },
    },
  };

  // window - information height - pet height/2 - information height - pet height/2 - padding
  const yTranslate = useMemo(
    () =>
      window?.innerHeight
        ? window.innerHeight - 96 - halfPetSize - 96 - halfOpponentPetSize - 16
        : 0,
    [],
  );

  // window - pet1 width /2 - pet2 width /2 - padding
  const xTranslate = useMemo(
    () =>
      window?.innerWidth
        ? window.innerWidth - halfPetSize - halfOpponentPetSize / 2 - 16
        : 0,
    [],
  );

  const petImageMargin = 40;

  const attackVariants: Variants = {
    hidden: { x: 0, y: 0 },
    melee: {
      x: [0, xTranslate - petImageMargin, 0],
      y: [0, -yTranslate + petImageMargin, 0],
      zIndex: 3,
      transition: {
        duration: 0.6,
        ease: [0.68, -0.6, 0.32, 1.6], // Bezier curve for the animation
      },
    },
    meleeOpponent: {
      x: [0, -xTranslate + petImageMargin, 0],
      y: [0, yTranslate - petImageMargin, 0],
      zIndex: 3,
      transition: {
        duration: 0.6,
        ease: [0.68, -0.6, 0.32, 1.6], // Bezier curve for the animation
      },
    },
    shake: {
      x: [0, -5, 5, -5, 5, -5, 5, 0], // Shake effect
      transition: { duration: 0.5, ease: 'easeInOut' },
    },
  };

  const image = (
    <Box
      position={'relative'}
      height={'100px'}
      w="full"
      pointerEvents={'none'}
      zIndex={opponent ? 1 : 5}
    >
      {/* pet image */}
      <motion.div
        style={{
          position: 'absolute',
          height: opponent ? opponentPetSize : petSize,
          width: opponent ? opponentPetSize : petSize,
          top: opponent ? '0' : undefined,
          bottom: opponent ? undefined : '0',
          left: opponent ? undefined : '0',
          right: opponent ? '0' : undefined,
        }}
        initial="hidden"
        animate={animationToPlay}
        variants={attackVariants}
      >
        {petKo ? (
          <img src={CleanShot} alt={`CleanShot`} />
        ) : (
          <>
            {/* projectile image for player */}
            {playMoveEvent &&
              !opponent &&
              playMoveEvent.move.projectileImage && (
                <motion.div
                  style={{
                    position: 'absolute',
                    top: opponent ? halfOpponentPetSize : undefined,
                    bottom: opponent ? undefined : halfPetSize,
                    left: opponent ? undefined : halfPetSize,
                    right: opponent ? halfOpponentPetSize : undefined,
                    transformOrigin: opponent ? 'top right' : 'bottom left',
                  }}
                  initial="hidden"
                  animate={
                    projectileVisible &&
                    playMoveEvent.initiatorPlayerId === playerId
                      ? ['ranged']
                      : 'hidden'
                  }
                  variants={projectileVariants}
                >
                  <img
                    src={playMoveEvent.move.projectileImage}
                    alt="projectile"
                    style={{
                      height: yTranslate,
                      minWidth: xTranslate,
                      transform: !opponent ? `` : `rotate(180deg)`,
                    }}
                  />
                </motion.div>
              )}

            {pet && opponentPet && (
              <img
                src={
                  opponent
                    ? getTelegramPetImage(opponentPet.petConfigV2)
                    : getTelegramPetImage(pet.petConfigV2, 'back')
                }
                alt={`${opponent ? 'opponent' : 'player'} pet`}
                style={{ position: 'absolute' }}
              />
            )}

            {/* charge image */}
            {playMoveEvent && playMoveEvent.move.userImage && (
              <img
                src={playMoveEvent.move.userImage}
                style={{
                  opacity:
                    chargeVisible &&
                    playMoveEvent.initiatorPlayerId === playerId
                      ? 1
                      : 0,
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
                alt="impact"
              />
            )}

            {/* impact image */}
            {playMoveEvent && playMoveEvent.move.targetImage && (
              <>
                {
                  <img
                    src={playMoveEvent.move.targetImage}
                    style={{
                      opacity:
                        impactVisible &&
                        playMoveEvent.initiatorPlayerId !== playerId
                          ? 1
                          : 0,
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    alt="impact"
                  />
                }
              </>
            )}

            {/* projectile image for opponent*/}
            {playMoveEvent &&
              opponent &&
              playMoveEvent.move.projectileImage && (
                <motion.div
                  style={{
                    position: 'absolute',
                    top: opponent ? halfOpponentPetSize : undefined,
                    bottom: opponent ? undefined : halfPetSize,
                    left: opponent ? undefined : halfPetSize,
                    right: opponent ? halfOpponentPetSize : undefined,
                    transformOrigin: opponent ? 'top right' : 'bottom left',
                  }}
                  initial="hidden"
                  animate={
                    projectileVisible &&
                    playMoveEvent.initiatorPlayerId === playerId
                      ? ['ranged']
                      : 'hidden'
                  }
                  variants={projectileVariants}
                >
                  <img
                    src={playMoveEvent.move.projectileImage}
                    alt="projectile"
                    style={{
                      height: yTranslate,
                      minWidth: xTranslate,
                      transform: !opponent ? `` : `rotate(180deg)`,
                    }}
                  />
                </motion.div>
              )}
          </>
        )}
      </motion.div>
    </Box>
  );

  return (
    <>
      <StatusInformationModal
        isOpen={isOpen}
        onClose={onClose}
        status={status}
        playerId={playerId}
      />
      <Flex
        flexDirection="column"
        padding={opponent ? '0 16px' : '16px'}
        w={`40vw`}
        cursor={'pointer'}
        {...props}
        onClick={() => {
          onOpen();
        }}
      >
        {!opponent ? image : null}
        <Box>
          <Text textAlign={opponent ? 'right' : 'left'}>
            {getPetName(opponent ? opponentPet : pet, player) ?? player}
          </Text>

          <Text textAlign={opponent ? 'right' : 'left'}>
            <TranslatedText translationKey={`textLvl`} defaultMessage={`Lvl`} />
            {` ${level}`}
          </Text>
        </Box>
        <Bar toProgress={`${(Math.max(hp, 0) * 100) / maxHp}%`} />
        <BreakIndicator
          level={breakLevel}
          reverse={opponent}
          printHand={pet.id === DEFAULT_TELEGRAM_PET}
        />
        <Flex
          flexDir={'column'}
          alignItems={opponent ? 'flex-end' : 'flex-start'}
        >
          <StatusIndicators status={status} />
        </Flex>
        {opponent ? image : null}
      </Flex>
    </>
  );
};
