import { ClaimDailyTicketsModal } from '@/components/battle/ClaimDailyTicketsModal';
import { NotCoinModal } from '@/components/notcoin/NotCoinModal';
import {
  GAME_SETTINGS_TELEGRAM_TICKETS_PER_DAY,
  useGameSettings,
} from '@/components/battle/hooks/useGameSettings';
import { useUserData } from '@/context/FirestoreContext';
import { TermConditionsModal } from '@/components/home/TermConditionsModal';
import useLocalStorage from '@/hooks/useLocalStorage';

/**
 * Place any modal messages that need to be displayed to the user here.
 * Add code for specific modals in the order they should be displayed.
 */
export const ModalMessageManager = () => {
  const { userData } = useUserData();
  const notCoinBonusTickets =
    userData?.NotcoinReferral?.bonusTelegramTickets ?? 0;
  const telegramNextDayTimestampMs = userData?.telegramNextDayTimestampMs ?? 0;
  // backend will prevent bad actors that change their system time
  // to after `telegramNextDayTimestampMs` since validation is done there
  const canClaimTickets = telegramNextDayTimestampMs < Date.now();

  const settings = useGameSettings();

  /* // Remove T&C modal from showing since we want to no overwhelm NOTcoin users
  const [firstTimeSeeTerms, setFirstTimeSeeTerms] = useLocalStorage(
    'firstTimeSeeTerms',
    false,
  );

  if (!firstTimeSeeTerms) {
    return (
      <TermConditionsModal
        firstTimeSeeTerms={firstTimeSeeTerms}
        setFirstTimeSeeTerms={setFirstTimeSeeTerms}
      />
    );
  }
  */

  // We want to prioritize showing the not coin modal of the daily tickets modal
  if (notCoinBonusTickets > 0) {
    return <NotCoinModal ticketAmount={notCoinBonusTickets} />;
  }

  // wait for settings to load
  if (canClaimTickets && settings) {
    return (
      <ClaimDailyTicketsModal
        isOpen={canClaimTickets}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClose={() => {}}
        ticketsToClaim={settings[GAME_SETTINGS_TELEGRAM_TICKETS_PER_DAY] ?? 3}
      />
    );
  }

  return null;
};
