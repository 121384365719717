import { PreloadSounds } from '@/components/Sounds/PreloadSounds';
import { useMusic } from '@/components/Sounds/useMusic';
import { MainContainer } from '@/components/battle/MainContainer';
import { Home } from '@/components/home/Home';
import { BlinkingText } from '@/components/ui/atoms/BlinkingText';
import { TELEGRAM_PET_ENDPOINT } from '@/config/endpoints';
import { useUserData } from '@/context/FirestoreContext';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { bgSounds } from '@/lib/sounds';
import { Center } from '@chakra-ui/react';
import { postEvent } from '@tma.js/sdk-react';
import { useEffect, useState } from 'react';

export const HomePage = () => {
  const { userData } = useUserData();
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    postEvent(`web_app_setup_closing_behavior`, { need_confirmation: false });
  }, []);

  const { apiPost } = useAPI();

  const alert = useAlert();

  const createBaby = () => {
    const answers = Array.from({ length: 5 }, () =>
      Math.floor(Math.random() * 2),
    );

    apiPost(`${TELEGRAM_PET_ENDPOINT}`, {
      answers,
    })
      .then((pet: any) => {
        console.log('pet created', pet, 'answers', answers);
      })
      .catch((error: any) => {
        alert({
          title: error.message || 'Error',
        });
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (userData && !userData.telegramPet) {
      createBaby();
    } else if (userData && !isLoading) {
      setInitialized(true);
    }
  }, [userData, isLoading]);

  useMusic(bgSounds.menu);

  if (isLoading)
    return (
      <Center w="full" h="full">
        <BlinkingText w="full" textAlign={'center'}>
          Loading...
        </BlinkingText>
      </Center>
    );

  return (
    <MainContainer padding={0}>
      <PreloadSounds />
      {initialized && <Home />}
    </MainContainer>
  );
};
