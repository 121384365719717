export type ColorId =
  | 'Green'
  | 'Yellow'
  | 'Black'
  | 'White'
  | 'Red'
  | 'DarkGreen';

export const Colors = {
  DarkGreen: '#086F54',
  Green: '#3cc4a1',
  Yellow: '#ebdc53',
  Black: '#000',
  White: '#fff',
  Red: '#a80000',
  DarkYellow: '#B7A500',
};
