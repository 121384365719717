import { Flex, Center, Box } from '@chakra-ui/react';
import { BlinkingText } from '../ui/atoms/BlinkingText';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { CommandCard } from '../ui/organisms/CommandCard';
import { MenuMoves } from './MenuMoves/MenuMoves';
import { PetInformation } from './PetInformation/PetInformation';
import { WaitingForOpponentToPlay } from './WaitingForOpponentToPlay';
import { useMemo } from 'react';
import {
  BattleEntity,
  BattlePlayerEntity,
  BattleState,
  DEFAULT_TELEGRAM_PET,
  RootState,
  getOpponentData,
} from 'genopets-utils';
import { useSelector } from 'react-redux';
import { useUIBattleContext } from './context/useBattleContext';

export const Battle = ({
  player,
  battle,
}: {
  player: '' | BattlePlayerEntity | undefined;
  battle: BattleEntity;
}) => {
  const { user, busyUi } = useUIBattleContext();

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const opponent = useMemo(
    () =>
      user?.uid &&
      battleState?.player1 &&
      battleState?.player2 &&
      getOpponentData(battleState, user.uid),
    [user, battleState],
  );

  return (
    <>
      <Box position={'relative'} w="full" h="full">
        {opponent && (
          <Flex position="absolute" top={0} right={-2}>
            <PetInformation
              player={
                battleState.player1.id !== user?.uid ? 'player 1' : 'player 2'
              }
              playerId={
                battleState.player1.id !== user?.uid
                  ? battleState.player1.id
                  : battleState.player2.id
              }
              level={opponent.petInfo.level}
              breakLevel={opponent.break.level}
              status={opponent.pet.status}
              maxHp={opponent.pet.base.HP}
              opponent
            />
          </Flex>
        )}
        {player && (
          <Flex
            position="absolute"
            bottom={-2}
            // remove padding from higher
            left={-2}
            right={-2}
            alignItems={'flex-end'}
          >
            <PetInformation
              player={
                battleState.player1.id === user?.uid ? 'player 1' : 'player 2'
              }
              playerId={
                battleState.player1.id === user?.uid
                  ? battleState.player1.id
                  : battleState.player2.id
              }
              level={player.petInfo.level}
              breakLevel={player.break.level}
              maxHp={player.pet.base.HP}
              status={player.pet.status}
              flexShrink={0}
            />

            {!battleState.winner &&
              battleState.nextPlayer === user?.uid &&
              !busyUi && (
                <CommandCard
                  expiryTime={battle?.expiryTime}
                  w="60vw"
                  zIndex={8}
                  printHand={
                    battle.actions.length < 4 &&
                    battleState.player1.petInfo.id === DEFAULT_TELEGRAM_PET
                  }
                  altHand={
                    player.break.level === player.break.triggerLevel &&
                    battleState.player1.petInfo.id === DEFAULT_TELEGRAM_PET
                  }
                >
                  <MenuMoves player={player} />
                </CommandCard>
              )}
            {!battleState.winner &&
              battleState.nextPlayer !== user?.uid &&
              !busyUi && <WaitingForOpponentToPlay battle={battle} />}
          </Flex>
        )}
      </Box>
      {!battleState.winner &&
        battleState.nextPlayer === user?.uid &&
        !busyUi && (
          <>
            <Center
              top={0}
              right={0}
              left={0}
              bottom={0}
              position="absolute"
              pointerEvents={'none'}
              zIndex={7}
            >
              <BlinkingText>
                <TranslatedText
                  translationKey={`yourMove`}
                  defaultMessage={`Your Move`}
                />
                !
              </BlinkingText>
            </Center>
          </>
        )}
    </>
  );
};
