import { BlinkingText } from '@/components/ui/atoms/BlinkingText';
import { Text } from '@/components/ui/atoms/Text';
import { Button } from '@/components/ui/molecules/Button';
import { Card } from '@/components/ui/molecules/Card';
import { LEARN_NEW_MOVE_ENDPOINT } from '@/config/endpoints';
import { useTelegramPet } from '@/context/FirestoreContext';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { sounds } from '@/lib/sounds';
import { Center, Flex } from '@chakra-ui/react';
import {
  BattleMoveEntity,
  MoveType,
  TelegramRolledMoveType,
  getTelegramPetImage,
} from 'genopets-utils';
import { useEffect, useState } from 'react';
import { MenuChooseMoves } from './MenuChooseMoves/MenuChooseMoves';
import { useMoves } from './hooks/useMoves';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { GoHomeButtons } from './GoHomeButtons';
import { useTranslation } from 'react-i18next';
import { useEffectPlayer } from '@/context/EffectPlayerContext';

export const PetChooseMove = () => {
  const { t: translateText } = useTranslation();
  const moves = useMoves();

  const [move, setMove] = useState<BattleMoveEntity>();

  const { pet } = useTelegramPet();

  useEffect(() => {
    if (moves && pet) {
      const mv = moves.find(
        (m) => pet?.telegramRolledMove && m.name === pet?.telegramRolledMove,
      );
      if (mv) {
        setMove(mv);
      }
    }
  }, [moves, pet]);

  const type = move?.types.includes(MoveType.Break)
    ? TelegramRolledMoveType.BREAK
    : TelegramRolledMoveType.REGULAR;

  const moveTypeLabel = type === TelegramRolledMoveType.BREAK ? 'power' : '';
  const alert = useAlert();
  const [blinking, setBlinking] = useState(true);
  const { apiPost } = useAPI();
  const [showLearn, setShowLearn] = useState(false);
  const [learned, setLearned] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [discard, setDiscard] = useState(false);

  const effectPlayer = useEffectPlayer();

  useEffect(() => {
    let timeout: any;
    if (pet && blinking && move) {
      effectPlayer.playEffect(sounds.newMove);
      timeout = setTimeout(() => {
        setBlinking(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [pet, move]);

  const handleLearnMove = () => {
    setShowLearn(true);
  };

  const triggerLearnMove = async (
    learnMove: boolean,
    moveToReplace?: string,
  ) => {
    setIsProcessing(true);

    apiPost(LEARN_NEW_MOVE_ENDPOINT, {
      learnMove,
      moveToReplace,
      type,
    })
      .then(() => {
        if (learnMove) {
          setLearned(true);
          effectPlayer.playEffect(sounds.newMoveLearned);
        } else {
          setMove(undefined);
        }
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
        setLearned(false);
      })
      .finally(() => setIsProcessing(false));
  };

  return (
    <>
      <Center w="full" h="full" position="relative">
        {pet && (
          <Center flexDirection={'column'} position="relative">
            <img
              src={getTelegramPetImage(pet.petConfigV2)}
              alt="pet"
              style={{ width: '150px', height: '150px' }}
            />
            {blinking && (
              <>
                <BlinkingText textAlign="center">
                  {type === TelegramRolledMoveType.BREAK ? (
                    <TranslatedText
                      defaultMessage="oh snap"
                      translationKey="ohSnap"
                    />
                  ) : (
                    <TranslatedText
                      defaultMessage="woah"
                      translationKey="woah"
                    />
                  )}
                  !
                </BlinkingText>
                <BlinkingText textAlign="center">
                  <TranslatedText
                    translationKey={`youGotNewMove`}
                    defaultMessage={`you got a new ${moveTypeLabel} move!`}
                    props={{ moveType: moveTypeLabel }}
                  />
                  !
                </BlinkingText>
              </>
            )}
          </Center>
        )}
        {!blinking && pet && (
          <Card
            position="absolute"
            bottom={'-16px'}
            left={'-16px'}
            w="calc(100% + 32px)"
            flexDirection={'column'}
            gap={2}
            display={'flex'}
          >
            {!moves ? (
              <Text colorId="Black">
                <TranslatedText
                  translationKey={`loading`}
                  defaultMessage={`Loading`}
                />
                ...
              </Text>
            ) : (
              <>
                {learned && move ? (
                  <>
                    <Text colorId="Black">
                      <TranslatedText
                        translationKey={`petLearnedMove`}
                        defaultMessage={`${
                          pet.nickname || pet.name
                        } learned ${translateText(move.lokaliseKeyName as string, { defaultValue: move.name as string })}!`}
                        props={{
                          pet: pet.nickname || pet.name,
                          move: translateText(move.lokaliseKeyName as string, {
                            defaultValue: move.name as string,
                          }),
                        }}
                      />
                      !
                    </Text>
                    <GoHomeButtons />
                  </>
                ) : (
                  <>
                    {showLearn ? (
                      <MenuChooseMoves
                        pet={pet}
                        isBreak={move?.types.includes(MoveType.Break)}
                        onBack={() => setShowLearn(false)}
                        onReplace={(moveToReplace?: string) => {
                          triggerLearnMove(true, moveToReplace);
                        }}
                      />
                    ) : (
                      <>
                        {move && !isProcessing && (
                          <>
                            <Flex w="full">
                              <Flex flexDirection={'column'} w="50%">
                                <Text colorId="Black">
                                  {' '}
                                  <TranslatedText
                                    translationKey={move.lokaliseKeyName as any}
                                    defaultMessage={move.name}
                                  />
                                </Text>
                                <Text colorId="Black">
                                  <TranslatedText
                                    translationKey={`moveStatTextPower`}
                                    defaultMessage={`power`}
                                  />
                                  {`:${move.power}`}
                                </Text>
                              </Flex>
                              <Flex
                                flexDirection={'column'}
                                w="50%"
                                alignItems={'flex-end'}
                              >
                                <Text colorId="Black">
                                  {move.categories?.join(',') ??
                                    move.types.join(',')}
                                </Text>
                                <Text colorId="Black">
                                  <TranslatedText
                                    translationKey={`coolDown`}
                                    defaultMessage={`cooldown`}
                                  />
                                  {`:${move.cooldown}`}
                                </Text>
                              </Flex>
                            </Flex>
                            <Text my="16px" colorId="Black" width={'full'}>
                              {translateText(
                                move.lokaliseKeyDescription as string,
                                {
                                  defaultValue:
                                    move.description || 'No description',
                                },
                              )}
                            </Text>
                          </>
                        )}
                        {move ? (
                          <>
                            <Button
                              colorId="Black"
                              w="full"
                              onClick={handleLearnMove}
                              isDisabled={isProcessing}
                              whiteSpace={'pre-wrap'}
                            >
                              {isProcessing ? (
                                <>
                                  {discard ? (
                                    <TranslatedText
                                      translationKey={`discarding`}
                                      defaultMessage={`Discarding`}
                                    />
                                  ) : (
                                    <TranslatedText
                                      translationKey={`learning`}
                                      defaultMessage={`Learning`}
                                    />
                                  )}{' '}
                                  <TranslatedText
                                    translationKey={move.lokaliseKeyName as any}
                                    defaultMessage={move.name}
                                  />
                                  ...
                                </>
                              ) : (
                                <TranslatedText
                                  translationKey={`learnNewMove`}
                                  defaultMessage={`Learn new ${moveTypeLabel} move`}
                                  props={{ moveType: moveTypeLabel }}
                                />
                              )}
                              !
                            </Button>
                          </>
                        ) : (
                          <Text
                            colorId="Black"
                            width={'full'}
                            textAlign={'center'}
                          >
                            <TranslatedText
                              translationKey={`moveDiscarded`}
                              defaultMessage={`Move discarded`}
                            />
                          </Text>
                        )}
                        {!isProcessing && move && (
                          <Button
                            colorId="White"
                            variant={'secondary'}
                            w="full"
                            onClick={() => {
                              setDiscard(true);
                              triggerLearnMove(false);
                            }}
                          >
                            <TranslatedText
                              translationKey={`discard`}
                              defaultMessage={`Discard`}
                            />
                          </Button>
                        )}
                        {!isProcessing && !move && <GoHomeButtons />}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Card>
        )}
      </Center>
    </>
  );
};
