import { DateTime } from 'luxon';
import { UserData } from '../types';

interface AdViewResult {
  currentViews: number;
  isNewDay: boolean;
}

function isNewUtcDay(lastViewedAt: number | undefined): boolean {
  if (!lastViewedAt) return true;

  const lastView = DateTime.fromMillis(lastViewedAt).toUTC();
  const now = DateTime.utc();

  // Check if the last view was before the current UTC midnight
  return lastView.startOf(`day`) < now.startOf(`day`);
}

export const MAX_ADSGRAM_VIEWS = 5;

export function calculateAdViews(userData?: UserData): AdViewResult {
  const adsgramLastViewedAt = userData?.telegram?.adsgramLastViewedAt;
  const adsgramViewCount = userData?.telegram?.adsgramViewCount ?? 0;

  // If no previous views or it's a new UTC day
  if (!adsgramLastViewedAt || isNewUtcDay(adsgramLastViewedAt)) {
    return {
      currentViews: 0,
      isNewDay: true,
    };
  }

  // Same UTC day, return current count
  return {
    currentViews: adsgramViewCount,
    isNewDay: false,
  };
}
