import { Text } from '@/components/ui/atoms/Text';
import { FEED_TO_EARN_FEED_ENDPOINT } from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { Box, Center, Flex, Input, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Button } from '../ui/molecules/Button';
import { Modal } from '../ui/molecules/Modal';
import { FeedToEarnMode, Ingredient } from './FeedToEarn';
import { getFontFamilyByLanguage } from '@/utils/utils';
import { Trash } from '../ui/icons/Trash';
import { DropdownIcon } from '../ui/icons/Dropdown';

const FoodRow = ({
  ingredient: ingredient,
  onDelete,
  onEditIngredient: onEditIngredient,
}: {
  ingredient: Ingredient;
  onDelete: () => void;
  onEditIngredient: (ingredient: Ingredient) => void;
}) => {
  return (
    <Flex gap={1} w="full">
      <button onClick={() => onEditIngredient(ingredient)}>
        <Center
          padding={'16px 8px'}
          border={`1px solid var(--Black)`}
          flexShrink={1}
          minWidth="100px"
          maxWidth="150px"
        >
          <Text colorId="Black" style={{ textAlign: 'right', width: '100%' }}>
            {ingredient.grams.toFixed(0) ?? '0'}G
          </Text>
          <Box padding={'0px 6px'}>
            <DropdownIcon />
          </Box>
        </Center>
      </button>
      <Box
        padding={'16px 4px'}
        border={`1px solid var(--Black)`}
        flexGrow={2}
        wordBreak={'break-word'}
        whiteSpace={'pre-wrap'}
      >
        <Text colorId="Black" style={{ textAlign: 'center' }}>
          {ingredient.name}
        </Text>
      </Box>
      <Button
        colorId="Black"
        padding="10px"
        onClick={onDelete}
        minW={0}
        flexShrink={0}
        flexGrow={0}
      >
        <Trash />
      </Button>
    </Flex>
  );
};

enum FormType {
  add,
  edit,
}

export const ValidateView = ({
  setMode,
  setValidatedImage,
  food,
}: {
  setMode: React.Dispatch<React.SetStateAction<FeedToEarnMode>>;
  setValidatedImage: React.Dispatch<React.SetStateAction<any>>;
  imageUrl: string;
  food?: Ingredient[];
}) => {
  const { t, i18n } = useTranslation();
  const [isTransacting, setIsTransacting] = useState(false);

  const [humanVerifiedIngredients, setHumanVerifiedIngredients] = useState([
    ...(food ?? []),
  ]);

  const defaultIngredient = {
    name: '',
    calories: 1,
    grams: 100,
  };

  const [formType, setFormType] = useState<FormType>(FormType.add);

  const [ingredientItem, setIngredientItem] =
    useState<Ingredient>(defaultIngredient);
  const [error, setError] = useState<
    'addIngredientError' | 'addIngredientGramsError'
  >();

  const { onOpen, isOpen, onClose } = useDisclosure();

  const alert = useAlert();

  const { apiPost } = useAPI();

  const confirmFeed = () => {
    setIsTransacting(true);
    apiPost(FEED_TO_EARN_FEED_ENDPOINT, {
      humanVerifiedIngredients,
    })
      .then((data) => {
        if (data) {
          setMode('success');
        } else {
          alert({ title: data?.message ?? 'Error' });
        }
      })
      .catch((err) => {
        alert({ title: err.message ?? 'Error' });
      })
      .finally(() => setIsTransacting(false));
  };

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        cardProps={{ gap: 0, mb: windowHeight * 0.22 }}
        footerProps={{ mt: 1 }}
        showBackButton={false}
      >
        <Text colorId="Black" pb={3}>
          {formType === FormType.add ? (
            <TranslatedText
              translationKey="addIngredients"
              defaultMessage="Add Ingredient"
            />
          ) : (
            <TranslatedText
              translationKey="adjustUnits"
              defaultMessage="Adjust Units"
            />
          )}
        </Text>
        {formType === FormType.add && (
          <Center border={`1px solid var(--Black)`} flexShrink={1}>
            <Input
              value={ingredientItem.name}
              onChange={(e) => {
                if (e.target.value.length > 12) {
                  setError('addIngredientError');
                  return;
                }
                setError(undefined);
                setIngredientItem({
                  ...ingredientItem,
                  name: e.target.value,
                });
              }}
              border="none"
              _focus={{}}
              _focusVisible={{}}
              fontFamily={getFontFamilyByLanguage(i18n)}
              fontSize="14px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="20px"
              textTransform="uppercase"
              placeholder={t('foodItemPlaceholder', 'food / ingredient')}
              padding={'8px'}
              max={16}
            />
          </Center>
        )}
        {formType === FormType.edit && (
          <Box padding={'8px 0px'}>
            <Text colorId="Black">{ingredientItem.name}</Text>
          </Box>
        )}
        <Flex gap={1} w="full" padding={'8px 0px'}>
          <Center border={`1px solid var(--Black)`} flexShrink={1} flex={1}>
            <Input
              value={ingredientItem.grams === 0 ? '' : ingredientItem.grams}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '') {
                  setError('addIngredientGramsError');
                  setIngredientItem({ ...ingredientItem, grams: 0 });
                  return;
                }

                const newGrams = parseFloat(value);

                if (isNaN(newGrams) || newGrams === 0) {
                  setError('addIngredientGramsError');
                  return;
                }

                setIngredientItem({ ...ingredientItem, grams: newGrams });
                setError(undefined);
              }}
              type="number"
              inputMode="decimal"
              border="none"
              _focus={{}}
              _focusVisible={{}}
              fontFamily={getFontFamilyByLanguage(i18n)}
              placeholder={t('grams')}
              max={4}
              color="black"
              margin={0}
              style={{
                minWidth: '50px',
                maxWidth: formType === FormType.edit ? undefined : '120px',
              }}
            />
          </Center>
          <Center flexShrink={1}>
            <Text colorId="Black">
              <TranslatedText translationKey="grams" defaultMessage="Grams" />
            </Text>
          </Center>
        </Flex>

        {error && (
          <Text colorId="Red">
            <TranslatedText
              translationKey={error}
              defaultMessage={`Ingredient error`}
              props={{ count: 17 }}
            />
          </Text>
        )}

        <Button
          mt={2}
          colorId="Black"
          w="full"
          onClick={() => {
            if (!ingredientItem) return;
            if (ingredientItem.grams <= 0) {
              setError('addIngredientGramsError');
              return;
            }
            if (formType === FormType.add) {
              setHumanVerifiedIngredients(
                humanVerifiedIngredients?.concat([ingredientItem]),
              );
              alert({
                title: t('foodAdded', {
                  defaultMessage: `${ingredientItem.grams}G ${ingredientItem.name} added`,
                  food: `${ingredientItem.grams}G ${ingredientItem.name}`,
                }),
              });
            } else {
              const index = humanVerifiedIngredients.findIndex(
                (ingredient) => ingredient.name === ingredientItem.name,
              );

              if (index !== -1) {
                humanVerifiedIngredients[index] = ingredientItem;
                setHumanVerifiedIngredients(humanVerifiedIngredients);
              }
            }

            setIngredientItem(defaultIngredient);
            onClose();
          }}
        >
          <TranslatedText translationKey={`save`} defaultMessage={`Save`} />
        </Button>
        <Button
          colorId="White"
          w="full"
          isDisabled={isTransacting}
          onClick={onClose}
        >
          <TranslatedText translationKey="goBack" defaultMessage="Go back" />
        </Button>
      </Modal>
      <Flex
        flexDirection={'column'}
        gap={1}
        w="full"
        maxHeight={'50vh'}
        overflow={'auto'}
      >
        {humanVerifiedIngredients ? (
          <>
            {humanVerifiedIngredients.map((f, i) => (
              <FoodRow
                ingredient={f}
                key={i}
                onDelete={() => {
                  setHumanVerifiedIngredients(
                    humanVerifiedIngredients.filter((_, j) => i !== j),
                  );
                  alert({
                    title: t('foodRemoved', {
                      defaultMessage: `${f.grams}G ${f.name} removed`,
                      food: `${f.grams}G ${f.name}`,
                    }),
                  });
                }}
                onEditIngredient={(ingredient: Ingredient) => {
                  setIngredientItem(ingredient);
                  setFormType(FormType.edit);
                  onOpen();
                }}
              />
            ))}
          </>
        ) : null}
      </Flex>
      <Button
        colorId="Black"
        w="full"
        onClick={() => {
          setIngredientItem(defaultIngredient);
          setError(undefined);
          setFormType(FormType.add);
          onOpen();
        }}
        isDisabled={isTransacting}
      >
        <TranslatedText
          translationKey="addIngredients"
          defaultMessage="Add Ingredient"
        />
      </Button>
      <Button
        colorId="Black"
        w="full"
        onClick={confirmFeed}
        isDisabled={isTransacting}
      >
        <TranslatedText
          translationKey="confirmAndFeed"
          defaultMessage="Confirm & Feed"
        />
        !
      </Button>
      <Button
        colorId="White"
        w="full"
        isDisabled={isTransacting}
        onClick={() => {
          setMode('default');
          setValidatedImage(null);
        }}
      >
        <TranslatedText translationKey="cancel" defaultMessage="Cancel" />
      </Button>
    </>
  );
};
