import { useMusic } from '@/components/Sounds/useMusic';
import { MainContainer } from '@/components/battle/MainContainer';
import { bgSounds } from '@/lib/sounds';
import ShopPageBg from '../../images/shop-page-bg.png';
import Shop from '@/components/shop/Shop';

export const ShopPage = () => {
  useMusic(bgSounds.viralLoopPage);

  return (
    <MainContainer
      background={ShopPageBg}
      backgroundPosition="top"
      backgroundSize="100% auto"
      padding={0}
    >
      <Shop />
    </MainContainer>
  );
};
