import { Link } from '@/components/Link/Link';
import { Text } from '@/components/ui/atoms/Text';
import {
  VITE_AIRDROP_DISABLED_TIME,
  VITE_BATTLE_AIRDROP_ENABLED,
} from '@/config/constants';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Card, CardProps } from '../ui/molecules/Card';
import { MenuItemPop } from '../ui/molecules/MenuItemPop';
import { useAirdropData } from './hooks/useAirdropData';
import { useAirdropTimeLeft } from '@/components/airdrop/hooks/useAirdropTimeLeft';
import { Flex } from '@chakra-ui/react';
import { DateTime } from 'luxon';

export const AirdropCard = ({
  cardProps,
  showTimeLeft = true,
}: {
  cardProps?: CardProps;
  showTimeLeft?: boolean;
}) => {
  const { amount } = useAirdropData();
  const timeLeft = useAirdropTimeLeft();
  const disabledTime =
    VITE_AIRDROP_DISABLED_TIME && DateTime.fromISO(VITE_AIRDROP_DISABLED_TIME);

  if (!VITE_BATTLE_AIRDROP_ENABLED || disabledTime?.diffNow().toMillis() < 0) {
    return <></>;
  }

  return (
    <Link to="/earn" style={{ width: '100%' }}>
      <Card
        w="full"
        flexDirection={'column'}
        background={'var(--Yellow)'}
        padding={'8px 16px 0px 16px'}
        gap={0}
        {...cardProps}
      >
        {typeof amount !== 'undefined' && timeLeft !== '00:00:00' ? (
          <>
            <MenuItemPop width="full" color="black" padding={0}>
              <Flex flexDirection={`column`}>
                <Text colorId="Black" fontSize="10px">
                  OKX AIRDROP CHALLENGE IS LIVE!
                  {/* $
                  <TranslatedText
                    translationKey={`amountInAirdropPrizes`}
                    defaultMessage={`${amount} in airdrop prizes`}
                    props={{ amount }}
                  />
                  ! */}
                </Text>
                {/* {showTimeLeft && (
                  <Text colorId="Black" fontSize="10px">
                    <TranslatedText
                      translationKey={`durationLeftInAirdropPrizes`}
                      defaultMessage={`${timeLeft} left`}
                      props={{ timeLeft }}
                    />
                    !
                  </Text>
                )} */}
              </Flex>
            </MenuItemPop>
          </>
        ) : (
          <Text color={`var(--Black)`} w="full">
            <TranslatedText
              translationKey={`loading`}
              defaultMessage={`loading`}
            />
            ...
          </Text>
        )}
      </Card>
    </Link>
  );
};
