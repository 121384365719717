import { UiEventPlayStatus, UiEventType } from 'genopets-utils';
import { useEffect, useState } from 'react';
import { useUIBattleContext } from '../context/useBattleContext';

export const STATUS_TRANSLATION_PREFIX = 'specialStatEffect';

export const usePrintPlayStatus = () => {
  const { uiEvent, dispatchUiEventPlayed } = useUIBattleContext();

  const [playStatusEvent, setPlayStatusEvent] =
    useState<UiEventPlayStatus['payload']>();

  useEffect(() => {
    if (uiEvent && uiEvent.type === UiEventType.PLAY_STATUS) {
      setPlayStatusEvent(uiEvent.payload);
    }
  }, [uiEvent]);

  useEffect(() => {
    if (playStatusEvent && uiEvent) {
      // DEACTIVATE PLAY STATUS EVENT, this event is integrated to MOVE EVENT (see usePrintMove.tsx)
      // keep the code here for future reference

      // const status = playStatusEvent.status;

      // const attackerPetFromState =
      //   playStatusEvent.initiatorPlayerId === battleState.player1.id
      //     ? battleState.player1.pet
      //     : battleState.player2.pet;
      // const defenderPetFromState =
      //   playStatusEvent.initiatorPlayerId === battleState.player1.id
      //     ? battleState.player2.pet
      //     : battleState.player1.pet;

      // const attackerPetName = getPetName(
      //   playStatusEvent.initiatorPlayerId === battleState.player1.id
      //     ? battleState.player1.petInfo
      //     : battleState.player2.petInfo,
      //   battleState.player1.id === user?.uid ? 'player 1' : 'player 2',
      // ) as string;
      // const defenderPetName = getPetName(
      //   playStatusEvent.initiatorPlayerId === battleState.player1.id
      //     ? battleState.player2.petInfo
      //     : battleState.player1.petInfo,
      //   battleState.player1.id === user?.uid ? 'player 1' : 'player 2',
      // ) as string;

      // const petForStatus =
      //   status.target === 'self' ? attackerPetFromState : defenderPetFromState;
      // const statusModifier =
      //   status.statAffected &&
      //   petForStatus.statusModifier?.[status.statAffected];
      // const isMax = statusModifier === 4;
      // const isMin = statusModifier === -4;

      // const title = (
      //   <VStack alignItems={'flex-start'}>
      //     <Box key={status.value}>
      //       {status.value && status.statAffected !== PetStatisticsEnum.HP ? (
      //         <>
      //           <Text as="span" colorId="Black">
      //             {status.target === `self`
      //               ? `${attackerPetName}`
      //               : `${defenderPetName}`}
      //           </Text>
      //           <Text as="span" colorId="Black">
      //             {`'s ${status.statAffected}`}
      //           </Text>
      //           <Text as="span" colorId="Black">
      //             {status.value.startsWith(`-`)
      //               ? `${isMin ? ' ' + translateText('wontGoAny', { defaultValue: "won't go any" }) : ' ' + translateText('went', { defaultValue: 'went' })}`
      //               : `${isMax ? ' ' + translateText('wontGoAny', { defaultValue: "won't go any" }) : ' ' + translateText('went', { defaultValue: 'went' })}`}
      //           </Text>
      //           <Text
      //             as="span"
      //             colorId={status.value.startsWith(`-`) ? 'Red' : 'Green'}
      //           >
      //             {status.value.startsWith(`-`)
      //               ? `${isMin ? ` ${translateText('lower', { defaultValue: 'lower' })}` : ' ' + translateText('down', { defaultValue: 'down' })}`
      //               : `${isMax ? ` ${translateText('higher', { defaultValue: 'higher' })}` : ' ' + translateText('up', { defaultValue: 'up' })}`}
      //           </Text>
      //           <Text as="span" colorId="Black">
      //             !
      //           </Text>
      //         </>
      //       ) : (
      //         <Text as="span" colorId="Black">
      //           <TranslatedText
      //             translationKey={
      //               (STATUS_TRANSLATION_PREFIX + status.effect).replace(
      //                 ' ',
      //                 '',
      //               ) as any
      //             }
      //             defaultMessage={status.name ?? status.effect ?? 'status name'}
      //             props={{
      //               pet:
      //                 status.target === `self`
      //                   ? `${attackerPetName}`
      //                   : `${defenderPetName}`,
      //             }}
      //           />
      //         </Text>
      //       )}
      //     </Box>
      //   </VStack>
      // );

      // alert({
      //   title,
      //   onCloseComplete: () => {
      //     dispatchUiEventPlayed(uiEvent.id);
      //     setPlayStatusEvent(undefined);
      //   },
      // });

      // hapticFeedback.impactOccurred('heavy');

      // const statsValue = status.value ?? '0';
      // if (parseInt(statsValue) > 0) {
      //   playBuffSound();
      // } else if (parseInt(statsValue) < 0) {
      //   playNerfSound();
      // }

      dispatchUiEventPlayed(uiEvent.id);
      setPlayStatusEvent(undefined);
    }
  }, [playStatusEvent]);
};
