import { Flex, Box, Center } from '@chakra-ui/react';
import { Text } from '@/components/ui/atoms/Text';
import { Button } from '@/components/ui/molecules/Button';
import { MANTLE_MAINNET_CHAIN_ID } from '@/config/constants';
import PrivyWalletProvider, {
  privyWalletList,
} from '@/components/auth/PrivyWalletProvider';
import { usePrivy, useWallets } from '@privy-io/react-auth';
import { useUserData } from '@/context/FirestoreContext';
import { useState, useEffect } from 'react';
import {
  USER_MANTLE_CHECK_IN_ENDPOINT,
  USER_ADD_EVM_WALLET_ENDPOINT,
  USER_CREATE_MANTLE_CHECK_IN_TASK_ENDPOINT,
} from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { encodeFunctionData } from 'viem';
import {
  mantleCheckInAbi,
  MANTLE_MAINNET_CHECKIN_CONTRACT_ADDRESS,
} from 'genopets-utils';
import { getAndWaitForTransactionConfirmedReceipt } from '@/utils/getAndWaitForTransactionConfirmedReceipt';
import { Energy } from '@/components/ui/icons/Energy';
import { Colors } from '@/components/ui/colors';
import { Ticket } from '@/components/ui/icons/Ticket';
import { Modal } from '../ui/molecules/Modal';
import WhittakrImg from '@/images/whittakr-no-margin.png';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { getRandomGif } from '@/images/spinner-gifs/getRandomGif';
import { Okx } from '@/components/ui/icons/Okx';

const MantleCheckInDesktopContainer = () => {
  return (
    <PrivyWalletProvider>
      <MantleCheckInDesktop />
    </PrivyWalletProvider>
  );
};

const MantleCheckInDesktop = () => {
  const { apiPost, apiPut } = useAPI();
  const alert = useAlert();
  const { userData } = useUserData();
  const mantleCheckInNextDayTimestampMs =
    userData?.telegram?.mantleCheckInNextDayTimestampMs ?? 0;
  const mantleCheckInPending = userData?.telegram?.mantleCheckInPending;
  const hasCheckedIn = mantleCheckInNextDayTimestampMs > Date.now();

  const { wallets } = useWallets();
  const { ready, authenticated, connectWallet } = usePrivy();
  const isPrivyInitializing = !ready || !authenticated;

  const [isTransacting, setIsTransacting] = useState(false);
  const [isEvmWalletConnected, setIsEvmWalletConnected] = useState(false);
  const hasConnectedWallet = userData?.evmWallet || isEvmWalletConnected;
  const [
    connectWalletRewardModalTicketCount,
    setConnectWalletRewardModalTicketCount,
  ] = useState(0);

  const evmWallet = wallets.find(
    (wallet) =>
      (wallet as any).type === 'ethereum' &&
      wallet.chainId.startsWith(`eip155`) &&
      wallet.connectorType !== `embedded` &&
      wallet.walletClientType === `okx_wallet`,
  );

  const checkIn = async () => {
    if (hasCheckedIn || mantleCheckInPending) return;

    if (!evmWallet?.address || !isEvmWalletConnected) {
      connectWallet({
        walletList: privyWalletList,
      });
      return;
    }

    try {
      setIsTransacting(true);

      // switch to mantle network
      await evmWallet?.switchChain(MANTLE_MAINNET_CHAIN_ID);

      const provider = await evmWallet.getEthereumProvider();

      const encodedData = encodeFunctionData({
        abi: mantleCheckInAbi,
        functionName: 'checkIn',
        args: [userData?.id],
      });

      const txParams = {
        from: evmWallet.address,
        to: MANTLE_MAINNET_CHECKIN_CONTRACT_ADDRESS,
        data: encodedData,
        chainId: MANTLE_MAINNET_CHAIN_ID,
      };

      alert({
        title: `Please sign the transaction!`,
        duration: 5000,
      });

      const txid = await provider.request({
        method: 'eth_sendTransaction',
        params: [txParams],
      });

      console.log(`Transaction Hash: ${txid}`);

      // fire & forget
      apiPost(USER_CREATE_MANTLE_CHECK_IN_TASK_ENDPOINT, {
        txid,
      });

      alert({ title: `Confirming transaction...`, duration: 5000 });

      // let client wait for transaction to be confirmed on chain until retry limit reached before calling API
      await getAndWaitForTransactionConfirmedReceipt(provider, txid);

      // regardless if receipt has been confirmed, call and let API process this
      await apiPost(USER_MANTLE_CHECK_IN_ENDPOINT, {
        txid: txid,
      });

      alert({ title: `Check in successful!` });
    } catch (error: any) {
      alert({ title: `Err: ${error?.message}` });
      if (
        evmWallet?.address &&
        error?.message
          ?.toLowerCase()
          ?.includes(`disconnect your dapp first and then reconnect`)
      ) {
        evmWallet.disconnect();
      }
    } finally {
      setIsTransacting(false);
    }
  };

  // once the user has connected their wallet, save it to their game account
  useEffect(() => {
    // exit if no wallet is connected
    if (!isEvmWalletConnected || !evmWallet?.address) return;

    // if new wallet has been connected, save it to their game account
    if (userData?.evmWallet !== evmWallet?.address) {
      apiPut(USER_ADD_EVM_WALLET_ENDPOINT, {
        walletAddress: evmWallet?.address,
      })
        .then((data) => {
          if (data?.ticketAmount > 0) {
            setConnectWalletRewardModalTicketCount(data?.ticketAmount);
          }
        })
        .catch((error) => {
          alert({ title: `Err: ${error?.message}` });
        });
    }
  }, [isEvmWalletConnected, evmWallet?.address, userData?.evmWallet]);

  useEffect(() => {
    if (evmWallet && !isEvmWalletConnected) {
      evmWallet?.isConnected().then((result) => {
        if (result) {
          setIsEvmWalletConnected(true);
        } else {
          setIsEvmWalletConnected(false);
        }
      });
    } else if (isEvmWalletConnected && !evmWallet) {
      setIsEvmWalletConnected(false);
    }
  }, [evmWallet, isEvmWalletConnected]);

  const mantleCheckInCount = userData?.telegram?.mantleCheckInCount ?? 0;

  return (
    <>
      {connectWalletRewardModalTicketCount > 0 && (
        <OkxWalletConnectedRewardModal
          ticketCount={connectWalletRewardModalTicketCount}
          onClose={() => setConnectWalletRewardModalTicketCount(0)}
        />
      )}

      <Flex
        w={`full`}
        border={`solid 2px black`}
        background={hasConnectedWallet ? Colors.DarkGreen : `transparent`}
      >
        <Flex w={`full`} alignItems={`center`}>
          <Box pl={`6px`}>
            <Okx />
          </Box>
          <Flex
            flexDirection={`column`}
            gap={`4px`}
            w={`full`}
            borderRight={`1px solid black`}
            padding={`4px 0 4px 6px`}
            minHeight={`50px`}
            justifyContent={`center`}
          >
            <Text
              colorId={hasConnectedWallet ? `White` : 'Black'}
              textAlign={`left`}
              style={{ textWrap: `balance` }}
            >
              <TranslatedText
                translationKey={`connectOkxWallet`}
                defaultMessage={`Connect okx wallet`}
              />
            </Text>

            {!userData?.telegram?.hasConnectedEvmWallet && (
              <Flex w={`full`} alignItems={`center`}>
                <Ticket />
                <Text
                  colorId={hasConnectedWallet ? `White` : 'Black'}
                  w="full"
                  width={`auto`}
                >
                  5
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>

        <Button
          borderLeft={`1px solid black`}
          colorId={hasConnectedWallet ? `DarkGreen` : `Yellow`}
          padding={`8px`}
          maxWidth={hasConnectedWallet ? `80px` : `60px`}
          minWidth={`0px`}
          w={`full`}
          isDisabled={isPrivyInitializing}
          onClick={() => {
            if (hasConnectedWallet) return;
            connectWallet({
              walletList: privyWalletList,
            });
          }}
        >
          <Text colorId={hasConnectedWallet ? `White` : 'Black'}>
            {isPrivyInitializing ? `...` : hasConnectedWallet ? `Done` : `Go`}
          </Text>
        </Button>
      </Flex>

      <Flex
        mt={`4px`}
        w={`full`}
        border={`solid 2px black`}
        opacity={isTransacting && !hasCheckedIn ? 0.5 : 1}
        background={hasCheckedIn ? Colors.DarkGreen : `transparent`}
      >
        <Flex
          flexDirection={`column`}
          gap={`4px`}
          w={`full`}
          borderRight={`1px solid black`}
          padding={`4px 0 4px 6px`}
        >
          <Text
            colorId={hasCheckedIn ? `White` : 'Black'}
            whiteSpace={`initial`}
            textAlign={`left`}
            style={{ textWrap: `balance` }}
          >
            {isTransacting ? (
              <TranslatedText
                translationKey={`confirming`}
                defaultMessage={`confirming`}
              />
            ) : isEvmWalletConnected ? (
              <>
                <TranslatedText
                  translationKey={`mantleDailyCheckIn`}
                  defaultMessage={`OKX/MNT daily check-in`}
                />
                {mantleCheckInCount > 0 && (
                  <span>{` (${mantleCheckInCount})`}</span>
                )}
              </>
            ) : (
              <TranslatedText
                translationKey={`connectOkxWalletToCheckIn`}
                defaultMessage={`connect okx wallet to check in`}
              />
            )}
          </Text>

          <Flex w={`full`}>
            <Energy color={hasCheckedIn ? `White` : 'var(--Black)'} />
            <Text
              colorId={hasCheckedIn ? `White` : 'Black'}
              w="full"
              width={`auto`}
            >
              100
            </Text>
          </Flex>
        </Flex>

        <Button
          borderLeft={`1px solid black`}
          colorId={hasCheckedIn ? `DarkGreen` : `Yellow`}
          padding={`8px`}
          maxWidth={hasCheckedIn ? `80px` : `60px`}
          minWidth={`0px`}
          w={`full`}
          isDisabled={
            isPrivyInitializing || isTransacting || mantleCheckInPending
          }
          onClick={checkIn}
        >
          <Text colorId={hasCheckedIn ? `White` : 'Black'}>
            {isPrivyInitializing ? `...` : hasCheckedIn ? `Done` : `Go`}
          </Text>
        </Button>
      </Flex>
    </>
  );
};

export const OkxWalletConnectedRewardModal = ({
  onClose,
  ticketCount,
}: {
  ticketCount: number;
  onClose: () => void;
}) => {
  return (
    <Modal
      isOpen
      onClose={() => {
        onClose();
      }}
      showBackButton={false}
    >
      <Box
        background={`var(--Yellow)`}
        w="calc(100% + 32px)"
        marginTop={-6}
        marginLeft={-4}
        padding={4}
        position="relative"
      >
        <Box position="absolute" top="-75px" left="-15px">
          <img
            src={WhittakrImg}
            alt="Witakar"
            style={{ height: '120px', width: 'auto' }}
          />
        </Box>
        <Text colorId="Black" w="full" textAlign={'center'}>
          <TranslatedText
            translationKey={`youEarned`}
            defaultMessage={`you earned`}
          />
        </Text>
        <Center alignItems={'center'} w="full" fontSize={`24px`}>
          <Ticket />
          <Text colorId="Black" fontSize={'20px'}>
            {ticketCount}
          </Text>
        </Center>
      </Box>
      <Flex
        mt={1}
        flexDirection={`column`}
        justifyContent={`center`}
        textAlign={`center`}
      >
        <Text colorId={`Black`}>
          <TranslatedText
            translationKey={`okxTask`}
            defaultMessage={`okx Task`}
          />
          :
        </Text>

        <Text colorId={`Black`}>
          <TranslatedText
            translationKey={`connectOkxWallet`}
            defaultMessage={`Connect okx wallet`}
          />
        </Text>
      </Flex>
      <Center w="full" mt={2}>
        <img
          src={getRandomGif()}
          alt="wheel"
          style={{ width: 'auto', height: '240px' }}
        />
      </Center>

      <Button colorId="Black" mt={2} onClick={onClose} w="full">
        <TranslatedText translationKey={`ok`} defaultMessage={`ok`} />
      </Button>
    </Modal>
  );
};

export default MantleCheckInDesktopContainer;
