export const Shop = ({ ...props }) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.6087 5.97864H9.54736V5.03997H8.6087V5.97864Z" fill="black" />
      <path d="M8.6087 6.91736H9.54736V5.97869H8.6087V6.91736Z" fill="black" />
      <path d="M8.6087 7.85065H9.54736V6.91198H8.6087V7.85065Z" fill="black" />
      <path d="M8.6087 8.78931H9.54736V7.85064H8.6087V8.78931Z" fill="black" />
      <path d="M8.6087 9.71735H9.54736V8.77868H8.6087V9.71735Z" fill="black" />
      <path d="M8.6087 10.656H9.54736V9.71734H8.6087V10.656Z" fill="black" />
      <path d="M8.6087 11.5894H9.54736V10.6507H8.6087V11.5894Z" fill="black" />
      <path
        d="M9.54229 5.97864H10.481V5.03997H9.54229V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M9.54229 6.91736H10.481V5.97869H9.54229V6.91736Z"
        fill="#FEFEFE"
      />
      <path
        d="M9.54229 7.85065H10.481V6.91198H9.54229V7.85065Z"
        fill="#FEFEFE"
      />
      <path
        d="M9.54229 8.78931H10.481V7.85064H9.54229V8.78931Z"
        fill="#FEFEFE"
      />
      <path
        d="M9.54229 9.71735H10.481V8.77868H9.54229V9.71735Z"
        fill="#FEFEFE"
      />
      <path d="M9.54229 10.656H10.481V9.71734H9.54229V10.656Z" fill="#FEFEFE" />
      <path
        d="M9.54229 11.5894H10.481V10.6507H9.54229V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M10.4861 5.97864H11.4248V5.03997H10.4861V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M10.4861 6.91736H11.4248V5.97869H10.4861V6.91736Z"
        fill="#F1F0DC"
      />
      <path
        d="M10.4861 7.85065H11.4248V6.91198H10.4861V7.85065Z"
        fill="#F1F0DC"
      />
      <path
        d="M10.4861 8.78931H11.4248V7.85064H10.4861V8.78931Z"
        fill="#F1F0DC"
      />
      <path
        d="M10.4861 9.71735H11.4248V8.77868H10.4861V9.71735Z"
        fill="#F1F0DC"
      />
      <path
        d="M10.4861 10.656H11.4248V9.71734H10.4861V10.656Z"
        fill="#F1F0DC"
      />
      <path
        d="M10.4861 11.5894H11.4248V10.6507H10.4861V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M11.4192 5.97864H12.3579V5.03997H11.4192V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M11.4192 6.91736H12.3579V5.97869H11.4192V6.91736Z"
        fill="#F1F0DC"
      />
      <path
        d="M11.4192 7.85065H12.3579V6.91198H11.4192V7.85065Z"
        fill="#F1F0DC"
      />
      <path
        d="M11.4192 8.78931H12.3579V7.85064H11.4192V8.78931Z"
        fill="#F1F0DC"
      />
      <path
        d="M11.4192 9.71735H12.3579V8.77868H11.4192V9.71735Z"
        fill="#F1F0DC"
      />
      <path
        d="M11.4192 10.656H12.3579V9.71734H11.4192V10.656Z"
        fill="#F1F0DC"
      />
      <path
        d="M11.4192 11.5894H12.3579V10.6507H11.4192V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M12.3528 5.97864H13.2915V5.03997H12.3528V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M12.3528 6.91736H13.2915V5.97869H12.3528V6.91736Z"
        fill="#5162B2"
      />
      <path
        d="M12.3528 7.85065H13.2915V6.91198H12.3528V7.85065Z"
        fill="#5162B2"
      />
      <path
        d="M12.3528 8.78931H13.2915V7.85064H12.3528V8.78931Z"
        fill="#5162B2"
      />
      <path
        d="M12.3528 9.71735H13.2915V8.77868H12.3528V9.71735Z"
        fill="#F1F0DC"
      />
      <path
        d="M12.3528 10.656H13.2915V9.71734H12.3528V10.656Z"
        fill="#5162B2"
      />
      <path
        d="M12.3528 11.5894H13.2915V10.6507H12.3528V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M13.2913 5.97864H14.23V5.03997H13.2913V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M13.2913 6.91736H14.23V5.97869H13.2913V6.91736Z"
        fill="#5162B2"
      />
      <path
        d="M13.2913 7.85065H14.23V6.91198H13.2913V7.85065Z"
        fill="#CDCDA7"
      />
      <path
        d="M13.2913 8.78931H14.23V7.85064H13.2913V8.78931Z"
        fill="#5162B2"
      />
      <path
        d="M13.2913 9.71735H14.23V8.77868H13.2913V9.71735Z"
        fill="#F1F0DC"
      />
      <path d="M13.2913 10.656H14.23V9.71734H13.2913V10.656Z" fill="#5162B2" />
      <path
        d="M13.2913 11.5894H14.23V10.6507H13.2913V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M14.2303 5.97864H15.1689V5.03997H14.2303V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M14.2303 6.91736H15.1689V5.97869H14.2303V6.91736Z"
        fill="#5162B2"
      />
      <path
        d="M14.2303 7.85065H15.1689V6.91198H14.2303V7.85065Z"
        fill="#CDCDA7"
      />
      <path
        d="M14.2303 8.78931H15.1689V7.85064H14.2303V8.78931Z"
        fill="#5162B2"
      />
      <path
        d="M14.2303 9.71735H15.1689V8.77868H14.2303V9.71735Z"
        fill="#5162B2"
      />
      <path
        d="M14.2303 10.656H15.1689V9.71734H14.2303V10.656Z"
        fill="#5162B2"
      />
      <path
        d="M14.2303 11.5894H15.1689V10.6507H14.2303V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M15.1634 5.97864H16.1021V5.03997H15.1634V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M15.1634 6.91736H16.1021V5.97869H15.1634V6.91736Z"
        fill="#CDCDA7"
      />
      <path
        d="M15.1634 7.85065H16.1021V6.91198H15.1634V7.85065Z"
        fill="#F1F0DC"
      />
      <path
        d="M15.1634 8.78931H16.1021V7.85064H15.1634V8.78931Z"
        fill="#CDCDA7"
      />
      <path
        d="M15.1634 9.71735H16.1021V8.77868H15.1634V9.71735Z"
        fill="#CDCDA7"
      />
      <path
        d="M15.1634 10.656H16.1021V9.71734H15.1634V10.656Z"
        fill="#CDCDA7"
      />
      <path
        d="M15.1634 11.5894H16.1021V10.6507H15.1634V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M16.1019 5.97864H17.0405V5.03997H16.1019V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M16.1019 6.91736H17.0405V5.97869H16.1019V6.91736Z"
        fill="#F1F0DC"
      />
      <path
        d="M16.1019 7.85065H17.0405V6.91198H16.1019V7.85065Z"
        fill="#F1F0DC"
      />
      <path
        d="M16.1019 8.78931H17.0405V7.85064H16.1019V8.78931Z"
        fill="#F1F0DC"
      />
      <path
        d="M16.1019 9.71735H17.0405V8.77868H16.1019V9.71735Z"
        fill="#F1F0DC"
      />
      <path
        d="M16.1019 10.656H17.0405V9.71734H16.1019V10.656Z"
        fill="#F1F0DC"
      />
      <path
        d="M16.1019 11.5894H17.0405V10.6507H16.1019V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M17.0408 5.97864H17.9795V5.03997H17.0408V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M17.0408 6.91736H17.9795V5.97869H17.0408V6.91736Z"
        fill="#5162B2"
      />
      <path
        d="M17.0408 7.85065H17.9795V6.91198H17.0408V7.85065Z"
        fill="#5162B2"
      />
      <path
        d="M17.0408 8.78931H17.9795V7.85064H17.0408V8.78931Z"
        fill="#5162B2"
      />
      <path
        d="M17.0408 9.71735H17.9795V8.77868H17.0408V9.71735Z"
        fill="#5162B2"
      />
      <path
        d="M17.0408 10.656H17.9795V9.71734H17.0408V10.656Z"
        fill="#5162B2"
      />
      <path
        d="M17.0408 11.5894H17.9795V10.6507H17.0408V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M17.9739 5.97864H18.9126V5.03997H17.9739V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M17.9739 6.91736H18.9126V5.97869H17.9739V6.91736Z"
        fill="#F1F0DC"
      />
      <path
        d="M17.9739 7.85065H18.9126V6.91198H17.9739V7.85065Z"
        fill="#F1F0DC"
      />
      <path
        d="M17.9739 8.78931H18.9126V7.85064H17.9739V8.78931Z"
        fill="#5162B2"
      />
      <path
        d="M17.9739 9.71735H18.9126V8.77868H17.9739V9.71735Z"
        fill="#CDCDA7"
      />
      <path
        d="M17.9739 10.656H18.9126V9.71734H17.9739V10.656Z"
        fill="#F1F0DC"
      />
      <path
        d="M17.9739 11.5894H18.9126V10.6507H17.9739V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M18.9129 5.97864H19.8516V5.03997H18.9129V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M18.9129 6.91736H19.8516V5.97869H18.9129V6.91736Z"
        fill="#5162B2"
      />
      <path
        d="M18.9129 7.85065H19.8516V6.91198H18.9129V7.85065Z"
        fill="#5162B2"
      />
      <path
        d="M18.9129 8.78931H19.8516V7.85064H18.9129V8.78931Z"
        fill="#5162B2"
      />
      <path
        d="M18.9129 9.71735H19.8516V8.77868H18.9129V9.71735Z"
        fill="#5162B2"
      />
      <path
        d="M18.9129 10.656H19.8516V9.71734H18.9129V10.656Z"
        fill="#5162B2"
      />
      <path
        d="M18.9129 11.5894H19.8516V10.6507H18.9129V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M19.8514 5.97864H20.79V5.03997H19.8514V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M19.8514 6.91736H20.79V5.97869H19.8514V6.91736Z"
        fill="#CDCDA7"
      />
      <path
        d="M19.8514 7.85065H20.79V6.91198H19.8514V7.85065Z"
        fill="#CDCDA7"
      />
      <path
        d="M19.8514 8.78931H20.79V7.85064H19.8514V8.78931Z"
        fill="#CDCDA7"
      />
      <path
        d="M19.8514 9.71735H20.79V8.77868H19.8514V9.71735Z"
        fill="#CDCDA7"
      />
      <path d="M19.8514 10.656H20.79V9.71734H19.8514V10.656Z" fill="#CDCDA7" />
      <path
        d="M19.8514 11.5894H20.79V10.6507H19.8514V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M20.7898 5.97864H21.7285V5.03997H20.7898V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M20.7898 6.91736H21.7285V5.97869H20.7898V6.91736Z"
        fill="#F1F0DC"
      />
      <path
        d="M20.7898 7.85065H21.7285V6.91198H20.7898V7.85065Z"
        fill="#F1F0DC"
      />
      <path
        d="M20.7898 8.78931H21.7285V7.85064H20.7898V8.78931Z"
        fill="#F1F0DC"
      />
      <path
        d="M20.7898 9.71735H21.7285V8.77868H20.7898V9.71735Z"
        fill="#F1F0DC"
      />
      <path
        d="M20.7898 10.656H21.7285V9.71734H20.7898V10.656Z"
        fill="#F1F0DC"
      />
      <path
        d="M20.7898 11.5894H21.7285V10.6507H20.7898V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M21.7234 5.97864H22.6621V5.03997H21.7234V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M21.7234 6.91736H22.6621V5.97869H21.7234V6.91736Z"
        fill="#5162B2"
      />
      <path
        d="M21.7234 7.85065H22.6621V6.91198H21.7234V7.85065Z"
        fill="#5162B2"
      />
      <path
        d="M21.7234 8.78931H22.6621V7.85064H21.7234V8.78931Z"
        fill="#5162B2"
      />
      <path
        d="M21.7234 9.71735H22.6621V8.77868H21.7234V9.71735Z"
        fill="#5162B2"
      />
      <path
        d="M21.7234 10.656H22.6621V9.71734H21.7234V10.656Z"
        fill="#5162B2"
      />
      <path
        d="M21.7234 11.5894H22.6621V10.6507H21.7234V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M22.6565 5.97864H23.5952V5.03997H22.6565V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M22.6565 6.91736H23.5952V5.97869H22.6565V6.91736Z"
        fill="#5162B2"
      />
      <path
        d="M22.6565 7.85065H23.5952V6.91198H22.6565V7.85065Z"
        fill="#F1F0DC"
      />
      <path
        d="M22.6565 8.78931H23.5952V7.85064H22.6565V8.78931Z"
        fill="#F1F0DC"
      />
      <path
        d="M22.6565 9.71735H23.5952V8.77868H22.6565V9.71735Z"
        fill="#F1F0DC"
      />
      <path
        d="M22.6565 10.656H23.5952V9.71734H22.6565V10.656Z"
        fill="#5162B2"
      />
      <path
        d="M22.6565 11.5894H23.5952V10.6507H22.6565V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M23.6009 5.97864H24.5396V5.03997H23.6009V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M23.6009 6.91736H24.5396V5.97869H23.6009V6.91736Z"
        fill="#5162B2"
      />
      <path
        d="M23.6009 7.85065H24.5396V6.91198H23.6009V7.85065Z"
        fill="#5162B2"
      />
      <path
        d="M23.6009 8.78931H24.5396V7.85064H23.6009V8.78931Z"
        fill="#5162B2"
      />
      <path
        d="M23.6009 9.71735H24.5396V8.77868H23.6009V9.71735Z"
        fill="#5162B2"
      />
      <path
        d="M23.6009 10.656H24.5396V9.71734H23.6009V10.656Z"
        fill="#5162B2"
      />
      <path
        d="M23.6009 11.5894H24.5396V10.6507H23.6009V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M24.534 5.97864H25.4727V5.03997H24.534V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M24.534 6.91736H25.4727V5.97869H24.534V6.91736Z"
        fill="#CDCDA7"
      />
      <path
        d="M24.534 7.85065H25.4727V6.91198H24.534V7.85065Z"
        fill="#CDCDA7"
      />
      <path
        d="M24.534 8.78931H25.4727V7.85064H24.534V8.78931Z"
        fill="#CDCDA7"
      />
      <path
        d="M24.534 9.71735H25.4727V8.77868H24.534V9.71735Z"
        fill="#CDCDA7"
      />
      <path d="M24.534 10.656H25.4727V9.71734H24.534V10.656Z" fill="#CDCDA7" />
      <path
        d="M24.534 11.5894H25.4727V10.6507H24.534V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M25.4676 5.97864H26.4062V5.03997H25.4676V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M25.4676 6.91736H26.4062V5.97869H25.4676V6.91736Z"
        fill="#F1F0DC"
      />
      <path
        d="M25.4676 7.85065H26.4062V6.91198H25.4676V7.85065Z"
        fill="#F1F0DC"
      />
      <path
        d="M25.4676 8.78931H26.4062V7.85064H25.4676V8.78931Z"
        fill="#F1F0DC"
      />
      <path
        d="M25.4676 9.71735H26.4062V8.77868H25.4676V9.71735Z"
        fill="#F1F0DC"
      />
      <path
        d="M25.4676 10.656H26.4062V9.71734H25.4676V10.656Z"
        fill="#F1F0DC"
      />
      <path
        d="M25.4676 11.5894H26.4062V10.6507H25.4676V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M26.4114 5.97864H27.3501V5.03997H26.4114V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M26.4114 6.91736H27.3501V5.97869H26.4114V6.91736Z"
        fill="#5162B2"
      />
      <path
        d="M26.4114 7.85065H27.3501V6.91198H26.4114V7.85065Z"
        fill="#5162B2"
      />
      <path
        d="M26.4114 8.78931H27.3501V7.85064H26.4114V8.78931Z"
        fill="#5162B2"
      />
      <path
        d="M26.4114 9.71735H27.3501V8.77868H26.4114V9.71735Z"
        fill="#5162B2"
      />
      <path
        d="M26.4114 10.656H27.3501V9.71734H26.4114V10.656Z"
        fill="#5162B2"
      />
      <path
        d="M26.4114 11.5894H27.3501V10.6507H26.4114V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M27.3445 5.97864H28.2832V5.03997H27.3445V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M27.3445 6.91736H28.2832V5.97869H27.3445V6.91736Z"
        fill="#5162B2"
      />
      <path
        d="M27.3445 7.85065H28.2832V6.91198H27.3445V7.85065Z"
        fill="#F1F0DC"
      />
      <path
        d="M27.3445 8.78931H28.2832V7.85064H27.3445V8.78931Z"
        fill="#5162B2"
      />
      <path
        d="M27.3445 9.71735H28.2832V8.77868H27.3445V9.71735Z"
        fill="#CDCDA7"
      />
      <path
        d="M27.3445 10.656H28.2832V9.71734H27.3445V10.656Z"
        fill="#CDCDA7"
      />
      <path
        d="M27.3445 11.5894H28.2832V10.6507H27.3445V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M28.2781 5.97864H29.2168V5.03997H28.2781V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M28.2781 6.91736H29.2168V5.97869H28.2781V6.91736Z"
        fill="#5162B2"
      />
      <path
        d="M28.2781 7.85065H29.2168V6.91198H28.2781V7.85065Z"
        fill="#5162B2"
      />
      <path
        d="M28.2781 8.78931H29.2168V7.85064H28.2781V8.78931Z"
        fill="#5162B2"
      />
      <path
        d="M28.2781 9.71735H29.2168V8.77868H28.2781V9.71735Z"
        fill="#CDCDA7"
      />
      <path
        d="M28.2781 10.656H29.2168V9.71734H28.2781V10.656Z"
        fill="#F1F0DC"
      />
      <path
        d="M28.2781 11.5894H29.2168V10.6507H28.2781V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M29.2166 5.97864H30.1553V5.03997H29.2166V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M29.2166 6.91736H30.1553V5.97869H29.2166V6.91736Z"
        fill="#CDCDA7"
      />
      <path
        d="M29.2166 7.85065H30.1553V6.91198H29.2166V7.85065Z"
        fill="#CDCDA7"
      />
      <path
        d="M29.2166 8.78931H30.1553V7.85064H29.2166V8.78931Z"
        fill="#CDCDA7"
      />
      <path
        d="M29.2166 9.71735H30.1553V8.77868H29.2166V9.71735Z"
        fill="#F1F0DC"
      />
      <path
        d="M29.2166 10.656H30.1553V9.71734H29.2166V10.656Z"
        fill="#F1F0DC"
      />
      <path
        d="M29.2166 11.5894H30.1553V10.6507H29.2166V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M30.1556 5.97864H31.0942V5.03997H30.1556V5.97864Z"
        fill="#FEFEFE"
      />
      <path
        d="M30.1556 6.91736H31.0942V5.97869H30.1556V6.91736Z"
        fill="#F1F0DC"
      />
      <path
        d="M30.1556 7.85065H31.0942V6.91198H30.1556V7.85065Z"
        fill="#F1F0DC"
      />
      <path
        d="M30.1556 8.78931H31.0942V7.85064H30.1556V8.78931Z"
        fill="#F1F0DC"
      />
      <path
        d="M30.1556 9.71735H31.0942V8.77868H30.1556V9.71735Z"
        fill="#F1F0DC"
      />
      <path
        d="M30.1556 10.656H31.0942V9.71734H30.1556V10.656Z"
        fill="#F1F0DC"
      />
      <path
        d="M30.1556 11.5894H31.0942V10.6507H30.1556V11.5894Z"
        fill="#CDCDA7"
      />
      <path
        d="M31.0887 5.97864H32.0273V5.03997H31.0887V5.97864Z"
        fill="#FEFEFE"
      />
      <path d="M9.54229 5.05066H10.481V4.11199H9.54229V5.05066Z" fill="black" />
      <path
        d="M10.4861 5.05066H11.4248V4.11199H10.4861V5.05066Z"
        fill="black"
      />
      <path
        d="M11.4192 5.05066H12.3579V4.11199H11.4192V5.05066Z"
        fill="black"
      />
      <path
        d="M12.3528 5.05066H13.2915V4.11199H12.3528V5.05066Z"
        fill="black"
      />
      <path d="M13.2913 5.05066H14.23V4.11199H13.2913V5.05066Z" fill="black" />
      <path
        d="M14.2303 5.05066H15.1689V4.11199H14.2303V5.05066Z"
        fill="black"
      />
      <path
        d="M15.1634 5.05066H16.1021V4.11199H15.1634V5.05066Z"
        fill="black"
      />
      <path
        d="M16.1019 5.05066H17.0405V4.11199H16.1019V5.05066Z"
        fill="black"
      />
      <path
        d="M17.0408 5.05066H17.9795V4.11199H17.0408V5.05066Z"
        fill="black"
      />
      <path
        d="M17.9739 5.05066H18.9126V4.11199H17.9739V5.05066Z"
        fill="black"
      />
      <path
        d="M18.9129 5.05066H19.8516V4.11199H18.9129V5.05066Z"
        fill="black"
      />
      <path d="M19.8514 5.05066H20.79V4.11199H19.8514V5.05066Z" fill="black" />
      <path
        d="M20.7898 5.05066H21.7285V4.11199H20.7898V5.05066Z"
        fill="black"
      />
      <path
        d="M21.7234 5.05066H22.6621V4.11199H21.7234V5.05066Z"
        fill="black"
      />
      <path
        d="M22.6565 5.05066H23.5952V4.11199H22.6565V5.05066Z"
        fill="black"
      />
      <path
        d="M23.6009 5.05066H24.5396V4.11199H23.6009V5.05066Z"
        fill="black"
      />
      <path d="M24.534 5.05066H25.4727V4.11199H24.534V5.05066Z" fill="black" />
      <path
        d="M25.4676 5.05066H26.4062V4.11199H25.4676V5.05066Z"
        fill="black"
      />
      <path
        d="M26.4114 5.05066H27.3501V4.11199H26.4114V5.05066Z"
        fill="black"
      />
      <path
        d="M27.3445 5.05066H28.2832V4.11199H27.3445V5.05066Z"
        fill="black"
      />
      <path
        d="M28.2781 5.05066H29.2168V4.11199H28.2781V5.05066Z"
        fill="black"
      />
      <path
        d="M29.2166 5.05066H30.1553V4.11199H29.2166V5.05066Z"
        fill="black"
      />
      <path
        d="M30.1556 5.05066H31.0942V4.11199H30.1556V5.05066Z"
        fill="black"
      />
      <path
        d="M31.0887 5.05066H32.0273V4.11199H31.0887V5.05066Z"
        fill="black"
      />
      <path
        d="M31.0887 6.91736H32.0273V5.97869H31.0887V6.91736Z"
        fill="#F1F0DC"
      />
      <path
        d="M31.0887 7.85065H32.0273V6.91198H31.0887V7.85065Z"
        fill="#F1F0DC"
      />
      <path
        d="M31.0887 8.78931H32.0273V7.85064H31.0887V8.78931Z"
        fill="#F1F0DC"
      />
      <path
        d="M31.0887 9.71735H32.0273V8.77868H31.0887V9.71735Z"
        fill="#F1F0DC"
      />
      <path
        d="M31.0887 10.656H32.0273V9.71734H31.0887V10.656Z"
        fill="#F1F0DC"
      />
      <path
        d="M31.0887 11.5894H32.0273V10.6507H31.0887V11.5894Z"
        fill="#CDCDA7"
      />
      <path d="M9.54229 12.528H10.481V11.5893H9.54229V12.528Z" fill="black" />
      <path d="M10.4861 12.528H11.4248V11.5893H10.4861V12.528Z" fill="black" />
      <path d="M11.4192 12.528H12.3579V11.5893H11.4192V12.528Z" fill="black" />
      <path d="M12.3528 12.528H13.2915V11.5893H12.3528V12.528Z" fill="black" />
      <path d="M13.2913 12.528H14.23V11.5893H13.2913V12.528Z" fill="black" />
      <path d="M14.2303 12.528H15.1689V11.5893H14.2303V12.528Z" fill="black" />
      <path d="M15.1634 12.528H16.1021V11.5893H15.1634V12.528Z" fill="black" />
      <path d="M16.1019 12.528H17.0405V11.5893H16.1019V12.528Z" fill="black" />
      <path d="M17.0408 12.528H17.9795V11.5893H17.0408V12.528Z" fill="black" />
      <path d="M17.9739 12.528H18.9126V11.5893H17.9739V12.528Z" fill="black" />
      <path d="M18.9129 12.528H19.8516V11.5893H18.9129V12.528Z" fill="black" />
      <path d="M19.8514 12.528H20.79V11.5893H19.8514V12.528Z" fill="black" />
      <path d="M20.7898 12.528H21.7285V11.5893H20.7898V12.528Z" fill="black" />
      <path d="M21.7234 12.528H22.6621V11.5893H21.7234V12.528Z" fill="black" />
      <path d="M22.6565 12.528H23.5952V11.5893H22.6565V12.528Z" fill="black" />
      <path d="M23.6009 12.528H24.5396V11.5893H23.6009V12.528Z" fill="black" />
      <path d="M24.534 12.528H25.4727V11.5893H24.534V12.528Z" fill="black" />
      <path d="M25.4676 12.528H26.4062V11.5893H25.4676V12.528Z" fill="black" />
      <path d="M26.4114 12.528H27.3501V11.5893H26.4114V12.528Z" fill="black" />
      <path d="M27.3445 12.528H28.2832V11.5893H27.3445V12.528Z" fill="black" />
      <path d="M28.2781 12.528H29.2168V11.5893H28.2781V12.528Z" fill="black" />
      <path d="M29.2166 12.528H30.1553V11.5893H29.2166V12.528Z" fill="black" />
      <path d="M30.1556 12.528H31.0942V11.5893H30.1556V12.528Z" fill="black" />
      <path d="M31.0779 12.528H32.0166V11.5893H31.0779V12.528Z" fill="black" />
      <path d="M8.71563 13.456H9.6543V12.5173H8.71563V13.456Z" fill="black" />
      <path d="M9.65411 13.456H10.5928V12.5173H9.65411V13.456Z" fill="black" />
      <path d="M10.5872 13.456H11.5259V12.5173H10.5872V13.456Z" fill="black" />
      <path d="M11.5262 13.456H12.4648V12.5173H11.5262V13.456Z" fill="black" />
      <path d="M12.4647 13.456H13.4033V12.5173H12.4647V13.456Z" fill="black" />
      <path d="M4.97686 13.456H5.91553V12.5173H4.97686V13.456Z" fill="black" />
      <path d="M5.91534 13.456H6.854V12.5173H5.91534V13.456Z" fill="black" />
      <path d="M6.84893 13.456H7.7876V12.5173H6.84893V13.456Z" fill="black" />
      <path d="M7.78741 13.456H8.72607V12.5173H7.78741V13.456Z" fill="black" />
      <path d="M13.3982 13.456H14.3369V12.5173H13.3982V13.456Z" fill="black" />
      <path d="M14.3367 13.456H15.2754V12.5173H14.3367V13.456Z" fill="black" />
      <path d="M15.2703 13.456H16.209V12.5173H15.2703V13.456Z" fill="black" />
      <path d="M16.2142 13.456H17.1528V12.5173H16.2142V13.456Z" fill="black" />
      <path d="M17.1473 13.456H18.0859V12.5173H17.1473V13.456Z" fill="black" />
      <path d="M18.0809 13.456H19.0195V12.5173H18.0809V13.456Z" fill="black" />
      <path d="M19.0247 13.456H19.9634V12.5173H19.0247V13.456Z" fill="black" />
      <path d="M19.9583 13.456H20.897V12.5173H19.9583V13.456Z" fill="black" />
      <path d="M20.8914 13.456H21.8301V12.5173H20.8914V13.456Z" fill="black" />
      <path d="M21.8353 13.456H22.7739V12.5173H21.8353V13.456Z" fill="black" />
      <path d="M22.7689 13.456H23.7075V12.5173H22.7689V13.456Z" fill="black" />
      <path d="M23.702 13.456H24.6406V12.5173H23.702V13.456Z" fill="black" />
      <path d="M24.6409 13.456H25.5796V12.5173H24.6409V13.456Z" fill="black" />
      <path d="M25.5794 13.456H26.5181V12.5173H25.5794V13.456Z" fill="black" />
      <path d="M26.513 13.456H27.4517V12.5173H26.513V13.456Z" fill="black" />
      <path d="M27.4515 13.456H28.3901V12.5173H27.4515V13.456Z" fill="black" />
      <path d="M28.3899 13.456H29.3286V12.5173H28.3899V13.456Z" fill="black" />
      <path d="M29.3235 13.456H30.2622V12.5173H29.3235V13.456Z" fill="black" />
      <path d="M30.262 13.456H31.2007V12.5173H30.262V13.456Z" fill="black" />
      <path d="M31.201 13.456H32.1396V12.5173H31.201V13.456Z" fill="black" />
      <path d="M32.1448 13.456H33.0835V12.5173H32.1448V13.456Z" fill="black" />
      <path d="M33.0779 13.456H34.0166V12.5173H33.0779V13.456Z" fill="black" />
      <path d="M34.0115 13.456H34.9502V12.5173H34.0115V13.456Z" fill="black" />
      <path d="M34.0115 13.456H34.9502V12.5173H34.0115V13.456Z" fill="black" />
      <path d="M34.95 13.456H35.8887V12.5173H34.95V13.456Z" fill="black" />
      <path
        d="M8.71563 14.3947H9.6543V13.456H8.71563V14.3947Z"
        fill="#F43740"
      />
      <path
        d="M9.65411 14.3947H10.5928V13.456H9.65411V14.3947Z"
        fill="#F43740"
      />
      <path
        d="M10.5872 14.3947H11.5259V13.456H10.5872V14.3947Z"
        fill="#F43740"
      />
      <path
        d="M11.5262 14.3947H12.4648V13.456H11.5262V14.3947Z"
        fill="#F43740"
      />
      <path
        d="M12.4647 14.3947H13.4033V13.456H12.4647V14.3947Z"
        fill="#F43740"
      />
      <path d="M4.0379 14.3947H4.97656V13.456H4.0379V14.3947Z" fill="black" />
      <path
        d="M4.97686 14.3947H5.91553V13.456H4.97686V14.3947Z"
        fill="#F43740"
      />
      <path d="M5.91534 14.3947H6.854V13.456H5.91534V14.3947Z" fill="white" />
      <path d="M6.84893 14.3947H7.7876V13.456H6.84893V14.3947Z" fill="white" />
      <path d="M7.78741 14.3947H8.72607V13.456H7.78741V14.3947Z" fill="white" />
      <path d="M13.3982 14.3947H14.3369V13.456H13.3982V14.3947Z" fill="white" />
      <path d="M14.3367 14.3947H15.2754V13.456H14.3367V14.3947Z" fill="white" />
      <path d="M15.2703 14.3947H16.209V13.456H15.2703V14.3947Z" fill="white" />
      <path
        d="M16.2142 14.3947H17.1528V13.456H16.2142V14.3947Z"
        fill="#F43740"
      />
      <path
        d="M17.1473 14.3947H18.0859V13.456H17.1473V14.3947Z"
        fill="#F43740"
      />
      <path
        d="M18.0809 14.3947H19.0195V13.456H18.0809V14.3947Z"
        fill="#F43740"
      />
      <path
        d="M19.0247 14.3947H19.9634V13.456H19.0247V14.3947Z"
        fill="#F43740"
      />
      <path
        d="M19.9583 14.3947H20.897V13.456H19.9583V14.3947Z"
        fill="#F43740"
      />
      <path d="M20.8914 14.3947H21.8301V13.456H20.8914V14.3947Z" fill="white" />
      <path d="M21.8353 14.3947H22.7739V13.456H21.8353V14.3947Z" fill="white" />
      <path d="M22.7689 14.3947H23.7075V13.456H22.7689V14.3947Z" fill="white" />
      <path d="M23.702 14.3947H24.6406V13.456H23.702V14.3947Z" fill="#F43740" />
      <path
        d="M24.6409 14.3947H25.5796V13.456H24.6409V14.3947Z"
        fill="#F43740"
      />
      <path
        d="M25.5794 14.3947H26.5181V13.456H25.5794V14.3947Z"
        fill="#F43740"
      />
      <path d="M26.513 14.3947H27.4517V13.456H26.513V14.3947Z" fill="#F43740" />
      <path
        d="M27.4515 14.3947H28.3901V13.456H27.4515V14.3947Z"
        fill="#F43740"
      />
      <path d="M28.3899 14.3947H29.3286V13.456H28.3899V14.3947Z" fill="white" />
      <path d="M29.3235 14.3947H30.2622V13.456H29.3235V14.3947Z" fill="white" />
      <path d="M30.262 14.3947H31.2007V13.456H30.262V14.3947Z" fill="white" />
      <path d="M31.201 14.3947H32.1396V13.456H31.201V14.3947Z" fill="#F43740" />
      <path
        d="M32.1448 14.3947H33.0835V13.456H32.1448V14.3947Z"
        fill="#F43740"
      />
      <path
        d="M33.0779 14.3947H34.0166V13.456H33.0779V14.3947Z"
        fill="#F43740"
      />
      <path
        d="M34.0115 14.3947H34.9502V13.456H34.0115V14.3947Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 14.3947H34.9502V13.456H34.0115V14.3947Z"
        fill="#F43740"
      />
      <path d="M34.95 14.3947H35.8887V13.456H34.95V14.3947Z" fill="#F43740" />
      <path d="M8.71563 15.328H9.6543V14.3893H8.71563V15.328Z" fill="#F21C27" />
      <path
        d="M9.65411 15.328H10.5928V14.3893H9.65411V15.328Z"
        fill="#F21C27"
      />
      <path
        d="M10.5872 15.328H11.5259V14.3893H10.5872V15.328Z"
        fill="#F21C27"
      />
      <path
        d="M11.5262 15.328H12.4648V14.3893H11.5262V15.328Z"
        fill="#F21C27"
      />
      <path
        d="M12.4647 15.328H13.4033V14.3893H12.4647V15.328Z"
        fill="#F21C27"
      />
      <path d="M4.0379 15.328H4.97656V14.3893H4.0379V15.328Z" fill="black" />
      <path
        d="M4.97686 15.328H5.91553V14.3893H4.97686V15.328Z"
        fill="#F21C27"
      />
      <path d="M5.91534 15.328H6.854V14.3893H5.91534V15.328Z" fill="white" />
      <path d="M6.84893 15.328H7.7876V14.3893H6.84893V15.328Z" fill="white" />
      <path d="M7.78741 15.328H8.72607V14.3893H7.78741V15.328Z" fill="white" />
      <path d="M13.3982 15.328H14.3369V14.3893H13.3982V15.328Z" fill="white" />
      <path d="M14.3367 15.328H15.2754V14.3893H14.3367V15.328Z" fill="white" />
      <path d="M15.2703 15.328H16.209V14.3893H15.2703V15.328Z" fill="white" />
      <path
        d="M16.2142 15.328H17.1528V14.3893H16.2142V15.328Z"
        fill="#F21C27"
      />
      <path
        d="M17.1473 15.328H18.0859V14.3893H17.1473V15.328Z"
        fill="#F21C27"
      />
      <path
        d="M18.0809 15.328H19.0195V14.3893H18.0809V15.328Z"
        fill="#F21C27"
      />
      <path
        d="M19.0247 15.328H19.9634V14.3893H19.0247V15.328Z"
        fill="#F21C27"
      />
      <path d="M19.9583 15.328H20.897V14.3893H19.9583V15.328Z" fill="#F21C27" />
      <path d="M20.8914 15.328H21.8301V14.3893H20.8914V15.328Z" fill="white" />
      <path d="M21.8353 15.328H22.7739V14.3893H21.8353V15.328Z" fill="white" />
      <path d="M22.7689 15.328H23.7075V14.3893H22.7689V15.328Z" fill="white" />
      <path d="M23.702 15.328H24.6406V14.3893H23.702V15.328Z" fill="#F21C27" />
      <path
        d="M24.6409 15.328H25.5796V14.3893H24.6409V15.328Z"
        fill="#F21C27"
      />
      <path
        d="M25.5794 15.328H26.5181V14.3893H25.5794V15.328Z"
        fill="#F21C27"
      />
      <path d="M26.513 15.328H27.4517V14.3893H26.513V15.328Z" fill="#F21C27" />
      <path
        d="M27.4515 15.328H28.3901V14.3893H27.4515V15.328Z"
        fill="#F21C27"
      />
      <path d="M28.3899 15.328H29.3286V14.3893H28.3899V15.328Z" fill="white" />
      <path d="M29.3235 15.328H30.2622V14.3893H29.3235V15.328Z" fill="white" />
      <path d="M30.262 15.328H31.2007V14.3893H30.262V15.328Z" fill="white" />
      <path d="M31.201 15.328H32.1396V14.3893H31.201V15.328Z" fill="#F21C27" />
      <path
        d="M32.1448 15.328H33.0835V14.3893H32.1448V15.328Z"
        fill="#F21C27"
      />
      <path
        d="M33.0779 15.328H34.0166V14.3893H33.0779V15.328Z"
        fill="#F21C27"
      />
      <path
        d="M34.0115 15.328H34.9502V14.3893H34.0115V15.328Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 15.328H34.9502V14.3893H34.0115V15.328Z"
        fill="#F21C27"
      />
      <path d="M34.95 15.328H35.8887V14.3893H34.95V15.328Z" fill="#F21C27" />
      <path
        d="M8.71563 16.2667H9.6543V15.328H8.71563V16.2667Z"
        fill="#F21C27"
      />
      <path
        d="M9.65411 16.2667H10.5928V15.328H9.65411V16.2667Z"
        fill="#F21C27"
      />
      <path
        d="M10.5872 16.2667H11.5259V15.328H10.5872V16.2667Z"
        fill="#F21C27"
      />
      <path
        d="M11.5262 16.2667H12.4648V15.328H11.5262V16.2667Z"
        fill="#F21C27"
      />
      <path
        d="M12.4647 16.2667H13.4033V15.328H12.4647V16.2667Z"
        fill="#F21C27"
      />
      <path d="M4.0379 16.2667H4.97656V15.328H4.0379V16.2667Z" fill="black" />
      <path
        d="M4.97686 16.2667H5.91553V15.328H4.97686V16.2667Z"
        fill="#F21C27"
      />
      <path d="M5.91534 16.2667H6.854V15.328H5.91534V16.2667Z" fill="white" />
      <path d="M6.84893 16.2667H7.7876V15.328H6.84893V16.2667Z" fill="white" />
      <path d="M7.78741 16.2667H8.72607V15.328H7.78741V16.2667Z" fill="white" />
      <path d="M13.3982 16.2667H14.3369V15.328H13.3982V16.2667Z" fill="white" />
      <path d="M14.3367 16.2667H15.2754V15.328H14.3367V16.2667Z" fill="white" />
      <path d="M15.2703 16.2667H16.209V15.328H15.2703V16.2667Z" fill="white" />
      <path
        d="M16.2142 16.2667H17.1528V15.328H16.2142V16.2667Z"
        fill="#F21C27"
      />
      <path
        d="M17.1473 16.2667H18.0859V15.328H17.1473V16.2667Z"
        fill="#F21C27"
      />
      <path
        d="M18.0809 16.2667H19.0195V15.328H18.0809V16.2667Z"
        fill="#F21C27"
      />
      <path
        d="M19.0247 16.2667H19.9634V15.328H19.0247V16.2667Z"
        fill="#F21C27"
      />
      <path
        d="M19.9583 16.2667H20.897V15.328H19.9583V16.2667Z"
        fill="#F21C27"
      />
      <path d="M20.8914 16.2667H21.8301V15.328H20.8914V16.2667Z" fill="white" />
      <path d="M21.8353 16.2667H22.7739V15.328H21.8353V16.2667Z" fill="white" />
      <path d="M22.7689 16.2667H23.7075V15.328H22.7689V16.2667Z" fill="white" />
      <path d="M23.702 16.2667H24.6406V15.328H23.702V16.2667Z" fill="#F21C27" />
      <path
        d="M24.6409 16.2667H25.5796V15.328H24.6409V16.2667Z"
        fill="#F21C27"
      />
      <path
        d="M25.5794 16.2667H26.5181V15.328H25.5794V16.2667Z"
        fill="#F21C27"
      />
      <path d="M26.513 16.2667H27.4517V15.328H26.513V16.2667Z" fill="#F21C27" />
      <path
        d="M27.4515 16.2667H28.3901V15.328H27.4515V16.2667Z"
        fill="#F21C27"
      />
      <path d="M28.3899 16.2667H29.3286V15.328H28.3899V16.2667Z" fill="white" />
      <path d="M29.3235 16.2667H30.2622V15.328H29.3235V16.2667Z" fill="white" />
      <path d="M30.262 16.2667H31.2007V15.328H30.262V16.2667Z" fill="white" />
      <path d="M31.201 16.2667H32.1396V15.328H31.201V16.2667Z" fill="#F21C27" />
      <path
        d="M32.1448 16.2667H33.0835V15.328H32.1448V16.2667Z"
        fill="#F21C27"
      />
      <path
        d="M33.0779 16.2667H34.0166V15.328H33.0779V16.2667Z"
        fill="#F21C27"
      />
      <path
        d="M34.0115 16.2667H34.9502V15.328H34.0115V16.2667Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 16.2667H34.9502V15.328H34.0115V16.2667Z"
        fill="#F21C27"
      />
      <path d="M34.95 16.2667H35.8887V15.328H34.95V16.2667Z" fill="#F21C27" />
      <path
        d="M8.71563 17.1947H9.6543V16.256H8.71563V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M9.65411 17.1947H10.5928V16.256H9.65411V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M10.5872 17.1947H11.5259V16.256H10.5872V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M11.5262 17.1947H12.4648V16.256H11.5262V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M12.4647 17.1947H13.4033V16.256H12.4647V17.1947Z"
        fill="#DE0001"
      />
      <path d="M4.0379 17.1947H4.97656V16.256H4.0379V17.1947Z" fill="black" />
      <path
        d="M4.97686 17.1947H5.91553V16.256H4.97686V17.1947Z"
        fill="#A22F3C"
      />
      <path d="M5.91534 17.1947H6.854V16.256H5.91534V17.1947Z" fill="#F4F4F4" />
      <path
        d="M6.84893 17.1947H7.7876V16.256H6.84893V17.1947Z"
        fill="#F4F4F4"
      />
      <path
        d="M7.78741 17.1947H8.72607V16.256H7.78741V17.1947Z"
        fill="#F4F4F4"
      />
      <path
        d="M13.3982 17.1947H14.3369V16.256H13.3982V17.1947Z"
        fill="#F4F4F4"
      />
      <path
        d="M14.3367 17.1947H15.2754V16.256H14.3367V17.1947Z"
        fill="#F4F4F4"
      />
      <path
        d="M15.2703 17.1947H16.209V16.256H15.2703V17.1947Z"
        fill="#F4F4F4"
      />
      <path
        d="M16.2142 17.1947H17.1528V16.256H16.2142V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M17.1473 17.1947H18.0859V16.256H17.1473V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M18.0809 17.1947H19.0195V16.256H18.0809V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M19.0247 17.1947H19.9634V16.256H19.0247V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M19.9583 17.1947H20.897V16.256H19.9583V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M20.8914 17.1947H21.8301V16.256H20.8914V17.1947Z"
        fill="#F4F4F4"
      />
      <path
        d="M21.8353 17.1947H22.7739V16.256H21.8353V17.1947Z"
        fill="#F4F4F4"
      />
      <path
        d="M22.7689 17.1947H23.7075V16.256H22.7689V17.1947Z"
        fill="#F4F4F4"
      />
      <path d="M23.702 17.1947H24.6406V16.256H23.702V17.1947Z" fill="#DE0001" />
      <path
        d="M24.6409 17.1947H25.5796V16.256H24.6409V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M25.5794 17.1947H26.5181V16.256H25.5794V17.1947Z"
        fill="#DE0001"
      />
      <path d="M26.513 17.1947H27.4517V16.256H26.513V17.1947Z" fill="#DE0001" />
      <path
        d="M27.4515 17.1947H28.3901V16.256H27.4515V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M28.3899 17.1947H29.3286V16.256H28.3899V17.1947Z"
        fill="#F4F4F4"
      />
      <path
        d="M29.3235 17.1947H30.2622V16.256H29.3235V17.1947Z"
        fill="#F4F4F4"
      />
      <path d="M30.262 17.1947H31.2007V16.256H30.262V17.1947Z" fill="#F4F4F4" />
      <path d="M31.201 17.1947H32.1396V16.256H31.201V17.1947Z" fill="#DE0001" />
      <path
        d="M32.1448 17.1947H33.0835V16.256H32.1448V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M33.0779 17.1947H34.0166V16.256H33.0779V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 17.1947H34.9502V16.256H34.0115V17.1947Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 17.1947H34.9502V16.256H34.0115V17.1947Z"
        fill="#DE0001"
      />
      <path d="M34.95 17.1947H35.8887V16.256H34.95V17.1947Z" fill="#A22F3C" />
      <path
        d="M8.71563 18.1333H9.6543V17.1947H8.71563V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M9.65411 18.1333H10.5928V17.1947H9.65411V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M10.5872 18.1333H11.5259V17.1947H10.5872V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M11.5262 18.1333H12.4648V17.1947H11.5262V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M12.4647 18.1333H13.4033V17.1947H12.4647V18.1333Z"
        fill="#DE0001"
      />
      <path d="M4.0379 18.1333H4.97656V17.1947H4.0379V18.1333Z" fill="black" />
      <path
        d="M4.97686 18.1333H5.91553V17.1947H4.97686V18.1333Z"
        fill="#A22F3C"
      />
      <path
        d="M5.91534 18.1333H6.854V17.1947H5.91534V18.1333Z"
        fill="#F4F4F4"
      />
      <path
        d="M6.84893 18.1333H7.7876V17.1947H6.84893V18.1333Z"
        fill="#F4F4F4"
      />
      <path
        d="M7.78741 18.1333H8.72607V17.1947H7.78741V18.1333Z"
        fill="#F4F4F4"
      />
      <path
        d="M13.3982 18.1333H14.3369V17.1947H13.3982V18.1333Z"
        fill="#F4F4F4"
      />
      <path
        d="M14.3367 18.1333H15.2754V17.1947H14.3367V18.1333Z"
        fill="#F4F4F4"
      />
      <path
        d="M15.2703 18.1333H16.209V17.1947H15.2703V18.1333Z"
        fill="#F4F4F4"
      />
      <path
        d="M16.2142 18.1333H17.1528V17.1947H16.2142V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M17.1473 18.1333H18.0859V17.1947H17.1473V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M18.0809 18.1333H19.0195V17.1947H18.0809V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M19.0247 18.1333H19.9634V17.1947H19.0247V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M19.9583 18.1333H20.897V17.1947H19.9583V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M20.8914 18.1333H21.8301V17.1947H20.8914V18.1333Z"
        fill="#F4F4F4"
      />
      <path
        d="M21.8353 18.1333H22.7739V17.1947H21.8353V18.1333Z"
        fill="#F4F4F4"
      />
      <path
        d="M22.7689 18.1333H23.7075V17.1947H22.7689V18.1333Z"
        fill="#F4F4F4"
      />
      <path
        d="M23.702 18.1333H24.6406V17.1947H23.702V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M24.6409 18.1333H25.5796V17.1947H24.6409V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M25.5794 18.1333H26.5181V17.1947H25.5794V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M26.513 18.1333H27.4517V17.1947H26.513V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M27.4515 18.1333H28.3901V17.1947H27.4515V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M28.3899 18.1333H29.3286V17.1947H28.3899V18.1333Z"
        fill="#F4F4F4"
      />
      <path
        d="M29.3235 18.1333H30.2622V17.1947H29.3235V18.1333Z"
        fill="#F4F4F4"
      />
      <path
        d="M30.262 18.1333H31.2007V17.1947H30.262V18.1333Z"
        fill="#F4F4F4"
      />
      <path
        d="M31.201 18.1333H32.1396V17.1947H31.201V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M32.1448 18.1333H33.0835V17.1947H32.1448V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M33.0779 18.1333H34.0166V17.1947H33.0779V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 18.1333H34.9502V17.1947H34.0115V18.1333Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 18.1333H34.9502V17.1947H34.0115V18.1333Z"
        fill="#DE0001"
      />
      <path d="M34.95 18.1333H35.8887V17.1947H34.95V18.1333Z" fill="#A22F3C" />
      <path
        d="M8.71563 19.0667H9.6543V18.128H8.71563V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M9.65411 19.0667H10.5928V18.128H9.65411V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M10.5872 19.0667H11.5259V18.128H10.5872V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M11.5262 19.0667H12.4648V18.128H11.5262V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M12.4647 19.0667H13.4033V18.128H12.4647V19.0667Z"
        fill="#DE0001"
      />
      <path d="M4.0379 19.0667H4.97656V18.128H4.0379V19.0667Z" fill="black" />
      <path
        d="M4.97686 19.0667H5.91553V18.128H4.97686V19.0667Z"
        fill="#A22F3C"
      />
      <path d="M5.91534 19.0667H6.854V18.128H5.91534V19.0667Z" fill="#F4F4F4" />
      <path
        d="M6.84893 19.0667H7.7876V18.128H6.84893V19.0667Z"
        fill="#F4F4F4"
      />
      <path
        d="M7.78741 19.0667H8.72607V18.128H7.78741V19.0667Z"
        fill="#F4F4F4"
      />
      <path
        d="M13.3982 19.0667H14.3369V18.128H13.3982V19.0667Z"
        fill="#F4F4F4"
      />
      <path
        d="M14.3367 19.0667H15.2754V18.128H14.3367V19.0667Z"
        fill="#F4F4F4"
      />
      <path
        d="M15.2703 19.0667H16.209V18.128H15.2703V19.0667Z"
        fill="#F4F4F4"
      />
      <path
        d="M16.2142 19.0667H17.1528V18.128H16.2142V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M17.1473 19.0667H18.0859V18.128H17.1473V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M18.0809 19.0667H19.0195V18.128H18.0809V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M19.0247 19.0667H19.9634V18.128H19.0247V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M19.9583 19.0667H20.897V18.128H19.9583V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M20.8914 19.0667H21.8301V18.128H20.8914V19.0667Z"
        fill="#F4F4F4"
      />
      <path
        d="M21.8353 19.0667H22.7739V18.128H21.8353V19.0667Z"
        fill="#F4F4F4"
      />
      <path
        d="M22.7689 19.0667H23.7075V18.128H22.7689V19.0667Z"
        fill="#F4F4F4"
      />
      <path d="M23.702 19.0667H24.6406V18.128H23.702V19.0667Z" fill="#DE0001" />
      <path
        d="M24.6409 19.0667H25.5796V18.128H24.6409V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M25.5794 19.0667H26.5181V18.128H25.5794V19.0667Z"
        fill="#DE0001"
      />
      <path d="M26.513 19.0667H27.4517V18.128H26.513V19.0667Z" fill="#DE0001" />
      <path
        d="M27.4515 19.0667H28.3901V18.128H27.4515V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M28.3899 19.0667H29.3286V18.128H28.3899V19.0667Z"
        fill="#F4F4F4"
      />
      <path
        d="M29.3235 19.0667H30.2622V18.128H29.3235V19.0667Z"
        fill="#F4F4F4"
      />
      <path d="M30.262 19.0667H31.2007V18.128H30.262V19.0667Z" fill="#F4F4F4" />
      <path d="M31.201 19.0667H32.1396V18.128H31.201V19.0667Z" fill="#DE0001" />
      <path
        d="M32.1448 19.0667H33.0835V18.128H32.1448V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M33.0779 19.0667H34.0166V18.128H33.0779V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 19.0667H34.9502V18.128H34.0115V19.0667Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 19.0667H34.9502V18.128H34.0115V19.0667Z"
        fill="#DE0001"
      />
      <path d="M34.95 19.0667H35.8887V18.128H34.95V19.0667Z" fill="#A22F3C" />
      <path
        d="M8.71563 20.0053H9.6543V19.0667H8.71563V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M9.65411 20.0053H10.5928V19.0667H9.65411V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M10.5872 20.0053H11.5259V19.0667H10.5872V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M11.5262 20.0053H12.4648V19.0667H11.5262V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M12.4647 20.0053H13.4033V19.0667H12.4647V20.0053Z"
        fill="#DE0001"
      />
      <path d="M4.0379 20.0053H4.97656V19.0667H4.0379V20.0053Z" fill="black" />
      <path
        d="M4.97686 20.0053H5.91553V19.0667H4.97686V20.0053Z"
        fill="#A22F3C"
      />
      <path
        d="M5.91534 20.0053H6.854V19.0667H5.91534V20.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M6.84893 20.0053H7.7876V19.0667H6.84893V20.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M7.78741 20.0053H8.72607V19.0667H7.78741V20.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M13.3982 20.0053H14.3369V19.0667H13.3982V20.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M14.3367 20.0053H15.2754V19.0667H14.3367V20.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M15.2703 20.0053H16.209V19.0667H15.2703V20.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M16.2142 20.0053H17.1528V19.0667H16.2142V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M17.1473 20.0053H18.0859V19.0667H17.1473V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M18.0809 20.0053H19.0195V19.0667H18.0809V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M19.0247 20.0053H19.9634V19.0667H19.0247V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M19.9583 20.0053H20.897V19.0667H19.9583V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M20.8914 20.0053H21.8301V19.0667H20.8914V20.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M21.8353 20.0053H22.7739V19.0667H21.8353V20.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M22.7689 20.0053H23.7075V19.0667H22.7689V20.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M23.702 20.0053H24.6406V19.0667H23.702V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M24.6409 20.0053H25.5796V19.0667H24.6409V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M25.5794 20.0053H26.5181V19.0667H25.5794V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M26.513 20.0053H27.4517V19.0667H26.513V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M27.4515 20.0053H28.3901V19.0667H27.4515V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M28.3899 20.0053H29.3286V19.0667H28.3899V20.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M29.3235 20.0053H30.2622V19.0667H29.3235V20.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M30.262 20.0053H31.2007V19.0667H30.262V20.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M31.201 20.0053H32.1396V19.0667H31.201V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M32.1448 20.0053H33.0835V19.0667H32.1448V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M33.0779 20.0053H34.0166V19.0667H33.0779V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 20.0053H34.9502V19.0667H34.0115V20.0053Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 20.0053H34.9502V19.0667H34.0115V20.0053Z"
        fill="#DE0001"
      />
      <path d="M34.95 20.0053H35.8887V19.0667H34.95V20.0053Z" fill="#A22F3C" />
      <path
        d="M8.71563 20.9333H9.6543V19.9947H8.71563V20.9333Z"
        fill="#A22F3C"
      />
      <path
        d="M9.65411 20.9333H10.5928V19.9947H9.65411V20.9333Z"
        fill="#DE0001"
      />
      <path
        d="M10.5872 20.9333H11.5259V19.9947H10.5872V20.9333Z"
        fill="#DE0001"
      />
      <path
        d="M11.5262 20.9333H12.4648V19.9947H11.5262V20.9333Z"
        fill="#DE0001"
      />
      <path
        d="M12.4647 20.9333H13.4033V19.9947H12.4647V20.9333Z"
        fill="#A22F3C"
      />
      <path d="M4.0379 20.9333H4.97656V19.9947H4.0379V20.9333Z" fill="black" />
      <path
        d="M4.97686 20.9333H5.91553V19.9947H4.97686V20.9333Z"
        fill="#A22F3C"
      />
      <path
        d="M5.91534 20.9333H6.854V19.9947H5.91534V20.9333Z"
        fill="#F4F4F4"
      />
      <path
        d="M6.84893 20.9333H7.7876V19.9947H6.84893V20.9333Z"
        fill="#F4F4F4"
      />
      <path
        d="M7.78741 20.9333H8.72607V19.9947H7.78741V20.9333Z"
        fill="#F4F4F4"
      />
      <path
        d="M13.3982 20.9333H14.3369V19.9947H13.3982V20.9333Z"
        fill="#F4F4F4"
      />
      <path
        d="M14.3367 20.9333H15.2754V19.9947H14.3367V20.9333Z"
        fill="#F4F4F4"
      />
      <path
        d="M15.2703 20.9333H16.209V19.9947H15.2703V20.9333Z"
        fill="#F4F4F4"
      />
      <path
        d="M16.2142 20.9333H17.1528V19.9947H16.2142V20.9333Z"
        fill="#A22F3C"
      />
      <path
        d="M17.1473 20.9333H18.0859V19.9947H17.1473V20.9333Z"
        fill="#DE0001"
      />
      <path
        d="M18.0809 20.9333H19.0195V19.9947H18.0809V20.9333Z"
        fill="#DE0001"
      />
      <path
        d="M19.0247 20.9333H19.9634V19.9947H19.0247V20.9333Z"
        fill="#DE0001"
      />
      <path
        d="M19.9583 20.9333H20.897V19.9947H19.9583V20.9333Z"
        fill="#A22F3C"
      />
      <path
        d="M20.8914 20.9333H21.8301V19.9947H20.8914V20.9333Z"
        fill="#F4F4F4"
      />
      <path
        d="M21.8353 20.9333H22.7739V19.9947H21.8353V20.9333Z"
        fill="#F4F4F4"
      />
      <path
        d="M22.7689 20.9333H23.7075V19.9947H22.7689V20.9333Z"
        fill="#F4F4F4"
      />
      <path
        d="M23.702 20.9333H24.6406V19.9947H23.702V20.9333Z"
        fill="#A22F3C"
      />
      <path
        d="M24.6409 20.9333H25.5796V19.9947H24.6409V20.9333Z"
        fill="#DE0001"
      />
      <path
        d="M25.5794 20.9333H26.5181V19.9947H25.5794V20.9333Z"
        fill="#DE0001"
      />
      <path
        d="M26.513 20.9333H27.4517V19.9947H26.513V20.9333Z"
        fill="#DE0001"
      />
      <path
        d="M27.4515 20.9333H28.3901V19.9947H27.4515V20.9333Z"
        fill="#A22F3C"
      />
      <path
        d="M28.3899 20.9333H29.3286V19.9947H28.3899V20.9333Z"
        fill="#F4F4F4"
      />
      <path
        d="M29.3235 20.9333H30.2622V19.9947H29.3235V20.9333Z"
        fill="#F4F4F4"
      />
      <path
        d="M30.262 20.9333H31.2007V19.9947H30.262V20.9333Z"
        fill="#F4F4F4"
      />
      <path
        d="M31.201 20.9333H32.1396V19.9947H31.201V20.9333Z"
        fill="#A22F3C"
      />
      <path
        d="M32.1448 20.9333H33.0835V19.9947H32.1448V20.9333Z"
        fill="#DE0001"
      />
      <path
        d="M33.0779 20.9333H34.0166V19.9947H33.0779V20.9333Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 20.9333H34.9502V19.9947H34.0115V20.9333Z"
        fill="#DE0001"
      />
      <path
        d="M34.0115 20.9333H34.9502V19.9947H34.0115V20.9333Z"
        fill="#DE0001"
      />
      <path d="M34.95 20.9333H35.8887V19.9947H34.95V20.9333Z" fill="#A22F3C" />
      <path d="M8.71563 21.872H9.6543V20.9333H8.71563V21.872Z" fill="black" />
      <path
        d="M9.65411 21.872H10.5928V20.9333H9.65411V21.872Z"
        fill="#A22F3C"
      />
      <path
        d="M10.5872 21.872H11.5259V20.9333H10.5872V21.872Z"
        fill="#A22F3C"
      />
      <path
        d="M11.5262 21.872H12.4648V20.9333H11.5262V21.872Z"
        fill="#A22F3C"
      />
      <path d="M12.4647 21.872H13.4033V20.9333H12.4647V21.872Z" fill="black" />
      <path d="M4.0379 21.872H4.97656V20.9333H4.0379V21.872Z" fill="black" />
      <path d="M4.97686 21.872H5.91553V20.9333H4.97686V21.872Z" fill="black" />
      <path d="M5.91534 21.872H6.854V20.9333H5.91534V21.872Z" fill="#E2E2E2" />
      <path d="M6.84893 21.872H7.7876V20.9333H6.84893V21.872Z" fill="#E2E2E2" />
      <path
        d="M7.78741 21.872H8.72607V20.9333H7.78741V21.872Z"
        fill="#E2E2E2"
      />
      <path
        d="M13.3982 21.872H14.3369V20.9333H13.3982V21.872Z"
        fill="#E2E2E2"
      />
      <path
        d="M14.3367 21.872H15.2754V20.9333H14.3367V21.872Z"
        fill="#E2E2E2"
      />
      <path d="M15.2703 21.872H16.209V20.9333H15.2703V21.872Z" fill="#E2E2E2" />
      <path d="M16.2142 21.872H17.1528V20.9333H16.2142V21.872Z" fill="black" />
      <path
        d="M17.1473 21.872H18.0859V20.9333H17.1473V21.872Z"
        fill="#A22F3C"
      />
      <path
        d="M18.0809 21.872H19.0195V20.9333H18.0809V21.872Z"
        fill="#A22F3C"
      />
      <path
        d="M19.0247 21.872H19.9634V20.9333H19.0247V21.872Z"
        fill="#A22F3C"
      />
      <path d="M19.9583 21.872H20.897V20.9333H19.9583V21.872Z" fill="black" />
      <path
        d="M20.8914 21.872H21.8301V20.9333H20.8914V21.872Z"
        fill="#E2E2E2"
      />
      <path
        d="M21.8353 21.872H22.7739V20.9333H21.8353V21.872Z"
        fill="#E2E2E2"
      />
      <path
        d="M22.7689 21.872H23.7075V20.9333H22.7689V21.872Z"
        fill="#E2E2E2"
      />
      <path d="M23.702 21.872H24.6406V20.9333H23.702V21.872Z" fill="black" />
      <path
        d="M24.6409 21.872H25.5796V20.9333H24.6409V21.872Z"
        fill="#A22F3C"
      />
      <path
        d="M25.5794 21.872H26.5181V20.9333H25.5794V21.872Z"
        fill="#A22F3C"
      />
      <path d="M26.513 21.872H27.4517V20.9333H26.513V21.872Z" fill="#A22F3C" />
      <path d="M27.4515 21.872H28.3901V20.9333H27.4515V21.872Z" fill="black" />
      <path
        d="M28.3899 21.872H29.3286V20.9333H28.3899V21.872Z"
        fill="#E2E2E2"
      />
      <path
        d="M29.3235 21.872H30.2622V20.9333H29.3235V21.872Z"
        fill="#E2E2E2"
      />
      <path d="M30.262 21.872H31.2007V20.9333H30.262V21.872Z" fill="#E2E2E2" />
      <path d="M31.201 21.872H32.1396V20.9333H31.201V21.872Z" fill="black" />
      <path
        d="M32.1448 21.872H33.0835V20.9333H32.1448V21.872Z"
        fill="#A22F3C"
      />
      <path
        d="M33.0779 21.872H34.0166V20.9333H33.0779V21.872Z"
        fill="#A22F3C"
      />
      <path
        d="M34.0115 21.872H34.9502V20.9333H34.0115V21.872Z"
        fill="#A22F3C"
      />
      <path
        d="M34.0115 21.872H34.9502V20.9333H34.0115V21.872Z"
        fill="#A22F3C"
      />
      <path d="M34.95 21.872H35.8887V20.9333H34.95V21.872Z" fill="black" />
      <path d="M35.889 14.3947H36.8276V13.456H35.889V14.3947Z" fill="black" />
      <path d="M35.889 15.328H36.8276V14.3893H35.889V15.328Z" fill="black" />
      <path d="M35.889 16.2667H36.8276V15.328H35.889V16.2667Z" fill="black" />
      <path d="M35.889 17.1947H36.8276V16.256H35.889V17.1947Z" fill="black" />
      <path d="M35.889 18.1333H36.8276V17.1947H35.889V18.1333Z" fill="black" />
      <path d="M35.889 19.0667H36.8276V18.128H35.889V19.0667Z" fill="black" />
      <path d="M35.889 20.0053H36.8276V19.0667H35.889V20.0053Z" fill="black" />
      <path d="M35.889 20.9333H36.8276V19.9947H35.889V20.9333Z" fill="black" />
      <path d="M35.889 21.872H36.8276V20.9333H35.889V21.872Z" fill="black" />
      <path
        d="M8.71563 22.8053H9.6543V21.8667H8.71563V22.8053Z"
        fill="#B17F1C"
      />
      <path
        d="M9.65411 22.8053H10.5928V21.8667H9.65411V22.8053Z"
        fill="black"
      />
      <path
        d="M10.5872 22.8053H11.5259V21.8667H10.5872V22.8053Z"
        fill="black"
      />
      <path
        d="M11.5262 22.8053H12.4648V21.8667H11.5262V22.8053Z"
        fill="black"
      />
      <path
        d="M12.4647 22.8053H13.4033V21.8667H12.4647V22.8053Z"
        fill="#B17F1C"
      />
      <path d="M5.91534 22.8053H6.854V21.8667H5.91534V22.8053Z" fill="black" />
      <path d="M6.84893 22.8053H7.7876V21.8667H6.84893V22.8053Z" fill="black" />
      <path
        d="M7.78741 22.8053H8.72607V21.8667H7.78741V22.8053Z"
        fill="black"
      />
      <path
        d="M13.3982 22.8053H14.3369V21.8667H13.3982V22.8053Z"
        fill="black"
      />
      <path
        d="M14.3367 22.8053H15.2754V21.8667H14.3367V22.8053Z"
        fill="black"
      />
      <path d="M15.2703 22.8053H16.209V21.8667H15.2703V22.8053Z" fill="black" />
      <path
        d="M16.2142 22.8053H17.1528V21.8667H16.2142V22.8053Z"
        fill="#B17F1C"
      />
      <path
        d="M17.1473 22.8053H18.0859V21.8667H17.1473V22.8053Z"
        fill="black"
      />
      <path
        d="M18.0809 22.8053H19.0195V21.8667H18.0809V22.8053Z"
        fill="black"
      />
      <path
        d="M19.0247 22.8053H19.9634V21.8667H19.0247V22.8053Z"
        fill="black"
      />
      <path
        d="M19.9583 22.8053H20.897V21.8667H19.9583V22.8053Z"
        fill="#B17F1C"
      />
      <path
        d="M20.8914 22.8053H21.8301V21.8667H20.8914V22.8053Z"
        fill="black"
      />
      <path
        d="M21.8353 22.8053H22.7739V21.8667H21.8353V22.8053Z"
        fill="black"
      />
      <path
        d="M22.7689 22.8053H23.7075V21.8667H22.7689V22.8053Z"
        fill="black"
      />
      <path
        d="M23.702 22.8053H24.6406V21.8667H23.702V22.8053Z"
        fill="#B17F1C"
      />
      <path
        d="M24.6409 22.8053H25.5796V21.8667H24.6409V22.8053Z"
        fill="black"
      />
      <path
        d="M25.5794 22.8053H26.5181V21.8667H25.5794V22.8053Z"
        fill="black"
      />
      <path d="M26.513 22.8053H27.4517V21.8667H26.513V22.8053Z" fill="black" />
      <path
        d="M27.4515 22.8053H28.3901V21.8667H27.4515V22.8053Z"
        fill="#B17F1C"
      />
      <path
        d="M28.3899 22.8053H29.3286V21.8667H28.3899V22.8053Z"
        fill="black"
      />
      <path
        d="M29.3235 22.8053H30.2622V21.8667H29.3235V22.8053Z"
        fill="black"
      />
      <path d="M30.262 22.8053H31.2007V21.8667H30.262V22.8053Z" fill="black" />
      <path
        d="M31.201 22.8053H32.1396V21.8667H31.201V22.8053Z"
        fill="#B17F1C"
      />
      <path
        d="M32.1448 22.8053H33.0835V21.8667H32.1448V22.8053Z"
        fill="black"
      />
      <path
        d="M33.0779 22.8053H34.0166V21.8667H33.0779V22.8053Z"
        fill="black"
      />
      <path
        d="M34.0115 22.8053H34.9502V21.8667H34.0115V22.8053Z"
        fill="black"
      />
      <path d="M34.95 22.8053H35.8887V21.8667H34.95V22.8053Z" fill="black" />
      <path d="M8.71563 23.744H9.6543V22.8053H8.71563V23.744Z" fill="#D5AE72" />
      <path
        d="M9.65411 23.744H10.5928V22.8053H9.65411V23.744Z"
        fill="#B17F1C"
      />
      <path
        d="M10.5872 23.744H11.5259V22.8053H10.5872V23.744Z"
        fill="#B17F1C"
      />
      <path
        d="M11.5262 23.744H12.4648V22.8053H11.5262V23.744Z"
        fill="#B17F1C"
      />
      <path
        d="M12.4647 23.744H13.4033V22.8053H12.4647V23.744Z"
        fill="#D5AE72"
      />
      <path d="M5.91534 23.744H6.854V22.8053H5.91534V23.744Z" fill="black" />
      <path d="M6.84893 23.744H7.7876V22.8053H6.84893V23.744Z" fill="#B17F1C" />
      <path
        d="M7.78741 23.744H8.72607V22.8053H7.78741V23.744Z"
        fill="#B17F1C"
      />
      <path
        d="M13.3982 23.744H14.3369V22.8053H13.3982V23.744Z"
        fill="#B17F1C"
      />
      <path
        d="M14.3367 23.744H15.2754V22.8053H14.3367V23.744Z"
        fill="#B17F1C"
      />
      <path d="M15.2703 23.744H16.209V22.8053H15.2703V23.744Z" fill="#B17F1C" />
      <path
        d="M16.2142 23.744H17.1528V22.8053H16.2142V23.744Z"
        fill="#D5AE72"
      />
      <path
        d="M17.1473 23.744H18.0859V22.8053H17.1473V23.744Z"
        fill="#B17F1C"
      />
      <path
        d="M18.0809 23.744H19.0195V22.8053H18.0809V23.744Z"
        fill="#B17F1C"
      />
      <path
        d="M19.0247 23.744H19.9634V22.8053H19.0247V23.744Z"
        fill="#B17F1C"
      />
      <path d="M19.9583 23.744H20.897V22.8053H19.9583V23.744Z" fill="#D5AE72" />
      <path
        d="M20.8914 23.744H21.8301V22.8053H20.8914V23.744Z"
        fill="#B17F1C"
      />
      <path
        d="M21.8353 23.744H22.7739V22.8053H21.8353V23.744Z"
        fill="#B17F1C"
      />
      <path
        d="M22.7689 23.744H23.7075V22.8053H22.7689V23.744Z"
        fill="#B17F1C"
      />
      <path d="M23.702 23.744H24.6406V22.8053H23.702V23.744Z" fill="#D5AE72" />
      <path
        d="M24.6409 23.744H25.5796V22.8053H24.6409V23.744Z"
        fill="#B17F1C"
      />
      <path
        d="M25.5794 23.744H26.5181V22.8053H25.5794V23.744Z"
        fill="#B17F1C"
      />
      <path d="M26.513 23.744H27.4517V22.8053H26.513V23.744Z" fill="#B17F1C" />
      <path
        d="M27.4515 23.744H28.3901V22.8053H27.4515V23.744Z"
        fill="#D5AE72"
      />
      <path
        d="M28.3899 23.744H29.3286V22.8053H28.3899V23.744Z"
        fill="#B17F1C"
      />
      <path
        d="M29.3235 23.744H30.2622V22.8053H29.3235V23.744Z"
        fill="#B17F1C"
      />
      <path d="M30.262 23.744H31.2007V22.8053H30.262V23.744Z" fill="#B17F1C" />
      <path d="M31.201 23.744H32.1396V22.8053H31.201V23.744Z" fill="#D5AE72" />
      <path
        d="M32.1448 23.744H33.0835V22.8053H32.1448V23.744Z"
        fill="#D5AE72"
      />
      <path
        d="M33.0779 23.744H34.0166V22.8053H33.0779V23.744Z"
        fill="#B17F1C"
      />
      <path d="M34.95 23.744H35.8887V22.8053H34.95V23.744Z" fill="black" />
      <path d="M8.71563 24.672H9.6543V23.7333H8.71563V24.672Z" fill="#D5AE72" />
      <path
        d="M9.65411 24.672H10.5928V23.7333H9.65411V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M10.5872 24.672H11.5259V23.7333H10.5872V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M11.5262 24.672H12.4648V23.7333H11.5262V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M12.4647 24.672H13.4033V23.7333H12.4647V24.672Z"
        fill="#D5AE72"
      />
      <path d="M5.91534 24.672H6.854V23.7333H5.91534V24.672Z" fill="black" />
      <path d="M6.84893 24.672H7.7876V23.7333H6.84893V24.672Z" fill="#D5AE72" />
      <path
        d="M7.78741 24.672H8.72607V23.7333H7.78741V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M13.3982 24.672H14.3369V23.7333H13.3982V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M14.3367 24.672H15.2754V23.7333H14.3367V24.672Z"
        fill="#D5AE72"
      />
      <path d="M15.2703 24.672H16.209V23.7333H15.2703V24.672Z" fill="#D5AE72" />
      <path
        d="M16.2142 24.672H17.1528V23.7333H16.2142V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M17.1473 24.672H18.0859V23.7333H17.1473V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M18.0809 24.672H19.0195V23.7333H18.0809V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M19.0247 24.672H19.9634V23.7333H19.0247V24.672Z"
        fill="#D5AE72"
      />
      <path d="M19.9583 24.672H20.897V23.7333H19.9583V24.672Z" fill="#D5AE72" />
      <path
        d="M20.8914 24.672H21.8301V23.7333H20.8914V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M21.8353 24.672H22.7739V23.7333H21.8353V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M22.7689 24.672H23.7075V23.7333H22.7689V24.672Z"
        fill="#D5AE72"
      />
      <path d="M23.702 24.672H24.6406V23.7333H23.702V24.672Z" fill="#D5AE72" />
      <path
        d="M24.6409 24.672H25.5796V23.7333H24.6409V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M25.5794 24.672H26.5181V23.7333H25.5794V24.672Z"
        fill="#D5AE72"
      />
      <path d="M26.513 24.672H27.4517V23.7333H26.513V24.672Z" fill="#D5AE72" />
      <path
        d="M27.4515 24.672H28.3901V23.7333H27.4515V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M28.3899 24.672H29.3286V23.7333H28.3899V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M29.3235 24.672H30.2622V23.7333H29.3235V24.672Z"
        fill="#D5AE72"
      />
      <path d="M30.262 24.672H31.2007V23.7333H30.262V24.672Z" fill="#D5AE72" />
      <path d="M31.201 24.672H32.1396V23.7333H31.201V24.672Z" fill="#D5AE72" />
      <path
        d="M32.1448 24.672H33.0835V23.7333H32.1448V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M33.0779 24.672H34.0166V23.7333H33.0779V24.672Z"
        fill="#D5AE72"
      />
      <path d="M34.95 24.672H35.8887V23.7333H34.95V24.672Z" fill="black" />
      <path
        d="M8.71563 25.6107H9.6543V24.672H8.71563V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M9.65411 25.6107H10.5928V24.672H9.65411V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M10.5872 25.6107H11.5259V24.672H10.5872V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M11.5262 25.6107H12.4648V24.672H11.5262V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M12.4647 25.6107H13.4033V24.672H12.4647V25.6107Z"
        fill="#FFD686"
      />
      <path d="M5.91534 25.6107H6.854V24.672H5.91534V25.6107Z" fill="black" />
      <path
        d="M6.84893 25.6107H7.7876V24.672H6.84893V25.6107Z"
        fill="#D5AE72"
      />
      <path
        d="M7.78741 25.6107H8.72607V24.672H7.78741V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M13.3982 25.6107H14.3369V24.672H13.3982V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M14.3367 25.6107H15.2754V24.672H14.3367V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M15.2703 25.6107H16.209V24.672H15.2703V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M16.2142 25.6107H17.1528V24.672H16.2142V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M17.1473 25.6107H18.0859V24.672H17.1473V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M18.0809 25.6107H19.0195V24.672H18.0809V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M19.0247 25.6107H19.9634V24.672H19.0247V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M19.9583 25.6107H20.897V24.672H19.9583V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M20.8914 25.6107H21.8301V24.672H20.8914V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M21.8353 25.6107H22.7739V24.672H21.8353V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M22.7689 25.6107H23.7075V24.672H22.7689V25.6107Z"
        fill="#FFD686"
      />
      <path d="M23.702 25.6107H24.6406V24.672H23.702V25.6107Z" fill="#FFD686" />
      <path
        d="M24.6409 25.6107H25.5796V24.672H24.6409V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M25.5794 25.6107H26.5181V24.672H25.5794V25.6107Z"
        fill="#FFD686"
      />
      <path d="M26.513 25.6107H27.4517V24.672H26.513V25.6107Z" fill="#FFD686" />
      <path
        d="M27.4515 25.6107H28.3901V24.672H27.4515V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M28.3899 25.6107H29.3286V24.672H28.3899V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M29.3235 25.6107H30.2622V24.672H29.3235V25.6107Z"
        fill="#FFD686"
      />
      <path d="M30.262 25.6107H31.2007V24.672H30.262V25.6107Z" fill="#FFD686" />
      <path d="M31.201 25.6107H32.1396V24.672H31.201V25.6107Z" fill="#FFD686" />
      <path
        d="M32.1448 25.6107H33.0835V24.672H32.1448V25.6107Z"
        fill="#FFD686"
      />
      <path
        d="M33.0779 25.6107H34.0166V24.672H33.0779V25.6107Z"
        fill="#FFD686"
      />
      <path d="M34.95 25.6107H35.8887V24.672H34.95V25.6107Z" fill="black" />
      <path d="M8.71563 26.544H9.6543V25.6053H8.71563V26.544Z" fill="#FFD686" />
      <path
        d="M9.65411 26.544H10.5928V25.6053H9.65411V26.544Z"
        fill="#FFD686"
      />
      <path
        d="M10.5872 26.544H11.5259V25.6053H10.5872V26.544Z"
        fill="#FFD686"
      />
      <path
        d="M11.5262 26.544H12.4648V25.6053H11.5262V26.544Z"
        fill="#FFD686"
      />
      <path d="M12.4647 26.544H13.4033V25.6053H12.4647V26.544Z" fill="black" />
      <path d="M5.91534 26.544H6.854V25.6053H5.91534V26.544Z" fill="black" />
      <path d="M6.84893 26.544H7.7876V25.6053H6.84893V26.544Z" fill="#D5AE72" />
      <path
        d="M7.78741 26.544H8.72607V25.6053H7.78741V26.544Z"
        fill="#FFD686"
      />
      <path d="M13.3982 26.544H14.3369V25.6053H13.3982V26.544Z" fill="black" />
      <path d="M14.3367 26.544H15.2754V25.6053H14.3367V26.544Z" fill="black" />
      <path d="M15.2703 26.544H16.209V25.6053H15.2703V26.544Z" fill="black" />
      <path d="M16.2142 26.544H17.1528V25.6053H16.2142V26.544Z" fill="black" />
      <path d="M17.1473 26.544H18.0859V25.6053H17.1473V26.544Z" fill="black" />
      <path d="M18.0809 26.544H19.0195V25.6053H18.0809V26.544Z" fill="black" />
      <path
        d="M19.0247 26.544H19.9634V25.6053H19.0247V26.544Z"
        fill="#FFD686"
      />
      <path d="M19.9583 26.544H20.897V25.6053H19.9583V26.544Z" fill="#FFD686" />
      <path
        d="M20.8914 26.544H21.8301V25.6053H20.8914V26.544Z"
        fill="#FFD686"
      />
      <path
        d="M21.8353 26.544H22.7739V25.6053H21.8353V26.544Z"
        fill="#FFD686"
      />
      <path
        d="M22.7689 26.544H23.7075V25.6053H22.7689V26.544Z"
        fill="#FFD686"
      />
      <path d="M23.702 26.544H24.6406V25.6053H23.702V26.544Z" fill="black" />
      <path d="M24.6409 26.544H25.5796V25.6053H24.6409V26.544Z" fill="black" />
      <path d="M25.5794 26.544H26.5181V25.6053H25.5794V26.544Z" fill="black" />
      <path d="M26.513 26.544H27.4517V25.6053H26.513V26.544Z" fill="black" />
      <path d="M27.4515 26.544H28.3901V25.6053H27.4515V26.544Z" fill="black" />
      <path d="M28.3899 26.544H29.3286V25.6053H28.3899V26.544Z" fill="black" />
      <path d="M29.3235 26.544H30.2622V25.6053H29.3235V26.544Z" fill="black" />
      <path d="M30.262 26.544H31.2007V25.6053H30.262V26.544Z" fill="black" />
      <path d="M31.201 26.544H32.1396V25.6053H31.201V26.544Z" fill="#FFD686" />
      <path
        d="M32.1448 26.544H33.0835V25.6053H32.1448V26.544Z"
        fill="#FFD686"
      />
      <path
        d="M33.0779 26.544H34.0166V25.6053H33.0779V26.544Z"
        fill="#FFD686"
      />
      <path d="M34.95 26.544H35.8887V25.6053H34.95V26.544Z" fill="black" />
      <path
        d="M8.71563 27.4827H9.6543V26.544H8.71563V27.4827Z"
        fill="#FFD686"
      />
      <path
        d="M9.65411 27.4827H10.5928V26.544H9.65411V27.4827Z"
        fill="#FFD686"
      />
      <path
        d="M10.5872 27.4827H11.5259V26.544H10.5872V27.4827Z"
        fill="#FFD686"
      />
      <path
        d="M11.5262 27.4827H12.4648V26.544H11.5262V27.4827Z"
        fill="#FFD686"
      />
      <path d="M12.4647 27.4827H13.4033V26.544H12.4647V27.4827Z" fill="black" />
      <path d="M5.91534 27.4827H6.854V26.544H5.91534V27.4827Z" fill="black" />
      <path
        d="M6.84893 27.4827H7.7876V26.544H6.84893V27.4827Z"
        fill="#D5AE72"
      />
      <path
        d="M7.78741 27.4827H8.72607V26.544H7.78741V27.4827Z"
        fill="#FFD686"
      />
      <path
        d="M13.3982 27.4827H14.3369V26.544H13.3982V27.4827Z"
        fill="#5162B2"
      />
      <path
        d="M14.3367 27.4827H15.2754V26.544H14.3367V27.4827Z"
        fill="#5162B2"
      />
      <path
        d="M15.2703 27.4827H16.209V26.544H15.2703V27.4827Z"
        fill="#5162B2"
      />
      <path
        d="M16.2142 27.4827H17.1528V26.544H16.2142V27.4827Z"
        fill="#5162B2"
      />
      <path
        d="M17.1473 27.4827H18.0859V26.544H17.1473V27.4827Z"
        fill="#5162B2"
      />
      <path d="M18.0809 27.4827H19.0195V26.544H18.0809V27.4827Z" fill="black" />
      <path
        d="M19.0247 27.4827H19.9634V26.544H19.0247V27.4827Z"
        fill="#FFD686"
      />
      <path
        d="M19.9583 27.4827H20.897V26.544H19.9583V27.4827Z"
        fill="#FFD686"
      />
      <path
        d="M20.8914 27.4827H21.8301V26.544H20.8914V27.4827Z"
        fill="#FFD686"
      />
      <path
        d="M21.8353 27.4827H22.7739V26.544H21.8353V27.4827Z"
        fill="#FFD686"
      />
      <path d="M22.7689 27.4827H23.7075V26.544H22.7689V27.4827Z" fill="black" />
      <path d="M23.702 27.4827H24.6406V26.544H23.702V27.4827Z" fill="#FFFFFB" />
      <path
        d="M24.6409 27.4827H25.5796V26.544H24.6409V27.4827Z"
        fill="#FFFFFB"
      />
      <path
        d="M25.5794 27.4827H26.5181V26.544H25.5794V27.4827Z"
        fill="#FFFFFB"
      />
      <path d="M26.513 27.4827H27.4517V26.544H26.513V27.4827Z" fill="#FFFFFB" />
      <path
        d="M27.4515 27.4827H28.3901V26.544H27.4515V27.4827Z"
        fill="#FFFFFB"
      />
      <path
        d="M28.3899 27.4827H29.3286V26.544H28.3899V27.4827Z"
        fill="#FFFFFB"
      />
      <path
        d="M29.3235 27.4827H30.2622V26.544H29.3235V27.4827Z"
        fill="#FFFFFB"
      />
      <path d="M30.262 27.4827H31.2007V26.544H30.262V27.4827Z" fill="#FFFFFB" />
      <path d="M31.201 27.4827H32.1396V26.544H31.201V27.4827Z" fill="black" />
      <path
        d="M32.1448 27.4827H33.0835V26.544H32.1448V27.4827Z"
        fill="#FFD686"
      />
      <path
        d="M33.0779 27.4827H34.0166V26.544H33.0779V27.4827Z"
        fill="#FFD686"
      />
      <path d="M34.95 27.4827H35.8887V26.544H34.95V27.4827Z" fill="black" />
      <path
        d="M8.71563 28.4107H9.6543V27.472H8.71563V28.4107Z"
        fill="#FFD686"
      />
      <path
        d="M9.65411 28.4107H10.5928V27.472H9.65411V28.4107Z"
        fill="#FFD686"
      />
      <path
        d="M10.5872 28.4107H11.5259V27.472H10.5872V28.4107Z"
        fill="#FFD686"
      />
      <path
        d="M11.5262 28.4107H12.4648V27.472H11.5262V28.4107Z"
        fill="#FFD686"
      />
      <path d="M12.4647 28.4107H13.4033V27.472H12.4647V28.4107Z" fill="black" />
      <path d="M5.91534 28.4107H6.854V27.472H5.91534V28.4107Z" fill="black" />
      <path
        d="M6.84893 28.4107H7.7876V27.472H6.84893V28.4107Z"
        fill="#D5AE72"
      />
      <path
        d="M7.78741 28.4107H8.72607V27.472H7.78741V28.4107Z"
        fill="#FFD686"
      />
      <path
        d="M13.3982 28.4107H14.3369V27.472H13.3982V28.4107Z"
        fill="#5162B2"
      />
      <path
        d="M14.3367 28.4107H15.2754V27.472H14.3367V28.4107Z"
        fill="#303970"
      />
      <path
        d="M15.2703 28.4107H16.209V27.472H15.2703V28.4107Z"
        fill="#303970"
      />
      <path
        d="M16.2142 28.4107H17.1528V27.472H16.2142V28.4107Z"
        fill="#303970"
      />
      <path
        d="M17.1473 28.4107H18.0859V27.472H17.1473V28.4107Z"
        fill="#5162B2"
      />
      <path d="M18.0809 28.4107H19.0195V27.472H18.0809V28.4107Z" fill="black" />
      <path
        d="M19.0247 28.4107H19.9634V27.472H19.0247V28.4107Z"
        fill="#FFD686"
      />
      <path
        d="M19.9583 28.4107H20.897V27.472H19.9583V28.4107Z"
        fill="#FFD686"
      />
      <path
        d="M20.8914 28.4107H21.8301V27.472H20.8914V28.4107Z"
        fill="#FFD686"
      />
      <path
        d="M21.8353 28.4107H22.7739V27.472H21.8353V28.4107Z"
        fill="#FFD686"
      />
      <path d="M22.7689 28.4107H23.7075V27.472H22.7689V28.4107Z" fill="black" />
      <path d="M23.702 28.4107H24.6406V27.472H23.702V28.4107Z" fill="#B1C0FF" />
      <path
        d="M24.6409 28.4107H25.5796V27.472H24.6409V28.4107Z"
        fill="#B1C0FF"
      />
      <path
        d="M25.5794 28.4107H26.5181V27.472H25.5794V28.4107Z"
        fill="#B1C0FF"
      />
      <path d="M26.513 28.4107H27.4517V27.472H26.513V28.4107Z" fill="#B1C0FF" />
      <path
        d="M27.4515 28.4107H28.3901V27.472H27.4515V28.4107Z"
        fill="#B1C0FF"
      />
      <path
        d="M28.3899 28.4107H29.3286V27.472H28.3899V28.4107Z"
        fill="#B1C0FF"
      />
      <path
        d="M29.3235 28.4107H30.2622V27.472H29.3235V28.4107Z"
        fill="#B1C0FF"
      />
      <path d="M30.262 28.4107H31.2007V27.472H30.262V28.4107Z" fill="#B1C0FF" />
      <path d="M31.201 28.4107H32.1396V27.472H31.201V28.4107Z" fill="black" />
      <path
        d="M32.1448 28.4107H33.0835V27.472H32.1448V28.4107Z"
        fill="#FFD686"
      />
      <path
        d="M33.0779 28.4107H34.0166V27.472H33.0779V28.4107Z"
        fill="#FFD686"
      />
      <path d="M34.95 28.4107H35.8887V27.472H34.95V28.4107Z" fill="black" />
      <path
        d="M8.71563 29.3493H9.6543V28.4107H8.71563V29.3493Z"
        fill="#FFD686"
      />
      <path
        d="M9.65411 29.3493H10.5928V28.4107H9.65411V29.3493Z"
        fill="#FFD686"
      />
      <path
        d="M10.5872 29.3493H11.5259V28.4107H10.5872V29.3493Z"
        fill="#FFD686"
      />
      <path
        d="M11.5262 29.3493H12.4648V28.4107H11.5262V29.3493Z"
        fill="#FFD686"
      />
      <path
        d="M12.4647 29.3493H13.4033V28.4107H12.4647V29.3493Z"
        fill="black"
      />
      <path d="M5.91534 29.3493H6.854V28.4107H5.91534V29.3493Z" fill="black" />
      <path
        d="M6.84893 29.3493H7.7876V28.4107H6.84893V29.3493Z"
        fill="#D5AE72"
      />
      <path
        d="M7.78741 29.3493H8.72607V28.4107H7.78741V29.3493Z"
        fill="#FFD686"
      />
      <path
        d="M13.3982 29.3493H14.3369V28.4107H13.3982V29.3493Z"
        fill="#5162B2"
      />
      <path
        d="M14.3367 29.3493H15.2754V28.4107H14.3367V29.3493Z"
        fill="#303970"
      />
      <path
        d="M15.2703 29.3493H16.209V28.4107H15.2703V29.3493Z"
        fill="#5162B2"
      />
      <path
        d="M16.2142 29.3493H17.1528V28.4107H16.2142V29.3493Z"
        fill="#303970"
      />
      <path
        d="M17.1473 29.3493H18.0859V28.4107H17.1473V29.3493Z"
        fill="#5162B2"
      />
      <path
        d="M18.0809 29.3493H19.0195V28.4107H18.0809V29.3493Z"
        fill="black"
      />
      <path
        d="M19.0247 29.3493H19.9634V28.4107H19.0247V29.3493Z"
        fill="#FFD686"
      />
      <path
        d="M19.9583 29.3493H20.897V28.4107H19.9583V29.3493Z"
        fill="#FFD686"
      />
      <path
        d="M20.8914 29.3493H21.8301V28.4107H20.8914V29.3493Z"
        fill="#FFD686"
      />
      <path
        d="M21.8353 29.3493H22.7739V28.4107H21.8353V29.3493Z"
        fill="#FFD686"
      />
      <path
        d="M22.7689 29.3493H23.7075V28.4107H22.7689V29.3493Z"
        fill="black"
      />
      <path
        d="M23.702 29.3493H24.6406V28.4107H23.702V29.3493Z"
        fill="#303970"
      />
      <path
        d="M24.6409 29.3493H25.5796V28.4107H24.6409V29.3493Z"
        fill="#303970"
      />
      <path
        d="M25.5794 29.3493H26.5181V28.4107H25.5794V29.3493Z"
        fill="#303970"
      />
      <path
        d="M26.513 29.3493H27.4517V28.4107H26.513V29.3493Z"
        fill="#303970"
      />
      <path
        d="M27.4515 29.3493H28.3901V28.4107H27.4515V29.3493Z"
        fill="#303970"
      />
      <path
        d="M28.3899 29.3493H29.3286V28.4107H28.3899V29.3493Z"
        fill="#303970"
      />
      <path
        d="M29.3235 29.3493H30.2622V28.4107H29.3235V29.3493Z"
        fill="#303970"
      />
      <path
        d="M30.262 29.3493H31.2007V28.4107H30.262V29.3493Z"
        fill="#303970"
      />
      <path d="M31.201 29.3493H32.1396V28.4107H31.201V29.3493Z" fill="black" />
      <path
        d="M32.1448 29.3493H33.0835V28.4107H32.1448V29.3493Z"
        fill="#FFD686"
      />
      <path
        d="M33.0779 29.3493H34.0166V28.4107H33.0779V29.3493Z"
        fill="#FFD686"
      />
      <path d="M34.95 29.3493H35.8887V28.4107H34.95V29.3493Z" fill="black" />
      <path
        d="M8.71563 30.2827H9.6543V29.344H8.71563V30.2827Z"
        fill="#FFD686"
      />
      <path
        d="M9.65411 30.2827H10.5928V29.344H9.65411V30.2827Z"
        fill="#FFD686"
      />
      <path
        d="M10.5872 30.2827H11.5259V29.344H10.5872V30.2827Z"
        fill="#FFD686"
      />
      <path
        d="M11.5262 30.2827H12.4648V29.344H11.5262V30.2827Z"
        fill="#FFD686"
      />
      <path d="M12.4647 30.2827H13.4033V29.344H12.4647V30.2827Z" fill="black" />
      <path d="M5.91534 30.2827H6.854V29.344H5.91534V30.2827Z" fill="black" />
      <path
        d="M6.84893 30.2827H7.7876V29.344H6.84893V30.2827Z"
        fill="#D5AE72"
      />
      <path
        d="M7.78741 30.2827H8.72607V29.344H7.78741V30.2827Z"
        fill="#FFD686"
      />
      <path
        d="M13.3982 30.2827H14.3369V29.344H13.3982V30.2827Z"
        fill="#5162B2"
      />
      <path
        d="M14.3367 30.2827H15.2754V29.344H14.3367V30.2827Z"
        fill="#303C6E"
      />
      <path
        d="M15.2703 30.2827H16.209V29.344H15.2703V30.2827Z"
        fill="#5162B2"
      />
      <path
        d="M16.2142 30.2827H17.1528V29.344H16.2142V30.2827Z"
        fill="#303970"
      />
      <path
        d="M17.1473 30.2827H18.0859V29.344H17.1473V30.2827Z"
        fill="#5162B2"
      />
      <path d="M18.0809 30.2827H19.0195V29.344H18.0809V30.2827Z" fill="black" />
      <path
        d="M19.0247 30.2827H19.9634V29.344H19.0247V30.2827Z"
        fill="#FFD686"
      />
      <path
        d="M19.9583 30.2827H20.897V29.344H19.9583V30.2827Z"
        fill="#FFD686"
      />
      <path
        d="M20.8914 30.2827H21.8301V29.344H20.8914V30.2827Z"
        fill="#FFD686"
      />
      <path
        d="M21.8353 30.2827H22.7739V29.344H21.8353V30.2827Z"
        fill="#FFD686"
      />
      <path d="M22.7689 30.2827H23.7075V29.344H22.7689V30.2827Z" fill="black" />
      <path d="M23.702 30.2827H24.6406V29.344H23.702V30.2827Z" fill="black" />
      <path d="M24.6409 30.2827H25.5796V29.344H24.6409V30.2827Z" fill="black" />
      <path d="M25.5794 30.2827H26.5181V29.344H25.5794V30.2827Z" fill="black" />
      <path d="M26.513 30.2827H27.4517V29.344H26.513V30.2827Z" fill="black" />
      <path d="M27.4515 30.2827H28.3901V29.344H27.4515V30.2827Z" fill="black" />
      <path d="M28.3899 30.2827H29.3286V29.344H28.3899V30.2827Z" fill="black" />
      <path d="M29.3235 30.2827H30.2622V29.344H29.3235V30.2827Z" fill="black" />
      <path d="M30.262 30.2827H31.2007V29.344H30.262V30.2827Z" fill="black" />
      <path d="M31.201 30.2827H32.1396V29.344H31.201V30.2827Z" fill="black" />
      <path
        d="M32.1448 30.2827H33.0835V29.344H32.1448V30.2827Z"
        fill="#FFD686"
      />
      <path
        d="M33.0779 30.2827H34.0166V29.344H33.0779V30.2827Z"
        fill="#FFD686"
      />
      <path d="M34.95 30.2827H35.8887V29.344H34.95V30.2827Z" fill="black" />
      <path
        d="M8.71563 31.2213H9.6543V30.2827H8.71563V31.2213Z"
        fill="#FFD686"
      />
      <path
        d="M9.65411 31.2213H10.5928V30.2827H9.65411V31.2213Z"
        fill="#FFD686"
      />
      <path
        d="M10.5872 31.2213H11.5259V30.2827H10.5872V31.2213Z"
        fill="#FFD686"
      />
      <path
        d="M11.5262 31.2213H12.4648V30.2827H11.5262V31.2213Z"
        fill="#FFD686"
      />
      <path
        d="M12.4647 31.2213H13.4033V30.2827H12.4647V31.2213Z"
        fill="black"
      />
      <path d="M5.91534 31.2213H6.854V30.2827H5.91534V31.2213Z" fill="black" />
      <path
        d="M6.84893 31.2213H7.7876V30.2827H6.84893V31.2213Z"
        fill="#D5AE72"
      />
      <path
        d="M7.78741 31.2213H8.72607V30.2827H7.78741V31.2213Z"
        fill="#FFD686"
      />
      <path
        d="M13.3982 31.2213H14.3369V30.2827H13.3982V31.2213Z"
        fill="#5162B2"
      />
      <path
        d="M14.3367 31.2213H15.2754V30.2827H14.3367V31.2213Z"
        fill="#1A2242"
      />
      <path
        d="M15.2703 31.2213H16.209V30.2827H15.2703V31.2213Z"
        fill="#5162B2"
      />
      <path
        d="M16.2142 31.2213H17.1528V30.2827H16.2142V31.2213Z"
        fill="#303970"
      />
      <path
        d="M17.1473 31.2213H18.0859V30.2827H17.1473V31.2213Z"
        fill="#5162B2"
      />
      <path
        d="M18.0809 31.2213H19.0195V30.2827H18.0809V31.2213Z"
        fill="black"
      />
      <path
        d="M19.0247 31.2213H19.9634V30.2827H19.0247V31.2213Z"
        fill="#FFD686"
      />
      <path
        d="M19.9583 31.2213H20.897V30.2827H19.9583V31.2213Z"
        fill="#FFD686"
      />
      <path
        d="M20.8914 31.2213H21.8301V30.2827H20.8914V31.2213Z"
        fill="#FFD686"
      />
      <path
        d="M21.8353 31.2213H22.7739V30.2827H21.8353V31.2213Z"
        fill="black"
      />
      <path
        d="M22.7689 31.2213H23.7075V30.2827H22.7689V31.2213Z"
        fill="#D5AE72"
      />
      <path
        d="M23.702 31.2213H24.6406V30.2827H23.702V31.2213Z"
        fill="#D5AE72"
      />
      <path
        d="M24.6409 31.2213H25.5796V30.2827H24.6409V31.2213Z"
        fill="#D5AE72"
      />
      <path
        d="M25.5794 31.2213H26.5181V30.2827H25.5794V31.2213Z"
        fill="#D5AE72"
      />
      <path
        d="M26.513 31.2213H27.4517V30.2827H26.513V31.2213Z"
        fill="#D5AE72"
      />
      <path
        d="M27.4515 31.2213H28.3901V30.2827H27.4515V31.2213Z"
        fill="#D5AE72"
      />
      <path
        d="M28.3899 31.2213H29.3286V30.2827H28.3899V31.2213Z"
        fill="#D5AE72"
      />
      <path
        d="M29.3235 31.2213H30.2622V30.2827H29.3235V31.2213Z"
        fill="#D5AE72"
      />
      <path
        d="M30.262 31.2213H31.2007V30.2827H30.262V31.2213Z"
        fill="#D5AE72"
      />
      <path
        d="M31.201 31.2213H32.1396V30.2827H31.201V31.2213Z"
        fill="#D5AE72"
      />
      <path
        d="M32.1448 31.2213H33.0835V30.2827H32.1448V31.2213Z"
        fill="black"
      />
      <path
        d="M33.0779 31.2213H34.0166V30.2827H33.0779V31.2213Z"
        fill="#FFD686"
      />
      <path d="M34.95 31.2213H35.8887V30.2827H34.95V31.2213Z" fill="black" />
      <path
        d="M8.71563 32.1493H9.6543V31.2107H8.71563V32.1493Z"
        fill="#FFD686"
      />
      <path
        d="M9.65411 32.1493H10.5928V31.2107H9.65411V32.1493Z"
        fill="#FFD686"
      />
      <path
        d="M10.5872 32.1493H11.5259V31.2107H10.5872V32.1493Z"
        fill="#FFD686"
      />
      <path
        d="M11.5262 32.1493H12.4648V31.2107H11.5262V32.1493Z"
        fill="#FFD686"
      />
      <path
        d="M12.4647 32.1493H13.4033V31.2107H12.4647V32.1493Z"
        fill="black"
      />
      <path d="M5.91534 32.1493H6.854V31.2107H5.91534V32.1493Z" fill="black" />
      <path
        d="M6.84893 32.1493H7.7876V31.2107H6.84893V32.1493Z"
        fill="#D5AE72"
      />
      <path
        d="M7.78741 32.1493H8.72607V31.2107H7.78741V32.1493Z"
        fill="#FFD686"
      />
      <path
        d="M13.3982 32.1493H14.3369V31.2107H13.3982V32.1493Z"
        fill="#5162B2"
      />
      <path
        d="M14.3367 32.1493H15.2754V31.2107H14.3367V32.1493Z"
        fill="#303970"
      />
      <path
        d="M15.2703 32.1493H16.209V31.2107H15.2703V32.1493Z"
        fill="#5162B2"
      />
      <path
        d="M16.2142 32.1493H17.1528V31.2107H16.2142V32.1493Z"
        fill="#303970"
      />
      <path
        d="M17.1473 32.1493H18.0859V31.2107H17.1473V32.1493Z"
        fill="#5162B2"
      />
      <path
        d="M18.0809 32.1493H19.0195V31.2107H18.0809V32.1493Z"
        fill="black"
      />
      <path
        d="M19.0247 32.1493H19.9634V31.2107H19.0247V32.1493Z"
        fill="#FFD686"
      />
      <path
        d="M19.9583 32.1493H20.897V31.2107H19.9583V32.1493Z"
        fill="#FFD686"
      />
      <path
        d="M20.8914 32.1493H21.8301V31.2107H20.8914V32.1493Z"
        fill="#FFD686"
      />
      <path
        d="M21.8353 32.1493H22.7739V31.2107H21.8353V32.1493Z"
        fill="#FFD686"
      />
      <path
        d="M22.7689 32.1493H23.7075V31.2107H22.7689V32.1493Z"
        fill="black"
      />
      <path d="M23.702 32.1493H24.6406V31.2107H23.702V32.1493Z" fill="black" />
      <path
        d="M24.6409 32.1493H25.5796V31.2107H24.6409V32.1493Z"
        fill="black"
      />
      <path
        d="M25.5794 32.1493H26.5181V31.2107H25.5794V32.1493Z"
        fill="black"
      />
      <path d="M26.513 32.1493H27.4517V31.2107H26.513V32.1493Z" fill="black" />
      <path
        d="M27.4515 32.1493H28.3901V31.2107H27.4515V32.1493Z"
        fill="black"
      />
      <path
        d="M28.3899 32.1493H29.3286V31.2107H28.3899V32.1493Z"
        fill="black"
      />
      <path
        d="M29.3235 32.1493H30.2622V31.2107H29.3235V32.1493Z"
        fill="black"
      />
      <path d="M30.262 32.1493H31.2007V31.2107H30.262V32.1493Z" fill="black" />
      <path d="M31.201 32.1493H32.1396V31.2107H31.201V32.1493Z" fill="black" />
      <path
        d="M32.1448 32.1493H33.0835V31.2107H32.1448V32.1493Z"
        fill="#FFD686"
      />
      <path
        d="M33.0779 32.1493H34.0166V31.2107H33.0779V32.1493Z"
        fill="#FFD686"
      />
      <path d="M34.95 32.1493H35.8887V31.2107H34.95V32.1493Z" fill="black" />
      <path d="M8.71563 33.088H9.6543V32.1493H8.71563V33.088Z" fill="#D5AE72" />
      <path
        d="M9.65411 33.088H10.5928V32.1493H9.65411V33.088Z"
        fill="#D5AE72"
      />
      <path
        d="M10.5872 33.088H11.5259V32.1493H10.5872V33.088Z"
        fill="#D5AE72"
      />
      <path
        d="M11.5262 33.088H12.4648V32.1493H11.5262V33.088Z"
        fill="#D5AE72"
      />
      <path d="M12.4647 33.088H13.4033V32.1493H12.4647V33.088Z" fill="black" />
      <path d="M5.91534 33.088H6.854V32.1493H5.91534V33.088Z" fill="black" />
      <path d="M6.84893 33.088H7.7876V32.1493H6.84893V33.088Z" fill="#D5AE72" />
      <path
        d="M7.78741 33.088H8.72607V32.1493H7.78741V33.088Z"
        fill="#D5AE72"
      />
      <path
        d="M13.3982 33.088H14.3369V32.1493H13.3982V33.088Z"
        fill="#303970"
      />
      <path
        d="M14.3367 33.088H15.2754V32.1493H14.3367V33.088Z"
        fill="#303970"
      />
      <path d="M15.2703 33.088H16.209V32.1493H15.2703V33.088Z" fill="#303970" />
      <path
        d="M16.2142 33.088H17.1528V32.1493H16.2142V33.088Z"
        fill="#303970"
      />
      <path
        d="M17.1473 33.088H18.0859V32.1493H17.1473V33.088Z"
        fill="#303970"
      />
      <path d="M18.0809 33.088H19.0195V32.1493H18.0809V33.088Z" fill="black" />
      <path
        d="M19.0247 33.088H19.9634V32.1493H19.0247V33.088Z"
        fill="#D5AE72"
      />
      <path d="M19.9583 33.088H20.897V32.1493H19.9583V33.088Z" fill="#D5AE72" />
      <path
        d="M20.8914 33.088H21.8301V32.1493H20.8914V33.088Z"
        fill="#D5AE72"
      />
      <path
        d="M21.8353 33.088H22.7739V32.1493H21.8353V33.088Z"
        fill="#D5AE72"
      />
      <path
        d="M22.7689 33.088H23.7075V32.1493H22.7689V33.088Z"
        fill="#D5AE72"
      />
      <path d="M23.702 33.088H24.6406V32.1493H23.702V33.088Z" fill="#D5AE72" />
      <path
        d="M24.6409 33.088H25.5796V32.1493H24.6409V33.088Z"
        fill="#D5AE72"
      />
      <path
        d="M25.5794 33.088H26.5181V32.1493H25.5794V33.088Z"
        fill="#D5AE72"
      />
      <path d="M26.513 33.088H27.4517V32.1493H26.513V33.088Z" fill="#D5AE72" />
      <path
        d="M27.4515 33.088H28.3901V32.1493H27.4515V33.088Z"
        fill="#D5AE72"
      />
      <path
        d="M28.3899 33.088H29.3286V32.1493H28.3899V33.088Z"
        fill="#D5AE72"
      />
      <path
        d="M29.3235 33.088H30.2622V32.1493H29.3235V33.088Z"
        fill="#D5AE72"
      />
      <path d="M30.262 33.088H31.2007V32.1493H30.262V33.088Z" fill="#D5AE72" />
      <path d="M31.201 33.088H32.1396V32.1493H31.201V33.088Z" fill="#D5AE72" />
      <path
        d="M32.1448 33.088H33.0835V32.1493H32.1448V33.088Z"
        fill="#D5AE72"
      />
      <path
        d="M33.0779 33.088H34.0166V32.1493H33.0779V33.088Z"
        fill="#D5AE72"
      />
      <path d="M34.95 33.088H35.8887V32.1493H34.95V33.088Z" fill="black" />
      <path d="M8.71563 34.0213H9.6543V33.0827H8.71563V34.0213Z" fill="black" />
      <path
        d="M9.65411 34.0213H10.5928V33.0827H9.65411V34.0213Z"
        fill="black"
      />
      <path
        d="M10.5872 34.0213H11.5259V33.0827H10.5872V34.0213Z"
        fill="black"
      />
      <path
        d="M11.5262 34.0213H12.4648V33.0827H11.5262V34.0213Z"
        fill="black"
      />
      <path
        d="M12.4647 34.0213H13.4033V33.0827H12.4647V34.0213Z"
        fill="black"
      />
      <path d="M5.91534 34.0213H6.854V33.0827H5.91534V34.0213Z" fill="black" />
      <path d="M6.84893 34.0213H7.7876V33.0827H6.84893V34.0213Z" fill="black" />
      <path
        d="M7.78741 34.0213H8.72607V33.0827H7.78741V34.0213Z"
        fill="black"
      />
      <path
        d="M13.3982 34.0213H14.3369V33.0827H13.3982V34.0213Z"
        fill="black"
      />
      <path
        d="M14.3367 34.0213H15.2754V33.0827H14.3367V34.0213Z"
        fill="black"
      />
      <path d="M15.2703 34.0213H16.209V33.0827H15.2703V34.0213Z" fill="black" />
      <path
        d="M16.2142 34.0213H17.1528V33.0827H16.2142V34.0213Z"
        fill="black"
      />
      <path
        d="M17.1473 34.0213H18.0859V33.0827H17.1473V34.0213Z"
        fill="black"
      />
      <path
        d="M18.0809 34.0213H19.0195V33.0827H18.0809V34.0213Z"
        fill="black"
      />
      <path
        d="M19.0247 34.0213H19.9634V33.0827H19.0247V34.0213Z"
        fill="black"
      />
      <path d="M19.9583 34.0213H20.897V33.0827H19.9583V34.0213Z" fill="black" />
      <path
        d="M20.8914 34.0213H21.8301V33.0827H20.8914V34.0213Z"
        fill="black"
      />
      <path
        d="M21.8353 34.0213H22.7739V33.0827H21.8353V34.0213Z"
        fill="black"
      />
      <path
        d="M22.7689 34.0213H23.7075V33.0827H22.7689V34.0213Z"
        fill="black"
      />
      <path d="M23.702 34.0213H24.6406V33.0827H23.702V34.0213Z" fill="black" />
      <path
        d="M24.6409 34.0213H25.5796V33.0827H24.6409V34.0213Z"
        fill="black"
      />
      <path
        d="M25.5794 34.0213H26.5181V33.0827H25.5794V34.0213Z"
        fill="black"
      />
      <path d="M26.513 34.0213H27.4517V33.0827H26.513V34.0213Z" fill="black" />
      <path
        d="M27.4515 34.0213H28.3901V33.0827H27.4515V34.0213Z"
        fill="black"
      />
      <path
        d="M28.3899 34.0213H29.3286V33.0827H28.3899V34.0213Z"
        fill="black"
      />
      <path
        d="M29.3235 34.0213H30.2622V33.0827H29.3235V34.0213Z"
        fill="black"
      />
      <path d="M30.262 34.0213H31.2007V33.0827H30.262V34.0213Z" fill="black" />
      <path d="M31.201 34.0213H32.1396V33.0827H31.201V34.0213Z" fill="black" />
      <path
        d="M32.1448 34.0213H33.0835V33.0827H32.1448V34.0213Z"
        fill="black"
      />
      <path
        d="M33.0779 34.0213H34.0166V33.0827H33.0779V34.0213Z"
        fill="black"
      />
      <path d="M34.95 34.0213H35.8887V33.0827H34.95V34.0213Z" fill="black" />
      <path d="M8.71563 34.96H9.6543V34.0213H8.71563V34.96Z" fill="#A22F3C" />
      <path d="M9.65411 34.96H10.5928V34.0213H9.65411V34.96Z" fill="#A22F3C" />
      <path d="M10.5872 34.96H11.5259V34.0213H10.5872V34.96Z" fill="#A22F3C" />
      <path d="M11.5262 34.96H12.4648V34.0213H11.5262V34.96Z" fill="#A22F3C" />
      <path d="M12.4647 34.96H13.4033V34.0213H12.4647V34.96Z" fill="#A22F3C" />
      <path d="M5.91534 34.96H6.854V34.0213H5.91534V34.96Z" fill="#A22F3C" />
      <path d="M4.97686 34.96H5.91553V34.0213H4.97686V34.96Z" fill="black" />
      <path d="M6.84893 34.96H7.7876V34.0213H6.84893V34.96Z" fill="#A22F3C" />
      <path d="M7.78741 34.96H8.72607V34.0213H7.78741V34.96Z" fill="#A22F3C" />
      <path d="M13.3982 34.96H14.3369V34.0213H13.3982V34.96Z" fill="#A22F3C" />
      <path d="M14.3367 34.96H15.2754V34.0213H14.3367V34.96Z" fill="#A22F3C" />
      <path d="M15.2703 34.96H16.209V34.0213H15.2703V34.96Z" fill="#A22F3C" />
      <path d="M16.2142 34.96H17.1528V34.0213H16.2142V34.96Z" fill="#A22F3C" />
      <path d="M17.1473 34.96H18.0859V34.0213H17.1473V34.96Z" fill="#A22F3C" />
      <path d="M18.0809 34.96H19.0195V34.0213H18.0809V34.96Z" fill="#A22F3C" />
      <path d="M19.0247 34.96H19.9634V34.0213H19.0247V34.96Z" fill="#A22F3C" />
      <path d="M19.9583 34.96H20.897V34.0213H19.9583V34.96Z" fill="#A22F3C" />
      <path d="M20.8914 34.96H21.8301V34.0213H20.8914V34.96Z" fill="#A22F3C" />
      <path d="M21.8353 34.96H22.7739V34.0213H21.8353V34.96Z" fill="#A22F3C" />
      <path d="M22.7689 34.96H23.7075V34.0213H22.7689V34.96Z" fill="#A22F3C" />
      <path d="M23.702 34.96H24.6406V34.0213H23.702V34.96Z" fill="#A22F3C" />
      <path d="M24.6409 34.96H25.5796V34.0213H24.6409V34.96Z" fill="#A22F3C" />
      <path d="M25.5794 34.96H26.5181V34.0213H25.5794V34.96Z" fill="#A22F3C" />
      <path d="M26.513 34.96H27.4517V34.0213H26.513V34.96Z" fill="#A22F3C" />
      <path d="M27.4515 34.96H28.3901V34.0213H27.4515V34.96Z" fill="#A22F3C" />
      <path d="M28.3899 34.96H29.3286V34.0213H28.3899V34.96Z" fill="#A22F3C" />
      <path d="M29.3235 34.96H30.2622V34.0213H29.3235V34.96Z" fill="#A22F3C" />
      <path d="M30.262 34.96H31.2007V34.0213H30.262V34.96Z" fill="#A22F3C" />
      <path d="M31.201 34.96H32.1396V34.0213H31.201V34.96Z" fill="#A22F3C" />
      <path d="M32.1448 34.96H33.0835V34.0213H32.1448V34.96Z" fill="#A22F3C" />
      <path d="M33.0779 34.96H34.0166V34.0213H33.0779V34.96Z" fill="#A22F3C" />
      <path d="M34.95 34.96H35.8887V34.0213H34.95V34.96Z" fill="#A22F3C" />
      <path d="M35.889 34.96H36.8276V34.0213H35.889V34.96Z" fill="black" />
      <path d="M8.71563 35.888H9.6543V34.9493H8.71563V35.888Z" fill="black" />
      <path d="M9.65411 35.888H10.5928V34.9493H9.65411V35.888Z" fill="black" />
      <path d="M10.5872 35.888H11.5259V34.9493H10.5872V35.888Z" fill="black" />
      <path d="M11.5262 35.888H12.4648V34.9493H11.5262V35.888Z" fill="black" />
      <path d="M12.4647 35.888H13.4033V34.9493H12.4647V35.888Z" fill="black" />
      <path d="M4.0379 35.888H4.97656V34.9493H4.0379V35.888Z" fill="black" />
      <path d="M4.97686 35.888H5.91553V34.9493H4.97686V35.888Z" fill="black" />
      <path d="M5.91534 35.888H6.854V34.9493H5.91534V35.888Z" fill="black" />
      <path d="M6.84893 35.888H7.7876V34.9493H6.84893V35.888Z" fill="black" />
      <path d="M7.78741 35.888H8.72607V34.9493H7.78741V35.888Z" fill="black" />
      <path d="M13.3982 35.888H14.3369V34.9493H13.3982V35.888Z" fill="black" />
      <path d="M14.3367 35.888H15.2754V34.9493H14.3367V35.888Z" fill="black" />
      <path d="M15.2703 35.888H16.209V34.9493H15.2703V35.888Z" fill="black" />
      <path d="M16.2142 35.888H17.1528V34.9493H16.2142V35.888Z" fill="black" />
      <path d="M17.1473 35.888H18.0859V34.9493H17.1473V35.888Z" fill="black" />
      <path d="M18.0809 35.888H19.0195V34.9493H18.0809V35.888Z" fill="black" />
      <path d="M19.0247 35.888H19.9634V34.9493H19.0247V35.888Z" fill="black" />
      <path d="M19.9583 35.888H20.897V34.9493H19.9583V35.888Z" fill="black" />
      <path d="M20.8914 35.888H21.8301V34.9493H20.8914V35.888Z" fill="black" />
      <path d="M21.8353 35.888H22.7739V34.9493H21.8353V35.888Z" fill="black" />
      <path d="M22.7689 35.888H23.7075V34.9493H22.7689V35.888Z" fill="black" />
      <path d="M23.702 35.888H24.6406V34.9493H23.702V35.888Z" fill="black" />
      <path d="M24.6409 35.888H25.5796V34.9493H24.6409V35.888Z" fill="black" />
      <path d="M25.5794 35.888H26.5181V34.9493H25.5794V35.888Z" fill="black" />
      <path d="M26.513 35.888H27.4517V34.9493H26.513V35.888Z" fill="black" />
      <path d="M27.4515 35.888H28.3901V34.9493H27.4515V35.888Z" fill="black" />
      <path d="M28.3899 35.888H29.3286V34.9493H28.3899V35.888Z" fill="black" />
      <path d="M29.3235 35.888H30.2622V34.9493H29.3235V35.888Z" fill="black" />
      <path d="M30.262 35.888H31.2007V34.9493H30.262V35.888Z" fill="black" />
      <path d="M31.201 35.888H32.1396V34.9493H31.201V35.888Z" fill="black" />
      <path d="M32.1448 35.888H33.0835V34.9493H32.1448V35.888Z" fill="black" />
      <path d="M33.0779 35.888H34.0166V34.9493H33.0779V35.888Z" fill="black" />
      <path
        d="M34.0115 23.744H34.9502V22.8053H34.0115V23.744Z"
        fill="#B17F1C"
      />
      <path
        d="M34.0115 24.672H34.9502V23.7333H34.0115V24.672Z"
        fill="#D5AE72"
      />
      <path
        d="M34.0115 25.6107H34.9502V24.672H34.0115V25.6107Z"
        fill="#D5AE72"
      />
      <path
        d="M34.0115 26.544H34.9502V25.6053H34.0115V26.544Z"
        fill="#D5AE72"
      />
      <path
        d="M34.0115 27.4827H34.9502V26.544H34.0115V27.4827Z"
        fill="#D5AE72"
      />
      <path
        d="M34.0115 28.4107H34.9502V27.472H34.0115V28.4107Z"
        fill="#D5AE72"
      />
      <path
        d="M34.0115 29.3493H34.9502V28.4107H34.0115V29.3493Z"
        fill="#D5AE72"
      />
      <path
        d="M34.0115 30.2827H34.9502V29.344H34.0115V30.2827Z"
        fill="#D5AE72"
      />
      <path
        d="M34.0115 31.2213H34.9502V30.2827H34.0115V31.2213Z"
        fill="#D5AE72"
      />
      <path
        d="M34.0115 32.1493H34.9502V31.2107H34.0115V32.1493Z"
        fill="#D5AE72"
      />
      <path
        d="M34.0115 33.088H34.9502V32.1493H34.0115V33.088Z"
        fill="#D5AE72"
      />
      <path
        d="M34.0115 34.0213H34.9502V33.0827H34.0115V34.0213Z"
        fill="black"
      />
      <path d="M34.0115 34.96H34.9502V34.0213H34.0115V34.96Z" fill="#A22F3C" />
      <path d="M34.0115 35.888H34.9502V34.9493H34.0115V35.888Z" fill="black" />
      <path d="M34.95 35.888H35.8887V34.9493H34.95V35.888Z" fill="black" />
      <path d="M35.889 35.888H36.8276V34.9493H35.889V35.888Z" fill="black" />
      <path d="M36.8221 35.888H37.7607V34.9493H36.8221V35.888Z" fill="black" />
      <path
        d="M32.0325 5.97864H32.9712V5.03997H32.0325V5.97864Z"
        fill="black"
      />
      <path
        d="M32.0325 6.91736H32.9712V5.97869H32.0325V6.91736Z"
        fill="black"
      />
      <path
        d="M32.0325 7.85065H32.9712V6.91198H32.0325V7.85065Z"
        fill="black"
      />
      <path
        d="M32.0325 8.78931H32.9712V7.85064H32.0325V8.78931Z"
        fill="black"
      />
      <path
        d="M32.0325 9.71735H32.9712V8.77868H32.0325V9.71735Z"
        fill="black"
      />
      <path d="M32.0325 10.656H32.9712V9.71734H32.0325V10.656Z" fill="black" />
      <path
        d="M32.0325 11.5894H32.9712V10.6507H32.0325V11.5894Z"
        fill="black"
      />
    </svg>
  );
};
