import { Box, Flex } from '@chakra-ui/react';
import { Link } from '@/components/Link/Link';
import { Button } from '@/components/ui/molecules/Button';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import {
  VITE_PRIVY_WALLET_ENABLED,
  VITE_FEED_TO_EARN_ENABLED,
} from '@/config/constants';
import { Text } from '@/components/ui/atoms/Text';
import MantleCheckInDesktopContainer from '@/components/earn/MantleCheckInDesktop';
import { Energy } from '@/components/ui/icons/Energy';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '@/context/FirestoreContext';
import { Colors } from '@/components/ui/colors';
import MantleCheckInMobile from '@/components/earn/MantleCheckInMobile';
import { isMobile } from '@/utils/isMobile';

const BonusContainer = () => {
  if (!VITE_PRIVY_WALLET_ENABLED) return null;

  return (
    <Flex
      background={`var(--White)`}
      flexDirection={'column'}
      w={`full`}
      borderLeft={`solid 2px black`}
      borderRight={`solid 2px black`}
      h="full"
    >
      <Box w="full" h="full" position={'relative'} padding={`8px 8px 4px 8px`}>
        <Text colorId={`Black`}>
          <TranslatedText
            translationKey={`okxTasks`}
            defaultMessage={`OKX Tasks`}
          />
        </Text>

        <Flex flexDirection={`column`} alignItems={`center`}>
          {isMobile() ? (
            <MantleCheckInMobile />
          ) : (
            <MantleCheckInDesktopContainer />
          )}
        </Flex>

        {VITE_FEED_TO_EARN_ENABLED && (
          <Box mt={`16px`}>
            <Text colorId={`Black`}>
              <TranslatedText
                translationKey={`megaBonus`}
                defaultMessage={`mega bonus`}
              />
            </Text>

            <FeedGenopetDailyButton />
          </Box>
        )}

        <Box mt={`16px`}>
          <Link to="/home" style={{ width: '100%' }}>
            <Button colorId="Black" w="full">
              <TranslatedText
                translationKey={`goHome`}
                defaultMessage={`Go Home`}
              />
            </Button>
          </Link>
        </Box>
      </Box>
    </Flex>
  );
};

const FeedGenopetDailyButton = () => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  const feedNextDayTimestampMs =
    userData?.telegram?.feedPetNextDayTimestampMs ?? 0;
  const hasPerformedFeedToday = feedNextDayTimestampMs > Date.now();

  return (
    <Flex
      w={`full`}
      border={`solid 2px black`}
      background={hasPerformedFeedToday ? Colors.DarkGreen : `transparent`}
    >
      <Flex
        flexDirection={`column`}
        w={`full`}
        borderRight={`1px solid black`}
        padding={`4px 0 4px 6px`}
        gap={`4px`}
      >
        <Text
          colorId={hasPerformedFeedToday ? `White` : 'Black'}
          whiteSpace={`initial`}
          textAlign={`left`}
        >
          Feed genopet daily
        </Text>

        <Flex w={`full`} gap={`4px`}>
          <Energy
            color={hasPerformedFeedToday ? `var(--White)` : `var(--Black)`}
          />
          <Text
            colorId={hasPerformedFeedToday ? `White` : 'Black'}
            w="full"
            width={`auto`}
          >
            500
          </Text>
        </Flex>
      </Flex>

      <Button
        borderLeft={`1px solid black`}
        colorId={hasPerformedFeedToday ? `DarkGreen` : `Yellow`}
        maxWidth={hasPerformedFeedToday ? `80px` : `60px`}
        padding={`8px`}
        minWidth={`0px`}
        w={`full`}
        onClick={() => {
          if (hasPerformedFeedToday) return;
          navigate('feed-to-earn');
        }}
      >
        <Text colorId={hasPerformedFeedToday ? `White` : 'Black'}>
          {hasPerformedFeedToday ? `Done` : `Go`}
        </Text>
      </Button>
    </Flex>
  );
};

export default BonusContainer;
