import { Whittakr } from '@/components/ui/organisms/Whittakr';
import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Box, Flex } from '@chakra-ui/react';
import { ticketBundles } from 'genopets-utils';
import { Ticket } from '@/components/ui/icons/Ticket';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import useAPI from '@/hooks/useAPI';
import { useAlert } from '@/hooks/useAlert';
import { initInvoice } from '@tma.js/sdk-react';
import { PURCHASE_TICKETS_ENDPOINT } from '@/config/endpoints';
import { TICKET_NOT_PAID_PREFIX } from '@/components/battle/ClaimDailyTicketsModal';
import { Button } from '@/components/ui/molecules/Button';
import { useNavigate } from 'react-router-dom';
import TelegramStar from '@/images/telegram-star.png';

const Shop = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { apiPost } = useAPI();
  const alert = useAlert();

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        // there's no event when telegram IAP modal is closed ie when user cancelled the IAP modal
        // so adding this to enable the buttons after few seconds
        setIsLoading(false);
      }, 5000);
    }
  }, [isLoading]);

  const buyTickets = async (qty = 1) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const invoice = initInvoice();
      const result = await apiPost(PURCHASE_TICKETS_ENDPOINT, { qty });
      const status = await invoice.open(result?.invoiceUrl, 'url');

      if (status !== 'paid') {
        alert({
          title: t(TICKET_NOT_PAID_PREFIX + status, 'cancelled'),
        });
      } else {
        alert({
          title: (
            <Flex alignItems={`center`} gap={`4px`}>
              <Ticket />
              <Text colorId={`Black`} mt={`1px`}>
                <TranslatedText
                  translationKey={`ticketsClaimed`}
                  defaultMessage={`${qty} claimed`}
                  props={{ amount: qty }}
                />
                !
              </Text>
            </Flex>
          ),
        });
      }
    } catch (error: any) {
      console.error('Error purchasing tickets:', error);
      alert({ title: error?.message ?? 'Error purchasing tickets' });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Whittakr
        imgStyle={{ left: `-90px`, top: `50px` }}
        textBoxProps={{ top: '20px', left: '140px', padding: 2 }}
      >
        <Text w="full">
          <TranslatedText
            translationKey={`moarTkts`}
            defaultMessage={`moar tkts `}
          />
          {` >`}
        </Text>
        <Text w="full">
          <TranslatedText
            translationKey={`moarEnergy`}
            defaultMessage={`moar energy`}
          />
          {` >`}
        </Text>
        <Text w="full">
          <TranslatedText
            translationKey={`moarAirdrop`}
            defaultMessage={`moar airdrop`}
          />
          !
        </Text>
      </Whittakr>
      <Box
        overflow={'auto'}
        background={`#FFF`}
        padding={2}
        position={`absolute`}
        bottom={0}
        w={`full`}
      >
        <Box
          maxHeight={`300px`}
          overflowY={`auto`}
          display={'flex'}
          flexDirection={'column'}
          gap={2}
          paddingBottom={2}
        >
          {ticketBundles
            .map((t) => ({ ...t, mostPopular: t.tickets === 27 }))
            .sort((a, b) => {
              if (a.mostPopular && !b.mostPopular) return -1;
              if (!a.mostPopular && b.mostPopular) return 1;
              if (a.tickets < b.tickets) return -1;
              if (a.tickets > b.tickets) return 1;
              return 0;
            })
            .map(({ tickets, stars, mostPopular }) => {
              return (
                <Flex
                  key={tickets}
                  padding={2}
                  border={`${mostPopular ? 5 : 3}px solid var(--Black)`}
                  mb={mostPopular ? 1 : 0}
                  justifyContent={'space-between'}
                  onClick={() => buyTickets(tickets)}
                  cursor={'pointer'}
                  opacity={isLoading ? 0.5 : 1}
                  pointerEvents={isLoading ? 'none' : 'auto'}
                >
                  <Flex flexDirection={'column'}>
                    <Flex justifyContent={'flex-start'} alignItems={'center'}>
                      <Text colorId="Black">
                        <TranslatedText
                          translationKey={'buy'}
                          defaultMessage={'buy'}
                        />
                      </Text>
                      <Ticket />
                      <Text colorId="Black" w="full" width={`auto`}>
                        {tickets}
                      </Text>
                    </Flex>
                    {mostPopular ? (
                      <Text
                        colorId="Green"
                        w="full"
                        fontSize={'10px'}
                        lineHeight={'20px'}
                      >
                        <TranslatedText
                          translationKey={'mostPopular'}
                          defaultMessage={'most popular'}
                        />
                      </Text>
                    ) : null}
                  </Flex>
                  <Flex alignItems={'center'} gap={1}>
                    <img
                      src={TelegramStar}
                      alt={'TelegramStar'}
                      style={{ width: 22, height: 22 }}
                    />
                    <Text colorId="Black" width={`auto`}>
                      {stars}
                    </Text>
                  </Flex>
                </Flex>
              );
            })}
        </Box>
        <Button colorId="Black" onClick={() => navigate('home')} w="full">
          <TranslatedText
            translationKey={`goHome`}
            defaultMessage={`Go home`}
          />
        </Button>
      </Box>
    </>
  );
};

export default Shop;
