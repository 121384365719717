import { useState } from 'react';
import { TasksContainer } from '@/components/earn/TasksContainer';
import { ReferralShare } from '@/components/earn/ReferralShare';
import { Flex } from '@chakra-ui/react';
import { Button } from '@/components/ui/molecules/Button';
import { Text } from '@/components/ui/atoms/Text';
import BonusContainer from '@/components/earn/BonusContainer';
import { VITE_PRIVY_WALLET_ENABLED } from '@/config/constants';

const Earn = () => {
  const [view, setView] = useState<'bonus' | 'basic' | 'invite'>(
    VITE_PRIVY_WALLET_ENABLED ? 'bonus' : 'basic',
  );

  return (
    <Flex
      position={`absolute`}
      bottom={0}
      w={`full`}
      h={view === 'basic' ? 'calc(100% - 130px)' : 'auto'}
      maxH="calc(100% - 130px)"
      flexDirection={'column'}
    >
      <Flex justifyContent={`center`}>
        {VITE_PRIVY_WALLET_ENABLED && (
          <Button
            colorId={view === `bonus` ? `Yellow` : `White`}
            border={`solid 2px black`}
            onClick={() => setView('bonus')}
            padding={`8px`}
            minWidth={`0px`}
            width={`full`}
          >
            <Text colorId={`Black`}>Bonus</Text>
          </Button>
        )}

        <Button
          colorId={view === `basic` ? `Yellow` : `White`}
          border={`solid 2px black`}
          onClick={() => setView('basic')}
          padding={`8px`}
          minWidth={`0px`}
          width={`full`}
        >
          <Text colorId={`Black`}>Basic</Text>
        </Button>
        <Button
          colorId={view === `invite` ? `Yellow` : `White`}
          border={`solid 2px black`}
          onClick={() => setView('invite')}
          padding={`8px`}
          minWidth={`0px`}
          width={`full`}
        >
          <Text colorId={`Black`}>Invite</Text>
        </Button>
      </Flex>

      {view === 'bonus' ? (
        <BonusContainer />
      ) : view === 'basic' ? (
        <TasksContainer />
      ) : (
        <ReferralShare />
      )}
    </Flex>
  );
};

export default Earn;
