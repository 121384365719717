import { Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Energy } from '../ui/icons/Energy';
import { FeedToEarnMode } from './FeedToEarn';
import { Text } from '@/components/ui/atoms/Text';
import { Button } from '../ui/molecules/Button';
import {
  GAME_SETTINGS_TELEGRAM_PET_FEED_ENERGY_AMOUNT,
  useGameSettings,
} from '@/components/battle/hooks/useGameSettings';

export const SuccessView = ({
  setMode,
  setValidatedImage,
}: {
  setMode: React.Dispatch<React.SetStateAction<FeedToEarnMode>>;
  setValidatedImage: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const settings = useGameSettings();
  const navigate = useNavigate();
  return (
    <>
      <Center color="var(--Green)" w="full">
        <Text as="span" colorId="Green">
          <TranslatedText translationKey="youEarn" defaultMessage="You Earn" />
        </Text>
        <Energy />
        <Text as="span" colorId="Green">
          {settings
            ? settings?.[GAME_SETTINGS_TELEGRAM_PET_FEED_ENERGY_AMOUNT] ?? 500
            : '...'}
        </Text>
      </Center>
      <Text colorId="Black">
        <TranslatedText
          translationKey="genopetFed"
          defaultMessage="genopet fed"
        />
        !
      </Text>
      <Button
        w="full"
        colorId="Black"
        onClick={() => {
          navigate('home');
        }}
      >
        <TranslatedText translationKey="goHome" defaultMessage="Go home" />
      </Button>
    </>
  );
};
