import { FeedToEarn } from '@/components/feedToEarn/FeedToEarn';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { VITE_FEED_TO_EARN_ENABLED } from '@/config/constants';
import { Center } from '@chakra-ui/react';
import { Text } from '@/components/ui/atoms/Text';

export const FeedToEarnPage = () => {
  if (VITE_FEED_TO_EARN_ENABLED) {
    return <FeedToEarn />;
  }
  return (
    <Center w="full" h="full">
      <Text>
        <TranslatedText
          translationKey="comingSoon"
          defaultMessage="coming soon"
        />
      </Text>
    </Center>
  );
};
