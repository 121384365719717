import { EIP1193Provider } from '@privy-io/react-auth';
import { OKXUniversalProvider } from '@okxconnect/universal-provider';
import { MANTLE_MAINNET_CHAIN_ID } from '@/config/constants';

export const getAndWaitForTransactionConfirmedReceipt = async (
  provider: EIP1193Provider | OKXUniversalProvider,
  txid: string,
) => {
  let receipt = null;
  console.log(`Waiting for transaction ${txid} to be mined...`);

  while (receipt === null) {
    if (provider instanceof OKXUniversalProvider) {
      receipt = await provider.request(
        {
          method: 'eth_getTransactionByHash',
          params: [txid],
        },
        `eip155:${MANTLE_MAINNET_CHAIN_ID}`,
      );
    } else {
      receipt = await provider.request({
        method: 'eth_getTransactionReceipt',
        params: [txid],
      });
    }

    if (receipt && receipt.blockNumber) {
      console.log(
        `Transaction ${txid} confirmed in block ${receipt.blockNumber}`,
      );
      return receipt;
    } else {
      // Add 1 sec delay then check again
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
};
