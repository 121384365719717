export const HomeEarnTickets = ({ ...props }) => {
  return (
    <svg
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M61.2387 29.6281L59.8245 28.2139L58.4102 29.6281L59.8245 31.0423L61.2387 29.6281Z"
        fill="#FE4F99"
      />
      <path
        d="M62.6488 31.0275L61.2346 29.6133L59.8204 31.0275L61.2346 32.4417L62.6488 31.0275Z"
        fill="black"
      />
      <path
        d="M59.8288 28.2238L58.4146 26.8096L57.0003 28.2238L58.4146 29.638L59.8288 28.2238Z"
        fill="#FE4F99"
      />
      <path
        d="M58.4189 26.8097L57.0046 25.3955L55.5904 26.8097L57.0046 28.2239L58.4189 26.8097Z"
        fill="#FE4F99"
      />
      <path
        d="M57.0087 25.4054L55.5945 23.9912L54.1803 25.4054L55.5945 26.8196L57.0087 25.4054Z"
        fill="#FE4F99"
      />
      <path
        d="M55.5988 23.9914L54.1846 22.5771L52.7704 23.9914L54.1846 25.4056L55.5988 23.9914Z"
        fill="#FE4F99"
      />
      <path
        d="M54.1818 22.5705L52.7676 21.1562L51.3534 22.5705L52.7676 23.9847L54.1818 22.5705Z"
        fill="#FE4F99"
      />
      <path
        d="M52.7787 21.1593L51.3645 19.7451L49.9503 21.1593L51.3645 22.5735L52.7787 21.1593Z"
        fill="#FE4F99"
      />
      <path
        d="M51.3688 19.7453L49.9546 18.3311L48.5404 19.7453L49.9546 21.1595L51.3688 19.7453Z"
        fill="#FF8DCC"
      />
      <path
        d="M49.9586 18.341L48.5444 16.9268L47.1302 18.341L48.5444 19.7552L49.9586 18.341Z"
        fill="#FF8DCC"
      />
      <path
        d="M48.5487 16.9269L47.1345 15.5127L45.7203 16.9269L47.1345 18.3411L48.5487 16.9269Z"
        fill="#FF8DCC"
      />
      <path
        d="M47.1388 15.5236L45.7246 14.1094L44.3104 15.5236L45.7246 16.9378L47.1388 15.5236Z"
        fill="#FF8DCC"
      />
      <path
        d="M45.7287 14.1095L44.3145 12.6953L42.9002 14.1095L44.3145 15.5237L45.7287 14.1095Z"
        fill="#FF8DCC"
      />
      <path
        d="M44.3188 12.6955L42.9045 11.2812L41.4903 12.6955L42.9045 14.1097L44.3188 12.6955Z"
        fill="#FF8DCC"
      />
      <path
        d="M42.9088 11.2912L41.4946 9.87695L40.0804 11.2912L41.4946 12.7054L42.9088 11.2912Z"
        fill="black"
      />
      <path
        d="M62.6498 28.2111L61.2356 26.7969L59.8214 28.2111L61.2356 29.6253L62.6498 28.2111Z"
        fill="black"
      />
      <path
        d="M61.2399 26.8068L59.8257 25.3926L58.4115 26.8068L59.8257 28.221L61.2399 26.8068Z"
        fill="black"
      />
      <path
        d="M59.8297 25.3927L58.4155 23.9785L57.0013 25.3927L58.4155 26.8069L59.8297 25.3927Z"
        fill="black"
      />
      <path
        d="M58.4198 23.9787L57.0056 22.5645L55.5914 23.9787L57.0056 25.3929L58.4198 23.9787Z"
        fill="black"
      />
      <path
        d="M57.0099 22.5744L55.5957 21.1602L54.1815 22.5744L55.5957 23.9886L57.0099 22.5744Z"
        fill="black"
      />
      <path
        d="M55.5998 21.1603L54.1855 19.7461L52.7713 21.1603L54.1855 22.5745L55.5998 21.1603Z"
        fill="black"
      />
      <path
        d="M54.1828 19.7492L52.7686 18.335L51.3543 19.7492L52.7686 21.1634L54.1828 19.7492Z"
        fill="black"
      />
      <path
        d="M52.7729 18.3351L51.3586 16.9209L49.9444 18.3351L51.3586 19.7493L52.7729 18.3351Z"
        fill="black"
      />
      <path
        d="M51.3627 16.921L49.9485 15.5068L48.5343 16.921L49.9485 18.3353L51.3627 16.921Z"
        fill="black"
      />
      <path
        d="M49.9599 15.5099L48.5457 14.0957L47.1314 15.5099L48.5457 16.9241L49.9599 15.5099Z"
        fill="black"
      />
      <path
        d="M48.55 14.0959L47.1357 12.6816L45.7215 14.0959L47.1357 15.5101L48.55 14.0959Z"
        fill="black"
      />
      <path
        d="M47.1398 12.6925L45.7256 11.2783L44.3114 12.6925L45.7256 14.1067L47.1398 12.6925Z"
        fill="black"
      />
      <path
        d="M45.7299 11.2785L44.3157 9.86426L42.9015 11.2785L44.3157 12.6927L45.7299 11.2785Z"
        fill="black"
      />
      <path
        d="M59.8317 31.0304L58.4175 29.6162L57.0033 31.0304L58.4175 32.4446L59.8317 31.0304Z"
        fill="#FE4F99"
      />
      <path
        d="M58.4218 29.6164L57.0076 28.2021L55.5934 29.6164L57.0076 31.0306L58.4218 29.6164Z"
        fill="#F41740"
      />
      <path
        d="M57.0119 28.213L55.5977 26.7988L54.1834 28.213L55.5977 29.6273L57.0119 28.213Z"
        fill="#F41740"
      />
      <path
        d="M55.6017 26.799L54.1875 25.3848L52.7733 26.799L54.1875 28.2132L55.6017 26.799Z"
        fill="#F41740"
      />
      <path
        d="M54.1918 25.3849L52.7776 23.9707L51.3634 25.3849L52.7776 26.7991L54.1918 25.3849Z"
        fill="#F41740"
      />
      <path
        d="M52.7819 23.9806L51.3677 22.5664L49.9535 23.9806L51.3677 25.3948L52.7819 23.9806Z"
        fill="#F41740"
      />
      <path
        d="M51.3717 22.5666L49.9575 21.1523L48.5433 22.5666L49.9575 23.9808L51.3717 22.5666Z"
        fill="#F41740"
      />
      <path
        d="M49.9618 21.1623L48.5476 19.748L47.1334 21.1623L48.5476 22.5765L49.9618 21.1623Z"
        fill="#F7345E"
      />
      <path
        d="M48.5517 19.7482L47.1375 18.334L45.7232 19.7482L47.1375 21.1624L48.5517 19.7482Z"
        fill="#F7345E"
      />
      <path
        d="M47.1418 18.3449L45.7275 16.9307L44.3133 18.3449L45.7275 19.7591L47.1418 18.3449Z"
        fill="#F7345E"
      />
      <path
        d="M45.7318 16.9308L44.3176 15.5166L42.9034 16.9308L44.3176 18.345L45.7318 16.9308Z"
        fill="#F7345E"
      />
      <path
        d="M44.3288 15.5089L42.9146 14.0947L41.5003 15.5089L42.9146 16.9232L44.3288 15.5089Z"
        fill="#F7345E"
      />
      <path
        d="M42.9189 14.1056L41.5046 12.6914L40.0904 14.1056L41.5046 15.5198L42.9189 14.1056Z"
        fill="#FF8DCC"
      />
      <path
        d="M58.4418 32.4308L57.0276 31.0166L55.6134 32.4308L57.0276 33.845L58.4418 32.4308Z"
        fill="#F41740"
      />
      <path
        d="M57.0219 31.0168L55.6077 29.6025L54.1935 31.0168L55.6077 32.431L57.0219 31.0168Z"
        fill="#FE4F99"
      />
      <path
        d="M55.612 29.6125L54.1978 28.1982L52.7835 29.6125L54.1978 31.0267L55.612 29.6125Z"
        fill="#F41740"
      />
      <path
        d="M54.2018 28.1984L52.7876 26.7842L51.3734 28.1984L52.7876 29.6126L54.2018 28.1984Z"
        fill="#FE4F99"
      />
      <path
        d="M52.7919 26.7951L51.3777 25.3809L49.9635 26.7951L51.3777 28.2093L52.7919 26.7951Z"
        fill="#FE4F99"
      />
      <path
        d="M51.382 25.381L49.9678 23.9668L48.5536 25.381L49.9678 26.7952L51.382 25.381Z"
        fill="#FE4F99"
      />
      <path
        d="M49.9718 23.9767L48.5576 22.5625L47.1434 23.9767L48.5576 25.3909L49.9718 23.9767Z"
        fill="#FE4F99"
      />
      <path
        d="M48.5619 22.5627L47.1477 21.1484L45.7335 22.5627L47.1477 23.9769L48.5619 22.5627Z"
        fill="#FD64B6"
      />
      <path
        d="M47.152 21.1486L45.7378 19.7344L44.3236 21.1486L45.7378 22.5628L47.152 21.1486Z"
        fill="#FD64B6"
      />
      <path
        d="M45.7419 19.7443L44.3276 18.3301L42.9134 19.7443L44.3276 21.1585L45.7419 19.7443Z"
        fill="#FD64B6"
      />
      <path
        d="M44.3319 18.3302L42.9177 16.916L41.5035 18.3302L42.9177 19.7444L44.3319 18.3302Z"
        fill="#F41740"
      />
      <path
        d="M42.9147 16.9191L41.5005 15.5049L40.0863 16.9191L41.5005 18.3333L42.9147 16.9191Z"
        fill="#FF64B4"
      />
      <path
        d="M41.5048 15.505L40.0906 14.0908L38.6764 15.505L40.0906 16.9192L41.5048 15.505Z"
        fill="#F7345E"
      />
      <path
        d="M57.0348 33.838L55.6206 32.4238L54.2064 33.838L55.6206 35.2523L57.0348 33.838Z"
        fill="#F41740"
      />
      <path
        d="M55.6249 32.4337L54.2107 31.0195L52.7965 32.4337L54.2107 33.848L55.6249 32.4337Z"
        fill="#FE4F99"
      />
      <path
        d="M54.215 31.0197L52.8008 29.6055L51.3866 31.0197L52.8008 32.4339L54.215 31.0197Z"
        fill="#F41740"
      />
      <path
        d="M52.8048 29.6154L51.3906 28.2012L49.9764 29.6154L51.3906 31.0296L52.8048 29.6154Z"
        fill="#FE4F99"
      />
      <path
        d="M51.3849 28.2013L49.9707 26.7871L48.5565 28.2013L49.9707 29.6155L51.3849 28.2013Z"
        fill="#9D0B27"
      />
      <path
        d="M49.975 26.7736L48.5608 25.3594L47.1466 26.7736L48.5608 28.1878L49.975 26.7736Z"
        fill="#F63273"
      />
      <path
        d="M48.5648 25.3693L47.1506 23.9551L45.7364 25.3693L47.1506 26.7835L48.5648 25.3693Z"
        fill="#9D0B27"
      />
      <path
        d="M47.1549 23.9552L45.7407 22.541L44.3265 23.9552L45.7407 25.3694L47.1549 23.9552Z"
        fill="#FF65AD"
      />
      <path
        d="M45.745 22.5509L44.3308 21.1367L42.9166 22.5509L44.3308 23.9651L45.745 22.5509Z"
        fill="#9D0B27"
      />
      <path
        d="M44.3349 21.1378L42.9207 19.7236L41.5064 21.1378L42.9207 22.5521L44.3349 21.1378Z"
        fill="#BE0820"
      />
      <path
        d="M42.925 19.7335L41.5107 18.3193L40.0965 19.7335L41.5107 21.1478L42.925 19.7335Z"
        fill="#F41740"
      />
      <path
        d="M41.5148 18.3195L40.1006 16.9053L38.6864 18.3195L40.1006 19.7337L41.5148 18.3195Z"
        fill="#FF64B4"
      />
      <path
        d="M40.1049 16.9054L38.6907 15.4912L37.2765 16.9054L38.6907 18.3196L40.1049 16.9054Z"
        fill="#F7345E"
      />
      <path
        d="M55.6278 35.2404L54.2136 33.8262L52.7994 35.2404L54.2136 36.6546L55.6278 35.2404Z"
        fill="#F41740"
      />
      <path
        d="M54.2179 33.8263L52.8037 32.4121L51.3895 33.8263L52.8037 35.2405L54.2179 33.8263Z"
        fill="#FE4F99"
      />
      <path
        d="M52.808 32.4123L51.3938 30.998L49.9796 32.4123L51.3938 33.8265L52.808 32.4123Z"
        fill="#F41740"
      />
      <path
        d="M51.3979 31.0089L49.9836 29.5947L48.5694 31.0089L49.9836 32.4232L51.3979 31.0089Z"
        fill="#FE4F99"
      />
      <path
        d="M49.9879 29.5949L48.5737 28.1807L47.1595 29.5949L48.5737 31.0091L49.9879 29.5949Z"
        fill="#F61340"
      />
      <path
        d="M48.578 28.1906L47.1638 26.7764L45.7496 28.1906L47.1638 29.6048L48.578 28.1906Z"
        fill="#FE4F99"
      />
      <path
        d="M47.1579 26.7765L45.7437 25.3623L44.3294 26.7765L45.7437 28.1907L47.1579 26.7765Z"
        fill="#9D0B27"
      />
      <path
        d="M45.748 25.3722L44.3337 23.958L42.9195 25.3722L44.3337 26.7864L45.748 25.3722Z"
        fill="#F63273"
      />
      <path
        d="M44.338 23.9582L42.9238 22.5439L41.5096 23.9582L42.9238 25.3724L44.338 23.9582Z"
        fill="#F63273"
      />
      <path
        d="M42.9279 22.5441L41.5137 21.1299L40.0995 22.5441L41.5137 23.9583L42.9279 22.5441Z"
        fill="#FD64B6"
      />
      <path
        d="M41.518 21.1408L40.1038 19.7266L38.6895 21.1408L40.1038 22.555L41.518 21.1408Z"
        fill="#F41740"
      />
      <path
        d="M40.1149 19.7189L38.7007 18.3047L37.2865 19.7189L38.7007 21.1331L40.1149 19.7189Z"
        fill="#FF64B4"
      />
      <path
        d="M38.705 18.3156L37.2908 16.9014L35.8766 18.3156L37.2908 19.7298L38.705 18.3156Z"
        fill="#F7345E"
      />
      <path
        d="M54.2279 36.6408L52.8137 35.2266L51.3995 36.6408L52.8137 38.055L54.2279 36.6408Z"
        fill="#F41740"
      />
      <path
        d="M52.818 35.2267L51.4038 33.8125L49.9896 35.2267L51.4038 36.6409L52.818 35.2267Z"
        fill="#FE4F99"
      />
      <path
        d="M51.4081 33.8224L49.9939 32.4082L48.5797 33.8224L49.9939 35.2366L51.4081 33.8224Z"
        fill="#F41740"
      />
      <path
        d="M49.998 32.4084L48.5837 30.9941L47.1695 32.4084L48.5837 33.8226L49.998 32.4084Z"
        fill="#FE4F99"
      />
      <path
        d="M48.588 31.005L47.1738 29.5908L45.7596 31.005L47.1738 32.4192L48.588 31.005Z"
        fill="#FE4F99"
      />
      <path
        d="M47.1781 29.591L45.7639 28.1768L44.3497 29.591L45.7639 31.0052L47.1781 29.591Z"
        fill="#FE4F99"
      />
      <path
        d="M45.768 28.1867L44.3538 26.7725L42.9395 28.1867L44.3538 29.6009L45.768 28.1867Z"
        fill="#FE4F99"
      />
      <path
        d="M44.3581 26.7726L42.9438 25.3584L41.5296 26.7726L42.9438 28.1868L44.3581 26.7726Z"
        fill="#FD64B6"
      />
      <path
        d="M42.9381 25.3585L41.5239 23.9443L40.1097 25.3585L41.5239 26.7728L42.9381 25.3585Z"
        fill="#FD64B6"
      />
      <path
        d="M41.528 23.9543L40.1138 22.54L38.6996 23.9543L40.1138 25.3685L41.528 23.9543Z"
        fill="#FD64B6"
      />
      <path
        d="M40.1181 22.5402L38.7039 21.126L37.2896 22.5402L38.7039 23.9544L40.1181 22.5402Z"
        fill="#F41740"
      />
      <path
        d="M38.7082 21.1359L37.2939 19.7217L35.8797 21.1359L37.2939 22.5501L38.7082 21.1359Z"
        fill="#FF64B4"
      />
      <path
        d="M37.2909 19.715L35.8767 18.3008L34.4625 19.715L35.8767 21.1292L37.2909 19.715Z"
        fill="#F7345E"
      />
      <path
        d="M52.821 38.048L51.4067 36.6338L49.9925 38.048L51.4067 39.4622L52.821 38.048Z"
        fill="#F41740"
      />
      <path
        d="M51.411 36.6437L49.9968 35.2295L48.5826 36.6437L49.9968 38.0579L51.411 36.6437Z"
        fill="#FE4F99"
      />
      <path
        d="M50.0011 35.2296L48.5869 33.8154L47.1727 35.2296L48.5869 36.6439L50.0011 35.2296Z"
        fill="#F41740"
      />
      <path
        d="M48.591 33.8253L47.1768 32.4111L45.7625 33.8253L47.1768 35.2396L48.591 33.8253Z"
        fill="#FE4F99"
      />
      <path
        d="M47.1881 32.4044L45.7739 30.9902L44.3597 32.4044L45.7739 33.8187L47.1881 32.4044Z"
        fill="#BE0820"
      />
      <path
        d="M45.7782 30.9904L44.364 29.5762L42.9498 30.9904L44.364 32.4046L45.7782 30.9904Z"
        fill="#F61044"
      />
      <path
        d="M44.361 29.5793L42.9468 28.165L41.5326 29.5793L42.9468 30.9935L44.361 29.5793Z"
        fill="#F21548"
      />
      <path
        d="M42.9511 28.1652L41.5369 26.751L40.1227 28.1652L41.5369 29.5794L42.9511 28.1652Z"
        fill="#F21548"
      />
      <path
        d="M41.5412 26.7619L40.127 25.3477L38.7127 26.7619L40.127 28.1761L41.5412 26.7619Z"
        fill="#F93A77"
      />
      <path
        d="M40.131 25.3478L38.7168 23.9336L37.3026 25.3478L38.7168 26.762L40.131 25.3478Z"
        fill="#FD64B6"
      />
      <path
        d="M38.7111 23.9435L37.2969 22.5293L35.8827 23.9435L37.2969 25.3577L38.7111 23.9435Z"
        fill="#F41740"
      />
      <path
        d="M37.3012 22.5294L35.887 21.1152L34.4727 22.5294L35.887 23.9437L37.3012 22.5294Z"
        fill="#FF64B4"
      />
      <path
        d="M35.891 21.1154L34.4768 19.7012L33.0626 21.1154L34.4768 22.5296L35.891 21.1154Z"
        fill="#F7345E"
      />
      <path
        d="M51.4142 39.4503L50 38.0361L48.5858 39.4503L50 40.8646L51.4142 39.4503Z"
        fill="#F41740"
      />
      <path
        d="M50.0041 38.0373L48.5898 36.623L47.1756 38.0373L48.5898 39.4515L50.0041 38.0373Z"
        fill="#FE4F99"
      />
      <path
        d="M48.5941 36.6232L47.1799 35.209L45.7657 36.6232L47.1799 38.0374L48.5941 36.6232Z"
        fill="#F41740"
      />
      <path
        d="M47.184 35.2189L45.7698 33.8047L44.3556 35.2189L45.7698 36.6331L47.184 35.2189Z"
        fill="#FE4F99"
      />
      <path
        d="M45.7741 33.8048L44.3599 32.3906L42.9456 33.8048L44.3599 35.2191L45.7741 33.8048Z"
        fill="#FE4F99"
      />
      <path
        d="M44.3642 32.4005L42.95 30.9863L41.5357 32.4005L42.9499 33.8148L44.3642 32.4005Z"
        fill="#F61044"
      />
      <path
        d="M42.954 30.9865L41.5398 29.5723L40.1256 30.9865L41.5398 32.4007L42.954 30.9865Z"
        fill="#BE0820"
      />
      <path
        d="M41.5441 29.5822L40.1299 28.168L38.7157 29.5822L40.1299 30.9964L41.5441 29.5822Z"
        fill="#BE0820"
      />
      <path
        d="M40.1342 28.1681L38.72 26.7539L37.3058 28.1681L38.72 29.5823L40.1342 28.1681Z"
        fill="#F61340"
      />
      <path
        d="M38.724 26.7541L37.3098 25.3398L35.8956 26.7541L37.3098 28.1683L38.724 26.7541Z"
        fill="#FD64B6"
      />
      <path
        d="M37.3212 25.3429L35.907 23.9287L34.4928 25.3429L35.907 26.7571L37.3212 25.3429Z"
        fill="#F41740"
      />
      <path
        d="M35.9113 23.9289L34.4971 22.5146L33.0829 23.9289L34.4971 25.3431L35.9113 23.9289Z"
        fill="#FF64B4"
      />
      <path
        d="M34.5011 22.5255L33.0869 21.1113L31.6727 22.5255L33.0869 23.9398L34.5011 22.5255Z"
        fill="#F7345E"
      />
      <path
        d="M50.0143 40.8507L48.6001 39.4365L47.1859 40.8507L48.6001 42.265L50.0143 40.8507Z"
        fill="#ED3589"
      />
      <path
        d="M61.2489 32.4377L59.8347 31.0234L58.4205 32.4377L59.8347 33.8519L61.2489 32.4377Z"
        fill="#ED3589"
      />
      <path
        d="M59.8349 33.838L58.4207 32.4238L57.0064 33.838L58.4207 35.2523L59.8349 33.838Z"
        fill="#ED3589"
      />
      <path
        d="M58.435 35.2375L57.0208 33.8232L55.6065 35.2375L57.0208 36.6517L58.435 35.2375Z"
        fill="#ED3589"
      />
      <path
        d="M57.0351 36.6476L55.6208 35.2334L54.2066 36.6476L55.6208 38.0618L57.0351 36.6476Z"
        fill="#ED3589"
      />
      <path
        d="M55.621 38.048L54.2068 36.6338L52.7926 38.048L54.2068 39.4622L55.621 38.048Z"
        fill="#ED3589"
      />
      <path
        d="M54.2211 39.4474L52.8069 38.0332L51.3927 39.4474L52.8069 40.8616L54.2211 39.4474Z"
        fill="#ED3589"
      />
      <path
        d="M52.8212 40.8478L51.407 39.4336L49.9928 40.8478L51.407 42.262L52.8212 40.8478Z"
        fill="#ED3589"
      />
      <path
        d="M51.4171 42.258L50.0029 40.8438L48.5887 42.258L50.0029 43.6722L51.4171 42.258Z"
        fill="#ED3589"
      />
      <path
        d="M62.6618 33.8634L61.2476 32.4492L59.8333 33.8634L61.2476 35.2776L62.6618 33.8634Z"
        fill="black"
      />
      <path
        d="M61.2619 35.2628L59.8477 33.8486L58.4334 35.2628L59.8477 36.6771L61.2619 35.2628Z"
        fill="black"
      />
      <path
        d="M59.8549 36.6564L58.4407 35.2422L57.0265 36.6564L58.4407 38.0706L59.8549 36.6564Z"
        fill="black"
      />
      <path
        d="M58.4479 38.0734L57.0337 36.6592L55.6195 38.0734L57.0337 39.4876L58.4479 38.0734Z"
        fill="black"
      />
      <path
        d="M57.048 39.4728L55.6338 38.0586L54.2196 39.4728L55.6338 40.887L57.048 39.4728Z"
        fill="black"
      />
      <path
        d="M55.651 40.8664L54.2368 39.4521L52.8226 40.8664L54.2368 42.2806L55.651 40.8664Z"
        fill="black"
      />
      <path
        d="M54.244 42.2834L52.8298 40.8691L51.4156 42.2834L52.8298 43.6976L54.244 42.2834Z"
        fill="black"
      />
      <path
        d="M40.0817 11.2804L38.6675 9.86621L37.2533 11.2804L38.6675 12.6946L40.0817 11.2804Z"
        fill="black"
      />
      <path
        d="M38.6818 12.6798L37.2676 11.2656L35.8534 12.6798L37.2676 14.0941L38.6818 12.6798Z"
        fill="black"
      />
      <path
        d="M37.2848 14.0871L35.8706 12.6729L34.4564 14.0871L35.8706 15.5013L37.2848 14.0871Z"
        fill="black"
      />
      <path
        d="M35.8778 15.4904L34.4636 14.0762L33.0494 15.4904L34.4636 16.9046L35.8778 15.4904Z"
        fill="black"
      />
      <path
        d="M34.4779 16.8898L33.0637 15.4756L31.6495 16.8898L33.0637 18.304L34.4779 16.8898Z"
        fill="black"
      />
      <path
        d="M33.071 18.297L31.6567 16.8828L30.2425 18.297L31.6567 19.7112L33.071 18.297Z"
        fill="black"
      />
      <path
        d="M31.6642 19.7003L30.25 18.2861L28.8358 19.7003L30.25 21.1146L31.6642 19.7003Z"
        fill="black"
      />
      <path
        d="M30.2643 21.0998L28.8501 19.6855L27.4359 21.0998L28.8501 22.514L30.2643 21.0998Z"
        fill="black"
      />
      <path
        d="M48.6042 39.4367L47.1899 38.0225L45.7757 39.4367L47.1899 40.8509L48.6042 39.4367Z"
        fill="#F41740"
      />
      <path
        d="M47.1942 38.0324L45.78 36.6182L44.3658 38.0324L45.78 39.4466L47.1942 38.0324Z"
        fill="#F41740"
      />
      <path
        d="M45.7841 36.6183L44.3699 35.2041L42.9557 36.6183L44.3699 38.0325L45.7841 36.6183Z"
        fill="#F41740"
      />
      <path
        d="M44.3742 35.215L42.96 33.8008L41.5457 35.215L42.96 36.6292L44.3742 35.215Z"
        fill="#F41740"
      />
      <path
        d="M42.9643 33.8009L41.55 32.3867L40.1358 33.8009L41.55 35.2151L42.9643 33.8009Z"
        fill="#F41740"
      />
      <path
        d="M41.5541 32.3869L40.1399 30.9727L38.7257 32.3869L40.1399 33.8011L41.5541 32.3869Z"
        fill="#F41740"
      />
      <path
        d="M40.1442 30.9826L38.73 29.5684L37.3158 30.9826L38.73 32.3968L40.1442 30.9826Z"
        fill="#F41740"
      />
      <path
        d="M38.7343 29.5685L37.3201 28.1543L35.9059 29.5685L37.3201 30.9827L38.7343 29.5685Z"
        fill="#F41740"
      />
      <path
        d="M37.3241 28.1642L35.9099 26.75L34.4957 28.1642L35.9099 29.5784L37.3241 28.1642Z"
        fill="#F41740"
      />
      <path
        d="M35.9142 26.7502L34.5 25.3359L33.0858 26.7502L34.5 28.1644L35.9142 26.7502Z"
        fill="#F41740"
      />
      <path
        d="M34.5043 25.3468L33.0901 23.9326L31.6759 25.3468L33.0901 26.761L34.5043 25.3468Z"
        fill="#F41740"
      />
      <path
        d="M33.0941 23.9328L31.6799 22.5186L30.2657 23.9328L31.6799 25.347L33.0941 23.9328Z"
        fill="#FF64B4"
      />
      <path
        d="M41.5089 12.6916L40.0947 11.2773L38.6805 12.6916L40.0947 14.1058L41.5089 12.6916Z"
        fill="#FF8DCC"
      />
      <path
        d="M40.0949 14.1007L38.6807 12.6865L37.2665 14.1007L38.6807 15.515L40.0949 14.1007Z"
        fill="#FF8DCC"
      />
      <path
        d="M38.695 15.5011L37.2808 14.0869L35.8665 15.5011L37.2808 16.9153L38.695 15.5011Z"
        fill="#FF8DCC"
      />
      <path
        d="M37.2951 16.9015L35.8809 15.4873L34.4666 16.9015L35.8809 18.3157L37.2951 16.9015Z"
        fill="#FF8DCC"
      />
      <path
        d="M35.881 18.3117L34.4668 16.8975L33.0526 18.3117L34.4668 19.7259L35.881 18.3117Z"
        fill="#FF8DCC"
      />
      <path
        d="M34.4811 19.7111L33.0669 18.2969L31.6527 19.7111L33.0669 21.1253L34.4811 19.7111Z"
        fill="#FF8DCC"
      />
      <path
        d="M33.0812 21.1115L31.667 19.6973L30.2528 21.1115L31.667 22.5257L33.0812 21.1115Z"
        fill="#FF8DCC"
      />
      <path
        d="M31.6772 22.5109L30.2629 21.0967L28.8487 22.5109L30.2629 23.9251L31.6772 22.5109Z"
        fill="#FF64B4"
      />
      <path
        d="M48.6073 42.258L47.1931 40.8438L45.7789 42.258L47.1931 43.6722L48.6073 42.258Z"
        fill="#ED3589"
      />
      <path
        d="M50.0172 43.6574L48.603 42.2432L47.1888 43.6574L48.603 45.0716L50.0172 43.6574Z"
        fill="black"
      />
      <path
        d="M47.1972 40.8537L45.783 39.4395L44.3687 40.8537L45.783 42.2679L47.1972 40.8537Z"
        fill="#ED3589"
      />
      <path
        d="M45.7873 39.4396L44.373 38.0254L42.9588 39.4396L44.373 40.8538L45.7873 39.4396Z"
        fill="#ED3589"
      />
      <path
        d="M44.3773 38.0353L42.9631 36.6211L41.5489 38.0353L42.9631 39.4495L44.3773 38.0353Z"
        fill="#ED3589"
      />
      <path
        d="M42.9743 36.6144L41.5601 35.2002L40.1458 36.6144L41.5601 38.0286L42.9743 36.6144Z"
        fill="#ED3589"
      />
      <path
        d="M41.5644 35.2003L40.1501 33.7861L38.7359 35.2003L40.1501 36.6146L41.5644 35.2003Z"
        fill="#ED3589"
      />
      <path
        d="M40.1542 33.797L38.74 32.3828L37.3258 33.797L38.74 35.2112L40.1542 33.797Z"
        fill="#ED3589"
      />
      <path
        d="M38.7372 32.3752L37.323 30.9609L35.9088 32.3752L37.323 33.7894L38.7372 32.3752Z"
        fill="#ED3589"
      />
      <path
        d="M37.3273 30.9718L35.9131 29.5576L34.4989 30.9718L35.9131 32.386L37.3273 30.9718Z"
        fill="#ED3589"
      />
      <path
        d="M35.9171 29.5578L34.5029 28.1436L33.0887 29.5578L34.5029 30.972L35.9171 29.5578Z"
        fill="#ED3589"
      />
      <path
        d="M34.5072 28.1535L33.093 26.7393L31.6788 28.1535L33.093 29.5677L34.5072 28.1535Z"
        fill="#ED3589"
      />
      <path
        d="M33.0973 26.7394L31.6831 25.3252L30.2689 26.7394L31.6831 28.1536L33.0973 26.7394Z"
        fill="#ED3589"
      />
      <path
        d="M31.6872 25.3253L30.2729 23.9111L28.8587 25.3253L30.2729 26.7396L31.6872 25.3253Z"
        fill="#ED3589"
      />
      <path
        d="M30.2773 23.921L28.863 22.5068L27.4488 23.921L28.863 25.3353L30.2773 23.921Z"
        fill="black"
      />
      <path
        d="M47.1962 43.671L45.782 42.2568L44.3678 43.671L45.782 45.0853L47.1962 43.671Z"
        fill="black"
      />
      <path
        d="M45.786 42.257L44.3718 40.8428L42.9576 42.257L44.3718 43.6712L45.786 42.257Z"
        fill="black"
      />
      <path
        d="M44.3761 40.8527L42.9619 39.4385L41.5477 40.8527L42.9619 42.2669L44.3761 40.8527Z"
        fill="black"
      />
      <path
        d="M42.9662 39.4386L41.552 38.0244L40.1378 39.4386L41.552 40.8528L42.9662 39.4386Z"
        fill="black"
      />
      <path
        d="M41.5561 38.0246L40.1418 36.6104L38.7276 38.0246L40.1418 39.4388L41.5561 38.0246Z"
        fill="black"
      />
      <path
        d="M40.1461 36.6203L38.7319 35.2061L37.3177 36.6203L38.7319 38.0345L40.1461 36.6203Z"
        fill="black"
      />
      <path
        d="M38.7362 35.2062L37.322 33.792L35.9078 35.2062L37.322 36.6204L38.7362 35.2062Z"
        fill="black"
      />
      <path
        d="M37.3261 33.8029L35.9119 32.3887L34.4977 33.8029L35.9119 35.2171L37.3261 33.8029Z"
        fill="black"
      />
      <path
        d="M35.9162 32.3888L34.502 30.9746L33.0877 32.3888L34.502 33.803L35.9162 32.3888Z"
        fill="black"
      />
      <path
        d="M34.4962 30.9845L33.082 29.5703L31.6678 30.9845L33.082 32.3987L34.4962 30.9845Z"
        fill="black"
      />
      <path
        d="M33.0861 29.5705L31.6719 28.1562L30.2577 29.5705L31.6719 30.9847L33.0861 29.5705Z"
        fill="black"
      />
      <path
        d="M31.6833 28.1496L30.269 26.7354L28.8548 28.1496L30.269 29.5638L31.6833 28.1496Z"
        fill="black"
      />
      <path
        d="M30.2731 26.7453L28.8589 25.3311L27.4447 26.7453L28.8589 28.1595L30.2731 26.7453Z"
        fill="black"
      />
      <path
        d="M45.3699 37.4297H43.3699V39.4297H45.3699V37.4297Z"
        fill="#FE4F99"
      />
      <path
        d="M47.3499 37.4297H45.3499V39.4297H47.3499V37.4297Z"
        fill="black"
      />
      <path
        d="M43.3699 37.4297H41.3699V39.4297H43.3699V37.4297Z"
        fill="#FE4F99"
      />
      <path
        d="M41.3799 37.4297H39.3799V39.4297H41.3799V37.4297Z"
        fill="#FE4F99"
      />
      <path
        d="M39.3799 37.4297H37.3799V39.4297H39.3799V37.4297Z"
        fill="#FE4F99"
      />
      <path
        d="M37.3899 37.4297H35.3899V39.4297H37.3899V37.4297Z"
        fill="#FE4F99"
      />
      <path
        d="M35.3899 37.4297H33.3899V39.4297H35.3899V37.4297Z"
        fill="#FE4F99"
      />
      <path
        d="M33.3999 37.4297H31.3999V39.4297H33.3999V37.4297Z"
        fill="#FE4F99"
      />
      <path
        d="M31.3999 37.4297H29.3999V39.4297H31.3999V37.4297Z"
        fill="#FF8DCC"
      />
      <path
        d="M29.4099 37.4297H27.4099V39.4297H29.4099V37.4297Z"
        fill="#FF8DCC"
      />
      <path
        d="M27.4099 37.4297H25.4099V39.4297H27.4099V37.4297Z"
        fill="#FF8DCC"
      />
      <path
        d="M25.4199 37.4297H23.4199V39.4297H25.4199V37.4297Z"
        fill="#FF8DCC"
      />
      <path
        d="M23.4299 37.4297H21.4299V39.4297H23.4299V37.4297Z"
        fill="#FF8DCC"
      />
      <path
        d="M21.4299 37.4297H19.4299V39.4297H21.4299V37.4297Z"
        fill="#FF8DCC"
      />
      <path
        d="M19.4399 37.4297H17.4399V39.4297H19.4399V37.4297Z"
        fill="black"
      />
      <path
        d="M45.3599 35.4297H43.3599V37.4297H45.3599V35.4297Z"
        fill="black"
      />
      <path
        d="M43.3699 35.4297H41.3699V37.4297H43.3699V35.4297Z"
        fill="black"
      />
      <path
        d="M41.3699 35.4297H39.3699V37.4297H41.3699V35.4297Z"
        fill="black"
      />
      <path
        d="M39.3799 35.4297H37.3799V37.4297H39.3799V35.4297Z"
        fill="black"
      />
      <path
        d="M37.3799 35.4297H35.3799V37.4297H37.3799V35.4297Z"
        fill="black"
      />
      <path
        d="M35.3899 35.4297H33.3899V37.4297H35.3899V35.4297Z"
        fill="black"
      />
      <path
        d="M33.3899 35.4297H31.3899V37.4297H33.3899V35.4297Z"
        fill="black"
      />
      <path
        d="M31.3999 35.4297H29.3999V37.4297H31.3999V35.4297Z"
        fill="black"
      />
      <path
        d="M29.3999 35.4297H27.3999V37.4297H29.3999V35.4297Z"
        fill="black"
      />
      <path
        d="M27.4099 35.4297H25.4099V37.4297H27.4099V35.4297Z"
        fill="black"
      />
      <path
        d="M25.4099 35.4297H23.4099V37.4297H25.4099V35.4297Z"
        fill="black"
      />
      <path
        d="M23.4199 35.4297H21.4199V37.4297H23.4199V35.4297Z"
        fill="black"
      />
      <path
        d="M21.4299 35.4297H19.4299V37.4297H21.4299V35.4297Z"
        fill="black"
      />
      <path
        d="M45.3699 39.4102H43.3699V41.4102H45.3699V39.4102Z"
        fill="#FE4F99"
      />
      <path
        d="M43.3699 39.4102H41.3699V41.4102H43.3699V39.4102Z"
        fill="#F41740"
      />
      <path
        d="M41.3799 39.4102H39.3799V41.4102H41.3799V39.4102Z"
        fill="#F41740"
      />
      <path
        d="M39.3799 39.4102H37.3799V41.4102H39.3799V39.4102Z"
        fill="#F41740"
      />
      <path
        d="M37.3899 39.4102H35.3899V41.4102H37.3899V39.4102Z"
        fill="#F41740"
      />
      <path
        d="M35.3899 39.4102H33.3899V41.4102H35.3899V39.4102Z"
        fill="#F41740"
      />
      <path
        d="M33.3999 39.4102H31.3999V41.4102H33.3999V39.4102Z"
        fill="#F41740"
      />
      <path
        d="M31.3999 39.4102H29.3999V41.4102H31.3999V39.4102Z"
        fill="#F7345E"
      />
      <path
        d="M29.4099 39.4102H27.4099V41.4102H29.4099V39.4102Z"
        fill="#F7345E"
      />
      <path
        d="M27.4099 39.4102H25.4099V41.4102H27.4099V39.4102Z"
        fill="#F7345E"
      />
      <path
        d="M25.4199 39.4102H23.4199V41.4102H25.4199V39.4102Z"
        fill="#F7345E"
      />
      <path
        d="M23.4299 39.4102H21.4299V41.4102H23.4299V39.4102Z"
        fill="#F7345E"
      />
      <path
        d="M21.4299 39.4102H19.4299V41.4102H21.4299V39.4102Z"
        fill="#FF8DCC"
      />
      <path
        d="M45.3699 41.3896H43.3699V43.3896H45.3699V41.3896Z"
        fill="#F41740"
      />
      <path
        d="M43.3699 41.3896H41.3699V43.3896H43.3699V41.3896Z"
        fill="#FE4F99"
      />
      <path
        d="M41.3799 41.3896H39.3799V43.3896H41.3799V41.3896Z"
        fill="#F41740"
      />
      <path
        d="M39.3799 41.3896H37.3799V43.3896H39.3799V41.3896Z"
        fill="#FE4F99"
      />
      <path
        d="M37.3899 41.3896H35.3899V43.3896H37.3899V41.3896Z"
        fill="#FE4F99"
      />
      <path
        d="M35.3899 41.3896H33.3899V43.3896H35.3899V41.3896Z"
        fill="#FE4F99"
      />
      <path
        d="M33.3999 41.3896H31.3999V43.3896H33.3999V41.3896Z"
        fill="#FE4F99"
      />
      <path
        d="M31.3999 41.3896H29.3999V43.3896H31.3999V41.3896Z"
        fill="#FD64B6"
      />
      <path
        d="M29.4099 41.3896H27.4099V43.3896H29.4099V41.3896Z"
        fill="#FD64B6"
      />
      <path
        d="M27.4099 41.3896H25.4099V43.3896H27.4099V41.3896Z"
        fill="#FD64B6"
      />
      <path
        d="M25.4199 41.3896H23.4199V43.3896H25.4199V41.3896Z"
        fill="#F41740"
      />
      <path
        d="M23.4299 41.3896H21.4299V43.3896H23.4299V41.3896Z"
        fill="#FF64B4"
      />
      <path
        d="M21.4299 41.3896H19.4299V43.3896H21.4299V41.3896Z"
        fill="#F7345E"
      />
      <path
        d="M45.3699 43.3799H43.3699V45.3799H45.3699V43.3799Z"
        fill="#F41740"
      />
      <path
        d="M43.3699 43.3799H41.3699V45.3799H43.3699V43.3799Z"
        fill="#FE4F99"
      />
      <path
        d="M41.3799 43.3799H39.3799V45.3799H41.3799V43.3799Z"
        fill="#F41740"
      />
      <path
        d="M39.3799 43.3799H37.3799V45.3799H39.3799V43.3799Z"
        fill="#FE4F99"
      />
      <path
        d="M37.3899 43.3799H35.3899V45.3799H37.3899V43.3799Z"
        fill="#9D0B27"
      />
      <path
        d="M35.3899 43.3799H33.3899V45.3799H35.3899V43.3799Z"
        fill="#F63273"
      />
      <path
        d="M33.3999 43.3799H31.3999V45.3799H33.3999V43.3799Z"
        fill="#9D0B27"
      />
      <path
        d="M31.3999 43.3799H29.3999V45.3799H31.3999V43.3799Z"
        fill="#FF65AD"
      />
      <path
        d="M29.4099 43.3799H27.4099V45.3799H29.4099V43.3799Z"
        fill="#9D0B27"
      />
      <path
        d="M27.4099 43.3799H25.4099V45.3799H27.4099V43.3799Z"
        fill="#BE0820"
      />
      <path
        d="M25.4199 43.3799H23.4199V45.3799H25.4199V43.3799Z"
        fill="#F41740"
      />
      <path
        d="M23.4299 43.3799H21.4299V45.3799H23.4299V43.3799Z"
        fill="#FF64B4"
      />
      <path
        d="M21.4299 43.3799H19.4299V45.3799H21.4299V43.3799Z"
        fill="#F7345E"
      />
      <path
        d="M45.3699 45.3604H43.3699V47.3604H45.3699V45.3604Z"
        fill="#F41740"
      />
      <path
        d="M43.3699 45.3604H41.3699V47.3604H43.3699V45.3604Z"
        fill="#FE4F99"
      />
      <path
        d="M41.3799 45.3604H39.3799V47.3604H41.3799V45.3604Z"
        fill="#F41740"
      />
      <path
        d="M39.3799 45.3604H37.3799V47.3604H39.3799V45.3604Z"
        fill="#FE4F99"
      />
      <path
        d="M37.3899 45.3604H35.3899V47.3604H37.3899V45.3604Z"
        fill="#F61340"
      />
      <path
        d="M35.3899 45.3604H33.3899V47.3604H35.3899V45.3604Z"
        fill="#FE4F99"
      />
      <path
        d="M33.3999 45.3604H31.3999V47.3604H33.3999V45.3604Z"
        fill="#9D0B27"
      />
      <path
        d="M31.3999 45.3604H29.3999V47.3604H31.3999V45.3604Z"
        fill="#F63273"
      />
      <path
        d="M29.4099 45.3604H27.4099V47.3604H29.4099V45.3604Z"
        fill="#F63273"
      />
      <path
        d="M27.4099 45.3604H25.4099V47.3604H27.4099V45.3604Z"
        fill="#FD64B6"
      />
      <path
        d="M25.4199 45.3604H23.4199V47.3604H25.4199V45.3604Z"
        fill="#F41740"
      />
      <path
        d="M23.4299 45.3604H21.4299V47.3604H23.4299V45.3604Z"
        fill="#FF64B4"
      />
      <path
        d="M21.4299 45.3604H19.4299V47.3604H21.4299V45.3604Z"
        fill="#F7345E"
      />
      <path
        d="M45.3699 47.3506H43.3699V49.3506H45.3699V47.3506Z"
        fill="#F41740"
      />
      <path
        d="M43.3699 47.3506H41.3699V49.3506H43.3699V47.3506Z"
        fill="#FE4F99"
      />
      <path
        d="M41.3799 47.3506H39.3799V49.3506H41.3799V47.3506Z"
        fill="#F41740"
      />
      <path
        d="M39.3799 47.3506H37.3799V49.3506H39.3799V47.3506Z"
        fill="#FE4F99"
      />
      <path
        d="M37.3899 47.3506H35.3899V49.3506H37.3899V47.3506Z"
        fill="#FE4F99"
      />
      <path
        d="M35.3899 47.3506H33.3899V49.3506H35.3899V47.3506Z"
        fill="#FE4F99"
      />
      <path
        d="M33.3999 47.3506H31.3999V49.3506H33.3999V47.3506Z"
        fill="#FE4F99"
      />
      <path
        d="M31.3999 47.3506H29.3999V49.3506H31.3999V47.3506Z"
        fill="#FD64B6"
      />
      <path
        d="M29.4099 47.3506H27.4099V49.3506H29.4099V47.3506Z"
        fill="#FD64B6"
      />
      <path
        d="M27.4099 47.3506H25.4099V49.3506H27.4099V47.3506Z"
        fill="#FD64B6"
      />
      <path
        d="M25.4199 47.3506H23.4199V49.3506H25.4199V47.3506Z"
        fill="#F41740"
      />
      <path
        d="M23.4299 47.3506H21.4299V49.3506H23.4299V47.3506Z"
        fill="#FF64B4"
      />
      <path
        d="M21.4299 47.3506H19.4299V49.3506H21.4299V47.3506Z"
        fill="#F7345E"
      />
      <path
        d="M45.3699 49.3301H43.3699V51.3301H45.3699V49.3301Z"
        fill="#F41740"
      />
      <path
        d="M43.3699 49.3301H41.3699V51.3301H43.3699V49.3301Z"
        fill="#FE4F99"
      />
      <path
        d="M41.3799 49.3301H39.3799V51.3301H41.3799V49.3301Z"
        fill="#F41740"
      />
      <path
        d="M39.3799 49.3301H37.3799V51.3301H39.3799V49.3301Z"
        fill="#FE4F99"
      />
      <path
        d="M37.3899 49.3301H35.3899V51.3301H37.3899V49.3301Z"
        fill="#BE0820"
      />
      <path
        d="M35.3899 49.3301H33.3899V51.3301H35.3899V49.3301Z"
        fill="#F61044"
      />
      <path
        d="M33.3999 49.3301H31.3999V51.3301H33.3999V49.3301Z"
        fill="#F21548"
      />
      <path
        d="M31.3999 49.3301H29.3999V51.3301H31.3999V49.3301Z"
        fill="#F21548"
      />
      <path
        d="M29.4099 49.3301H27.4099V51.3301H29.4099V49.3301Z"
        fill="#F93A77"
      />
      <path
        d="M27.4099 49.3301H25.4099V51.3301H27.4099V49.3301Z"
        fill="#FD64B6"
      />
      <path
        d="M25.4199 49.3301H23.4199V51.3301H25.4199V49.3301Z"
        fill="#F41740"
      />
      <path
        d="M23.4299 49.3301H21.4299V51.3301H23.4299V49.3301Z"
        fill="#FF64B4"
      />
      <path
        d="M21.4299 49.3301H19.4299V51.3301H21.4299V49.3301Z"
        fill="#F7345E"
      />
      <path
        d="M45.3699 51.3203H43.3699V53.3203H45.3699V51.3203Z"
        fill="#F41740"
      />
      <path
        d="M43.3699 51.3203H41.3699V53.3203H43.3699V51.3203Z"
        fill="#FE4F99"
      />
      <path
        d="M41.3799 51.3203H39.3799V53.3203H41.3799V51.3203Z"
        fill="#F41740"
      />
      <path
        d="M39.3799 51.3203H37.3799V53.3203H39.3799V51.3203Z"
        fill="#FE4F99"
      />
      <path
        d="M37.3899 51.3203H35.3899V53.3203H37.3899V51.3203Z"
        fill="#FE4F99"
      />
      <path
        d="M35.3899 51.3203H33.3899V53.3203H35.3899V51.3203Z"
        fill="#F61044"
      />
      <path
        d="M33.3999 51.3203H31.3999V53.3203H33.3999V51.3203Z"
        fill="#BE0820"
      />
      <path
        d="M31.3999 51.3203H29.3999V53.3203H31.3999V51.3203Z"
        fill="#BE0820"
      />
      <path
        d="M29.4099 51.3203H27.4099V53.3203H29.4099V51.3203Z"
        fill="#F61340"
      />
      <path
        d="M27.4099 51.3203H25.4099V53.3203H27.4099V51.3203Z"
        fill="#FD64B6"
      />
      <path
        d="M25.4199 51.3203H23.4199V53.3203H25.4199V51.3203Z"
        fill="#F41740"
      />
      <path
        d="M23.4299 51.3203H21.4299V53.3203H23.4299V51.3203Z"
        fill="#FF64B4"
      />
      <path
        d="M21.4299 51.3203H19.4299V53.3203H21.4299V51.3203Z"
        fill="#F7345E"
      />
      <path
        d="M45.3699 53.2998H43.3699V55.2998H45.3699V53.2998Z"
        fill="#ED3589"
      />
      <path
        d="M47.3499 39.4102H45.3499V41.4102H47.3499V39.4102Z"
        fill="#ED3589"
      />
      <path
        d="M47.3499 41.3896H45.3499V43.3896H47.3499V41.3896Z"
        fill="#ED3589"
      />
      <path
        d="M47.3499 43.3799H45.3499V45.3799H47.3499V43.3799Z"
        fill="#ED3589"
      />
      <path
        d="M47.3499 45.3604H45.3499V47.3604H47.3499V45.3604Z"
        fill="#ED3589"
      />
      <path
        d="M47.3499 47.3506H45.3499V49.3506H47.3499V47.3506Z"
        fill="#ED3589"
      />
      <path
        d="M47.3499 49.3301H45.3499V51.3301H47.3499V49.3301Z"
        fill="#ED3589"
      />
      <path
        d="M47.3499 51.3203H45.3499V53.3203H47.3499V51.3203Z"
        fill="#ED3589"
      />
      <path
        d="M47.3499 53.2998H45.3499V55.2998H47.3499V53.2998Z"
        fill="#ED3589"
      />
      <path
        d="M49.3599 39.4102H47.3599V41.4102H49.3599V39.4102Z"
        fill="black"
      />
      <path
        d="M49.3599 41.3896H47.3599V43.3896H49.3599V41.3896Z"
        fill="black"
      />
      <path
        d="M49.3599 43.3799H47.3599V45.3799H49.3599V43.3799Z"
        fill="black"
      />
      <path
        d="M49.3599 45.3604H47.3599V47.3604H49.3599V45.3604Z"
        fill="black"
      />
      <path
        d="M49.3599 47.3506H47.3599V49.3506H49.3599V47.3506Z"
        fill="black"
      />
      <path
        d="M49.3599 49.3301H47.3599V51.3301H49.3599V49.3301Z"
        fill="black"
      />
      <path
        d="M49.3599 51.3203H47.3599V53.3203H49.3599V51.3203Z"
        fill="black"
      />
      <path
        d="M49.3599 53.2998H47.3599V55.2998H49.3599V53.2998Z"
        fill="black"
      />
      <path
        d="M17.4399 39.4102H15.4399V41.4102H17.4399V39.4102Z"
        fill="black"
      />
      <path
        d="M17.4399 41.3896H15.4399V43.3896H17.4399V41.3896Z"
        fill="black"
      />
      <path
        d="M17.4399 43.3799H15.4399V45.3799H17.4399V43.3799Z"
        fill="black"
      />
      <path
        d="M17.4399 45.3604H15.4399V47.3604H17.4399V45.3604Z"
        fill="black"
      />
      <path
        d="M17.4399 47.3506H15.4399V49.3506H17.4399V47.3506Z"
        fill="black"
      />
      <path
        d="M17.4399 49.3301H15.4399V51.3301H17.4399V49.3301Z"
        fill="black"
      />
      <path
        d="M17.4399 51.3203H15.4399V53.3203H17.4399V51.3203Z"
        fill="black"
      />
      <path
        d="M17.4399 53.2998H15.4399V55.2998H17.4399V53.2998Z"
        fill="black"
      />
      <path
        d="M43.3699 53.2998H41.3699V55.2998H43.3699V53.2998Z"
        fill="#F41740"
      />
      <path
        d="M41.3799 53.2998H39.3799V55.2998H41.3799V53.2998Z"
        fill="#F41740"
      />
      <path
        d="M39.3799 53.2998H37.3799V55.2998H39.3799V53.2998Z"
        fill="#F41740"
      />
      <path
        d="M37.3899 53.2998H35.3899V55.2998H37.3899V53.2998Z"
        fill="#F41740"
      />
      <path
        d="M35.3899 53.2998H33.3899V55.2998H35.3899V53.2998Z"
        fill="#F41740"
      />
      <path
        d="M33.3999 53.2998H31.3999V55.2998H33.3999V53.2998Z"
        fill="#F41740"
      />
      <path
        d="M31.3999 53.2998H29.3999V55.2998H31.3999V53.2998Z"
        fill="#F41740"
      />
      <path
        d="M29.4099 53.2998H27.4099V55.2998H29.4099V53.2998Z"
        fill="#F41740"
      />
      <path
        d="M27.4099 53.2998H25.4099V55.2998H27.4099V53.2998Z"
        fill="#F41740"
      />
      <path
        d="M25.4199 53.2998H23.4199V55.2998H25.4199V53.2998Z"
        fill="#F41740"
      />
      <path
        d="M23.4299 53.2998H21.4299V55.2998H23.4299V53.2998Z"
        fill="#F41740"
      />
      <path
        d="M21.4299 53.2998H19.4299V55.2998H21.4299V53.2998Z"
        fill="#FF64B4"
      />
      <path
        d="M19.4399 39.4102H17.4399V41.4102H19.4399V39.4102Z"
        fill="#FF8DCC"
      />
      <path
        d="M19.4399 41.3896H17.4399V43.3896H19.4399V41.3896Z"
        fill="#FF8DCC"
      />
      <path
        d="M19.4399 43.3799H17.4399V45.3799H19.4399V43.3799Z"
        fill="#FF8DCC"
      />
      <path
        d="M19.4399 45.3604H17.4399V47.3604H19.4399V45.3604Z"
        fill="#FF8DCC"
      />
      <path
        d="M19.4399 47.3506H17.4399V49.3506H19.4399V47.3506Z"
        fill="#FF8DCC"
      />
      <path
        d="M19.4399 49.3301H17.4399V51.3301H19.4399V49.3301Z"
        fill="#FF8DCC"
      />
      <path
        d="M19.4399 51.3203H17.4399V53.3203H19.4399V51.3203Z"
        fill="#FF8DCC"
      />
      <path
        d="M19.4399 53.2998H17.4399V55.2998H19.4399V53.2998Z"
        fill="#FF64B4"
      />
      <path d="M45.3699 55.29H43.3699V57.29H45.3699V55.29Z" fill="#ED3589" />
      <path d="M47.3499 55.29H45.3499V57.29H47.3499V55.29Z" fill="black" />
      <path d="M43.3699 55.29H41.3699V57.29H43.3699V55.29Z" fill="#ED3589" />
      <path d="M41.3799 55.29H39.3799V57.29H41.3799V55.29Z" fill="#ED3589" />
      <path d="M39.3799 55.29H37.3799V57.29H39.3799V55.29Z" fill="#ED3589" />
      <path d="M37.3899 55.29H35.3899V57.29H37.3899V55.29Z" fill="#ED3589" />
      <path d="M35.3899 55.29H33.3899V57.29H35.3899V55.29Z" fill="#ED3589" />
      <path d="M33.3999 55.29H31.3999V57.29H33.3999V55.29Z" fill="#ED3589" />
      <path d="M31.3999 55.29H29.3999V57.29H31.3999V55.29Z" fill="#ED3589" />
      <path d="M29.4099 55.29H27.4099V57.29H29.4099V55.29Z" fill="#ED3589" />
      <path d="M27.4099 55.29H25.4099V57.29H27.4099V55.29Z" fill="#ED3589" />
      <path d="M25.4199 55.29H23.4199V57.29H25.4199V55.29Z" fill="#ED3589" />
      <path d="M23.4299 55.29H21.4299V57.29H23.4299V55.29Z" fill="#ED3589" />
      <path d="M21.4299 55.29H19.4299V57.29H21.4299V55.29Z" fill="#ED3589" />
      <path d="M19.4399 55.29H17.4399V57.29H19.4399V55.29Z" fill="black" />
      <path d="M45.3699 57.29H43.3699V59.29H45.3699V57.29Z" fill="black" />
      <path d="M43.3699 57.29H41.3699V59.29H43.3699V57.29Z" fill="black" />
      <path d="M41.3799 57.29H39.3799V59.29H41.3799V57.29Z" fill="black" />
      <path d="M39.3799 57.29H37.3799V59.29H39.3799V57.29Z" fill="black" />
      <path d="M37.3899 57.29H35.3899V59.29H37.3899V57.29Z" fill="black" />
      <path d="M35.3899 57.29H33.3899V59.29H35.3899V57.29Z" fill="black" />
      <path d="M33.3999 57.29H31.3999V59.29H33.3999V57.29Z" fill="black" />
      <path d="M31.3999 57.29H29.3999V59.29H31.3999V57.29Z" fill="black" />
      <path d="M29.4099 57.29H27.4099V59.29H29.4099V57.29Z" fill="black" />
      <path d="M27.4099 57.29H25.4099V59.29H27.4099V57.29Z" fill="black" />
      <path d="M25.4199 57.29H23.4199V59.29H25.4199V57.29Z" fill="black" />
      <path d="M23.4299 57.29H21.4299V59.29H23.4299V57.29Z" fill="black" />
      <path d="M21.4299 57.29H19.4299V59.29H21.4299V57.29Z" fill="black" />
      <path
        d="M16.3199 11.1299H15.4399V12.0099H16.3199V11.1299Z"
        fill="#FDAE35"
      />
      <path d="M16.3199 12H15.4399V12.88H16.3199V12Z" fill="#FDE962" />
      <path d="M15.4398 12H14.5598V12.88H15.4398V12Z" fill="#FDAE35" />
      <path
        d="M16.3199 12.8799H15.4399V13.7599H16.3199V12.8799Z"
        fill="#FDE962"
      />
      <path
        d="M15.4398 12.8799H14.5598V13.7599H15.4398V12.8799Z"
        fill="#FDAE35"
      />
      <path
        d="M16.3199 13.7598H15.4399V14.6398H16.3199V13.7598Z"
        fill="#FDE962"
      />
      <path
        d="M15.4398 13.7598H14.5598V14.6398H15.4398V13.7598Z"
        fill="#FDE962"
      />
      <path
        d="M16.3199 14.6396H15.4399V15.5196H16.3199V14.6396Z"
        fill="#FDE962"
      />
      <path
        d="M15.4398 14.6396H14.5598V15.5196H15.4398V14.6396Z"
        fill="#FDE962"
      />
      <path
        d="M14.5599 13.7598H13.6799V14.6398H14.5599V13.7598Z"
        fill="#FDAE35"
      />
      <path
        d="M14.5599 14.6396H13.6799V15.5196H14.5599V14.6396Z"
        fill="#FDAE35"
      />
      <path
        d="M16.3199 15.5205H15.4399V16.4005H16.3199V15.5205Z"
        fill="#EBEFF0"
      />
      <path
        d="M15.4398 15.5205H14.5598V16.4005H15.4398V15.5205Z"
        fill="#FDE962"
      />
      <path
        d="M14.5599 15.5205H13.6799V16.4005H14.5599V15.5205Z"
        fill="#FDE962"
      />
      <path
        d="M13.6798 15.5205H12.7998V16.4005H13.6798V15.5205Z"
        fill="#FDAE35"
      />
      <path
        d="M12.8099 15.5205H11.9299V16.4005H12.8099V15.5205Z"
        fill="#FDAE35"
      />
      <path
        d="M14.5599 16.4004H13.6799V17.2804H14.5599V16.4004Z"
        fill="#FDE962"
      />
      <path
        d="M13.6798 16.4004H12.7998V17.2804H13.6798V16.4004Z"
        fill="#FDE962"
      />
      <path
        d="M12.8099 16.4004H11.9299V17.2804H12.8099V16.4004Z"
        fill="#FDAE35"
      />
      <path
        d="M11.9298 16.4004H11.0498V17.2804H11.9298V16.4004Z"
        fill="#FDAE35"
      />
      <path
        d="M11.0499 16.4004H10.1699V17.2804H11.0499V16.4004Z"
        fill="#FDAE35"
      />
      <path
        d="M16.3199 16.4004H15.4399V17.2804H16.3199V16.4004Z"
        fill="#EBEFF0"
      />
      <path
        d="M15.4398 16.4004H14.5598V17.2804H15.4398V16.4004Z"
        fill="#FDE962"
      />
      <path
        d="M14.5599 17.2705H13.6799V18.1505H14.5599V17.2705Z"
        fill="#EBEFF0"
      />
      <path
        d="M13.6798 17.2705H12.7998V18.1505H13.6798V17.2705Z"
        fill="#FDE962"
      />
      <path
        d="M12.8099 17.2705H11.9299V18.1505H12.8099V17.2705Z"
        fill="#FDE962"
      />
      <path
        d="M11.9298 17.2705H11.0498V18.1505H11.9298V17.2705Z"
        fill="#FDE962"
      />
      <path
        d="M11.0499 17.2705H10.1699V18.1505H11.0499V17.2705Z"
        fill="#FDAE35"
      />
      <path
        d="M10.1698 17.2705H9.28979V18.1505H10.1698V17.2705Z"
        fill="#FDAE35"
      />
      <path
        d="M9.28991 17.2705H8.40991V18.1505H9.28991V17.2705Z"
        fill="#FDAE35"
      />
      <path
        d="M16.3199 17.2705H15.4399V18.1505H16.3199V17.2705Z"
        fill="#EBEFF0"
      />
      <path
        d="M15.4398 17.2705H14.5598V18.1505H15.4398V17.2705Z"
        fill="#EBEFF0"
      />
      <path d="M16.3199 10.25H15.4399V11.13H16.3199V10.25Z" fill="#FDAE35" />
      <path
        d="M16.3199 9.37012H15.4399V10.2501H16.3199V9.37012Z"
        fill="#FDAE35"
      />
      <path d="M15.4398 12H16.3198V11.12H15.4398V12Z" fill="#FDAE35" />
      <path
        d="M15.4398 12.8896H16.3198V12.0096H15.4398V12.8896Z"
        fill="#FDE962"
      />
      <path
        d="M16.3099 12.8896H17.1899V12.0096H16.3099V12.8896Z"
        fill="#FDAE35"
      />
      <path
        d="M15.4398 13.7598H16.3198V12.8798H15.4398V13.7598Z"
        fill="#FDE962"
      />
      <path
        d="M16.3099 13.7598H17.1899V12.8798H16.3099V13.7598Z"
        fill="#FDAE35"
      />
      <path
        d="M15.4398 14.6396H16.3198V13.7596H15.4398V14.6396Z"
        fill="#FDE962"
      />
      <path
        d="M16.3099 14.6396H17.1899V13.7596H16.3099V14.6396Z"
        fill="#FDE962"
      />
      <path
        d="M15.4398 15.5205H16.3198V14.6405H15.4398V15.5205Z"
        fill="#FDE962"
      />
      <path
        d="M16.3099 15.5205H17.1899V14.6405H16.3099V15.5205Z"
        fill="#FDE962"
      />
      <path
        d="M17.1898 14.6396H18.0698V13.7596H17.1898V14.6396Z"
        fill="#FDAE35"
      />
      <path
        d="M17.1898 15.5205H18.0698V14.6405H17.1898V15.5205Z"
        fill="#FDAE35"
      />
      <path
        d="M15.4398 16.3896H16.3198V15.5096H15.4398V16.3896Z"
        fill="#EBEFF0"
      />
      <path
        d="M16.3099 16.3896H17.1899V15.5096H16.3099V16.3896Z"
        fill="#FDE962"
      />
      <path
        d="M17.1898 16.3896H18.0698V15.5096H17.1898V16.3896Z"
        fill="#FDE962"
      />
      <path d="M18.08 16.3896H18.96V15.5096H18.08V16.3896Z" fill="#FDAE35" />
      <path
        d="M18.9498 16.3896H19.8298V15.5096H18.9498V16.3896Z"
        fill="#FDAE35"
      />
      <path
        d="M17.1898 17.2705H18.0698V16.3905H17.1898V17.2705Z"
        fill="#FDE962"
      />
      <path d="M18.08 17.2705H18.96V16.3905H18.08V17.2705Z" fill="#FDE962" />
      <path
        d="M18.9498 17.2705H19.8298V16.3905H18.9498V17.2705Z"
        fill="#FDAE35"
      />
      <path d="M19.83 17.2705H20.71V16.3905H19.83V17.2705Z" fill="#FDAE35" />
      <path
        d="M20.7098 17.2705H21.5898V16.3905H20.7098V17.2705Z"
        fill="#FDAE35"
      />
      <path
        d="M15.4398 17.2705H16.3198V16.3905H15.4398V17.2705Z"
        fill="#EBEFF0"
      />
      <path
        d="M16.3099 17.2705H17.1899V16.3905H16.3099V17.2705Z"
        fill="#FDE962"
      />
      <path
        d="M17.1898 18.1602H18.0698V17.2802H17.1898V18.1602Z"
        fill="#EBEFF0"
      />
      <path d="M18.08 18.1602H18.96V17.2802H18.08V18.1602Z" fill="#FDE962" />
      <path
        d="M18.9498 18.1602H19.8298V17.2802H18.9498V18.1602Z"
        fill="#FDE962"
      />
      <path d="M19.83 18.1602H20.71V17.2802H19.83V18.1602Z" fill="#FDE962" />
      <path
        d="M20.7098 18.1602H21.5898V17.2802H20.7098V18.1602Z"
        fill="#FDAE35"
      />
      <path d="M21.58 18.1602H22.46V17.2802H21.58V18.1602Z" fill="#FDAE35" />
      <path
        d="M22.4598 18.1602H23.3398V17.2802H22.4598V18.1602Z"
        fill="#FDAE35"
      />
      <path
        d="M15.4398 18.1602H16.3198V17.2802H15.4398V18.1602Z"
        fill="#EBEFF0"
      />
      <path
        d="M16.3099 18.1602H17.1899V17.2802H16.3099V18.1602Z"
        fill="#EBEFF0"
      />
      <path
        d="M15.4398 11.1201H16.3198V10.2401H15.4398V11.1201Z"
        fill="#FDAE35"
      />
      <path d="M15.4398 10.25H16.3198V9.37H15.4398V10.25Z" fill="#FDAE35" />
      <path
        d="M15.4398 24.3105H16.3198V23.4305H15.4398V24.3105Z"
        fill="#FDAE35"
      />
      <path
        d="M15.4398 23.4404H16.3198V22.5604H15.4398V23.4404Z"
        fill="#FDE962"
      />
      <path
        d="M16.3099 23.4404H17.1899V22.5604H16.3099V23.4404Z"
        fill="#FDAE35"
      />
      <path
        d="M15.4398 22.5498H16.3198V21.6698H15.4398V22.5498Z"
        fill="#FDE962"
      />
      <path
        d="M16.3099 22.5498H17.1899V21.6698H16.3099V22.5498Z"
        fill="#FDAE35"
      />
      <path
        d="M15.4398 21.6699H16.3198V20.7899H15.4398V21.6699Z"
        fill="#FDE962"
      />
      <path
        d="M16.3099 21.6699H17.1899V20.7899H16.3099V21.6699Z"
        fill="#FDE962"
      />
      <path
        d="M15.4398 20.7998H16.3198V19.9198H15.4398V20.7998Z"
        fill="#FDE962"
      />
      <path
        d="M16.3099 20.7998H17.1899V19.9198H16.3099V20.7998Z"
        fill="#FDE962"
      />
      <path
        d="M17.1898 21.6699H18.0698V20.7899H17.1898V21.6699Z"
        fill="#FDAE35"
      />
      <path
        d="M17.1898 20.7998H18.0698V19.9198H17.1898V20.7998Z"
        fill="#FDAE35"
      />
      <path
        d="M15.4398 19.9199H16.3198V19.0399H15.4398V19.9199Z"
        fill="#EBEFF0"
      />
      <path
        d="M16.3099 19.9199H17.1899V19.0399H16.3099V19.9199Z"
        fill="#FDE962"
      />
      <path
        d="M17.1898 19.9199H18.0698V19.0399H17.1898V19.9199Z"
        fill="#FDE962"
      />
      <path d="M18.08 19.9199H18.96V19.0399H18.08V19.9199Z" fill="#FDAE35" />
      <path
        d="M18.9498 19.9199H19.8298V19.0399H18.9498V19.9199Z"
        fill="#FDAE35"
      />
      <path d="M17.1898 19.04H18.0698V18.16H17.1898V19.04Z" fill="#FDE962" />
      <path d="M18.08 19.04H18.96V18.16H18.08V19.04Z" fill="#FDE962" />
      <path d="M18.9498 19.04H19.8298V18.16H18.9498V19.04Z" fill="#FDAE35" />
      <path d="M19.83 19.04H20.71V18.16H19.83V19.04Z" fill="#FDAE35" />
      <path d="M20.7098 19.04H21.5898V18.16H20.7098V19.04Z" fill="#FDAE35" />
      <path d="M15.4398 19.04H16.3198V18.16H15.4398V19.04Z" fill="#EBEFF0" />
      <path d="M16.3099 19.04H17.1899V18.16H16.3099V19.04Z" fill="#FDE962" />
      <path
        d="M17.1898 18.1699H18.0698V17.2899H17.1898V18.1699Z"
        fill="#EBEFF0"
      />
      <path d="M18.08 18.1699H18.96V17.2899H18.08V18.1699Z" fill="#FDE962" />
      <path
        d="M18.9498 18.1699H19.8298V17.2899H18.9498V18.1699Z"
        fill="#FDE962"
      />
      <path d="M19.83 18.1699H20.71V17.2899H19.83V18.1699Z" fill="#FDE962" />
      <path
        d="M20.7098 18.1699H21.5898V17.2899H20.7098V18.1699Z"
        fill="#FDAE35"
      />
      <path d="M21.58 18.1699H22.46V17.2899H21.58V18.1699Z" fill="#FDAE35" />
      <path
        d="M22.4598 18.1699H23.3398V17.2899H22.4598V18.1699Z"
        fill="#FDAE35"
      />
      <path
        d="M15.4398 18.1699H16.3198V17.2899H15.4398V18.1699Z"
        fill="#EBEFF0"
      />
      <path
        d="M16.3099 18.1699H17.1899V17.2899H16.3099V18.1699Z"
        fill="#EBEFF0"
      />
      <path
        d="M15.4398 25.1904H16.3198V24.3104H15.4398V25.1904Z"
        fill="#FDAE35"
      />
      <path
        d="M15.4398 26.0703H16.3198V25.1903H15.4398V26.0703Z"
        fill="#FDAE35"
      />
      <path
        d="M16.3199 23.4297H15.4399V24.3097H16.3199V23.4297Z"
        fill="#FDAE35"
      />
      <path
        d="M16.3199 22.5498H15.4399V23.4298H16.3199V22.5498Z"
        fill="#FDE962"
      />
      <path
        d="M15.4398 22.5498H14.5598V23.4298H15.4398V22.5498Z"
        fill="#FDAE35"
      />
      <path
        d="M16.3199 21.6797H15.4399V22.5597H16.3199V21.6797Z"
        fill="#FDE962"
      />
      <path
        d="M15.4398 21.6797H14.5598V22.5597H15.4398V21.6797Z"
        fill="#FDAE35"
      />
      <path
        d="M16.3199 20.7998H15.4399V21.6798H16.3199V20.7998Z"
        fill="#FDE962"
      />
      <path
        d="M15.4398 20.7998H14.5598V21.6798H15.4398V20.7998Z"
        fill="#FDE962"
      />
      <path
        d="M16.3199 19.9199H15.4399V20.7999H16.3199V19.9199Z"
        fill="#FDE962"
      />
      <path
        d="M15.4398 19.9199H14.5598V20.7999H15.4398V19.9199Z"
        fill="#FDE962"
      />
      <path
        d="M14.5599 20.7998H13.6799V21.6798H14.5599V20.7998Z"
        fill="#FDAE35"
      />
      <path
        d="M14.5599 19.9199H13.6799V20.7999H14.5599V19.9199Z"
        fill="#FDAE35"
      />
      <path d="M16.3199 19.04H15.4399V19.92H16.3199V19.04Z" fill="#EBEFF0" />
      <path d="M15.4398 19.04H14.5598V19.92H15.4398V19.04Z" fill="#FDE962" />
      <path d="M14.5599 19.04H13.6799V19.92H14.5599V19.04Z" fill="#FDE962" />
      <path d="M13.6798 19.04H12.7998V19.92H13.6798V19.04Z" fill="#FDAE35" />
      <path d="M12.8099 19.04H11.9299V19.92H12.8099V19.04Z" fill="#FDAE35" />
      <path
        d="M14.5599 18.1602H13.6799V19.0402H14.5599V18.1602Z"
        fill="#FDE962"
      />
      <path
        d="M13.6798 18.1602H12.7998V19.0402H13.6798V18.1602Z"
        fill="#FDE962"
      />
      <path
        d="M12.8099 18.1602H11.9299V19.0402H12.8099V18.1602Z"
        fill="#FDAE35"
      />
      <path
        d="M11.9298 18.1602H11.0498V19.0402H11.9298V18.1602Z"
        fill="#FDAE35"
      />
      <path
        d="M11.0499 18.1602H10.1699V19.0402H11.0499V18.1602Z"
        fill="#FDAE35"
      />
      <path
        d="M16.3199 18.1602H15.4399V19.0402H16.3199V18.1602Z"
        fill="#EBEFF0"
      />
      <path
        d="M15.4398 18.1602H14.5598V19.0402H15.4398V18.1602Z"
        fill="#FDE962"
      />
      <path
        d="M14.5599 17.2803H13.6799V18.1603H14.5599V17.2803Z"
        fill="#EBEFF0"
      />
      <path
        d="M13.6798 17.2803H12.7998V18.1603H13.6798V17.2803Z"
        fill="#FDE962"
      />
      <path
        d="M12.8099 17.2803H11.9299V18.1603H12.8099V17.2803Z"
        fill="#FDE962"
      />
      <path
        d="M11.9298 17.2803H11.0498V18.1603H11.9298V17.2803Z"
        fill="#FDE962"
      />
      <path
        d="M11.0499 17.2803H10.1699V18.1603H11.0499V17.2803Z"
        fill="#FDAE35"
      />
      <path
        d="M10.1698 17.2803H9.28979V18.1603H10.1698V17.2803Z"
        fill="#FDAE35"
      />
      <path
        d="M9.28991 17.2803H8.40991V18.1603H9.28991V17.2803Z"
        fill="#FDAE35"
      />
      <path
        d="M16.3199 17.2803H15.4399V18.1603H16.3199V17.2803Z"
        fill="#EBEFF0"
      />
      <path
        d="M15.4398 17.2803H14.5598V18.1603H15.4398V17.2803Z"
        fill="#EBEFF0"
      />
      <path
        d="M16.3199 24.3105H15.4399V25.1905H16.3199V24.3105Z"
        fill="#FDAE35"
      />
      <path
        d="M16.3199 25.1904H15.4399V26.0704H16.3199V25.1904Z"
        fill="#FDAE35"
      />
      <path d="M64 56H62V58H64V56Z" fill="black" />
      <path d="M62 56H59V58H62V56Z" fill="black" />
      <path d="M53 39H51V41H53V39Z" fill="black" />
      <path d="M55 39H53V41H55V39Z" fill="black" />
      <path d="M57 39H55V41H57V39Z" fill="black" />
      <path d="M59 39H57V41H59V39Z" fill="black" />
      <path d="M62 39H59V41H62V39Z" fill="black" />
      <path d="M64 39H62V41H64V39Z" fill="black" />
      <path d="M66 39H64V41H66V39Z" fill="black" />
      <path d="M68 39H66V41H68V39Z" fill="black" />
      <path d="M51 41H49V43H51V41Z" fill="black" />
      <path d="M53 41H51V43H53V41Z" fill="#FFD169" />
      <path d="M55 41H53V43H55V41Z" fill="#FFD169" />
      <path d="M57 41H55V43H57V41Z" fill="#FFD169" />
      <path d="M59 41H57V43H59V41Z" fill="#FFD169" />
      <path d="M62 41H59V43H62V41Z" fill="#FFD169" />
      <path d="M64 41H62V43H64V41Z" fill="#FFD169" />
      <path d="M51 43H49V45H51V43Z" fill="black" />
      <path d="M53 43H51V45H53V43Z" fill="#FFD169" />
      <path d="M55 43H53V45H55V43Z" fill="white" />
      <path d="M57 43H55V45H57V43Z" fill="#FFA935" />
      <path d="M59 43H57V45H59V43Z" fill="#EF8845" />
      <path d="M62 43H59V45H62V43Z" fill="#EF8845" />
      <path d="M49 45H47V48H49V45Z" fill="black" />
      <path d="M51 45H49V48H51V45Z" fill="#FFD169" />
      <path d="M53 45H51V48H53V45Z" fill="white" />
      <path d="M55 45H53V48H55V45Z" fill="#FFA935" />
      <path d="M57 45H55V48H57V45Z" fill="#EF8845" />
      <path d="M59 45H57V48H59V45Z" fill="black" />
      <path d="M49 48H47V50H49V48Z" fill="black" />
      <path d="M51 48H49V50H51V48Z" fill="#FFD169" />
      <path d="M53 48H51V50H53V48Z" fill="white" />
      <path d="M55 48H53V50H55V48Z" fill="#FFA935" />
      <path d="M57 48H55V50H57V48Z" fill="black" />
      <path d="M66 41H64V43H66V41Z" fill="#FFD169" />
      <path d="M64 43H62V45H64V43Z" fill="#EF8845" />
      <path d="M66 43H64V45H66V43Z" fill="black" />
      <path d="M62 45H59V48H62V45Z" fill="black" />
      <path d="M64 45H62V48H64V45Z" fill="black" />
      <path d="M66 45H64V48H66V45Z" fill="black" />
      <path d="M68 41H66V43H68V41Z" fill="black" />
      <path d="M51 50H49V52H51V50Z" fill="#FFD169" />
      <path d="M49 50H47V52H49V50Z" fill="#FFD169" />
      <path d="M47 50H45V52H47V50Z" fill="black" />
      <path d="M53 50H51V52H53V50Z" fill="white" />
      <path d="M55 50H53V52H55V50Z" fill="#FFA935" />
      <path d="M57 50H55V52H57V50Z" fill="#EF8845" />
      <path d="M59 50H57V52H59V50Z" fill="black" />
      <path d="M62 50H59V52H62V50Z" fill="black" />
      <path d="M64 50H62V52H64V50Z" fill="black" />
      <path d="M51 52H49V54H51V52Z" fill="#FBC94C" />
      <path d="M49 52H47V54H49V52Z" fill="#FBC94C" />
      <path d="M47 52H45V54H47V52Z" fill="black" />
      <path d="M53 52H51V54H53V52Z" fill="#FBC94C" />
      <path d="M55 52H53V54H55V52Z" fill="#FFA935" />
      <path d="M57 52H55V54H57V52Z" fill="#FFA935" />
      <path d="M59 52H57V54H59V52Z" fill="#EF8845" />
      <path d="M62 52H59V54H62V52Z" fill="#EF8845" />
      <path d="M64 52H62V54H64V52Z" fill="#EF8845" />
      <path d="M66 52H64V54H66V52Z" fill="black" />
      <path d="M51 54H49V56H51V54Z" fill="black" />
      <path d="M49 54H47V56H49V54Z" fill="black" />
      <path d="M47 54H45V56H47V54Z" fill="black" />
      <path d="M53 54H51V56H53V54Z" fill="black" />
      <path d="M55 54H53V56H55V54Z" fill="#FFA935" />
      <path d="M57 54H55V56H57V54Z" fill="#FFA935" />
      <path d="M59 54H57V56H59V54Z" fill="#EF8845" />
      <path d="M62 54H59V56H62V54Z" fill="#EF8845" />
      <path d="M55 56H53V58H55V56Z" fill="#FFA935" />
      <path d="M57 56H55V58H57V56Z" fill="#EF8845" />
      <path d="M55 58H53V60H55V58Z" fill="#EF8845" />
      <path d="M53 58H51V60H53V58Z" fill="black" />
      <path d="M53 56H51V58H53V56Z" fill="black" />
      <path d="M57 58H55V60H57V58Z" fill="black" />
      <path d="M53 60H51V62H53V60Z" fill="#EF8845" />
      <path d="M51 60H49V62H51V60Z" fill="black" />
      <path d="M53 62H51V65H53V62Z" fill="black" />
      <path d="M49 62H47V65H49V62Z" fill="black" />
      <path d="M51 62H49V65H51V62Z" fill="#EF8845" />
      <path d="M49 65H47V67H49V65Z" fill="black" />
      <path d="M51 65H49V67H51V65Z" fill="black" />
      <path d="M55 60H53V62H55V60Z" fill="black" />
      <path d="M59 56H57V58H59V56Z" fill="black" />
      <path d="M64 54H62V56H64V54Z" fill="black" />
      <path
        d="M47.7198 33.5996H45.7998V35.5196H47.7198V33.5996Z"
        fill="black"
      />
    </svg>
  );
};
