export const Trophy = ({ ...props }) => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.79314 22.6562H7.4917V22.9549H7.79314V22.6562Z" fill="#FBDC00" />
    <path
      d="M7.49138 22.9549H7.18994V23.2536H7.49138V22.9549Z"
      fill="#FBDC00"
    />
    <path
      d="M7.49138 23.2536H7.18994V23.5522H7.49138V23.2536Z"
      fill="#FBDC00"
    />
    <path
      d="M7.19012 23.5469H6.88867V23.8456H7.19012V23.5469Z"
      fill="#FBDC00"
    />
    <path
      d="M7.19012 23.8456H6.88867V24.1442H7.19012V23.8456Z"
      fill="#FBDC00"
    />
    <path d="M6.88885 24.1442H6.5874V24.4429H6.88885V24.1442Z" fill="#FBDC00" />
    <path
      d="M6.58709 24.1442H6.28564V24.4429H6.58709V24.1442Z"
      fill="#FBDC00"
    />
    <path
      d="M6.58709 24.4429H6.28564V24.7416H6.58709V24.4429Z"
      fill="#FBDC00"
    />
    <path
      d="M6.29119 24.4429H5.98975V24.7416H6.29119V24.4429Z"
      fill="#FBDC00"
    />
    <path
      d="M5.98992 24.4429H5.68848V24.7416H5.98992V24.4429Z"
      fill="#FBDC00"
    />
    <path
      d="M5.98992 24.7416H5.68848V25.0402H5.98992V24.7416Z"
      fill="#FBDC00"
    />
    <path
      d="M5.68816 24.7416H5.38672V25.0402H5.68816V24.7416Z"
      fill="#FBDC00"
    />
    <path
      d="M5.38689 24.7416H5.08545V25.0402H5.38689V24.7416Z"
      fill="#FBDC00"
    />
    <path d="M7.79314 22.3576H7.4917V22.6562H7.79314V22.3576Z" fill="#FBDC00" />
    <path d="M7.79314 22.0589H7.4917V22.3576H7.79314V22.0589Z" fill="#FBDC00" />
    <path d="M7.48615 22.9549H7.7876V22.6562H7.48615V22.9549Z" fill="#FBDC00" />
    <path
      d="M7.78791 23.2482H8.08936V22.9496H7.78791V23.2482Z"
      fill="#FBDC00"
    />
    <path
      d="M7.78791 23.5469H8.08936V23.2482H7.78791V23.5469Z"
      fill="#FBDC00"
    />
    <path d="M8.09455 23.8509H8.396V23.5522H8.09455V23.8509Z" fill="#FBDC00" />
    <path d="M8.09455 24.1442H8.396V23.8456H8.09455V24.1442Z" fill="#FBDC00" />
    <path
      d="M8.39045 24.4429H8.69189V24.1442H8.39045V24.4429Z"
      fill="#FBDC00"
    />
    <path
      d="M8.69221 24.4429H8.99365V24.1442H8.69221V24.4429Z"
      fill="#FBDC00"
    />
    <path
      d="M8.69221 24.7416H8.99365V24.4429H8.69221V24.7416Z"
      fill="#FBDC00"
    />
    <path
      d="M8.99348 24.7416H9.29492V24.4429H8.99348V24.7416Z"
      fill="#FBDC00"
    />
    <path
      d="M9.28938 24.7416H9.59082V24.4429H9.28938V24.7416Z"
      fill="#FBDC00"
    />
    <path
      d="M9.28938 25.0349H9.59082V24.7362H9.28938V25.0349Z"
      fill="#FBDC00"
    />
    <path
      d="M9.59113 25.0349H9.89258V24.7362H9.59113V25.0349Z"
      fill="#FBDC00"
    />
    <path d="M9.8924 25.0349H10.1938V24.7362H9.8924V25.0349Z" fill="#FBDC00" />
    <path d="M7.48615 22.6562H7.7876V22.3576H7.48615V22.6562Z" fill="#FBDC00" />
    <path d="M7.48615 22.3576H7.7876V22.0589H7.48615V22.3576Z" fill="#FBDC00" />
    <path d="M7.48615 27.1256H7.7876V26.8269H7.48615V27.1256Z" fill="#FBDC00" />
    <path
      d="M7.78791 26.8269H8.08936V26.5282H7.78791V26.8269Z"
      fill="#FBDC00"
    />
    <path
      d="M7.78791 26.5336H8.08936V26.2349H7.78791V26.5336Z"
      fill="#FBDC00"
    />
    <path d="M8.09455 26.2296H8.396V25.9309H8.09455V26.2296Z" fill="#FBDC00" />
    <path d="M8.09455 25.9362H8.396V25.6376H8.09455V25.9362Z" fill="#FBDC00" />
    <path
      d="M8.39045 25.6376H8.69189V25.3389H8.39045V25.6376Z"
      fill="#FBDC00"
    />
    <path
      d="M8.69221 25.6376H8.99365V25.3389H8.69221V25.6376Z"
      fill="#FBDC00"
    />
    <path
      d="M8.69221 25.3389H8.99365V25.0402H8.69221V25.3389Z"
      fill="#FBDC00"
    />
    <path
      d="M8.99348 25.3389H9.29492V25.0402H8.99348V25.3389Z"
      fill="#FBDC00"
    />
    <path
      d="M9.28938 25.3389H9.59082V25.0402H9.28938V25.3389Z"
      fill="#FBDC00"
    />
    <path
      d="M9.28938 25.0456H9.59082V24.7469H9.28938V25.0456Z"
      fill="#FBDC00"
    />
    <path
      d="M9.59113 25.0456H9.89258V24.7469H9.59113V25.0456Z"
      fill="#FBDC00"
    />
    <path d="M9.8924 25.0456H10.1938V24.7469H9.8924V25.0456Z" fill="#FBDC00" />
    <path d="M7.48615 27.4242H7.7876V27.1256H7.48615V27.4242Z" fill="#FBDC00" />
    <path d="M7.48615 27.7176H7.7876V27.4189H7.48615V27.7176Z" fill="#FBDC00" />
    <path d="M7.79314 26.8269H7.4917V27.1256H7.79314V26.8269Z" fill="#FBDC00" />
    <path
      d="M7.49138 26.5282H7.18994V26.8269H7.49138V26.5282Z"
      fill="#FBDC00"
    />
    <path
      d="M7.49138 26.2296H7.18994V26.5283H7.49138V26.2296Z"
      fill="#FBDC00"
    />
    <path
      d="M7.19012 25.9362H6.88867V26.2349H7.19012V25.9362Z"
      fill="#FBDC00"
    />
    <path
      d="M7.19012 25.6376H6.88867V25.9362H7.19012V25.6376Z"
      fill="#FBDC00"
    />
    <path d="M6.88885 25.3389H6.5874V25.6376H6.88885V25.3389Z" fill="#FBDC00" />
    <path
      d="M6.58709 25.3389H6.28564V25.6376H6.58709V25.3389Z"
      fill="#FBDC00"
    />
    <path
      d="M6.58709 25.0402H6.28564V25.3389H6.58709V25.0402Z"
      fill="#FBDC00"
    />
    <path
      d="M6.29119 25.0402H5.98975V25.3389H6.29119V25.0402Z"
      fill="#FBDC00"
    />
    <path
      d="M5.98992 25.0402H5.68848V25.3389H5.98992V25.0402Z"
      fill="#FBDC00"
    />
    <path
      d="M5.98992 24.7416H5.68848V25.0402H5.98992V24.7416Z"
      fill="#FBDC00"
    />
    <path
      d="M5.68816 24.7416H5.38672V25.0402H5.68816V24.7416Z"
      fill="#FBDC00"
    />
    <path
      d="M5.38689 24.7416H5.08545V25.0402H5.38689V24.7416Z"
      fill="#FBDC00"
    />
    <path d="M7.79314 27.1256H7.4917V27.4242H7.79314V27.1256Z" fill="#FBDC00" />
    <path d="M7.79314 27.4242H7.4917V27.7229H7.79314V27.4242Z" fill="#FBDC00" />
    <path
      d="M36.1501 2.23492H35.8164V2.56559H36.1501V2.23492Z"
      fill="#FBDC00"
    />
    <path
      d="M35.8167 2.56555H35.4829V2.89622H35.8167V2.56555Z"
      fill="#FBDC00"
    />
    <path
      d="M35.8167 2.89624H35.4829V3.22691H35.8167V2.89624Z"
      fill="#FBDC00"
    />
    <path d="M35.4827 3.22693H35.1489V3.5576H35.4827V3.22693Z" fill="#FBDC00" />
    <path
      d="M35.4827 3.55756H35.1489V3.88822H35.4827V3.55756Z"
      fill="#FBDC00"
    />
    <path
      d="M35.1492 3.88824H34.8154V4.21891H35.1492V3.88824Z"
      fill="#FBDC00"
    />
    <path
      d="M34.8152 3.88824H34.4814V4.21891H34.8152V3.88824Z"
      fill="#FBDC00"
    />
    <path d="M34.8152 4.21893H34.4814V4.5496H34.8152V4.21893Z" fill="#FBDC00" />
    <path d="M34.4817 4.21893H34.1479V4.5496H34.4817V4.21893Z" fill="#FBDC00" />
    <path d="M34.1477 4.21893H33.814V4.5496H34.1477V4.21893Z" fill="#FBDC00" />
    <path d="M34.1477 4.54956H33.814V4.88023H34.1477V4.54956Z" fill="#FBDC00" />
    <path
      d="M33.8142 4.54956H33.4805V4.88023H33.8142V4.54956Z"
      fill="#FBDC00"
    />
    <path
      d="M33.4802 4.54956H33.1465V4.88023H33.4802V4.54956Z"
      fill="#FBDC00"
    />
    <path d="M36.1501 1.90424H35.8164V2.2349H36.1501V1.90424Z" fill="#FBDC00" />
    <path
      d="M36.1501 1.57355H35.8164V1.90421H36.1501V1.57355Z"
      fill="#FBDC00"
    />
    <path
      d="M35.8167 2.56024H36.1504V2.22957H35.8167V2.56024Z"
      fill="#FBDC00"
    />
    <path
      d="M36.1501 2.89093H36.4839V2.56026H36.1501V2.89093Z"
      fill="#FBDC00"
    />
    <path
      d="M36.1501 3.22156H36.4839V2.89089H36.1501V3.22156Z"
      fill="#FBDC00"
    />
    <path
      d="M36.4841 3.55225H36.8179V3.22158H36.4841V3.55225Z"
      fill="#FBDC00"
    />
    <path
      d="M36.4841 3.88287H36.8179V3.55221H36.4841V3.88287Z"
      fill="#FBDC00"
    />
    <path d="M36.8176 4.21356H37.1514V3.8829H36.8176V4.21356Z" fill="#FBDC00" />
    <path d="M37.1516 4.21356H37.4854V3.8829H37.1516V4.21356Z" fill="#FBDC00" />
    <path
      d="M37.1516 4.54425H37.4854V4.21358H37.1516V4.54425Z"
      fill="#FBDC00"
    />
    <path
      d="M37.4851 4.54425H37.8188V4.21358H37.4851V4.54425Z"
      fill="#FBDC00"
    />
    <path
      d="M37.8191 4.54425H38.1528V4.21358H37.8191V4.54425Z"
      fill="#FBDC00"
    />
    <path
      d="M37.8191 4.87488H38.1528V4.54421H37.8191V4.87488Z"
      fill="#FBDC00"
    />
    <path
      d="M38.1526 4.87488H38.4863V4.54421H38.1526V4.87488Z"
      fill="#FBDC00"
    />
    <path
      d="M38.4866 4.87488H38.8203V4.54421H38.4866V4.87488Z"
      fill="#FBDC00"
    />
    <path
      d="M35.8167 2.22955H36.1504V1.89889H35.8167V2.22955Z"
      fill="#FBDC00"
    />
    <path
      d="M35.8167 1.89893H36.1504V1.56826H35.8167V1.89893Z"
      fill="#FBDC00"
    />
    <path
      d="M35.8167 7.20026H36.1504V6.86959H35.8167V7.20026Z"
      fill="#FBDC00"
    />
    <path d="M36.1501 6.86957H36.4839V6.5389H36.1501V6.86957Z" fill="#FBDC00" />
    <path
      d="M36.1501 6.53888H36.4839V6.20821H36.1501V6.53888Z"
      fill="#FBDC00"
    />
    <path
      d="M36.4841 6.20825H36.8179V5.87759H36.4841V6.20825Z"
      fill="#FBDC00"
    />
    <path d="M36.4841 5.87756H36.8179V5.5469H36.4841V5.87756Z" fill="#FBDC00" />
    <path
      d="M36.8176 5.54688H37.1514V5.21621H36.8176V5.54688Z"
      fill="#FBDC00"
    />
    <path
      d="M37.1516 5.54688H37.4854V5.21621H37.1516V5.54688Z"
      fill="#FBDC00"
    />
    <path
      d="M37.1516 5.21625H37.4854V4.88558H37.1516V5.21625Z"
      fill="#FBDC00"
    />
    <path
      d="M37.4851 5.21625H37.8188V4.88558H37.4851V5.21625Z"
      fill="#FBDC00"
    />
    <path
      d="M37.8191 5.21625H38.1528V4.88558H37.8191V5.21625Z"
      fill="#FBDC00"
    />
    <path
      d="M37.8191 4.88556H38.1528V4.55489H37.8191V4.88556Z"
      fill="#FBDC00"
    />
    <path
      d="M38.1526 4.88556H38.4863V4.55489H38.1526V4.88556Z"
      fill="#FBDC00"
    />
    <path
      d="M38.4866 4.88556H38.8203V4.55489H38.4866V4.88556Z"
      fill="#FBDC00"
    />
    <path
      d="M35.8167 7.53088H36.1504V7.20022H35.8167V7.53088Z"
      fill="#FBDC00"
    />
    <path
      d="M35.8167 7.86157H36.1504V7.53091H35.8167V7.86157Z"
      fill="#FBDC00"
    />
    <path
      d="M36.1501 6.86426H35.8164V7.19492H36.1501V6.86426Z"
      fill="#FBDC00"
    />
    <path
      d="M35.8167 6.53357H35.4829V6.86424H35.8167V6.53357Z"
      fill="#FBDC00"
    />
    <path
      d="M35.8167 6.20288H35.4829V6.53355H35.8167V6.20288Z"
      fill="#FBDC00"
    />
    <path
      d="M35.4827 5.87225H35.1489V6.20292H35.4827V5.87225Z"
      fill="#FBDC00"
    />
    <path
      d="M35.4827 5.54156H35.1489V5.87223H35.4827V5.54156Z"
      fill="#FBDC00"
    />
    <path
      d="M35.1492 5.21088H34.8154V5.54154H35.1492V5.21088Z"
      fill="#FBDC00"
    />
    <path
      d="M34.8152 5.21088H34.4814V5.54154H34.8152V5.21088Z"
      fill="#FBDC00"
    />
    <path
      d="M34.8152 4.88025H34.4814V5.21092H34.8152V4.88025Z"
      fill="#FBDC00"
    />
    <path
      d="M34.4817 4.88025H34.1479V5.21092H34.4817V4.88025Z"
      fill="#FBDC00"
    />
    <path d="M34.1477 4.88025H33.814V5.21092H34.1477V4.88025Z" fill="#FBDC00" />
    <path d="M34.1477 4.54956H33.814V4.88023H34.1477V4.54956Z" fill="#FBDC00" />
    <path
      d="M33.8142 4.54956H33.4805V4.88023H33.8142V4.54956Z"
      fill="#FBDC00"
    />
    <path
      d="M33.4802 4.54956H33.1465V4.88023H33.4802V4.54956Z"
      fill="#FBDC00"
    />
    <path
      d="M36.1501 7.19489H35.8164V7.52555H36.1501V7.19489Z"
      fill="#FBDC00"
    />
    <path
      d="M36.1501 7.52557H35.8164V7.85624H36.1501V7.52557Z"
      fill="#FBDC00"
    />
    <path d="M10.016 28.8802H9.83838V29.0562H10.016V28.8802Z" fill="#FBDC00" />
    <path d="M9.84414 29.0562H9.6665V29.2322H9.84414V29.0562Z" fill="#FBDC00" />
    <path d="M9.84414 29.2269H9.6665V29.4029H9.84414V29.2269Z" fill="#FBDC00" />
    <path
      d="M9.66641 29.4029H9.48877V29.5789H9.66641V29.4029Z"
      fill="#FBDC00"
    />
    <path
      d="M9.66641 29.5736H9.48877V29.7496H9.66641V29.5736Z"
      fill="#FBDC00"
    />
    <path
      d="M9.48867 29.7496H9.31104V29.9256H9.48867V29.7496Z"
      fill="#FBDC00"
    />
    <path
      d="M9.31631 29.7496H9.13867V29.9256H9.31631V29.7496Z"
      fill="#FBDC00"
    />
    <path
      d="M9.31631 29.9256H9.13867V30.1016H9.31631V29.9256Z"
      fill="#FBDC00"
    />
    <path
      d="M9.13857 29.9256H8.96094V30.1016H9.13857V29.9256Z"
      fill="#FBDC00"
    />
    <path d="M8.9667 29.9256H8.78906V30.1016H8.9667V29.9256Z" fill="#FBDC00" />
    <path d="M8.9667 30.0963H8.78906V30.2723H8.9667V30.0963Z" fill="#FBDC00" />
    <path
      d="M8.78897 30.0963H8.61133V30.2723H8.78897V30.0963Z"
      fill="#FBDC00"
    />
    <path
      d="M8.61123 30.0963H8.43359V30.2723H8.61123V30.0963Z"
      fill="#FBDC00"
    />
    <path d="M10.016 28.7042H9.83838V28.8802H10.016V28.7042Z" fill="#FBDC00" />
    <path d="M10.016 28.5336H9.83838V28.7096H10.016V28.5336Z" fill="#FBDC00" />
    <path
      d="M9.83848 29.0562H10.0161V28.8802H9.83848V29.0562Z"
      fill="#FBDC00"
    />
    <path
      d="M10.0108 29.2269H10.1885V29.0509H10.0108V29.2269Z"
      fill="#FBDC00"
    />
    <path
      d="M10.0108 29.4029H10.1885V29.2269H10.0108V29.4029Z"
      fill="#FBDC00"
    />
    <path
      d="M10.1939 29.5736H10.3716V29.3976H10.1939V29.5736Z"
      fill="#FBDC00"
    />
    <path
      d="M10.1939 29.7549H10.3716V29.5789H10.1939V29.7549Z"
      fill="#FBDC00"
    />
    <path
      d="M10.3663 29.9256H10.5439V29.7496H10.3663V29.9256Z"
      fill="#FBDC00"
    />
    <path
      d="M10.5382 29.9256H10.7158V29.7496H10.5382V29.9256Z"
      fill="#FBDC00"
    />
    <path
      d="M10.5382 30.0963H10.7158V29.9203H10.5382V30.0963Z"
      fill="#FBDC00"
    />
    <path
      d="M10.7159 30.0963H10.8936V29.9203H10.7159V30.0963Z"
      fill="#FBDC00"
    />
    <path
      d="M10.8883 30.0963H11.0659V29.9203H10.8883V30.0963Z"
      fill="#FBDC00"
    />
    <path
      d="M10.8883 30.2722H11.0659V30.0962H10.8883V30.2722Z"
      fill="#FBDC00"
    />
    <path d="M11.066 30.2722H11.2437V30.0962H11.066V30.2722Z" fill="#FBDC00" />
    <path
      d="M11.2438 30.2722H11.4214V30.0962H11.2438V30.2722Z"
      fill="#FBDC00"
    />
    <path
      d="M9.83848 28.8802H10.0161V28.7042H9.83848V28.8802Z"
      fill="#FBDC00"
    />
    <path
      d="M9.83848 28.7042H10.0161V28.5282H9.83848V28.7042Z"
      fill="#FBDC00"
    />
    <path
      d="M9.83848 31.4936H10.0161V31.3176H9.83848V31.4936Z"
      fill="#FBDC00"
    />
    <path
      d="M10.0108 31.3176H10.1885V31.1416H10.0108V31.3176Z"
      fill="#FBDC00"
    />
    <path
      d="M10.0108 31.1416H10.1885V30.9656H10.0108V31.1416Z"
      fill="#FBDC00"
    />
    <path
      d="M10.1939 30.9709H10.3716V30.7949H10.1939V30.9709Z"
      fill="#FBDC00"
    />
    <path
      d="M10.1939 30.8002H10.3716V30.6242H10.1939V30.8002Z"
      fill="#FBDC00"
    />
    <path
      d="M10.3663 30.6189H10.5439V30.4429H10.3663V30.6189Z"
      fill="#FBDC00"
    />
    <path
      d="M10.5382 30.6189H10.7158V30.4429H10.5382V30.6189Z"
      fill="#FBDC00"
    />
    <path
      d="M10.5382 30.4482H10.7158V30.2722H10.5382V30.4482Z"
      fill="#FBDC00"
    />
    <path
      d="M10.7159 30.4482H10.8936V30.2722H10.7159V30.4482Z"
      fill="#FBDC00"
    />
    <path
      d="M10.8883 30.4482H11.0659V30.2722H10.8883V30.4482Z"
      fill="#FBDC00"
    />
    <path
      d="M10.8883 30.2722H11.0659V30.0962H10.8883V30.2722Z"
      fill="#FBDC00"
    />
    <path d="M11.066 30.2722H11.2437V30.0962H11.066V30.2722Z" fill="#FBDC00" />
    <path
      d="M11.2438 30.2722H11.4214V30.0962H11.2438V30.2722Z"
      fill="#FBDC00"
    />
    <path
      d="M9.83848 31.6642H10.0161V31.4882H9.83848V31.6642Z"
      fill="#FBDC00"
    />
    <path
      d="M9.83848 31.8402H10.0161V31.6642H9.83848V31.8402Z"
      fill="#FBDC00"
    />
    <path d="M10.016 31.3176H9.83838V31.4936H10.016V31.3176Z" fill="#FBDC00" />
    <path d="M9.84414 31.1416H9.6665V31.3176H9.84414V31.1416Z" fill="#FBDC00" />
    <path d="M9.84414 30.9709H9.6665V31.1469H9.84414V30.9709Z" fill="#FBDC00" />
    <path
      d="M9.66641 30.7949H9.48877V30.9709H9.66641V30.7949Z"
      fill="#FBDC00"
    />
    <path
      d="M9.66641 30.6189H9.48877V30.7949H9.66641V30.6189Z"
      fill="#FBDC00"
    />
    <path
      d="M9.48867 30.4482H9.31104V30.6242H9.48867V30.4482Z"
      fill="#FBDC00"
    />
    <path
      d="M9.31631 30.4482H9.13867V30.6242H9.31631V30.4482Z"
      fill="#FBDC00"
    />
    <path
      d="M9.31631 30.2722H9.13867V30.4482H9.31631V30.2722Z"
      fill="#FBDC00"
    />
    <path
      d="M9.13857 30.2722H8.96094V30.4482H9.13857V30.2722Z"
      fill="#FBDC00"
    />
    <path d="M8.9667 30.2722H8.78906V30.4482H8.9667V30.2722Z" fill="#FBDC00" />
    <path d="M8.9667 30.1016H8.78906V30.2776H8.9667V30.1016Z" fill="#FBDC00" />
    <path
      d="M8.78897 30.1016H8.61133V30.2776H8.78897V30.1016Z"
      fill="#FBDC00"
    />
    <path
      d="M8.61123 30.1016H8.43359V30.2776H8.61123V30.1016Z"
      fill="#FBDC00"
    />
    <path d="M10.016 31.4936H9.83838V31.6696H10.016V31.4936Z" fill="#FBDC00" />
    <path d="M10.016 31.6642H9.83838V31.8402H10.016V31.6642Z" fill="#FBDC00" />
    <path d="M31.9784 7.34424H32.9258V6.40557H31.9784V7.34424Z" fill="black" />
    <path d="M31.0311 7.34424H31.9785V6.40557H31.0311V7.34424Z" fill="black" />
    <path d="M30.0892 7.34424H31.0366V6.40557H30.0892V7.34424Z" fill="black" />
    <path d="M29.1366 7.34424H30.084V6.40557H29.1366V7.34424Z" fill="#D39C00" />
    <path
      d="M28.1888 7.34424H29.1362V6.40557H28.1888V7.34424Z"
      fill="#D39C00"
    />
    <path
      d="M27.2469 7.34424H28.1943V6.40557H27.2469V7.34424Z"
      fill="#F2BA02"
    />
    <path
      d="M26.2997 7.34424H27.2471V6.40557H26.2997V7.34424Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 7.34424H26.2998V6.40557H25.3524V7.34424Z"
      fill="#F2BA02"
    />
    <path d="M24.41 7.34424H25.3574V6.40557H24.41V7.34424Z" fill="#D39C00" />
    <path
      d="M23.4574 7.34424H24.4048V6.40557H23.4574V7.34424Z"
      fill="#D39C00"
    />
    <path d="M29.1366 6.40021H30.084V5.46154H29.1366V6.40021Z" fill="black" />
    <path
      d="M28.1888 6.40021H29.1362V5.46154H28.1888V6.40021Z"
      fill="#D39C00"
    />
    <path
      d="M27.2469 6.40021H28.1943V5.46154H27.2469V6.40021Z"
      fill="#D39C00"
    />
    <path
      d="M26.2997 6.40021H27.2471V5.46154H26.2997V6.40021Z"
      fill="#D39C00"
    />
    <path
      d="M25.3524 6.40021H26.2998V5.46154H25.3524V6.40021Z"
      fill="#D39C00"
    />
    <path d="M24.41 6.40021H25.3574V5.46154H24.41V6.40021Z" fill="#D39C00" />
    <path d="M23.4574 6.40021H24.4048V5.46154H23.4574V6.40021Z" fill="black" />
    <path d="M22.5101 7.34424H23.4575V6.40557H22.5101V7.34424Z" fill="black" />
    <path d="M21.5677 7.34424H22.5151V6.40557H21.5677V7.34424Z" fill="black" />
    <path d="M20.6205 7.34424H21.5679V6.40557H20.6205V7.34424Z" fill="black" />
    <path d="M19.6732 7.34424H20.6206V6.40557H19.6732V7.34424Z" fill="black" />
    <path d="M18.7259 7.34424H19.6733V6.40557H18.7259V7.34424Z" fill="black" />
    <path d="M17.7836 7.34424H18.731V6.40557H17.7836V7.34424Z" fill="black" />
    <path
      d="M16.8309 7.34424H17.7783V6.40557H16.8309V7.34424Z"
      fill="#D39C00"
    />
    <path
      d="M15.8837 7.34424H16.8311V6.40557H15.8837V7.34424Z"
      fill="#FFD900"
    />
    <path
      d="M14.9418 7.34424H15.8892V6.40557H14.9418V7.34424Z"
      fill="#FFD900"
    />
    <path d="M13.994 7.34424H14.9414V6.40557H13.994V7.34424Z" fill="#FFD900" />
    <path
      d="M13.0467 7.34424H13.9941V6.40557H13.0467V7.34424Z"
      fill="#FFD900"
    />
    <path
      d="M12.1049 7.34424H13.0522V6.40557H12.1049V7.34424Z"
      fill="#D39C00"
    />
    <path
      d="M11.1522 7.34424H12.0996V6.40557H11.1522V7.34424Z"
      fill="#D39C00"
    />
    <path d="M16.8309 6.40021H17.7783V5.46154H16.8309V6.40021Z" fill="black" />
    <path
      d="M15.8837 6.40021H16.8311V5.46154H15.8837V6.40021Z"
      fill="#D39C00"
    />
    <path
      d="M14.9418 6.40021H15.8892V5.46154H14.9418V6.40021Z"
      fill="#D39C00"
    />
    <path d="M13.994 6.40021H14.9414V5.46154H13.994V6.40021Z" fill="#D39C00" />
    <path
      d="M13.0467 6.40021H13.9941V5.46154H13.0467V6.40021Z"
      fill="#D39C00"
    />
    <path
      d="M12.1049 6.40021H13.0522V5.46154H12.1049V6.40021Z"
      fill="#D39C00"
    />
    <path d="M28.1888 5.46155H29.1362V4.52288H28.1888V5.46155Z" fill="black" />
    <path d="M27.2469 5.46155H28.1943V4.52288H27.2469V5.46155Z" fill="black" />
    <path d="M26.2997 5.46155H27.2471V4.52288H26.2997V5.46155Z" fill="black" />
    <path d="M25.3524 5.46155H26.2998V4.52288H25.3524V5.46155Z" fill="black" />
    <path d="M24.41 5.46155H25.3574V4.52288H24.41V5.46155Z" fill="black" />
    <path d="M15.8837 5.46155H16.8311V4.52288H15.8837V5.46155Z" fill="black" />
    <path d="M14.9418 5.46155H15.8892V4.52288H14.9418V5.46155Z" fill="black" />
    <path d="M13.994 5.46155H14.9414V4.52288H13.994V5.46155Z" fill="black" />
    <path d="M13.0467 5.46155H13.9941V4.52288H13.0467V5.46155Z" fill="black" />
    <path d="M12.1049 5.46155H13.0522V4.52288H12.1049V5.46155Z" fill="black" />
    <path d="M11.1522 6.40021H12.0996V5.46154H11.1522V6.40021Z" fill="black" />
    <path d="M10.2045 7.34424H11.1519V6.40557H10.2045V7.34424Z" fill="black" />
    <path d="M9.26257 7.34424H10.21V6.40557H9.26257V7.34424Z" fill="black" />
    <path d="M8.3153 7.34424H9.2627V6.40557H8.3153V7.34424Z" fill="black" />
    <path d="M32.9261 8.27759H33.8735V7.33892H32.9261V8.27759Z" fill="black" />
    <path
      d="M31.9784 8.27759H32.9258V7.33892H31.9784V8.27759Z"
      fill="#D39C00"
    />
    <path
      d="M31.0311 8.27759H31.9785V7.33892H31.0311V8.27759Z"
      fill="#D39C00"
    />
    <path
      d="M30.0892 8.27759H31.0366V7.33892H30.0892V8.27759Z"
      fill="#D39C00"
    />
    <path d="M29.1366 8.27759H30.084V7.33892H29.1366V8.27759Z" fill="#FFD900" />
    <path
      d="M28.1888 8.27759H29.1362V7.33892H28.1888V8.27759Z"
      fill="#D39C00"
    />
    <path
      d="M27.2469 8.27759H28.1943V7.33892H27.2469V8.27759Z"
      fill="#F2BA02"
    />
    <path
      d="M26.2997 8.27759H27.2471V7.33892H26.2997V8.27759Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 8.27759H26.2998V7.33892H25.3524V8.27759Z"
      fill="#F2BA02"
    />
    <path d="M24.41 8.27759H25.3574V7.33892H24.41V8.27759Z" fill="#F2BA02" />
    <path
      d="M23.4574 8.27759H24.4048V7.33892H23.4574V8.27759Z"
      fill="#FFD900"
    />
    <path
      d="M22.5101 8.27759H23.4575V7.33892H22.5101V8.27759Z"
      fill="#FFD900"
    />
    <path
      d="M21.5677 8.27759H22.5151V7.33892H21.5677V8.27759Z"
      fill="#FFD900"
    />
    <path
      d="M20.6205 8.27759H21.5679V7.33892H20.6205V8.27759Z"
      fill="#FFD900"
    />
    <path
      d="M19.6732 8.27759H20.6206V7.33892H19.6732V8.27759Z"
      fill="#FFD900"
    />
    <path
      d="M18.7259 8.27759H19.6733V7.33892H18.7259V8.27759Z"
      fill="#FFD900"
    />
    <path d="M17.7836 8.27759H18.731V7.33892H17.7836V8.27759Z" fill="#FFD900" />
    <path
      d="M16.8309 8.27759H17.7783V7.33892H16.8309V8.27759Z"
      fill="#FFD900"
    />
    <path
      d="M15.8837 8.27759H16.8311V7.33892H15.8837V8.27759Z"
      fill="#FFD900"
    />
    <path
      d="M14.9418 8.27759H15.8892V7.33892H14.9418V8.27759Z"
      fill="#FFD900"
    />
    <path d="M13.994 8.27759H14.9414V7.33892H13.994V8.27759Z" fill="#FFD900" />
    <path
      d="M13.0467 8.27759H13.9941V7.33892H13.0467V8.27759Z"
      fill="#FFD900"
    />
    <path
      d="M12.1049 8.27759H13.0522V7.33892H12.1049V8.27759Z"
      fill="#D39C00"
    />
    <path
      d="M11.1522 8.27759H12.0996V7.33892H11.1522V8.27759Z"
      fill="#FFFBF0"
    />
    <path
      d="M10.2045 8.27759H11.1519V7.33892H10.2045V8.27759Z"
      fill="#FFFBF0"
    />
    <path d="M9.26257 8.27759H10.21V7.33892H9.26257V8.27759Z" fill="#FFD900" />
    <path d="M8.3153 8.27759H9.2627V7.33892H8.3153V8.27759Z" fill="#D39C00" />
    <path d="M7.36803 8.27759H8.31543V7.33892H7.36803V8.27759Z" fill="black" />
    <path d="M32.9261 9.21625H33.8735V8.27758H32.9261V9.21625Z" fill="black" />
    <path
      d="M31.9784 9.21625H32.9258V8.27758H31.9784V9.21625Z"
      fill="#D39C00"
    />
    <path
      d="M31.0311 9.21625H31.9785V8.27758H31.0311V9.21625Z"
      fill="#FFD900"
    />
    <path
      d="M30.0892 9.21625H31.0366V8.27758H30.0892V9.21625Z"
      fill="#FFD900"
    />
    <path d="M29.1366 9.21625H30.084V8.27758H29.1366V9.21625Z" fill="#FFD900" />
    <path
      d="M28.1888 9.21625H29.1362V8.27758H28.1888V9.21625Z"
      fill="#D39C00"
    />
    <path
      d="M27.2469 9.21625H28.1943V8.27758H27.2469V9.21625Z"
      fill="#F2BA02"
    />
    <path
      d="M26.2997 9.21625H27.2471V8.27758H26.2997V9.21625Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 9.21625H26.2998V8.27758H25.3524V9.21625Z"
      fill="#F2BA02"
    />
    <path d="M24.41 9.21625H25.3574V8.27758H24.41V9.21625Z" fill="#F2BA02" />
    <path
      d="M23.4574 9.21625H24.4048V8.27758H23.4574V9.21625Z"
      fill="#FFD900"
    />
    <path
      d="M22.5101 9.21625H23.4575V8.27758H22.5101V9.21625Z"
      fill="#FFD900"
    />
    <path
      d="M21.5677 9.21625H22.5151V8.27758H21.5677V9.21625Z"
      fill="#FFD900"
    />
    <path
      d="M20.6205 9.21625H21.5679V8.27758H20.6205V9.21625Z"
      fill="#FFD900"
    />
    <path
      d="M19.6732 9.21625H20.6206V8.27758H19.6732V9.21625Z"
      fill="#FFD900"
    />
    <path
      d="M18.7259 9.21625H19.6733V8.27758H18.7259V9.21625Z"
      fill="#FFD900"
    />
    <path d="M17.7836 9.21625H18.731V8.27758H17.7836V9.21625Z" fill="#FFD900" />
    <path
      d="M16.8309 9.21625H17.7783V8.27758H16.8309V9.21625Z"
      fill="#FFD900"
    />
    <path
      d="M15.8837 9.21625H16.8311V8.27758H15.8837V9.21625Z"
      fill="#FFD900"
    />
    <path
      d="M14.9418 9.21625H15.8892V8.27758H14.9418V9.21625Z"
      fill="#FFD900"
    />
    <path d="M13.994 9.21625H14.9414V8.27758H13.994V9.21625Z" fill="#FFD900" />
    <path
      d="M13.0467 9.21625H13.9941V8.27758H13.0467V9.21625Z"
      fill="#FFD900"
    />
    <path
      d="M12.1049 9.21625H13.0522V8.27758H12.1049V9.21625Z"
      fill="#D39C00"
    />
    <path
      d="M11.1522 9.21625H12.0996V8.27758H11.1522V9.21625Z"
      fill="#FFD900"
    />
    <path
      d="M10.2045 9.21625H11.1519V8.27758H10.2045V9.21625Z"
      fill="#FFD900"
    />
    <path d="M9.26257 9.21625H10.21V8.27758H9.26257V9.21625Z" fill="#FFFBF0" />
    <path d="M8.3153 9.21625H9.2627V8.27758H8.3153V9.21625Z" fill="#D39C00" />
    <path d="M7.36803 9.21625H8.31543V8.27758H7.36803V9.21625Z" fill="black" />
    <path d="M32.9261 10.1549H33.8735V9.21624H32.9261V10.1549Z" fill="black" />
    <path
      d="M31.9784 10.1549H32.9258V9.21624H31.9784V10.1549Z"
      fill="#D39C00"
    />
    <path
      d="M31.0311 10.1549H31.9785V9.21624H31.0311V10.1549Z"
      fill="#FFD900"
    />
    <path
      d="M30.0892 10.1549H31.0366V9.21624H30.0892V10.1549Z"
      fill="#FFD900"
    />
    <path d="M29.1366 10.1549H30.084V9.21624H29.1366V10.1549Z" fill="black" />
    <path
      d="M28.1888 10.1549H29.1362V9.21624H28.1888V10.1549Z"
      fill="#D39C00"
    />
    <path
      d="M27.2469 10.1549H28.1943V9.21624H27.2469V10.1549Z"
      fill="#F2BA02"
    />
    <path
      d="M26.2997 10.1549H27.2471V9.21624H26.2997V10.1549Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 10.1549H26.2998V9.21624H25.3524V10.1549Z"
      fill="#F2BA02"
    />
    <path d="M24.41 10.1549H25.3574V9.21624H24.41V10.1549Z" fill="#F2BA02" />
    <path
      d="M23.4574 10.1549H24.4048V9.21624H23.4574V10.1549Z"
      fill="#FFD900"
    />
    <path
      d="M22.5101 10.1549H23.4575V9.21624H22.5101V10.1549Z"
      fill="#FFD900"
    />
    <path
      d="M21.5677 10.1549H22.5151V9.21624H21.5677V10.1549Z"
      fill="#FFD900"
    />
    <path
      d="M20.6205 10.1549H21.5679V9.21624H20.6205V10.1549Z"
      fill="#FFD900"
    />
    <path
      d="M19.6732 10.1549H20.6206V9.21624H19.6732V10.1549Z"
      fill="#FFD900"
    />
    <path
      d="M18.7259 10.1549H19.6733V9.21624H18.7259V10.1549Z"
      fill="#FFD900"
    />
    <path d="M17.7836 10.1549H18.731V9.21624H17.7836V10.1549Z" fill="#FFD900" />
    <path
      d="M16.8309 10.1549H17.7783V9.21624H16.8309V10.1549Z"
      fill="#FFD900"
    />
    <path
      d="M15.8837 10.1549H16.8311V9.21624H15.8837V10.1549Z"
      fill="#FFD900"
    />
    <path
      d="M14.9418 10.1549H15.8892V9.21624H14.9418V10.1549Z"
      fill="#FFD900"
    />
    <path d="M13.994 10.1549H14.9414V9.21624H13.994V10.1549Z" fill="#FFD900" />
    <path
      d="M13.0467 10.1549H13.9941V9.21624H13.0467V10.1549Z"
      fill="#FFD900"
    />
    <path
      d="M12.1049 10.1549H13.0522V9.21624H12.1049V10.1549Z"
      fill="#D39C00"
    />
    <path d="M11.1522 10.1549H12.0996V9.21624H11.1522V10.1549Z" fill="black" />
    <path
      d="M10.2045 10.1549H11.1519V9.21624H10.2045V10.1549Z"
      fill="#FFD900"
    />
    <path d="M9.26257 10.1549H10.21V9.21624H9.26257V10.1549Z" fill="#FFFBF0" />
    <path d="M8.3153 10.1549H9.2627V9.21624H8.3153V10.1549Z" fill="#D39C00" />
    <path d="M7.36803 10.1549H8.31543V9.21624H7.36803V10.1549Z" fill="black" />
    <path d="M32.9261 11.0883H33.8735V10.1496H32.9261V11.0883Z" fill="black" />
    <path
      d="M31.9784 11.0883H32.9258V10.1496H31.9784V11.0883Z"
      fill="#D39C00"
    />
    <path
      d="M31.0311 11.0883H31.9785V10.1496H31.0311V11.0883Z"
      fill="#FFD900"
    />
    <path d="M30.0892 11.0883H31.0366V10.1496H30.0892V11.0883Z" fill="black" />
    <path d="M28.1888 11.0883H29.1362V10.1496H28.1888V11.0883Z" fill="black" />
    <path
      d="M27.2469 11.0883H28.1943V10.1496H27.2469V11.0883Z"
      fill="#F2BA02"
    />
    <path
      d="M26.2997 11.0883H27.2471V10.1496H26.2997V11.0883Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 11.0883H26.2998V10.1496H25.3524V11.0883Z"
      fill="#F2BA02"
    />
    <path d="M24.41 11.0883H25.3574V10.1496H24.41V11.0883Z" fill="#F2BA02" />
    <path
      d="M23.4574 11.0883H24.4048V10.1496H23.4574V11.0883Z"
      fill="#FFD900"
    />
    <path
      d="M22.5101 11.0883H23.4575V10.1496H22.5101V11.0883Z"
      fill="#FFD900"
    />
    <path
      d="M21.5677 11.0883H22.5151V10.1496H21.5677V11.0883Z"
      fill="#FFD900"
    />
    <path
      d="M20.6205 11.0883H21.5679V10.1496H20.6205V11.0883Z"
      fill="#FFD900"
    />
    <path
      d="M19.6732 11.0883H20.6206V10.1496H19.6732V11.0883Z"
      fill="#FFD900"
    />
    <path
      d="M18.7259 11.0883H19.6733V10.1496H18.7259V11.0883Z"
      fill="#FFD900"
    />
    <path d="M17.7836 11.0883H18.731V10.1496H17.7836V11.0883Z" fill="#FFD900" />
    <path
      d="M16.8309 11.0883H17.7783V10.1496H16.8309V11.0883Z"
      fill="#FFD900"
    />
    <path
      d="M15.8837 11.0883H16.8311V10.1496H15.8837V11.0883Z"
      fill="#FFD900"
    />
    <path
      d="M14.9418 11.0883H15.8892V10.1496H14.9418V11.0883Z"
      fill="#FFFBF0"
    />
    <path d="M13.994 11.0883H14.9414V10.1496H13.994V11.0883Z" fill="#FFD900" />
    <path
      d="M13.0467 11.0883H13.9941V10.1496H13.0467V11.0883Z"
      fill="#F2BA02"
    />
    <path d="M12.1049 11.0883H13.0522V10.1496H12.1049V11.0883Z" fill="black" />
    <path d="M10.2045 11.0883H11.1519V10.1496H10.2045V11.0883Z" fill="black" />
    <path d="M9.26257 11.0883H10.21V10.1496H9.26257V11.0883Z" fill="#FFD900" />
    <path d="M8.3153 11.0883H9.2627V10.1496H8.3153V11.0883Z" fill="#D39C00" />
    <path d="M7.36803 11.0883H8.31543V10.1496H7.36803V11.0883Z" fill="black" />
    <path d="M32.9261 12.0269H33.8735V11.0882H32.9261V12.0269Z" fill="black" />
    <path
      d="M31.9784 12.0269H32.9258V11.0882H31.9784V12.0269Z"
      fill="#D39C00"
    />
    <path d="M31.0311 12.0269H31.9785V11.0882H31.0311V12.0269Z" fill="black" />
    <path d="M28.1888 12.0269H29.1362V11.0882H28.1888V12.0269Z" fill="black" />
    <path
      d="M27.2469 12.0269H28.1943V11.0882H27.2469V12.0269Z"
      fill="#D39C00"
    />
    <path
      d="M26.2997 12.0269H27.2471V11.0882H26.2997V12.0269Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 12.0269H26.2998V11.0882H25.3524V12.0269Z"
      fill="#F2BA02"
    />
    <path d="M24.41 12.0269H25.3574V11.0882H24.41V12.0269Z" fill="#F2BA02" />
    <path
      d="M23.4574 12.0269H24.4048V11.0882H23.4574V12.0269Z"
      fill="#FFD900"
    />
    <path
      d="M22.5101 12.0269H23.4575V11.0882H22.5101V12.0269Z"
      fill="#FFD900"
    />
    <path
      d="M21.5677 12.0269H22.5151V11.0882H21.5677V12.0269Z"
      fill="#FFD900"
    />
    <path
      d="M20.6205 12.0269H21.5679V11.0882H20.6205V12.0269Z"
      fill="#FFD900"
    />
    <path
      d="M19.6732 12.0269H20.6206V11.0882H19.6732V12.0269Z"
      fill="#FFD900"
    />
    <path
      d="M18.7259 12.0269H19.6733V11.0882H18.7259V12.0269Z"
      fill="#FFD900"
    />
    <path d="M17.7836 12.0269H18.731V11.0882H17.7836V12.0269Z" fill="#FFD900" />
    <path
      d="M16.8309 12.0269H17.7783V11.0882H16.8309V12.0269Z"
      fill="#FFD900"
    />
    <path
      d="M15.8837 12.0269H16.8311V11.0882H15.8837V12.0269Z"
      fill="#FFD900"
    />
    <path
      d="M14.9418 12.0269H15.8892V11.0882H14.9418V12.0269Z"
      fill="#FFFBF0"
    />
    <path d="M13.994 12.0269H14.9414V11.0882H13.994V12.0269Z" fill="#FFD900" />
    <path
      d="M13.0467 12.0269H13.9941V11.0882H13.0467V12.0269Z"
      fill="#D39C00"
    />
    <path d="M12.1049 12.0269H13.0522V11.0882H12.1049V12.0269Z" fill="black" />
    <path d="M9.26257 12.0269H10.21V11.0882H9.26257V12.0269Z" fill="black" />
    <path d="M8.3153 12.0269H9.2627V11.0882H8.3153V12.0269Z" fill="#D39C00" />
    <path d="M7.36803 12.0269H8.31543V11.0882H7.36803V12.0269Z" fill="black" />
    <path d="M32.9261 12.9709H33.8735V12.0322H32.9261V12.9709Z" fill="black" />
    <path
      d="M31.9784 12.9709H32.9258V12.0322H31.9784V12.9709Z"
      fill="#D39C00"
    />
    <path d="M31.0311 12.9709H31.9785V12.0322H31.0311V12.9709Z" fill="black" />
    <path d="M28.1888 12.9709H29.1362V12.0322H28.1888V12.9709Z" fill="black" />
    <path
      d="M27.2469 12.9709H28.1943V12.0322H27.2469V12.9709Z"
      fill="#D39C00"
    />
    <path
      d="M26.2997 12.9709H27.2471V12.0322H26.2997V12.9709Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 12.9709H26.2998V12.0322H25.3524V12.9709Z"
      fill="#F2BA02"
    />
    <path d="M24.41 12.9709H25.3574V12.0322H24.41V12.9709Z" fill="#F2BA02" />
    <path
      d="M23.4574 12.9709H24.4048V12.0322H23.4574V12.9709Z"
      fill="#FFD900"
    />
    <path
      d="M22.5101 12.9709H23.4575V12.0322H22.5101V12.9709Z"
      fill="#FFD900"
    />
    <path
      d="M21.5677 12.9709H22.5151V12.0322H21.5677V12.9709Z"
      fill="#FFD900"
    />
    <path
      d="M20.6205 12.9709H21.5679V12.0322H20.6205V12.9709Z"
      fill="#FFD900"
    />
    <path
      d="M19.6732 12.9709H20.6206V12.0322H19.6732V12.9709Z"
      fill="#FFD900"
    />
    <path
      d="M18.7259 12.9709H19.6733V12.0322H18.7259V12.9709Z"
      fill="#FFD900"
    />
    <path d="M17.7836 12.9709H18.731V12.0322H17.7836V12.9709Z" fill="#FFD900" />
    <path
      d="M16.8309 12.9709H17.7783V12.0322H16.8309V12.9709Z"
      fill="#FFD900"
    />
    <path
      d="M15.8837 12.9709H16.8311V12.0322H15.8837V12.9709Z"
      fill="#FFD900"
    />
    <path
      d="M14.9418 12.9709H15.8892V12.0322H14.9418V12.9709Z"
      fill="#FFFBF0"
    />
    <path d="M13.994 12.9709H14.9414V12.0322H13.994V12.9709Z" fill="#FFD900" />
    <path
      d="M13.0467 12.9709H13.9941V12.0322H13.0467V12.9709Z"
      fill="#D39C00"
    />
    <path d="M12.1049 12.9709H13.0522V12.0322H12.1049V12.9709Z" fill="black" />
    <path d="M9.26257 12.9709H10.21V12.0322H9.26257V12.9709Z" fill="black" />
    <path d="M8.3153 12.9709H9.2627V12.0322H8.3153V12.9709Z" fill="#D39C00" />
    <path d="M7.36803 12.9709H8.31543V12.0322H7.36803V12.9709Z" fill="black" />
    <path d="M32.9261 13.9042H33.8735V12.9656H32.9261V13.9042Z" fill="black" />
    <path
      d="M31.9784 13.9042H32.9258V12.9656H31.9784V13.9042Z"
      fill="#D39C00"
    />
    <path
      d="M31.0311 13.9042H31.9785V12.9656H31.0311V13.9042Z"
      fill="#FFD900"
    />
    <path d="M30.0892 13.9042H31.0366V12.9656H30.0892V13.9042Z" fill="black" />
    <path d="M28.1888 13.9042H29.1362V12.9656H28.1888V13.9042Z" fill="black" />
    <path
      d="M27.2469 13.9042H28.1943V12.9656H27.2469V13.9042Z"
      fill="#F2BA02"
    />
    <path
      d="M26.2997 13.9042H27.2471V12.9656H26.2997V13.9042Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 13.9042H26.2998V12.9656H25.3524V13.9042Z"
      fill="#F2BA02"
    />
    <path d="M24.41 13.9042H25.3574V12.9656H24.41V13.9042Z" fill="#F2BA02" />
    <path
      d="M23.4574 13.9042H24.4048V12.9656H23.4574V13.9042Z"
      fill="#FFD900"
    />
    <path
      d="M22.5101 13.9042H23.4575V12.9656H22.5101V13.9042Z"
      fill="#FFD900"
    />
    <path
      d="M21.5677 13.9042H22.5151V12.9656H21.5677V13.9042Z"
      fill="#FFD900"
    />
    <path
      d="M20.6205 13.9042H21.5679V12.9656H20.6205V13.9042Z"
      fill="#FFD900"
    />
    <path
      d="M19.6732 13.9042H20.6206V12.9656H19.6732V13.9042Z"
      fill="#FFD900"
    />
    <path
      d="M18.7259 13.9042H19.6733V12.9656H18.7259V13.9042Z"
      fill="#FFD900"
    />
    <path d="M17.7836 13.9042H18.731V12.9656H17.7836V13.9042Z" fill="#FFD900" />
    <path
      d="M16.8309 13.9042H17.7783V12.9656H16.8309V13.9042Z"
      fill="#FFD900"
    />
    <path
      d="M15.8837 13.9042H16.8311V12.9656H15.8837V13.9042Z"
      fill="#FFD900"
    />
    <path
      d="M14.9418 13.9042H15.8892V12.9656H14.9418V13.9042Z"
      fill="#FFFBF0"
    />
    <path d="M13.994 13.9042H14.9414V12.9656H13.994V13.9042Z" fill="#FFD900" />
    <path
      d="M13.0467 13.9042H13.9941V12.9656H13.0467V13.9042Z"
      fill="#F2BA02"
    />
    <path d="M12.1049 13.9042H13.0522V12.9656H12.1049V13.9042Z" fill="black" />
    <path d="M10.2045 13.9042H11.1519V12.9656H10.2045V13.9042Z" fill="black" />
    <path d="M9.26257 13.9042H10.21V12.9656H9.26257V13.9042Z" fill="#FFD900" />
    <path d="M8.3153 13.9042H9.2627V12.9656H8.3153V13.9042Z" fill="#D39C00" />
    <path d="M7.36803 13.9042H8.31543V12.9656H7.36803V13.9042Z" fill="black" />
    <path d="M32.9261 14.8429H33.8735V13.9042H32.9261V14.8429Z" fill="black" />
    <path
      d="M31.9784 14.8429H32.9258V13.9042H31.9784V14.8429Z"
      fill="#D39C00"
    />
    <path
      d="M31.0311 14.8429H31.9785V13.9042H31.0311V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M30.0892 14.8429H31.0366V13.9042H30.0892V14.8429Z"
      fill="#FFD900"
    />
    <path d="M29.1366 14.8429H30.084V13.9042H29.1366V14.8429Z" fill="black" />
    <path
      d="M28.1888 14.8429H29.1362V13.9042H28.1888V14.8429Z"
      fill="#D39C00"
    />
    <path
      d="M27.2469 14.8429H28.1943V13.9042H27.2469V14.8429Z"
      fill="#F2BA02"
    />
    <path
      d="M26.2997 14.8429H27.2471V13.9042H26.2997V14.8429Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 14.8429H26.2998V13.9042H25.3524V14.8429Z"
      fill="#F2BA02"
    />
    <path d="M24.41 14.8429H25.3574V13.9042H24.41V14.8429Z" fill="#F2BA02" />
    <path
      d="M23.4574 14.8429H24.4048V13.9042H23.4574V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M22.5101 14.8429H23.4575V13.9042H22.5101V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M21.5677 14.8429H22.5151V13.9042H21.5677V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M20.6205 14.8429H21.5679V13.9042H20.6205V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M19.6732 14.8429H20.6206V13.9042H19.6732V14.8429Z"
      fill="#FFD900"
    />
    <path d="M17.7836 14.8429H18.731V13.9042H17.7836V14.8429Z" fill="#FFD900" />
    <path
      d="M18.7259 14.8429H19.6733V13.9042H18.7259V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M16.8309 14.8429H17.7783V13.9042H16.8309V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M15.8837 14.8429H16.8311V13.9042H15.8837V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M14.9418 14.8429H15.8892V13.9042H14.9418V14.8429Z"
      fill="#FFFBF0"
    />
    <path d="M13.994 14.8429H14.9414V13.9042H13.994V14.8429Z" fill="#FFD900" />
    <path
      d="M13.0467 14.8429H13.9941V13.9042H13.0467V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M12.1049 14.8429H13.0522V13.9042H12.1049V14.8429Z"
      fill="#D39C00"
    />
    <path d="M11.1522 14.8429H12.0996V13.9042H11.1522V14.8429Z" fill="black" />
    <path
      d="M10.2045 14.8429H11.1519V13.9042H10.2045V14.8429Z"
      fill="#FFD900"
    />
    <path d="M9.26257 14.8429H10.21V13.9042H9.26257V14.8429Z" fill="#FFD900" />
    <path d="M8.3153 14.8429H9.2627V13.9042H8.3153V14.8429Z" fill="black" />
    <path d="M7.36803 14.8429H8.31543V13.9042H7.36803V14.8429Z" fill="black" />
    <path d="M31.9784 15.7816H32.9258V14.8429H31.9784V15.7816Z" fill="black" />
    <path
      d="M31.0311 15.7816H31.9785V14.8429H31.0311V15.7816Z"
      fill="#D39C00"
    />
    <path
      d="M30.0892 15.7816H31.0366V14.8429H30.0892V15.7816Z"
      fill="#FFD900"
    />
    <path d="M29.1366 15.7816H30.084V14.8429H29.1366V15.7816Z" fill="#D39C00" />
    <path
      d="M28.1888 15.7816H29.1362V14.8429H28.1888V15.7816Z"
      fill="#D39C00"
    />
    <path
      d="M27.2469 15.7816H28.1943V14.8429H27.2469V15.7816Z"
      fill="#F2BA02"
    />
    <path
      d="M26.2997 15.7816H27.2471V14.8429H26.2997V15.7816Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 15.7816H26.2998V14.8429H25.3524V15.7816Z"
      fill="#F2BA02"
    />
    <path d="M24.41 15.7816H25.3574V14.8429H24.41V15.7816Z" fill="#F2BA02" />
    <path
      d="M23.4574 15.7816H24.4048V14.8429H23.4574V15.7816Z"
      fill="#FFD900"
    />
    <path
      d="M22.5101 15.7816H23.4575V14.8429H22.5101V15.7816Z"
      fill="#FFD900"
    />
    <path
      d="M21.5677 15.7816H22.5151V14.8429H21.5677V15.7816Z"
      fill="#FFD900"
    />
    <path
      d="M20.6205 15.7816H21.5679V14.8429H20.6205V15.7816Z"
      fill="#FFD900"
    />
    <path d="M31.0311 16.7149H31.9785V15.7762H31.0311V16.7149Z" fill="black" />
    <path
      d="M30.0892 16.7149H31.0366V15.7762H30.0892V16.7149Z"
      fill="#D39C00"
    />
    <path d="M29.1366 16.7149H30.084V15.7762H29.1366V16.7149Z" fill="#D39C00" />
    <path
      d="M28.1888 16.7149H29.1362V15.7762H28.1888V16.7149Z"
      fill="#D39C00"
    />
    <path
      d="M27.2469 16.7149H28.1943V15.7762H27.2469V16.7149Z"
      fill="#F2BA02"
    />
    <path
      d="M26.2997 16.7149H27.2471V15.7762H26.2997V16.7149Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 16.7149H26.2998V15.7762H25.3524V16.7149Z"
      fill="#F2BA02"
    />
    <path d="M24.41 16.7149H25.3574V15.7762H24.41V16.7149Z" fill="#F2BA02" />
    <path
      d="M23.4574 16.7149H24.4048V15.7762H23.4574V16.7149Z"
      fill="#FFD900"
    />
    <path
      d="M22.5101 16.7149H23.4575V15.7762H22.5101V16.7149Z"
      fill="#FFD900"
    />
    <path
      d="M21.5677 16.7149H22.5151V15.7762H21.5677V16.7149Z"
      fill="#FFD900"
    />
    <path
      d="M20.6205 16.7149H21.5679V15.7762H20.6205V16.7149Z"
      fill="#FFD900"
    />
    <path d="M30.0892 17.6536H31.0366V16.7149H30.0892V17.6536Z" fill="black" />
    <path d="M29.1366 17.6536H30.084V16.7149H29.1366V17.6536Z" fill="#D39C00" />
    <path
      d="M28.1888 17.6536H29.1362V16.7149H28.1888V17.6536Z"
      fill="#D39C00"
    />
    <path
      d="M27.2469 17.6536H28.1943V16.7149H27.2469V17.6536Z"
      fill="#F2BA02"
    />
    <path
      d="M26.2997 17.6536H27.2471V16.7149H26.2997V17.6536Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 17.6536H26.2998V16.7149H25.3524V17.6536Z"
      fill="#F2BA02"
    />
    <path d="M24.41 17.6536H25.3574V16.7149H24.41V17.6536Z" fill="#F2BA02" />
    <path
      d="M23.4574 17.6536H24.4048V16.7149H23.4574V17.6536Z"
      fill="#FFD900"
    />
    <path
      d="M22.5101 17.6536H23.4575V16.7149H22.5101V17.6536Z"
      fill="#FFD900"
    />
    <path
      d="M21.5677 17.6536H22.5151V16.7149H21.5677V17.6536Z"
      fill="#FFD900"
    />
    <path
      d="M20.6205 17.6536H21.5679V16.7149H20.6205V17.6536Z"
      fill="#FFD900"
    />
    <path d="M30.0892 18.5976H31.0366V17.6589H30.0892V18.5976Z" fill="black" />
    <path d="M29.1366 18.5976H30.084V17.6589H29.1366V18.5976Z" fill="black" />
    <path
      d="M28.1888 18.5976H29.1362V17.6589H28.1888V18.5976Z"
      fill="#D39C00"
    />
    <path
      d="M27.2469 18.5976H28.1943V17.6589H27.2469V18.5976Z"
      fill="#D39C00"
    />
    <path
      d="M26.2997 18.5976H27.2471V17.6589H26.2997V18.5976Z"
      fill="#F2BA02"
    />
    <path
      d="M25.3524 18.5976H26.2998V17.6589H25.3524V18.5976Z"
      fill="#F2BA02"
    />
    <path d="M24.41 18.5976H25.3574V17.6589H24.41V18.5976Z" fill="#F2BA02" />
    <path
      d="M23.4574 18.5976H24.4048V17.6589H23.4574V18.5976Z"
      fill="#F2BA02"
    />
    <path
      d="M22.5101 18.5976H23.4575V17.6589H22.5101V18.5976Z"
      fill="#F2BA02"
    />
    <path
      d="M21.5677 18.5976H22.5151V17.6589H21.5677V18.5976Z"
      fill="#F2BA02"
    />
    <path
      d="M20.6205 18.5976H21.5679V17.6589H20.6205V18.5976Z"
      fill="#FFD900"
    />
    <path d="M28.1888 19.5309H29.1362V18.5922H28.1888V19.5309Z" fill="black" />
    <path d="M27.2469 19.5309H28.1943V18.5922H27.2469V19.5309Z" fill="black" />
    <path
      d="M26.2997 19.5309H27.2471V18.5922H26.2997V19.5309Z"
      fill="#D39C00"
    />
    <path
      d="M25.3524 19.5309H26.2998V18.5922H25.3524V19.5309Z"
      fill="#F2BA02"
    />
    <path d="M24.41 19.5309H25.3574V18.5922H24.41V19.5309Z" fill="#F2BA02" />
    <path
      d="M23.4574 19.5309H24.4048V18.5922H23.4574V19.5309Z"
      fill="#F2BA02"
    />
    <path
      d="M22.5101 19.5309H23.4575V18.5922H22.5101V19.5309Z"
      fill="#F2BA02"
    />
    <path
      d="M21.5677 19.5309H22.5151V18.5922H21.5677V19.5309Z"
      fill="#F2BA02"
    />
    <path
      d="M20.6205 19.5309H21.5679V18.5922H20.6205V19.5309Z"
      fill="#FFD900"
    />
    <path d="M26.2997 20.4696H27.2471V19.5309H26.2997V20.4696Z" fill="black" />
    <path
      d="M25.3524 20.4696H26.2998V19.5309H25.3524V20.4696Z"
      fill="#D39C00"
    />
    <path d="M24.41 20.4696H25.3574V19.5309H24.41V20.4696Z" fill="#F2BA02" />
    <path
      d="M23.4574 20.4696H24.4048V19.5309H23.4574V20.4696Z"
      fill="#F2BA02"
    />
    <path
      d="M22.5101 20.4696H23.4575V19.5309H22.5101V20.4696Z"
      fill="#F2BA02"
    />
    <path
      d="M21.5677 20.4696H22.5151V19.5309H21.5677V20.4696Z"
      fill="#F2BA02"
    />
    <path
      d="M20.6205 20.4696H21.5679V19.5309H20.6205V20.4696Z"
      fill="#FFD900"
    />
    <path d="M26.2997 21.4082H27.2471V20.4696H26.2997V21.4082Z" fill="black" />
    <path d="M25.3524 21.4082H26.2998V20.4696H25.3524V21.4082Z" fill="black" />
    <path d="M24.41 21.4082H25.3574V20.4696H24.41V21.4082Z" fill="#D39C00" />
    <path
      d="M23.4574 21.4082H24.4048V20.4696H23.4574V21.4082Z"
      fill="#D39C00"
    />
    <path
      d="M22.5101 21.4082H23.4575V20.4696H22.5101V21.4082Z"
      fill="#F2BA02"
    />
    <path
      d="M21.5677 21.4082H22.5151V20.4696H21.5677V21.4082Z"
      fill="#F2BA02"
    />
    <path
      d="M20.6205 21.4082H21.5679V20.4696H20.6205V21.4082Z"
      fill="#F2BA02"
    />
    <path d="M24.41 22.3416H25.3574V21.4029H24.41V22.3416Z" fill="black" />
    <path d="M23.4574 22.3416H24.4048V21.4029H23.4574V22.3416Z" fill="black" />
    <path
      d="M22.5101 22.3416H23.4575V21.4029H22.5101V22.3416Z"
      fill="#D39C00"
    />
    <path
      d="M21.5677 22.3416H22.5151V21.4029H21.5677V22.3416Z"
      fill="#D39C00"
    />
    <path
      d="M20.6205 22.3416H21.5679V21.4029H20.6205V22.3416Z"
      fill="#F2BA02"
    />
    <path d="M22.5101 23.2802H23.4575V22.3416H22.5101V23.2802Z" fill="black" />
    <path
      d="M21.5677 23.2802H22.5151V22.3416H21.5677V23.2802Z"
      fill="#D39C00"
    />
    <path
      d="M20.6205 23.2802H21.5679V22.3416H20.6205V23.2802Z"
      fill="#F2BA02"
    />
    <path d="M22.5101 24.2242H23.4575V23.2856H22.5101V24.2242Z" fill="black" />
    <path
      d="M21.5677 24.2242H22.5151V23.2856H21.5677V24.2242Z"
      fill="#D39C00"
    />
    <path
      d="M20.6205 24.2242H21.5679V23.2856H20.6205V24.2242Z"
      fill="#F2BA02"
    />
    <path d="M22.5101 25.1576H23.4575V24.2189H22.5101V25.1576Z" fill="black" />
    <path
      d="M21.5677 25.1576H22.5151V24.2189H21.5677V25.1576Z"
      fill="#D39C00"
    />
    <path
      d="M20.6205 25.1576H21.5679V24.2189H20.6205V25.1576Z"
      fill="#F2BA02"
    />
    <path d="M22.5101 26.0963H23.4575V25.1576H22.5101V26.0963Z" fill="black" />
    <path
      d="M21.5677 26.0963H22.5151V25.1576H21.5677V26.0963Z"
      fill="#D39C00"
    />
    <path
      d="M20.6205 26.0963H21.5679V25.1576H20.6205V26.0963Z"
      fill="#F2BA02"
    />
    <path d="M22.5101 27.0349H23.4575V26.0962H22.5101V27.0349Z" fill="black" />
    <path
      d="M21.5677 27.0349H22.5151V26.0962H21.5677V27.0349Z"
      fill="#D39C00"
    />
    <path
      d="M20.6205 27.0349H21.5679V26.0962H20.6205V27.0349Z"
      fill="#F2BA02"
    />
    <path d="M22.5101 27.9682H23.4575V27.0296H22.5101V27.9682Z" fill="black" />
    <path
      d="M21.5677 27.9682H22.5151V27.0296H21.5677V27.9682Z"
      fill="#D39C00"
    />
    <path
      d="M20.6205 27.9682H21.5679V27.0296H20.6205V27.9682Z"
      fill="#F2BA02"
    />
    <path d="M22.5101 28.9069H23.4575V27.9682H22.5101V28.9069Z" fill="black" />
    <path
      d="M21.5677 28.9069H22.5151V27.9682H21.5677V28.9069Z"
      fill="#D39C00"
    />
    <path
      d="M20.6205 28.9069H21.5679V27.9682H20.6205V28.9069Z"
      fill="#F2BA02"
    />
    <path d="M23.4574 29.8509H24.4048V28.9122H23.4574V29.8509Z" fill="black" />
    <path
      d="M22.5101 29.8509H23.4575V28.9122H22.5101V29.8509Z"
      fill="#D39C00"
    />
    <path
      d="M21.5677 29.8509H22.5151V28.9122H21.5677V29.8509Z"
      fill="#D39C00"
    />
    <path
      d="M20.6205 29.8509H21.5679V28.9122H20.6205V29.8509Z"
      fill="#F2BA02"
    />
    <path d="M24.41 30.7842H25.3574V29.8456H24.41V30.7842Z" fill="black" />
    <path
      d="M23.4574 30.7842H24.4048V29.8456H23.4574V30.7842Z"
      fill="#D39C00"
    />
    <path
      d="M22.5101 30.7842H23.4575V29.8456H22.5101V30.7842Z"
      fill="#D39C00"
    />
    <path
      d="M21.5677 30.7842H22.5151V29.8456H21.5677V30.7842Z"
      fill="#F2BA02"
    />
    <path
      d="M20.6205 30.7842H21.5679V29.8456H20.6205V30.7842Z"
      fill="#F2BA02"
    />
    <path d="M25.3524 31.7229H26.2998V30.7842H25.3524V31.7229Z" fill="black" />
    <path d="M24.41 31.7229H25.3574V30.7842H24.41V31.7229Z" fill="#D39C00" />
    <path
      d="M23.4574 31.7229H24.4048V30.7842H23.4574V31.7229Z"
      fill="#F2BA02"
    />
    <path
      d="M22.5101 31.7229H23.4575V30.7842H22.5101V31.7229Z"
      fill="#F2BA02"
    />
    <path
      d="M21.5677 31.7229H22.5151V30.7842H21.5677V31.7229Z"
      fill="#F2BA02"
    />
    <path
      d="M20.6205 31.7229H21.5679V30.7842H20.6205V31.7229Z"
      fill="#F2BA02"
    />
    <path d="M26.2997 32.6616H27.2471V31.7229H26.2997V32.6616Z" fill="black" />
    <path
      d="M25.3524 32.6616H26.2998V31.7229H25.3524V32.6616Z"
      fill="#D39C00"
    />
    <path d="M24.41 32.6616H25.3574V31.7229H24.41V32.6616Z" fill="#F2BA02" />
    <path
      d="M23.4574 32.6616H24.4048V31.7229H23.4574V32.6616Z"
      fill="#F2BA02"
    />
    <path
      d="M22.5101 32.6616H23.4575V31.7229H22.5101V32.6616Z"
      fill="#F2BA02"
    />
    <path
      d="M21.5677 32.6616H22.5151V31.7229H21.5677V32.6616Z"
      fill="#F2BA02"
    />
    <path
      d="M20.6205 32.6616H21.5679V31.7229H20.6205V32.6616Z"
      fill="#F2BA02"
    />
    <path d="M26.2997 33.5949H27.2471V32.6562H26.2997V33.5949Z" fill="black" />
    <path
      d="M25.3524 33.5949H26.2998V32.6562H25.3524V33.5949Z"
      fill="#D39C00"
    />
    <path d="M24.41 33.5949H25.3574V32.6562H24.41V33.5949Z" fill="#F2BA02" />
    <path
      d="M23.4574 33.5949H24.4048V32.6562H23.4574V33.5949Z"
      fill="#F2BA02"
    />
    <path
      d="M22.5101 33.5949H23.4575V32.6562H22.5101V33.5949Z"
      fill="#F2BA02"
    />
    <path
      d="M21.5677 33.5949H22.5151V32.6562H21.5677V33.5949Z"
      fill="#F2BA02"
    />
    <path
      d="M20.6205 33.5949H21.5679V32.6562H20.6205V33.5949Z"
      fill="#F2BA02"
    />
    <path d="M26.2997 34.5336H27.2471V33.5949H26.2997V34.5336Z" fill="black" />
    <path
      d="M25.3524 34.5336H26.2998V33.5949H25.3524V34.5336Z"
      fill="#D39C00"
    />
    <path d="M24.41 34.5336H25.3574V33.5949H24.41V34.5336Z" fill="#D39C00" />
    <path
      d="M23.4574 34.5336H24.4048V33.5949H23.4574V34.5336Z"
      fill="#D39C00"
    />
    <path
      d="M22.5101 34.5336H23.4575V33.5949H22.5101V34.5336Z"
      fill="#D39C00"
    />
    <path
      d="M21.5677 34.5336H22.5151V33.5949H21.5677V34.5336Z"
      fill="#D39C00"
    />
    <path
      d="M20.6205 34.5336H21.5679V33.5949H20.6205V34.5336Z"
      fill="#D39C00"
    />
    <path d="M26.2997 35.4776H27.2471V34.5389H26.2997V35.4776Z" fill="black" />
    <path d="M25.3524 35.4776H26.2998V34.5389H25.3524V35.4776Z" fill="black" />
    <path d="M24.41 35.4776H25.3574V34.5389H24.41V35.4776Z" fill="black" />
    <path d="M23.4574 35.4776H24.4048V34.5389H23.4574V35.4776Z" fill="black" />
    <path d="M22.5101 35.4776H23.4575V34.5389H22.5101V35.4776Z" fill="black" />
    <path d="M21.5677 35.4776H22.5151V34.5389H21.5677V35.4776Z" fill="black" />
    <path d="M10.2101 14.8429H9.2627V15.7816H10.2101V14.8429Z" fill="black" />
    <path d="M11.1574 14.8429H10.21V15.7816H11.1574V14.8429Z" fill="#FFD900" />
    <path
      d="M12.1046 14.8429H11.1572V15.7816H12.1046V14.8429Z"
      fill="#FFD900"
    />
    <path d="M13.047 14.8429H12.0996V15.7816H13.047V14.8429Z" fill="#D39C00" />
    <path
      d="M13.9943 14.8429H13.0469V15.7816H13.9943V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M14.9415 14.8429H13.9941V15.7816H14.9415V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M15.8888 14.8429H14.9414V15.7816H15.8888V14.8429Z"
      fill="#FFFBF0"
    />
    <path
      d="M16.8366 14.8429H15.8892V15.7816H16.8366V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M17.7838 14.8429H16.8364V15.7816H17.7838V14.8429Z"
      fill="#FFD900"
    />
    <path
      d="M18.7257 14.8429H17.7783V15.7816H18.7257V14.8429Z"
      fill="#FFD900"
    />
    <path d="M19.673 14.8429H18.7256V15.7816H19.673V14.8429Z" fill="#FFD900" />
    <path
      d="M20.6207 14.8429H19.6733V15.7816H20.6207V14.8429Z"
      fill="#FFD900"
    />
    <path d="M11.1574 15.7816H10.21V16.7202H11.1574V15.7816Z" fill="black" />
    <path
      d="M12.1046 15.7816H11.1572V16.7202H12.1046V15.7816Z"
      fill="#D39C00"
    />
    <path d="M13.047 15.7816H12.0996V16.7202H13.047V15.7816Z" fill="#D39C00" />
    <path
      d="M13.9943 15.7816H13.0469V16.7202H13.9943V15.7816Z"
      fill="#FFD900"
    />
    <path
      d="M14.9415 15.7816H13.9941V16.7202H14.9415V15.7816Z"
      fill="#FFD900"
    />
    <path
      d="M15.8888 15.7816H14.9414V16.7202H15.8888V15.7816Z"
      fill="#FFD900"
    />
    <path
      d="M16.8366 15.7816H15.8892V16.7202H16.8366V15.7816Z"
      fill="#FFFBF0"
    />
    <path
      d="M17.7838 15.7816H16.8364V16.7202H17.7838V15.7816Z"
      fill="#FFD900"
    />
    <path
      d="M18.7257 15.7816H17.7783V16.7202H18.7257V15.7816Z"
      fill="#FFD900"
    />
    <path d="M19.673 15.7816H18.7256V16.7202H19.673V15.7816Z" fill="#FFD900" />
    <path
      d="M20.6207 15.7816H19.6733V16.7202H20.6207V15.7816Z"
      fill="#FFD900"
    />
    <path d="M12.1046 16.7202H11.1572V17.6589H12.1046V16.7202Z" fill="black" />
    <path d="M13.047 16.7202H12.0996V17.6589H13.047V16.7202Z" fill="#D39C00" />
    <path
      d="M13.9943 16.7202H13.0469V17.6589H13.9943V16.7202Z"
      fill="#FFD900"
    />
    <path
      d="M14.9415 16.7202H13.9941V17.6589H14.9415V16.7202Z"
      fill="#FFD900"
    />
    <path
      d="M15.8888 16.7202H14.9414V17.6589H15.8888V16.7202Z"
      fill="#FFD900"
    />
    <path
      d="M16.8366 16.7202H15.8892V17.6589H16.8366V16.7202Z"
      fill="#FFD900"
    />
    <path
      d="M17.7838 16.7202H16.8364V17.6589H17.7838V16.7202Z"
      fill="#FFFBF0"
    />
    <path
      d="M18.7257 16.7202H17.7783V17.6589H18.7257V16.7202Z"
      fill="#FFD900"
    />
    <path d="M19.673 16.7202H18.7256V17.6589H19.673V16.7202Z" fill="#FFD900" />
    <path
      d="M20.6207 16.7202H19.6733V17.6589H20.6207V16.7202Z"
      fill="#FFD900"
    />
    <path d="M12.1046 17.6536H11.1572V18.5922H12.1046V17.6536Z" fill="black" />
    <path d="M13.047 17.6536H12.0996V18.5922H13.047V17.6536Z" fill="black" />
    <path
      d="M13.9943 17.6536H13.0469V18.5922H13.9943V17.6536Z"
      fill="#D39C00"
    />
    <path
      d="M14.9415 17.6536H13.9941V18.5922H14.9415V17.6536Z"
      fill="#D39C00"
    />
    <path
      d="M15.8888 17.6536H14.9414V18.5922H15.8888V17.6536Z"
      fill="#FFD900"
    />
    <path
      d="M16.8366 17.6536H15.8892V18.5922H16.8366V17.6536Z"
      fill="#FFD900"
    />
    <path
      d="M17.7838 17.6536H16.8364V18.5922H17.7838V17.6536Z"
      fill="#FFD900"
    />
    <path
      d="M18.7257 17.6536H17.7783V18.5922H18.7257V17.6536Z"
      fill="#FFD900"
    />
    <path d="M19.673 17.6536H18.7256V18.5922H19.673V17.6536Z" fill="#FFD900" />
    <path
      d="M20.6207 17.6536H19.6733V18.5922H20.6207V17.6536Z"
      fill="#FFD900"
    />
    <path d="M13.9943 18.5922H13.0469V19.5309H13.9943V18.5922Z" fill="black" />
    <path d="M14.9415 18.5922H13.9941V19.5309H14.9415V18.5922Z" fill="black" />
    <path
      d="M15.8888 18.5922H14.9414V19.5309H15.8888V18.5922Z"
      fill="#D39C00"
    />
    <path
      d="M16.8366 18.5922H15.8892V19.5309H16.8366V18.5922Z"
      fill="#FFD900"
    />
    <path
      d="M17.7838 18.5922H16.8364V19.5309H17.7838V18.5922Z"
      fill="#FFD900"
    />
    <path
      d="M18.7257 18.5922H17.7783V19.5309H18.7257V18.5922Z"
      fill="#FFD900"
    />
    <path d="M19.673 18.5922H18.7256V19.5309H19.673V18.5922Z" fill="#FFD900" />
    <path
      d="M20.6207 18.5922H19.6733V19.5309H20.6207V18.5922Z"
      fill="#FFD900"
    />
    <path d="M15.8888 19.5309H14.9414V20.4696H15.8888V19.5309Z" fill="black" />
    <path
      d="M16.8366 19.5309H15.8892V20.4696H16.8366V19.5309Z"
      fill="#D39C00"
    />
    <path
      d="M17.7838 19.5309H16.8364V20.4696H17.7838V19.5309Z"
      fill="#FFD900"
    />
    <path
      d="M18.7257 19.5309H17.7783V20.4696H18.7257V19.5309Z"
      fill="#FFD900"
    />
    <path d="M19.673 19.5309H18.7256V20.4696H19.673V19.5309Z" fill="#FFD900" />
    <path
      d="M20.6207 19.5309H19.6733V20.4696H20.6207V19.5309Z"
      fill="#FFD900"
    />
    <path d="M15.8888 20.4696H14.9414V21.4082H15.8888V20.4696Z" fill="black" />
    <path d="M16.8366 20.4696H15.8892V21.4082H16.8366V20.4696Z" fill="black" />
    <path
      d="M17.7838 20.4696H16.8364V21.4082H17.7838V20.4696Z"
      fill="#D39C00"
    />
    <path
      d="M18.7257 20.4696H17.7783V21.4082H18.7257V20.4696Z"
      fill="#FFD900"
    />
    <path d="M19.673 20.4696H18.7256V21.4082H19.673V20.4696Z" fill="#FFD900" />
    <path
      d="M20.6207 20.4696H19.6733V21.4082H20.6207V20.4696Z"
      fill="#FFD900"
    />
    <path d="M17.7838 21.4082H16.8364V22.3469H17.7838V21.4082Z" fill="black" />
    <path d="M18.7257 21.4082H17.7783V22.3469H18.7257V21.4082Z" fill="black" />
    <path d="M19.673 21.4082H18.7256V22.3469H19.673V21.4082Z" fill="#D39C00" />
    <path
      d="M20.6207 21.4082H19.6733V22.3469H20.6207V21.4082Z"
      fill="#FFD900"
    />
    <path d="M19.673 22.3469H18.7256V23.2856H19.673V22.3469Z" fill="black" />
    <path
      d="M20.6207 22.3469H19.6733V23.2856H20.6207V22.3469Z"
      fill="#FFD900"
    />
    <path d="M19.673 23.2802H18.7256V24.2189H19.673V23.2802Z" fill="black" />
    <path
      d="M20.6207 23.2802H19.6733V24.2189H20.6207V23.2802Z"
      fill="#FFD900"
    />
    <path d="M19.673 24.2189H18.7256V25.1576H19.673V24.2189Z" fill="black" />
    <path
      d="M20.6207 24.2189H19.6733V25.1576H20.6207V24.2189Z"
      fill="#FFD900"
    />
    <path d="M19.673 25.1576H18.7256V26.0962H19.673V25.1576Z" fill="black" />
    <path
      d="M20.6207 25.1576H19.6733V26.0962H20.6207V25.1576Z"
      fill="#FFD900"
    />
    <path d="M19.673 26.0963H18.7256V27.0349H19.673V26.0963Z" fill="black" />
    <path
      d="M20.6207 26.0963H19.6733V27.0349H20.6207V26.0963Z"
      fill="#FFD900"
    />
    <path d="M19.673 27.0349H18.7256V27.9736H19.673V27.0349Z" fill="black" />
    <path
      d="M20.6207 27.0349H19.6733V27.9736H20.6207V27.0349Z"
      fill="#FFD900"
    />
    <path d="M19.673 27.9736H18.7256V28.9122H19.673V27.9736Z" fill="black" />
    <path
      d="M20.6207 27.9736H19.6733V28.9122H20.6207V27.9736Z"
      fill="#FFD900"
    />
    <path d="M18.7257 28.9069H17.7783V29.8456H18.7257V28.9069Z" fill="black" />
    <path d="M19.673 28.9069H18.7256V29.8456H19.673V28.9069Z" fill="#D39C00" />
    <path
      d="M20.6207 28.9069H19.6733V29.8456H20.6207V28.9069Z"
      fill="#FFD900"
    />
    <path d="M17.7838 29.8456H16.8364V30.7843H17.7838V29.8456Z" fill="black" />
    <path
      d="M18.7257 29.8456H17.7783V30.7843H18.7257V29.8456Z"
      fill="#D39C00"
    />
    <path d="M19.673 29.8456H18.7256V30.7843H19.673V29.8456Z" fill="#D39C00" />
    <path
      d="M20.6207 29.8456H19.6733V30.7843H20.6207V29.8456Z"
      fill="#FFD900"
    />
    <path d="M16.8366 30.7842H15.8892V31.7229H16.8366V30.7842Z" fill="black" />
    <path
      d="M17.7838 30.7842H16.8364V31.7229H17.7838V30.7842Z"
      fill="#FFD900"
    />
    <path
      d="M18.7257 30.7842H17.7783V31.7229H18.7257V30.7842Z"
      fill="#FFD900"
    />
    <path d="M19.673 30.7842H18.7256V31.7229H19.673V30.7842Z" fill="#FFD900" />
    <path
      d="M20.6207 30.7842H19.6733V31.7229H20.6207V30.7842Z"
      fill="#FFD900"
    />
    <path d="M15.8888 31.7229H14.9414V32.6616H15.8888V31.7229Z" fill="black" />
    <path
      d="M16.8366 31.7229H15.8892V32.6616H16.8366V31.7229Z"
      fill="#F2BA02"
    />
    <path
      d="M17.7838 31.7229H16.8364V32.6616H17.7838V31.7229Z"
      fill="#FFD900"
    />
    <path
      d="M18.7257 31.7229H17.7783V32.6616H18.7257V31.7229Z"
      fill="#FFD900"
    />
    <path d="M19.673 31.7229H18.7256V32.6616H19.673V31.7229Z" fill="#FFD900" />
    <path
      d="M20.6207 31.7229H19.6733V32.6616H20.6207V31.7229Z"
      fill="#FFD900"
    />
    <path d="M15.8888 32.6616H14.9414V33.6002H15.8888V32.6616Z" fill="black" />
    <path
      d="M16.8366 32.6616H15.8892V33.6002H16.8366V32.6616Z"
      fill="#F2BA02"
    />
    <path
      d="M17.7838 32.6616H16.8364V33.6002H17.7838V32.6616Z"
      fill="#F2BA02"
    />
    <path
      d="M18.7257 32.6616H17.7783V33.6002H18.7257V32.6616Z"
      fill="#F2BA02"
    />
    <path d="M19.673 32.6616H18.7256V33.6002H19.673V32.6616Z" fill="#F2BA02" />
    <path
      d="M20.6207 32.6616H19.6733V33.6002H20.6207V32.6616Z"
      fill="#F2BA02"
    />
    <path d="M15.8888 33.6003H14.9414V34.5389H15.8888V33.6003Z" fill="black" />
    <path
      d="M16.8366 33.6003H15.8892V34.5389H16.8366V33.6003Z"
      fill="#D39C00"
    />
    <path
      d="M17.7838 33.6003H16.8364V34.5389H17.7838V33.6003Z"
      fill="#D39C00"
    />
    <path
      d="M18.7257 33.6003H17.7783V34.5389H18.7257V33.6003Z"
      fill="#D39C00"
    />
    <path d="M19.673 33.6003H18.7256V34.5389H19.673V33.6003Z" fill="#D39C00" />
    <path
      d="M20.6207 33.6003H19.6733V34.5389H20.6207V33.6003Z"
      fill="#D39C00"
    />
    <path d="M15.8888 34.5336H14.9414V35.4722H15.8888V34.5336Z" fill="black" />
    <path d="M16.8366 34.5336H15.8892V35.4722H16.8366V34.5336Z" fill="black" />
    <path d="M17.7838 34.5336H16.8364V35.4722H17.7838V34.5336Z" fill="black" />
    <path d="M18.7257 34.5336H17.7783V35.4722H18.7257V34.5336Z" fill="black" />
    <path d="M19.673 34.5336H18.7256V35.4722H19.673V34.5336Z" fill="black" />
    <path d="M20.6207 34.5336H19.6733V35.4722H20.6207V34.5336Z" fill="black" />
    <path d="M20.6205 35.4776H21.5679V34.5389H20.6205V35.4776Z" fill="black" />
  </svg>
);
