import { BlinkingText } from '@/components/ui/atoms/BlinkingText';
import { Text } from '@/components/ui/atoms/Text';
import { Button } from '@/components/ui/molecules/Button';
import { Card } from '@/components/ui/molecules/Card';
import { useTelegramPet } from '@/context/FirestoreContext';
import { Box, Center, Flex, effect } from '@chakra-ui/react';
import { getTelegramPetImage } from 'genopets-utils';
import { PetStats } from 'genopets-utils/src/pet';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TextWithShadow } from '../ui/atoms/TextWithShadow';
import { sounds } from '@/lib/sounds';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { GoHomeButtons } from './GoHomeButtons';
import { use } from 'i18next';
import { useEffectPlayer } from '@/context/EffectPlayerContext';

const Stats = ({
  name,
  stats = 0,
  prev = 0,
}: {
  name: string;
  stats?: number;
  prev?: number;
}) => {
  return (
    <Flex w="full" gap={2}>
      <Text colorId="Black">{`${name}:`}</Text>
      <Text colorId="Black">{stats}</Text>
      <Text colorId="Green">{`+${stats - prev}`}</Text>
    </Flex>
  );
};

export const PetLevelUp = () => {
  const navigate = useNavigate();
  const [blinking, setBlinking] = useState(true);
  const { pet } = useTelegramPet();
  const [stats, setStats] = useState<PetStats>();
  const [prevStats, setPrevStats] = useState<PetStats>();

  const effectPlayer = useEffectPlayer();

  const petName = pet?.nickname || pet?.name;

  const [searchParams] = useSearchParams();

  const redirection = searchParams.get('redirection');

  useEffect(() => {
    let timeout: any;
    // may need a mutate here to update the pet when integrated in the flow
    if (pet && blinking) {
      effectPlayer.playEffect(sounds.petLevelUp);
      timeout = setTimeout(() => {
        setBlinking(false);
      }, 2300);
      const stat = new PetStats(pet, new Map(), { telegramStats: true });
      setStats(stat);
      const prevStat = new PetStats(pet, new Map(), {
        levelOverride: pet.telegramLevel - 1,
      });
      setPrevStats(prevStat);
      return () => clearTimeout(timeout);
    }
  }, [pet]);

  return (
    <>
      <Center w="full" h="full" position="relative">
        {pet && (
          <Center flexDirection={'column'} position="relative">
            <Box className="blink-pet" transformOrigin={'center 120px'}>
              <img
                src={getTelegramPetImage(pet.petConfigV2)}
                alt="pet"
                style={{ width: '150px', height: '150px' }}
              />
            </Box>
            {blinking ? (
              <BlinkingText textAlign="center">
                <TranslatedText
                  translationKey={`petLevelingUp`}
                  defaultMessage={`${petName} is leveling up!`}
                  props={{ pet: petName }}
                />
                !
              </BlinkingText>
            ) : (
              <TextWithShadow textAlign="center">
                <TranslatedText
                  translationKey={`petLeveledUp`}
                  defaultMessage={`${petName} leveled up`}
                  props={{ pet: petName }}
                />
                !
              </TextWithShadow>
            )}
          </Center>
        )}
        {!blinking && pet && (
          <Card
            position="absolute"
            bottom={'-16px'}
            left={'-16px'}
            w="calc(100% + 32px)"
            flexDirection={'column'}
            gap={2}
            display={'flex'}
          >
            <Text colorId="Black" width={'full'} textAlign={'center'}>
              <TranslatedText
                translationKey={`petReachedLevel`}
                defaultMessage={`${
                  petName
                } reached level ${pet.telegramLevel}!`}
                props={{ pet: petName, level: pet.telegramLevel }}
              />
              !
            </Text>
            <Flex w="full">
              <Flex flexDirection={'column'} w="50%">
                <Stats name={'HP'} stats={stats?.hp} prev={prevStats?.hp} />
                <Stats name={'ATK'} stats={stats?.atk} prev={prevStats?.atk} />
              </Flex>
              <Flex flexDirection={'column'} w="50%">
                <Stats name={'DEF'} stats={stats?.def} prev={prevStats?.def} />
                <Stats name={'SPD'} stats={stats?.spd} prev={prevStats?.spd} />
              </Flex>
            </Flex>
            {pet.telegramRolledMove ? (
              <Button
                colorId="Black"
                w="full"
                width={`100%`}
                onClick={() => {
                  navigate('/battle-choose-move');
                }}
              >
                <TranslatedText translationKey={`ok`} defaultMessage={`ok`} />
              </Button>
            ) : (
              <>
                {redirection === 'spinner' ? (
                  <>
                    <Flex w="full" gap={2} flexDirection="column">
                      <Button
                        colorId="Black"
                        w="full"
                        onClick={() => navigate('/spinner?auto=true')}
                      >
                        <TranslatedText
                          translationKey={'spinAgain'}
                          defaultMessage={'spin again'}
                        />
                        !
                      </Button>

                      <Button
                        colorId="White"
                        w="full"
                        onClick={() => navigate('/home')}
                      >
                        <TranslatedText
                          translationKey={'goHome'}
                          defaultMessage={'Go Home'}
                        />
                      </Button>
                    </Flex>
                  </>
                ) : (
                  <GoHomeButtons />
                )}
              </>
            )}
          </Card>
        )}
      </Center>
    </>
  );
};
