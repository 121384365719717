import { useMusic } from '@/components/Sounds/useMusic';
import { MainContainer } from '@/components/battle/MainContainer';
import { bgSounds } from '@/lib/sounds';
import InitBackground from '../../images/bg-earns-page.png';
import Earn from '@/components/earn/Earn';
import { Text } from '@/components/ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Whittakr } from '@/components/ui/organisms/Whittakr';

export const EarnPage = () => {
  useMusic(bgSounds.viralLoopPage);

  return (
    <MainContainer
      padding={0}
      background={InitBackground}
      backgroundPosition="top"
      backgroundSize="100% auto"
    >
      <Whittakr
        imgStyle={{ left: `-90px`, top: `0px`, width: `87px` }}
        textBoxProps={{ top: '-8px', left: '160px', padding: 2 }}
        triangleProps={{ left: '0', right: 'none' }}
      >
        <Text w="full" fontSize={`10px`}>
          <TranslatedText
            translationKey={`doStuff`}
            defaultMessage={`Do stuff`}
          />
          {`!`}
        </Text>
        <Text w="full" fontSize={`10px`}>
          <TranslatedText
            translationKey={`getEnergy`}
            defaultMessage={`get energy`}
          />
          {`!`}
        </Text>
        <Text w="full" fontSize={`10px`}>
          <TranslatedText
            translationKey={`winAirdrops`}
            defaultMessage={`win airdrops`}
          />
          !
        </Text>
      </Whittakr>
      <Earn />
    </MainContainer>
  );
};
