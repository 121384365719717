import { useMusic } from '@/components/Sounds/useMusic';
import { MainContainer } from '@/components/battle/MainContainer';
import { PetChooseMove } from '@/components/battle/PetChooseMove';
import { bgSounds } from '@/lib/sounds';

export const BattleChooseMovePage = () => {
  useMusic(bgSounds.summon);

  return (
    <MainContainer>
      <PetChooseMove />
    </MainContainer>
  );
};
