import { useEffectPlayer } from '@/context/EffectPlayerContext';
import { sounds } from '@/lib/sounds';
import { FlexProps } from '@chakra-ui/react';
import { useHapticFeedback } from '@tma.js/sdk-react';
import { isString } from 'lodash';
import { forwardRef } from 'react';
import { Text } from '../atoms/Text';
import { ColorId } from '../colors';
import { Next } from '../icons/Next';
import { MenuItemDefault } from './MenuItemDefault';

export const MenuItemPop = forwardRef(
  (
    {
      children,
      color = 'var(--White)',
      disabled,
      onClick,
      ...props
    }: { colorId?: ColorId; disabled?: boolean } & FlexProps,
    ref,
  ) => {
    const effectPlayer = useEffectPlayer();
    const hapticFeedback = useHapticFeedback();

    return (
      <MenuItemDefault
        justifyContent={'space-between'}
        disabled={disabled}
        ref={ref}
        onClick={(e) => {
          // always play sound due to link that not use onClick
          effectPlayer.playEffect(sounds.pressButton);
          hapticFeedback.impactOccurred('rigid');
          if (onClick) {
            onClick(e);
          }
        }}
        {...props}
      >
        {isString(children) ? (
          <Text color={disabled ? `var(--Black)` : color}>{children}</Text>
        ) : (
          children
        )}

        <Next
          color={disabled ? `var(--Black)` : color}
          style={{ minWidth: '24px' }}
        />
      </MenuItemDefault>
    );
  },
);

MenuItemPop.displayName = 'MenuItemPop';
