import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { useAlert } from '@/hooks/useAlert';
import { getPetName } from '@/utils/utils';
import { VStack } from '@chakra-ui/react';
import {
  BattleState,
  RootState,
  UiEventType,
  getPlayerData,
} from 'genopets-utils';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../../ui/atoms/Text';
import { useUIBattleContext } from '../context/useBattleContext';
import { set } from 'lodash';

export const usePlayParalyze = () => {
  const { uiEvent, dispatchUiEventPlayed } = useUIBattleContext();

  const [playParalyze, setPlayParalyze] = useState<{
    paralyzedPlayerId: string;
  }>();

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const alert = useAlert();

  useEffect(() => {
    if (uiEvent?.type === UiEventType.PLAY_PARALYZE) {
      setPlayParalyze(uiEvent.payload);
    }
  }, [uiEvent]);

  useEffect(() => {
    // wait for play move animation to be played ans toast to not be visible before end round
    if (playParalyze && uiEvent) {
      const petName = getPetName(
        getPlayerData(battleState, playParalyze.paralyzedPlayerId).petInfo,
        'player 1',
      ) as string;

      const title = (
        <VStack alignItems={'flex-start'}>
          <Text as="span" colorId="Black">
            <TranslatedText
              translationKey={'petParalyzed'}
              defaultMessage={'Pet ${petName} paralyzed and cannot move'}
              props={{ petName }}
            />
            !
          </Text>
        </VStack>
      );

      alert({
        title,
        onCloseComplete: () => {
          dispatchUiEventPlayed(uiEvent.id);
          setPlayParalyze(undefined);
        },
      });
    }
  }, [playParalyze]);
};
