export const VITE_SHOW_BATTLE_INFO = import.meta.env.VITE_SHOW_BATTLE_INFO;

export const VITE_SHOW_LOCAL_SIGN_IN = import.meta.env.VITE_SHOW_LOCAL_SIGN_IN;

export const ENV = import.meta.env.VITE_VERCEL_ENV;

export const VITE_NEW_HOME_ENABLED =
  import.meta.env.VITE_NEW_HOME_ENABLED === 'true';

export const VITE_BATTLE_REPLAY_ENABLED =
  import.meta.env.VITE_BATTLE_REPLAY_ENABLED === 'true';

export const VITE_BATTLE_AIRDROP_ENABLED =
  import.meta.env.VITE_BATTLE_AIRDROP_ENABLED === 'true';

export const VITE_SHOW_WEEKLY_LEADERBOARD =
  import.meta.env.VITE_SHOW_WEEKLY_LEADERBOARD === 'true';

export const VITE_TASK_ENERGY_REWARD_ENABLED =
  import.meta.env.VITE_TASK_ENERGY_REWARD_ENABLED === 'true';

export const VITE_AIRDROP_TIME_OVERRIDE = import.meta.env
  .VITE_AIRDROP_TIME_OVERRIDE;

export const VITE_AIRDROP_DISABLED_TIME = import.meta.env
  .VITE_AIRDROP_DISABLED_TIME;

export const VITE_FEED_TO_EARN_ENABLED =
  import.meta.env.VITE_FEED_TO_EARN_ENABLED === 'true';

export const VITE_NOTCOIN_ENABLED =
  import.meta.env.VITE_NOTCOIN_ENABLED === 'true';

export const VITE_PRIVY_WALLET_ENABLED =
  import.meta.env.VITE_PRIVY_WALLET_ENABLED === 'true';

export const VITE_OKX_MANTLE_LEADERBOARD_ENABLED =
  import.meta.env.VITE_OKX_MANTLE_LEADERBOARD_ENABLED === 'true';

export const VITE_ADSGRAM_ENABLED =
  import.meta.env.VITE_ADSGRAM_ENABLED === 'true';

export const isDev = import.meta.env.VITE_FIREBASE_PROJECTID === 'genopets-dev';
export const isProd = import.meta.env.VITE_FIREBASE_PROJECTID === 'genopets';

export const VITE_ADSGRAM_BLOCK_ID = isProd ? '5139' : '5138';

// our wallet provider (privy) doesn't support mantle testnet network yet
export const MANTLE_MAINNET_CHAIN_ID = 5000;
export const ETHEREUM_CHAIN_ID = 1;
