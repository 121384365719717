// used to construct transaction
export const mantleCheckInAbi = [
  {
    inputs: [{ name: 'userId', type: 'string' }],
    name: 'checkIn',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

// used to decode data from a transaction
export const mantleCheckInEventAbi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'string',
        name: 'userId',
        type: 'string',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    name: 'CheckIn',
    type: 'event',
  },
];

export const MANTLE_MAINNET_CHECKIN_CONTRACT_ADDRESS = `0xdDF1F22EaC73A52145C9dB97534Ff11159Bd5431`;
