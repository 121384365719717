import {
  BattleEntity,
  BattleNextAction,
  BattleState,
  BattleStatus,
  RootState,
  initBattle,
  resetBattle,
  store,
} from 'genopets-utils';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { BattleMode, useUIBattleContext } from '../context/useBattleContext';
import { useSelector } from 'react-redux';
import { useAlert } from '@/hooks/useAlert';
import { startBattle } from '@/lib/sounds';
import { useTranslation } from 'react-i18next';
import { useEffectPlayer } from '@/context/EffectPlayerContext';

export const useDispatchBattleActions = (battle?: BattleEntity) => {
  const [initialized, setInitialized] = useState(false); // used to prevent the battle from replaying all animation when rejoin

  const { setLoading, user, mode, setMode } = useUIBattleContext();

  const { t: translateText } = useTranslation();

  const alert = useAlert();

  const effectPlayer = useEffectPlayer();

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  useEffect(() => {
    // if we do not have a battle and the battle state is not in the initial state, reset the store
    if (!battle && battleState.nextAction !== BattleNextAction.INIT) {
      console.log(`reset battle no battle from database`);
      store.dispatch(resetBattle());
    }

    if (battle?.id && battleState?.id && battle.id !== battleState.id) {
      console.log(`reset battle due to battle id changed`);
      store.dispatch(resetBattle());
    }

    if (
      battle?.status === BattleStatus.ONGOING ||
      battle?.status === BattleStatus.ENDED
    ) {
      if (
        isEmpty(battleState.actions) &&
        battleState.nextAction === BattleNextAction.INIT
      ) {
        setInitialized(false);
        store.dispatch(
          initBattle({
            ...battle,
            startTime: { ...battle.startTime } as any,
            endTime: { ...battle.endTime } as any,
            withHistory: mode === BattleMode.REPLAY ? true : false,
            withUiEvents: true,
          }),
        );
        if (mode !== BattleMode.REPLAY) {
          setMode(BattleMode.BATTLE);
        }
      }

      if (mode === BattleMode.REPLAY) {
        setInitialized(true);
      } else {
        if (isEmpty(battle.actions) && !initialized) {
          alert({
            duration: 3000,
            title: translateText(`opponentAppearedFkEmUp`, {
              name: battle.player1.id === user?.uid ? '2' : '1',
              defaultValue: `player ${
                battle.player1.id === user?.uid ? '2' : '1'
              } appeared! f*ck em up!`,
            }),
          });
          effectPlayer.playEffect(startBattle);
        }

        if (
          (battle.actions?.length ?? 0) > (battleState?.actions?.length ?? 0)
        ) {
          // replay all missing events compare to the document' events,
          // allow to rejoin a ongoing battle by replaying all events saved
          for (
            let i = 0;
            i <
            (battle.actions?.length ?? 0) - (battleState?.actions?.length ?? 0);
            i++
          ) {
            const action =
              battle.actions[(battleState.actions.length ?? 0) + i];
            store.dispatch(action);
          }
          setInitialized(true);
          setLoading(false);
        } else if (!initialized && battle.actions.length === 0) {
          setInitialized(true);
        }
      }
    }
  }, [battle]);
};
