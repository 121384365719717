import { useNavigate } from 'react-router-dom';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Text } from '@/components/ui/atoms/Text';
import { Button } from '../ui/molecules/Button';
import { useUserData } from '@/context/FirestoreContext';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

export const AlreadyFedView = () => {
  const { userData } = useUserData();
  const feedNextDayTimestampMs =
    userData?.telegram?.feedPetNextDayTimestampMs ?? 0;

  const [timeLeft, setTimeLeft] = useState('00:00:00');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = DateTime.utc();
      const endDate = DateTime.fromMillis(feedNextDayTimestampMs, {
        zone: 'utc',
      });
      const duration = endDate.diff(now, ['hours', 'minutes', 'seconds']);

      setTimeLeft(duration.toFormat('hh:mm:ss'));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <Text colorId="Black">
        <TranslatedText
          translationKey="genopetAlreadyFed"
          defaultMessage="you already fed your genopet today!"
        />
      </Text>

      <Text colorId="Black">
        <TranslatedText
          translationKey="timeUntilFeed"
          defaultMessage={`${timeLeft} until next feeding`}
          props={{
            time: timeLeft,
          }}
        />
      </Text>
      <Button
        colorId="Black"
        w="full"
        onClick={() => {
          navigate('home');
        }}
      >
        <TranslatedText translationKey="goHome" defaultMessage="Go home" />
      </Button>
    </>
  );
};
