export const HomeBattle = ({ ...props }) => {
  return (
    <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M14.8001 8.74707H12.8701V10.6771H14.8001V8.74707Z" fill="black"/>
<path d="M16.7303 8.74707H14.8003V10.6771H16.7303V8.74707Z" fill="black"/>
<path d="M18.66 8.74707H16.73V10.6771H18.66V8.74707Z" fill="black"/>
<path d="M20.6004 8.74707H18.6704V10.6771H20.6004V8.74707Z" fill="black"/>
<path d="M55.4002 8.74707H53.4702V10.6771H55.4002V8.74707Z" fill="black"/>
<path d="M57.3304 8.74707H55.4004V10.6771H57.3304V8.74707Z" fill="black"/>
<path d="M59.2601 8.74707H57.3301V10.6771H59.2601V8.74707Z" fill="black"/>
<path d="M61.2 8.74707H59.27V10.6771H61.2V8.74707Z" fill="black"/>
<path d="M14.8001 10.5871H12.8701V12.5171H14.8001V10.5871Z" fill="black"/>
<path d="M16.7303 10.5871H14.8003V12.5171H16.7303V10.5871Z" fill="#3863A0"/>
<path d="M18.66 10.5871H16.73V12.5171H18.66V10.5871Z" fill="#00CEEA"/>
<path d="M20.6004 10.5871H18.6704V12.5171H20.6004V10.5871Z" fill="#83F6FF"/>
<path d="M22.5301 10.5871H20.6001V12.5171H22.5301V10.5871Z" fill="black"/>
<path d="M53.4603 10.5871H51.5303V12.5171H53.4603V10.5871Z" fill="black"/>
<path d="M55.4002 10.5871H53.4702V12.5171H55.4002V10.5871Z" fill="#66FFE5"/>
<path d="M57.3304 10.5871H55.4004V12.5171H57.3304V10.5871Z" fill="#03BDA3"/>
<path d="M59.2601 10.5871H57.3301V12.5171H59.2601V10.5871Z" fill="#005145"/>
<path d="M61.2 10.6771H59.27V12.6071H61.2V10.6771Z" fill="black"/>
<path d="M14.8001 12.5171H12.8701V14.4471H14.8001V12.5171Z" fill="black"/>
<path d="M16.7303 12.5171H14.8003V14.4471H16.7303V12.5171Z" fill="#00CEEA"/>
<path d="M18.66 12.5171H16.73V14.4471H18.66V12.5171Z" fill="#3863A0"/>
<path d="M20.6004 12.5171H18.6704V14.4471H20.6004V12.5171Z" fill="#00CEEA"/>
<path d="M22.5301 12.5171H20.6001V14.4471H22.5301V12.5171Z" fill="#83F6FF"/>
<path d="M24.4603 12.5171H22.5303V14.4471H24.4603V12.5171Z" fill="black"/>
<path d="M51.5301 12.5171H49.6001V14.4471H51.5301V12.5171Z" fill="black"/>
<path d="M53.4603 12.5171H51.5303V14.4471H53.4603V12.5171Z" fill="#66FFE5"/>
<path d="M55.4002 12.5171H53.4702V14.4471H55.4002V12.5171Z" fill="#03BDA3"/>
<path d="M57.3304 12.5171H55.4004V14.4471H57.3304V12.5171Z" fill="#005145"/>
<path d="M59.2601 12.5171H57.3301V14.4471H59.2601V12.5171Z" fill="#03BDA3"/>
<path d="M61.2 12.6171H59.27V14.5471H61.2V12.6171Z" fill="black"/>
<path d="M14.8001 14.4471H12.8701V16.3771H14.8001V14.4471Z" fill="black"/>
<path d="M16.7303 14.4471H14.8003V16.3771H16.7303V14.4471Z" fill="#00CEEA"/>
<path d="M18.66 14.4471H16.73V16.3771H18.66V14.4471Z" fill="#00CEEA"/>
<path d="M20.6004 14.4471H18.6704V16.3771H20.6004V14.4471Z" fill="#3863A0"/>
<path d="M22.5301 14.4471H20.6001V16.3771H22.5301V14.4471Z" fill="#00CEEA"/>
<path d="M24.4603 14.4471H22.5303V16.3771H24.4603V14.4471Z" fill="#83F6FF"/>
<path d="M26.4002 14.4471H24.4702V16.3771H26.4002V14.4471Z" fill="black"/>
<path d="M49.6004 14.5471H47.6704V16.4771H49.6004V14.5471Z" fill="black"/>
<path d="M51.5301 14.4471H49.6001V16.3771H51.5301V14.4471Z" fill="#66FFE5"/>
<path d="M53.4603 14.4471H51.5303V16.3771H53.4603V14.4471Z" fill="#03BDA3"/>
<path d="M55.4002 14.4471H53.4702V16.3771H55.4002V14.4471Z" fill="#005145"/>
<path d="M57.3304 14.4471H55.4004V16.3771H57.3304V14.4471Z" fill="#03BDA3"/>
<path d="M59.2601 14.4471H57.3301V16.3771H59.2601V14.4471Z" fill="#03877D"/>
<path d="M61.2 14.5471H59.27V16.4771H61.2V14.5471Z" fill="black"/>
<path d="M14.8001 16.3871H12.8701V18.3171H14.8001V16.3871Z" fill="black"/>
<path d="M16.7303 16.3871H14.8003V18.3171H16.7303V16.3871Z" fill="#00B1E8"/>
<path d="M18.66 16.3871H16.73V18.3171H18.66V16.3871Z" fill="#00CEEA"/>
<path d="M20.6004 16.3871H18.6704V18.3171H20.6004V16.3871Z" fill="#00CEEA"/>
<path d="M22.5301 16.3871H20.6001V18.3171H22.5301V16.3871Z" fill="#3863A0"/>
<path d="M24.4603 16.3871H22.5303V18.3171H24.4603V16.3871Z" fill="#00CEEA"/>
<path d="M26.4002 16.3871H24.4702V18.3171H26.4002V16.3871Z" fill="#83F6FF"/>
<path d="M28.3304 16.3871H26.4004V18.3171H28.3304V16.3871Z" fill="black"/>
<path d="M47.66 16.4771H45.73V18.407H47.66V16.4771Z" fill="black"/>
<path d="M49.6004 16.3871H47.6704V18.3171H49.6004V16.3871Z" fill="#66FFE5"/>
<path d="M51.5301 16.3871H49.6001V18.3171H51.5301V16.3871Z" fill="#03BDA3"/>
<path d="M53.4603 16.3871H51.5303V18.3171H53.4603V16.3871Z" fill="#005145"/>
<path d="M55.4002 16.3871H53.4702V18.3171H55.4002V16.3871Z" fill="#03BDA3"/>
<path d="M57.3304 16.3871H55.4004V18.3171H57.3304V16.3871Z" fill="#03BDA3"/>
<path d="M59.2601 16.3871H57.3301V18.3171H59.2601V16.3871Z" fill="#03877D"/>
<path d="M61.2 16.4771H59.27V18.407H61.2V16.4771Z" fill="black"/>
<path d="M16.7303 18.3171H14.8003V20.2471H16.7303V18.3171Z" fill="black"/>
<path d="M18.66 18.3171H16.73V20.2471H18.66V18.3171Z" fill="#00B1E8"/>
<path d="M20.6004 18.3171H18.6704V20.2471H20.6004V18.3171Z" fill="#00B1E8"/>
<path d="M22.5301 18.3171H20.6001V20.2471H22.5301V18.3171Z" fill="#00CEEA"/>
<path d="M24.4603 18.3171H22.5303V20.2471H24.4603V18.3171Z" fill="#3863A0"/>
<path d="M26.4002 18.3171H24.4702V20.2471H26.4002V18.3171Z" fill="#00CEEA"/>
<path d="M28.3304 18.3171H26.4004V20.2471H28.3304V18.3171Z" fill="#83F6FF"/>
<path d="M30.2601 18.3171H28.3301V20.2471H30.2601V18.3171Z" fill="black"/>
<path d="M45.7303 18.4171H43.8003V20.3471H45.7303V18.4171Z" fill="black"/>
<path d="M47.66 18.3171H45.73V20.2471H47.66V18.3171Z" fill="#66FFE5"/>
<path d="M49.6004 18.3171H47.6704V20.2471H49.6004V18.3171Z" fill="#03BDA3"/>
<path d="M51.5301 18.3171H49.6001V20.2471H51.5301V18.3171Z" fill="#005145"/>
<path d="M53.4603 18.3171H51.5303V20.2471H53.4603V18.3171Z" fill="#03BDA3"/>
<path d="M55.4002 18.3171H53.4702V20.2471H55.4002V18.3171Z" fill="#03BDA3"/>
<path d="M57.3304 18.3171H55.4004V20.2471H57.3304V18.3171Z" fill="#03877D"/>
<path d="M59.2601 18.3171H57.3301V20.2471H59.2601V18.3171Z" fill="black"/>
<path d="M18.66 20.2471H16.73V22.1771H18.66V20.2471Z" fill="black"/>
<path d="M20.6004 20.2471H18.6704V22.1771H20.6004V20.2471Z" fill="#00B1E8"/>
<path d="M22.5301 20.2471H20.6001V22.1771H22.5301V20.2471Z" fill="#00B1E8"/>
<path d="M24.4603 20.2471H22.5303V22.1771H24.4603V20.2471Z" fill="#00CEEA"/>
<path d="M26.4002 20.2471H24.4702V22.1771H26.4002V20.2471Z" fill="#3863A0"/>
<path d="M28.3304 20.2471H26.4004V22.1771H28.3304V20.2471Z" fill="#00CEEA"/>
<path d="M30.2601 20.2471H28.3301V22.1771H30.2601V20.2471Z" fill="#83F6FF"/>
<path d="M32.2 20.2471H30.27V22.1771H32.2V20.2471Z" fill="black"/>
<path d="M43.8001 20.347H41.8701V22.277H43.8001V20.347Z" fill="black"/>
<path d="M45.7303 20.2471H43.8003V22.1771H45.7303V20.2471Z" fill="#66FFE5"/>
<path d="M47.66 20.2471H45.73V22.1771H47.66V20.2471Z" fill="#03BDA3"/>
<path d="M49.6004 20.2471H47.6704V22.1771H49.6004V20.2471Z" fill="#005145"/>
<path d="M51.5301 20.2471H49.6001V22.1771H51.5301V20.2471Z" fill="#03BDA3"/>
<path d="M53.4603 20.2471H51.5303V22.1771H53.4603V20.2471Z" fill="#03877D"/>
<path d="M55.4002 20.2471H53.4702V22.1771H55.4002V20.2471Z" fill="#03877D"/>
<path d="M57.3304 20.2471H55.4004V22.1771H57.3304V20.2471Z" fill="black"/>
<path d="M20.6004 22.1871H18.6704V24.1171H20.6004V22.1871Z" fill="black"/>
<path d="M22.5301 22.1871H20.6001V24.1171H22.5301V22.1871Z" fill="black"/>
<path d="M24.4603 22.1871H22.5303V24.1171H24.4603V22.1871Z" fill="#00B1E8"/>
<path d="M26.4002 22.1871H24.4702V24.1171H26.4002V22.1871Z" fill="#00CEEA"/>
<path d="M28.3304 22.1871H26.4004V24.1171H28.3304V22.1871Z" fill="#3863A0"/>
<path d="M30.2601 22.1871H28.3301V24.1171H30.2601V22.1871Z" fill="#00CEEA"/>
<path d="M32.2 22.1871H30.27V24.1171H32.2V22.1871Z" fill="#83F6FF"/>
<path d="M34.1302 22.1871H32.2002V24.1171H34.1302V22.1871Z" fill="black"/>
<path d="M41.8602 22.1871H39.9302V24.1171H41.8602V22.1871Z" fill="black"/>
<path d="M43.8001 22.2771H41.8701V24.2071H43.8001V22.2771Z" fill="#66FFE5"/>
<path d="M45.7303 22.1871H43.8003V24.1171H45.7303V22.1871Z" fill="#03BDA3"/>
<path d="M47.66 22.1871H45.73V24.1171H47.66V22.1871Z" fill="#005145"/>
<path d="M49.6004 22.1871H47.6704V24.1171H49.6004V22.1871Z" fill="#03BDA3"/>
<path d="M51.5301 22.1871H49.6001V24.1171H51.5301V22.1871Z" fill="#03877D"/>
<path d="M53.4603 22.1871H51.5303V24.1171H53.4603V22.1871Z" fill="black"/>
<path d="M55.4002 22.1871H53.4702V24.1171H55.4002V22.1871Z" fill="black"/>
<path d="M24.4603 24.1171H22.5303V26.0471H24.4603V24.1171Z" fill="black"/>
<path d="M26.4002 24.1171H24.4702V26.0471H26.4002V24.1171Z" fill="#00B1E8"/>
<path d="M28.3304 24.1171H26.4004V26.0471H28.3304V24.1171Z" fill="#00CEEA"/>
<path d="M30.2601 24.1171H28.3301V26.0471H30.2601V24.1171Z" fill="#3863A0"/>
<path d="M32.2 24.1171H30.27V26.0471H32.2V24.1171Z" fill="#00CEEA"/>
<path d="M34.1302 24.1171H32.2002V26.0471H34.1302V24.1171Z" fill="#83F6FF"/>
<path d="M36.0604 24.1171H34.1304V26.0471H36.0604V24.1171Z" fill="black"/>
<path d="M39.93 24.217H38V26.147H39.93V24.217Z" fill="black"/>
<path d="M41.8602 24.1171H39.9302V26.0471H41.8602V24.1171Z" fill="#66FFE5"/>
<path d="M43.8001 24.1171H41.8701V26.0471H43.8001V24.1171Z" fill="#03BDA3"/>
<path d="M45.7303 24.1171H43.8003V26.0471H45.7303V24.1171Z" fill="#005145"/>
<path d="M47.66 24.1171H45.73V26.0471H47.66V24.1171Z" fill="#03BDA3"/>
<path d="M49.6004 24.1171H47.6704V26.0471H49.6004V24.1171Z" fill="#03877D"/>
<path d="M51.5301 24.1171H49.6001V26.0471H51.5301V24.1171Z" fill="black"/>
<path d="M26.4002 26.0471H24.4702V27.9771H26.4002V26.0471Z" fill="black"/>
<path d="M28.3304 26.0471H26.4004V27.9771H28.3304V26.0471Z" fill="#00B1E8"/>
<path d="M30.2601 26.0471H28.3301V27.9771H30.2601V26.0471Z" fill="#00CEEA"/>
<path d="M32.2 26.0471H30.27V27.9771H32.2V26.0471Z" fill="#3863A0"/>
<path d="M34.1302 26.0471H32.2002V27.9771H34.1302V26.0471Z" fill="#00CEEA"/>
<path d="M36.0604 26.0471H34.1304V27.9771H36.0604V26.0471Z" fill="#83F6FF"/>
<path d="M38.0003 26.0471H36.0703V27.9771H38.0003V26.0471Z" fill="black"/>
<path d="M39.93 26.0471H38V27.9771H39.93V26.0471Z" fill="black"/>
<path d="M41.8602 26.0471H39.9302V27.9771H41.8602V26.0471Z" fill="#03BDA3"/>
<path d="M43.8001 26.0471H41.8701V27.9771H43.8001V26.0471Z" fill="#005145"/>
<path d="M45.7303 26.0471H43.8003V27.9771H45.7303V26.0471Z" fill="#03BDA3"/>
<path d="M47.66 26.0471H45.73V27.9771H47.66V26.0471Z" fill="#03877D"/>
<path d="M49.6004 26.0471H47.6704V27.9771H49.6004V26.0471Z" fill="black"/>
<path d="M28.3304 27.9871H26.4004V29.9171H28.3304V27.9871Z" fill="black"/>
<path d="M30.2601 27.9871H28.3301V29.9171H30.2601V27.9871Z" fill="#00B1E8"/>
<path d="M32.2 27.9871H30.27V29.9171H32.2V27.9871Z" fill="#00CEEA"/>
<path d="M34.1302 27.9871H32.2002V29.9171H34.1302V27.9871Z" fill="#3863A0"/>
<path d="M36.0604 27.9871H34.1304V29.9171H36.0604V27.9871Z" fill="#00CEEA"/>
<path d="M38.0003 27.9871H36.0703V29.9171H38.0003V27.9871Z" fill="#00CEEA"/>
<path d="M39.93 27.9871H38V29.9171H39.93V27.9871Z" fill="black"/>
<path d="M41.8602 27.9871H39.9302V29.9171H41.8602V27.9871Z" fill="black"/>
<path d="M43.8001 27.9871H41.8701V29.9171H43.8001V27.9871Z" fill="#03BDA3"/>
<path d="M45.7303 27.9871H43.8003V29.9171H45.7303V27.9871Z" fill="#03877D"/>
<path d="M47.66 27.9871H45.73V29.9171H47.66V27.9871Z" fill="black"/>
<path d="M30.2601 29.9171H28.3301V31.8471H30.2601V29.9171Z" fill="black"/>
<path d="M32.2 29.9171H30.27V31.8471H32.2V29.9171Z" fill="#00B1E8"/>
<path d="M34.1302 29.9171H32.2002V31.8471H34.1302V29.9171Z" fill="#00CEEA"/>
<path d="M36.0604 29.9171H34.1304V31.8471H36.0604V29.9171Z" fill="#3863A0"/>
<path d="M38.0003 29.9171H36.0703V31.8471H38.0003V29.9171Z" fill="#00CEEA"/>
<path d="M39.93 29.9171H38V31.8471H39.93V29.9171Z" fill="#00CEEA"/>
<path d="M41.8602 29.9171H39.9302V31.8471H41.8602V29.9171Z" fill="black"/>
<path d="M43.8001 29.9171H41.8701V31.8471H43.8001V29.9171Z" fill="#03877D"/>
<path d="M45.7303 29.9171H43.8003V31.8471H45.7303V29.9171Z" fill="black"/>
<path d="M30.2601 31.847H28.3301V33.777H30.2601V31.847Z" fill="black"/>
<path d="M32.2 31.847H30.27V33.777H32.2V31.847Z" fill="black"/>
<path d="M34.1302 31.847H32.2002V33.777H34.1302V31.847Z" fill="#00B1E8"/>
<path d="M36.0604 31.847H34.1304V33.777H36.0604V31.847Z" fill="#00CEEA"/>
<path d="M38.0003 31.847H36.0703V33.777H38.0003V31.847Z" fill="#3863A0"/>
<path d="M39.93 31.847H38V33.777H39.93V31.847Z" fill="#00CEEA"/>
<path d="M41.8602 31.847H39.9302V33.777H41.8602V31.847Z" fill="#00CEEA"/>
<path d="M43.8001 31.847H41.8701V33.777H43.8001V31.847Z" fill="black"/>
<path d="M45.7303 31.847H43.8003V33.777H45.7303V31.847Z" fill="black"/>
<path d="M28.3304 33.787H26.4004V35.717H28.3304V33.787Z" fill="black"/>
<path d="M30.2601 33.787H28.3301V35.717H30.2601V33.787Z" fill="#03877D"/>
<path d="M32.2 33.787H30.27V35.717H32.2V33.787Z" fill="#03877D"/>
<path d="M34.1302 33.787H32.2002V35.717H34.1302V33.787Z" fill="black"/>
<path d="M36.0604 33.787H34.1304V35.717H36.0604V33.787Z" fill="#00B1E8"/>
<path d="M38.0003 33.787H36.0703V35.717H38.0003V33.787Z" fill="#00CEEA"/>
<path d="M39.93 33.787H38V35.717H39.93V33.787Z" fill="#3863A0"/>
<path d="M41.8602 33.787H39.9302V35.717H41.8602V33.787Z" fill="#00CEEA"/>
<path d="M43.8001 33.787H41.8701V35.717H43.8001V33.787Z" fill="#00CEEA"/>
<path d="M45.7303 33.787H43.8003V35.717H45.7303V33.787Z" fill="black"/>
<path d="M47.66 33.787H45.73V35.717H47.66V33.787Z" fill="black"/>
<path d="M14.8001 35.717H12.8701V37.647H14.8001V35.717Z" fill="black"/>
<path d="M16.7303 35.717H14.8003V37.647H16.7303V35.717Z" fill="black"/>
<path d="M26.4002 35.717H24.4702V37.647H26.4002V35.717Z" fill="black"/>
<path d="M28.3304 35.717H26.4004V37.647H28.3304V35.717Z" fill="#03BDA3"/>
<path d="M30.2601 35.717H28.3301V37.647H30.2601V35.717Z" fill="#03BDA3"/>
<path d="M32.2 35.717H30.27V37.647H32.2V35.717Z" fill="#005145"/>
<path d="M34.1302 35.717H32.2002V37.647H34.1302V35.717Z" fill="#03877D"/>
<path d="M36.0604 35.717H34.1304V37.647H36.0604V35.717Z" fill="black"/>
<path d="M38.0003 35.717H36.0703V37.647H38.0003V35.717Z" fill="#00B1E8"/>
<path d="M39.93 35.717H38V37.647H39.93V35.717Z" fill="#00CEEA"/>
<path d="M41.8602 35.717H39.9302V37.647H41.8602V35.717Z" fill="#3863A0"/>
<path d="M43.8001 35.717H41.8701V37.647H43.8001V35.717Z" fill="#00CEEA"/>
<path d="M45.7303 35.717H43.8003V37.647H45.7303V35.717Z" fill="#00CEEA"/>
<path d="M47.66 35.717H45.73V37.647H47.66V35.717Z" fill="black"/>
<path d="M59.2601 35.717H57.3301V37.647H59.2601V35.717Z" fill="black"/>
<path d="M61.2 35.717H59.27V37.647H61.2V35.717Z" fill="black"/>
<path d="M14.8001 37.6471H12.8701V39.5771H14.8001V37.6471Z" fill="black"/>
<path d="M16.7303 37.6471H14.8003V39.5771H16.7303V37.6471Z" fill="#00887D"/>
<path d="M18.66 37.6471H16.73V39.5771H18.66V37.6471Z" fill="black"/>
<path d="M24.4603 37.6471H22.5303V39.5771H24.4603V37.6471Z" fill="black"/>
<path d="M26.4002 37.6471H24.4702V39.5771H26.4002V37.6471Z" fill="#03BDA3"/>
<path d="M28.3304 37.6471H26.4004V39.5771H28.3304V37.6471Z" fill="#03BDA3"/>
<path d="M30.2601 37.6471H28.3301V39.5771H30.2601V37.6471Z" fill="#005145"/>
<path d="M32.2 37.6471H30.27V39.5771H32.2V37.6471Z" fill="#03BDA3"/>
<path d="M34.1302 37.6471H32.2002V39.5771H34.1302V37.6471Z" fill="#03BDA3"/>
<path d="M36.0604 37.6471H34.1304V39.5771H36.0604V37.6471Z" fill="#03877D"/>
<path d="M38.0003 37.6471H36.0703V39.5771H38.0003V37.6471Z" fill="black"/>
<path d="M39.93 37.6471H38V39.5771H39.93V37.6471Z" fill="#00B1E8"/>
<path d="M41.8602 37.6471H39.9302V39.5771H41.8602V37.6471Z" fill="#00CEEA"/>
<path d="M43.8001 37.6471H41.8701V39.5771H43.8001V37.6471Z" fill="#3863A0"/>
<path d="M45.7303 37.6471H43.8003V39.5771H45.7303V37.6471Z" fill="#00CEEA"/>
<path d="M47.66 37.6471H45.73V39.5771H47.66V37.6471Z" fill="#00CEEA"/>
<path d="M49.6004 37.6471H47.6704V39.5771H49.6004V37.6471Z" fill="black"/>
<path d="M57.3304 37.6471H55.4004V39.5771H57.3304V37.6471Z" fill="black"/>
<path d="M59.2601 37.6471H57.3301V39.5771H59.2601V37.6471Z" fill="#3863A0"/>
<path d="M61.2 37.6471H59.27V39.5771H61.2V37.6471Z" fill="#3863A0"/>
<path d="M12.8602 39.5871H10.9302V41.5171H12.8602V39.5871Z" fill="black"/>
<path d="M14.8001 39.5871H12.8701V41.5171H14.8001V39.5871Z" fill="#004C43"/>
<path d="M16.7303 39.5871H14.8003V41.5171H16.7303V39.5871Z" fill="#00887D"/>
<path d="M18.66 39.5871H16.73V41.5171H18.66V39.5871Z" fill="#00887D"/>
<path d="M20.6004 39.5871H18.6704V41.5171H20.6004V39.5871Z" fill="black"/>
<path d="M24.4603 39.5871H22.5303V41.5171H24.4603V39.5871Z" fill="#03BDA3"/>
<path d="M22.5301 39.5871H20.6001V41.5171H22.5301V39.5871Z" fill="black"/>
<path d="M26.4002 39.5871H24.4702V41.5171H26.4002V39.5871Z" fill="#03BDA3"/>
<path d="M28.3304 39.5871H26.4004V41.5171H28.3304V39.5871Z" fill="#005145"/>
<path d="M30.2601 39.5871H28.3301V41.5171H30.2601V39.5871Z" fill="#03BDA3"/>
<path d="M32.2 39.5871H30.27V41.5171H32.2V39.5871Z" fill="#03877D"/>
<path d="M34.1302 39.5871H32.2002V41.5171H34.1302V39.5871Z" fill="#03877D"/>
<path d="M36.0604 39.5871H34.1304V41.5171H36.0604V39.5871Z" fill="black"/>
<path d="M39.93 39.5871H38V41.5171H39.93V39.5871Z" fill="black"/>
<path d="M41.8602 39.5871H39.9302V41.5171H41.8602V39.5871Z" fill="#00B1E8"/>
<path d="M43.8001 39.5871H41.8701V41.5171H43.8001V39.5871Z" fill="#00CEEA"/>
<path d="M45.7303 39.5871H43.8003V41.5171H45.7303V39.5871Z" fill="#3863A0"/>
<path d="M47.66 39.5871H45.73V41.5171H47.66V39.5871Z" fill="#00CEEA"/>
<path d="M49.6004 39.5871H47.6704V41.5171H49.6004V39.5871Z" fill="#00CEEA"/>
<path d="M51.5301 39.5871H49.6001V41.5171H51.5301V39.5871Z" fill="black"/>
<path d="M55.4002 39.5871H53.4702V41.5171H55.4002V39.5871Z" fill="black"/>
<path d="M57.3304 39.5871H55.4004V41.5171H57.3304V39.5871Z" fill="#3863A0"/>
<path d="M59.2601 39.5871H57.3301V41.5171H59.2601V39.5871Z" fill="#3863A0"/>
<path d="M61.2 39.5871H59.27V41.5171H61.2V39.5871Z" fill="#113A77"/>
<path d="M63.1302 39.5871H61.2002V41.5171H63.1302V39.5871Z" fill="black"/>
<path d="M63.1302 37.6471H61.2002V39.5771H63.1302V37.6471Z" fill="black"/>
<path d="M12.8602 41.5171H10.9302V43.4471H12.8602V41.5171Z" fill="black"/>
<path d="M14.8001 41.5171H12.8701V43.4471H14.8001V41.5171Z" fill="#004C43"/>
<path d="M16.7303 41.5171H14.8003V43.4471H16.7303V41.5171Z" fill="#004C43"/>
<path d="M18.66 41.5171H16.73V43.4471H18.66V41.5171Z" fill="#00887D"/>
<path d="M20.6004 41.5171H18.6704V43.4471H20.6004V41.5171Z" fill="#00887D"/>
<path d="M22.5301 41.5171H20.6001V43.4471H22.5301V41.5171Z" fill="black"/>
<path d="M24.4603 41.5171H22.5303V43.4471H24.4603V41.5171Z" fill="#03BDA3"/>
<path d="M26.4002 41.5171H24.4702V43.4471H26.4002V41.5171Z" fill="#005145"/>
<path d="M28.3304 41.5171H26.4004V43.4471H28.3304V41.5171Z" fill="#03BDA3"/>
<path d="M30.2601 41.5171H28.3301V43.4471H30.2601V41.5171Z" fill="#03877D"/>
<path d="M32.2 41.5171H30.27V43.4471H32.2V41.5171Z" fill="#03877D"/>
<path d="M34.1302 41.5171H32.2002V43.4471H34.1302V41.5171Z" fill="black"/>
<path d="M41.8602 41.5171H39.9302V43.4471H41.8602V41.5171Z" fill="black"/>
<path d="M43.8001 41.5171H41.8701V43.4471H43.8001V41.5171Z" fill="#00B1E8"/>
<path d="M45.7303 41.5171H43.8003V43.4471H45.7303V41.5171Z" fill="#00CEEA"/>
<path d="M47.66 41.5171H45.73V43.4471H47.66V41.5171Z" fill="#3863A0"/>
<path d="M49.6004 41.5171H47.6704V43.4471H49.6004V41.5171Z" fill="#00CEEA"/>
<path d="M51.5301 41.5171H49.6001V43.4471H51.5301V41.5171Z" fill="#00B1E8"/>
<path d="M53.4603 41.5171H51.5303V43.4471H53.4603V41.5171Z" fill="black"/>
<path d="M55.4002 41.5171H53.4702V43.4471H55.4002V41.5171Z" fill="#3863A0"/>
<path d="M57.3304 41.5171H55.4004V43.4471H57.3304V41.5171Z" fill="#3863A0"/>
<path d="M59.2601 41.5171H57.3301V43.4471H59.2601V41.5171Z" fill="#113A77"/>
<path d="M61.2 41.5171H59.27V43.4471H61.2V41.5171Z" fill="black"/>
<path d="M63.1302 41.5171H61.2002V43.4471H63.1302V41.5171Z" fill="black"/>
<path d="M14.8001 43.4471H12.8701V45.3771H14.8001V43.4471Z" fill="black"/>
<path d="M16.7303 43.4471H14.8003V45.3771H16.7303V43.4471Z" fill="black"/>
<path d="M18.66 43.4471H16.73V45.3771H18.66V43.4471Z" fill="#004C43"/>
<path d="M20.6004 43.4471H18.6704V45.3771H20.6004V43.4471Z" fill="#004C43"/>
<path d="M22.5301 43.4471H20.6001V45.3771H22.5301V43.4471Z" fill="#00887D"/>
<path d="M24.4603 43.4471H22.5303V45.3771H24.4603V43.4471Z" fill="black"/>
<path d="M26.4002 43.4471H24.4702V45.3771H26.4002V43.4471Z" fill="#03BDA3"/>
<path d="M28.3304 43.4471H26.4004V45.3771H28.3304V43.4471Z" fill="#03877D"/>
<path d="M30.2601 43.4471H28.3301V45.3771H30.2601V43.4471Z" fill="black"/>
<path d="M32.2 43.4471H30.27V45.3771H32.2V43.4471Z" fill="black"/>
<path d="M43.8001 43.4471H41.8701V45.3771H43.8001V43.4471Z" fill="black"/>
<path d="M45.7303 43.4471H43.8003V45.3771H45.7303V43.4471Z" fill="#00B1E8"/>
<path d="M47.66 43.4471H45.73V45.3771H47.66V43.4471Z" fill="#00B1E8"/>
<path d="M49.6004 43.4471H47.6704V45.3771H49.6004V43.4471Z" fill="#3863A0"/>
<path d="M51.5301 43.4471H49.6001V45.3771H51.5301V43.4471Z" fill="black"/>
<path d="M53.4603 43.4471H51.5303V45.3771H53.4603V43.4471Z" fill="#3863A0"/>
<path d="M55.4002 43.4471H53.4702V45.3771H55.4002V43.4471Z" fill="#113A77"/>
<path d="M57.3304 43.4471H55.4004V45.3771H57.3304V43.4471Z" fill="#113A77"/>
<path d="M59.2601 43.4471H57.3301V45.3771H59.2601V43.4471Z" fill="black"/>
<path d="M61.2 43.4471H59.27V45.3771H61.2V43.4471Z" fill="black"/>
<path d="M16.7303 45.3871H14.8003V47.3171H16.7303V45.3871Z" fill="black"/>
<path d="M18.66 45.3871H16.73V47.3171H18.66V45.3871Z" fill="black"/>
<path d="M20.6004 45.3871H18.6704V47.3171H20.6004V45.3871Z" fill="black"/>
<path d="M22.5301 45.3871H20.6001V47.3171H22.5301V45.3871Z" fill="#004C43"/>
<path d="M24.4603 45.3871H22.5303V47.3171H24.4603V45.3871Z" fill="#00887D"/>
<path d="M26.4002 45.3871H24.4702V47.3171H26.4002V45.3871Z" fill="black"/>
<path d="M28.3304 45.3871H26.4004V47.3171H28.3304V45.3871Z" fill="black"/>
<path d="M30.2601 45.3871H28.3301V47.3171H30.2601V45.3871Z" fill="black"/>
<path d="M43.8001 45.3871H41.8701V47.3171H43.8001V45.3871Z" fill="black"/>
<path d="M45.7303 45.3871H43.8003V47.3171H45.7303V45.3871Z" fill="black"/>
<path d="M47.66 45.3871H45.73V47.3171H47.66V45.3871Z" fill="black"/>
<path d="M49.6004 45.3871H47.6704V47.3171H49.6004V45.3871Z" fill="black"/>
<path d="M51.5301 45.3871H49.6001V47.3171H51.5301V45.3871Z" fill="#3863A0"/>
<path d="M53.4603 45.3871H51.5303V47.3171H53.4603V45.3871Z" fill="#113A77"/>
<path d="M55.4002 45.3871H53.4702V47.3171H55.4002V45.3871Z" fill="black"/>
<path d="M57.3304 45.3871H55.4004V47.3171H57.3304V45.3871Z" fill="black"/>
<path d="M59.2601 45.3871H57.3301V47.3171H59.2601V45.3871Z" fill="black"/>
<path d="M16.7303 47.3171H14.8003V49.2471H16.7303V47.3171Z" fill="black"/>
<path d="M18.66 47.3171H16.73V49.2471H18.66V47.3171Z" fill="#00887D"/>
<path d="M20.6004 47.3171H18.6704V49.2471H20.6004V47.3171Z" fill="#00887D"/>
<path d="M22.5301 47.3171H20.6001V49.2471H22.5301V47.3171Z" fill="black"/>
<path d="M24.4603 47.3171H22.5303V49.2471H24.4603V47.3171Z" fill="#004C43"/>
<path d="M26.4002 47.3171H24.4702V49.2471H26.4002V47.3171Z" fill="#00887D"/>
<path d="M28.3304 47.3171H26.4004V49.2471H28.3304V47.3171Z" fill="black"/>
<path d="M30.2601 47.3171H28.3301V49.2471H30.2601V47.3171Z" fill="black"/>
<path d="M45.7303 47.3171H43.8003V49.2471H45.7303V47.3171Z" fill="black"/>
<path d="M47.66 47.3171H45.73V49.2471H47.66V47.3171Z" fill="#3863A0"/>
<path d="M49.6004 47.3171H47.6704V49.2471H49.6004V47.3171Z" fill="#3863A0"/>
<path d="M51.5301 47.3171H49.6001V49.2471H51.5301V47.3171Z" fill="#113A77"/>
<path d="M53.4603 47.3171H51.5303V49.2471H53.4603V47.3171Z" fill="black"/>
<path d="M55.4002 47.3171H53.4702V49.2471H55.4002V47.3171Z" fill="#3863A0"/>
<path d="M57.3304 47.3171H55.4004V49.2471H57.3304V47.3171Z" fill="#113A77"/>
<path d="M59.2601 47.3171H57.3301V49.2471H59.2601V47.3171Z" fill="black"/>
<path d="M14.8001 49.2471H12.8701V51.1771H14.8001V49.2471Z" fill="black"/>
<path d="M16.7303 49.2471H14.8003V51.1771H16.7303V49.2471Z" fill="#00887D"/>
<path d="M18.66 49.2471H16.73V51.1771H18.66V49.2471Z" fill="#00887D"/>
<path d="M20.6004 49.2471H18.6704V51.1771H20.6004V49.2471Z" fill="#004C43"/>
<path d="M22.5301 49.2471H20.6001V51.1771H22.5301V49.2471Z" fill="black"/>
<path d="M24.4603 49.2471H22.5303V51.1771H24.4603V49.2471Z" fill="#004C43"/>
<path d="M26.4002 49.2471H24.4702V51.1771H26.4002V49.2471Z" fill="#00887D"/>
<path d="M28.3304 49.2471H26.4004V51.1771H28.3304V49.2471Z" fill="#00887D"/>
<path d="M30.2601 49.2471H28.3301V51.1771H30.2601V49.2471Z" fill="black"/>
<path d="M32.2 49.2471H30.27V51.1771H32.2V49.2471Z" fill="black"/>
<path d="M43.8001 49.2471H41.8701V51.1771H43.8001V49.2471Z" fill="black"/>
<path d="M45.7303 49.2471H43.8003V51.1771H45.7303V49.2471Z" fill="#113A77"/>
<path d="M47.66 49.2471H45.73V51.1771H47.66V49.2471Z" fill="#3863A0"/>
<path d="M49.6004 49.2471H47.6704V51.1771H49.6004V49.2471Z" fill="#3863A0"/>
<path d="M51.5301 49.2471H49.6001V51.1771H51.5301V49.2471Z" fill="#113A77"/>
<path d="M53.4603 49.2471H51.5303V51.1771H53.4603V49.2471Z" fill="black"/>
<path d="M55.4002 49.2471H53.4702V51.1771H55.4002V49.2471Z" fill="#3863A0"/>
<path d="M57.3304 49.2471H55.4004V51.1771H57.3304V49.2471Z" fill="#3863A0"/>
<path d="M59.2601 49.2471H57.3301V51.1771H59.2601V49.2471Z" fill="#113A77"/>
<path d="M61.2 49.2471H59.27V51.1771H61.2V49.2471Z" fill="black"/>
<path d="M12.8602 51.1771H10.9302V53.1071H12.8602V51.1771Z" fill="black"/>
<path d="M14.8001 51.1771H12.8701V53.1071H14.8001V51.1771Z" fill="#00887D"/>
<path d="M16.7303 51.1771H14.8003V53.1071H16.7303V51.1771Z" fill="#00887D"/>
<path d="M18.66 51.1771H16.73V53.1071H18.66V51.1771Z" fill="#004C43"/>
<path d="M20.6004 51.1771H18.6704V53.1071H20.6004V51.1771Z" fill="black"/>
<path d="M22.5301 51.1771H20.6001V53.1071H22.5301V51.1771Z" fill="black"/>
<path d="M24.4603 51.1771H22.5303V53.1071H24.4603V51.1771Z" fill="#004C43"/>
<path d="M26.4002 51.1771H24.4702V53.1071H26.4002V51.1771Z" fill="#004C43"/>
<path d="M28.3304 51.1771H26.4004V53.1071H28.3304V51.1771Z" fill="#00887D"/>
<path d="M30.2601 51.1771H28.3301V53.1071H30.2601V51.1771Z" fill="#00887D"/>
<path d="M32.2 51.1771H30.27V53.1071H32.2V51.1771Z" fill="#004C43"/>
<path d="M34.1302 51.1771H32.2002V53.1071H34.1302V51.1771Z" fill="black"/>
<path d="M43.8001 51.1771H41.8701V53.1071H43.8001V51.1771Z" fill="black"/>
<path d="M45.7303 51.1771H43.8003V53.1071H45.7303V51.1771Z" fill="#113A77"/>
<path d="M47.66 51.1771H45.73V53.1071H47.66V51.1771Z" fill="#3863A0"/>
<path d="M49.6004 51.1771H47.6704V53.1071H49.6004V51.1771Z" fill="#3863A0"/>
<path d="M51.5301 51.1771H49.6001V53.1071H51.5301V51.1771Z" fill="#113A77"/>
<path d="M53.4603 51.1771H51.5303V53.1071H53.4603V51.1771Z" fill="black"/>
<path d="M55.4002 51.1771H53.4702V53.1071H55.4002V51.1771Z" fill="#113A77"/>
<path d="M57.3304 51.1771H55.4004V53.1071H57.3304V51.1771Z" fill="#3863A0"/>
<path d="M59.2601 51.1771H57.3301V53.1071H59.2601V51.1771Z" fill="#3863A0"/>
<path d="M61.2 51.1771H59.27V53.1071H61.2V51.1771Z" fill="#3863A0"/>
<path d="M63.1302 51.1771H61.2002V53.1071H63.1302V51.1771Z" fill="black"/>
<path d="M10.93 53.1171H9V55.0471H10.93V53.1171Z" fill="black"/>
<path d="M12.8602 53.1171H10.9302V55.0471H12.8602V53.1171Z" fill="#00887D"/>
<path d="M14.8001 53.1171H12.8701V55.0471H14.8001V53.1171Z" fill="#00887D"/>
<path d="M16.7303 53.1171H14.8003V55.0471H16.7303V53.1171Z" fill="#004C43"/>
<path d="M18.66 53.1171H16.73V55.0471H18.66V53.1171Z" fill="black"/>
<path d="M20.6004 53.1171H18.6704V55.0471H20.6004V53.1171Z" fill="black"/>
<path d="M22.5301 53.1171H20.6001V55.0471H22.5301V53.1171Z" fill="black"/>
<path d="M24.4603 53.1171H22.5303V55.0471H24.4603V53.1171Z" fill="black"/>
<path d="M26.4002 53.1171H24.4702V55.0471H26.4002V53.1171Z" fill="black"/>
<path d="M28.3304 53.1171H26.4004V55.0471H28.3304V53.1171Z" fill="#004C43"/>
<path d="M30.2601 53.1171H28.3301V55.0471H30.2601V53.1171Z" fill="#004C43"/>
<path d="M32.2 53.1171H30.27V55.0471H32.2V53.1171Z" fill="black"/>
<path d="M34.1302 53.1171H32.2002V55.0471H34.1302V53.1171Z" fill="black"/>
<path d="M43.8001 53.1171H41.8701V55.0471H43.8001V53.1171Z" fill="black"/>
<path d="M45.7303 53.1171H43.8003V55.0471H45.7303V53.1171Z" fill="#113A77"/>
<path d="M47.66 53.1171H45.73V55.0471H47.66V53.1171Z" fill="#3863A0"/>
<path d="M49.6004 53.1171H47.6704V55.0471H49.6004V53.1171Z" fill="#3863A0"/>
<path d="M51.5301 53.1171H49.6001V55.0471H51.5301V53.1171Z" fill="black"/>
<path d="M53.4603 53.1171H51.5303V55.0471H53.4603V53.1171Z" fill="black"/>
<path d="M55.4002 53.1171H53.4702V55.0471H55.4002V53.1171Z" fill="black"/>
<path d="M57.3304 53.1171H55.4004V55.0471H57.3304V53.1171Z" fill="#113A77"/>
<path d="M59.2601 53.1171H57.3301V55.0471H59.2601V53.1171Z" fill="#3863A0"/>
<path d="M61.2 53.1171H59.27V55.0471H61.2V53.1171Z" fill="#3863A0"/>
<path d="M63.1302 53.1171H61.2002V55.0471H63.1302V53.1171Z" fill="#3863A0"/>
<path d="M65.0604 53.1171H63.1304V55.0471H65.0604V53.1171Z" fill="black"/>
<path d="M10.93 55.0471H9V56.9771H10.93V55.0471Z" fill="black"/>
<path d="M9.00031 55.0471H7.07031V56.9771H9.00031V55.0471Z" fill="black"/>
<path d="M7.07014 55.0471H5.14014V56.9771H7.07014V55.0471Z" fill="black"/>
<path d="M5.1302 55.0471H3.2002V56.9771H5.1302V55.0471Z" fill="black"/>
<path d="M5.1302 56.9771H3.2002V58.9071H5.1302V56.9771Z" fill="black"/>
<path d="M5.1302 58.9171H3.2002V60.8471H5.1302V58.9171Z" fill="black"/>
<path d="M5.1302 60.8471H3.2002V62.7771H5.1302V60.8471Z" fill="black"/>
<path d="M5.1302 62.7771H3.2002V64.7071H5.1302V62.7771Z" fill="black"/>
<path d="M5.1302 64.7171H3.2002V66.6471H5.1302V64.7171Z" fill="black"/>
<path d="M7.07014 64.7171H5.14014V66.6471H7.07014V64.7171Z" fill="black"/>
<path d="M9.00031 64.7171H7.07031V66.6471H9.00031V64.7171Z" fill="black"/>
<path d="M10.93 64.7171H9V66.6471H10.93V64.7171Z" fill="black"/>
<path d="M12.8602 64.7171H10.9302V66.6471H12.8602V64.7171Z" fill="black"/>
<path d="M14.8001 64.7171H12.8701V66.6471H14.8001V64.7171Z" fill="black"/>
<path d="M14.8001 62.7771H12.8701V64.7071H14.8001V62.7771Z" fill="black"/>
<path d="M14.8001 60.8471H12.8701V62.7771H14.8001V60.8471Z" fill="black"/>
<path d="M12.8602 55.0471H10.9302V56.9771H12.8602V55.0471Z" fill="#00887D"/>
<path d="M14.8001 55.0471H12.8701V56.9771H14.8001V55.0471Z" fill="#004C43"/>
<path d="M16.7303 55.0471H14.8003V56.9771H16.7303V55.0471Z" fill="black"/>
<path d="M18.66 55.0471H16.73V56.9771H18.66V55.0471Z" fill="black"/>
<path d="M26.4002 55.0471H24.4702V56.9771H26.4002V55.0471Z" fill="black"/>
<path d="M28.3304 55.0471H26.4004V56.9771H28.3304V55.0471Z" fill="black"/>
<path d="M30.2601 55.0471H28.3301V56.9771H30.2601V55.0471Z" fill="black"/>
<path d="M32.2 55.0471H30.27V56.9771H32.2V55.0471Z" fill="black"/>
<path d="M43.8001 55.0471H41.8701V56.9771H43.8001V55.0471Z" fill="black"/>
<path d="M45.7303 55.0471H43.8003V56.9771H45.7303V55.0471Z" fill="#113A77"/>
<path d="M47.66 55.0471H45.73V56.9771H47.66V55.0471Z" fill="#113A77"/>
<path d="M49.6004 55.0471H47.6704V56.9771H49.6004V55.0471Z" fill="black"/>
<path d="M57.3304 55.0471H55.4004V56.9771H57.3304V55.0471Z" fill="black"/>
<path d="M59.2601 55.0471H57.3301V56.9771H59.2601V55.0471Z" fill="#113A77"/>
<path d="M63.1302 55.0471H61.2002V56.9771H63.1302V55.0471Z" fill="#3863A0"/>
<path d="M65.0604 55.0471H63.1304V56.9771H65.0604V55.0471Z" fill="black"/>
<path d="M10.93 56.9771H9V58.9071H10.93V56.9771Z" fill="black"/>
<path d="M9.00031 56.9771H7.07031V58.9071H9.00031V56.9771Z" fill="#004C43"/>
<path d="M7.07014 56.9771H5.14014V58.9071H7.07014V56.9771Z" fill="#004C43"/>
<path d="M10.93 58.9171H9V60.8471H10.93V58.9171Z" fill="#00887D"/>
<path d="M9.00031 58.9171H7.07031V60.8471H9.00031V58.9171Z" fill="#00887D"/>
<path d="M7.07014 58.9171H5.14014V60.8471H7.07014V58.9171Z" fill="#004C43"/>
<path d="M10.93 60.8471H9V62.7771H10.93V60.8471Z" fill="#00887D"/>
<path d="M9.00031 60.8471H7.07031V62.7771H9.00031V60.8471Z" fill="#FCB040"/>
<path d="M7.07014 60.8471H5.14014V62.7771H7.07014V60.8471Z" fill="#004C43"/>
<path d="M10.93 62.7771H9V64.7071H10.93V62.7771Z" fill="#004C43"/>
<path d="M12.8602 60.8471H10.9302V62.7771H12.8602V60.8471Z" fill="#004C43"/>
<path d="M12.8602 62.7771H10.9302V64.7071H12.8602V62.7771Z" fill="#004C43"/>
<path d="M9.00031 62.7771H7.07031V64.7071H9.00031V62.7771Z" fill="#004C43"/>
<path d="M7.07014 62.7771H5.14014V64.7071H7.07014V62.7771Z" fill="#004C43"/>
<path d="M12.8602 56.9771H10.9302V58.9071H12.8602V56.9771Z" fill="#004C43"/>
<path d="M14.8001 56.9771H12.8701V58.9071H14.8001V56.9771Z" fill="black"/>
<path d="M16.7303 56.9771H14.8003V58.9071H16.7303V56.9771Z" fill="black"/>
<path d="M28.3304 56.9771H26.4004V58.9071H28.3304V56.9771Z" fill="black"/>
<path d="M30.2601 56.9771H28.3301V58.9071H30.2601V56.9771Z" fill="black"/>
<path d="M45.7303 56.9771H43.8003V58.9071H45.7303V56.9771Z" fill="black"/>
<path d="M47.66 56.9771H45.73V58.9071H47.66V56.9771Z" fill="black"/>
<path d="M59.2601 56.9771H57.3301V58.9071H59.2601V56.9771Z" fill="black"/>
<path d="M61.2 56.9771H59.27V58.9071H61.2V56.9771Z" fill="#3863A0"/>
<path d="M63.1302 56.9771H61.2002V58.9071H63.1302V56.9771Z" fill="#113A77"/>
<path d="M65.0604 56.9771H63.1304V58.9071H65.0604V56.9771Z" fill="black"/>
<path d="M12.8602 58.9171H10.9302V60.8471H12.8602V58.9171Z" fill="black"/>
<path d="M14.8001 58.9171H12.8701V60.8471H14.8001V58.9171Z" fill="black"/>
<path d="M65.0602 56.9871H66.9902V55.0571H65.0602V56.9871Z" fill="black"/>
<path d="M67.0002 56.9871H68.9302V55.0571H67.0002V56.9871Z" fill="black"/>
<path d="M68.9401 56.9871H70.8701V55.0571H68.9401V56.9871Z" fill="black"/>
<path d="M70.8703 56.9871H72.8003V55.0571H70.8703V56.9871Z" fill="black"/>
<path d="M70.8703 58.9171H72.8003V56.9871H70.8703V58.9171Z" fill="black"/>
<path d="M70.8703 60.8471H72.8003V58.9171H70.8703V60.8471Z" fill="black"/>
<path d="M70.8703 62.7871H72.8003V60.8571H70.8703V62.7871Z" fill="black"/>
<path d="M70.8703 64.7171H72.8003V62.7871H70.8703V64.7171Z" fill="black"/>
<path d="M70.8703 66.6471H72.8003V64.7171H70.8703V66.6471Z" fill="black"/>
<path d="M68.9401 66.6471H70.8701V64.7171H68.9401V66.6471Z" fill="black"/>
<path d="M67.0002 66.6471H68.9302V64.7171H67.0002V66.6471Z" fill="black"/>
<path d="M65.0602 66.6471H66.9902V64.7171H65.0602V66.6471Z" fill="black"/>
<path d="M63.1403 66.6471H65.0703V64.7171H63.1403V66.6471Z" fill="black"/>
<path d="M61.2004 66.6471H63.1304V64.7171H61.2004V66.6471Z" fill="black"/>
<path d="M61.2004 64.7171H63.1304V62.7871H61.2004V64.7171Z" fill="black"/>
<path d="M61.2004 62.7871H63.1304V60.8571H61.2004V62.7871Z" fill="black"/>
<path d="M63.1403 56.9871H65.0703V55.0571H63.1403V56.9871Z" fill="#3863A0"/>
<path d="M61.2004 56.9871H63.1304V55.0571H61.2004V56.9871Z" fill="#3863A0"/>
<path d="M59.2604 56.9871H61.1904V55.0571H59.2604V56.9871Z" fill="#3863A0"/>
<path d="M65.0602 58.9171H66.9902V56.9871H65.0602V58.9171Z" fill="black"/>
<path d="M67.0002 58.9171H68.9302V56.9871H67.0002V58.9171Z" fill="#113A77"/>
<path d="M68.9401 58.9171H70.8701V56.9871H68.9401V58.9171Z" fill="#113A77"/>
<path d="M65.0602 60.8471H66.9902V58.9171H65.0602V60.8471Z" fill="#3863A0"/>
<path d="M67.0002 60.8471H68.9302V58.9171H67.0002V60.8471Z" fill="#3863A0"/>
<path d="M68.9401 60.8471H70.8701V58.9171H68.9401V60.8471Z" fill="#113A77"/>
<path d="M65.0602 62.7871H66.9902V60.8571H65.0602V62.7871Z" fill="#3863A0"/>
<path d="M67.0002 62.7871H68.9302V60.8571H67.0002V62.7871Z" fill="#FCB040"/>
<path d="M68.9401 62.7871H70.8701V60.8571H68.9401V62.7871Z" fill="#113A77"/>
<path d="M65.0602 64.7171H66.9902V62.7871H65.0602V64.7171Z" fill="#113A77"/>
<path d="M63.1403 62.7871H65.0703V60.8571H63.1403V62.7871Z" fill="#113A77"/>
<path d="M63.1403 64.7171H65.0703V62.7871H63.1403V64.7171Z" fill="#113A77"/>
<path d="M67.0002 64.7171H68.9302V62.7871H67.0002V64.7171Z" fill="#113A77"/>
<path d="M68.9401 64.7171H70.8701V62.7871H68.9401V64.7171Z" fill="#113A77"/>
<path d="M63.1403 58.9171H65.0703V56.9871H63.1403V58.9171Z" fill="#113A77"/>
<path d="M59.2604 58.9171H61.1904V56.9871H59.2604V58.9171Z" fill="#113A77"/>
<path d="M63.1403 60.8471H65.0703V58.9171H63.1403V60.8471Z" fill="black"/>
<path d="M61.2004 60.8471H63.1304V58.9171H61.2004V60.8471Z" fill="black"/>
<path d="M61.2 58.9171H59.27V60.8471H61.2V58.9171Z" fill="black"/>
<path d="M63.1302 58.9171H61.2002V60.8471H63.1302V58.9171Z" fill="black"/>
</svg>

  );
};
