export const HomePetStats = ({ ...props }) => {
  return (
    <svg
      width="63"
      height="65"
      viewBox="0 0 63 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="icon">
        <path
          id="Vector"
          d="M17.125 25.3711H15.625V26.8711H17.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_2"
          d="M18.625 25.3711H17.125V26.8711H18.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_3"
          d="M20.125 25.3711H18.625V26.8711H20.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_4"
          d="M21.625 25.3711H20.125V26.8711H21.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_5"
          d="M23.125 25.3711H21.625V26.8711H23.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_6"
          d="M24.625 25.3711H23.125V26.8711H24.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_7"
          d="M26.125 25.3711H24.625V26.8711H26.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_8"
          d="M27.625 25.3711H26.125V26.8711H27.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_9"
          d="M29.125 25.3711H27.625V26.8711H29.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_10"
          d="M30.625 25.3711H29.125V26.8711H30.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_11"
          d="M32.125 25.3711H30.625V26.8711H32.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_12"
          d="M33.625 25.3711H32.125V26.8711H33.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_13"
          d="M35.125 25.3711H33.625V26.8711H35.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_14"
          d="M36.625 25.3711H35.125V26.8711H36.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_15"
          d="M38.125 25.3711H36.625V26.8711H38.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_16"
          d="M39.625 25.3711H38.125V26.8711H39.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_17"
          d="M41.125 25.3711H39.625V26.8711H41.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_18"
          d="M42.625 25.3711H41.125V26.8711H42.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_19"
          d="M44.125 25.3711H42.625V26.8711H44.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_20"
          d="M45.625 25.3711H44.125V26.8711H45.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_21"
          d="M47.125 25.3711H45.625V26.8711H47.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_22"
          d="M48.625 25.3711H47.125V26.8711H48.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_23"
          d="M50.125 25.3711H48.625V26.8711H50.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_24"
          d="M51.625 25.3711H50.125V26.8711H51.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_25"
          d="M53.125 25.3711H51.625V26.8711H53.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_26"
          d="M54.625 25.3711H53.125V26.8711H54.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_27"
          d="M56.125 25.3711H54.625V26.8711H56.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_28"
          d="M57.625 25.3711H56.125V26.8711H57.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_29"
          d="M59.125 25.3711H57.625V26.8711H59.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_30"
          d="M18.625 26.8711H17.125V28.3711H18.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_31"
          d="M20.125 26.8711H18.625V28.3711H20.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_32"
          d="M15.625 26.8711H14.125V28.3711H15.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_33"
          d="M17.125 26.8711H15.625V28.3711H17.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_34"
          d="M12.625 26.8711H11.125V28.3711H12.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_35"
          d="M11.125 26.8711H9.625V28.3711H11.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_36"
          d="M14.125 26.8711H12.625V28.3711H14.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_37"
          d="M21.625 26.8711H20.125V28.3711H21.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_38"
          d="M23.125 26.8711H21.625V28.3711H23.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_39"
          d="M24.625 26.8711H23.125V28.3711H24.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_40"
          d="M26.125 26.8711H24.625V28.3711H26.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_41"
          d="M27.625 26.8711H26.125V28.3711H27.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_42"
          d="M29.125 26.8711H27.625V28.3711H29.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_43"
          d="M30.625 26.8711H29.125V28.3711H30.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_44"
          d="M32.125 26.8711H30.625V28.3711H32.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_45"
          d="M33.625 26.8711H32.125V28.3711H33.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_46"
          d="M35.125 26.8711H33.625V28.3711H35.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_47"
          d="M36.625 26.8711H35.125V28.3711H36.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_48"
          d="M38.125 26.8711H36.625V28.3711H38.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_49"
          d="M39.625 26.8711H38.125V28.3711H39.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_50"
          d="M41.125 26.8711H39.625V28.3711H41.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_51"
          d="M42.625 26.8711H41.125V28.3711H42.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_52"
          d="M44.125 26.8711H42.625V28.3711H44.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_53"
          d="M45.625 26.8711H44.125V28.3711H45.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_54"
          d="M47.125 26.8711H45.625V28.3711H47.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_55"
          d="M48.625 26.8711H47.125V28.3711H48.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_56"
          d="M50.125 26.8711H48.625V28.3711H50.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_57"
          d="M51.625 26.8711H50.125V28.3711H51.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_58"
          d="M53.125 26.8711H51.625V28.3711H53.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_59"
          d="M54.625 26.8711H53.125V28.3711H54.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_60"
          d="M56.125 26.8711H54.625V28.3711H56.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_61"
          d="M57.625 26.8711H56.125V28.3711H57.625V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_62"
          d="M59.125 26.8711H57.625V28.3711H59.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_63"
          d="M60.625 26.8711H59.125V28.3711H60.625V26.8711Z"
          fill="black"
        />
        <path
          id="Vector_64"
          d="M18.625 28.3711H17.125V29.8711H18.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_65"
          d="M17.125 28.3711H15.625V29.8711H17.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_66"
          d="M15.625 28.3711H14.125V29.8711H15.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_67"
          d="M20.125 28.3711H18.625V29.8711H20.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_68"
          d="M21.625 28.3711H20.125V29.8711H21.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_69"
          d="M23.125 28.3711H21.625V29.8711H23.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_70"
          d="M24.625 28.3711H23.125V29.8711H24.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_71"
          d="M26.125 28.3711H24.625V29.8711H26.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_72"
          d="M27.625 28.3711H26.125V29.8711H27.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_73"
          d="M29.125 28.3711H27.625V29.8711H29.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_74"
          d="M30.625 28.3711H29.125V29.8711H30.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_75"
          d="M32.125 28.3711H30.625V29.8711H32.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_76"
          d="M33.625 28.3711H32.125V29.8711H33.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_77"
          d="M35.125 28.3711H33.625V29.8711H35.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_78"
          d="M36.625 28.3711H35.125V29.8711H36.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_79"
          d="M38.125 28.3711H36.625V29.8711H38.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_80"
          d="M39.625 28.3711H38.125V29.8711H39.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_81"
          d="M41.125 28.3711H39.625V29.8711H41.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_82"
          d="M42.625 28.3711H41.125V29.8711H42.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_83"
          d="M44.125 28.3711H42.625V29.8711H44.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_84"
          d="M45.625 28.3711H44.125V29.8711H45.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_85"
          d="M47.125 28.3711H45.625V29.8711H47.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_86"
          d="M48.625 28.3711H47.125V29.8711H48.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_87"
          d="M50.125 28.3711H48.625V29.8711H50.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_88"
          d="M51.625 28.3711H50.125V29.8711H51.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_89"
          d="M53.125 28.3711H51.625V29.8711H53.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_90"
          d="M54.625 28.3711H53.125V29.8711H54.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_91"
          d="M56.125 28.3711H54.625V29.8711H56.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_92"
          d="M57.625 28.3711H56.125V29.8711H57.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_93"
          d="M59.125 28.3711H57.625V29.8711H59.125V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_94"
          d="M60.625 28.3711H59.125V29.8711H60.625V28.3711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_95"
          d="M62.125 28.3711H60.625V29.8711H62.125V28.3711Z"
          fill="black"
        />
        <path
          id="Vector_96"
          d="M17.125 29.8711H15.625V31.3711H17.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_97"
          d="M18.625 29.8711H17.125V31.3711H18.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_98"
          d="M20.125 29.8711H18.625V31.3711H20.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_99"
          d="M21.625 29.8711H20.125V31.3711H21.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_100"
          d="M23.125 29.8711H21.625V31.3711H23.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_101"
          d="M24.625 29.8711H23.125V31.3711H24.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_102"
          d="M26.125 29.8711H24.625V31.3711H26.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_103"
          d="M27.625 29.8711H26.125V31.3711H27.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_104"
          d="M29.125 29.8711H27.625V31.3711H29.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_105"
          d="M30.625 29.8711H29.125V31.3711H30.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_106"
          d="M32.125 29.8711H30.625V31.3711H32.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_107"
          d="M33.625 29.8711H32.125V31.3711H33.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_108"
          d="M35.125 29.8711H33.625V31.3711H35.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_109"
          d="M36.625 29.8711H35.125V31.3711H36.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_110"
          d="M38.125 29.8711H36.625V31.3711H38.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_111"
          d="M39.625 29.8711H38.125V31.3711H39.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_112"
          d="M41.125 29.8711H39.625V31.3711H41.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_113"
          d="M42.625 29.8711H41.125V31.3711H42.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_114"
          d="M44.125 29.8711H42.625V31.3711H44.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_115"
          d="M45.625 29.8711H44.125V31.3711H45.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_116"
          d="M47.125 29.8711H45.625V31.3711H47.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_117"
          d="M48.625 29.8711H47.125V31.3711H48.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_118"
          d="M50.125 29.8711H48.625V31.3711H50.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_119"
          d="M51.625 29.8711H50.125V31.3711H51.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_120"
          d="M53.125 29.8711H51.625V31.3711H53.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_121"
          d="M54.625 29.8711H53.125V31.3711H54.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_122"
          d="M56.125 29.8711H54.625V31.3711H56.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_123"
          d="M57.625 29.8711H56.125V31.3711H57.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_124"
          d="M59.125 29.8711H57.625V31.3711H59.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_125"
          d="M60.625 29.8711H59.125V31.3711H60.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_126"
          d="M62.125 29.8711H60.625V31.3711H62.125V29.8711Z"
          fill="black"
        />
        <path
          id="Vector_127"
          d="M15.625 31.3711H14.125V32.8711H15.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_128"
          d="M17.125 31.3711H15.625V32.8711H17.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_129"
          d="M18.625 31.3711H17.125V32.8711H18.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_130"
          d="M20.125 31.3711H18.625V32.8711H20.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_131"
          d="M21.625 31.3711H20.125V32.8711H21.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_132"
          d="M23.125 31.3711H21.625V32.8711H23.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_133"
          d="M24.625 31.3711H23.125V32.8711H24.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_134"
          d="M26.125 31.3711H24.625V32.8711H26.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_135"
          d="M27.625 31.3711H26.125V32.8711H27.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_136"
          d="M29.125 31.3711H27.625V32.8711H29.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_137"
          d="M30.625 31.3711H29.125V32.8711H30.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_138"
          d="M32.125 31.3711H30.625V32.8711H32.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_139"
          d="M33.625 31.3711H32.125V32.8711H33.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_140"
          d="M35.125 31.3711H33.625V32.8711H35.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_141"
          d="M36.625 31.3711H35.125V32.8711H36.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_142"
          d="M38.125 31.3711H36.625V32.8711H38.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_143"
          d="M39.625 31.3711H38.125V32.8711H39.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_144"
          d="M41.125 31.3711H39.625V32.8711H41.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_145"
          d="M42.625 31.3711H41.125V32.8711H42.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_146"
          d="M44.125 31.3711H42.625V32.8711H44.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_147"
          d="M45.625 31.3711H44.125V32.8711H45.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_148"
          d="M47.125 31.3711H45.625V32.8711H47.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_149"
          d="M48.625 31.3711H47.125V32.8711H48.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_150"
          d="M50.125 31.3711H48.625V32.8711H50.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_151"
          d="M51.625 31.3711H50.125V32.8711H51.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_152"
          d="M53.125 31.3711H51.625V32.8711H53.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_153"
          d="M54.625 31.3711H53.125V32.8711H54.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_154"
          d="M56.125 31.3711H54.625V32.8711H56.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_155"
          d="M57.625 31.3711H56.125V32.8711H57.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_156"
          d="M59.125 31.3711H57.625V32.8711H59.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_157"
          d="M60.625 31.3711H59.125V32.8711H60.625V31.3711Z"
          fill="black"
        />
        <path
          id="Vector_158"
          d="M14.125 32.8711H12.625V34.3711H14.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_159"
          d="M12.625 32.8711H11.125V34.3711H12.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_160"
          d="M15.625 32.8711H14.125V34.3711H15.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_161"
          d="M17.125 32.8711H15.625V34.3711H17.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_162"
          d="M18.625 32.8711H17.125V34.3711H18.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_163"
          d="M20.125 32.8711H18.625V34.3711H20.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_164"
          d="M21.625 32.8711H20.125V34.3711H21.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_165"
          d="M23.125 32.8711H21.625V34.3711H23.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_166"
          d="M24.625 32.8711H23.125V34.3711H24.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_167"
          d="M26.125 32.8711H24.625V34.3711H26.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_168"
          d="M27.625 32.8711H26.125V34.3711H27.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_169"
          d="M29.125 32.8711H27.625V34.3711H29.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_170"
          d="M30.625 32.8711H29.125V34.3711H30.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_171"
          d="M32.125 32.8711H30.625V34.3711H32.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_172"
          d="M33.625 32.8711H32.125V34.3711H33.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_173"
          d="M35.125 32.8711H33.625V34.3711H35.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_174"
          d="M36.625 32.8711H35.125V34.3711H36.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_175"
          d="M38.125 32.8711H36.625V34.3711H38.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_176"
          d="M39.625 32.8711H38.125V34.3711H39.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_177"
          d="M41.125 32.8711H39.625V34.3711H41.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_178"
          d="M42.625 32.8711H41.125V34.3711H42.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_179"
          d="M44.125 32.8711H42.625V34.3711H44.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_180"
          d="M45.625 32.8711H44.125V34.3711H45.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_181"
          d="M47.125 32.8711H45.625V34.3711H47.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_182"
          d="M48.625 32.8711H47.125V34.3711H48.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_183"
          d="M50.125 32.8711H48.625V34.3711H50.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_184"
          d="M51.625 32.8711H50.125V34.3711H51.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_185"
          d="M53.125 32.8711H51.625V34.3711H53.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_186"
          d="M54.625 32.8711H53.125V34.3711H54.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_187"
          d="M56.125 32.8711H54.625V34.3711H56.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_188"
          d="M57.625 32.8711H56.125V34.3711H57.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_189"
          d="M59.125 32.8711H57.625V34.3711H59.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_190"
          d="M6.625 20.8711H5.125V22.3711H6.625V20.8711Z"
          fill="black"
        />
        <path
          id="Vector_191"
          d="M8.125 20.8711H6.625V22.3711H8.125V20.8711Z"
          fill="black"
        />
        <path
          id="Vector_192"
          d="M9.625 20.8711H8.125V22.3711H9.625V20.8711Z"
          fill="black"
        />
        <path
          id="Vector_193"
          d="M11.125 20.8711H9.625V22.3711H11.125V20.8711Z"
          fill="black"
        />
        <path
          id="Vector_194"
          d="M12.625 20.8711H11.125V22.3711H12.625V20.8711Z"
          fill="black"
        />
        <path
          id="Vector_195"
          d="M14.125 20.8711H12.625V22.3711H14.125V20.8711Z"
          fill="black"
        />
        <path
          id="Vector_196"
          d="M15.625 20.8711H14.125V22.3711H15.625V20.8711Z"
          fill="black"
        />
        <path
          id="Vector_197"
          d="M17.125 20.8711H15.625V22.3711H17.125V20.8711Z"
          fill="black"
        />
        <path
          id="Vector_198"
          d="M5.125 22.3711H3.625V23.8711H5.125V22.3711Z"
          fill="black"
        />
        <path
          id="Vector_199"
          d="M6.625 22.3711H5.125V23.8711H6.625V22.3711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_200"
          d="M8.125 22.3711H6.625V23.8711H8.125V22.3711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_201"
          d="M9.625 22.3711H8.125V23.8711H9.625V22.3711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_202"
          d="M11.125 22.3711H9.625V23.8711H11.125V22.3711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_203"
          d="M12.625 22.3711H11.125V23.8711H12.625V22.3711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_204"
          d="M14.125 22.3711H12.625V23.8711H14.125V22.3711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_205"
          d="M5.125 23.8711H3.625V25.3711H5.125V23.8711Z"
          fill="black"
        />
        <path
          id="Vector_206"
          d="M6.625 23.8711H5.125V25.3711H6.625V23.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_207"
          d="M8.125 23.8711H6.625V25.3711H8.125V23.8711Z"
          fill="white"
        />
        <path
          id="Vector_208"
          d="M9.625 23.8711H8.125V25.3711H9.625V23.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_209"
          d="M11.125 23.8711H9.625V25.3711H11.125V23.8711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_210"
          d="M12.625 23.8711H11.125V25.3711H12.625V23.8711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_211"
          d="M3.625 25.3711H2.125V26.8711H3.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_212"
          d="M5.125 25.3711H3.625V26.8711H5.125V25.3711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_213"
          d="M6.625 25.3711H5.125V26.8711H6.625V25.3711Z"
          fill="white"
        />
        <path
          id="Vector_214"
          d="M8.125 25.3711H6.625V26.8711H8.125V25.3711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_215"
          d="M9.625 25.3711H8.125V26.8711H9.625V25.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_216"
          d="M11.125 25.3711H9.625V26.8711H11.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_217"
          d="M3.625 26.8711H2.125V28.3711H3.625V26.8711Z"
          fill="black"
        />
        <path
          id="Vector_218"
          d="M5.125 26.8711H3.625V28.3711H5.125V26.8711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_219"
          d="M6.625 26.8711H5.125V28.3711H6.625V26.8711Z"
          fill="white"
        />
        <path
          id="Vector_220"
          d="M8.125 26.8711H6.625V28.3711H8.125V26.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_221"
          d="M9.625 26.8711H8.125V28.3711H9.625V26.8711Z"
          fill="black"
        />
        <path
          id="Vector_222"
          d="M15.625 22.3711H14.125V23.8711H15.625V22.3711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_223"
          d="M14.125 23.8711H12.625V25.3711H14.125V23.8711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_224"
          d="M15.625 23.8711H14.125V25.3711H15.625V23.8711Z"
          fill="black"
        />
        <path
          id="Vector_225"
          d="M12.625 25.3711H11.125V26.8711H12.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_226"
          d="M14.125 25.3711H12.625V26.8711H14.125V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_227"
          d="M15.625 25.3711H14.125V26.8711H15.625V25.3711Z"
          fill="black"
        />
        <path
          id="Vector_228"
          d="M17.125 22.3711H15.625V23.8711H17.125V22.3711Z"
          fill="black"
        />
        <path
          id="Vector_229"
          d="M5.125 28.3711H3.625V29.8711H5.125V28.3711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_230"
          d="M3.625 28.3711H2.125V29.8711H3.625V28.3711Z"
          fill="#FFD169"
        />
        <path
          id="Vector_231"
          d="M2.125 28.3711H0.625V29.8711H2.125V28.3711Z"
          fill="black"
        />
        <path
          id="Vector_232"
          d="M6.625 28.3711H5.125V29.8711H6.625V28.3711Z"
          fill="white"
        />
        <path
          id="Vector_233"
          d="M8.125 28.3711H6.625V29.8711H8.125V28.3711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_234"
          d="M9.625 28.3711H8.125V29.8711H9.625V28.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_235"
          d="M11.125 28.3711H9.625V29.8711H11.125V28.3711Z"
          fill="black"
        />
        <path
          id="Vector_236"
          d="M12.625 28.3711H11.125V29.8711H12.625V28.3711Z"
          fill="black"
        />
        <path
          id="Vector_237"
          d="M14.125 28.3711H12.625V29.8711H14.125V28.3711Z"
          fill="black"
        />
        <path
          id="Vector_238"
          d="M5.125 29.8711H3.625V31.3711H5.125V29.8711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_239"
          d="M3.625 29.8711H2.125V31.3711H3.625V29.8711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_240"
          d="M2.125 29.8711H0.625V31.3711H2.125V29.8711Z"
          fill="black"
        />
        <path
          id="Vector_241"
          d="M6.625 29.8711H5.125V31.3711H6.625V29.8711Z"
          fill="#FBC94C"
        />
        <path
          id="Vector_242"
          d="M8.125 29.8711H6.625V31.3711H8.125V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_243"
          d="M9.625 29.8711H8.125V31.3711H9.625V29.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_244"
          d="M11.125 29.8711H9.625V31.3711H11.125V29.8711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_245"
          d="M12.625 29.8711H11.125V31.3711H12.625V29.8711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_246"
          d="M14.125 29.8711H12.625V31.3711H14.125V29.8711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_247"
          d="M15.625 29.8711H14.125V31.3711H15.625V29.8711Z"
          fill="black"
        />
        <path
          id="Vector_248"
          d="M5.125 31.3711H3.625V32.8711H5.125V31.3711Z"
          fill="black"
        />
        <path
          id="Vector_249"
          d="M3.625 31.3711H2.125V32.8711H3.625V31.3711Z"
          fill="black"
        />
        <path
          id="Vector_250"
          d="M2.125 31.3711H0.625V32.8711H2.125V31.3711Z"
          fill="black"
        />
        <path
          id="Vector_251"
          d="M6.625 31.3711H5.125V32.8711H6.625V31.3711Z"
          fill="black"
        />
        <path
          id="Vector_252"
          d="M8.125 31.3711H6.625V32.8711H8.125V31.3711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_253"
          d="M9.625 31.3711H8.125V32.8711H9.625V31.3711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_254"
          d="M11.125 31.3711H9.625V32.8711H11.125V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_255"
          d="M12.625 31.3711H11.125V32.8711H12.625V31.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_256"
          d="M8.125 32.8711H6.625V34.3711H8.125V32.8711Z"
          fill="#FFA935"
        />
        <path
          id="Vector_257"
          d="M9.625 32.8711H8.125V34.3711H9.625V32.8711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_258"
          d="M8.125 34.3711H6.625V35.8711H8.125V34.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_259"
          d="M6.625 34.3711H5.125V35.8711H6.625V34.3711Z"
          fill="black"
        />
        <path
          id="Vector_260"
          d="M6.625 32.8711H5.125V34.3711H6.625V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_261"
          d="M9.625 34.3711H8.125V35.8711H9.625V34.3711Z"
          fill="black"
        />
        <path
          id="Vector_262"
          d="M6.625 35.8711H5.125V37.3711H6.625V35.8711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_263"
          d="M5.125 35.8711H3.625V37.3711H5.125V35.8711Z"
          fill="black"
        />
        <path
          id="Vector_264"
          d="M6.625 37.3711H5.125V38.8711H6.625V37.3711Z"
          fill="black"
        />
        <path
          id="Vector_265"
          d="M3.625 37.3711H2.125V38.8711H3.625V37.3711Z"
          fill="black"
        />
        <path
          id="Vector_266"
          d="M5.125 37.3711H3.625V38.8711H5.125V37.3711Z"
          fill="#EF8845"
        />
        <path
          id="Vector_267"
          d="M3.625 38.8711H2.125V40.3711H3.625V38.8711Z"
          fill="black"
        />
        <path
          id="Vector_268"
          d="M5.125 38.8711H3.625V40.3711H5.125V38.8711Z"
          fill="black"
        />
        <path
          id="Vector_269"
          d="M8.125 35.8711H6.625V37.3711H8.125V35.8711Z"
          fill="black"
        />
        <path
          id="Vector_270"
          d="M11.125 32.8711H9.625V34.3711H11.125V32.8711Z"
          fill="black"
        />
        <path
          id="Vector_271"
          d="M14.125 31.3711H12.625V32.8711H14.125V31.3711Z"
          fill="black"
        />
        <path
          id="Vector_272"
          d="M44.125 28.3711H42.625V29.8711H44.125V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_273"
          d="M45.625 28.3711H44.125V29.8711H45.625V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_274"
          d="M47.125 28.3711H45.625V29.8711H47.125V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_275"
          d="M48.625 28.3711H47.125V29.8711H48.625V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_276"
          d="M50.125 28.3711H48.625V29.8711H50.125V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_277"
          d="M51.625 28.3711H50.125V29.8711H51.625V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_278"
          d="M53.125 28.3711H51.625V29.8711H53.125V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_279"
          d="M54.625 28.3711H53.125V29.8711H54.625V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_280"
          d="M56.125 28.3711H54.625V29.8711H56.125V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_281"
          d="M57.625 28.3711H56.125V29.8711H57.625V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_282"
          d="M59.125 28.3711H57.625V29.8711H59.125V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_283"
          d="M60.625 28.3711H59.125V29.8711H60.625V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_284"
          d="M44.125 29.8711H42.625V31.3711H44.125V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_285"
          d="M45.625 29.8711H44.125V31.3711H45.625V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_286"
          d="M47.125 29.8711H45.625V31.3711H47.125V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_287"
          d="M48.625 29.8711H47.125V31.3711H48.625V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_288"
          d="M50.125 29.8711H48.625V31.3711H50.125V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_289"
          d="M51.625 29.8711H50.125V31.3711H51.625V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_290"
          d="M53.125 29.8711H51.625V31.3711H53.125V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_291"
          d="M54.625 29.8711H53.125V31.3711H54.625V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_292"
          d="M56.125 29.8711H54.625V31.3711H56.125V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_293"
          d="M57.625 29.8711H56.125V31.3711H57.625V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_294"
          d="M59.125 29.8711H57.625V31.3711H59.125V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_295"
          d="M60.625 29.8711H59.125V31.3711H60.625V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_296"
          d="M44.125 31.3711H42.625V32.8711H44.125V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_297"
          d="M45.625 31.3711H44.125V32.8711H45.625V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_298"
          d="M47.125 31.3711H45.625V32.8711H47.125V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_299"
          d="M48.625 31.3711H47.125V32.8711H48.625V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_300"
          d="M50.125 31.3711H48.625V32.8711H50.125V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_301"
          d="M51.625 31.3711H50.125V32.8711H51.625V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_302"
          d="M53.125 31.3711H51.625V32.8711H53.125V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_303"
          d="M54.625 31.3711H53.125V32.8711H54.625V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_304"
          d="M56.125 31.3711H54.625V32.8711H56.125V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_305"
          d="M57.625 31.3711H56.125V32.8711H57.625V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_306"
          d="M59.125 31.3711H57.625V32.8711H59.125V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_307"
          d="M44.125 26.8711H42.625V28.3711H44.125V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_308"
          d="M42.625 28.3711H41.125V29.8711H42.625V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_309"
          d="M42.625 29.8711H41.125V31.3711H42.625V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_310"
          d="M42.625 31.3711H41.125V32.8711H42.625V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_311"
          d="M42.625 26.8711H41.125V28.3711H42.625V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_312"
          d="M41.125 28.3711H39.625V29.8711H41.125V28.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_313"
          d="M41.125 29.8711H39.625V31.3711H41.125V29.8711Z"
          fill="#445569"
        />
        <path
          id="Vector_314"
          d="M41.125 31.3711H39.625V32.8711H41.125V31.3711Z"
          fill="#445569"
        />
        <path
          id="Vector_315"
          d="M41.125 26.8711H39.625V28.3711H41.125V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_316"
          d="M45.625 26.8711H44.125V28.3711H45.625V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_317"
          d="M47.125 26.8711H45.625V28.3711H47.125V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_318"
          d="M48.625 26.8711H47.125V28.3711H48.625V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_319"
          d="M50.125 26.8711H48.625V28.3711H50.125V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_320"
          d="M51.625 26.8711H50.125V28.3711H51.625V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_321"
          d="M53.125 26.8711H51.625V28.3711H53.125V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_322"
          d="M54.625 26.8711H53.125V28.3711H54.625V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_323"
          d="M56.125 26.8711H54.625V28.3711H56.125V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_324"
          d="M57.625 26.8711H56.125V28.3711H57.625V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_325"
          d="M59.125 26.8711H57.625V28.3711H59.125V26.8711Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_326"
          d="M18.625 50.7598H17.125V52.2598H18.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_327"
          d="M20.125 50.7598H18.625V52.2598H20.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_328"
          d="M21.625 50.7598H20.125V52.2598H21.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_329"
          d="M23.125 50.7598H21.625V52.2598H23.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_330"
          d="M24.625 50.7598H23.125V52.2598H24.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_331"
          d="M26.125 50.7598H24.625V52.2598H26.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_332"
          d="M27.625 50.7598H26.125V52.2598H27.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_333"
          d="M29.125 50.7598H27.625V52.2598H29.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_334"
          d="M30.625 50.7598H29.125V52.2598H30.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_335"
          d="M32.125 50.7598H30.625V52.2598H32.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_336"
          d="M33.625 50.7598H32.125V52.2598H33.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_337"
          d="M35.125 50.7598H33.625V52.2598H35.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_338"
          d="M36.625 50.7598H35.125V52.2598H36.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_339"
          d="M38.125 50.7598H36.625V52.2598H38.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_340"
          d="M39.625 50.7598H38.125V52.2598H39.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_341"
          d="M41.125 50.7598H39.625V52.2598H41.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_342"
          d="M42.625 50.7598H41.125V52.2598H42.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_343"
          d="M44.125 50.7598H42.625V52.2598H44.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_344"
          d="M45.625 50.7598H44.125V52.2598H45.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_345"
          d="M47.125 50.7598H45.625V52.2598H47.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_346"
          d="M48.625 50.7598H47.125V52.2598H48.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_347"
          d="M50.125 50.7598H48.625V52.2598H50.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_348"
          d="M51.625 50.7598H50.125V52.2598H51.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_349"
          d="M53.125 50.7598H51.625V52.2598H53.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_350"
          d="M54.625 50.7598H53.125V52.2598H54.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_351"
          d="M56.125 50.7598H54.625V52.2598H56.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_352"
          d="M57.625 50.7598H56.125V52.2598H57.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_353"
          d="M59.125 50.7598H57.625V52.2598H59.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_354"
          d="M18.625 52.2598H17.125V53.7598H18.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_355"
          d="M20.125 52.2598H18.625V53.7598H20.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_356"
          d="M21.625 52.2598H20.125V53.7598H21.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_357"
          d="M23.125 52.2598H21.625V53.7598H23.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_358"
          d="M24.625 52.2598H23.125V53.7598H24.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_359"
          d="M26.125 52.2598H24.625V53.7598H26.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_360"
          d="M27.625 52.2598H26.125V53.7598H27.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_361"
          d="M29.125 52.2598H27.625V53.7598H29.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_362"
          d="M30.625 52.2598H29.125V53.7598H30.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_363"
          d="M32.125 52.2598H30.625V53.7598H32.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_364"
          d="M33.625 52.2598H32.125V53.7598H33.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_365"
          d="M35.125 52.2598H33.625V53.7598H35.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_366"
          d="M36.625 52.2598H35.125V53.7598H36.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_367"
          d="M38.125 52.2598H36.625V53.7598H38.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_368"
          d="M39.625 52.2598H38.125V53.7598H39.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_369"
          d="M41.125 52.2598H39.625V53.7598H41.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_370"
          d="M42.625 52.2598H41.125V53.7598H42.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_371"
          d="M44.125 52.2598H42.625V53.7598H44.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_372"
          d="M45.625 52.2598H44.125V53.7598H45.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_373"
          d="M47.125 52.2598H45.625V53.7598H47.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_374"
          d="M48.625 52.2598H47.125V53.7598H48.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_375"
          d="M50.125 52.2598H48.625V53.7598H50.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_376"
          d="M51.625 52.2598H50.125V53.7598H51.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_377"
          d="M53.125 52.2598H51.625V53.7598H53.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_378"
          d="M54.625 52.2598H53.125V53.7598H54.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_379"
          d="M56.125 52.2598H54.625V53.7598H56.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_380"
          d="M57.625 52.2598H56.125V53.7598H57.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_381"
          d="M59.125 52.2598H57.625V53.7598H59.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_382"
          d="M60.625 52.2598H59.125V53.7598H60.625V52.2598Z"
          fill="black"
        />
        <path
          id="Vector_383"
          d="M18.625 53.7598H17.125V55.2598H18.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_384"
          d="M20.125 53.7598H18.625V55.2598H20.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_385"
          d="M21.625 53.7598H20.125V55.2598H21.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_386"
          d="M23.125 53.7598H21.625V55.2598H23.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_387"
          d="M24.625 53.7598H23.125V55.2598H24.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_388"
          d="M26.125 53.7598H24.625V55.2598H26.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_389"
          d="M27.625 53.7598H26.125V55.2598H27.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_390"
          d="M29.125 53.7598H27.625V55.2598H29.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_391"
          d="M30.625 53.7598H29.125V55.2598H30.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_392"
          d="M32.125 53.7598H30.625V55.2598H32.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_393"
          d="M33.625 53.7598H32.125V55.2598H33.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_394"
          d="M35.125 53.7598H33.625V55.2598H35.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_395"
          d="M36.625 53.7598H35.125V55.2598H36.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_396"
          d="M38.125 53.7598H36.625V55.2598H38.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_397"
          d="M39.625 53.7598H38.125V55.2598H39.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_398"
          d="M41.125 53.7598H39.625V55.2598H41.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_399"
          d="M42.625 53.7598H41.125V55.2598H42.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_400"
          d="M44.125 53.7598H42.625V55.2598H44.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_401"
          d="M45.625 53.7598H44.125V55.2598H45.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_402"
          d="M47.125 53.7598H45.625V55.2598H47.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_403"
          d="M48.625 53.7598H47.125V55.2598H48.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_404"
          d="M50.125 53.7598H48.625V55.2598H50.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_405"
          d="M51.625 53.7598H50.125V55.2598H51.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_406"
          d="M53.125 53.7598H51.625V55.2598H53.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_407"
          d="M54.625 53.7598H53.125V55.2598H54.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_408"
          d="M56.125 53.7598H54.625V55.2598H56.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_409"
          d="M57.625 53.7598H56.125V55.2598H57.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_410"
          d="M59.125 53.7598H57.625V55.2598H59.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_411"
          d="M60.625 53.7598H59.125V55.2598H60.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_412"
          d="M62.125 53.7598H60.625V55.2598H62.125V53.7598Z"
          fill="black"
        />
        <path
          id="Vector_413"
          d="M18.625 55.2598H17.125V56.7598H18.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_414"
          d="M20.125 55.2598H18.625V56.7598H20.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_415"
          d="M21.625 55.2598H20.125V56.7598H21.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_416"
          d="M23.125 55.2598H21.625V56.7598H23.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_417"
          d="M24.625 55.2598H23.125V56.7598H24.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_418"
          d="M26.125 55.2598H24.625V56.7598H26.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_419"
          d="M27.625 55.2598H26.125V56.7598H27.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_420"
          d="M29.125 55.2598H27.625V56.7598H29.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_421"
          d="M30.625 55.2598H29.125V56.7598H30.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_422"
          d="M32.125 55.2598H30.625V56.7598H32.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_423"
          d="M33.625 55.2598H32.125V56.7598H33.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_424"
          d="M35.125 55.2598H33.625V56.7598H35.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_425"
          d="M36.625 55.2598H35.125V56.7598H36.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_426"
          d="M38.125 55.2598H36.625V56.7598H38.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_427"
          d="M39.625 55.2598H38.125V56.7598H39.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_428"
          d="M41.125 55.2598H39.625V56.7598H41.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_429"
          d="M42.625 55.2598H41.125V56.7598H42.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_430"
          d="M44.125 55.2598H42.625V56.7598H44.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_431"
          d="M45.625 55.2598H44.125V56.7598H45.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_432"
          d="M47.125 55.2598H45.625V56.7598H47.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_433"
          d="M48.625 55.2598H47.125V56.7598H48.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_434"
          d="M50.125 55.2598H48.625V56.7598H50.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_435"
          d="M51.625 55.2598H50.125V56.7598H51.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_436"
          d="M53.125 55.2598H51.625V56.7598H53.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_437"
          d="M54.625 55.2598H53.125V56.7598H54.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_438"
          d="M56.125 55.2598H54.625V56.7598H56.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_439"
          d="M57.625 55.2598H56.125V56.7598H57.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_440"
          d="M59.125 55.2598H57.625V56.7598H59.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_441"
          d="M60.625 55.2598H59.125V56.7598H60.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_442"
          d="M62.125 55.2598H60.625V56.7598H62.125V55.2598Z"
          fill="black"
        />
        <path
          id="Vector_443"
          d="M18.625 56.7598H17.125V58.2598H18.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_444"
          d="M17.125 50.7598H15.625V52.2598H17.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_445"
          d="M17.125 52.2598H15.625V53.7598H17.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_446"
          d="M17.125 53.7598H15.625V55.2598H17.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_447"
          d="M17.125 55.2598H15.625V56.7598H17.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_448"
          d="M17.125 56.7598H15.625V58.2598H17.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_449"
          d="M15.625 50.7598H14.125V52.2598H15.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_450"
          d="M15.625 52.2598H14.125V53.7598H15.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_451"
          d="M15.625 53.7598H14.125V55.2598H15.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_452"
          d="M15.625 55.2598H14.125V56.7598H15.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_453"
          d="M15.625 56.7598H14.125V58.2598H15.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_454"
          d="M14.125 50.7598H12.625V52.2598H14.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_455"
          d="M14.125 52.2598H12.625V53.7598H14.125V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_456"
          d="M14.125 53.7598H12.625V55.2598H14.125V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_457"
          d="M14.125 55.2598H12.625V56.7598H14.125V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_458"
          d="M14.125 56.7598H12.625V58.2598H14.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_459"
          d="M12.625 50.7598H11.125V52.2598H12.625V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_460"
          d="M12.625 52.2598H11.125V53.7598H12.625V52.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_461"
          d="M12.625 53.7598H11.125V55.2598H12.625V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_462"
          d="M12.625 55.2598H11.125V56.7598H12.625V55.2598Z"
          fill="#28B0AE"
        />
        <path
          id="Vector_463"
          d="M12.625 56.7598H11.125V58.2598H12.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_464"
          d="M20.125 56.7598H18.625V58.2598H20.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_465"
          d="M21.625 56.7598H20.125V58.2598H21.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_466"
          d="M23.125 56.7598H21.625V58.2598H23.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_467"
          d="M24.625 56.7598H23.125V58.2598H24.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_468"
          d="M26.125 56.7598H24.625V58.2598H26.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_469"
          d="M27.625 56.7598H26.125V58.2598H27.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_470"
          d="M29.125 56.7598H27.625V58.2598H29.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_471"
          d="M30.625 56.7598H29.125V58.2598H30.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_472"
          d="M32.125 56.7598H30.625V58.2598H32.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_473"
          d="M33.625 56.7598H32.125V58.2598H33.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_474"
          d="M35.125 56.7598H33.625V58.2598H35.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_475"
          d="M36.625 56.7598H35.125V58.2598H36.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_476"
          d="M38.125 56.7598H36.625V58.2598H38.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_477"
          d="M39.625 56.7598H38.125V58.2598H39.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_478"
          d="M41.125 56.7598H39.625V58.2598H41.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_479"
          d="M42.625 56.7598H41.125V58.2598H42.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_480"
          d="M44.125 56.7598H42.625V58.2598H44.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_481"
          d="M45.625 56.7598H44.125V58.2598H45.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_482"
          d="M47.125 56.7598H45.625V58.2598H47.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_483"
          d="M48.625 56.7598H47.125V58.2598H48.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_484"
          d="M50.125 56.7598H48.625V58.2598H50.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_485"
          d="M51.625 56.7598H50.125V58.2598H51.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_486"
          d="M53.125 56.7598H51.625V58.2598H53.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_487"
          d="M54.625 56.7598H53.125V58.2598H54.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_488"
          d="M56.125 56.7598H54.625V58.2598H56.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_489"
          d="M57.625 56.7598H56.125V58.2598H57.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_490"
          d="M59.125 56.7598H57.625V58.2598H59.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_491"
          d="M60.625 56.7598H59.125V58.2598H60.625V56.7598Z"
          fill="black"
        />
        <path
          id="Vector_492"
          d="M12.625 58.2598H11.125V59.7598H12.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_493"
          d="M14.125 58.2598H12.625V59.7598H14.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_494"
          d="M15.625 58.2598H14.125V59.7598H15.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_495"
          d="M17.125 58.2598H15.625V59.7598H17.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_496"
          d="M18.625 58.2598H17.125V59.7598H18.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_497"
          d="M20.125 58.2598H18.625V59.7598H20.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_498"
          d="M21.625 58.2598H20.125V59.7598H21.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_499"
          d="M23.125 58.2598H21.625V59.7598H23.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_500"
          d="M24.625 58.2598H23.125V59.7598H24.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_501"
          d="M26.125 58.2598H24.625V59.7598H26.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_502"
          d="M27.625 58.2598H26.125V59.7598H27.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_503"
          d="M29.125 58.2598H27.625V59.7598H29.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_504"
          d="M30.625 58.2598H29.125V59.7598H30.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_505"
          d="M32.125 58.2598H30.625V59.7598H32.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_506"
          d="M33.625 58.2598H32.125V59.7598H33.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_507"
          d="M35.125 58.2598H33.625V59.7598H35.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_508"
          d="M36.625 58.2598H35.125V59.7598H36.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_509"
          d="M38.125 58.2598H36.625V59.7598H38.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_510"
          d="M39.625 58.2598H38.125V59.7598H39.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_511"
          d="M41.125 58.2598H39.625V59.7598H41.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_512"
          d="M42.625 58.2598H41.125V59.7598H42.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_513"
          d="M44.125 58.2598H42.625V59.7598H44.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_514"
          d="M45.625 58.2598H44.125V59.7598H45.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_515"
          d="M47.125 58.2598H45.625V59.7598H47.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_516"
          d="M48.625 58.2598H47.125V59.7598H48.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_517"
          d="M50.125 58.2598H48.625V59.7598H50.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_518"
          d="M51.625 58.2598H50.125V59.7598H51.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_519"
          d="M53.125 58.2598H51.625V59.7598H53.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_520"
          d="M54.625 58.2598H53.125V59.7598H54.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_521"
          d="M56.125 58.2598H54.625V59.7598H56.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_522"
          d="M57.625 58.2598H56.125V59.7598H57.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_523"
          d="M59.125 58.2598H57.625V59.7598H59.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_524"
          d="M12.635 41.7598H11.135V43.2598H12.635V41.7598Z"
          fill="black"
        />
        <path
          id="Vector_525"
          d="M14.115 41.7598H12.615V43.2598H14.115V41.7598Z"
          fill="black"
        />
        <path
          id="Vector_526"
          d="M12.635 43.2598H11.135V44.7598H12.635V43.2598Z"
          fill="black"
        />
        <path
          id="Vector_527"
          d="M14.115 43.2598H12.615V44.7598H14.115V43.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_528"
          d="M12.635 44.7598H11.135V46.2598H12.635V44.7598Z"
          fill="black"
        />
        <path
          id="Vector_529"
          d="M14.115 44.7598H12.615V46.2598H14.115V44.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_530"
          d="M15.615 43.2598H14.115V44.7598H15.615V43.2598Z"
          fill="black"
        />
        <path
          id="Vector_531"
          d="M15.615 44.7598H14.115V46.2598H15.615V44.7598Z"
          fill="black"
        />
        <path
          id="Vector_532"
          d="M12.635 46.2598H11.135V47.7598H12.635V46.2598Z"
          fill="black"
        />
        <path
          id="Vector_533"
          d="M14.115 46.2598H12.615V47.7598H14.115V46.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_534"
          d="M11.135 46.2598H9.63499V47.7598H11.135V46.2598Z"
          fill="black"
        />
        <path
          id="Vector_535"
          d="M12.635 47.7598H11.135V49.2598H12.635V47.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_536"
          d="M14.115 47.7598H12.615V49.2598H14.115V47.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_537"
          d="M11.135 47.7598H9.63499V49.2598H11.135V47.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_538"
          d="M12.635 49.2598H11.135V50.7598H12.635V49.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_539"
          d="M14.115 49.2598H12.615V50.7598H14.115V49.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_540"
          d="M11.135 49.2598H9.63499V50.7598H11.135V49.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_541"
          d="M12.635 50.7598H11.135V52.2598H12.635V50.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_542"
          d="M14.115 50.7598H12.615V52.2598H14.115V50.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_543"
          d="M11.135 50.7598H9.63499V52.2598H11.135V50.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_544"
          d="M12.635 52.2598H11.135V53.7598H12.635V52.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_545"
          d="M14.115 52.2598H12.615V53.7598H14.115V52.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_546"
          d="M11.135 52.2598H9.63499V53.7598H11.135V52.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_547"
          d="M12.635 53.7598H11.135V55.2598H12.635V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_548"
          d="M14.115 53.7598H12.615V55.2598H14.115V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_549"
          d="M11.135 53.7598H9.63499V55.2598H11.135V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_550"
          d="M12.635 55.2598H11.135V56.7598H12.635V55.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_551"
          d="M14.115 55.2598H12.615V56.7598H14.115V55.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_552"
          d="M11.135 55.2598H9.63499V56.7598H11.135V55.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_553"
          d="M12.635 56.7598H11.135V58.2598H12.635V56.7598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_554"
          d="M14.115 56.7598H12.615V58.2598H14.115V56.7598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_555"
          d="M11.135 56.7598H9.63499V58.2598H11.135V56.7598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_556"
          d="M12.635 58.2598H11.135V59.7598H12.635V58.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_557"
          d="M14.115 58.2598H12.615V59.7598H14.115V58.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_558"
          d="M11.135 58.2598H9.63499V59.7598H11.135V58.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_559"
          d="M12.635 59.7598H11.135V61.2598H12.635V59.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_560"
          d="M14.115 59.7598H12.615V61.2598H14.115V59.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_561"
          d="M11.135 59.7598H9.63499V61.2598H11.135V59.7598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_562"
          d="M12.635 61.2598H11.135V62.7598H12.635V61.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_563"
          d="M14.115 61.2598H12.615V62.7598H14.115V61.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_564"
          d="M11.135 61.2598H9.63499V62.7598H11.135V61.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_565"
          d="M12.635 62.7598H11.135V64.2598H12.635V62.7598Z"
          fill="black"
        />
        <path
          id="Vector_566"
          d="M14.115 62.7598H12.615V64.2598H14.115V62.7598Z"
          fill="black"
        />
        <path
          id="Vector_567"
          d="M11.135 62.7598H9.63499V64.2598H11.135V62.7598Z"
          fill="black"
        />
        <path
          id="Vector_568"
          d="M15.615 46.2598H14.115V47.7598H15.615V46.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_569"
          d="M15.615 47.7598H14.115V49.2598H15.615V47.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_570"
          d="M15.615 49.2598H14.115V50.7598H15.615V49.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_571"
          d="M15.615 50.7598H14.115V52.2598H15.615V50.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_572"
          d="M15.615 52.2598H14.115V53.7598H15.615V52.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_573"
          d="M15.615 53.7598H14.115V55.2598H15.615V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_574"
          d="M15.615 55.2598H14.115V56.7598H15.615V55.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_575"
          d="M15.615 56.7598H14.115V58.2598H15.615V56.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_576"
          d="M15.615 58.2598H14.115V59.7598H15.615V58.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_577"
          d="M15.615 59.7598H14.115V61.2598H15.615V59.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_578"
          d="M15.615 61.2598H14.115V62.7598H15.615V61.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_579"
          d="M15.615 62.7598H14.115V64.2598H15.615V62.7598Z"
          fill="black"
        />
        <path
          id="Vector_580"
          d="M15.625 46.2598H14.125V47.7598H15.625V46.2598Z"
          fill="black"
        />
        <path
          id="Vector_581"
          d="M15.625 47.7598H14.125V49.2598H15.625V47.7598Z"
          fill="black"
        />
        <path
          id="Vector_582"
          d="M15.625 49.2598H14.125V50.7598H15.625V49.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_583"
          d="M15.625 50.7598H14.125V52.2598H15.625V50.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_584"
          d="M15.625 52.2598H14.125V53.7598H15.625V52.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_585"
          d="M15.625 53.7598H14.125V55.2598H15.625V53.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_586"
          d="M15.625 55.2598H14.125V56.7598H15.625V55.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_587"
          d="M15.625 56.7598H14.125V58.2598H15.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_588"
          d="M15.625 58.2598H14.125V59.7598H15.625V58.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_589"
          d="M15.625 59.7598H14.125V61.2598H15.625V59.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_590"
          d="M15.625 61.2598H14.125V62.7598H15.625V61.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_591"
          d="M17.125 49.2598H15.625V50.7598H17.125V49.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_592"
          d="M17.125 50.7598H15.625V52.2598H17.125V50.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_593"
          d="M17.125 52.2598H15.625V53.7598H17.125V52.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_594"
          d="M17.125 53.7598H15.625V55.2598H17.125V53.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_595"
          d="M17.125 55.2598H15.625V56.7598H17.125V55.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_596"
          d="M17.125 56.7598H15.625V58.2598H17.125V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_597"
          d="M17.125 58.2598H15.625V59.7598H17.125V58.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_598"
          d="M17.125 59.7598H15.625V61.2598H17.125V59.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_599"
          d="M15.625 61.2598H14.125V62.7598H15.625V61.2598Z"
          fill="black"
        />
        <path
          id="Vector_600"
          d="M17.125 49.2598H15.625V50.7598H17.125V49.2598Z"
          fill="black"
        />
        <path
          id="Vector_601"
          d="M17.125 50.7598H15.625V52.2598H17.125V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_602"
          d="M17.125 52.2598H15.625V53.7598H17.125V52.2598Z"
          fill="black"
        />
        <path
          id="Vector_603"
          d="M18.625 53.7598H17.125V55.2598H18.625V53.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_604"
          d="M18.625 55.2598H17.125V56.7598H18.625V55.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_605"
          d="M18.625 56.7598H17.125V58.2598H18.625V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_606"
          d="M17.125 58.2598H15.625V59.7598H17.125V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_607"
          d="M17.125 59.7598H15.625V61.2598H17.125V59.7598Z"
          fill="black"
        />
        <path
          id="Vector_608"
          d="M18.625 53.7598H17.125V55.2598H18.625V53.7598Z"
          fill="black"
        />
        <path
          id="Vector_609"
          d="M18.625 55.2598H17.125V56.7598H18.625V55.2598Z"
          fill="black"
        />
        <path
          id="Vector_610"
          d="M18.625 56.7598H17.125V58.2598H18.625V56.7598Z"
          fill="black"
        />
        <path
          id="Vector_611"
          d="M9.63499 47.7598H8.13499V49.2598H9.63499V47.7598Z"
          fill="black"
        />
        <path
          id="Vector_612"
          d="M9.63499 49.2598H8.13499V50.7598H9.63499V49.2598Z"
          fill="black"
        />
        <path
          id="Vector_613"
          d="M9.63499 50.7598H8.13499V52.2598H9.63499V50.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_614"
          d="M9.63499 52.2598H8.13499V53.7598H9.63499V52.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_615"
          d="M9.63499 53.7598H8.13499V55.2598H9.63499V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_616"
          d="M9.63499 55.2598H8.13499V56.7598H9.63499V55.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_617"
          d="M9.63499 56.7598H8.13499V58.2598H9.63499V56.7598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_618"
          d="M9.63499 58.2598H8.13499V59.7598H9.63499V58.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_619"
          d="M9.63499 59.7598H8.13499V61.2598H9.63499V59.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_620"
          d="M9.63499 61.2598H8.13499V62.7598H9.63499V61.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_621"
          d="M9.63499 62.7598H8.13499V64.2598H9.63499V62.7598Z"
          fill="black"
        />
        <path
          id="Vector_622"
          d="M8.13499 49.2598H6.63499V50.7598H8.13499V49.2598Z"
          fill="black"
        />
        <path
          id="Vector_623"
          d="M8.13499 50.7598H6.63499V52.2598H8.13499V50.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_624"
          d="M8.13499 52.2598H6.63499V53.7598H8.13499V52.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_625"
          d="M8.13499 53.7598H6.63499V55.2598H8.13499V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_626"
          d="M8.13499 55.2598H6.63499V56.7598H8.13499V55.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_627"
          d="M8.13499 56.7598H6.63499V58.2598H8.13499V56.7598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_628"
          d="M8.13499 58.2598H6.63499V59.7598H8.13499V58.2598Z"
          fill="#37F6EE"
        />
        <path
          id="Vector_629"
          d="M8.13499 59.7598H6.63499V61.2598H8.13499V59.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_630"
          d="M8.13499 61.2598H6.63499V62.7598H8.13499V61.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_631"
          d="M8.13499 62.7598H6.63499V64.2598H8.13499V62.7598Z"
          fill="black"
        />
        <path
          id="Vector_632"
          d="M6.63499 49.2598H5.13499V50.7598H6.63499V49.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_633"
          d="M6.63499 49.2598H5.13499V50.7598H6.63499V49.2598Z"
          fill="black"
        />
        <path
          id="Vector_634"
          d="M6.63499 50.7598H5.13499V52.2598H6.63499V50.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_635"
          d="M6.63499 52.2598H5.13499V53.7598H6.63499V52.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_636"
          d="M6.63499 53.7598H5.13499V55.2598H6.63499V53.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_637"
          d="M6.63499 55.2598H5.13499V56.7598H6.63499V55.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_638"
          d="M6.63499 56.7598H5.13499V58.2598H6.63499V56.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_639"
          d="M6.63499 58.2598H5.13499V59.7598H6.63499V58.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_640"
          d="M6.63499 59.7598H5.13499V61.2598H6.63499V59.7598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_641"
          d="M6.63499 61.2598H5.13499V62.7598H6.63499V61.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_642"
          d="M6.63499 62.7598H5.13499V64.2598H6.63499V62.7598Z"
          fill="black"
        />
        <path
          id="Vector_643"
          d="M5.13499 47.7598H3.63499V49.2598H5.13499V47.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_644"
          d="M5.13499 49.2598H3.63499V50.7598H5.13499V49.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_645"
          d="M5.13499 47.7598H3.63499V49.2598H5.13499V47.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_646"
          d="M5.13499 47.7598H3.63499V49.2598H5.13499V47.7598Z"
          fill="black"
        />
        <path
          id="Vector_647"
          d="M5.13499 49.2598H3.63499V50.7598H5.13499V49.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_648"
          d="M5.13499 50.7598H3.63499V52.2598H5.13499V50.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_649"
          d="M5.13499 52.2598H3.63499V53.7598H5.13499V52.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_650"
          d="M5.13499 53.7598H3.63499V55.2598H5.13499V53.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_651"
          d="M5.13499 55.2598H3.63499V56.7598H5.13499V55.2598Z"
          fill="#2EBCB5"
        />
        <path
          id="Vector_652"
          d="M5.13499 56.7598H3.63499V58.2598H5.13499V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_653"
          d="M5.13499 58.2598H3.63499V59.7598H5.13499V58.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_654"
          d="M5.13499 59.7598H3.63499V61.2598H5.13499V59.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_655"
          d="M5.13499 61.2598H3.63499V62.7598H5.13499V61.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_656"
          d="M3.63499 50.7598H2.13499V52.2598H3.63499V50.7598Z"
          fill="black"
        />
        <path
          id="Vector_657"
          d="M3.63499 49.2598H2.13499V50.7598H3.63499V49.2598Z"
          fill="black"
        />
        <path
          id="Vector_658"
          d="M3.63499 52.2598H2.13499V53.7598H3.63499V52.2598Z"
          fill="black"
        />
        <path
          id="Vector_659"
          d="M3.63499 53.7598H2.13499V55.2598H3.63499V53.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_660"
          d="M3.63499 55.2598H2.13499V56.7598H3.63499V55.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_661"
          d="M3.63499 56.7598H2.13499V58.2598H3.63499V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_662"
          d="M3.63499 58.2598H2.13499V59.7598H3.63499V58.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_663"
          d="M3.63499 59.7598H2.13499V61.2598H3.63499V59.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_664"
          d="M5.125 61.2598H3.625V62.7598H5.125V61.2598Z"
          fill="black"
        />
        <path
          id="Vector_665"
          d="M2.13499 53.7598H0.634995V55.2598H2.13499V53.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_666"
          d="M2.13499 55.2598H0.634995V56.7598H2.13499V55.2598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_667"
          d="M2.13499 56.7598H0.634995V58.2598H2.13499V56.7598Z"
          fill="#2B9490"
        />
        <path
          id="Vector_668"
          d="M3.625 58.2598H2.125V59.7598H3.625V58.2598Z"
          fill="black"
        />
        <path
          id="Vector_669"
          d="M3.625 59.7598H2.125V61.2598H3.625V59.7598Z"
          fill="black"
        />
        <path
          id="Vector_670"
          d="M2.125 53.7598H0.625V55.2598H2.125V53.7598Z"
          fill="black"
        />
        <path
          id="Vector_671"
          d="M2.125 55.2598H0.625V56.7598H2.125V55.2598Z"
          fill="black"
        />
        <path
          id="Vector_672"
          d="M2.125 56.7598H0.625V58.2598H2.125V56.7598Z"
          fill="black"
        />
        <path
          id="Vector_673"
          d="M53.125 53.7598H51.625V55.2598H53.125V53.7598Z"
          fill="#445569"
        />
        <path
          id="Vector_674"
          d="M54.625 53.7598H53.125V55.2598H54.625V53.7598Z"
          fill="#445569"
        />
        <path
          id="Vector_675"
          d="M56.125 53.7598H54.625V55.2598H56.125V53.7598Z"
          fill="#445569"
        />
        <path
          id="Vector_676"
          d="M57.625 53.7598H56.125V55.2598H57.625V53.7598Z"
          fill="#445569"
        />
        <path
          id="Vector_677"
          d="M59.125 53.7598H57.625V55.2598H59.125V53.7598Z"
          fill="#445569"
        />
        <path
          id="Vector_678"
          d="M60.625 53.7598H59.125V55.2598H60.625V53.7598Z"
          fill="#445569"
        />
        <path
          id="Vector_679"
          d="M53.125 55.2598H51.625V56.7598H53.125V55.2598Z"
          fill="#445569"
        />
        <path
          id="Vector_680"
          d="M54.625 55.2598H53.125V56.7598H54.625V55.2598Z"
          fill="#445569"
        />
        <path
          id="Vector_681"
          d="M56.125 55.2598H54.625V56.7598H56.125V55.2598Z"
          fill="#445569"
        />
        <path
          id="Vector_682"
          d="M57.625 55.2598H56.125V56.7598H57.625V55.2598Z"
          fill="#445569"
        />
        <path
          id="Vector_683"
          d="M59.125 55.2598H57.625V56.7598H59.125V55.2598Z"
          fill="#445569"
        />
        <path
          id="Vector_684"
          d="M60.625 55.2598H59.125V56.7598H60.625V55.2598Z"
          fill="#445569"
        />
        <path
          id="Vector_685"
          d="M53.125 56.7598H51.625V58.2598H53.125V56.7598Z"
          fill="#445569"
        />
        <path
          id="Vector_686"
          d="M54.625 56.7598H53.125V58.2598H54.625V56.7598Z"
          fill="#445569"
        />
        <path
          id="Vector_687"
          d="M56.125 56.7598H54.625V58.2598H56.125V56.7598Z"
          fill="#445569"
        />
        <path
          id="Vector_688"
          d="M57.625 56.7598H56.125V58.2598H57.625V56.7598Z"
          fill="#445569"
        />
        <path
          id="Vector_689"
          d="M59.125 56.7598H57.625V58.2598H59.125V56.7598Z"
          fill="#445569"
        />
        <path
          id="Vector_690"
          d="M53.125 52.2598H51.625V53.7598H53.125V52.2598Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_691"
          d="M54.625 52.2598H53.125V53.7598H54.625V52.2598Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_692"
          d="M56.125 52.2598H54.625V53.7598H56.125V52.2598Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_693"
          d="M57.625 52.2598H56.125V53.7598H57.625V52.2598Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_694"
          d="M59.125 52.2598H57.625V53.7598H59.125V52.2598Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_695"
          d="M5.125 0.740234H3.625V2.24023H5.125V0.740234Z"
          fill="black"
        />
        <path
          id="Vector_696"
          d="M6.625 0.740234H5.125V2.24023H6.625V0.740234Z"
          fill="black"
        />
        <path
          id="Vector_697"
          d="M12.625 0.740234H11.125V2.24023H12.625V0.740234Z"
          fill="black"
        />
        <path
          id="Vector_698"
          d="M14.125 0.740234H12.625V2.24023H14.125V0.740234Z"
          fill="black"
        />
        <path
          id="Vector_699"
          d="M5.125 2.24023H3.625V3.74023H5.125V2.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_700"
          d="M3.625 2.24023H2.125V3.74023H3.625V2.24023Z"
          fill="black"
        />
        <path
          id="Vector_701"
          d="M6.625 2.24023H5.125V3.74023H6.625V2.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_702"
          d="M8.125 2.24023H6.625V3.74023H8.125V2.24023Z"
          fill="black"
        />
        <path
          id="Vector_703"
          d="M11.125 2.24023H9.625V3.74023H11.125V2.24023Z"
          fill="black"
        />
        <path
          id="Vector_704"
          d="M12.625 2.24023H11.125V3.74023H12.625V2.24023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_705"
          d="M14.125 2.24023H12.625V3.74023H14.125V2.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_706"
          d="M15.625 2.24023H14.125V3.74023H15.625V2.24023Z"
          fill="black"
        />
        <path
          id="Vector_707"
          d="M5.125 3.74023H3.625V5.24023H5.125V3.74023Z"
          fill="#EFEDEE"
        />
        <path
          id="Vector_708"
          d="M3.625 3.74023H2.125V5.24023H3.625V3.74023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_709"
          d="M2.125 3.74023H0.625V5.24023H2.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_710"
          d="M6.625 3.74023H5.125V5.24023H6.625V3.74023Z"
          fill="#EFEDEE"
        />
        <path
          id="Vector_711"
          d="M8.125 3.74023H6.625V5.24023H8.125V3.74023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_712"
          d="M9.625 3.74023H8.125V5.24023H9.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_713"
          d="M11.125 3.74023H9.625V5.24023H11.125V3.74023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_714"
          d="M12.625 3.74023H11.125V5.24023H12.625V3.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_715"
          d="M14.125 3.74023H12.625V5.24023H14.125V3.74023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_716"
          d="M15.625 3.74023H14.125V5.24023H15.625V3.74023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_717"
          d="M17.125 3.74023H15.625V5.24023H17.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_718"
          d="M18.625 3.74023H17.125V5.24023H18.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_719"
          d="M20.125 3.74023H18.625V5.24023H20.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_720"
          d="M21.625 3.74023H20.125V5.24023H21.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_721"
          d="M23.125 3.74023H21.625V5.24023H23.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_722"
          d="M24.625 3.74023H23.125V5.24023H24.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_723"
          d="M26.125 3.74023H24.625V5.24023H26.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_724"
          d="M27.625 3.74023H26.125V5.24023H27.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_725"
          d="M29.125 3.74023H27.625V5.24023H29.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_726"
          d="M30.625 3.74023H29.125V5.24023H30.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_727"
          d="M32.125 3.74023H30.625V5.24023H32.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_728"
          d="M33.625 3.74023H32.125V5.24023H33.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_729"
          d="M35.125 3.74023H33.625V5.24023H35.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_730"
          d="M36.625 3.74023H35.125V5.24023H36.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_731"
          d="M38.125 3.74023H36.625V5.24023H38.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_732"
          d="M39.625 3.74023H38.125V5.24023H39.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_733"
          d="M41.125 3.74023H39.625V5.24023H41.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_734"
          d="M42.625 3.74023H41.125V5.24023H42.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_735"
          d="M44.125 3.74023H42.625V5.24023H44.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_736"
          d="M45.625 3.74023H44.125V5.24023H45.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_737"
          d="M47.125 3.74023H45.625V5.24023H47.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_738"
          d="M48.625 3.74023H47.125V5.24023H48.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_739"
          d="M50.125 3.74023H48.625V5.24023H50.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_740"
          d="M51.625 3.74023H50.125V5.24023H51.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_741"
          d="M53.125 3.74023H51.625V5.24023H53.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_742"
          d="M54.625 3.74023H53.125V5.24023H54.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_743"
          d="M56.125 3.74023H54.625V5.24023H56.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_744"
          d="M57.625 3.74023H56.125V5.24023H57.625V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_745"
          d="M59.125 3.74023H57.625V5.24023H59.125V3.74023Z"
          fill="black"
        />
        <path
          id="Vector_746"
          d="M5.125 5.24023H3.625V6.74023H5.125V5.24023Z"
          fill="#EFEDEE"
        />
        <path
          id="Vector_747"
          d="M3.625 5.24023H2.125V6.74023H3.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_748"
          d="M2.125 5.24023H0.625V6.74023H2.125V5.24023Z"
          fill="black"
        />
        <path
          id="Vector_749"
          d="M6.625 5.24023H5.125V6.74023H6.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_750"
          d="M8.125 5.24023H6.625V6.74023H8.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_751"
          d="M9.625 5.24023H8.125V6.74023H9.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_752"
          d="M11.125 5.24023H9.625V6.74023H11.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_753"
          d="M12.625 5.24023H11.125V6.74023H12.625V5.24023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_754"
          d="M14.125 5.24023H12.625V6.74023H14.125V5.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_755"
          d="M15.625 5.24023H14.125V6.74023H15.625V5.24023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_756"
          d="M17.125 5.24023H15.625V6.74023H17.125V5.24023Z"
          fill="black"
        />
        <path
          id="Vector_757"
          d="M18.625 5.24023H17.125V6.74023H18.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_758"
          d="M20.125 5.24023H18.625V6.74023H20.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_759"
          d="M21.625 5.24023H20.125V6.74023H21.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_760"
          d="M23.125 5.24023H21.625V6.74023H23.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_761"
          d="M24.625 5.24023H23.125V6.74023H24.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_762"
          d="M26.125 5.24023H24.625V6.74023H26.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_763"
          d="M27.625 5.24023H26.125V6.74023H27.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_764"
          d="M29.125 5.24023H27.625V6.74023H29.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_765"
          d="M30.625 5.24023H29.125V6.74023H30.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_766"
          d="M32.125 5.24023H30.625V6.74023H32.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_767"
          d="M33.625 5.24023H32.125V6.74023H33.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_768"
          d="M35.125 5.24023H33.625V6.74023H35.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_769"
          d="M36.625 5.24023H35.125V6.74023H36.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_770"
          d="M38.125 5.24023H36.625V6.74023H38.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_771"
          d="M39.625 5.24023H38.125V6.74023H39.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_772"
          d="M41.125 5.24023H39.625V6.74023H41.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_773"
          d="M42.625 5.24023H41.125V6.74023H42.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_774"
          d="M44.125 5.24023H42.625V6.74023H44.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_775"
          d="M45.625 5.24023H44.125V6.74023H45.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_776"
          d="M47.125 5.24023H45.625V6.74023H47.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_777"
          d="M48.625 5.24023H47.125V6.74023H48.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_778"
          d="M50.125 5.24023H48.625V6.74023H50.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_779"
          d="M51.625 5.24023H50.125V6.74023H51.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_780"
          d="M53.125 5.24023H51.625V6.74023H53.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_781"
          d="M54.625 5.24023H53.125V6.74023H54.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_782"
          d="M56.125 5.24023H54.625V6.74023H56.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_783"
          d="M57.625 5.24023H56.125V6.74023H57.625V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_784"
          d="M59.125 5.24023H57.625V6.74023H59.125V5.24023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_785"
          d="M60.625 5.24023H59.125V6.74023H60.625V5.24023Z"
          fill="black"
        />
        <path
          id="Vector_786"
          d="M5.125 6.74023H3.625V8.24023H5.125V6.74023Z"
          fill="#EFEDEE"
        />
        <path
          id="Vector_787"
          d="M3.625 6.74023H2.125V8.24023H3.625V6.74023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_788"
          d="M2.125 6.74023H0.625V8.24023H2.125V6.74023Z"
          fill="black"
        />
        <path
          id="Vector_789"
          d="M6.625 6.74023H5.125V8.24023H6.625V6.74023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_790"
          d="M8.125 6.74023H6.625V8.24023H8.125V6.74023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_791"
          d="M9.625 6.74023H8.125V8.24023H9.625V6.74023Z"
          fill="#FF4588"
        />
        <path
          id="Vector_792"
          d="M11.125 6.74023H9.625V8.24023H11.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_793"
          d="M12.625 6.74023H11.125V8.24023H12.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_794"
          d="M14.125 6.74023H12.625V8.24023H14.125V6.74023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_795"
          d="M15.625 6.74023H14.125V8.24023H15.625V6.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_796"
          d="M17.125 6.74023H15.625V8.24023H17.125V6.74023Z"
          fill="black"
        />
        <path
          id="Vector_797"
          d="M18.625 6.74023H17.125V8.24023H18.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_798"
          d="M20.125 6.74023H18.625V8.24023H20.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_799"
          d="M21.625 6.74023H20.125V8.24023H21.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_800"
          d="M23.125 6.74023H21.625V8.24023H23.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_801"
          d="M24.625 6.74023H23.125V8.24023H24.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_802"
          d="M26.125 6.74023H24.625V8.24023H26.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_803"
          d="M27.625 6.74023H26.125V8.24023H27.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_804"
          d="M29.125 6.74023H27.625V8.24023H29.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_805"
          d="M30.625 6.74023H29.125V8.24023H30.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_806"
          d="M32.125 6.74023H30.625V8.24023H32.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_807"
          d="M33.625 6.74023H32.125V8.24023H33.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_808"
          d="M35.125 6.74023H33.625V8.24023H35.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_809"
          d="M36.625 6.74023H35.125V8.24023H36.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_810"
          d="M38.125 6.74023H36.625V8.24023H38.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_811"
          d="M39.625 6.74023H38.125V8.24023H39.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_812"
          d="M41.125 6.74023H39.625V8.24023H41.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_813"
          d="M42.625 6.74023H41.125V8.24023H42.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_814"
          d="M44.125 6.74023H42.625V8.24023H44.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_815"
          d="M45.625 6.74023H44.125V8.24023H45.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_816"
          d="M47.125 6.74023H45.625V8.24023H47.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_817"
          d="M48.625 6.74023H47.125V8.24023H48.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_818"
          d="M50.125 6.74023H48.625V8.24023H50.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_819"
          d="M51.625 6.74023H50.125V8.24023H51.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_820"
          d="M53.125 6.74023H51.625V8.24023H53.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_821"
          d="M54.625 6.74023H53.125V8.24023H54.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_822"
          d="M56.125 6.74023H54.625V8.24023H56.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_823"
          d="M57.625 6.74023H56.125V8.24023H57.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_824"
          d="M59.125 6.74023H57.625V8.24023H59.125V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_825"
          d="M60.625 6.74023H59.125V8.24023H60.625V6.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_826"
          d="M62.125 6.74023H60.625V8.24023H62.125V6.74023Z"
          fill="black"
        />
        <path
          id="Vector_827"
          d="M5.125 8.24023H3.625V9.74023H5.125V8.24023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_828"
          d="M3.625 8.24023H2.125V9.74023H3.625V8.24023Z"
          fill="black"
        />
        <path
          id="Vector_829"
          d="M6.625 8.24023H5.125V9.74023H6.625V8.24023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_830"
          d="M8.125 8.24023H6.625V9.74023H8.125V8.24023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_831"
          d="M9.625 8.24023H8.125V9.74023H9.625V8.24023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_832"
          d="M11.125 8.24023H9.625V9.74023H11.125V8.24023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_833"
          d="M12.625 8.24023H11.125V9.74023H12.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_834"
          d="M14.125 8.24023H12.625V9.74023H14.125V8.24023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_835"
          d="M15.625 8.24023H14.125V9.74023H15.625V8.24023Z"
          fill="black"
        />
        <path
          id="Vector_836"
          d="M17.125 8.24023H15.625V9.74023H17.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_837"
          d="M18.625 8.24023H17.125V9.74023H18.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_838"
          d="M20.125 8.24023H18.625V9.74023H20.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_839"
          d="M21.625 8.24023H20.125V9.74023H21.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_840"
          d="M23.125 8.24023H21.625V9.74023H23.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_841"
          d="M24.625 8.24023H23.125V9.74023H24.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_842"
          d="M26.125 8.24023H24.625V9.74023H26.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_843"
          d="M27.625 8.24023H26.125V9.74023H27.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_844"
          d="M29.125 8.24023H27.625V9.74023H29.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_845"
          d="M30.625 8.24023H29.125V9.74023H30.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_846"
          d="M32.125 8.24023H30.625V9.74023H32.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_847"
          d="M33.625 8.24023H32.125V9.74023H33.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_848"
          d="M35.125 8.24023H33.625V9.74023H35.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_849"
          d="M36.625 8.24023H35.125V9.74023H36.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_850"
          d="M38.125 8.24023H36.625V9.74023H38.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_851"
          d="M39.625 8.24023H38.125V9.74023H39.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_852"
          d="M41.125 8.24023H39.625V9.74023H41.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_853"
          d="M42.625 8.24023H41.125V9.74023H42.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_854"
          d="M44.125 8.24023H42.625V9.74023H44.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_855"
          d="M45.625 8.24023H44.125V9.74023H45.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_856"
          d="M47.125 8.24023H45.625V9.74023H47.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_857"
          d="M48.625 8.24023H47.125V9.74023H48.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_858"
          d="M50.125 8.24023H48.625V9.74023H50.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_859"
          d="M51.625 8.24023H50.125V9.74023H51.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_860"
          d="M53.125 8.24023H51.625V9.74023H53.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_861"
          d="M54.625 8.24023H53.125V9.74023H54.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_862"
          d="M56.125 8.24023H54.625V9.74023H56.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_863"
          d="M57.625 8.24023H56.125V9.74023H57.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_864"
          d="M59.125 8.24023H57.625V9.74023H59.125V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_865"
          d="M60.625 8.24023H59.125V9.74023H60.625V8.24023Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_866"
          d="M62.125 8.24023H60.625V9.74023H62.125V8.24023Z"
          fill="black"
        />
        <path
          id="Vector_867"
          d="M5.125 9.74023H3.625V11.2402H5.125V9.74023Z"
          fill="black"
        />
        <path
          id="Vector_868"
          d="M6.625 9.74023H5.125V11.2402H6.625V9.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_869"
          d="M8.125 9.74023H6.625V11.2402H8.125V9.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_870"
          d="M9.625 9.74023H8.125V11.2402H9.625V9.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_871"
          d="M11.125 9.74023H9.625V11.2402H11.125V9.74023Z"
          fill="#EF1432"
        />
        <path
          id="Vector_872"
          d="M12.625 9.74023H11.125V11.2402H12.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_873"
          d="M14.125 9.74023H12.625V11.2402H14.125V9.74023Z"
          fill="black"
        />
        <path
          id="Vector_874"
          d="M15.625 9.74023H14.125V11.2402H15.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_875"
          d="M17.125 9.74023H15.625V11.2402H17.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_876"
          d="M18.625 9.74023H17.125V11.2402H18.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_877"
          d="M20.125 9.74023H18.625V11.2402H20.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_878"
          d="M21.625 9.74023H20.125V11.2402H21.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_879"
          d="M23.125 9.74023H21.625V11.2402H23.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_880"
          d="M24.625 9.74023H23.125V11.2402H24.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_881"
          d="M26.125 9.74023H24.625V11.2402H26.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_882"
          d="M27.625 9.74023H26.125V11.2402H27.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_883"
          d="M29.125 9.74023H27.625V11.2402H29.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_884"
          d="M30.625 9.74023H29.125V11.2402H30.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_885"
          d="M32.125 9.74023H30.625V11.2402H32.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_886"
          d="M33.625 9.74023H32.125V11.2402H33.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_887"
          d="M35.125 9.74023H33.625V11.2402H35.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_888"
          d="M36.625 9.74023H35.125V11.2402H36.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_889"
          d="M38.125 9.74023H36.625V11.2402H38.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_890"
          d="M39.625 9.74023H38.125V11.2402H39.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_891"
          d="M41.125 9.74023H39.625V11.2402H41.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_892"
          d="M42.625 9.74023H41.125V11.2402H42.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_893"
          d="M44.125 9.74023H42.625V11.2402H44.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_894"
          d="M45.625 9.74023H44.125V11.2402H45.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_895"
          d="M47.125 9.74023H45.625V11.2402H47.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_896"
          d="M48.625 9.74023H47.125V11.2402H48.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_897"
          d="M50.125 9.74023H48.625V11.2402H50.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_898"
          d="M51.625 9.74023H50.125V11.2402H51.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_899"
          d="M53.125 9.74023H51.625V11.2402H53.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_900"
          d="M54.625 9.74023H53.125V11.2402H54.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_901"
          d="M56.125 9.74023H54.625V11.2402H56.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_902"
          d="M57.625 9.74023H56.125V11.2402H57.625V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_903"
          d="M59.125 9.74023H57.625V11.2402H59.125V9.74023Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_904"
          d="M60.625 9.74023H59.125V11.2402H60.625V9.74023Z"
          fill="black"
        />
        <path
          id="Vector_905"
          d="M6.625 11.2402H5.125V12.7402H6.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_906"
          d="M8.125 11.2402H6.625V12.7402H8.125V11.2402Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_907"
          d="M9.625 11.2402H8.125V12.7402H9.625V11.2402Z"
          fill="#CF1F32"
        />
        <path
          id="Vector_908"
          d="M11.125 11.2402H9.625V12.7402H11.125V11.2402Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_909"
          d="M12.625 11.2402H11.125V12.7402H12.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_910"
          d="M14.125 11.2402H12.625V12.7402H14.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_911"
          d="M15.625 11.2402H14.125V12.7402H15.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_912"
          d="M17.125 11.2402H15.625V12.7402H17.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_913"
          d="M18.625 11.2402H17.125V12.7402H18.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_914"
          d="M20.125 11.2402H18.625V12.7402H20.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_915"
          d="M21.625 11.2402H20.125V12.7402H21.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_916"
          d="M23.125 11.2402H21.625V12.7402H23.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_917"
          d="M24.625 11.2402H23.125V12.7402H24.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_918"
          d="M26.125 11.2402H24.625V12.7402H26.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_919"
          d="M27.625 11.2402H26.125V12.7402H27.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_920"
          d="M29.125 11.2402H27.625V12.7402H29.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_921"
          d="M30.625 11.2402H29.125V12.7402H30.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_922"
          d="M32.125 11.2402H30.625V12.7402H32.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_923"
          d="M33.625 11.2402H32.125V12.7402H33.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_924"
          d="M35.125 11.2402H33.625V12.7402H35.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_925"
          d="M36.625 11.2402H35.125V12.7402H36.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_926"
          d="M38.125 11.2402H36.625V12.7402H38.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_927"
          d="M39.625 11.2402H38.125V12.7402H39.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_928"
          d="M41.125 11.2402H39.625V12.7402H41.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_929"
          d="M42.625 11.2402H41.125V12.7402H42.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_930"
          d="M44.125 11.2402H42.625V12.7402H44.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_931"
          d="M45.625 11.2402H44.125V12.7402H45.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_932"
          d="M47.125 11.2402H45.625V12.7402H47.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_933"
          d="M48.625 11.2402H47.125V12.7402H48.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_934"
          d="M50.125 11.2402H48.625V12.7402H50.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_935"
          d="M51.625 11.2402H50.125V12.7402H51.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_936"
          d="M53.125 11.2402H51.625V12.7402H53.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_937"
          d="M54.625 11.2402H53.125V12.7402H54.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_938"
          d="M56.125 11.2402H54.625V12.7402H56.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_939"
          d="M57.625 11.2402H56.125V12.7402H57.625V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_940"
          d="M59.125 11.2402H57.625V12.7402H59.125V11.2402Z"
          fill="black"
        />
        <path
          id="Vector_941"
          d="M8.125 12.7402H6.625V14.2402H8.125V12.7402Z"
          fill="black"
        />
        <path
          id="Vector_942"
          d="M9.625 12.7402H8.125V14.2402H9.625V12.7402Z"
          fill="#7D1A1E"
        />
        <path
          id="Vector_943"
          d="M11.125 12.7402H9.625V14.2402H11.125V12.7402Z"
          fill="black"
        />
        <path
          id="Vector_944"
          d="M9.625 14.2402H8.125V15.7402H9.625V14.2402Z"
          fill="black"
        />
        <path
          id="Vector_945"
          d="M53.125 6.74023H51.625V8.24023H53.125V6.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_946"
          d="M54.625 6.74023H53.125V8.24023H54.625V6.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_947"
          d="M56.125 6.74023H54.625V8.24023H56.125V6.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_948"
          d="M57.625 6.74023H56.125V8.24023H57.625V6.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_949"
          d="M59.125 6.74023H57.625V8.24023H59.125V6.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_950"
          d="M60.625 6.74023H59.125V8.24023H60.625V6.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_951"
          d="M53.125 8.24023H51.625V9.74023H53.125V8.24023Z"
          fill="#445569"
        />
        <path
          id="Vector_952"
          d="M54.625 8.24023H53.125V9.74023H54.625V8.24023Z"
          fill="#445569"
        />
        <path
          id="Vector_953"
          d="M56.125 8.24023H54.625V9.74023H56.125V8.24023Z"
          fill="#445569"
        />
        <path
          id="Vector_954"
          d="M57.625 8.24023H56.125V9.74023H57.625V8.24023Z"
          fill="#445569"
        />
        <path
          id="Vector_955"
          d="M59.125 8.24023H57.625V9.74023H59.125V8.24023Z"
          fill="#445569"
        />
        <path
          id="Vector_956"
          d="M60.625 8.24023H59.125V9.74023H60.625V8.24023Z"
          fill="#445569"
        />
        <path
          id="Vector_957"
          d="M53.125 9.74023H51.625V11.2402H53.125V9.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_958"
          d="M54.625 9.74023H53.125V11.2402H54.625V9.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_959"
          d="M56.125 9.74023H54.625V11.2402H56.125V9.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_960"
          d="M57.625 9.74023H56.125V11.2402H57.625V9.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_961"
          d="M59.125 9.74023H57.625V11.2402H59.125V9.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_962"
          d="M53.125 5.24023H51.625V6.74023H53.125V5.24023Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_963"
          d="M54.625 5.24023H53.125V6.74023H54.625V5.24023Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_964"
          d="M50.125 6.74023H48.625V8.24023H50.125V6.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_965"
          d="M51.625 6.74023H50.125V8.24023H51.625V6.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_966"
          d="M50.125 8.24023H48.625V9.74023H50.125V8.24023Z"
          fill="#445569"
        />
        <path
          id="Vector_967"
          d="M51.625 8.24023H50.125V9.74023H51.625V8.24023Z"
          fill="#445569"
        />
        <path
          id="Vector_968"
          d="M50.125 9.74023H48.625V11.2402H50.125V9.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_969"
          d="M51.625 9.74023H50.125V11.2402H51.625V9.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_970"
          d="M50.125 5.24023H48.625V6.74023H50.125V5.24023Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_971"
          d="M51.625 5.24023H50.125V6.74023H51.625V5.24023Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_972"
          d="M47.125 6.74023H45.625V8.24023H47.125V6.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_973"
          d="M48.625 6.74023H47.125V8.24023H48.625V6.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_974"
          d="M47.125 8.24023H45.625V9.74023H47.125V8.24023Z"
          fill="#445569"
        />
        <path
          id="Vector_975"
          d="M48.625 8.24023H47.125V9.74023H48.625V8.24023Z"
          fill="#445569"
        />
        <path
          id="Vector_976"
          d="M47.125 9.74023H45.625V11.2402H47.125V9.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_977"
          d="M48.625 9.74023H47.125V11.2402H48.625V9.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_978"
          d="M47.125 5.24023H45.625V6.74023H47.125V5.24023Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_979"
          d="M48.625 5.24023H47.125V6.74023H48.625V5.24023Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_980"
          d="M45.625 6.74023H44.125V8.24023H45.625V6.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_981"
          d="M45.625 8.24023H44.125V9.74023H45.625V8.24023Z"
          fill="#445569"
        />
        <path
          id="Vector_982"
          d="M45.625 9.74023H44.125V11.2402H45.625V9.74023Z"
          fill="#445569"
        />
        <path
          id="Vector_983"
          d="M45.625 5.24023H44.125V6.74023H45.625V5.24023Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_984"
          d="M56.125 5.24023H54.625V6.74023H56.125V5.24023Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_985"
          d="M57.625 5.24023H56.125V6.74023H57.625V5.24023Z"
          fill="#ACB6C1"
        />
        <path
          id="Vector_986"
          d="M59.125 5.24023H57.625V6.74023H59.125V5.24023Z"
          fill="#ACB6C1"
        />
      </g>
    </svg>
  );
};
