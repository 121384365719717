import { useNavigate } from 'react-router-dom';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Button } from '../ui/molecules/Button';
import { Gift } from '../ui/icons/Gift';
import { Flex } from '@chakra-ui/react';
import { useTrackEvent } from '../battle/hooks/useTrackEvent';
import { Text } from '../ui/atoms/Text';
import { HomeWheel } from '../ui/icons/HomeWheel';

export const FeelingLuckyButton = () => {
  const trackEvent = useTrackEvent();
  const navigate = useNavigate();
  return (
    <Button
      className="color-box"
      border="3px solid var(--Black)"
      color="var(--Black)"
      w="60px"
      minW={0}
      padding="10px"
      borderTop={0}
      onClick={() => {
        trackEvent(`telegramFeelingLuckyButtonClicked`, {});
        navigate('/spinner');
      }}
    >
      <Flex flexDirection={'column'} alignItems={'center'} w="100%" gap={1}>
        <HomeWheel style={{ width: '40px', height: '40px' }} />
        <Text
          colorId="Black"
          fontSize="6px"
          lineHeight="10px"
          whiteSpace={'pre-wrap'}
        >
          <TranslatedText
            translationKey={'spinToEarn'}
            defaultMessage={'Spin to earn'}
          />
          !
        </Text>
      </Flex>
    </Button>
  );
};
