/* eslint-disable max-classes-per-file */
import { PayloadAction } from '@reduxjs/toolkit';
import { BattleActionEntity, BattleMoveEntity } from './battle-action.entity';
import { BattlePlayerEntity } from './battle-player.entity';
import { BattleStatus } from './battle.entity';
import { StatusEffectEntity } from './status-effect.entity';

export enum BattleNextAction {
  INIT = `INIT`,
  MOVE_SELECTION = `MOVE_SELECTION`,
  PLAYER_CHOOSE = `PLAYER_CHOOSE`,
  PLAYER_DODGE = `PLAYER_DODGE`,
}

export enum UiEventType {
  PLAY_MOVE = `PLAY_MOVE`,
  PLAY_STATUS = `PLAY_STATUS`,
  PLAY_PARALYZE = `PLAY_PARALYZE`,
  PLAY_TICK_STATUS = `PLAY_TICK_STATUS`,
  PLAY_COUNTER_MOVE = `PLAY_COUNTER_MOVE`,
  PLAY_KO = `PLAY_KO`,
}

export type UiEventPayload<T extends object> = T & {
  initiatorPlayerId: string;
  targetPlayerId: string;
  damage: number;
};

export type UiEventPlayMove = {
  type: UiEventType.PLAY_MOVE;
  id: string;
  payload: UiEventPayload<{
    move: BattleMoveEntity;
    dodged?: boolean;
    critical?: boolean;
  }>;
};

export type UiEventPlayStatus = {
  type: UiEventType.PLAY_STATUS;
  id: string;
  payload: UiEventPayload<{
    status: StatusEffectEntity;
  }>;
};

export type UiEventPlayTickStatus = {
  type: UiEventType.PLAY_TICK_STATUS;
  id: string;
  payload: UiEventPayload<{
    status: StatusEffectEntity;
  }>;
};

export type UiEventPlayKO = {
  type: UiEventType.PLAY_KO;
  id: string;
  payload: { petKoId: string };
};

export type UiEvent =
  | UiEventPlayMove
  | UiEventPlayStatus
  | UiEventPlayTickStatus
  | UiEventPlayKO
  | {
      type: UiEventType.PLAY_PARALYZE;
      id: string;
      payload: { paralyzedPlayerId: string };
    }
  | {
      type: UiEventType.PLAY_COUNTER_MOVE;
      id: string;
      payload: UiEventPayload<{
        move: BattleMoveEntity;
        dodged?: boolean;
      }>;
    };

export interface RawBattleState {
  id: string;

  nextPlayer?: string;

  nextAction: BattleNextAction | undefined;

  player1: BattlePlayerEntity;

  player2: BattlePlayerEntity;

  actions: PayloadAction<BattleActionEntity>[];

  status: BattleStatus;

  elementalMoves: BattleMoveEntity[];

  winner?: string;

  error?: string;
}

export interface BattleState extends RawBattleState {
  history?: RawBattleState[];

  withUiEvents?: boolean;

  uiEvents?: UiEvent[];

  withHistory?: boolean;
}
