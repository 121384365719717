import { useIntegration } from '@tma.js/react-router-integration';
import {
  bindMiniAppCSSVars,
  bindThemeParamsCSSVars,
  bindViewportCSSVars,
  initNavigator,
  postEvent,
  useBackButton,
  useLaunchParams,
  useMiniApp,
  useThemeParams,
  useViewport,
} from '@tma.js/sdk-react';
import { AppRoot } from '@telegram-apps/telegram-ui';
import { type FC, useEffect, useMemo } from 'react';
import { Navigate, Route, Router, Routes } from 'react-router-dom';

import { routes } from '@/navigation/routes.tsx';
import { API_ROOT } from '@/config/endpoints';
import { setupFirebase } from '@/lib/firebase';
import {
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
} from 'firebase/auth';
import { useAuthState, useSignIn } from '@/context/UserContext';
import { Center } from '@chakra-ui/react';
import { MainContainer } from './battle/MainContainer';
import { BlinkingText } from './ui/atoms/BlinkingText';
import { FirestoreProvider } from '@/context/FirestoreContext';
import { VITE_SHOW_LOCAL_SIGN_IN } from '@/config/constants';
import { SignInButton } from './auth/SignInButton';
import { paramsToObject, parseStartParams } from '@/utils/utils';
import { useAlert } from '@/hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { Mute } from './ui/icons/Mute';
import { Text } from '@/components/ui/atoms/Text';
import * as amplitude from '@amplitude/analytics-browser';

export const App: FC = () => {
  const lp = useLaunchParams();
  const miniApp = useMiniApp();
  const themeParams = useThemeParams();
  const viewport = useViewport();
  const {
    i18n: { changeLanguage },
  } = useTranslation();

  useEffect(() => {
    return bindMiniAppCSSVars(miniApp, themeParams);
  }, [miniApp, themeParams]);

  useEffect(() => {
    return bindThemeParamsCSSVars(themeParams);
  }, [themeParams]);

  useEffect(() => {
    return viewport && bindViewportCSSVars(viewport);
  }, [viewport]);

  // Create new application navigator and attach it to the browser history, so it could modify
  // it and listen to its changes.
  const navigator = useMemo(() => initNavigator('app-navigation-state'), []);
  const [location, reactNavigator] = useIntegration(navigator);

  // Don't forget to attach the navigator to allow it to control the BackButton state as well
  // as browser history.
  useEffect(() => {
    if (!VITE_SHOW_LOCAL_SIGN_IN) {
      navigator.attach();
      return () => navigator.detach();
    }
  }, [navigator]);

  const { initDataRaw, startParam } = useLaunchParams();

  const params = parseStartParams(startParam);

  const { signIn } = useSignIn();

  const bb = useBackButton();

  bb.hide();

  useEffect(() => {
    postEvent(`web_app_expand`);
  }, []);

  useEffect(() => {
    setupFirebase();
    if (VITE_SHOW_LOCAL_SIGN_IN) {
      const auth = getAuth();

      onAuthStateChanged(auth, (user) => {
        console.log('user local signin', user, initDataRaw);
        if (user) {
          signIn(user);
        }
      });
    }
  }, []);

  const { state } = useAuthState();

  const alert = useAlert();

  useEffect(() => {
    if (initDataRaw) {
      try {
        const urlParams = new URLSearchParams(initDataRaw);
        const entries = urlParams.entries();
        const params: Record<string, string> = paramsToObject(entries);
        // eslint-disable-next-line no-unused-vars
        const user = JSON.parse(params.user);

        // set user's language for dev/staging
        if (import.meta.env.VITE_VERCEL_ENV === `development`) {
          changeLanguage(user?.language_code || `en`);
        } else {
          /* TODO: for future use when supported languages are ready.
          const supportedLanguages = new Set(['en', 'ru', 'jp', 'cn', 'fr']);
          if (supportedLanguages.has(user?.language_code)) {
            changeLanguage(user?.language_code);
          } else {
            changeLanguage(`en`);
          }
          */
          changeLanguage(`en`);
        }
      } catch (error) {
        console.error(`Error parsing initDataRaw:`, error);
      }
    }
  }, [initDataRaw]);

  useEffect(() => {
    if (initDataRaw) {
      if (VITE_SHOW_LOCAL_SIGN_IN) {
        try {
          const urlParams = new URLSearchParams(initDataRaw);
          const entries = urlParams.entries();
          const params: Record<string, string> = paramsToObject(entries);
          const user = JSON.parse(params.user);
          if (user.id === 99281932 && user.first_name === 'Andrew') {
            // see mockEnv.ts
            console.log('Mock env: do not try to connect with telegram token');
            return;
          }
        } catch (e) {
          // Catches a malformed URI
          console.error(e);
        }
      }
      const auth = getAuth();
      fetch(`${API_ROOT}/user/telegram-auth`, {
        method: 'POST',
        headers: {
          Authorization: `tma ${initDataRaw}`,
          'Content-Type': `application/json`,
        },
        body: params?.referrer
          ? JSON.stringify({
              referralCode: params.referrer,
            })
          : null,
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          console.log(`response`, response);

          // initialize amplitude w/ user id
          amplitude.init(
            import.meta.env.VITE_AMPLITUDE_API_KEY,
            response?.userData?.id,
            {
              autocapture: true,
            },
          );

          return signInWithCustomToken(auth, response.token);
        })
        .then((userCredentials) => {
          signIn(userCredentials.user);
          console.log(
            `I have signed in with ${JSON.stringify(userCredentials)}`,
          );
        })
        .catch((err: any) => {
          console.error(err);
          alert({ title: err?.message ?? JSON.stringify(err) });
        });
    }
  }, [initDataRaw]);

  return (
    <AppRoot
      appearance={miniApp.isDark ? 'dark' : 'light'}
      platform={['macos', 'ios'].includes(lp.platform) ? 'ios' : 'base'}
    >
      {state.state !== 'SIGNED_IN' ? (
        <MainContainer>
          <Center
            w="full"
            h="full"
            flexDirection={'column'}
            gap={2}
            position="relative"
          >
            {VITE_SHOW_LOCAL_SIGN_IN && <SignInButton />}
            <BlinkingText textAlign="center">
              <TranslatedText
                translationKey={`connectingToGenoverse`}
                defaultMessage={`Connecting to genoverse...`}
              />
            </BlinkingText>
            <Center
              w="full"
              flexDirection={'column'}
              gap={4}
              animation="blink 0.6s infinite"
            >
              <Mute />
              <Text colorId="White" w="full" textAlign={'center'}>
                <TranslatedText
                  translationKey={`turnSoundOn`}
                  defaultMessage={`turn Sound On`}
                />
                !
              </Text>
            </Center>
          </Center>
        </MainContainer>
      ) : (
        <FirestoreProvider>
          <Router location={location} navigator={reactNavigator}>
            <Routes>
              {routes.map((route) => (
                <Route key={route.path} {...route} />
              ))}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Router>
        </FirestoreProvider>
      )}
    </AppRoot>
  );
};
