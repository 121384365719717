import { now } from '../time';
import type { LeaderboardTimeframe, LeaderboardDefinition } from '../models';

/**
 * For current energy points leaderboard, below is the current config used to retrieve it
 * We would just need to change the values below when season changes
 */
export const ENERGY_POINTS_LEADERBOARD_TIMEFRAME = `all-time`;

export const ENERGY_POINTS_LEADERBOARD_TIMEFRAME_VALUE = `default`;

export const OKX_MANTLE_LEADERBOARD_START_DATE = `2024-10-22`;
export const OKX_MANTLE_LEADERBOARD_END_DATE = `2024-11-14`;

export const getEnergyPointsLeaderboardWeeklyTimeframeValue = (): string =>
  now().startOf(`week`).toISODate() as string;

export const getEnergyPointsLeaderboardTimeframeValue = ({
  timeframe,
  definition,
}: {
  timeframe?: LeaderboardTimeframe;
  definition?: LeaderboardDefinition;
}): { timeframe: LeaderboardTimeframe; timeframeValue: string } => {
  if (definition === `OKXMantleEnergyPointsDefinition`) {
    return {
      timeframe: `custom`,

      // same format as getTimeframeKey
      timeframeValue: `${OKX_MANTLE_LEADERBOARD_START_DATE}_${OKX_MANTLE_LEADERBOARD_END_DATE}`,
    };
  }

  if (timeframe === `weekly`) {
    return {
      timeframe: `weekly`,
      timeframeValue: getEnergyPointsLeaderboardWeeklyTimeframeValue(),
    };
  }
  // default is all time
  return {
    timeframe: ENERGY_POINTS_LEADERBOARD_TIMEFRAME,
    timeframeValue: ENERGY_POINTS_LEADERBOARD_TIMEFRAME_VALUE,
  };
};
