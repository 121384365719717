import { RENAME_PET_ENDPOINT } from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { Input, useDisclosure } from '@chakra-ui/react';
import { Pet } from 'genopets-utils';
import { useState } from 'react';
import { Button } from '../ui/molecules/Button';
import { Modal } from '../ui/molecules/Modal';
import { useAlert } from '@/hooks/useAlert';
import { Text } from '../ui/atoms/Text';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { useTranslation } from 'react-i18next';
import { getFontFamilyByLanguage, isJaOrZh } from '@/utils/utils';

export const RenameButton = ({ pet }: { pet: Pet }) => {
  const { t: translateText, i18n } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { apiPost } = useAPI();

  const alert = useAlert();

  const [nickname, setNickname] = useState(pet.nickname || pet.name);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const renamePet = () => {
    if (!nickname || nickname === pet.name) return;

    if (nickname.length > 16) {
      alert({
        title: translateText(`renamePetError`, {
          count: 17,
          defaultValue: 'Name must be less than 17 characters',
        }),
      });
      return;
    }
    setLoading(true);
    apiPost(RENAME_PET_ENDPOINT, {
      nickname: nickname.trim(),
      petId: pet.id,
    })
      .then(() => {
        onClose();
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        cardProps={{ gap: 0 }}
        footerProps={{ mt: 1 }}
      >
        <Input
          value={nickname}
          onChange={(e) => {
            if (e.target.value.length > 16) {
              setError(true);
              return;
            }
            setError(false);
            setNickname(e.target.value);
          }}
          border="none"
          _focus={{}}
          _focusVisible={{}}
          fontFamily={getFontFamilyByLanguage(i18n)}
          fontSize="14px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="20px"
          textTransform="uppercase"
          padding={'8px'}
          max={16}
        />
        {error && (
          <Text colorId="Red">
            <TranslatedText
              translationKey={`renamePetError`}
              defaultMessage={`Name must be less than 17 characters`}
              props={{ count: 17 }}
            />
          </Text>
        )}
        <Button
          mt={2}
          colorId="Black"
          w="full"
          isLoading={loading}
          onClick={() => {
            renamePet();
          }}
        >
          <TranslatedText translationKey={`save`} defaultMessage={`Save`} />
        </Button>
      </Modal>
      <Button
        colorId="Black"
        w="full"
        onClick={() => {
          onOpen();
        }}
      >
        <TranslatedText
          translationKey={`textRenamePetButton`}
          defaultMessage={`Rename pet`}
        />
      </Button>
    </>
  );
};
