import { useMusic } from '@/components/Sounds/useMusic';
import { MainContainer } from '@/components/battle/MainContainer';
import { PetStatistic } from '@/components/battle/PetStatistic';
import { bgSounds } from '@/lib/sounds';

export const BattleStatsPage = () => {
  useMusic(bgSounds.stats);

  return (
    <MainContainer background={'none'}>
      <PetStatistic />
    </MainContainer>
  );
};
