import { Button } from '@/components/ui/molecules/Button';
import { Card } from '@/components/ui/molecules/Card';
import { Box } from '@chakra-ui/react';
import { Camera } from 'react-camera-pro';
import { TranslatedText } from '../ui/atoms/TranslatedText';

export const CameraContainer = ({
  onBack,
  onValidateImage,
  camera,
  img,
}: {
  onBack: () => void;
  onValidateImage: (img: string) => void;
  showControls?: boolean;
  img: string | null;
  camera: React.MutableRefObject<any>;
}) => {
  if (img) {
    return (
      <Card
        margin="0px"
        padding="0px"
        position={'absolute'}
        bottom={0}
        left={0}
        right={0}
        height={'auto'}
      >
        {img && (
          <>
            <img
              src={img}
              alt="Taken photo"
              style={{ width: '100%', height: 'auto' }}
            />
            <Box w="full" padding={'16px'}>
              <Button
                colorId="Black"
                w="full"
                onClick={() => onValidateImage(img)}
              >
                <TranslatedText
                  translationKey="analyzeFood"
                  defaultMessage="Analyze Food"
                />
                !
              </Button>
              <Button w="full" colorId="White" onClick={onBack}>
                <TranslatedText
                  translationKey="retakeImage"
                  defaultMessage="retakeImage"
                />
              </Button>
            </Box>
          </>
        )}
      </Card>
    );
  }
  return (
    <Box>
      <Camera
        ref={camera}
        facingMode="environment"
        errorMessages={{
          noCameraAccessible:
            'No camera device accessible. Please connect your camera or try a different browser.',
          permissionDenied:
            'Permission denied. Please refresh and give camera permission.',
          switchCamera:
            'It is not possible to switch camera to different one because there is only one video device accessible.',
          canvas: 'Canvas is not supported.',
        }}
      />
    </Box>
  );
};
