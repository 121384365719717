import { LEADERBOARD_ENDPOINT } from '@/config/endpoints';
import { useTelegramPet, useUserData } from '@/context/FirestoreContext';
import useAPI from '@/hooks/useAPI';
import { getPetName } from '@/utils/utils';
import { Box, Center, Flex, VStack } from '@chakra-ui/react';
import {
  getTelegramPetImage,
  TELEGRAM_LEADERBOARD_PAGE_SIZE,
  EnergyPointsSummary,
  LeaderboardTimeframe,
} from 'genopets-utils';
import { EnergyPointsLeaderboardResponse } from 'genopets-utils/src/models/dto/leaderboard.response';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from '../ui/atoms/Text';
import { TextWithShadow } from '../ui/atoms/TextWithShadow';
import { Energy } from '../ui/icons/Energy';
import { Button } from '../ui/molecules/Button';
import { Card } from '../ui/molecules/Card';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { useTranslation } from 'react-i18next';
import { MailWhite } from '@/components/ui/icons/MailWhite';
import { BattleSword } from '@/components/ui/icons/BattleSword';
import {
  VITE_BATTLE_AIRDROP_ENABLED,
  VITE_SHOW_WEEKLY_LEADERBOARD,
  VITE_OKX_MANTLE_LEADERBOARD_ENABLED,
} from '@/config/constants';
import { AirdropCard } from '../airdrop/AirdropCard';
import { SummaryInfoRow } from '../ui/molecules/SummaryInfoRow';
import { useAirdropTimeLeft } from '@/components/airdrop/hooks/useAirdropTimeLeft';

const LETTER_WIDTH = 14;

export const Leaderboard = () => {
  const { t: translateText } = useTranslation();
  const [page, setPage] = useState(1);
  const [leaderboard, setLeaderboard] =
    useState<EnergyPointsLeaderboardResponse>();
  const [rankWidth, setRankWidth] = useState(10);
  const [energyWidth, setEnergyWidth] = useState(100);
  const { pet } = useTelegramPet();
  const [loading, setLoading] = useState(false);
  const [timeframe, setTimeframe] = useState<LeaderboardTimeframe>(
    VITE_SHOW_WEEKLY_LEADERBOARD ? 'weekly' : `all-time`,
  );
  const airdropTimeLeft = useAirdropTimeLeft();
  const hoursLeft = +airdropTimeLeft.split(`:`)?.[0];
  const { userData: user } = useUserData();
  const yourPetSummary: EnergyPointsSummary = (leaderboard?.yourPetSummary ||
    {}) as EnergyPointsSummary;

  const { apiGet } = useAPI();

  const navigate = useNavigate();

  const getLeaderboard = async () => {
    setLoading(true);
    const okxDefinitionParam =
      VITE_OKX_MANTLE_LEADERBOARD_ENABLED && timeframe === `weekly`
        ? '&definition=OKXMantleEnergyPointsDefinition'
        : '';
    apiGet(LEADERBOARD_ENDPOINT(page, timeframe) + okxDefinitionParam)
      .then((resp: EnergyPointsLeaderboardResponse) => {
        if (resp) {
          setLeaderboard(resp);
          setRankWidth(
            resp.leaderboard?.[resp.leaderboard.length - 1]?.rank?.toString()
              .length * LETTER_WIDTH,
          );
          setEnergyWidth(
            Math.max(
              ...resp.leaderboard.map(
                (leaderboardItem) =>
                  leaderboardItem.stepsOrEnergy.toString().length,
              ),
            ) * LETTER_WIDTH,
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getLeaderboard();
  }, [page, timeframe, apiGet]);

  const getPageSize = (direction: 'up' | 'down') => {
    if (!leaderboard) return '...';
    const start = (page - 1) * TELEGRAM_LEADERBOARD_PAGE_SIZE + 1;
    const end = page * TELEGRAM_LEADERBOARD_PAGE_SIZE;
    const nextStart = start + TELEGRAM_LEADERBOARD_PAGE_SIZE;
    const nextEnd = end + TELEGRAM_LEADERBOARD_PAGE_SIZE;
    const prevStart = start - TELEGRAM_LEADERBOARD_PAGE_SIZE;
    const prevEnd = end - TELEGRAM_LEADERBOARD_PAGE_SIZE;

    if (direction === 'up') {
      if (end >= leaderboard.totalItemCount) {
        return translateText('noMorePages', 'No more pages');
      }
      return `${nextStart} - ${nextEnd}`;
    } else {
      if (page === 1) {
        return translateText('noMorePages', 'No more pages');
      }
      return `${prevStart} - ${prevEnd}`;
    }
  };

  function getReferredCount() {
    if (timeframe === 'weekly') {
      return (
        leaderboard?.weeklyTelegramInfo?.referralCount || 0
      ).toLocaleString();
    }
    return (yourPetSummary.referredUsersCount || 0).toLocaleString();
  }

  function getReferralPoints() {
    if (timeframe === 'weekly') {
      return (
        leaderboard?.weeklyTelegramInfo?.referralPoints || 0
      ).toLocaleString();
    }
    return (yourPetSummary.referralPoints || 0).toLocaleString();
  }

  function getBattleCount() {
    if (timeframe === 'weekly') {
      return (
        leaderboard?.weeklyTelegramInfo?.telegramBattleCount || 0
      ).toLocaleString();
    }
    return (
      (user?.telegramBattleWinCount ?? 0) + (user?.telegramBattleLossCount ?? 0)
    ).toLocaleString();
  }

  function getBattlePoints() {
    if (timeframe === 'weekly') {
      return (
        leaderboard?.weeklyTelegramInfo?.telegramBattleScore || 0
      ).toLocaleString();
    }
    return (
      (yourPetSummary?.energyPoints ?? 0) -
      (yourPetSummary?.referralPoints ?? 0)
    ).toLocaleString();
  }

  return (
    <VStack w="full" h={'full'}>
      {VITE_BATTLE_AIRDROP_ENABLED ? (
        <AirdropCard
          showTimeLeft={true}
          cardProps={{
            padding: `4px 8px`,
          }}
        />
      ) : null}
      <Center flexDirection={'column'} w="full">
        {pet && (
          <img
            src={getTelegramPetImage(pet.petConfigV2)}
            alt="pet"
            style={{
              width: '150px',
              height: '150px',
              marginTop: VITE_BATTLE_AIRDROP_ENABLED ? `-30px` : `-20px`,
            }}
          />
        )}
        <TextWithShadow mt="-35px">{getPetName(pet)}</TextWithShadow>
        <TextWithShadow>
          {loading ? (
            `Rank -`
          ) : (
            <TranslatedText
              translationKey={`rank`}
              defaultMessage={`Rank`}
              props={{ number: yourPetSummary?.rank ?? '-' }}
            />
          )}
        </TextWithShadow>
      </Center>
      <Center
        mt={`-10px`}
        flexDirection={'column'}
        w="full"
        padding={`0px 16px`}
        bg={`black`}
      >
        <SummaryInfoRow
          leftComponent={
            <>
              <MailWhite />
              <Text colorId={`White`}>
                {leaderboard && !loading ? getReferredCount() : `...`}
              </Text>
            </>
          }
          rightComponent={
            <>
              <Energy />
              <Text colorId={`White`}>
                {leaderboard && !loading ? getReferralPoints() : `...`}
              </Text>
            </>
          }
        />

        <SummaryInfoRow
          leftComponent={
            <>
              <BattleSword />
              <Text colorId={`White`}>
                {leaderboard && !loading ? getBattleCount() : `...`}
              </Text>
            </>
          }
          rightComponent={
            <>
              <Energy />
              <Text colorId={`White`}>
                {leaderboard && !loading ? getBattlePoints() : `...`}
              </Text>
            </>
          }
        />

        <SummaryInfoRow
          leftComponent={<Text colorId={`White`}>Total</Text>}
          rightComponent={
            <>
              <Energy />
              <Text colorId={`White`}>
                {leaderboard && !loading
                  ? yourPetSummary.energyPoints.toLocaleString() || 0
                  : `...`}
              </Text>
            </>
          }
        />
      </Center>

      <Card
        w="full"
        height="full"
        display={'flex'}
        flexDirection={'column'}
        mt={`-10px`}
      >
        {VITE_SHOW_WEEKLY_LEADERBOARD ? (
          <>
            <Text colorId="Black" w="full" textAlign={'center'} mt={`-12px`}>
              {timeframe === `weekly` ? (
                VITE_OKX_MANTLE_LEADERBOARD_ENABLED ? (
                  `OKX/Mantle leaderboard`
                ) : (
                  <TranslatedText
                    translationKey={`thisWeekRankings`}
                    defaultMessage={`This week's rankings`}
                  />
                )
              ) : (
                <TranslatedText
                  translationKey={`allTimeLeaderboard`}
                  defaultMessage={`All time leaderboard`}
                />
              )}
            </Text>
            {timeframe === `weekly` &&
              airdropTimeLeft !== `00:00:00` &&
              VITE_OKX_MANTLE_LEADERBOARD_ENABLED && (
                <Text
                  color={
                    hoursLeft > 72
                      ? `#086F54`
                      : hoursLeft > 24
                        ? `#B7A500`
                        : `#A80000`
                  }
                  w="full"
                  textAlign={'center'}
                  mt={`-8px`}
                >
                  <TranslatedText
                    translationKey={`durationLeftInAirdropPrizes`}
                    defaultMessage={`${airdropTimeLeft} left`}
                    props={{ timeLeft: airdropTimeLeft }}
                  />
                  !
                </Text>
              )}
          </>
        ) : (
          <>
            <Text colorId="Black" w="full" textAlign={'center'} mt={`-8px`}>
              <TranslatedText
                translationKey={`leaderboard`}
                defaultMessage={`Leaderboard`}
              />
            </Text>
            <Text colorId="Black" w="full" textAlign={'center'}>
              <TranslatedText
                translationKey={`top`}
                defaultMessage={`Top`}
                props={{
                  number:
                    page === 1
                      ? TELEGRAM_LEADERBOARD_PAGE_SIZE
                      : `${(page - 1) * TELEGRAM_LEADERBOARD_PAGE_SIZE + 1} - ${page * TELEGRAM_LEADERBOARD_PAGE_SIZE}`,
                }}
              />
            </Text>
          </>
        )}

        {leaderboard && !loading ? (
          <Box overflow={'hidden'} w="full" height={'full'} position="relative">
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              overflow={'auto'}
              maxW={'full'}
            >
              {leaderboard.leaderboard.length ? (
                leaderboard.leaderboard.map((leaderboardItem, index) => {
                  return (
                    <Flex key={index} gap={2} w="full" alignItems={'center'}>
                      <Text
                        colorId={
                          yourPetSummary?.rank === leaderboardItem.rank
                            ? 'Green'
                            : 'Black'
                        }
                        textAlign={'center'}
                        minW={rankWidth + 'px'}
                      >
                        {leaderboardItem.rank}
                      </Text>
                      <img
                        src={leaderboardItem.telegramPetImageUrl}
                        alt="pet"
                        style={{ width: '32px', height: '32px' }}
                      />
                      <Text
                        colorId={
                          yourPetSummary?.rank === leaderboardItem.rank
                            ? 'Green'
                            : 'Black'
                        }
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        w="full"
                      >
                        {leaderboardItem.name}
                      </Text>
                      <Flex
                        gap={1}
                        justifyContent={'flex-start'}
                        minW={energyWidth + 24 + 'px'}
                      >
                        <Energy
                          color={
                            yourPetSummary?.rank === leaderboardItem.rank
                              ? 'var(--Green)'
                              : 'var(--Black)'
                          }
                        />
                        <Text
                          colorId={
                            yourPetSummary?.rank === leaderboardItem.rank
                              ? 'Green'
                              : 'Black'
                          }
                        >
                          {leaderboardItem.stepsOrEnergy}
                        </Text>
                      </Flex>
                    </Flex>
                  );
                })
              ) : (
                <Center w="full" h="full">
                  <Text colorId="Black">No leaderboard data</Text>
                </Center>
              )}
            </Box>
          </Box>
        ) : (
          <Center w="full" h="full">
            <Text colorId="Black">
              <TranslatedText
                translationKey={`loading`}
                defaultMessage={`Loading`}
              />
              ...
            </Text>
          </Center>
        )}
        <Flex gap={2} w="full" alignItems={'center'}>
          <Button
            w="full"
            minW={0}
            onClick={() => {
              if (page === 1) return;

              setPage(page - 1);
            }}
            height={'auto'}
            padding={`4px`}
            colorId="Black"
            isDisabled={loading || page === 1}
            whiteSpace={'pre-wrap'}
          >
            {getPageSize('down')}
          </Button>
          <Button
            w="full"
            minW={0}
            isDisabled={
              loading ||
              page * TELEGRAM_LEADERBOARD_PAGE_SIZE >=
                (leaderboard?.totalItemCount ?? 0)
            }
            onClick={() => {
              if (
                page * TELEGRAM_LEADERBOARD_PAGE_SIZE >=
                (leaderboard?.totalItemCount ?? 0)
              )
                return;

              setPage(page + 1);
            }}
            height={'auto'}
            padding={`4px`}
            colorId="Black"
            whiteSpace={'pre-wrap'}
          >
            {getPageSize('up')}
          </Button>
        </Flex>
        {VITE_SHOW_WEEKLY_LEADERBOARD && (
          <Button
            padding={`8px`}
            w="full"
            colorId="Black"
            isDisabled={loading}
            onClick={() => {
              if (timeframe === `weekly`) {
                setTimeframe(`all-time`);
              } else {
                setTimeframe(`weekly`);
              }
            }}
          >
            {timeframe === `weekly` ? (
              <TranslatedText
                translationKey={`allTimeLeaderboard`}
                defaultMessage={`All time leaderboard`}
              />
            ) : VITE_OKX_MANTLE_LEADERBOARD_ENABLED ? (
              `OKX/Mantle leaderboard`
            ) : (
              <TranslatedText
                translationKey={`weeklyLeaderboard`}
                defaultMessage={`Weekly leaderboard`}
              />
            )}
          </Button>
        )}

        <Button
          w="full"
          onClick={() => {
            navigate('/home');
          }}
          colorId={VITE_BATTLE_AIRDROP_ENABLED ? `White` : `Black`}
          padding={`8px 0`}
        >
          <TranslatedText
            translationKey={`goBack`}
            defaultMessage={`Go Back`}
          />
        </Button>
      </Card>
    </VStack>
  );
};
