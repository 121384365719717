import { CDN_HOST } from 'genopets-utils';

const basePath = `${CDN_HOST}/sounds`;
export const bgSounds = {
  battle: `${basePath}/telegram-battle-bg-music.mp3`,
  stats: `${basePath}/telegram-pet-stats-bg-music.mp3`,
  menu: `${basePath}/telegram-main-menu-bg-music.mp3`,
  summon: `${basePath}/telegram-summon.mp3`,
  leaderboard: `${basePath}/telegram-leaderboard-bgmusic.mp3`,
  viralLoopPage: `${basePath}/telegram-invite-page-bgmusic.mp3`,
  spinner: `${basePath}/wheelbgmusic.mp3`,
};

export const startBattle = `${basePath}/telegram-start-battle.mp3`;

export const sounds = {
  newMove: `${basePath}/telegram-new-move.mp3`,
  buff: `${basePath}/telegram-buff.mp3`,
  faint: `${basePath}/telegram-faint.mp3`,
  newMoveLearned: `${basePath}/telegram-new-move-learned.mp3`,
  nerf: `${basePath}/telegram-nerf.mp3`,
  critical: `${basePath}/telegram-critical-hit.mp3`,
  pressButton: `${basePath}/telegram-press.mp3`,
  dodge: `${basePath}/telegram-dodge.mp3`,
  summoned: `${basePath}/telegram-summoned.mp3`,
  petLevelUp: `${basePath}/telegram-level-up.mp3`,
  win: `${basePath}/telegram-win.mp3`,
  lose: `${basePath}/telegram-lose.mp3`,
  spinWheel: `${basePath}/spin.mp3`,
  winWheel: `${basePath}/win.mp3`,
};

export type BackgroundSoundType = keyof typeof bgSounds;
