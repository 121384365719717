import { CONVERT_ENERGY_TO_XP_ENDPOINT } from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { Button } from '../../ui/molecules/Button';
import { BattleEntity } from 'genopets-utils';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUIBattleContext } from '../context/useBattleContext';
import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { GoHomeButtons } from '../GoHomeButtons';
import { Link } from '../../Link/Link';
import { useUserData } from '@/context/FirestoreContext';

export const ClaimRewardButton = ({
  battle,
  showHomeButtons,
  setShowHomeButtons,
}: {
  battle: BattleEntity;
  showHomeButtons: boolean;
  setShowHomeButtons: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isConvertingEnergy, setIsConvertingEnergy] = useState(false);

  const { user } = useUIBattleContext();

  const { apiPost } = useAPI();

  const { userData } = useUserData();

  const navigate = useNavigate();

  const convertEnergyToXP = async () => {
    setIsConvertingEnergy(true);

    apiPost(CONVERT_ENERGY_TO_XP_ENDPOINT, {})
      .then((data) => {
        if (data?.levelUp) {
          navigate('/battle-level-up');
        } else if (
          // if winner didn't level up but got a new move
          battle?.winnerTelegramRolledMove &&
          battle.winner === user?.uid
        ) {
          navigate('/battle-choose-move');
        } else {
          setShowHomeButtons(true);
        }
      })
      .catch((error: any) => {
        console.error(error);
        alert({ title: error?.message ?? 'Error' });
      })
      .finally(() => setIsConvertingEnergy(false));
  };

  if (showHomeButtons) {
    return (
      <GoHomeButtons
        buttonTextKey={
          (userData?.telegramTickets ?? 0) === 0 ? 'battleAnyway' : 'nextBattle'
        }
      />
    );
  }

  return (
    <>
      {(userData?.telegramTickets ?? 0) === 0 && !showHomeButtons && (
        <Link to="/earn" style={{ width: '100%' }}>
          <Button width="full" colorId="Black">
            <TranslatedText
              translationKey={`getMoreTickets`}
              defaultMessage={`Get more tickets`}
            />
          </Button>
        </Link>
      )}
      <Button
        colorId="Black"
        w="full"
        onClick={() => {
          if (!battle.telegramRewardsProcessed) return;
          convertEnergyToXP();
        }}
        // wait until rewards from backend is processed
        isLoading={!battle.telegramRewardsProcessed || isConvertingEnergy}
      >
        {!battle.telegramRewardsProcessed || isConvertingEnergy ? (
          <TranslatedText
            translationKey={`loading`}
            defaultMessage={`Loading`}
          />
        ) : (
          <TranslatedText translationKey={`ok`} defaultMessage={`ok`} />
        )}
      </Button>
    </>
  );
};
