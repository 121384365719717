import { useMusic } from '@/components/Sounds/useMusic';
import { MainContainer } from '@/components/battle/MainContainer';
import { Spinner } from '@/components/battle/spinner/Spinner';
import { bgSounds } from '@/lib/sounds';
import { useSearchParams } from 'react-router-dom';
import SpinnerBg from '../../images/spinner-bg.png';

export const SpinnerPage = () => {
  useMusic(bgSounds.spinner);

  const [searchParams] = useSearchParams();

  const auto = searchParams.get('auto');

  const ticketClaimedAmount = searchParams.get('tickets');

  return (
    <MainContainer
      background={SpinnerBg}
      backgroundPosition="center"
      backgroundSize="cover"
    >
      <Spinner
        auto={auto === 'true'}
        ticketClaimedAmount={
          ticketClaimedAmount ? +ticketClaimedAmount : undefined
        }
      />
    </MainContainer>
  );
};
