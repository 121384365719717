import { useMusic } from '@/components/Sounds/useMusic';
import { AirdropDetails } from '@/components/airdrop/AirdropDetails';
import { MainContainer } from '@/components/battle/MainContainer';
import { bgSounds } from '@/lib/sounds';
import InitBackground from '../../images/leaderboard-bg.png';

export const AirdropDetailsPage = () => {
  useMusic(bgSounds.leaderboard);

  return (
    <MainContainer
      padding={0}
      background={InitBackground}
      backgroundPosition="top"
      backgroundSize="100% auto"
    >
      <AirdropDetails />
    </MainContainer>
  );
};
