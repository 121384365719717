import { useAuthState } from '@/context/UserContext';
import { useCallback, useEffect, useState } from 'react';
import { boomerang } from 'ui/utils/boomerang';

type JSONPrimitive = string | number | boolean | null | undefined;

type JSONValue =
  | JSONPrimitive
  | JSONValue[]
  | {
    [key: string]: JSONValue;
  };

type RequestBody = JSONValue;

export default function useAPI() {
  const [token, setToken] = useState<string>();

  const { state } = useAuthState();

  const { currentUser: user } = state;

  useEffect(() => {
    if (user) {
      user.getIdToken().then((t) => {
        setToken(t);
      });
    }
  }, [user]);

  const apiGet = useCallback(
    async (
      endpoint: string,
      waitToken = true,
      params?: Record<string, any>,
    ) => {
      if (!token && waitToken) return;
      const headers = { Authorization: `Bearer ${token}` };

      // Build the full URL with query parameters if provided
      let url = endpoint;
      if (params) {
        const queryString = new URLSearchParams(params).toString();
        url += `?${queryString}`;
      }

      return boomerang(url, {
        method: `GET`,
        headers,
      });
    },
    [token],
  );


  const apiPost = useCallback(
    async (endpoint: string, body: RequestBody, waitToken = true) => {
      if (!token && waitToken) return;
      return boomerang(endpoint, {
        method: `POST`,
        headers: {
          'Content-Type': `application/json`,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
    },
    [token],
  );

  const apiPut = useCallback(
    async (endpoint: string, body: RequestBody, waitToken = true) => {
      if (!token && waitToken) return;

      return boomerang(endpoint, {
        method: `PUT`,
        headers: {
          'Content-Type': `application/json`,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
    },
    [token],
  );

  const apiPatch = useCallback(
    async (endpoint: string, body: RequestBody, waitToken = true) => {
      if (!token && waitToken) return;
      return boomerang(endpoint, {
        method: `PATCH`,
        headers: {
          'Content-Type': `application/json`,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
    },
    [token],
  );

  const apiDelete = useCallback(
    async (endpoint: string, waitToken = true) => {
      if (!token && waitToken) return;
      const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
      return boomerang(endpoint, {
        method: `DELETE`,
        headers,
      });
    },
    [token],
  );

  return { apiGet, apiPost, apiDelete, apiPatch, apiPut, token };
}
