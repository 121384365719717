import useLocalStorage from '@/hooks/useLocalStorage';
import { sounds } from '@/lib/sounds';
import React, { createContext, useContext, useState, useEffect } from 'react';
import useSound from 'use-sound';

type EffectPlayerContextType = {
  playEffect: (url: string) => void;
  stopEffect: () => void;
  volume: number;
  muted: boolean;
  setVolume: (volume: number) => void;
  toggleMute: () => void;
};

const EffectPlayerContext = createContext<EffectPlayerContextType | undefined>(
  undefined,
);

export const useEffectPlayer = (): EffectPlayerContextType => {
  const context = useContext(EffectPlayerContext);
  if (!context) {
    throw new Error(
      'useEffectPlayer must be used within an EffectPlayerProvider',
    );
  }
  return context;
};

export const EffectPlayerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // do not init to null or '' or it will not play the sounds
  const [soundUrl, setSoundUrl] = useState<string>(sounds.pressButton);

  const [volume, setVolume] = useLocalStorage<number>('volume', 1);
  const [muted, setMuted] = useLocalStorage<boolean>('muted', false);

  const [play, { stop, sound }] = useSound(soundUrl, {
    volume: muted ? 0 : +volume,
    interrupt: false,
    loop: false,
    // onend: () => {
    //    potential callback
    // },
  });

  useEffect(() => {
    // sometime the sound is not playing, so we need to trigger it again
    if (sound && soundUrl === sound._src) {
      play();
    }
  }, [sound?._src]);

  const playEffect = (url: string) => {
    setSoundUrl(url);
    if (sound && url === sound._src) {
      play();
    }
  };

  const stopEffect = () => {
    stop();
  };

  const toggleMute = () => setMuted(!muted);
  return (
    <EffectPlayerContext.Provider
      value={{ playEffect, stopEffect, volume, muted, setVolume, toggleMute }}
    >
      {children}
    </EffectPlayerContext.Provider>
  );
};
