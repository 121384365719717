import { PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { coerceArray } from '../../../utils';
import {
  BattleActionEntity,
  BattleMoveEntity,
  MoveType,
  Rolls,
} from '../../models/battle-action.entity';
import {
  BattleNextAction,
  BattleState,
  UiEventType,
} from '../../models/battle-state';
import { SpecialStatusEffectEnum } from '../../models/status-effect.entity';
import {
  checkVictory,
  getOpponentData,
  getPlayerData,
} from '../../utils/battle-utils';
import { calculateBreakLevel, updateBreakLevel } from '../../utils/break';
import { applyCooldown } from '../../utils/cooldowns';
import { calculateDamage } from '../../utils/damage';
import {
  getElementalStatus,
  isElementalStatusApplied,
} from '../../utils/elemental';
import { endTurnEffect } from '../../utils/end-turn';
import { commonErrorCheck } from '../../utils/error';
import { checkThatMoveAreEqual } from '../../utils/moves';
import { checkIfParalyze } from '../../utils/status';
import { useWrapperReducer } from '../../utils/useWrapperReducer';

/**
 * Verify common error that could occur when passing an useMove action
 * this function verify that the battle is executed as expected (player order, block allowed, etc)
 * the error is stored in the state and then is thrown by the store consumer id needed
 * @param state current state
 * @param action new action
 * @returns the error if any or null
 */
function checkUseMoveError(
  state: BattleState,
  action: PayloadAction<BattleActionEntity>,
): string | null {
  const error = commonErrorCheck(state, action);
  if (error) {
    return error;
  }

  if (state.nextAction !== BattleNextAction.PLAYER_CHOOSE) {
    return `You cannot perform this action, action should be ${state.nextAction}`;
  }

  const player = getPlayerData(state, action.payload.playerId);
  const { pet } = player;
  const move = action.payload.data as BattleMoveEntity;
  if ((pet.cooldowns?.[move.name] ?? 0) > 0) {
    return `Cannot used the move <${move.name}> because it is in cooldown for ${
      pet.cooldowns![move.name]
    } turn(s)`;
  }

  // if elemental status is applied move set become different, check again the element move set
  if (isElementalStatusApplied(pet)) {
    const status = getElementalStatus(pet);
    const availableMoves = state.elementalMoves.filter(
      (m) => m.element === status!.name,
    );
    if (!availableMoves.find((elMove) => checkThatMoveAreEqual(move, elMove))) {
      return `You chose a move not available in when under ${
        status!.name
      } elemental status`;
    }
  } else {
    const playerMoves = move.types.includes(MoveType.Break)
      ? coerceArray(player.break.move)
      : (player.moves ?? []).concat(player.minorMove);
    if (!playerMoves.find((petMove) => checkThatMoveAreEqual(move, petMove))) {
      return move.types.includes(MoveType.Break)
        ? `This move is not a valid break move`
        : `You chose a move not available in your move selection`;
    }
  }

  if (
    move.types.includes(MoveType.Break) &&
    player.break.level < player.break.triggerLevel
  ) {
    return `Cannot used the break move <${move.name}> because break point(s) are insufficient, player has ${player.break.level} and the move requires ${player.break.triggerLevel}`;
  }

  //

  return null;
}

export function handleAction(
  state: BattleState,
  actionStore: PayloadAction<BattleActionEntity>,
) {
  const action = actionStore.payload;

  let newState = cloneDeep(state) as BattleState;

  const playerThatPlayedTheAction = getPlayerData(newState, action.playerId);

  const opponent = getOpponentData(newState, action.playerId);

  const move = action.data as BattleMoveEntity;

  const paralyze = checkIfParalyze(playerThatPlayedTheAction.pet);

  let rolls: Rolls;

  if (paralyze) {
    rolls = {
      paralyze: playerThatPlayedTheAction.pet.status!.find(
        (s) => s.name === SpecialStatusEffectEnum.Paralyzed,
      )!.duration,
    };

    if (state.withUiEvents && newState.uiEvents) {
      newState.uiEvents.push({
        type: UiEventType.PLAY_PARALYZE,
        id: newState.uiEvents.length.toString(),
        payload: {
          paralyzedPlayerId: playerThatPlayedTheAction.id,
        },
      });
    }

    // this is consider as the start of the opponent turn (player attacked and no dodge is possible)
    // we tick all element linked to the next player
    newState = endTurnEffect(newState, opponent.id);
  } else {
    const { rolls: rollsDamage } = calculateDamage(
      playerThatPlayedTheAction.pet,
      opponent.pet,
      move,
      action.rolls,
    );

    rolls = rollsDamage;

    // put move on cooldown
    // cooldown are applied regardless of the block result
    playerThatPlayedTheAction.pet = applyCooldown(
      playerThatPlayedTheAction.pet,
      move,
    );

    playerThatPlayedTheAction.break.level = updateBreakLevel(
      playerThatPlayedTheAction.break.level,
      playerThatPlayedTheAction.break.triggerLevel,
      move,
    );

    // add break point if needed
    playerThatPlayedTheAction.break.level = calculateBreakLevel(
      playerThatPlayedTheAction.break.level,
      playerThatPlayedTheAction.break.maxLevel,
      move,
    );

    newState.nextAction = BattleNextAction.PLAYER_DODGE;
  }

  newState.nextPlayer = opponent.id;

  newState.actions.push({ ...actionStore, payload: { ...action, rolls } });

  return checkVictory(newState);
}

export const useMoveReducer = (
  state: Partial<BattleState>,
  action: PayloadAction<BattleActionEntity>,
): BattleState =>
  useWrapperReducer(state, action, handleAction, checkUseMoveError);
