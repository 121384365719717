import { Card } from '../ui/molecules/Card';
import NotcoinImg from '../../images/notcoin.png';
import { Text } from '../ui/atoms/Text';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Box, Center, Flex, color, useDisclosure } from '@chakra-ui/react';
import { Modal } from '../ui/molecules/Modal';
import { useUserData } from '@/context/FirestoreContext';
import TicketImg from '../../images/ticket.png';
import { TELEGRAM_REFERRAL_CODE_NOTCOIN } from 'genopets-utils';

export enum NOTCOIN_TIER {
  'BRONZE' = 'BRONZE',
  'GOLD' = 'GOLD',
  'PLATINUM' = 'PLATINUM',
  'NONE' = 'NONE',
}

const NotcoinCard = ({
  tier,
  color,
  tickets,
  claimed,
}: {
  tier: NOTCOIN_TIER;
  color: string;
  tickets: number;
  claimed: boolean;
}) => {
  return (
    <Card opacity={claimed ? 1 : 0.54} w="full" h="auto" padding={0}>
      <Text color={color} padding={'8px 0 0 8px'}>
        {tier}
      </Text>
      <Center gap={1} w="full">
        <img
          src={TicketImg}
          alt="notcoin"
          style={{ width: 'auto', height: '32px' }}
        />
        <Text colorId={'Black'}>{tickets}</Text>
      </Center>
      <Center
        background={claimed ? color : 'var(--Black)'}
        w="full"
        borderTop={`3px solid var(--Black)`}
        padding={4}
      >
        <Text>
          {claimed ? (
            <>
              <TranslatedText
                translationKey="claimed"
                defaultMessage="claimed"
              />
              !
            </>
          ) : (
            <TranslatedText translationKey="locked" defaultMessage="locked" />
          )}
        </Text>
      </Center>
    </Card>
  );
};

export const TIER_IN_NOTCOIN: Record<
  number,
  { color: string; tier: NOTCOIN_TIER; tickets: number }
> = {
  3: {
    tier: NOTCOIN_TIER.BRONZE,
    color: '#C37024',
    tickets: 3,
  },
  9: { tier: NOTCOIN_TIER.GOLD, color: '#EBDC53', tickets: 9 },
  18: { tier: NOTCOIN_TIER.PLATINUM, color: '#A4A4A4', tickets: 18 },
};

export const Notcoin = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  const { userData } = useUserData();

  let tier = { color: 'var(--Black)', tier: NOTCOIN_TIER.NONE };

  if (userData?.NotcoinReferral?.claimedPlatinum) {
    tier = TIER_IN_NOTCOIN[18];
  } else if (userData?.NotcoinReferral?.claimedGold) {
    tier = TIER_IN_NOTCOIN[9];
  } else if (userData?.NotcoinReferral?.claimedBronze) {
    tier = TIER_IN_NOTCOIN[3];
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Center flexDirection="column" w="full" h="full">
          <img
            src={NotcoinImg}
            alt="notcoin"
            style={{ width: 'auto', height: '80px' }}
          />
          <Text colorId="Black">
            <TranslatedText
              translationKey={`tierInNotcoin`}
              defaultMessage={`tier In Notcoin`}
            />
          </Text>
          {tier.tier === NOTCOIN_TIER.NONE ? (
            <Text colorId="Red">
              <TranslatedText
                translationKey={`notEligible`}
                defaultMessage={`not Eligible`}
              />
            </Text>
          ) : (
            <Text color={tier.color}>{tier.tier}</Text>
          )}
          <Center
            overflow={'auto'}
            height="50vh"
            w="full"
            position={'relative'}
          >
            <Center
              flexDirection="column"
              w="full"
              gap={1}
              position="absolute"
              top="0"
            >
              {Object.values(TIER_IN_NOTCOIN).map((t) => (
                <NotcoinCard
                  key={t.tier}
                  tier={t.tier}
                  color={t.color}
                  tickets={t.tickets}
                  claimed={tier.tier === t.tier}
                />
              ))}
            </Center>
          </Center>
        </Center>
      </Modal>
      {userData?.referralCodeUsed === TELEGRAM_REFERRAL_CODE_NOTCOIN && (
        <Card
          h="90px"
          w="90px"
          padding="10px"
          background="#FF9F1B"
          justifyContent={'center'}
          flexDirection={'column'}
          alignItems={'center'}
          gap={0}
          cursor={'pointer'}
          onClick={onOpen}
        >
          <img
            src={NotcoinImg}
            alt="notcoin"
            style={{ width: 'auto', height: '60px' }}
          />
          <Text colorId={'Black'} textAlign="center" w="full" fontSize={'6px'}>
            <TranslatedText
              translationKey={`collab`}
              defaultMessage={`collab`}
            />
            !
          </Text>
        </Card>
      )}
    </>
  );
};
