import { TranslatedText } from '@/components/ui/atoms/TranslatedText';
import { VITE_SHOW_BATTLE_INFO } from '@/config/constants';
import { sounds } from '@/lib/sounds';
import { Box, Center, Collapse, Flex } from '@chakra-ui/react';
import {
  BattleState,
  BattleStatus,
  RootState,
  copyToClipboard,
  getPlayerData,
} from 'genopets-utils';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { BlinkingText } from '../ui/atoms/BlinkingText';
import { Button } from '../ui/molecules/Button';
import { Battle } from './Battle';
import { WaitForPlayerToJoin } from './WaitForPlayerToJoin';
import { BattleMode, useUIBattleContext } from './context/useBattleContext';
import { useDispatchBattleActions } from './hooks/useDispatchBattleActions';
import { useEndTurn } from './hooks/useEndTurn';
import { usePrintMove } from './hooks/usePrintMove';
import { useReadBattleFromFirestore } from './hooks/useReadBattleFromFirestore';
import { BattleReplay } from './replay/BattleReplay';
import { BattleRewardsCard } from './rewards/BattleRewardsCard';
import { useReadUiEvents } from './hooks/useReadUiEvents';
import { usePrintPlayStatus } from './hooks/usePrintPlayStatus';
import { usePlayParalyze } from './hooks/usePlayParalyze';
import { useEffectPlayer } from '@/context/EffectPlayerContext';

const ToBeRemovedBattleInformation = ({ battleId, user }: any) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        position="fixed"
        display={show ? 'none' : 'block'}
        zIndex={100}
        top={0}
        left={0}
        width="fit-content"
        minW={0}
        onClick={() => setShow(true)}
      >{`INFO`}</Button>
      <Collapse in={show}>
        <Flex gap={2}>
          <Button
            minW={'20px'}
            padding={1}
            onClick={() => setShow(false)}
          >{`X`}</Button>
          <Flex flexDirection={'column'}>
            <Box>{'BID => ' + (battleId ?? 'No battle started')}</Box>
            <Box>{'UID:' + (user?.uid ?? 'No user id')}</Box>
          </Flex>
          <Button
            colorId={'Green'}
            onClick={() => {
              copyToClipboard(battleId);
            }}
            width="50px"
            minW={'50px'}
          >
            copy
          </Button>
        </Flex>
      </Collapse>
    </>
  );
};

export const BattleContainer = () => {
  const { battleId, user, mode } = useUIBattleContext();

  const battle = useReadBattleFromFirestore({ battleId });

  const battleState = useSelector<RootState>(
    (state: RootState) => state.battle,
  ) as BattleState;

  const effectPlayer = useEffectPlayer();

  useEffect(() => {
    if (!battleState.winner || mode !== BattleMode.ENDED || !user?.uid) return;

    if (battleState.winner === user?.uid) {
      effectPlayer.playEffect(sounds.win);
    } else {
      effectPlayer.playEffect(sounds.lose);
    }
  }, [battleState.winner, user?.uid]);

  useDispatchBattleActions(battle);

  useReadUiEvents();

  usePrintMove();

  usePrintPlayStatus();

  usePlayParalyze();

  useEndTurn();

  const player = useMemo(
    () =>
      user?.uid &&
      battleState?.player1 &&
      battleState?.player2 &&
      getPlayerData(battleState, user.uid),
    [user, battleState],
  );

  return (
    <>
      {!battleId ? null : (
        <>
          {VITE_SHOW_BATTLE_INFO ? (
            <ToBeRemovedBattleInformation battleId={battleId} user={user} />
          ) : null}
          {battle?.status === BattleStatus.WAITING ? (
            <WaitForPlayerToJoin battle={battle} />
          ) : null}
          {battle && mode === BattleMode.BATTLE ? (
            <Battle player={player} battle={battle} />
          ) : null}
          {battle && mode === BattleMode.REPLAY ? (
            <BattleReplay battle={battle} />
          ) : null}
          {battle?.status === BattleStatus.ENDED &&
          mode === BattleMode.ENDED ? (
            <>
              <Center
                top={0}
                right={0}
                left={0}
                bottom={0}
                position="absolute"
                pointerEvents={'none'}
              >
                <BlinkingText>
                  {battleState.winner === user?.uid ? (
                    <TranslatedText
                      translationKey={`youWin`}
                      defaultMessage={`You win`}
                    />
                  ) : (
                    <TranslatedText
                      translationKey={`youLose`}
                      defaultMessage={`You lose`}
                    />
                  )}
                  !
                </BlinkingText>
              </Center>
              <BattleRewardsCard battle={battle} player={player} />
            </>
          ) : null}
        </>
      )}
    </>
  );
};
